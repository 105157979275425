import _ from 'lodash';
import MiscUtils from './miscUtils';
import FrontendStorage from './frontendStorage';
import { stores } from '../stores';
import classes from '../api/classes';
import { onSuccess } from '../api';

const { apiClassMap } = classes;
const { ApiObject } = apiClassMap;

const GENERIC_DATA_TIMEOUT_SEC = 300;
const CLASS_DATA_KEY = '__classData';
let genericDataTime;
let dataInvalidated;

let classData = (() => {
	try {
		const jsonStr = window.localStorage.getItem(CLASS_DATA_KEY);
		if (jsonStr) {
			return JSON.parse(jsonStr);
		}
	} catch (e) { /** Ignore, we will soon get the correct data anyway.. */ }
	return null;
})();

class SystemData {
	static genericDataReady() {
		return SystemData.genericData && (new Date() - genericDataTime) <= GENERIC_DATA_TIMEOUT_SEC * 1000 && !dataInvalidated;
	}

	static invalidateSystemData() {
		const wasInvalid = dataInvalidated;
		dataInvalidated = true;
		if (!wasInvalid) {
			SystemData.onDataInvalidated.notify();
		}
	}

	static async loadGenericData() {
		if (!SystemData.genericDataReady()) {
			dataInvalidated = false;

			// As GlobalSettingsObject.getGenericAppData() (maybe) doesn't exist yet we'll create a "Dummy" class just
			// in order to do that call.
			class Dummy extends ApiObject {}
			Dummy.clsInfo = { apiPath: 'globalsettingsobjects', isPathOwner: true };
			Dummy.clsInitialize();
			SystemData.genericData = await Dummy.callStatic('getGenericAppData', {
				classDataHash: (classData || {}).hash,
			});
			const { inDemoMode, demoRequireLogin, classData: newClassData } = SystemData.genericData;
			if (newClassData) {
				classData = newClassData;
				try {
					localStorage.setItem(CLASS_DATA_KEY, JSON.stringify(classData));
				} catch (e) { /** No big deal - just that we need to download classData next time as well */ }
			}
			if (inDemoMode && !demoRequireLogin && !FrontendStorage.getItem('AuthToken')) {
				await stores.identity.tryLogin('admin@mugs.info', 'MAGIC_DEMO_PASSWORD');
			}
			genericDataTime = new Date();
			ApiObject.initializeClasses(classData.data);

			SystemData.models = apiClassMap;
			SystemData.onGenericDataUpdated.notify();
		}
	}
}

SystemData.onDataInvalidated = MiscUtils.listeners();
SystemData.onGenericDataUpdated = MiscUtils.listeners();

onSuccess.add(({ systemDataUpdate }) => {
	if (systemDataUpdate && SystemData.genericData) {
		Object.assign(SystemData.genericData, systemDataUpdate);
		SystemData.onGenericDataUpdated.notify();
	}
});

/** Invalidate data whenever local storage changes (as current user might change) */
FrontendStorage.listeners.add(() => {
	SystemData.invalidateSystemData();
});

export default SystemData;
