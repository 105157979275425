/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import _ from 'lodash';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import {
	Box, Checkbox, Tooltip, Typography,
} from '@mui/material';
import Constants from 'relevant-shared/reportData/constants';
import { byReportType } from 'relevant-shared/reportData/reportType';
import MiscUtils from '../../lib/miscUtils';

function WidgetSelector({
	availableWidgets, onSelect, nonSelected, selectedWidgets, multiple,
}) {
	const [selected, setSelected] = useState(_.isArray(selectedWidgets) ? selectedWidgets : [selectedWidgets]);

	let grouped = _.groupBy(availableWidgets, (w) => w.settings.type);
	grouped = MiscUtils.rearrangeObj(grouped, Constants.UI_ORDER);
	grouped = Object.entries(grouped).map(([k, arr]) => [byReportType[k]?.DESCRIPTION ?? 'Other', _.sortBy(arr, 'settings.title')]);

	function handleClick(widget) {
		if (multiple) {
			if (selected.some((w) => w.id === widget.id)) {
				const newSelection = selected.filter((w) => w.id !== widget.id);
				setSelected(newSelection);
				onSelect(newSelection);
			} else {
				const newSelection = [...selected, widget];
				setSelected(newSelection);
				onSelect(newSelection);
			}
		} else {
			onSelect(widget);
		}
	}

	return (
		<TreeView
			expanded={grouped.map(([d]) => d)}
		>
			{nonSelected && (
				<TreeItem
					key={NONE}
					nodeId={NONE}
					label={nonSelected}
					onClick={() => handleClick()}
				/>
			)}
			{grouped.map(([desc, arr]) => (
				<TreeItem key={desc} nodeId={desc} label={(<b>{desc}</b>)}>
					{arr.map((widget) => (
						<TreeItem
							key={widget.id}
							nodeId={widget.id}
							label={(
								<Tooltip
									title={widget.unavailable ? (
										<>
											<Box sx={{ backgroundColor: 'error.main', color: 'white', p: 1, borderRadius: 1 }}>
												<Typography variant="body1">GHOSTING MODE</Typography>
												<Typography variant="body3">This dashboard is unavailable because:</Typography>
											</Box>
											<ul>{widget.unavailable.map((msg, idx) => <li key={idx}>{msg}</li>)}</ul>
										</>
									) : (
										widget.description
									)}
									arrow
									placement="left-start"
									color={widget.unavailable && 'error'}
								>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										{multiple && (
											<Checkbox
												checked={selected.some((w) => w.id === widget.id)}
												onChange={() => !widget.unavailable && handleClick(widget)}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										)}
										{widget.unavailable ? (
											<Typography variant="body1" sx={{ textDecoration: 'line-through', color: 'text.disabled' }}>{widget.settings.title}</Typography>
										) : (
											<Typography variant="body1">{widget.settings.title}</Typography>
										)}
									</div>
								</Tooltip>
							)}
							onClick={() => !widget.unavailable && handleClick(widget)}
						/>
					))}
				</TreeItem>
			))}
		</TreeView>
	);
}

WidgetSelector.propTypes = {
	availableWidgets: PropTypes.array.isRequired,
	onSelect: PropTypes.func.isRequired,
	nonSelected: PropTypes.string,
	selectedWidgets: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	multiple: PropTypes.bool,
};

WidgetSelector.defaultProps = {
	nonSelected: undefined,
	selectedWidgets: [],
	multiple: false,
};

export default WidgetSelector;
