import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Box } from '@mui/material';
import { Reports } from '../../api/relevant';
import CheckboxGroup from '../CheckboxGroup';
import ExpandSelector from '../ExpandSelector';
import PopupSelector from '../PopupSelector';
import OperationWrapper from '../OperationWrapper';
import MiscUtils from '../../lib/miscUtils';
import SystemData from '../../lib/systemData';
import SearchAdder from './SearchAdder';
import FilterTypeSelector from '../Report/FilterTypeSelector';

const SELECTOR_BY_TYPE = {
	ExpandSelector,
	PopupSelector,
	NoSelector: OperationWrapper,
};

function NoContainer({ children }) {
	return children;
}

class SiteSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		props.fnTarget.getPublishers = () => this.pubs;
		this.byId = {};
		this.initSettings();
	}

	processItems(arr, idx = 0, state = null) {
		const { filterFn } = this.props;
		const currentState = state || { options: [], pos: [] };
		const { options, pos } = currentState;
		const path = this.paths[idx];
		const isFinal = idx === this.paths.length - 1;
		const items = [];
		MiscUtils.alphaSorted(arr, path.name).forEach((elm) => {
			const id = _.isFunction(path.id) ? path.id(elm) : elm[path.id];
			const item = { id, text: elm[path.name] };
			if (filterFn && !filterFn(this, { elm, path })) {
				return;
			}
			if (isFinal) {
				if (this.byId[id]) {
					return;
				}
				this.byId[id] = elm;
				options.push({
					label: path.searchNameFn ? path.searchNameFn(elm, currentState) : item.text,
					id: item.id,
					path: [...pos, item].map((it) => it.text).join(' - '),
				});
			} else {
				const next = this.paths[idx + 1];
				let children = elm[next.path] || [];
				if (next.getFromArr) {
					children = next.getFromArr(children);
				}
				pos.push(item);
				item.subitems = this.processItems(children, idx + 1, currentState).items;
				pos.pop();
				if (!item.subitems.length) {
					return; // don't make "dead-end" parts of the tree..
				}
			}
			items.push(item);
		});
		return { items, options };
	}

	async loadPublishers() {
		const { getPublishersFn } = this.props;
		this.pubs = await getPublishersFn();
		if (this.pubs.extraData) {
			Object.assign(this, this.pubs.extraData);
		}
		const { type } = this.props;
		this.pubs = this.pubs.filter((p) => {
			if (type === 'audience') {
				return p.audienceAccess;
			} if (type === 'programmatic') {
				return p.programmaticAccess;
			}
			return true;
		});
		this.byId = {};
		const { items, options } = this.processItems(this.pubs);
		this.setState({
			items,
			options,
		});
	}

	initSettings() {
		const { includeSourceDbId } = this.props;
		const paths = {
			isSites: [{ path: 'cxDmpSites' }],
			isSspSites: [{ path: 'websites', name: 'domain' }],
			isPlacements: [{
				path: 'placements',
				searchNameFn: ({ name }, { pos }) => (SystemData.genericData.includeSiteInPlacementNames
					? `${_.last(pos).text} - ${name}` : name
				),
			}],
			isSources: [{
				path: 'ssps',
				name: 'id',
				id: (src) => `${src.source}-${src.id}${includeSourceDbId ? `;;${src._id}` : ''}`,
			}],
		};
		paths.isPbConfigs = [...paths.isSspSites, {
			path: 'prebidConfigs',
			getFromArr: (arr) => arr.map((pbCfg) => [pbCfg, ...pbCfg.prebidConfigs.map(({ id, name }) => ({
				id,
				name: `${pbCfg.name} >> ${name}`,
			}))]).flat(),
		}];
		let prev = [];
		['isSspSites', 'isPlacements', 'isSources'].forEach((step) => {
			paths[step] = prev.concat(paths[step]);
			prev = paths[step];
		});
		const type = _.findLast(Object.keys(paths), (s) => this.props[s]);
		this.paths = [{}].concat(paths[type] || []).map((p) => ({ ...p, name: p.name || 'name', id: p.id || 'id' }));
		const titles = {
			isSites: 'Sites',
			isSspSites: 'Sites',
			isPlacements: 'Placements',
			isSources: 'SSP Sources',
			isPbConfigs: 'Prebid configurations',
		};
		this.defaultTitle = titles[type] || 'Publishers';
	}

	includeParents(selected) {
		const { items } = this.state;
		const selectMap = _.zipObject(selected, Array(selected.length).fill(true));
		const newMap = {};
		const markSelected = (item) => {
			let isSelected = false;
			if (item.subitems) {
				if (item.subitems.length) {
					isSelected = true;
					item.subitems.forEach((sub) => {
						if (!markSelected(sub)) {
							isSelected = false;
						}
					});
				}
			} else {
				isSelected = selectMap[item.id];
			}
			if (isSelected) {
				newMap[item.id] = true;
			}
			return isSelected;
		};
		items.forEach((item) => markSelected(item));
		return Object.keys(newMap);
	}

	render() {
		const {
			items,
			options,
			selectedInternal,
		} = this.state;
		const { OBSCURE_NAMING_ENABLED } = SystemData.genericData;
		const {
			selected,
			expanded,
			container,
			title,
			selectorType,
			isSources,
			isSspSites,
			isPlacements,
			onChange,
			onExpandChange,
			useSearchFilter,
			selectionType,
			onChangeSelectionType,
			singleSelect,
		} = this.props;
		const hide = OBSCURE_NAMING_ENABLED && isSources;
		const Container = container || NoContainer;
		const Selector = SELECTOR_BY_TYPE[selectorType];

		let type = 'publisher';
		if (isSources) {
			type = 'sspSource';
		} else if (isSspSites) {
			type = 'site';
		} else if (isPlacements) {
			type = 'placement';
		}
		const handleUpdate = (newItems, add) => {
			const prevSelected = selectedInternal || selected;
			let arr = (add ? _.uniq([...prevSelected, ...newItems]) : newItems).filter((i) => this.byId[i]);
			if (singleSelect && arr.length >= 2) {
				const added = _.without(arr, ...prevSelected);
				arr = added.length === 1 ? added : [];
			}
			if (Selector.useChangeOnOk) {
				this.setState({ selectedInternal: arr });
			} else {
				onChange(arr);
			}
		};
		return (
			<Selector
				title={title || this.defaultTitle}
				selected={selected}
				fn={() => this.loadPublishers()}
				expanded={expanded}
				onExpandChange={onExpandChange}
				getSingleTitle={(id) => (this.byId[id] || {})[_.last(this.paths).name]}
				onApplyChanges={() => this.state.selectedInternal && onChange(this.state.selectedInternal)}
			>
				{ onChangeSelectionType && (
					<FilterTypeSelector value={selectionType} onChange={onChangeSelectionType} />
				)}
				{useSearchFilter ? (
					<Box sx={{ mt: 4, pl: 1 }}>
						<SearchAdder
							options={options}
							type={type}
							onAdd={(itemIds) => handleUpdate(itemIds, true)}
							singleSelect={singleSelect}
						/>
					</Box>
				) : null }

				{items
					&& (hide ? (<i>Not visible in demo mode</i>) : (
						<Container>
							<CheckboxGroup
								items={items}
								selected={this.includeParents(selectedInternal || selected)}
								onChange={(itemIds) => handleUpdate(itemIds, false)}
							/>
						</Container>
					))}
			</Selector>
		);
	}
}

SiteSelect.propTypes = {
	isSites: PropTypes.bool,
	isSspSites: PropTypes.bool,
	isPlacements: PropTypes.bool,
	isSources: PropTypes.bool,
	isPbConfigs: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	selected: PropTypes.array,
	expanded: PropTypes.bool,
	container: PropTypes.object,
	fnTarget: PropTypes.object,
	title: PropTypes.string,
	onExpandChange: PropTypes.func,
	selectorType: PropTypes.oneOf(['ExpandSelector', 'PopupSelector', 'NoSelector']),
	type: PropTypes.oneOf(['audience', 'programmatic', 'all', 'future']),
	getPublishersFn: PropTypes.func,
	includeSourceDbId: PropTypes.bool,
	useSearchFilter: PropTypes.bool,
	selectionType: PropTypes.oneOf(['EXCLUSIVE', 'INCLUSIVE']),
	onChangeSelectionType: PropTypes.func,
	filterFn: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.oneOf([false, null, undefined]),
	]),
	singleSelect: PropTypes.bool,
};

SiteSelect.defaultProps = {
	isSites: false,
	isSspSites: false,
	isPlacements: false,
	isSources: false,
	isPbConfigs: false,
	selected: [],
	expanded: false,
	container: undefined,
	fnTarget: {},
	title: undefined,
	onExpandChange: undefined,
	selectorType: 'ExpandSelector',
	type: 'all',
	includeSourceDbId: false,
	getPublishersFn: () => Reports.call('getPublishers'),
	useSearchFilter: false,
	selectionType: 'INCLUSIVE',
	onChangeSelectionType: undefined,
	filterFn: undefined,
	singleSelect: false,
};

export default SiteSelect;
