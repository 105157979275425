import _ from 'lodash';
import MiscUtils from '../../lib/miscUtils';
import { AdvertiserUserMapping } from '../../api/relevant';
import DateUtils from '../../lib/dateUtils';

class UserAdvData {
	constructor(userData, advNr, advName) {
		Object.assign(this, {
			userData,
			advNr,
			advName,
			now: null,
			prev: [],
			next: [],
		});
	}

	addEntry(entry) {
		const { date } = this.userData.userStates;
		if (entry.end && entry.end < date) {
			this.prev.push(entry);
		} else if (entry.start && entry.start > date) {
			this.next.push(entry);
		} else {
			this.now = entry;
		}
	}

	allEntries() {
		return [...this.prev, this.now, ...this.next].filter((e) => e);
	}
}

class UserData {
	constructor(userStates, userId) {
		Object.assign(this, {
			userStates,
			userId,
			byAdvNr: {},
			prev: [],
			now: [],
			next: [],
		});
	}

	addEntry(mapping, entry) {
		let advData = this.byAdvNr[mapping.advNr];
		if (!advData) {
			advData = new UserAdvData(this, entry.advNr, entry.advName);
			this.byAdvNr[mapping.advNr] = advData;
		}
		advData.addEntry(entry);
	}

	finalize() {
		_.forOwn(this.byAdvNr, (data) => {
			if (data.prev.length) {
				this.prev.push(data);
			}
			if (data.now) {
				this.now.push(data);
			}
			if (data.next.length) {
				this.next.push(data);
			}
		});
	}
}

class UserStates {
	load(mappings, date) {
		Object.assign(this, {
			mappings,
			date,
			byUserId: {},
			mappingByAdvNr: {},
			userAdvDatasByAdvNr: {},
		});
		this.mappings.forEach((mapping) => {
			this.mappingByAdvNr[mapping.advNr] = mapping;
			mapping.entries.forEach((entry) => {
				let userData = this.byUserId[entry.userId];
				if (!userData) {
					userData = new UserData(this, entry.userId);
					this.byUserId[entry.userId] = userData;
				}
				userData.addEntry(mapping, entry);
			});
		});
		_.forOwn(this.byUserId, (userData) => {
			userData.finalize();
			_.forOwn(userData.byAdvNr, (userAdvData) => {
				let arr = this.userAdvDatasByAdvNr[userAdvData.advNr];
				if (!arr) {
					arr = [];
					this.userAdvDatasByAdvNr[userAdvData.advNr] = arr;
				}
				arr.push(userAdvData);
			});
		});
	}

	userData(userId) {
		return this.byUserId[userId] || new UserData(this, userId);
	}
}

class Entry {
	constructor(mapping, entry) {
		Object.assign(this, entry, { mapping });
		this.start = this.start ? new Date(this.start) : null;
		this.end = this.end ? new Date(this.end) : null;
	}

	get advName() {
		return this.mapping.advName;
	}

	get advNr() {
		return this.mapping.advNr;
	}

	get user() {
		return this.mapping.data.usersById[this.userId];
	}

	toJSON() {
		return _.omit(this, ['mapping']);
	}

	isForDate(date) {
		return (!this.start || this.start <= date) && (!this.end || this.end >= date);
	}
}

class Mapping {
	constructor(data, mapping, advName) {
		Object.assign(this, mapping, { advName, data });
		this.entries = (mapping.entries || []).map((e) => new Entry(this, e));
	}

	entriesFrom(date) {
		return this.entries.filter((e) => !date || !e.end || e.end >= date);
	}
}

class PortfolioData {
	constructor() {
		this.userStatesByDate = [];
	}

	init({ users, mappings, advByNr }) {
		users.forEach((user) => {
			const allEmpty = !user.firstname && !user.lastname;
			user.firstname = user.firstname || '-';
			user.lastname = user.lastname || '-';
			user.fullname = allEmpty ? user.email : `${user.firstname} ${user.lastname}`;
		});
		const usersById = _.keyBy(users, 'id');
		Object.assign(this, {
			today: DateUtils.today(),
			usersById,
			advByNr,
			users: MiscUtils.alphaSorted(users, 'fullname'),
			mappings: _.cloneDeep(mappings).filter((m) => {
				m.entries = (m.entries || []).filter((e) => usersById[e.userId]);
				return m.entries.length;
			}).map((m) => new Mapping(this, m, advByNr[m.advNr].name)),
		});
		this.mappingsByAdv = _.keyBy(this.mappings, 'advNr');
		_.forOwn(this.userStatesByDate, (us, date) => us.load(this.mappings, DateUtils.fullDay(date)));
		this.userStates = this.userStatesFor(this.today);
	}

	async reload() {
		this.init(await AdvertiserUserMapping.call('getUsersAndMappings'));
	}

	async moveAdvertisers(settings) {
		await AdvertiserUserMapping.call('moveAdvertisers', settings);
		await this.reload();
	}

	static async load() {
		const res = new PortfolioData();
		await res.reload();
		return res;
	}

	userStatesFor(date) {
		const d = date ? DateUtils.fullDay(date) : this.today;
		let res = this.userStatesByDate[d];
		if (res) {
			return res;
		}
		res = new UserStates();
		res.load(this.mappings, d);
		return res;
	}
}

export default PortfolioData;
