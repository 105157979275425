import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)(({ theme, color: colorProp }) => ({
	textDecoration: 'none',
	color: colorProp || theme.palette.primary.main,
}));

/**
 * React router link with 'clean' styles
 */
export default function UnstyledLink({ to, color, children, ...props }) {
	return (
		<StyledLink
			to={to}
			color={color}
			{...props}
		>
			{ children }
		</StyledLink>
	);
}

UnstyledLink.propTypes = {
	to: PropTypes.string.isRequired,
	color: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

UnstyledLink.defaultProps = {
	color: null,
};
