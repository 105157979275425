const Node = require('./node');

class BidderNode extends Node {
	constructor(...args) {
		super(...args);
		if (this.isPbjsBidder) {
			this.parents.forEach((node) => node.registerBidderNode(this));
		}
	}

	get isPbjsBidder() {
		const { ssp } = this;
		return !!(ssp && ssp.bidderName);
	}

	get isBidderNode() { return true; }

	get sspId() { throw Error('Not implemented'); }

	get sspPlacNode() { throw Error('Not implemented'); }

	get mediaTypeLimiter() {
		return this.sspPlacNode.obj.mediaTypeLimiter || this.defaultMediaTypeLimiter;
	}

	get defaultMediaTypeLimiter() {
		const { ssp } = this;
		return ssp ? ssp.defaultMediaTypeLimiter : 'mixed';
	}

	get ssp() { return this.root.sspsById[this.sspId]; }

	get siteNode() {
		return this.getParentOfType('SiteNode');
	}

	get placementNode() {
		return this.parentNode;
	}

	get prebidConfigs() {
		const { siteNode, publisherNode } = this;
		return this.obj.pbCfgs.map((id) => {
			let res = siteNode.obj.prebidConfigs.find((cfg) => cfg.id === id);
			if (!res) {
				for (const otherSite of publisherNode.children) {
					res = otherSite.obj.prebidConfigs.find((cfg) => cfg.id === id);
					if (res) {
						return res;
					}
				}
			}
			return res;
		}).filter((cfg) => cfg);
	}
}

module.exports = BidderNode;
