import React from 'react';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';

/** Internal sub-component for the price TextField */
class PriceField extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(ev) {
		const { value } = ev.target;
		const newPrice = value ? parseFloat(value) : undefined;
		this.props.onChange(newPrice);
	}

	render() {
		const { computed, price } = this.props;
		return (
			<Input
				placeholder={String(computed)}
				value={String(price || '')}
				name="price"
				aria-label="Price"
				type="number"
				onChange={this.handleChange}
			/>
		);
	}
}

PriceField.propTypes = {
	computed: PropTypes.number,
	price: PropTypes.number,
	onChange: PropTypes.func.isRequired,
};

PriceField.defaultProps = {
	computed: 0,
	price: undefined,
};

export default PriceField;
