import React, { useRef, useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import PropTypes from 'prop-types';
import {
	Box,
	Button,
	Avatar,
} from '@mui/material';
import { Publisher } from '../../../api/relevant';
import { readFile } from './utils';
import { UploadFile } from '@mui/icons-material';
import { Link } from '../../../components/Link/Link';
import clsx from 'clsx';

function CsvUploader({ onUploadFile }) {
	const [fileIsDraggedOver, setFileIsDraggedOver] = useState(false);
	const filePickerEl = useRef(null);

	return (
		<>
			<Box
				component="input"
				ref={filePickerEl}
				type="file"
				accept="text/csv"
				sx={{ display: 'none' }}
				onChange={async (e) => {
					const file = e.target.files[0];
					try {
						const csv = await readFile(file);
						const res = await Publisher.call('validateCsvImport', { csv });
						onUploadFile(res, csv);
					} catch (err) {
						onUploadFile(null, null, err.error);
					}
				}}
			/>

			<div
				className={clsx(
					"flex flex-col items-center gap-1 border rounded-md py-6 cursor-pointer",
					fileIsDraggedOver ? 'border-cherry-500 border-solid' : 'border-grey-300 border-dashed',
				)}
				onClick={() => filePickerEl.current.click()}
				onDrop={async (e) => {
					e.preventDefault();
					if (e.dataTransfer.items) {
						// Only handle first file, we don't support multiple files
						const firstItem = e.dataTransfer.items[0];
						if (firstItem.kind === 'file') {
							const file = firstItem.getAsFile();
							if (file) {
								try {
									const csv = await readFile(file);
									const res = await Publisher.call('validateCsvImport', { csv });
									onUploadFile(res, csv);
								} catch (err) {
									onUploadFile(null, null, err.error);
								}
							}
						}
					}
				}}
				onDragOver={(e) => {
					setFileIsDraggedOver(true);
					e.preventDefault();
				}}
				onDragLeave={() => {
					setFileIsDraggedOver(false);
				}}
			>
				<div className="bg-cherry-500 rounded-full p-4">
					<UploadFile className="text-white text-4xl" />
				</div>
				<Link className="text-lg mt-4 no-underline">Select a CSV to upload</Link>
				<span className="text-sm">or drop it here</span>
			</div>
		</>
	);
}

CsvUploader.propTypes = {
	onUploadFile: PropTypes.func.isRequired,
};

export default CsvUploader;
