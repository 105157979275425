import {
	Ssp,
	Publisher,
	Adserver,
	GlobalSettingsObject,
	Reports,
} from '../../api/relevant';
import BrowserUtils from '../../lib/browserUtils';
import { PublisherNode } from 'relevant-shared/objects/pubTree';
import { createCsv } from 'relevant-shared/misc/misc';
import _ from 'lodash';

// NOTE: This is an "older" CSV export function to the one inside CsvImportManager
// > Samuel Palmér
// > hmm, ja de är ju förvillande lika..den i.csv - exporten exporterar i samma
// > format som man kan ladda upp i sedan, så det är ju bra.
// > Den där nere(som är äldre) gör en rad per SSP - placering(istället för en
// > rad per placering med SSPs som kolumner) ..det som är bra med den är
// > egentligen bara att man kan hitta duplicerade ids, som tyvärr inte går på
// > något annat sätt nu.
// > eller just det, den skriver ut namnet på SSP - placeringen också(ifall vi
// > har det)
export async function exportPublisherStructureV1() {
	const UNKNOWN = '[Unknown]';
	const [publishers, ssps, adservers, globalSettings, labels] = await Promise.all([
		Publisher.list(),
		Ssp.list(),
		Adserver.list(),
		GlobalSettingsObject.listOne(),
		Reports.call('getCachedSspEntryLabels'),
	]);
	const pubNodes = publishers.map((pub) => new PublisherNode(pub, {
		ssps,
		adservers,
		globalSettings,
	}));
	const sspPlacs = _.filter(_.flatten(pubNodes.map((n) => n.byType.SspPlacementNode)), 'ssp');
	const keyOf = (node) => `${node.ssp.id}_${node.obj.id}`;
	const byKey = _.groupBy(sspPlacs, keyOf);
	const rows = sspPlacs.map((node) => ({
		sspPlacId: node.obj.id,
		sspPlacName: ((labels[`${node.ssp.id}-${node.obj.id}`]) || {}).placementName || UNKNOWN,
		sspName: node.ssp.name,
		placName: node.parentNode.obj.name,
		siteName: node.parentNode.parentNode.obj.domain,
		pubName: node.parentNode.parentNode.parentNode.obj.name,
		duplicates: byKey[keyOf(node)].length > 1 ? 'Yes' : '',
	}));
	const csv = createCsv(rows, {
		pubName: 'Publisher',
		siteName: 'Site',
		placName: 'Placement',
		sspName: 'SSP',
		sspPlacId: 'SSP Placement ID',
		sspPlacName: 'SSP Placement Name',
		duplicates: 'Has duplicates',
	});
	BrowserUtils.downloadTextFile(csv, 'Publishers.csv');
}