import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Reports } from '../../../api/relevant';
import { findNextReportPath } from '../../Sidebar/utils';
import useReports from '../../../hooks/useReports';

function useDeleteReport({
	pathname,
	reportId,
	setStatus,
}) {
	const history = useHistory();
	const { reports, updateReports } = useReports();
	return useCallback(async () => {
		try {
			setStatus('deleting');
			await Reports.call('deleteReport', { id: reportId });
			// Update reports and topbar status
			updateReports();
			setStatus(null);
			// Navigate to next report
			const reportType = pathname.split('/')[2];
			const typeReports = reports[reportType];
			const index = typeReports
				.findIndex(({ id }) => id === reportId);
			const nextPath = findNextReportPath(index, reportType, typeReports);
			history.push(`${nextPath}?status=deleted`);
		} catch (err) {
			setStatus('deleteError');
		}
	}, [history, pathname, reportId, reports, setStatus, updateReports]);
}

export default useDeleteReport;
