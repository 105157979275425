import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import PropTypes from 'prop-types';
import ViewEditReport from '../../components/ViewEditReport';
import { Reports } from '../../api/relevant';
import useRequest from '../../hooks/useRequest/useRequest';
import SimpleOperationWrapper from '../../components/SimpleOperationWrapper';

export default function ReportPage({
	reportId,
	type,
	defaultSettings,
	noSave,
}) {
	const [setRequest, loading, requestError, reset] = useRequest({});
	const [report, setReport] = useState(null);
	const isNew = !reportId || reportId === 'add';

	useEffect(() => {
		// Fetch report
		if (!isNew) {
			setRequest({
				requestFunction: () => Reports.call('getReport', { id: reportId }),
				requestCallback: (res) => {
					setReport(res);
				},
			});
		} else {
			setReport(null);
		}
	}, [reportId, isNew, setRequest]);

	return (
		<Container maxWidth="lg">
			<SimpleOperationWrapper
				loading={loading}
				error={requestError}
				onErrorModalClick={(reset)}
				loadingContainerProps={{
					position: 'static',
				}}
			>
				{ (!loading) && (
					<ViewEditReport
						// Key will force a re-mount when switching reports in sidebar etc,
						// which is something that the report component currently requires.
						key={report?.id || `${type}_new`}
						report={report}
						defaultSettings={defaultSettings}
						isNew={isNew}
						type={type}
						noSave={noSave}
						isActualReport
						warnOldData={!isNew}
					/>
				)}
			</SimpleOperationWrapper>
		</Container>
	);
}

ReportPage.propTypes = {
	defaultSettings: PropTypes.shape({
		tag: PropTypes.string,
		reportLocation: PropTypes.string,
		genericReportLocation: PropTypes.string,
	}),
	noSave: PropTypes.bool,
	reportId: PropTypes.string,
	/**
	 * programmatic, hb, audience, summary_hb, missing_ssp_entries
	 * or log_logTypeId
	 */
	type: PropTypes.string.isRequired,
};

ReportPage.defaultProps = {
	defaultSettings: null,
	noSave: false,
	reportId: undefined,
};
