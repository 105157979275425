const PERCENTAGE = { isPercentage: true };
const NEGATIVE_NUMBER = { isNegativeIfIncrease: true };
const NEGATIVE_PERCENTAGE = { ...PERCENTAGE, ...NEGATIVE_NUMBER };

const hb = {
	unloadBeforeResponse: NEGATIVE_NUMBER,
	avgResponseMs: NEGATIVE_NUMBER,
	avgRenderMs: NEGATIVE_NUMBER,
	timedOut: NEGATIVE_NUMBER,
	timedOutPerc: NEGATIVE_PERCENTAGE,
	renderFailed: NEGATIVE_NUMBER,
	bidRatePerc: PERCENTAGE,
	bidDensityPerc: PERCENTAGE,
	winRatePerc: PERCENTAGE,
	fillRate: PERCENTAGE,
};

const metricSettings = {
	hb,
	summary_hb: {
		...hb,
	},
	future: {
		sellThroughPerc: PERCENTAGE,
	},
	programmatic: {
		revenueChangePerc: PERCENTAGE,
		efficiencyChangePerc: PERCENTAGE,
		ismPerc: PERCENTAGE,
		fillRate: PERCENTAGE,
		sspFillRate: PERCENTAGE,
		relevantShare: PERCENTAGE,
		ctr: PERCENTAGE,
	},
};

export default metricSettings;
