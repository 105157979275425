import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import { Segment } from '../../api/relevant';
import ExternalDataImport from '../ExternalDataImport';
import RuleBox from './ruleBox';
import DatePicker from '../DatePicker';

const INIT_TRANSFORM = {
	name: '',
	type: '',
	group: '',
	groupFormat: 'exact',
	minRelativeMatchFactor: 0,
	minMatchPercent: 0,
	exponent: 0,
	multiplier: 1,
	siteIds: [],
	segmentDmpIds: [],
};

function RuleList({
	fields, getField, model, onAdd, onDelete, onSubmit, title, varName,
}) {
	return (
		<Card>
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h3">
							{title}
						</Typography>
					</Grid>
					{model[varName].map((rule, idx) => (
						<Grid item xs={12} key={idx}>
							<RuleBox
								field={(path) => getField(`${varName}[${idx}].${path}`)}
								onDelete={() => onDelete(idx)}
								path={`${varName}[${idx}]`}
								flds={fields}
								useContainer
								submit={onSubmit}
								rule={rule}
							/>
						</Grid>
					))}
					<Grid item xs={12}>
						<ActionButton
							label="Add new"
							onClick={onAdd}
							color="secondary"
							icon={<AddIcon />}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

RuleList.propTypes = {
	title: PropTypes.string.isRequired,
	varName: PropTypes.string.isRequired,
	fields: PropTypes.arrayOf(PropTypes.string).isRequired,
	getField: PropTypes.func.isRequired,
	model: PropTypes.object.isRequired,
	onAdd: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

class RevenueRules extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			field, model, submit, onCancel, collectionAdd, collectionRemove,
		} = this.props;

		return (
			<Grid container spacing={3}>
				<Grid item xs={3}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Card>
								<ExternalDataImport
									model={model}
									dataCols={[{ key: 'totalRevenue', title: 'Rev shares calculated' }]}
									importFn={(__, from, to) => Segment.call('updateRevShares', { from, to, recalculate: true })}
								/>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Typography variant="h2">
												General settings
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<DatePicker
												{...field('revLockUntil')}
												autoOk
												floatingLabelText="Lock revenue shares until date (inclusive)"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												{...field('minSiteUniques')}
												label="Minium unique site users / day"
												integer
												fullWidth
											/>
										</Grid>
										<Grid item xs={12}>
											<Card>
												<CardContent>
													<Grid container spacing={3}>
														<Grid item xs={12}>
															<Typography variant="h3">
																Default rules
															</Typography>
														</Grid>
														<RuleBox
															field={(p) => field(`defaultTransform.${p}`)}
															flds={['minRelativeMatchFactor', 'minMatchPercent', 'exponent']}
														/>
													</Grid>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Box display="flex" justifyContent="space-around">
								<ActionButton
									label="OK"
									onClick={submit}
									color="primary"
								/>
								<ActionButton
									label="Cancel"
									onClick={onCancel}
									color="primary"
								/>
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<RuleList
						title="Rules by filter"
						fields={['name', 'type', 'group', 'groupFormat', 'minRelativeMatchFactor', 'minMatchPercent', 'exponent', 'trafficTransform']}
						varName="filterTransforms"
						getField={field}
						onSubmit={submit}
						onDelete={(idx) => collectionRemove('filterTransforms', idx)}
						model={model}
						onAdd={() => collectionAdd('filterTransforms', _.cloneDeep(INIT_TRANSFORM))}
					/>
				</Grid>
				<Grid item xs={6}>
					<RuleList
						title="Specific rules by site and/or segment"
						fields={['name', 'type', 'group', 'groupFormat', 'minRelativeMatchFactor', 'minMatchPercent', 'exponent', 'multiplier', 'siteIds', 'segmentDmpIds']}
						varName="specificTransforms"
						getField={field}
						onSubmit={submit}
						onDelete={(idx) => collectionRemove('specificTransforms', idx)}
						model={model}
						onAdd={() => collectionAdd('specificTransforms', _.cloneDeep(INIT_TRANSFORM))}
					/>
				</Grid>
			</Grid>
		);
	}
}

RevenueRules.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	collectionAdd: PropTypes.func.isRequired,
	collectionRemove: PropTypes.func.isRequired,
};

RevenueRules.defaultProps = {
};

export default RevenueRules;
