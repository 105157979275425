import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { PageContentWrapperDiv } from '../../components/Wrappers';
import PricelistEditorWrapper from '../../containers/PricelistEditorWrapper';

class PricelistEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let { id } = this.props.match.params;
		const isNew = id === 'add';
		if (isNew) {
			id = null;
		}
		return (
			<PageContentWrapperDiv>
				<Container>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<PricelistEditorWrapper {...this.props} id={id} />
						</Grid>
					</Grid>
				</Container>
			</PageContentWrapperDiv>
		);
	}
}

PricelistEdit.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

PricelistEdit.defaultProps = {

};

export default PricelistEdit;
