import React, { PropsWithChildren, ReactElement, cloneElement, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button } from './Button/Button';
import clsx from 'clsx';
import FocusTrap from '@mui/material/Unstable_TrapFocus';

export type DialogProps = PropsWithChildren<{
	title?: string;
	text?: string | React.ReactNode;
	open?: boolean;
	closeLabel?: string;
	status?: 'success' | 'info' | 'warning' | 'error';
	buttons?: ((close: () => void) => ReactElement) | ReactElement;
	onClose?: () => void;
}>;

export function Dialog({
	title,
	text,
	open = false,
	closeLabel = 'Ok',
	status,
	buttons,
	onClose,
	...rest
}: DialogProps & React.HTMLAttributes<HTMLDivElement>) {
	const [isOpen, setIsOpen] = useState(open);
	const [render, setRender] = useState(isOpen);
	const [visible, setVisible] = useState(false);
	const div = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	const timeout = useRef<number>();
	useEffect(() => {
		clearTimeout(timeout.current);
		if (isOpen) {
			setRender(true);
			if (!visible) {
				// Delay fade in until after render or else it won't animate
				requestAnimationFrame(() => setVisible(true));
			}
		} else {
			setVisible(false);
			if (render) {
				// Delay removal until fade out is complete
				timeout.current = setTimeout(() => setRender(false), 150);
			}
		}
		return () => clearTimeout(timeout.current);
	}, [isOpen]);

	const handleClose = (e) => {
		e?.preventDefault();
		onClose?.();
		setIsOpen(false);
	}

	useEffect(() => {
		const onKeyDown = (e) => {
			if (!isOpen) return;

			if (e.key === 'Escape') {
				handleClose(e);
			} else if (e.key === 'Enter' && !buttons) {
				handleClose(e);
			}
		};
		document.addEventListener('keydown', onKeyDown);
		return () => document.removeEventListener('keydown', onKeyDown);
	}, [isOpen]);

	return (
		<>
			{render && createPortal(
				(
					<FocusTrap open disableEnforceFocus>
						<div
							ref={div}
							onLoad={() => div.current?.focus()}
							tabIndex={-1}
							className={clsx(
								"fixed top-0 left-0 right-0 bottom-0 z-[2000] bg-black bg-opacity-30 flex items-center justify-center backdrop-blur-sm cursor-default",
								"transition-all duration-150 opacity-0 pointer-events-none",
								visible && 'opacity-100 pointer-events-auto',
							)}
							onClick={handleClose}
							{...rest}
						>
							<div 
								className={clsx(
									"rounded-lg border px-10 py-8 flex flex-col gap-6 cursor-default",
									// Only constrain width if dialog is pure text
									(typeof text === 'string' || text instanceof String) && "max-w-[400px]",
									// Different colors for different statuses, not sure if we want to keep this
									{
										"bg-white border-transparent": !status,
										"bg-success-green-5 border-success-green-60 text-success-green-80": status === 'success',
										"bg-warning-yellow-5 border-warning-yellow-70 text-warning-yellow-80": status === 'warning',
										"bg-failure-red-5 border-failure-red-60 text-failure-red-80": status === 'error',
										"bg-success-blue-5 border-success-blue-60 text-success-blue-80": status === 'info',
									}
								)}
								onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
								<div className="flex flex-col gap-5">
									{title && <div className="text-xl font-medium text-zinc-800">{title}</div>}
									{text}
								</div>
								<div className="flex gap-4 *:flex-1">
									{buttons ? (
										buttons instanceof Function ? buttons(() => handleClose(undefined)) : buttons
									) : (
										<Button onClick={handleClose} variant="contained">{closeLabel}</Button>
									)}
								</div>
							</div>
						</div>
					</FocusTrap>
				),
				document.body
			)}
		</>
	);
}
