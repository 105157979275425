import { useQuery } from '@tanstack/react-query';
import classes from '../../api/classes';
const { CdnFile } = classes;

export function useCdnFileQuery(file: typeof CdnFile) {
	return useQuery<typeof CdnFile>({
		queryKey: ['cdnFile', file.id],
		queryFn: async () => {
			if (file.isNew) {
				const cdnBaseUrl = await CdnFile.callStatic('cdnBaseUrl') as string;
				file.cdnBaseUrl = cdnBaseUrl;
				return file;
			}
			return await CdnFile.get(file.id) as typeof CdnFile;
		},
	});
}

export function useCdnFilesQuery() {
	return useQuery<typeof CdnFile[]>({
		queryKey: ['cdnFiles'],
		queryFn: async () => {
			return await CdnFile.list({ toObjectProfile: 'header' }) as typeof CdnFile[];
		},
	});
}
