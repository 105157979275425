import React from 'react';
import Disabler from './Disabler';
import { clsx } from 'clsx';
import { Dialog } from './Dialog';
import { CircularProgress } from '@mui/material';

// Wrap a component with a loading spinner overlay, intended for use in a declarative way with React Query
export function LoadingWrapper({ 
	loading,
	error,
	transparent,
	onAfterErrorMsg,
	children,
}: {
	loading?: boolean
	error?: string
	transparent?: boolean
	onAfterErrorMsg?: () => void
	children: React.ReactNode
}) {
	return (
		<>
			<Disabler disabled={loading || error}>
				<div className={clsx(
					'relative',
					!transparent && 'invisible'
				)}>
					{children}
				</div>
			</Disabler>
			{loading && !error && (
				<div className="absolute inset-0 bg-white bg-opacity-50 z-50 flex items-center justify-center">
					<CircularProgress size={100} />
				</div>
			)}
			<Dialog
				open={!!error}
				text={error }
				status="error"
				onClose={() => {
					onAfterErrorMsg?.();
				}}
			/>
		</>
	);
}