import React from 'react';

import Grid from '@mui/material/Grid';
import storeContainer from '../storeContainer';
import DateUtils from '../../lib/dateUtils';
import * as relevantApi from '../../api/relevant';
import JobButton from '../../components/JobButton';
import DailyTaskStatuses from '../../components/DailyTaskStatuses';
import OperationWrapper from '../../components/OperationWrapper';
import SystemData from '../../lib/systemData';
import DatePicker from '../../components/DatePicker';

const TYPES = [
	{ name: 'Dsp', taskName: 'DSPimport' },
	{ name: 'Ssp', taskName: 'SSPimport' },
	{ name: 'Adserver', taskName: 'AdserverImport' },
];

const relevant = { ...relevantApi.DynamicExports, ...relevantApi };

@storeContainer()
class LogsContainer extends React.Component {
	constructor(props) {
		super(props);
		const now = new Date();
		this.initStartDate = DateUtils.fullDay(now, -14);
		this.initEndDate = DateUtils.fullDay(now, -1);
		this.state = { importFrom: this.initStartDate };
	}

	render() {
		const { data, importFrom } = this.state;
		const fld = (name) => ({ name, value: this.state[name], onChange: (ev) => this.setState({ [name]: ev.target.value }) });
		return (
			<OperationWrapper fn={async (op) => {
				this.op = op;
				const typeData = [];
				await Promise.all([].concat(...TYPES.map((type, i) => {
					if (SystemData.genericData.disableAudience && type.name === 'Dsp') {
						return [];
					}
					typeData[i] = { name: type.name };
					const dst = typeData[i];
					return [
						(async () => {
							dst.types = await relevant[type.name].call('getAll');
						})(),
						(async () => {
							dst.data = await relevant.Tasks.call('getTasks', {
								start: this.initStartDate,
								end: this.initEndDate,
								type: type.taskName,
							});
						})(),
					];
				})));
				this.setState({ data: typeData });
			}}
			>
				{data
				&& (
					<Grid container spacing={3} alignItems="center">
						{data.map((d) => (
							<Grid key={d.name} item xs={12}>
								<DailyTaskStatuses
									title={`${d.name} Daily Task Status`}
									data={d.data}
									types={d.types}
									from={this.initStartDate}
									to={this.initEndDate}
								/>
							</Grid>
						))}
						<Grid item>
							<DatePicker
								floatingLabelText="Import from"
								maxDate={DateUtils.yesterday()}
								autoOk
								{...fld('importFrom')}
							/>
						</Grid>
						{data.map((d) => (
							<Grid key={d.name} item>
								<JobButton
									label={`${d.name} re-import`}
									fn={async () => {
										await relevant[d.name].call('importAll', {
											from: importFrom,
											to: this.initEndDate,
										});
										this.op.reload();
									}}
								/>
							</Grid>
						))}
					</Grid>
				)}
			</OperationWrapper>
		);
	}
}

LogsContainer.propTypes = {

};

LogsContainer.defaultProps = {

};

export default LogsContainer;
