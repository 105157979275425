import storeContainer from '../storeContainer';

const withIdentity = () => storeContainer((stores) => ({
	isAuthenticated: () => stores.identity.isAuthenticated(),
	isAdministrator: () => stores.identity.isAdministrator(),
	isSuperAdministrator: () => stores.identity.isSuperAdministrator(),
	isProgrammaticPublisher: () => stores.identity.isProgrammaticPublisher(),
	isAudiencePublisher: () => stores.identity.isAudiencePublisher(),
	user: () => stores.identity.value,
	logout: () => stores.identity.logout(),
	tryLogin: (email, password, oauth) => stores.identity.tryLogin(email, password, oauth),
	startGhosting: (id) => stores.identity.startGhosting(id),
	stopGhosting: () => stores.identity.stopGhosting(),
	isGhosting: () => stores.identity.isGhosting(),
	isNoUser: () => stores.identity.isNoUser(),
}));

export default withIdentity;
