import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Select,
	MenuItem,
	Box,
	Checkbox,
	ListItemText,
} from '@mui/material';
import { listOfAdministrators } from '../../../api/relevant';
import { stores } from '../../../stores';
import { ConfirmDialog } from '../../ConfirmDialog';
import { Button } from '../../Button/Button';

const availableOccurences = [
	'daily',
	'weekly',
	'monthly',
	'yearly',
];

function SubscriptionForm({ hasDynamicDateRange, onAddSubscription }) {
	const currentUser = stores.identity.user();
	const [occurences, setOccurences] = useState(['daily']);
	const [recipients, setRecipients] = useState([currentUser.id]);
	const [users, setUsers] = useState([]);

	useEffect(() => {
		const getAdmins = async () => {
			const { result } = await listOfAdministrators();
			const admins = result.map((availableUser) => ({
				id: availableUser.id,
				firstName: availableUser.firstname,
				lastName: availableUser.lastname,
				email: availableUser.email,
			}));
			setUsers(admins);
		};
			// Admins can add subscriptions to themselves and other admins
		if (stores.identity.isAdministrator()) {
			getAdmins();
		} else { // Normal users can only add subscriptions to themselves
			setUsers([{
				id: currentUser.id,
				firstName: currentUser.firstname,
				lastName: currentUser.lastname,
				email: currentUser.email,
			}]);
		}
	}, [currentUser.email, currentUser.firstname, currentUser.id, currentUser.lastname]);

	const onSubmit = () => {
		const resRecipients = recipients.map((id) => {
			const user = users.find(({ id: userId }) => userId === id);
			return {
				userId: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
			};
		});
		const newSubscription = {
			recipients: resRecipients,
			occurences: occurences.map((occurence) => ({ type: occurence })),
			addedByUserId: currentUser.id,
		};
		onAddSubscription(newSubscription);
	};

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			flexWrap: 'wrap',
		}}
		>
			<Box sx={{ whiteSpace: 'nowrap', mt: 2 }}>Send an email</Box>
			<Box
				sx={{
					ml: 1,
					mr: 1,
				}}
			>
				<Select
					sx={{
						minWidth: '132px',
						textTransform: 'capitalize',
						mt: 2,
					}}
					value={occurences}
					onChange={({ target }) => setOccurences(target.value)}
					renderValue={(selected) => {
						if (selected.length > 1) {
							return `${selected[0]} + ${selected.length - 1}`;
						}
						return selected[0];
					}}
					multiple
				>
					{ availableOccurences.map((occurence) => (
						<MenuItem
							key={occurence}
							value={occurence}
						>
							<Checkbox checked={occurences.indexOf(occurence) !== -1} />
							<ListItemText
								primary={occurence}
								sx={{ textTransform: 'capitalize' }}
							/>
						</MenuItem>
					))}
				</Select>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
				<Box sx={{ mr: 1, ml: { xs: '86px', sm: 0 } }}>to</Box>
				<Select
					sx={{ minWidth: '126px', maxWidth: '188px' }}
					value={recipients}
					onChange={({ target }) => setRecipients(target.value)}
					renderValue={(selected) => {
						const user = users.find(({ id }) => id === selected[0]);
						if (selected.length > 1) {
							return `${user.firstName} + ${selected.length - 1}`;
						}
						return user?.firstName;
					}}
					multiple
				>
					{ users.map(({ id, firstName, lastName }) => {
						const fullName = `${firstName} ${lastName}`;
						return (
							<MenuItem
								key={id}
								value={id}
							>
								<Checkbox checked={recipients.indexOf(id) !== -1} />
								<ListItemText primary={fullName} />
							</MenuItem>
						);
					})}
				</Select>
			</Box>
			<Box sx={{ ml: 1.5, mt: 2 }}>
				{ !hasDynamicDateRange() ? (
					<ConfirmDialog
						text="Your report has fixed dates,
 						 which means that you will receive the same report in every email.
						 Are you sure you want to continue?"
						onConfirm={onSubmit}
					>
						<Button disabled={!recipients.length || !occurences.length}>
							Add
						</Button>
					</ConfirmDialog>
				) : (
					<Button
						disabled={!recipients.length || !occurences.length}
						onClick={onSubmit}
					>
						Add
					</Button>
				)}
			</Box>
		</Box>
	);
}

SubscriptionForm.propTypes = {
	hasDynamicDateRange: PropTypes.func.isRequired,
	onAddSubscription: PropTypes.func.isRequired,
};

export default SubscriptionForm;
