import React, { useRef, useState } from 'react';
import {
	Box,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
	Tooltip,
	TablePagination,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import PropTypes from 'prop-types';

function CsvTable({
	rows,
	headers,
	dataIssues,
}) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const currentPageRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	return (
		<>
			<Box
				sx={{
					position: 'relative',
					pt: 3,
					pr: 3,
					pl: 3,
					overflow: 'auto',
					// Scroll shadow
					background: `linear-gradient(to right, #fff 30%, rgba(255,255,255,0)),
						linear-gradient(to right, rgba(255,255,255,0), #fff 70%) 100% 0,
						radial-gradient(farthest-side at 0 50%, rgba(0,0,0,0.4), rgba(0,0,0,0)),
						radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,0.4), rgba(0,0,0,0)) 100% 0;`,
					backgroundPosition: 'left center, right center, left center, right center',
					backgroundRepeat: 'no-repeat',
					backgroundColor: 'white',
					backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
					backgroundAttachment: 'local, local, scroll, scroll',
				}}
			>
				<Table
					sx={{
						minWidth: 650,
					}}
					aria-label="table to import"
				>
					<TableHead>
						<TableRow>
							{ Object.entries(headers).map(([key, header]) => (
								<TableCell
									component="th"
									sx={{
										textAlign: 'left',
									}}
									key={key}
								>
									<Box sx={{ width: 150 }}>
										{header}
									</Box>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{currentPageRows.map((row, index) => (
							<TableRow
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								{Object.keys(headers).map((key) => {
								// Calculate "real" index since we're paginating stuff
									const realIndex = index + page * rowsPerPage;
									const issues = (dataIssues[realIndex] || {})[key];
									const errors = issues?.filter((issue) => issue.type === 'error');
									const warnings = issues?.filter((issue) => issue.type === 'warning');
									// Only display first issue in array
									let errorsToDisplay;
									let warningsToDisplay;
									if (errors?.length > 0) {
										errorsToDisplay = `${errors[0].message}${errors.length > 1 ? ` + ${errors.length - 1} more` : ''}`;
									}
									if (warnings?.length > 0) {
										warningsToDisplay = `${warnings[0].message}${warnings.length > 1 ? ` + ${warnings.length - 1} more` : ''}`;
									}
									return (
										<TableCell
											key={key}
											component="td"
											scope="row"
											sx={{
												textAlign: 'left',
											}}
											title={row[key]}
										>
											<Box sx={{
												display: 'flex',
												alignItems: 'center',
												width: 150,
											}}
											>
												<Box sx={{
													mr: 1,
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
												>
													{ row[key] ? row[key] : 'N/A' }
												</Box>
												<Box sx={{ display: 'flex', alignItems: 'center' }}>
													{errorsToDisplay && (
														<Tooltip title={errorsToDisplay}>
															<ErrorIcon sx={{ color: 'error.main' }} />
														</Tooltip>
													)}
													{warningsToDisplay && (
														<Tooltip title={warningsToDisplay}>
															<WarningIcon sx={{ color: 'warning.main' }} />
														</Tooltip>
													)}
												</Box>
											</Box>
										</TableCell>
									);
								})}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Box>
			<TablePagination
				sx={{
					pr: 2,
				}}
				component="div"
				count={rows.length}
				page={page}
				rowsPerPage={rowsPerPage}
				onPageChange={(__, newPage) => {
					setPage(newPage);
				}}
				onRowsPerPageChange={(e) => {
					setRowsPerPage(e.target.value);
					setPage(Math.floor((page * rowsPerPage) / e.target.value));
				}}
			/>
		</>
	);
}

CsvTable.propTypes = {
	rows: PropTypes.arrayOf(PropTypes.object).isRequired,
	headers: PropTypes.objectOf(PropTypes.string).isRequired,
	dataIssues: PropTypes.objectOf(PropTypes.shape({
		type: PropTypes.oneOf(['error', 'warning']),
		message: PropTypes.string,
		error: PropTypes.string,
	})).isRequired,
};

export default CsvTable;
