import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { ActionButton } from '../ActionButton/ActionButton';
import UploadButton from '../UploadButton';
import Base from '../../layouts/Base';
import MiscUtils from '../../lib/miscUtils';
import { FormOf } from '../Wrappers';
import PopupSelector from '../PopupSelector';
import Checkbox from '../Checkbox';
import Select from '../Select';
import { Dialog } from '../Dialog';

class CsvImport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	async importFromCsvFileInternal(file) {
		const {
			colSelectors, onCsvImported, renderBefore, renderAfter,
		} = this.props;
		const reader = new FileReader();
		const read = new Promise((resolve) => {
			reader.onload = (ev) => resolve(ev.target.result);
		});
		reader.readAsText(file);
		const txt = await read;
		let cols;
		const csvData = MiscUtils.loadCsv(txt, (headers) => {
			cols = headers.map((c) => c.trim()).filter((c) => c).map((c, idx) => ({ key: `key_${idx}`, header: c }));
			const csvCols = _.zipObject(cols.map(({ key }) => key), cols.map(({ header }) => header));
			return csvCols;
		});
		if (!cols.length) {
			throw Error('No columns');
		}
		const settings = {};
		let importTriggered;
		Base.renderGlobal((done) => (
			<FormOf
				model={settings}
				content={({ field, form }) => (
					<PopupSelector
						size="xs"
						forceExpanded
						allowErrorsIfCancel
						onApplyChanges={async () => {
							if (importTriggered) {
								return;
							}
							importTriggered = true;
							const data = csvData.map((row) => {
								const newRow = {};
								colSelectors.forEach(({ name }) => {
									newRow[name] = row[settings[name]];
								});
								return newRow;
							});
							await onCsvImported(data, settings);
							done();
						}}
						onCancel={done}
						form={form}
					>
						{!!renderBefore && renderBefore({ field })}
						{colSelectors.map(({
							name, label, required, showIf,
						}) => !!(!showIf || showIf(settings)) && (
							<Select
								key={name}
								{...field(name)}
								label={label}
								nonSelected="(none)"
								required={required}
								items={cols
									.filter(({ key }) => !colSelectors.find((c) => c.name !== name && settings[c.name] === key))
									.map(({ key, header }) => ({ label: header, value: key }))}
								fullWidth
								margin="normal"
							/>
						))}
						{!!renderAfter && renderAfter({ field })}
					</PopupSelector>
				)}
			/>
		));
	}

	async importFromCsvFile(file) {
		try {
			await this.importFromCsvFileInternal(file);
		} catch (e) {
			Base.renderGlobal((done) => (
				<Dialog
					open
					status="error"
					text={`Error loading .csv: ${MiscUtils.errorMsg(e)}`}
					onClose={done}
				/>
			));
		}
	}

	render() {
		return (
			<UploadButton
				color="secondary"
				accept=".csv"
				onChange={(ev) => this.importFromCsvFile(ev.target.files[0])}
			>
				Import from .csv
			</UploadButton>
		);
	}
}

CsvImport.propTypes = {
	label: PropTypes.string,
	renderBefore: PropTypes.func,
	renderAfter: PropTypes.func,
	colSelectors: PropTypes.array.isRequired,
	onCsvImported: PropTypes.func.isRequired,
};

CsvImport.defaultProps = {
	renderBefore: undefined,
	renderAfter: undefined,
	label: 'Upload .csv',
};

export default CsvImport;
