import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Issue from './Issue';
import { Button } from '../../../components/Button/Button';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import { useToast } from '../../../hooks/useToast';
import { useOperationToast } from '../../../hooks/useOperationToast';

function IssueList({ issues, onIgnore }) {
	return (
		<Box sx={{
			maxHeight: 304,
			overflow: 'auto',
		}}
		>
			{issues.map((issue, index) => (
				<Box
					key={index}
					sx={{
						display: 'flex',
						alignItems: 'center',
						pt: 1.5,
						pb: 1.5,
						borderTop: '1px solid #E0E0E0',
					}}
				>
					<Issue
						header={issue.header}
						description={issue.description}
						detailedDescription={issue.detailedDescription}
						borderColor={issue.type === 'error' ? '#ef5350' : '#ffa726'}
					/>
					{(true || issue.type === 'error' && issue.error === 'DUPLICATED_SSP_PLACEMENT') && (
						<ConfirmDialog
							text={(
								<div className="max-w-lg">
									<p className="my-4">
										This SSP placement already exists elsewhere.
										If you decide to ignore it, you will have a duplicate of this SSP placement.
									</p>
									<p className="my-4">
										You can also choose to ignore all instances of this error, or all in the same column.
										This means you will have duplicates for all of the SSP placements related to the errors you choose to ignore.
									</p>
								</div>
							)}
							buttons={(close) => (
								<>
									<Button
										variant="text"
										onClick={close}
									>
										Cancel
									</Button>
									<Button
										variant="outlined"
										onClick={() => {
											const duplicatedSspPlacements = issues
												.filter((i) => (
													i.error === 'DUPLICATED_SSP_PLACEMENT'
												));
											onIgnore(duplicatedSspPlacements);
											close();
										}}
									>
										Ignore all
									</Button>
									<Button
										variant="outlined"
										onClick={() => {
											const duplicatedSspPlacements = issues
												.filter((i) => {
													return i.key === issue.key;
												})
											onIgnore(duplicatedSspPlacements);
											close();
										}}
									>
										Ignore column
									</Button>
									<Button
										variant="contained"
										onClick={() => {
											onIgnore([issue]);
											close();
										}}
									>
										Ignore
									</Button>
								</>
							)}
						>
							<Button variant="text">Ignore</Button>
						</ConfirmDialog>
					)}
				</Box>
			))}

		</Box>
	);
}

IssueList.propTypes = {
	issues: PropTypes.arrayOf(PropTypes.shape({
		header: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		type: PropTypes.oneOf(['error', 'warning']).isRequired,
		error: PropTypes.string,
		property: PropTypes.string,
	})).isRequired,
	onIgnore: PropTypes.func.isRequired,
};

export default IssueList;
