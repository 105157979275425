import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import {
	fmtDate, isAcked, renderAckErrString, renderStatus, execStatusCol,
} from './utils';
import { CheckActions } from './actions';
import DataTableWithMemory from '../DataTable/dataTableWithMemory';
import PopupSelector from '../PopupSelector';
import CheckHistory from './checkHistory';
import Icon from '../Icon';
import { CELL_DEFAULT_STYLE } from './constants';
import TriggerActionButton from './triggerActionButton';

function TriggerCheckList({
	workspace, checks, selectedChecks, onSelectionChanged,
}) {
	let ackErrMsg;
	let recoveredMsg;
	if (!workspace.initialLoadDone()) {
		const msg = <b style={{ color: 'gray' }}>Loading...</b>;
		ackErrMsg = msg;
		recoveredMsg = msg;
	} else {
		ackErrMsg = renderAckErrString(checks);
		recoveredMsg = _.reject(checks, { status: 'failed' }).length || 'NONE';
	}
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item>
						<i>Total Acknowledged/Errors:</i>
						{'\xa0'.repeat(3)}
						<b>{ackErrMsg}</b>
						<br />
						<i>Recently recovered:</i>
						{'\xa0'.repeat(18)}
						<b style={{ color: 'green' }}>
							{recoveredMsg}
						</b>
					</Grid>
					{CheckActions.filter((a) => !a.hideInTop).map((a) => {
						const matches = selectedChecks.filter((check) => !a.cond || a.cond({
							check,
							workspace,
						}));
						return <TriggerActionButton key={a.name} matches={matches} action={a} workspace={workspace} />;
					})}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<DataTableWithMemory
					showCheckboxes
					identifier={(row) => row.id}
					onChange={(selected) => onSelectionChanged(selected)}
					selected={selectedChecks}
					cellDefaultStyle={CELL_DEFAULT_STYLE}
					definitions={[ // Active | Is private | Owner|  Ack/Err | Actions (check report)
						{
							key: 'humanIdentifier',
							title: 'Info',
						},
						{
							key: 'triggerId',
							title: 'Alarm',
							format: (triggerId) => workspace.triggerOf(triggerId).name,
						},
						{
							key: 'id',
							title: 'Status',
							format: (__, check) => renderStatus(check),
							style: { width: '60px' },
						},
						execStatusCol({ workspace }),
						{
							key: 'date',
							title: 'Date',
							format: (date, check) => (
								<PopupSelector
									title={fmtDate(date)}
									size="sm"
									content={() => (
										<CheckHistory
											check={check}
											workspace={workspace}
											dataTableProps={{ cellDefaultStyle: CELL_DEFAULT_STYLE }}
										/>
									)}
								/>
							),
						},
						{
							key: 'id',
							title: 'Acknowledged',
							format: (__, check) => (check.status === 'failed'
								&& (
									<span style={{ color: isAcked(check) ? 'orange' : 'red' }}>
										{`${isAcked(check) ? 'Acknowledged' : 'No'} ${check.autoAck ? '(AUTO)' : ''}`}
									</span>
								)
							),
						},
						{
							key: 'id',
							title: 'Actions',
							format: (id, check) => CheckActions.filter((a) => !a.cond || a.cond({
								check,
								workspace,
							})).map((a) => (
								<Icon
									pic={a.icon}
									tooltip={a.name}
									key={a.name}
									onClick={() => workspace.performAction(a, [check])}
								/>
							)),
						},
					].filter(({ hide }) => !hide)}
					data={workspace.getVisibleChecks()}
				/>
			</Grid>
		</Grid>
	);
}

TriggerCheckList.propTypes = {
	workspace: PropTypes.object.isRequired,
	checks: PropTypes.array.isRequired,
	selectedChecks: PropTypes.array.isRequired,
	onSelectionChanged: PropTypes.func.isRequired,
};

TriggerCheckList.defaultProps = {
};

export default TriggerCheckList;
