import React from 'react';
import _ from 'lodash';
import storeContainer from '../storeContainer';
import TableOfAdministrators from '../../components/TableOfAdministrators';
import { listOfAdministrators, deleteUser } from '../../api/relevant';
import Spinner from '../../components/Spinner';

@storeContainer()
class AdministratorCollection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			administrators: null,
		};
		this.deleteAdministrator = this.deleteAdministrator.bind(this);
	}

	componentDidMount() {
		this.fetchAdministrators();
	}

	async fetchAdministrators() {
		const administrators = (await listOfAdministrators()).result;
		this.setState({ administrators: _.sortBy(administrators, 'fullname') });
	}

	async deleteAdministrator(id) {
		try {
			await deleteUser(id);
			this.fetchAdministrators();
		} catch (e) { console.log(e); }
	}

	render() {
		const { administrators } = this.state;
		return administrators ? <TableOfAdministrators administrators={administrators} deleteRow={this.deleteAdministrator} /> : <Spinner />;
	}
}

AdministratorCollection.propTypes = {
};

AdministratorCollection.defaultProps = {
};

export default AdministratorCollection;
