import React, { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ForwardIcon from '@mui/icons-material/Forward';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import storeContainer from '../storeContainer';
import Spinner from '../../components/Spinner';
import DspSettingsForm from '../../components/DspSettingsForm';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import JobButton from '../../components/JobButton';
import {
	Dsp,
	Pricelist,
	Segment,
	SiteProperties,
	Reports,
} from '../../api/relevant';
import { DspList } from '../../components/SystemLists';
import { DspEdit } from '../../pages/SimplePages';
import withUseRequestHook from '../../hooks/useRequest/wrapper';

@storeContainer()
class DspSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.onSystemsChange = this.onSystemsChange.bind(this);
	}

	componentDidMount() {
		this.fetchApplicationSettings();
	}

	async fetchApplicationSettings() {
		const newState = { loaded: true };
		await Promise.all([
			(async () => { newState.dsps = await Dsp.list(); })(),
			(async () => { newState.pricelists = await Pricelist.list(); })(),
		]).then(() => {
			this.setState(newState);
		});
	}

	async submitApplicationSettings(model) {
		console.log('Submitting.. but what exactly..? ', model);
	}

	async deleteDsp(dsp) {
		await dsp.delete();
		this.fetchApplicationSettings();
	}

	async deletePricelist(pricelist) {
		await pricelist.delete();
		this.fetchApplicationSettings();
	}

	onSystemsChange() {
		this.props.setRequest({
			requestFunction: () => Dsp.list(),
			requestCallback: (dsps) => (
				this.setState({ dsps })
			),
		});
	}

	render() {
		if (!this.state.loaded) return <Spinner delay />;
		return (
			<>
				<Route
					exact
					path={`${this.props.match.path}/dsps/:id`}
					render={(props) => <DspEdit {...props} onChange={this.onSystemsChange} />}
				/>
				<Grid container spacing={3}>
					<Grid item lg={5} md={12} sm={12} xs={12}>
						<DspList list={this.state.dsps} onSystemsChange={this.onSystemsChange} />
					</Grid>
					<Grid item lg={5} md={8} sm={12} xs={12}>
						<DspSettingsForm
							model={this.state}
							deleteDsp={(dsp) => this.deleteDsp(dsp)}
							deletePricelist={(pricelist) => this.deletePricelist(pricelist)}
							onSubmit={(model) => this.submitApplicationSettings(model)}
						/>
					</Grid>
					<Grid item lg={2} md={4} sm={12} xs={12}>
						<Paper>
							<Box
								display="flex"
								alignItems="center"
								padding={2}
							>
								<SettingsIcon />
								{' '}
								<Typography variant="h4">
									Actions
								</Typography>
							</Box>
							<Divider />
							<Box padding={2}>
								<Grid container direction="column" spacing={3}>
									<Grid item>
										<ActionButton
											label="Edit segments"
											onClick={() => this.props.history.push('/settings/segment-list')}
											icon={<ForwardIcon />}
											color="primary"
										/>
									</Grid>
									<Grid item>
										<ActionButton
											label="Edit revenue rules"
											onClick={() => this.props.history.push('/settings/revenue-rules')}
											icon={<ForwardIcon />}
											color="primary"
										/>
									</Grid>
									<Grid item>
										<ActionButton
											label="My segments"
											onClick={() => this.props.history.push('/settings/my-segments')}
											icon={<ForwardIcon />}
											color="primary"
										/>
									</Grid>
									<Grid item>
										<ActionButton
											label="Audience Tag Fields"
											onClick={() => this.props.history.push('/settings/audience-tag-fields')}
											icon={<ForwardIcon />}
											color="primary"
										/>
									</Grid>
									<Grid item>
										<ActionButton
											label="Master Audience Tag Data"
											onClick={() => this.props.history.push('/settings/tag-data-edit?type=audienceMasterTag')}
											icon={<ForwardIcon />}
											color="primary"
										/>
									</Grid>
									<Grid item data-debug-id="refreshDmpSegments">
										<JobButton
											label="Refresh DMP segments"
											fn={Segment.fn('refresh')}
											color="secondary"
										/>
									</Grid>
									<Grid item>
										<JobButton
											label="Update all site properties"
											fn={() => SiteProperties.call('updateAllSiteProperties', { onlyWhenNeeded: true })}
											color="secondary"
										/>
									</Grid>
									<Grid item>
										<JobButton
											label="Clear report cache"
											fn={() => Reports.call('invalidateCache', {
												forceEvenWhenUnnecessary: true,
											})}
											color="secondary"
										/>
									</Grid>
								</Grid>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</>
		);
	}
}

DspSettings.propTypes = {
	history: PropTypes.object.isRequired,
};

DspSettings.defaultProps = {

};

export default withUseRequestHook(DspSettings);
