exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".systemTypeHeader__src-components-BidParamEdit-styles__3yXb7 {\n    opacity: 0.54;\n    padding: 12px 0 12px 1px; /* 1px to left to account for accordions shadows */\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/digitalenterprises/temp/relevant-yield/relevant-frontend/src/components/BidParamEdit/styles.css"],"names":[],"mappings":"AAAA;IACI,cAAa;IACb,yBAAwB,CAAE,mDAAkD;CAChF","file":"styles.css","sourcesContent":[".systemTypeHeader {\n    opacity: 0.54;\n    padding: 12px 0 12px 1px; /* 1px to left to account for accordions shadows */\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"systemTypeHeader": "systemTypeHeader__src-components-BidParamEdit-styles__3yXb7"
};