import PropTypes from 'prop-types';
import React from 'react';
// import styles from './styles.css';
import SectionHeader from '../SectionHeader';

class State extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { title, children, componentTitle } = this.props;

		return (
			<div>
				<SectionHeader>
					{componentTitle}
					{' '}
					<span style={{ color: '#999' }}>{title}</span>
				</SectionHeader>
				{children}
			</div>
		);
	}
}

State.propTypes = {
	title: PropTypes.string,
	componentTitle: PropTypes.string,
	children: PropTypes.node,
};

State.defaultProps = {

};

export default State;
