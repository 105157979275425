/* eslint no-param-reassign: 0 */
class ReactUtils {
	static fldMap(obj, fldFn, name, fn) {
		return (obj[name] || []).map((elm, i, arr) => fn(elm, (path) => fldFn(`${name}[${i}].${path}`), i, arr));
	}

	static fldMapObj(obj, fldFn, name, fn) {
		if (!fn) {
			fn = name;
			name = null;
		}
		let fldPfx = '';
		if (name) {
			obj = obj[name];
			fldPfx = `${name}.`;
		}
		return Object.entries(obj).map(([key, val]) => fn(val, (path) => fldFn(`${fldPfx}${key}.${path}`), key));
	}

	static subFld(field, name) {
		return (path) => field(`${name}.${path}`);
	}

	static fld(component) {
		return (name) => ({ name, value: component.state[name], onChange: (ev) => component.setState({ [name]: ev.target.value }) });
	}
}

export default ReactUtils;
