import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import AddIcon from '@mui/icons-material/Add';
import styles from './styles.css';
import AdministratorCollection from '../../containers/AdministratorCollection';
import { ActionButton } from '../../components/ActionButton/ActionButton';

class AdminList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className={styles.pageContentWrapper}>
				<Container>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TableContainer component={Paper}>
								<AdministratorCollection />
							</TableContainer>
						</Grid>
						<Grid item xs={12}>
							<ActionButton
								color="primary"
								label="New administrator"
								icon={<AddIcon />}
								href="/administrators/add"
							/>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}
}

AdminList.propTypes = {

};

AdminList.defaultProps = {

};

export default AdminList;
