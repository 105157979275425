function getFlattenedErrorsAndWarnings(dataIssues) {
	const dataErrors = [];
	const dataWarnings = [];

	Object.entries(dataIssues).forEach(([rowNum, issues]) => {
		const rowNumInt = parseInt(rowNum, 10) + 1;

		Object.entries(issues)
			.forEach(([key, entryIssues]) => {
				entryIssues.forEach((issue) => {
					if (issue.type === 'error') {
						dataErrors.push({
							type: issue.type,
							header: `Row ${rowNumInt} -  ${key}`,
							description: issue.message,
							detailedDescription: issue.detailedMessage,
							error: issue.error,
							property: issue.property,
							row: rowNumInt,
							key,
						});
					} else {
						dataWarnings.push({
							type: issue.type,
							header: `Row ${rowNumInt}`,
							description: issue.message,
							detailedDescription: issue.detailedMessage,
							error: issue.error,
							property: issue.property,
							row: rowNumInt,
							key,
						});
					}
				});
			});
	});
	return [dataErrors, dataWarnings];
}

export { getFlattenedErrorsAndWarnings };
