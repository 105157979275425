import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import DataTable from '../DataTable';

class ImportStatsTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		// eslint-disable-next-line react/destructuring-assignment
		const data = this.props.data || {};
		const { err } = data;
		const def = (props) => ({
			style: {
				whiteSpace: 'nowrap',
			},
			format: (date) => (data[props.key] ? moment(date).format('YYYY-MM-DD HH:mm') : ''),
			...props,
		});
		const definitions = [
			def({
				key: 'lastReportSync',
				title: 'HB Synced to',
			}),
			def({
				key: 'lastUpdated',
				title: 'Last update',
			}),
			def({
				key: 'approxByPath',
				title: 'Approx. updated',
			}),
			def({
				key: 'lineItems',
				title: 'LIs updated',
			}),
			def({
				key: 'lineItemCount',
				title: 'LIs',
				format: (nr) => _.isNumber(nr) ? nr : '',
			}),
			def({
				key: 'err',
				title: 'Last error',
				style: { width: '200px' },
				format: () => <span style={{ color: err ? 'red' : 'green' }}>{err || 'NONE'}</span>,
			}),
		];
		return (
			<Grid item xs={12}>
				<Paper>
					<Box padding={2}>
						<Grid container spacing={3} justifyContent="space-between">
							<Grid item>
								<Typography variant="h2">
									Import stats
								</Typography>
							</Grid>
							{data && (
								<Grid item xs={12}>
									<DataTable
										showCheckboxes={false}
										selectableRows={false}
										identifier={(row) => `${row.date}_${row.ip}`}
										definitions={definitions}
										data={[
											{
												...Object.fromEntries(definitions.map(({ key }) => ([key, '-']))),
												...data,
											},
										]}
									/>
								</Grid>
							)}
						</Grid>
					</Box>
				</Paper>
			</Grid>
		);
	}
}

ImportStatsTable.propTypes = {
	data: PropTypes.object,
};

ImportStatsTable.defaultProps = {
	data: undefined,
};

export default ImportStatsTable;
