function modulesMatch(built, expected) {
	if (built.length !== expected.length) {
		return false;
	}
	return built.every((module) => expected.indexOf(module) !== -1);
}

function getBuildStatus(buildConfig, statuses, expectedModules) {
	// If there are multiple builds going on for the same build config,
	// show the last created one. So start by sorting by 'created'
	const sortedStatuses = statuses.sort((a, b) => (
		new Date(b.created) - new Date(a.created)
	));
	// Then check if status is in active builds
	let { status } = sortedStatuses.find(({ configId }) => configId === buildConfig.id) || {};
	if (!status) {
		const builtModules = buildConfig.currentBuildModules;
		// If not in active builts, check if its been built previously or if versions mismatch
		if (!buildConfig.built) {
			status = 'FAILED';
		} else if (modulesMatch(builtModules, expectedModules)
			// If built, check if the modules and versions match
			&& buildConfig.version === buildConfig.currentVersion) {
			status = 'SUCCESS';
		} else { // If not, something has gone wrong
			status = 'FAILED';
		}
	}
	return status;
}

export default function generateTableData(buildConfigs, buildStatuses, autoIncludedModules) {
	const statuses = Object.values(buildStatuses);
	return buildConfigs.map((config) => {
		const expectedModules = [...config.additionalModules, ...(autoIncludedModules[config.version] || [])];
		const status = getBuildStatus(config, statuses, expectedModules);
		return {
			status,
			expectedModules,
			...config,
		};
	});
}
