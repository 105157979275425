import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/Autocomplete';
import DoneIcon from '@mui/icons-material/Done';
import AutocompleteInput from '../AutocompleteInput';
import styles from './styles.css';
import {Chip} from '@mui/material';

function AnalyticsSystemMapper({
	profiles, profileOptions, analyticsSystemId, onChange,
}) {
	if (profileOptions.length === 0) {
		return null;
	}
	const ga4Style = (str, isGa4) => (isGa4
		? (
			<>
				<span style={{ color: 'green', fontWeight: 'bold' }}>[GA4] </span>
				{str}
			</>
		)
		: str);

	return (
		<AutocompleteInput
			id={analyticsSystemId}
			suggestions={profileOptions}
			onChange={onChange}
			value={profiles}
			autocompleteProps={{
				multiple: true,
				filterOptions: createFilterOptions({
					stringify: (option) => (`${option.name}${option.profileId}${option.url}`),
				}),
				renderInput: (params) => (
					<TextField {...params} label="Analytics profiles" />
				),
				renderOption: (subProps, option, { selected }) => (
					<Box
						{...subProps}
						component="div"
						width={1}
						display="flex"
						alignItems="center"
						justifyContent="space-between"
					>
						<div>
							<div title="Profile name">{ga4Style(option.name, option.propertyId)}</div>
							<div className={styles.optionSubText} title="Profile ID">{option.profileId}</div>
							<div className={styles.optionSubText} title="Profile url">{option.url}</div>
						</div>
						<div>
							{ selected && <DoneIcon /> }
						</div>
					</Box>
				),
				renderTags: (arr, propsForIndex) => arr.map((option, index) => {
					const obj = profileOptions.byProfileId[option?.profileId];
					return obj && (
						<Chip
							size="medium"
							label={ga4Style(obj.label, obj.propertyId)}
							{...propsForIndex({ index })}
						/>
					);
				}),
				isOptionEqualToValue: (option, value) => (option.profileId === value.profileId),
			}}
		/>
	);
}

export default AnalyticsSystemMapper;

AnalyticsSystemMapper.propTypes = {
	profiles: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
	})),
	profileOptions: PropTypes.arrayOf(PropTypes.shape({
		profileId: PropTypes.string,
		name: PropTypes.string,
		url: PropTypes.string,
	})),
	analyticsSystemId: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

AnalyticsSystemMapper.defaultProps = {
	profiles: [],
	profileOptions: [],
};
