import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Dialog,
	DialogTitle,
	Box,
} from '@mui/material';
import PropTypes from 'prop-types';

function SimpleDialog({
	title,
	dialogProps,
	open,
	parentPath,
	children,
	onClose: onCloseCallback,
	dialogHeaderAction,
	...rest
}) {
	const [dialogIsOpen, setDialogIsOpen] = useState(open);
	const history = useHistory();
	const onClose = () => {
		if (onCloseCallback) {
			onCloseCallback();
		}
		setDialogIsOpen(false);
		if (parentPath) {
			history.push(parentPath);
		}
	};

	useEffect(() => {
		setDialogIsOpen(open);
	}, [open]);

	return (
		<Dialog
			open={dialogIsOpen}
			onClose={onClose}
			{...dialogProps}
			{...rest}
		>
			{title && (
				<DialogTitle>
					<Box component="div" display="flex" alignItems="center" justifyContent="space-between">
						{ title }
						{ dialogHeaderAction && dialogHeaderAction }
					</Box>
				</DialogTitle>
			)}
			{ children }
		</Dialog>
	);
}

SimpleDialog.propTypes = {
	title: PropTypes.string,
	open: PropTypes.bool.isRequired,
	dialogProps: PropTypes.objectOf(PropTypes.any),
	parentPath: PropTypes.string,
	children: PropTypes.node.isRequired,
	onClose: PropTypes.func,
	dialogHeaderAction: PropTypes.node,
};

SimpleDialog.defaultProps = {
	title: undefined,
	dialogProps: {},
	parentPath: '',
	onClose: () => undefined,
	dialogHeaderAction: null,
};

export default SimpleDialog;
