/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import '../styles/base.css';
import AudiencePublisherDashboard from '../pages/AudiencePublisherDashboard';
import { AudienceUserInvoingPage } from '../pages/SimplePages';
import UserList from '../pages/UserList';
import UserEdit from '../pages/UserEdit';
import { stores } from '../stores';
import Page from '../containers/Page';
import { reportRoutes } from '../components/Report/utils';

export default function Audience({ children, includeReportRoutes }) {
	const canEditUsers = stores.identity.canEditUsers();
	return (
		<div>
			<Switch>
				<Route
					exact
					path="/audience/dashboard"
					render={(props) => (
						<Page
							title="Dashboard"
						>
							<AudiencePublisherDashboard {...props} />
						</Page>
					)}
				/>
				<Route
					exact
					path="/audience/invoicing"
					render={(props) => (
						<Page
							title="Invoicing"
						>
							<AudienceUserInvoingPage {...props} />
						</Page>
					)}
				/>
				{includeReportRoutes && reportRoutes()}
				{canEditUsers && (
					<Route
						exact
						path="/audience/users"
						render={(props) => (
							<Page
								title="Users"
							>
								<UserList {...props} />
							</Page>
						)}
					/>
				)}
				{canEditUsers && (
					<Route
						exact
						path="/audience/users/:userId"
						render={(props) => (
							<Page
								title="Edit user"
							>
								<UserEdit {...props} />
							</Page>
						)}
					/>
				)}
				{children}
			</Switch>
		</div>
	);
}

Audience.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	includeReportRoutes: PropTypes.bool,
};

Audience.defaultProps = {
	children: null,
	includeReportRoutes: false,
};
