import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import TableChartIcon from '@mui/icons-material/TableChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import { Collapse } from '@mui/material';
import Constants from 'relevant-shared/reportData/constants';
import SharedUtils from 'relevant-shared/reportData/utils';
import { availReportTypes } from 'relevant-shared/reportData/reportType';
import * as DynamicDateRanges from 'relevant-shared/reportData/dynamicDateRanges';
import AllCustomizers from 'relevant-shared/reportData/customizers/allCustomizers';
import Switch from '../Switch';
import DateOrDaysPicker from '../DateOrDaysPicker';
import DateUtils from '../../lib/dateUtils';
import styles from './styles.css';

import Select from '../Select';
import SystemData from '../../lib/systemData';
import { stores } from '../../stores';
import Page from '../../containers/Page';
import ReportsOverviewPage from '../../pages/ReportsOverview';
import ReportPageWrapper from '../../pages/Report/ReportPageWrapper';

// find last numeric value before closing bracket and decrease it by 50 %
// rgba(250, 250, 250, 0.8) => rgba(250, 250, 250, 0.4)
export const reduceOpacity = (rgba) => rgba.replace(/(\d{1,}\.?\d*?)(?=\))/, (opacity) => {
	try {
		return (Number(opacity) * 0.5).toString();
	} catch (error) {
		return opacity;
	}
});

export const getColor = (idx, isComp, totalColors) => {
	const tableauClassic = [
		'#1f77b4', '#aec7e8',
		'#e377c2', '#f7b6d2',
		'#2ca02c', '#98df8a',
		'#17becf', '#9edae5',
		'#8c564b', '#c49c94',
		'#bcbd22', '#dbdb8d',
		'#9467bd', '#c5b0d5',
		'#ff7f0e', '#ffbb78',
		'#7f7f7f', '#c7c7c7',
		'#d62728', '#ff9896',
	];
	const tableauColorBlind = [
		'#006ba4', '#ff800e',
		'#ababab', '#595959',
		'#5f9ed1', '#c85200',
		'#898989', '#a2c8ec',
		'#ffbc79', '#cfcfcf',
	];
	const colors = (totalColors <= 10) ? tableauColorBlind : tableauClassic;
	const color = colors[idx % colors.length];
	return color.concat(isComp ? '59' : 'B3');
};

export const hbaEnabledForNormalUsers = () => {
	const { hbaEnabled, userReportOptions } = SystemData.genericData;
	return hbaEnabled && userReportOptions.hb.USER_OTHER_OPTIONS.includes('hbaEnabledForNormalUsers');
};

export const futureEnabledForNormalUsers = () => {
	const { futureEnabled, userReportOptions } = SystemData.genericData;
	return futureEnabled && userReportOptions.future.USER_OTHER_OPTIONS.includes('enabledForNormalUsers');
};

export const isForecastAvailable = (isAdmin) => {
	const { userReportOptions } = SystemData.genericData;
	return isAdmin || (userReportOptions.programmatic.USER_OTHER_OPTIONS
		&& userReportOptions.programmatic.USER_OTHER_OPTIONS.includes('forecast'));
};

/**
 * Check whether the user actually has access to publishers
 * For example called when the params are in the URL,
 * i.e someone has shared a report to you.
 */
export function hasAccessToPublishers(reportSettings) {
	if (!stores.identity.isAdministrator() && !stores.identity.hasAllPubAccess()) {
		const myPubs = stores.identity.allPubAccess();
		const filterPubs = (reportSettings.whereIn || {}).publisherId || [];
		if (!filterPubs.length || filterPubs.find((id) => !_.includes(myPubs, id))) {
			return false;
		}
	}
	return true;
}

export const getChartGroupBy = (groupBy) => {
	if (groupBy.length <= 1) {
		return groupBy;
	}
	if (!groupBy.includes('date')) {
		return [groupBy[1], groupBy[0], ...groupBy.slice(2)];
	}
	const dateIndex = groupBy.indexOf('date');
	if (dateIndex === 1) {
		return groupBy;
	}
	const groupByNoDate = groupBy.filter((element) => element !== 'date');
	return [groupByNoDate[0], 'date', ...groupByNoDate.slice(1)];
};

/**
 *
 * @param {*} chartGroupBy Whatever you get from the getChartGroupBy function
 */
export const isLineChart = (chartGroupBy, useCompareTo) => {
	const isOnlyDate = (chartGroupBy.length === 1 && chartGroupBy[0] === 'date');
	const includesDate = chartGroupBy.includes('date');
	return includesDate && (useCompareTo || !isOnlyDate);
};

const getCompareOptions = ({ state }) => {
	const { type, reportData } = state;
	const customizer = reportData?.customizer || AllCustomizers.createByType(type);
	return {
		[SharedUtils.COMPARISON_TYPES.YEAR_ON_YEAR.key]: !!customizer.getDynamicDateRanges().lastYear,
		[SharedUtils.COMPARISON_TYPES.MONTH_ON_MONTH.key]: !!customizer.getDynamicDateRanges().lastMonth,
	};
};

const getCommonDatePickerProps = ({ state }) => {
	const { type, reportData, timezone } = state;
	const customizer = reportData?.customizer || AllCustomizers.createByType(type);
	const canSelectBehind = customizer.canSelectBehind();
	const canSelectAhead = state.useForecast || customizer?.canSelectAhead();
	const now = DateUtils.timezoneOffsetDate(null, timezone);
	const today = DateUtils.fullDay(now);
	return {
		canSelectBehind,
		canSelectAhead,
		minDate: canSelectBehind ? null : today,
		maxDate: canSelectAhead ? null : today,
		allowZeroDaysBack: !!customizer?.hasDataForToday(),
	};
};

export function CompareToSwitch(props) {
	const {
		margin,
		state,
		update,
	} = props;

	const {
		useCompareTo, compareTo, timezone,
	} = state;
	const { compareStart, end, maxStart } = SharedUtils.getCompareToRange(state);
	const pickerProps = getCommonDatePickerProps(props);
	const compareOptions = getCompareOptions(props);
	const now = DateUtils.timezoneOffsetDate(null, timezone);

	const comparisonSwitch = (comp) => (
		useCompareTo && compareOptions[comp.key] && (
			<Switch
				value={compareTo === comp.key}
				onChange={(ev) => {
					update(
						{
							compareTo: ev.target.value // toggle
								? comp.key
								: SharedUtils.getCompareToRange(state).compStart,
						},
					);
				}}
				size="small"
				label={comp.label}
				margin={margin}
			/>
		)
	);

	return (
		<Box
			display="flex"
			flexDirection="column"
			key={Math.random() /* "Solves" some bugs in DateOrDaysPicker */}
		>
			<Switch
				value={!!useCompareTo}
				onChange={() => update({ useCompareTo: !useCompareTo })}
				size="small"
				label="Compare dates"
				margin={margin}
			/>
			{comparisonSwitch(SharedUtils.COMPARISON_TYPES.YEAR_ON_YEAR)}
			{comparisonSwitch(SharedUtils.COMPARISON_TYPES.MONTH_ON_MONTH)}
			<Collapse
				in={useCompareTo}
				mountOnEnter
				unmountOnExit
			>
				<Box display="flex" mt={1}>
					<Box mr={1}>
						<DateOrDaysPicker
							{...pickerProps}
							name="compareTo"
							label="From"
							value={compareStart}
							maxDate={maxStart}
							now={now}
							autoOk
							onSelect={(ev) => update({ compareTo: ev.target.value })}
						/>
					</Box>
					<Box>
						<DateOrDaysPicker
							label="To"
							name="compareToStart"
							value={moment(end).format('YYYY-MM-DD')}
							now={now}
							disabled
						/>
					</Box>
				</Box>
			</Collapse>
		</Box>
	);
}

CompareToSwitch.propTypes = {
	margin: PropTypes.string,
	state: PropTypes.object.isRequired,
	update: PropTypes.func.isRequired,
};

CompareToSwitch.defaultProps = {
	margin: undefined,
};

export function ReportTypeSelector(props) {
	const { state, update } = props;
	const {
		showChart, showTable, showPieChart, showGrid,
	} = state;
	return (
		<ToggleButtonGroup
			value={[
				showPieChart && 'pie',
				showChart && 'lineOrBar',
				showTable && 'table',
				showGrid && !(showTable || showPieChart) && 'grid',
			].filter(Boolean)}
			onChange={(event, selected) => update({
				showPieChart: selected.includes('pie'),
				showChart: selected.includes('lineOrBar'),
				showTable: selected.includes('table'),
			})}
			aria-label="Select report type"
		>
			<ToggleButton value="pie" aria-label="Pie Chart - Pie chart is best suited for single dimension selection">
				<Tooltip title="Pie Chart - Pie chart is best suited for single dimension selection">
					<PieChartIcon />
				</Tooltip>
			</ToggleButton>
			<ToggleButton value="lineOrBar" aria-label="Line/Bar Chart - Line or bar charts are best suited for two or less dimension selections">
				<Tooltip title="Line/Bar Chart - Line or bar charts are best suited for two or less dimension selections">
					<BarChartIcon />
				</Tooltip>
			</ToggleButton>
			<ToggleButton value="table" aria-label="Table - Tables are best suited for one or more dimensions with multiple metrics.">
				<Tooltip title="Table - Tables are best suited for one or more dimensions with multiple metrics.">
					<TableChartIcon />
				</Tooltip>
			</ToggleButton>
		</ToggleButtonGroup>
	);
}

ReportTypeSelector.propTypes = {
	state: PropTypes.object.isRequired,
	update: PropTypes.func.isRequired,
};

export function StartEndPicker(props) {
	const {
		state,
		update,
		renderAsForecasted,
		isActive,
		margin,
		useSpaceBetween,
	} = props;
	const {
		start, end, useForecast, granularity, timezone,
	} = state;

	const doUpdate = (settings) => {
		const DAY_MS = 24 * 3600 * 1000;
		const MAX_KEEP_PERIODS = 300;
		const newStart = settings.start || start;
		const newEnd = settings.end || end;
		const timeRange = Constants.SHORT_TIME_RANGES[granularity];
		const oldDays = Math.round((end - start) / DAY_MS) + 1;
		const newDays = Math.round((newEnd - newStart) / DAY_MS) + 1;
		if (timeRange && newDays / oldDays > 1.5) {
			const periodsOf = (ms) => (DAY_MS / ms) * newDays;
			if (periodsOf(timeRange.ms) > MAX_KEEP_PERIODS) {
				let candidate;
				let candidateMs;
				_.forOwn(Constants.SHORT_TIME_RANGES, ({ ms }, key) => {
					if ((!candidate || candidateMs > ms) && periodsOf(ms) <= MAX_KEEP_PERIODS) {
						candidate = key;
						candidateMs = ms;
					}
				});
				candidate = candidate || 'perday';
				settings.granularity = candidate;
			}
		}
		update({
			...settings,
			dynamicDateRange: DynamicDateRanges.getAllRanges().custom.key,
		});
	};

	const now = DateUtils.timezoneOffsetDate(null, timezone);
	const today = DateUtils.fullDay(now);
	const getClassName = () => {
		const dateIsInThePast = (date) => (DateUtils.toDate(date, now) < today);
		if (useForecast && dateIsInThePast(end)) {
			return styles.highlightedElement;
		}
		return undefined;
	};

	const getTextFieldProps = (isForecast) => {
		if (isForecast) {
			return { color: 'warning' };
		}
		if (!isActive) {
			return { color: 'grey.400' };
		}
		return {};
	};

	const pickerProps = getCommonDatePickerProps(props);
	return (
		<>
			<Box sx={useSpaceBetween ? { pr: 1 } : {}}>
				<DateOrDaysPicker
					{...pickerProps}
					name="Start date"
					maxDate={end}
					value={start}
					now={now}
					autoOk
					onSelect={(ev) => doUpdate({ start: ev.target.value })}
					textFieldProps={{ margin, ...getTextFieldProps(renderAsForecasted(start)) }}
				/>
			</Box>
			<DateOrDaysPicker
				{...pickerProps}
				name="End date"
				minDate={start}
				value={end}
				now={now}
				autoOk
				onSelect={(ev) => doUpdate({ end: ev.target.value })}
				className={getClassName()}
				textFieldProps={{ margin, ...getTextFieldProps(renderAsForecasted(end)) }}
			/>
		</>
	);
}

StartEndPicker.propTypes = {
	report: PropTypes.object.isRequired,
	state: PropTypes.object.isRequired,
	isActive: PropTypes.bool,
	update: PropTypes.func.isRequired,
	renderAsForecasted: PropTypes.func,
	margin: PropTypes.string,
	useSpaceBetween: PropTypes.bool,
};

StartEndPicker.defaultProps = {
	renderAsForecasted: () => false,
	isActive: true,
	margin: undefined,
	useSpaceBetween: false,
};

export function ForecastToggle(props) {
	const { state, update } = props;
	const {
		start, end, useForecast, groupBy, whereIn, type, trigger,
	} = state;
	const whereInUsedKeys = _.keys(_.pickBy(whereIn, (arr) => !_.isEmpty(arr)));
	const TYPE = Constants[type] || {};
	const forbidden = TYPE.NON_FORECAST_OPTIONS || [];
	const onToggle = () => {
		const settings = { useForecast: !useForecast };
		if (useForecast) {
			if (DateUtils.toDate(start) > DateUtils.today()) {
				settings.start = DateUtils.today();
			}
			if (DateUtils.toDate(end) > DateUtils.today()) {
				settings.end = DateUtils.today();
			}
		}
		update(settings);
	};
	return !!TYPE.CAN_FORECAST && (
		<Switch
			value={!!useForecast}
			onChange={onToggle}
			size="small"
			disabled={(groupBy || []).concat(whereInUsedKeys).some((v) => forbidden.includes(v)) || !!trigger}
			label="Forecast"
		/>
	);
}

ForecastToggle.propTypes = {
	state: PropTypes.object.isRequired,
	update: PropTypes.func.isRequired,
};

export function CorrectionSelector({ field }) {
	return (
		<Select
			{...field('correctionType')}
			label="Revenue correction "
			nonSelected="(default)"
			items={[
				{ value: 'none', label: 'Raw report numbers' },
				{ value: 'revenue', label: 'Use per SSP revenue corrections' },
			]}
			fullWidth
		/>
	);
}

export function reportRoutes() {
	return [
		<Route
			exact
			key="reports_overview"
			path="/reports"
			render={() => (
				<Page>
					<ReportsOverviewPage />
				</Page>
			)}
		/>,
		...availReportTypes.map((obj) => [
			<Route
				key={`overview_${obj.type}`}
				exact
				path={obj.reportLocation}
				render={() => (
					<Page
						title={`${obj.DESCRIPTION} reports`}
					>
						<ReportsOverviewPage type={obj.type} />
					</Page>
				)}
			/>,
			<Route
				key={`edit_${obj.type}`}
				exact
				path={`${obj.reportLocation}/:id`}
				render={({ match }) => (
					<Page>
						<ReportPageWrapper
							reportId={match?.params?.id}
							type={obj.type}
							noSave={obj.noSaveType}
						/>
					</Page>
				)}
			/>,
		]).flat(),
	];
}

CorrectionSelector.propTypes = {
	field: PropTypes.func.isRequired,
};

export default SharedUtils;

export function getTotals(array, sums) {
	const totals = _.zipObject(sums, Array(sums.length).fill(0));
	array.forEach((element) => {
		sums.forEach((sumVal) => {
			totals[sumVal] += (element.totals || element.data)[sumVal];
		});
	});
	return totals;
}

export function getUserGroupByOptions(type, {
	genericData = SystemData.genericData,
	user = stores.identity.user(),
} = {}) {
	const hasAdditionalPubAccess = user.additionalPubAccess?.length || user.permissions?.hasAllPubAccess;
	const { normalUserSspVisible, systemSettings, userReportOptions } = genericData;
	const { programmaticUserGroupByOptions } = systemSettings;
	let res = programmaticUserGroupByOptions || ((userReportOptions[type] || {}).USER_GROUP_BY_OPTIONS || []);
	if (hasAdditionalPubAccess) {
		res = res.concat('publisherId');
	}
	if (normalUserSspVisible) {
		res = res.concat(['sspId', 'sourceId']);
	}
	return _.uniq(res);
}
