import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { List, Box, Divider } from '@mui/material';

export default function SidebarButtonGroup({ children, sx }) {
	const isEmptyGroup = Array.isArray(children) && children?.every((child) => !isValidElement(child));
	if (isEmptyGroup) {
		return null;
	}

	return (
		<Box sx={sx}>
			<Divider />
			<List>
				{ children }
			</List>
		</Box>
	);
}

SidebarButtonGroup.propTypes = {
	sx: PropTypes.shape({}),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
};

SidebarButtonGroup.defaultProps = {
	sx: null,
};
