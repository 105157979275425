import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControlLabel,
} from '@mui/material';

function PopupItemList({
	items: initialItems, header, onChange, onOpenStateChange, getOptionSubText,
}) {
	const [items, setItems] = useState(_.cloneDeep(initialItems));
	const [isOpen, setIsOpen] = useState(true);

	const handleCheckboxChange = ({ target }, selected) => {
		setItems((prev) => {
			const edited = { ...prev };
			edited[target.value].selected = selected;
			return edited;
		});
	};

	const handleSelectAllCheckboxChange = (__, selected) => {
		setItems((prev) => {
			const edited = { ...prev };
			Object.keys(edited).forEach((key) => {
				edited[key].selected = selected;
			});
			return edited;
		});
	};

	const allItemsSelected = Object.values(items).every(({ selected }) => selected);
	const isIndeterminate = !allItemsSelected && Object.values(items).some(({ selected }) => selected);
	return (
		<Dialog
			open={isOpen}
			scroll="paper"
			onClose={() => setIsOpen(false)}
			TransitionProps={{
				onExited: () => onOpenStateChange(isOpen),
			}}
		>
			<>
				<DialogTitle>{header}</DialogTitle>
				<Box
					pl={3}
					label=""
					component={FormControlLabel}
					control={(
						<Checkbox
							indeterminate={isIndeterminate}
							checked={allItemsSelected}
							onChange={handleSelectAllCheckboxChange}
						/>
					)}
				/>
				<DialogContent dividers>
					{ Object.keys(items).map((key) => (
						<Box component="div" mb={1} key={key}>
							<FormControlLabel
								control={(
									<Checkbox
										checked={items[key].selected}
										onChange={handleCheckboxChange}
										value={key}
										color="default"
									/>
								)}
								label={items[key].label}
							/>
							{ getOptionSubText(items[key]) }
						</Box>
					))}
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						color="secondary"
						onClick={() => {
							setIsOpen(false);
						}}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							setIsOpen(false);
							onChange(items);
						}}
					>
						Save
					</Button>
				</DialogActions>
			</>
		</Dialog>
	);
}

export default PopupItemList;

PopupItemList.propTypes = {
	items: PropTypes.objectOf(PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
		selected: PropTypes.bool,
	})).isRequired,
	onChange: PropTypes.func.isRequired,
	header: PropTypes.string.isRequired,
	onOpenStateChange: PropTypes.func,
	getOptionSubText: PropTypes.func,
};

PopupItemList.defaultProps = {
	onOpenStateChange: () => undefined,
	getOptionSubText: () => null,
};
