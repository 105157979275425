import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withTheme from '@mui/styles/withTheme';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { createCsv } from 'relevant-shared/misc/misc';
import UploadButton from '../UploadButton';
import Select from '../Select';
import TextField from '../TextField';
import BrowserUtils from '../../lib/browserUtils';
import { ActionButton } from '../ActionButton/ActionButton';
import MiscUtils from '../../lib/miscUtils';
import DataTable from '../DataTable';
import Checkbox from '../Checkbox';
import PriceField from './PriceField';

const CSV_COLS = {
	dmpId: 'SegmentID',
	name: 'Segment name',
	finalPrice: { name: 'CPM', type: Number },
};

class PricelistEditor extends React.Component {
	constructor(props) {
		super(props);
		const ctx = props.priceListContext;
		ctx.editor = this;
		const currentList = ctx.pricelist || { prices: [] };
		this.pricelistsById = new Map(ctx.pricelists.map((p) => [p.id, p]));
		this.availablePriceLists = MiscUtils.alphaSorted(ctx.pricelists, 'name').filter((pricelist) => {
			for (let lst = pricelist; lst; lst = this.pricelistsById.get(lst.parent)) {
				if (lst.id === currentList.id) {
					return false; // Avoid circular pricelist hierarchy..
				}
			}
			return true;
		});
		this.segByDmpId = {};
		ctx.segments.forEach((seg) => {
			this.segByDmpId[seg.dmpId] = seg;
		});

		const prices = {};
		(currentList.prices || []).forEach((elm) => {
			prices[elm.segment] = elm.price;
		});
		this.state = {
			prices,
			parent: this.pricelistsById.get(currentList.parent),
		};
	}

	onParentChanged(val) {
		this.updateState({ parent: this.pricelistsById.get(val) });
	}

	updateState(newVals = {}, cb) {
		this.setState(Object.assign(_.cloneDeep(this.state), newVals), cb);
	}

	/** Returns the price-list. This must currently be used, as I've had some difficulties with our Form class,
	 * to make it work with the pricelists. */
	toPriceList() {
		const res = { parent: (this.state.parent || {}).id || null, prices: [] };
		_.forOwn(this.state.prices, (value, key) => res.prices.push({ segment: key, price: value }));
		return res;
	}

	/** Returns the price-list in a structure that is easier to render as a list.
	 *  (segment name,status + price + computed price)
	 *  This is the "data model" that is feeded to the <DataTable> */
	toGuiList() {
		const ctx = this.props.priceListContext;
		const sortedSegs = MiscUtils.alphaSorted(ctx.segments, 'name').filter((seg) => {
			if (seg.isRemoved && !this.state.showRemoved) {
				return false;
			} if (!seg.isActive && !this.state.showInactive) {
				return false;
			}
			return true;
		});
		const elmBySeg = {};
		const elms = sortedSegs.map((seg) => {
			const elm = { ...seg, price: this.state.prices[seg.id] };
			elmBySeg[seg.id] = elm;
			return elm;
		});
		for (let { parent } = this.state; parent; parent = this.pricelistsById.get(parent.parent)) {
			parent.prices.forEach((price) => {
				const elm = elmBySeg[price.segment];
				if (elm && !elm.computed && price.price != null) {
					elm.computed = price.price;
				}
			});
		}
		elms.forEach((p) => {
			p.finalPrice = p.price || p.computed || 0;
		});
		return elms;
	}

	exportToCsv() {
		const csv = createCsv(this.toGuiList(), CSV_COLS, ['dmpId', 'name', 'finalPrice']);
		BrowserUtils.downloadTextFile(csv, 'Pricelist.csv');
	}

	importFromCsv(ev) {
		const file = ev.target.files[0];
		if (!file) {
			return;
		}
		const reader = new FileReader();
		reader.onload = (event) => {
			const txt = event.target.result;
			try {
				const interesting = ['dmpId', 'finalPrice'];
				const cols = _.pickBy(CSV_COLS, (v, k) => _.includes(interesting, k));
				const newPrices = _.cloneDeep(this.state.prices);
				MiscUtils.loadCsv(txt, cols).forEach((row) => {
					const seg = this.segByDmpId[row.dmpId];
					if (seg) {
						newPrices[seg.id] = row.finalPrice;
					}
				});
				/** Do empty refresh first so that DataTable have latest edited prices first
				 * this is to deal with the situation that we import one .csv, edit something, but
				 * then re-import it to overwrite that changes. in that case the DataTable will have
				 * the same data and not update again because of 'onlyUpdateWhenDataChanges' prop */
				this.updateState({}, () => {
					this.updateState({ prices: newPrices });
				});
			} catch (err) {
				alert(err);
			}
		};
		reader.readAsText(file);
	}

	render() {
		const { field, hasName, style } = this.props;
		return (
			<Card style={style}>
				<CardContent>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h2">
								Prices per segment
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Select
								name="basePriceList"
								value={(this.state.parent || {}).id || 'none'}
								onChange={(ev) => this.onParentChanged(ev.target.value)}
								label="Base price list"
								items={[{ label: 'None', value: 'none' }, ...this.availablePriceLists.map((list) => ({
									label: list.name,
									value: list.id,
								}))]}
								fullWidth
							/>
						</Grid>
						{hasName
						&& (
							<Grid item xs={12}>
								<TextField
									{...field('name')}
									label="Name"
									required
									fullWidth
								/>
							</Grid>
						)}
						<Grid item>
							<ActionButton
								label="Export to .csv"
								color="primary"
								onClick={() => this.exportToCsv()}
							/>
						</Grid>
						<Grid item>
							<UploadButton
								accept=".csv"
								onChange={(ev) => this.importFromCsv(ev)}
							>
								Import from .csv
							</UploadButton>
						</Grid>
						<Grid item>
							<ActionButton
								label="Clear pricelist"
								color="secondary"
								onClick={() => this.setState({ prices: {} })}
							/>
						</Grid>
						<Grid item xs={12}>
							<Checkbox
								style={{ marginTop: '10px' }}
								label="Show inactive segments"
								name="showInactive"
								value={this.state.showInactive}
								onChange={(ev) => this.updateState({ showInactive: ev.target.value })}
							/>
						</Grid>
						<Grid item xs={12}>
							<DataTable
								onlyUpdateWhenDataChanges
								showCheckboxes={false}
								selectableRows={false}
								identifier={(row) => row.id}
								definitions={[
									{ key: 'name', title: 'Name' },
									{
										key: 'isActive',
										title: 'Status',
										style: { width: '50px' },
										format: (isActive) => (
											<span style={{ color: this.props.theme.palette[isActive ? 'success' : 'error'].main }}>
												{isActive ? 'Active' : 'Inactive'}
											</span>
										),
									},
									{
										key: 'price',
										title: 'Price',
										style: { width: '80px' },
										padding: 'none',
										whenNull: () => null,
										format: (price, row) => (
											<PriceField
												computed={row.computed}
												price={this.state.prices[row.id]}
												onChange={(newPrice) => this.setState({
													prices: {
														...this.state.prices,
														[row.id]: newPrice,
													},
												})}
											/>
										),
									},
								]}
								data={this.toGuiList()}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	}
}

PricelistEditor.propTypes = {
	field: PropTypes.func.isRequired,
	priceListContext: PropTypes.object.isRequired,
	style: PropTypes.object,
	hasName: PropTypes.bool,
};

PricelistEditor.defaultProps = {
	hasName: false,
	style: {},
};

export default withTheme(PricelistEditor);
