import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DataTable from '../DataTable';
import Checkbox from '../Checkbox';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import RadioButtonGroup from '../RadioButtonGroup';
import SiteSelect from '../SiteSelect';
import SegmentSelect from '../SegmentSelect';
import Select from '../Select';
import JobButton from '../JobButton';

const REVENUE_TYPES = {
	auto: 'All matching sites',
	whitelist: 'Using whitelist (include)',
	blacklist: 'Using blacklist (exclude)',
};

class ApproveSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = { segment: _.clone(props.segment.toLegacyObject()) };
		props.fnTarget.getSegmentSettings = () => this.state.segment;
	}

	render() {
		const { segments } = this.props;
		const { selectedSeg, segment } = this.state;
		const method = segment.revShareMethod;

		const set = (obj) => this.setState({ segment: { ...segment, ...obj } });

		const autoDetectSites = async () => {
			const res = await segment.calcContributionRates({ ignoreRevenueSites: true });
			const sites = [];
			this.getPublishers().forEach((pub) => {
				(pub.cxDmpSites || []).forEach((site) => {
					if (!!res[site.id] === (method === 'whitelist')) {
						sites.push(site.id);
					}
				});
			});
			set({ revenueSites: sites });
		};

		return (
			<Card>
				<CardContent>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h2">
								Revenue sharing settings
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Checkbox
								label="Approved for revenue sharing"
								name="isApproved"
								value={segment.isApproved}
								onChange={(ev) => set({ isApproved: ev.target.value })}
							/>
						</Grid>
						<Grid item xs={12}>
							<SegmentSelect
								title="Copy settings from other segment"
								segments={segments}
								selectable={false}
								selected={selectedSeg ? [selectedSeg] : []}
								singleSelect
								deselectOnClickaway
								onChange={(items) => {
									const dmpId = items.find((i) => i !== selectedSeg);
									if (dmpId) {
										const other = segments.find((s) => s.dmpId === dmpId);
										set(_.pick(other, ['revenueSites', 'revShareMethod']));
									}
									this.setState({ selectedSeg: dmpId });
								}}
								innerStyle={{ overflowY: 'scroll', height: '300px' }}
							/>
						</Grid>
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<Typography variant="h2">
										Select sites contributing
									</Typography>
									<RadioButtonGroup
										items={Object.entries(REVENUE_TYPES).map(([value, name]) => ({ value, name }))}
										name="method"
										selected={method}
										defaultValue={method}
										onChange={(ev) => set({ revShareMethod: ev.target.value })}
									/>
									{method !== 'auto' && (
										<JobButton
											label="Auto detect sites"
											fn={autoDetectSites}
											color="primary"
										/>
									)}
								</CardContent>
							</Card>
						</Grid>
						{method !== 'auto' && (
							<Grid item xs={12}>
								<SiteSelect
									isSites
									selected={segment.revenueSites || []}
									expanded
									type="audience"
									onChange={(items) => set({ revenueSites: items })}
									fnTarget={this}
								/>
							</Grid>
						)}
					</Grid>
				</CardContent>
			</Card>
		);
	}
}

ApproveSettings.propTypes = {
	segments: PropTypes.array.isRequired,
	segment: PropTypes.object.isRequired,
	fnTarget: PropTypes.object,

};

ApproveSettings.defaultProps = {
	fnTarget: {},
};

export default ApproveSettings;
