import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import CollapseHeader from '../CollapseHeader';
import TextField from '../TextField';
import {
	GlobalSettingsObject,
	Segment,
	CxReports,
	SiteProperties,
	listOfPublishers,
} from '../../api/relevant';
import SystemData from '../../lib/systemData';
import Checkbox from '../Checkbox';
import Select from '../Select';
import DatePicker from '../DatePicker';
import OperationWrapper from '../OperationWrapper';
import MiscUtils from '../../lib/miscUtils';
import DateUtils from '../../lib/dateUtils';
import BrowserUtils from '../../lib/browserUtils';
import SegmentSelect from '../SegmentSelect';
import PopupSelector from '../PopupSelector';
import SiteSelect from '../SiteSelect';
import { IfHasAudience } from '../Wrappers';
import SegmentList from '../SegmentList';
import {
	SspList, AdserverList, AnalyticsList, UserIdModulesList,
} from '../SystemLists';
import ExpandSelector from '../ExpandSelector';
import CutField from '../CutField';
import UserReportOptions from '../Report/userReportOptions';
import PlacementTypeEdit from '../PlacementTypeEdit';
import BidParamEdit from '../BidParamEdit';
import { Link } from '../Link/Link';
import AdserverPlacementSyncPopup from '../AdserverPlacementSyncPopup';
import { stores } from '../../stores';
import { Dialog } from '../Dialog';

const dateFormats = [
	{
		label: 'DD-MM-YYYY',
		value: 'DD-MM-YYYY',
	},
	{
		label: 'MM-DD-YYYY',
		value: 'MM-DD-YYYY',
	},
	{
		label: 'YYYY-MM-DD',
		value: 'YYYY-MM-DD',
	},
];

const InvoiceInfo = (p) => ((field, docTemplates) => (
	<ExpandSelector title={p.title || 'Invoicing'}>
		<Grid container spacing={3}>
			<Grid item md={4} xs={12}>
				<TextField
					{...field('InvoiceEmail')}
					label="Invoice Email"
					emails
					type="email"
					fullWidth
				/>
			</Grid>
			<Grid item md={3} xs={12}>
				<TextField
					{...field('MinInvoiceEUR')}
					label="Minimum invoice EUR"
					type="number"
					fullWidth
				/>
			</Grid>
			<Grid item md={5} xs={12}>
				<DatePicker
					floatingLabelText="Last manual invoice month"
					maxDate={DateUtils.lastMonth().end}
					autoOk
					canClear
					filter={(d) => (d ? DateUtils.lastDayOfMonth(d) : null)}
					{...field('InvocingLastManual')}
					fullWidth
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<Select
					label="Invoicing Template"
					{...field('InvoiceTemplate')}
					nonSelected="(default)"
					items={docTemplates
						.filter((template) => template.type === 'InvoiceData')
						.map((template) => ({ label: template.name, value: template.id }))}
					fullWidth
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<Select
					label="Balance Info Template"
					{...field('BalanceInfoTemplate')}
					nonSelected="(default)"
					items={docTemplates
						.filter((template) => template.type === 'InvoiceData')
						.map((template) => ({ label: template.name, value: template.id }))}
					fullWidth
				/>
			</Grid>
			{p.after}
		</Grid>
	</ExpandSelector>
))((path) => p.field(`publisher.${p.type}${path}`), p.docTemplates);

class PublisherForm extends React.Component {
	constructor(props) {
		super(props);
		this.loadSiteInfo = this.loadSiteInfo.bind(this);
		this.handleExpandChange = this.handleExpandChange.bind(this);
		this.state = {
			expanded: false,
			showSites: false,
		};
	}

	handleExpandChange = () => {
		if (this.state.expanded) {
			const { checkForErrors } = this.props;
			const hasErrors = checkForErrors();
			if (!hasErrors) this.setState({ expanded: false });
		} else {
			this.setState({
				expanded: true,
				showSites: true,
			});
		}
	};

	async loadSiteInfo() {
		const st = {};
		await Promise.all([
			(async () => { st.cxSiteInfo = await CxReports.call('getSites'); })(),
			(async () => { st.siteProps = await SiteProperties.call('getAllSiteProperties', { date: new Date() }); })(),
			(async () => {
				const allPubs = (await listOfPublishers()).result;
				const usedIds = _.flatten(allPubs.map((p) => p.cxDmpSites || [])).map((s) => s.id);
				st.usedSiteIds = _.zipObject(usedIds, Array(usedIds.length).fill(true));
			})(),
		]);
		this.setState(st);
	}

	renderCorePublisherInfo() {
		const { field, value } = this.props;
		const { LANGUAGES, CURRENCIES } = SystemData.genericData;
		return (
			<Paper>
				<Box padding={2}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h2">
								Core Account Info
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...field('publisher.name')}
								label="Name"
								required
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...field('publisher.notes')}
								label="Notes"
								multiLine
								fullWidth
							/>
						</Grid>
						<Grid item xs={4}>
							<Select
								{...field('publisher.language')}
								label="Language"
								items={_.map(LANGUAGES, (v, k) => ({ label: v.name, value: k }))}
								fullWidth
							/>
						</Grid>
						<Grid item xs={4}>
							<Select
								{...field('publisher.preferredCurrency')}
								label="Preferred currency"
								nonSelected="(none)"
								items={_.map(CURRENCIES, (v, k) => ({ label: `${v.name} (${k})`, value: k }))}
								fullWidth
							/>
						</Grid>
						<Grid item xs={4}>
							<Select
								{...field('publisher.dateformat')}
								label="Date format"
								items={dateFormats}
								required
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<UserReportOptions
								model={value('publisher.userReportOptions')}
								getDefaultsFn={async () => (await GlobalSettingsObject.listOne()).userReportOptions}
							/>
						</Grid>
						<IfHasAudience>
							<Grid item xs={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">
										Product access
									</FormLabel>
									<FormGroup>
										<Checkbox
											{...field('publisher.audienceAccess')}
											label="Relevant Audience"
										/>
										<Checkbox
											{...field('publisher.programmaticAccess')}
											label="Relevant Programmatic"
										/>
									</FormGroup>
								</FormControl>
							</Grid>
						</IfHasAudience>
					</Grid>
				</Box>
			</Paper>
		);
	}

	renderLocalSystems() {
		const { systems, publisherId } = this.props;
		const editLink = BrowserUtils.makeQs('/settings/programmatic-configurations', { publisherId });
		return (
			<ExpandSelector title="Local SSPs, Adservers, etc">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<ExpandSelector title="Local SSPs">
							<SspList
								list={systems.ssps}
								publisherId={publisherId}
								readOnly
								editLink={editLink}
							/>
						</ExpandSelector>
					</Grid>
					<Grid item xs={12}>
						<ExpandSelector title="Local Adservers">
							<AdserverList
								list={systems.adservers}
								publisherId={publisherId}
								readOnly
								editLink={editLink}
							/>
						</ExpandSelector>
					</Grid>
					<Grid item xs={12}>
						<ExpandSelector title="Local Analytics Systems">
							<AnalyticsList
								list={systems.analyticsSystems}
								publisherId={publisherId}
								readOnly
								editLink={editLink}
							/>
						</ExpandSelector>
					</Grid>
					<Grid item xs={12}>
						<ExpandSelector title="Local User ID modules">
							<UserIdModulesList
								list={systems.userIdModules}
								publisherId={publisherId}
								readOnly
								editLink={editLink}
							/>
						</ExpandSelector>
					</Grid>
				</Grid>
			</ExpandSelector>
		);
	}

	renderProgrammaticInfo() {
		const {
			field, systems, publisherId, docTemplates, value, form, publisher, publisherNode,
		} = this.props;
		const cutFields = (p) => (
			<>
				<Grid item xs={3}>
					<CutField
						{...field(`${p.base}.openRTBContractualCut`)}
						label="OpenRTB cut (%)"
						required={!!p.required}
						useEmptyString={!!p.useEmptyString}
					/>
				</Grid>
				<Grid item xs={3}>
					<CutField
						{...field(`${p.base}.dealsContractualCut`)}
						label="Deals cut (%)"
						required={!!p.required}
						useEmptyString={!!p.useEmptyString}
					/>
				</Grid>
				<Grid item xs={3}>
					<CutField
						{...field(`${p.base}.directContractualCut`)}
						label="Direct cut (%)"
						required={!!p.required}
						useEmptyString={!!p.useEmptyString}
					/>
				</Grid>
			</>
		);

		const masterTagUrl = BrowserUtils.makeQs('/settings/tag-data-edit', {
			type: 'sspPublisherId',
			accountId: publisherId,
			id: publisherId,
		});

		return (
			<Paper>
				<Box padding={2}>
					<Grid container spacing={3} alignItems="center">
						<Grid item xs={12}>
							<Typography variant="h2">
								Programmatic Info
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Link to={masterTagUrl}>Master programmatic tag</Link>
						</Grid>
						<Grid item xs={2}>
							<PlacementTypeEdit
								model={publisher}
								globalSettings={publisherNode.globalSettings}
								form={form}
								systems={systems}
								field={(p) => field(`publisher.${p}`)}
								nonDeletable={_.uniq(_.flatten(publisher.websites.map((s) => s.placements.map((p) => p.placementType))))}
							/>
						</Grid>
						<Grid item xs={2}>
							<BidParamEdit
								form={form}
								node={publisherNode}
								dstObj={publisher}
								onDone={() => form.update()}
								ssps={systems.ssps}
								userIdModules={systems.userIdModules}
								checkbox={!!publisher.bidParams.find((p) => (
									p.unitId === publisher.id && !_.isEmpty(p.params)
								))}
							/>
						</Grid>
						<Grid item xs={5}>
							{stores.identity.isSuperAdministrator() && (
								<AdserverPlacementSyncPopup
									publisherNode={publisherNode}
									form={form}
								/>
							)}
						</Grid>
						{cutFields({ base: 'publisher', required: true })}
						<Grid item xs={3}>
							<PopupSelector
								title="Individual cuts per SSP"
								selected={
									Object.entries(value('publisher.cutsPerSspOverrides') || {}).map(([id, cuts]) => (
										Object.values(cuts).filter((v) => !isNaN(parseFloat(v))).length ? id : null
									)).filter((id) => id)
								}
								getSingleTitle={(id) => (systems.ssps.find((sys) => sys.id === id) || {}).name}
								form={form}
							>
								<Grid container spacing={3} alignItems="center">
									{systems.ssps.map((ssp) => (
										<Fragment key={ssp.id}>
											<Grid item xs={3}>
												{ssp.name}
												:
											</Grid>
											{cutFields({
												base: `publisher.cutsPerSspOverrides.${ssp.id}`,
												useEmptyString: true,
											})}
										</Fragment>
									))}
								</Grid>
							</PopupSelector>
						</Grid>
						<Grid item xs={12}>
							<InvoiceInfo
								type="programmatic"
								title="Invoicing and API report data"
								docTemplates={docTemplates}
								field={field}
								after={(
									<Grid item xs={12}>
										<TextField
											{...field('publisher.programmaticDataDaysPubUser')}
											label="[Optionally override] Hide all API data for normal/publisher users after this many days [0 = unlimited]"
											placeholder={publisherNode.globalSettings.programmaticDataDaysPubUser || '(not set)'}
											integer
											fullWidth
										/>
									</Grid>
								)}
							/>
						</Grid>
						{publisherId ? (
							<Grid item xs={12}>
								{this.renderLocalSystems()}
							</Grid>
						) : (
							<Grid item xs={12}>
								<i>
									Local SSPs and Adservers can be created after saving the new publisher
								</i>
							</Grid>
						)}
					</Grid>
				</Box>
			</Paper>
		);
	}

	renderDmpSite(dmpSite, dmpSiteIndex) {
		const { OBSCURE_NAMING_ENABLED } = SystemData.genericData;
		const {
			field, collectionRemove, cxDmpSites, publisherId,
		} = this.props;
		const siteInfo = cxDmpSites[dmpSiteIndex];
		const { cxSiteInfo, siteProps } = this.state;
		const myProps = siteProps[siteInfo.id];
		let { url } = cxSiteInfo[siteInfo.id] || {};
		const siteTagUrl = BrowserUtils.makeQs('/settings/tag-data-edit', {
			type: 'siteId',
			accountId: publisherId,
			id: siteInfo.id,
		});
		if (url && !/^https?:\/\//i.test(url)) {
			url = `http://${url}`;
		}
		if (OBSCURE_NAMING_ENABLED) {
			url = 'http://www.example.com';
		}
		const sitePropStat = (siteProp) => {
			if (!myProps || myProps[siteProp] == null) {
				return <Box component="span" color="error.main">UNKNOWN</Box>;
			} if (myProps[siteProp]) {
				return <Box component="span" color="success.main">YES</Box>;
			}
			return <Box component="span" color="warning.main">NO</Box>;
		};
		return (
			<Paper>
				<Box padding={2}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h4">
								{url ? <a href={url}>{url}</a> : <i>No valid DMP site</i>}
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<TextField
								{...field(`publisher.cxDmpSites.${dmpSiteIndex}.name`)}
								label="Name"
								required
								fullWidth
							/>
						</Grid>
						<Grid item xs={5}>
							<TextField
								{...field(`publisher.cxDmpSites.${dmpSiteIndex}.id`)}
								label="Site ID"
								required
								fullWidth
							/>
						</Grid>
						<Grid item xs={2}>
							<Tooltip title="Delete DMP Site">
								<IconButton
									onClick={() => collectionRemove('publisher.cxDmpSites', dmpSiteIndex)}
									size="large"
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item xs={7}>
							<Checkbox
								{...field(`publisher.cxDmpSites.${dmpSiteIndex}.manualEnreach`)}
								label="Manual Enreach settings"
								fullWidth
							/>
							{siteInfo.manualEnreach
								&& (
									<Checkbox
										{...field(`publisher.cxDmpSites.${dmpSiteIndex}.enreachEnabled`)}
										label="Enreach enabled"
										fullWidth
									/>
								)}
							<Checkbox
								{...field(`publisher.cxDmpSites.${dmpSiteIndex}.disableRevShare`)}
								label="Disable revenue sharing (site will receive no more revenue!)"
								fullWidth
							/>
							<Checkbox
								{...field(`publisher.cxDmpSites.${dmpSiteIndex}.disableCrawling`)}
								label="Disable site crawling (please use Manual Enreach settings)"
								fullWidth
							/>
						</Grid>
						<Grid item xs={5}>
							<div>
								Cxense detected:
								{sitePropStat('isUsingCxense')}
							</div>
							<div>
								Enreach detected:
								{sitePropStat('isUsingEnreach')}
							</div>
						</Grid>
						<Grid item xs={4}>
							<SegmentSelect
								selectable={false}
								byWhitelistedSites={[siteInfo.id]}
								title="View Whitelisting"
								selectorType="PopupSelector"
							/>
						</Grid>
						<Grid item xs={4}>
							<OperationWrapper
								fn={(op) => { siteInfo.editOp = op; }}
							>
								<SegmentSelect
									title="Edit Whitelisting"
									selectorType="PopupSelector"
									selectByWhitelistedSites={[siteInfo.id]}
									onlyWithWhitelists
									noSelectCount
									onChange={(segIds) => siteInfo.editOp.reload(async () => {
										const num = await Segment.call('editWhitelisted', { siteId: siteInfo.id, segIds });
										this.setState({ infoMessage: (num ? `Updated ${num} segments` : 'No changes') });
									})}
									extraFilters
									fields={SegmentList.allFields}
								/>
							</OperationWrapper>
						</Grid>
						<Grid item xs={4}>
							<OperationWrapper
								fn={(op) => { siteInfo.copyOp = op; }}
							>
								<SiteSelect
									isSites
									type="audience"
									noSelectCount
									onChange={(siteIds) => siteInfo.copyOp.reload(async () => {
										const num = await Segment.call('copyWhitelists', { siteId: siteInfo.id, srcSiteIds: siteIds });
										this.setState({ infoMessage: (num ? `Updated ${num} segments` : 'No changes') });
									})}
									title="Copy whitelisting"
									selectorType="PopupSelector"
								/>
							</OperationWrapper>
						</Grid>
						{siteInfo._id
						&& (
							<Grid item xs={12}>
								<Link to={siteTagUrl}>Audience tag</Link>
							</Grid>
						)}
					</Grid>
				</Box>
			</Paper>
		);
	}

	renderAudienceInfo() {
		const {
			field, cxDmpSites, collectionAdd, history, publisherId, docTemplates,
		} = this.props;
		const {
			expanded, showSites, cxSiteInfo, usedSiteIds,
		} = this.state;
		const masterTagUrl = BrowserUtils.makeQs('/settings/tag-data-edit', {
			type: 'publisherId',
			accountId: publisherId,
			id: publisherId,
		});
		return (
			<Paper>
				<Box padding={2}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h2">
								Relevant Audience Info
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Link to={masterTagUrl}>Master Audience tag</Link>
						</Grid>
						<Grid item xs={12}>
							<TextField
								{...field('publisher.raContractualCut')}
								label="Contractual Revenue share(%)"
								between={{ low: 0, high: 100 }}
								required
								float
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<InvoiceInfo type="audience" docTemplates={docTemplates} field={field} />
						</Grid>
						<Grid item xs={12}>
							<CollapseHeader
								expanded={expanded}
								onClick={this.handleExpandChange}
								title="CxDMP Sites"
								variant="h3"
							/>
							<OperationWrapper
								fn={this.loadSiteInfo}
								load={showSites}
							>
								<Collapse in={expanded} unmountOnExit>
									{cxDmpSites && cxSiteInfo && (
										<Grid container spacing={3}>
											{/* Additional grid container is needed because items need to be direct descendants */}
											{cxDmpSites.map((dmpSite, dmpSiteIndex) => (
												<Grid key={dmpSiteIndex} item xs={12}>
													{this.renderDmpSite(dmpSite, dmpSiteIndex)}
												</Grid>
											))}
											<Grid item xs={12}>
												<Select
													label="Add from existing sites without publisher"
													name="addNew"
													items={(() => {
														const avail = Object.values(cxSiteInfo).filter((s) => !usedSiteIds[s.id] && !cxDmpSites.find((ds) => ds.id === s.id));
														return MiscUtils.alphaSorted(avail.map((s) => ({ label: s.name, value: s.id })), 'label');
													})()}
													onChange={(ev) => {
														collectionAdd('publisher.cxDmpSites', { id: ev.target.value, name: cxSiteInfo[ev.target.value].name });
													}}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12}>
												<Tooltip title="Add a new DMP Site">
													<IconButton
														onClick={() => collectionAdd('publisher.cxDmpSites', { id: '', name: '' })}
														size="large"
													>
														<AddIcon />
													</IconButton>
												</Tooltip>
											</Grid>
										</Grid>
									)}
								</Collapse>
							</OperationWrapper>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		);
	}

	render() {
		const { infoMessage } = this.state;
		const { value } = this.props;
		const audienceAccess = value('publisher.audienceAccess');
		const programmaticAccess = value('publisher.programmaticAccess');
		const anyAccess = audienceAccess || programmaticAccess;
		return (
			<>
				<Dialog
					open={!!infoMessage}
					text={infoMessage}
					onClose={() => this.setState({ infoMessage: null })}
				/>
				<Grid item md={anyAccess ? 5 : 12} xs={12}>
					{this.renderCorePublisherInfo()}
				</Grid>
				{anyAccess
					? (
						<Grid item md={anyAccess ? 7 : 12} xs={12}>
							{audienceAccess ? (
								<Box marginBottom={programmaticAccess ? 3 : 0}>
									{this.renderAudienceInfo()}
								</Box>
							) : null}
							{programmaticAccess ? this.renderProgrammaticInfo() : null}
						</Grid>
					)
					: null}
			</>
		);
	}
}

PublisherForm.propTypes = {
	field: PropTypes.func.isRequired,
	value: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	collectionAdd: PropTypes.func.isRequired,
	collectionRemove: PropTypes.func.isRequired,
	checkForErrors: PropTypes.func.isRequired,
	cxDmpSites: PropTypes.array.isRequired,
	isNew: PropTypes.bool.isRequired,
	systems: PropTypes.object.isRequired,
	publisherId: PropTypes.string,
	publisher: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	docTemplates: PropTypes.array.isRequired,
	publisherNode: PropTypes.object.isRequired,
};

PublisherForm.defaultProps = {
};

export default PublisherForm;
