import { React, useState } from 'react';
import {
	FormLabel,
	RadioGroup,
	Radio,
	FormControl,
	FormControlLabel,
	Box,
	Slider,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import Switch from '../Switch';

export default function GeneralChartSettings({
	autoChartType, chartSettings, onChange, showChartTypeSelector,
}) {
	const {
		chartType,
		maxChartsPerRow,
	} = chartSettings;

	const [input, setInput] = useState({
		...chartSettings, chartType,
	});

	const updateInput = (e) => {
		const newInput = { ...input, [e.target.name]: e.target.value };
		setInput(newInput);
		onChange(newInput);
	};

	const isType = (t) => autoChartType === t;

	const marks = [...Array(10).keys()].map((index) => ({ value: index + 1, label: `${index + 1}` }));

	return (
		<div>
			<FormControl fullWidth>
				<FormControl>
					<FormLabel id="max-row-label">
						<Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
							<Typography variant="h3">Maximum charts per row</Typography>
						</Box>
					</FormLabel>
					<FormControlLabel
						control={(
							<Slider
								sx={{ ml: 3 }}
								aria-label="Charts per row"
								value={maxChartsPerRow || 1}
								name="maxChartsPerRow"
								valueLabelDisplay="auto"
								step={1}
								marks={marks.filter((m) => m.value <= 6)}
								min={1}
								max={6}
								onChange={(e) => {
									updateInput(e);
								}}
							/>
						)}
					/>
					{(showChartTypeSelector) && (
						<FormControl>
							<FormLabel component="legend" id="chart-type">
								<Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
									<Typography variant="h3">Graph type</Typography>
								</Box>
							</FormLabel>
							<RadioGroup
								aria-labelledby="chart-type-label"
								name="chartType"
								value={chartType || 'auto'}
								onChange={(e) => {
									updateInput(e);
								}}
							>
								<FormControlLabel value="auto" control={<Radio />} label="Auto" />
								<FormControlLabel value="line" control={<Radio />} label="Line chart" />
								<FormControlLabel value="bar" control={<Radio />} label="Bar chart" />
							</RadioGroup>
						</FormControl>
					)}
				</FormControl>
				{(isType('bar')) && (
					<FormControl>
						<FormLabel id="bar-chart-type-label">
							<Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
								<Typography variant="h3">Orientation</Typography>
							</Box>
						</FormLabel>
						<RadioGroup
							aria-labelledby="bar-chart-type-label"
							value={input.barChartType ?? 'vertical'}
							name="barChartType"
							onChange={(e) => {
								updateInput(e);
							}}
						>
							<FormControlLabel value="vertical" control={<Radio />} label="Vertical" />
							<FormControlLabel value="horizontal" control={<Radio />} label="Horizontal" />
						</RadioGroup>
					</FormControl>
				)}
				{(isType('line')) && (
					<FormControl>
						<FormLabel component="legend" id="force-chart-type">
							<Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
								<Typography variant="h3">Graph style</Typography>
							</Box>
						</FormLabel>
						<FormControlLabel
							control={(
								<Switch
									name="stacked"
									value={input.stacked}
									color="primary"
									onChange={(e) => {
										updateInput(e);
									}}
								/>
							)}
							label="Stacked"
						/>
						<FormControlLabel
							control={(
								<Switch
									name="fill"
									value={input.fill}
									color="primary"
									onChange={(e) => {
										updateInput(e);
									}}
								/>
							)}
							label="Filled"
						/>
						<FormLabel id="point-radius-label">
							<Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
								<Typography variant="h3">Point Radius</Typography>
							</Box>
						</FormLabel>
						<FormControlLabel
							control={(
								<Slider
									sx={{ ml: 3 }}
									aria-label="Point Radius"
									name="pointRadius"
									value={input.pointRadius || 1}
									valueLabelDisplay="auto"
									step={1}
									marks={marks}
									min={1}
									max={marks.length}
									onChange={(e) => {
										updateInput(e);
									}}
								/>
							)}
						/>
						<FormLabel id="pointer-hover-label">
							<Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
								<Typography variant="h3">Point Hover Radius</Typography>
							</Box>
						</FormLabel>
						<FormControlLabel
							control={(
								<Slider
									sx={{ ml: 3 }}
									aria-label="Point Hover Radius"
									name="pointHoverRadius"
									value={input.pointHoverRadius || 1}
									valueLabelDisplay="auto"
									step={1}
									marks={marks}
									min={1}
									max={marks.length}
									onChange={(e) => {
										updateInput(e);
									}}
								/>
							)}
						/>
					</FormControl>
				)}
			</FormControl>
		</div>
	);
}

GeneralChartSettings.propTypes = {
	chartSettings: PropTypes.shape({
		stacked: PropTypes.bool.isRequired,
		fill: PropTypes.bool.isRequired,
		barChartType: PropTypes.oneOf(['horizontal', 'vertical']),
		chartType: PropTypes.oneOf(['auto', 'bar', 'line']),
		maxChartsPerRow: PropTypes.number.isRequired,
		pointRadius: PropTypes.number,
		pointHoverRadius: PropTypes.number,
	}),
	onChange: PropTypes.func.isRequired,
	showChartTypeSelector: PropTypes.bool,
	autoChartType: PropTypes.oneOf(['bar', 'line']).isRequired,
};

GeneralChartSettings.defaultProps = {
	chartSettings: {
		barChartType: 'vertical',
		pointHoverRadius: 2,
		pointRadius: 1,
		chartType: 'auto',
	},
	showChartTypeSelector: true,
};
