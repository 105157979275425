import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DataTable from '../DataTable';
import Checkbox from '../Checkbox';
import TextField from '../TextField';
import RecordTableTools from '../RecordTableTools';
import { ActionButton } from '../ActionButton/ActionButton';
import DatePicker from '../DatePicker';
import OperationWrapper from '../OperationWrapper';
import DateUtils from '../../lib/dateUtils';
import ExpandSelector from '../ExpandSelector';

class FilterStatistics extends React.Component {
	static toStatArray(stats, revShare) {
		const THRESHOLD = 0;
		let res = _.map(stats, (s, siteId) => ({
			siteId,
			totalUnique: s.total.uniqueUsers,
			matchUnique: s.match.uniqueUsers,
			name: s.name,
			url: s.url,
			revSharePerc: revShare[siteId] && revShare[siteId] * 100,
		}));
		const totalUnique = res.reduce((total, s) => total + s.totalUnique, 0);
		const totalMatchUnique = res.reduce((total, s) => total + s.matchUnique, 0);
		const totalRevMatchUnique = res.filter((r) => r.revSharePerc).reduce((total, s) => total + s.matchUnique, 0);
		const totalMatchFactor = totalMatchUnique / totalUnique;
		res = res.filter((s) => s.totalUnique >= THRESHOLD && s.matchUnique);
		if (!res.length) {
			return [];
		}
		res.forEach((s) => (s.matchFactor = s.matchUnique / s.totalUnique));
		const highest = _.maxBy(res, (s) => s.matchFactor).matchFactor;
		res.forEach((s) => (s.matchPerc = (s.matchFactor / highest) * 100));
		res.sort((a, b) => b.matchPerc - a.matchPerc);
		res.reduce((completedMatchUniques, s) => {
			const includingMe = completedMatchUniques + s.matchUnique;
			s.percOfAllMatches = (s.matchUnique / totalMatchUnique) * 100;
			s.percOfAllRevMatches = s.revSharePerc ? (s.matchUnique / totalRevMatchUnique) * 100 : undefined;
			s.percIncludingMe = (includingMe / totalMatchUnique) * 100;
			s.relativeMatchFactor = s.matchFactor / totalMatchFactor;
			return includingMe;
		}, 0);
		return res;
	}

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.fetchApplicationSettings();
	}

	async fetchApplicationSettings() {
		const { segment, path } = this.props;
		const { start, ignoreRevenueSites, noFilterTransforms } = this.state;
		let { stop } = this.state;
		stop = DateUtils.fullDay(stop, 1);
		const [stats, revShare] = await Promise.all([
			segment.getSiteStatistics({
				path, start, stop, noFilterTransforms,
			}),
			segment.calcContributionRates({
				path, start, stop, ignoreRevenueSites, noFilterTransforms,
			}),
		]);
		this.setState({ stats, revShare });
	}

	sortTab(ev, rowNumber, columnIndex) {
		console.info(ev);
	}

	render() {
		const {
			stats, revShare, start, stop, ignoreRevenueSites, noFilterTransforms,
		} = this.state;
		const friendlyStats = stats ? FilterStatistics.toStatArray(stats, revShare) : [];
		const chartVals = friendlyStats.filter((s) => s.matchPerc > 0.5);
		const earliestDate = DateUtils.fullDay(new Date(), -30);
		const chartData = {
			labels: chartVals.map((s) => s.name),
			datasets: [
				{
					label: '% of best match %',
					backgroundColor: 'rgba(235, 57, 71, 0.6)',
					borderColor: 'rgba(235,57,71,1)',
					data: chartVals.map((s) => s.matchPerc),
				},
			],
		};
		const percFormatter = (perc) => (perc == null || Math.floor(perc) === perc ? perc : perc.toFixed(2));
		return (
			<>
				<DialogTitle>
					Statistics
				</DialogTitle>
				<DialogContent dividers>
					<OperationWrapper
						reloadable
						load={[ignoreRevenueSites, noFilterTransforms]}
						fn={async (op) => {
							this.op = op;
							this.fetchApplicationSettings();
						}}
					>
						{stats && (
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Line data={chartData} />
								</Grid>
								<Grid item xs={12}>
									<ExpandSelector title="Custom data range" expanded={start || stop}>
										<DatePicker
											floatingLabelText="First date"
											maxDate={stop || DateUtils.yesterday()}
											minDate={earliestDate}
											name="from"
											value={start}
											autoOk
											onChange={(ev) => this.setState({ start: DateUtils.fullDay(ev.target.value) })}
											fullWidth
										/>
										<DatePicker
											floatingLabelText="Last date"
											minDate={start || earliestDate}
											maxDate={DateUtils.yesterday()}
											name="to"
											value={stop}
											autoOk
											onChange={(ev) => this.setState({ stop: DateUtils.fullDay(ev.target.value) })}
											fullWidth
										/>
										{start && stop
										&& (
											<ActionButton
												label="Update"
												color="primary"
												onClick={() => this.op.reload(async () => this.fetchApplicationSettings())}
											/>
										)}
									</ExpandSelector>
								</Grid>
								<Grid item xs={6}>
									<Card style={{ }}>
										<CardContent>
											<Typography variant="h2">
												Total numbers
											</Typography>
											<DataTable
												showCheckboxes={false}
												identifier={() => 'one'}
												definitions={[
													{
														key: 'totalUnique',
														title: 'Total unique',
													},
													{
														key: 'matchUnique',
														title: 'Segment unique',
													},
													{
														key: 'matchUnique',
														title: 'Match %',
														format: (matchUnique, row) => percFormatter((matchUnique / (row.totalUnique || 1)) * 100),
													},
												]}
												data={[{
													totalUnique: _.sumBy(friendlyStats, 'totalUnique'),
													matchUnique: _.sumBy(friendlyStats, 'matchUnique'),
												}]}
											/>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12}>
									<Checkbox
										label="Ignore whitelisting/blacklisting'"
										name="ignoreRevenueSites"
										value={ignoreRevenueSites}
										onChange={(ev) => this.setState({ ignoreRevenueSites: ev.target.value })}
									/>
								</Grid>
								<Grid item xs={12}>
									<Checkbox
										label="Don't transform filters when requesting traffic/events"
										name="noFilterTransforms"
										value={noFilterTransforms}
										onChange={(ev) => this.setState({ noFilterTransforms: ev.target.value })}
									/>
								</Grid>
								<Grid item xs={12}>
									<DataTable
										showCheckboxes={false}
										identifier={(row) => row.siteId}
										headerProps={{ onClick: (...a) => this.sortTab(...a) }}
										definitions={[
											{
												key: 'siteId',
												title: 'Site ID',
												style: { width: '150px' },
											},
											{
												key: 'name',
												title: 'Site',
												style: { width: '100px' },
											},
											{
												key: 'url',
												title: 'URL',
												style: { width: '170px' },
												format: (url) => (<a href={url}>{url}</a>),
											},
											{
												key: 'totalUnique',
												title: 'Total unique',
											},
											{
												key: 'matchUnique',
												title: 'Segment unique',
											},
											{
												key: 'matchFactor',
												title: 'Match %',
												format: (p) => percFormatter(p * 100),
											},
											{
												key: 'matchPerc',
												title: '% of highest',
												format: percFormatter,
											},
											{
												key: 'percOfAllMatches',
												title: '% of all matches',
												format: percFormatter,
											},
											{
												key: 'percOfAllRevMatches',
												title: '% of all rev matches',
												format: percFormatter,
											},
											{
												key: 'percIncludingMe',
												title: 'Accumulated %',
												format: percFormatter,
											},
											{
												key: 'relativeMatchFactor',
												title: 'Match factor',
												format: percFormatter,
											},
											{
												key: 'revSharePerc',
												title: 'Revenue share',
												format: percFormatter,
											},
										]}
										data={friendlyStats}
									/>
								</Grid>
							</Grid>
						)}
					</OperationWrapper>
				</DialogContent>
			</>
		);
	}
}

FilterStatistics.propTypes = {
	segment: PropTypes.object.isRequired,
	path: PropTypes.string,
};

FilterStatistics.defaultProps = {
	path: undefined,
};

export default FilterStatistics;
