import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import linkable from '../linkable';

function GhostingButton(props) {
	return (
		<IconButton {...props} size="large">
			<VisibilityIcon />
		</IconButton>
	);
}

GhostingButton.propTypes = {
	href: PropTypes.string,
	onClick: PropTypes.func,
};

GhostingButton.defaultProps = {
	href: undefined,
	onClick: undefined,
};

export default linkable(GhostingButton);
