import React, {
	useEffect, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { GoogleAnalytics } from '../../api/relevant';
import PopupItemSelect from '../PopupItemSelect';
import styles from './styles.css';

function createProfileSelectionItems(availableProfiles, savedProfileIds, shouldSelectAll = false) {
	const mappedProfiles = {};
	availableProfiles.forEach(({ profileId, name, url }) => {
		mappedProfiles[profileId] = {
			label: name,
			url,
			profileId,
			selected: shouldSelectAll || savedProfileIds.includes(profileId),
		};
	});

	return mappedProfiles;
}

function GoogleAnalyticsProfileSelect({
	isNew, savedProfileIds, analyticsSystemId, onChange, authToken, isVisible,
}) {
	const [profiles, setProfiles] = useState({});
	// Profiles from api
	const [availableProfiles, setAvailableProfiles] = useState([]);
	const isInitialRender = useRef(true);

	const onAnalyticsProfileSelectionChange = useCallback((updatedProfiles) => {
		const selectedIds = Object.entries(updatedProfiles)
			.reduce((acc, [id, { selected }]) => (selected ? [...acc, id] : acc), []);

		// Left this out on purpose from dependency array as we cant memoize it from parent component,
		// because its not a functional-style component. Anyway, it shouldn't be a problem.
		onChange(selectedIds);
	}, []);

	const fetchAvailableGoogleAnalyticsProfiles = useCallback(async (token) => {
		const payload = {
			analyticsSystemId,
			...(token && { token }),
		};

		const res = await GoogleAnalytics.call('getAvailableProfiles', payload);
		setAvailableProfiles(res);
	}, [analyticsSystemId]);

	/**
	 * Fetch available GA profiles if:
	 * 1. We're editing an already existing GA config and its the first render
	 * 2. We have authenticated to a new account, i.e authToken is defined.
	*/
	useEffect(() => {
		if (((!isNew && isInitialRender.current) || authToken)) {
			fetchAvailableGoogleAnalyticsProfiles(authToken);
			isInitialRender.current = false;
		}
	}, [isNew, fetchAvailableGoogleAnalyticsProfiles, authToken]);

	useEffect(() => {
		if (availableProfiles.length > 0) {
			// Assuming that profileIds are unique for an account.
			const hasChangedAccount = availableProfiles.find(({ profileId }) => savedProfileIds.includes(profileId)) === undefined;
			const shouldSelectAll = (isNew && savedProfileIds.length === 0) || hasChangedAccount;

			const mappedProfiles = createProfileSelectionItems(availableProfiles, savedProfileIds, shouldSelectAll);
			setProfiles(mappedProfiles);

			if (hasChangedAccount) {
				onAnalyticsProfileSelectionChange(mappedProfiles);
			}
		}
	}, [isNew, savedProfileIds, availableProfiles, onAnalyticsProfileSelectionChange]);

	if (Object.keys(profiles).length === 0) {
		return null;
	}

	const header = 'Select Google Analytics profiles';
	const numSelectedItems = Object.values(profiles).filter(({ selected }) => selected).length;

	const buttonText = numSelectedItems > 0 ? `${numSelectedItems} selected Google Analytics profiles` : header;

	return (
		<div style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
			<PopupItemSelect
				items={profiles}
				onChange={onAnalyticsProfileSelectionChange}
				header={header}
				buttonText={buttonText}
				getOptionSubText={(option) => (
					<Box component="div" mt={-1} pl={4}>
						<div
							className={styles.optionSubText}
							title="Google Analytics Profile ID"
						>
							{option.profileId}
						</div>
						<div
							className={styles.optionSubText}
							title="Google Analytics Profile URL"
						>
							{option.url}
						</div>
					</Box>
				)}
			/>
		</div>
	);
}

export default GoogleAnalyticsProfileSelect;

GoogleAnalyticsProfileSelect.propTypes = {
	isNew: PropTypes.bool.isRequired,
	savedProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
	analyticsSystemId: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	authToken: PropTypes.string,
	isVisible: PropTypes.bool,
};

GoogleAnalyticsProfileSelect.defaultProps = {
	authToken: undefined,
	onChange: () => undefined,
	isVisible: true,
};
