const _ = require('lodash');
const DiffArr = require('./details/diffArr');
const DiffConstants = require('./details/diffConstants');
const DiffFns = require('./details/diffFns');

const { getType, isDiffObject, withCtx } = DiffFns;

const {
	DELETE_MARKER,
	OBJECT_DIFF_MARKER,
	ARRAY_DIFF_MARKER,
	ARRAY,
	ID_STRING,
	PRIMITIVE,
} = DiffConstants;

const isDbIdField = (obj, key) => {
	if (key === '_id') {
		return true;
	}
	if (key === 'id' && obj._id && obj._id.toString() === obj.id) {
		return true;
	}
	return false;
};

/** Returns the "diff" between two values, this can turn out to be any of:
 *  undefined - this means there is no difference.
 *  a new value - when applying the diff we should overwrite the existing value with a new one
 *  an array-diff - when both 'orgVal' and 'newVal' are arrays
 *  an object-diff - when both 'orgVal' and 'newVal' are objects (that are not arrays)
 */
const getValueDiff = (orgVal, newVal) => {
	if (newVal === orgVal) {
		return undefined; // No change
	}
	if (orgVal === undefined) {
		return newVal; // new member
	}
	const type = getType(newVal);
	const orgType = getType(orgVal);
	if (type !== orgType) {
		return newVal; // type changed
	}
	if (type === PRIMITIVE || type === ID_STRING) {
		return newVal; // primitive value changed
	}
	let diffObj;
	if (type === ARRAY) {
		diffObj = DiffFns.getArrayDiff(orgVal, newVal);
	} else { // Objects
		if (orgVal._id !== newVal._id) {
			return newVal;
		}
		diffObj = DiffFns.getObjectDiffInternal(orgVal, newVal);
	}
	if (diffObj) {
		return diffObj;
	}
	return undefined;
};

/** Returns an "array-diff-object" that contain differences between two arrays. The result will
 *  at a later stage be used as a parameter to DiffArr.convertWithDiff(). If there is no difference
 *  undefined will be returned */
const getArrayDiff = (orgRawArr, newRawArr) => {
	const extraFields = {};
	let orgArr;
	let newArr;
	const uniquePrimitives = DiffFns.isUniquePrimitivesArray(orgRawArr) && DiffFns.isUniquePrimitivesArray(newRawArr);
	if (uniquePrimitives) { // Let's use the unique primitives in arrays as identifier
		orgArr = new DiffArr(orgRawArr, false, true);
		newArr = new DiffArr(newRawArr, false, true);
		// It looks like the change has been done by changing
		extraFields.uniquePrimitives = true;
	} else {
		orgArr = new DiffArr(orgRawArr);
		newArr = new DiffArr(newRawArr);
		if (orgArr.hasIdDuplicates || newArr.hasIdDuplicates) {
			// Ok, so we've tried to use other ways to get ids of elements than using obj._id
			// That is currently - strings that looks like mongoDb ids.
			// However we've found duplicates (not supported), let's fall back to only use objects with ._id fields
			orgArr = new DiffArr(orgRawArr, true);
			newArr = new DiffArr(newRawArr, true);
			if (orgArr.hasIdDuplicates || newArr.hasIdDuplicates) {
				if (orgArr.hasIdDuplicates) {
					// Emergency solution to work around an old bug that caused multiple ssp-placements
					// to have the same _id. In case there are '_id' duplicates => overwrite the old array with the new
					return DiffArr.createOverwriteDiff(orgRawArr, newRawArr);
				}
				throw Error('Duplicated ids detected in array'); // duplicates in NEW array but not in old!
			}
			extraFields.onlyUseIdsFromIdObjects = true;
		}
	}
	newArr.applyOrgDiffArray(orgArr);
	return newArr.getDiff(extraFields);
};

/**
 * Gets a "diff" between an original object 'orgObj' and an updated object 'newObj'. This diff-object can
 * then at a later stage be "applied" to another object by using the 'applyDiff()' function. The practical use
 * case is that 'orgObj' is an object read from the server while newObject would be the result of some
 * UI-editing by the user. When "saving", only the diff-object is sent to the server and applied to
 * the current version of the object in the database. This procedure is done so that any changes to the object
 * done in-between the user first reading 'orgObj' and saving won't be lost.
 * Both orgObj and newObj must be plain objects as obtained by JSON.parse(JSON.stringify(obj))
 * @param {Object} orgObj - The original plain object (probably fetched from a server)
 * @param {Object} newObj - The updated object
 * @return {Object} The diff-object that can later be used as a parameter to 'applyDiff()'
 */
const getObjectDiffInternal = (orgObj, newObj) => {
	const res = {};
	_.forOwn(orgObj, (v, k) => {
		if (newObj[k] === undefined) {
			res[k] = DELETE_MARKER;
		}
	});
	_.forOwn(newObj, (v, k) => {
		if (isDbIdField(newObj, k)) {
			return;
		}
		const diffVal = getValueDiff(orgObj[k], v);
		if (diffVal !== undefined) {
			res[k] = diffVal;
		}
	});
	delete res._diffIdentifier; // this one isn't needed in diff
	if (_.isEmpty(res)) {
		return null;
	}
	res[OBJECT_DIFF_MARKER] = true;
	return res;
};

// Assign a value when applying the diff. Even though a diff for this value wasn't created, probably as the
// destination value didn't exist at the time - it's now possible the destination is something we should
// merge the source 'val' into. Therefore we're creating + applying a diff again.
const assignValue = (dstObj, key, val) => {
	const dstVal = dstObj[key];
	const valDiff = getValueDiff(dstVal, val);
	if (valDiff === undefined) {
		return;
	}
	// As the desination didn't exist before, we don't want to remove anything, so let's use 'noRemove'
	DiffFns.withFlag('noRemove', () => {
		const isObj = _.isObject(valDiff);
		if (isObj && valDiff[OBJECT_DIFF_MARKER]) { // valDiff is a nested object-diff
			DiffFns.applyDiffInternal(dstVal, valDiff);
		} else if (isObj && valDiff[ARRAY_DIFF_MARKER]) { // valDiff is an array-diff
			dstObj[key] = DiffArr.convertWithDiff(dstVal, valDiff);
		} else {
			dstObj[key] = valDiff;
		}
	});
};

/** Applies the diff 'diffObj' to object 'dstObj' */
const applyDiffInternal = (dstObj, diffObj) => {
	const { noRemove, createEmptyWhenNeeded } = DiffFns.ctx;
	if (!diffObj[OBJECT_DIFF_MARKER]) {
		throw Error('Diff corrupted (missing object diff marker)');
	}
	_.forOwn(diffObj, (v, k) => {
		if (isDbIdField(dstObj, k)) {
			return;
		}
		if (k === OBJECT_DIFF_MARKER) {
			return; // ignore diff marker
		}
		if (v === DELETE_MARKER) {
			if (!noRemove) {
				delete dstObj[k]; // a delete of a property
			}
			return;
		}
		const dstVal = dstObj[k];
		if (v) {
			if (v[OBJECT_DIFF_MARKER]) { // v is a nested object-diff
				if (_.isObject(dstVal)) {
					DiffFns.applyDiffInternal(dstVal, v);
				} else if (createEmptyWhenNeeded) {
					dstObj[k] = {};
					DiffFns.applyDiffInternal(dstObj[k], v);
				}
				// Else: update of now deleted object, ignore these updates as otherwise we might re-construct
				// "partial" objects that doesn't make sense. (although for arrays we can do this, see below)
				return;
			}
			if (v[ARRAY_DIFF_MARKER]) { // v is an array-diff
				if (_.isArray(dstVal)) {
					const newArr = DiffArr.convertWithDiff(dstVal, v);
					// Workaround for Mongoose-bug that breaks validation when doing changes in objects
					// that also will get different indexes in the array. Hence the strange the splice + assigment.
					dstVal.splice(0);
					dstObj[k] = newArr;
				} else if (createEmptyWhenNeeded || dstVal === undefined || dstVal === null) {
					dstObj[k] = DiffArr.convertWithDiff([], v);
				} // Else: update of an array replaced with something else (not array/undefined/null), ignore.
				return;
			}
		}
		assignValue(dstObj, k, v);
	});
};

const getObjectDiff = function (orgObj, newObj) {
	const res = withCtx(() => getObjectDiffInternal(orgObj, newObj));
	return JSON.parse(JSON.stringify(res, (key, val) => {
		if (key === '_diffIdentifier' || key === '_diffType') {
			return undefined;
		}
		return val;
	}));
};

const applyDiff = (dstObj, diffObj) => {
	withCtx(() => applyDiffInternal(dstObj, diffObj));
};

// We need to add these functions here instead of defining them in DiffFns as there are some circular dependencies
// between these functions and DiffArr/DiffObjRange because of the recursive nature of this..
Object.assign(DiffFns, {
	getObjectDiffInternal,
	getArrayDiff,
	getValueDiff,
	applyDiffInternal,
});

module.exports = {
	getObjectDiff,
	applyDiff,
	isDiffObject,
};
