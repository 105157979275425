import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { Segment, Pricelist } from '../../api/relevant';
import BrowserUtils from '../../lib/browserUtils';
import ExternalSystemEdit from '../../components/ExternalSystemEdit';
import FormCollection from '../../lib/formCollection';
import SystemData from '../../lib/systemData';
import PopupSelector from '../../components/PopupSelector';

class SystemEditPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.formCollection = new FormCollection();
	}

	id() {
		const { id } = this.props.match.params;
		return id.toLowerCase() === 'add' ? null : id;
	}

	async init() {
		const { vars, type } = this.props;
		const id = this.id();
		const childProps = {};
		const allOps = {
			segments: () => Segment.list(),
			pricelists: () => Pricelist.list(),
			types: () => type.call('getEmptyTypes'),
			initObj: () => (id ? type.get(id) : null),
		};
		await Promise.all(_.union(['types', 'initObj'], vars).map((v) => (async () => {
			childProps[v] = await allOps[v]();
		})()));
		const { publisherId } = BrowserUtils.urlParams();
		if (publisherId) {
			childProps.types.forEach((ty) => {
				ty.publisherId = publisherId;
			});
		}
		this.setState({
			childProps,
			model: childProps.initObj || childProps.types[0],
		});
	}

	async doUpdate() {
		const { editor } = this.priceListContext;
		const { childProps, model } = this.state;
		const { type } = this.props;
		if (editor) {
			model.priceList = editor.toPriceList();
		}
		if (this.id()) {
			if (childProps.initObj.type !== model.type) {
				// Type has changed, this needs special treatment as an "object replacement" must be done
				await type.add({
					...model,
					updateParams: { replaceExistingId: true },
				});
			} else {
				await model.update();
			}
		} else {
			await type.add(model);
		}
		SystemData.invalidateSystemData();
	}

	render() {
		const {
			backUrl, desc, onChange, history,
		} = this.props;
		const { childProps, model } = this.state;
		const id = this.id();
		this.priceListContext = { ...childProps, pricelist: model && model.priceList };
		const goBack = () => backUrl && history.push(backUrl);
		return (
			<PopupSelector
				title={`${this.id() ? 'Edit' : 'Add'} ${desc}`}
				fn={() => this.init()}
				size="md"
				forceExpanded
				allowErrorsIfCancel
				forms={this.formCollection}
				okLabel="SAVE"
				customLink={() => undefined}
				onApplyChanges={async () => {
					await this.doUpdate();
					if (onChange) {
						onChange();
					}
					goBack();
				}}
				onCancel={goBack}
				content={() => (
					<ExternalSystemEdit
						{...this.state.childProps}
						desc={desc}
						model={model}
						formCollection={this.formCollection}
						changeModel={(obj) => this.setState({ model: obj })}
						id={id}
						priceListContext={this.priceListContext}
					/>
				)}
			/>
		);
	}
}

SystemEditPage.propTypes = {
	vars: PropTypes.arrayOf(PropTypes.string),
	match: PropTypes.object.isRequired,
	backUrl: PropTypes.string,
	desc: PropTypes.string.isRequired,
	type: PropTypes.object.isRequired,
	onChange: PropTypes.func,
};

SystemEditPage.defaultProps = {
	vars: [],
	backUrl: '',
	onChange: undefined,
};

export default withRouter(SystemEditPage);
