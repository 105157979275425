import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import linkable from '../linkable';

function EditButton(props) {
	return (
		<IconButton {...props} size="large">
			<EditIcon />
		</IconButton>
	);
}

EditButton.propTypes = {
	href: PropTypes.string,
	onClick: PropTypes.func,
};

export default linkable(EditButton);
