import React, { useEffect, useState } from 'react';
import {
	Paper,
	Container,
	CircularProgress,
} from '@mui/material';
import _ from 'lodash';
import EventTable from '../../components/EventTable';
import EventForm from '../../components/EventForm';

import classes from '../../api/classes';
import useTopBar from '../../hooks/useTopbar';
import { Toast } from '../../components/Toast/Toast';
import SidePopout from '../../components/SidePopout';

const { Event } = classes;

const initialFormData = {
	title: '',
	description: '',
	date: null,
	tags: [],
	public: false,
};

function EventPage() {
	const [events, setEvents] = useState([]);
	const [addViewIsOpen, setAddViewIsOpen] = useState(false);
	const [toastData, setToastData] = useState({
		type: '',
		message: '',
	});
	const [editedEventData, setEditedEventData] = useState(initialFormData);
	const { setSettings: setTopBarSettings } = useTopBar();

	const setUpdatedEvents = (updatedEvents) => setEvents([...updatedEvents].reverse());

	useEffect(() => {
		setTopBarSettings({
			type: 'events',
			onAddClick: () => {
				setEditedEventData(initialFormData);
				setAddViewIsOpen(true);
			},
		});
	}, [setTopBarSettings]);

	useEffect(() => {
		const fetchEvents = async () => {
			const fetchedEvents = await Event.fnCache.getEvents();
			setUpdatedEvents(fetchedEvents);
		};
		fetchEvents();
	}, []);

	// Close the toast after 10 seconds
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (toastData.type !== 'loading') {
				setToastData({});
			}
		}, 10000);
		return () => clearTimeout(timeout);
	}, [toastData.message, toastData.type]);

	return (
		<Container maxWidth="xl">
			<SidePopout open={addViewIsOpen}>
				<EventForm
					data={editedEventData}
					onSubmit={async (updatedEvents, actionType) => {
						setUpdatedEvents(updatedEvents);
						setAddViewIsOpen(false);
						setToastData({
							type: 'success',
							message: `Event ${actionType === 'add' ? 'added' : 'updated'}.`,
						});
					}}
					onFormDataChange={(data) => setEditedEventData(data)}
					availableTags={_.uniq(events.map((event) => event.tags).flat())}
					onClose={() => {
						setAddViewIsOpen(false);
					}}
				/>
			</SidePopout>
			<Paper variant="outlined">
				<EventTable
					events={events}
					onDelete={async (updatedEvents) => {
						setUpdatedEvents(updatedEvents);
						setToastData({
							type: 'info',
							message: 'Event deleted.',
						});
					}}
					onEdit={(id) => {
						setAddViewIsOpen(true);
						const eventToEdit = events.find((event) => event.id === id);
						// setFormTitle('Edit event');
						setEditedEventData(eventToEdit);
					}}
					onLoading={() => {
						setToastData({
							type: 'loading',
							message: 'Deleting event...',
						});
					}}
				/>
			</Paper>
			<Toast
				open={Boolean(toastData.message)}
				status={toastData.type === 'loading' ? 'info' : toastData.type}
				message={toastData.message}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				onClose={() => setToastData({})}
				icon={toastData.type === 'loading' ? <CircularProgress size="2rem" color="inherit" /> : undefined}
				AlertProps={{
					sx: { display: 'flex', alignItems: 'center' },
				}}
			/>
		</Container>
	);
}

export default EventPage;
