const {
	NATIVE_FIELDS,
	NATIVE_IMAGE_FIELDS,
} = require('./native');
const { splitParams } = require('./bidParamUtils');

/** Content in this file should not be included in tag-file in order to save space */

const finalizeNativeSettings = (settings) => {
	const { normal, internal } = splitParams(settings);
	const {
		nativeMode, adTemplate, rendererUrl, sendTargetingKeys,
	} = internal;
	if (!nativeMode || nativeMode === 'disabled') {
		return undefined;
	}
	const finalRes = {};
	NATIVE_FIELDS.forEach((fld) => {
		const obj = internal[fld] || {};
		const res = {};
		['required', 'disabled'].forEach((name) => {
			if (obj[name]) {
				res[name] = obj[name];
			}
		});
		if (NATIVE_IMAGE_FIELDS.includes(fld)) {
			if (obj.width && obj.height) {
				if (obj.useAspec) {
					res.aspect_ratios = [{
						min_width: obj.width,
						min_height: obj.height,
						ratio_width: obj.width,
						ratio_height: obj.height,
					}];
				} else {
					res.sizes = [obj.width, obj.height];
				}
			}
		} else if (obj.length) {
			res.len = obj.length;
		}
		if (Object.keys(res).length) {
			finalRes[fld] = res;
		}
	});
	return {
		nativeSettings: {
			...finalRes,
			sendTargetingKeys,
			adTemplate: (adTemplate || '').trim().length ? adTemplate : undefined,
			rendererUrl: rendererUrl || undefined,
			...normal,
		},
		internal,
	};
};

module.exports = {
	finalizeNativeSettings,
};
