import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Select from '../Select';
import DeleteButton from '../DeleteButton';
import SiteSelect from '../SiteSelect';
import SegmentSelect from '../SegmentSelect';
import TextField from '../TextField';
import ExpandSelector from '../ExpandSelector';

const GROUP_FORMATS = {
	exact: 'Exact',
	startsWith: 'Starts with',
	regex: 'Javascript Regexp',
};

const TRAFFIC_TRANSFORMS = {
	'': 'None',
	replaceWithRdeContent: 'Replace with keyword/rde-category',
};

function IconsHeader(props) {
	return (
		<div style={{ position: 'relative' }}>
			<div style={{ position: 'absolute', right: '0px' }}>
				<IconButton onClick={() => props.submit({ stayOnPage: true })} size="large">
					<DoneAllIcon />
				</IconButton>
				<DeleteButton
					onClick={props.onDelete}
				/>
			</div>
		</div>
	);
}

IconsHeader.propTypes = {
	submit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

const isExpanded = {};

class SelectWrapper extends React.Component {
	render() {
		const { component, obj, path } = this.props;
		const SelecComponent = component;
		return (
			<SelecComponent
				{...(_.omit(this.props, ['component', 'obj', 'path']))}
				selected={obj[path] || []}
				onChange={(items) => {
					obj[path] = items;
					this.forceUpdate();
				}}
			/>
		);
	}
}

SelectWrapper.propTypes = {
	component: PropTypes.func.isRequired,
	obj: PropTypes.object.isRequired,
	path: PropTypes.string.isRequired,
};

function CardBox({
	onSubmit, onDelete, title, expanded, children, onExpandChange,
}) {
	return (
		<ExpandSelector
			title={title}
			expanded={expanded}
			onExpandChange={onExpandChange}
		>
			<IconsHeader
				submit={onSubmit}
				onDelete={onDelete}
			/>
			<Box marginTop={1}>
				<Grid container spacing={3}>
					{children}
				</Grid>
			</Box>
		</ExpandSelector>
	);
}

CardBox.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	expanded: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
	onExpandChange: PropTypes.func.isRequired,
};

class RuleBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.fieldRefs = {};
	}

	setExpanded(val) {
		const { path } = this.props;
		let newVal = val;
		if (this.mustExpand()) {
			newVal = true;
		}
		isExpanded[path] = newVal;
		this.forceUpdate();
	}

	mustExpand() {
		const { rule } = this.props;
		return Object.values(this.fieldRefs).find((f) => f && f.props.error) || !rule.name || !rule.type;
	}

	isExpanded() {
		const { path } = this.props;
		return Boolean(this.mustExpand() || isExpanded[path]);
	}

	field(path) {
		const p = this.props.field(path);
		const { ref } = p;
		p.ref = (elm) => {
			this.fieldRefs[p.name] = elm;
			if (ref) {
				ref(elm);
			}
		};
		return p;
	}

	render() {
		const {
			flds, useContainer, submit, onDelete, rule,
		} = this.props;

		const expanded = this.isExpanded();
		const has = _.zipObject(flds, Array(flds.length).fill(true));
		const field = (p) => this.field(p);

		const tree = (
			<>
				{has.name
					&& (
						<Grid item xs={12}>
							<TextField
								label="Name"
								{...field('name')}
								required
								fullWidth
							/>
						</Grid>
					)}
				{has.type
					&& (
						<Grid item xs={12}>
							<TextField
								{...field('type')}
								label="Type"
								required
								fullWidth
							/>
						</Grid>
					)}
				{has.group
					&& (
						<Grid item xs={12}>
							<TextField
								{...field('group')}
								label="Group"
								fullWidth
							/>
						</Grid>
					)}
				{has.groupFormat
					&& (
						<Grid item xs={12}>
							<Select
								{...field('groupFormat')}
								label="How to match group name"
								items={_.map(GROUP_FORMATS, (label, value) => ({ label, value }))}
								fullWidth
							/>
						</Grid>
					)}
				{has.minRelativeMatchFactor
					&& (
						<Grid item xs={12}>
							<TextField
								{...(field('minRelativeMatchFactor'))}
								label="Minium match factor"
								float
								fullWidth
							/>
						</Grid>
					)}
				{has.minMatchPercent
					&& (
						<Grid item xs={12}>
							<TextField
								{...(field || field)('minMatchPercent')}
								label="Minium match %"
								integer
								between={{ low: 0, high: 100 }}
								fullWidth
							/>
						</Grid>
					)}
				{has.exponent
					&& (
						<Grid item xs={12}>
							<TextField
								{...field('exponent')}
								label="Exponent"
								float
								fullWidth
							/>
						</Grid>
					)}
				{has.multiplier
					&& (
						<Grid item xs={12}>
							<TextField
								label="Revenue multiplier"
								{...field('multiplier')}
								float
								fullWidth
							/>
						</Grid>
					)}
				{has.siteIds
					&& (
						<Grid item xs={12}>
							<SelectWrapper
								component={SiteSelect}
								title="Sites"
								isSites
								obj={rule}
								path="siteIds"
							/>
						</Grid>
					)}
				{has.segmentDmpIds
					&& (
						<Grid item xs={12}>
							<SelectWrapper
								component={SegmentSelect}
								obj={rule}
								path="segmentDmpIds"
							/>
						</Grid>
					)}
				{has.trafficTransform
					&& (
						<Grid item xs={12}>
							<Select
								{...field('trafficTransform')}
								label="Traffic event filter transform"
								items={_.map(TRAFFIC_TRANSFORMS, (label, value) => ({ label, value }))}
								fullWidth
							/>
						</Grid>
					)}
			</>
		);
		return useContainer ? (
			<CardBox
				expanded={expanded}
				onDelete={onDelete}
				onExpandChange={(newState) => this.setExpanded(newState)}
				onSubmit={submit}
				title={rule.name}
			>
				{tree}
			</CardBox>
		) : tree;
	}
}

RuleBox.propTypes = {
	field: PropTypes.func.isRequired,
	flds: PropTypes.arrayOf(PropTypes.string).isRequired,
	useContainer: PropTypes.bool,
	path: PropTypes.string,
	submit: PropTypes.func,
	onDelete: PropTypes.func,
	rule: PropTypes.object,
};

RuleBox.defaultProps = {
	useContainer: false,
	submit: () => {},
	onDelete: () => {},
	rule: {},
};

export default RuleBox;
