import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import _ from 'lodash';
import TextField from '../TextField';
import styles from './styles.css';
import AdformLogo from '../../assets/Adform.png';
import WideOrbitLogo from '../../assets/WideOrbit.jpg';
import AdxLogo from '../../assets/Google_Ad_Manager_logo.jpg';
import OpenXLogo from '../../assets/OpenX_logo.png';
import ImproveLogo from '../../assets/Improve_Digital_Logo.png';
import PubmaticLogo from '../../assets/Pubmatic_Logo.jpg';
import DistrictmLogo from '../../assets/DistrictM_logo.png';
import DFPLogo from '../../assets/Google_Ad_Manager_logo.jpg';
import SmartRtbLogo from '../../assets/Smart_RTB_logo.png';
import CriteoLogo from '../../assets/Criteo_Logo.png';
import IndexExchangeLogo from '../../assets/Index_Exchange_Logo.svg';
import DemoLogo from '../../assets/Relevant_yield_logo_topbar.png';
import WidespaceLogo from '../../assets/Widespace_logo.png';
import JustPremiumLogo from '../../assets/JustPremium.jpg';
import GmailLogo from '../../assets/gmail.png';
import AdnuntiusLogo from '../../assets/Adnuntius_logo.svg';
import RecognifiedLogo from '../../assets/recognified_logo.png';
import AmazonLogo from '../../assets/amazon_logo.svg';
import TeadsLogo from '../../assets/teads_logo.png';
import YieldLabLogo from '../../assets/yieldlab_logo.png';
import VlybyLogo from '../../assets/vlyby_logo.svg';
import AdDefendLogo from '../../assets/addefend_logo.svg';
import PlistaLogo from '../../assets/plista_logo.png';
import TaboolaLogo from '../../assets/taboola_logo.png';
import SeedtagLogo from '../../assets/seedtag_logo.png';
import UnrulyLogo from '../../assets/unruly_logo.png';
import RevContentLogo from '../../assets/revcontent_logo.png';
import ConnectAdLogo from '../../assets/connectad_logo.png';
import SmartClipLogo from '../../assets/smartclip_logo.png';
import HolidLogo from '../../assets/holid_logo.png';
import XandrLogo from '../../assets/xandr_logo.png';
import MagniteLogo from '../../assets/magnite_logo.png';
import OutbrainLogo from '../../assets/outbrain_logo.png';
import OneTagLogo from '../../assets/onetag_logo.png';
import RichAudienceLogo from '../../assets/richaudience.png';
import AdagioLogo from '../../assets/adagio_logo.png';
import SynacorMediaLogo from '../../assets/synacor-media-logo.png';
import NextMillenniumLogo from '../../assets/nextMillennium_logo.svg';
import DailymotionLogo from '../../assets/dailymotion_logo.png';
import SovrnLogo from '../../assets/sovrn-logo.png';
import ConnatixLogo from '../../assets/connatix_logo.png';
import SystemData from '../../lib/systemData';
import Base from '../../layouts/Base';
import { CenterContent } from '../Wrappers';
import PopupSelector from '../PopupSelector';
import CheckboxGroup from '../CheckboxGroup';
import SiteSelect from '../SiteSelect';
import Select from '../Select';
import { ConfirmDialog } from '../ConfirmDialog';

const SspLogos = [
	{
		type: 'AdformSsp',
		logo: AdformLogo,
	},
	{
		type: 'AdmetaSsp',
		logo: WideOrbitLogo,
	},
	{
		type: 'AdnuntiusSsp',
		logo: AdnuntiusLogo,
	},
	{
		type: 'AdxSsp',
		logo: AdxLogo,
	},
	{
		type: 'AppnexusSsp',
		logo: XandrLogo,
	},
	{
		type: 'CriteoSsp',
		logo: CriteoLogo,
	},
	{
		type: 'ImproveSsp',
		logo: ImproveLogo,
	},
	{
		type: 'IndexSsp',
		logo: IndexExchangeLogo,
	},
	{
		type: 'PubmaticSsp',
		logo: PubmaticLogo,
	},
	{
		type: 'RelevantSsp',
		logo: DemoLogo,
	},
	{
		type: 'RubiconSsp',
		logo: MagniteLogo,
	},
	{
		type: 'RtbPlusSsp',
		logo: SmartRtbLogo,
	},
	{
		type: 'OpenXSsp',
		logo: OpenXLogo,
	},
	{
		type: 'DFPSsp',
		logo: DFPLogo,
	},
	{
		type: 'DemoSsp',
		logo: DemoLogo,
	},
	{
		type: 'DistrictmSsp',
		logo: DistrictmLogo,
	},
	{
		type: 'WidespaceSsp',
		logo: WidespaceLogo,
	},
	{
		type: 'JustPremiumSsp',
		logo: JustPremiumLogo,
	},
	{
		type: 'MailImportSsp',
		logo: GmailLogo,
	},
	{
		type: 'RecognifiedSsp',
		logo: RecognifiedLogo,
	},
	{
		type: 'AmazonSsp',
		logo: AmazonLogo,
	},
	{
		type: 'TeadsSsp',
		logo: TeadsLogo,
	},
	{
		type: 'YieldLabSsp',
		logo: YieldLabLogo,
	},
	{
		type: 'VlybySsp',
		logo: VlybyLogo,
	},
	{
		type: 'AdDefendSsp',
		logo: AdDefendLogo,
	},
	{
		type: 'PlistaSsp',
		logo: PlistaLogo,
	},
	{
		type: 'TaboolaSsp',
		logo: TaboolaLogo,
	},
	{
		type: 'SeedTagSsp',
		logo: SeedtagLogo,
	},
	{
		type: 'UnrulySsp',
		logo: UnrulyLogo,
	},
	{
		type: 'RevContentSsp',
		logo: RevContentLogo,
	},
	{
		type: 'ConnectAdSsp',
		logo: ConnectAdLogo,
	},
	{
		type: 'SmartClipSsp',
		logo: SmartClipLogo,
	},
	{
		type: 'OutbrainSsp',
		logo: OutbrainLogo,
	},
	{
		type: 'OneTagSsp',
		logo: OneTagLogo,
	},
	{
		type: 'RichAudienceSsp',
		logo: RichAudienceLogo,
	},
	{
		type: 'AdagioSsp',
		logo: AdagioLogo,
	},
	{
		type: 'SynacorMediaSsp',
		logo: SynacorMediaLogo,
	},
	{
		type: 'NextMillenniumSsp',
		logo: NextMillenniumLogo,
	},
	{
		type: 'DailymotionSsp',
		logo: DailymotionLogo,
	},
	{
		type: 'SovrnSsp',
		logo: SovrnLogo,
	},
	{
		type: 'ConnatixSsp',
		logo: ConnatixLogo,
	},
	{
		type: 'HolidSsp',
		logo: HolidLogo,
	}
];

const MediaTypes = [
	{ value: 'mixed', label: 'Mixed' },
	{ value: 'banner', label: 'Banner' },
	{ value: 'video', label: 'Video' },
	{ value: 'native', label: 'Native' },
];

class SSPForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { OBSCURE_NAMING_ENABLED, hbmEnabled } = SystemData.genericData;
		const {
			field, form, object, placementNode,
		} = this.props;
		const { placementType } = placementNode;
		const {
			ssp, publisherNode, parentNode, shadows,
		} = object;
		const canSelectMediaType = placementType && placementType.mediaType === 'banner';
		const sspData = _.find(SspLogos, { type: ssp.sspStyleFrom || ssp.type });
		const logo = (sspData && sspData.logo) || ssp.logoUrl || null;
		const isShadow = object.type === 'SspShadowNode';
		return (
			<Card>
				<CardContent>
					<Grid container spacing={3} alignItems="center">
						<Grid item xs={11}>
							<Grid container spacing={3}>
								<Grid item xs={3}>
									<Grid container spacing={0}>
										<Grid item xs={12}>
											<span style={{ color: 'gray', fontWeight: 'bold' }}>{ssp.name}</span>
										</Grid>
										<Grid item xs={12}>
											<img
												src={logo || DemoLogo}
												alt="logo"
												className={styles.sspLogo}
												style={{
													...(isShadow && { filter: 'grayscale(100%)' }),
													pointerEvents: 'none',
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={9}>
									<CenterContent vertical>
										{OBSCURE_NAMING_ENABLED && <i>Not visible in demo mode</i>}
										{!OBSCURE_NAMING_ENABLED && (isShadow ? (
											<span>
												<span style={{ color: 'gray', fontWeight: 'bold' }}>
													COPY FROM:&nbsp;&nbsp;
												</span>
												{object.sspPlac.sspPlacementId}
												<div style={{ color: 'gray', fontSize: 'small' }}>
													{object.sspPlacNode.placementNode.name}
												</div>
											</span>
										) : (
											<TextField
												{...field('id')}
												label="ID"
												required
												fullWidth
												isolated={!shadows.length}
											/>
										))}
									</CenterContent>
								</Grid>
								<Grid item xs={12} style={{ padding: '4px 12px' }}>
									{object.isPbjsBidder && hbmEnabled && (
										<Grid container spacing={3}>
											<Grid item>
												<Box component="div" pl={1.5}>
													<PopupSelector
														title="Prebid configs"
														form={form}
														selected={object.prebidConfigs}
														content={(popup) => (
															<CheckboxGroup
																items={object.publisherNode.children.map((siteNode) => (
																	siteNode.obj.prebidConfigs.map((cfg) => ({
																		id: cfg.id,
																		text: `${siteNode.name} - ${cfg.name}`,
																	}))
																)).flat()}
																selected={object.prebidConfigs.map(({ id }) => id)}
																onChange={(items) => popup.formUpdate(() => {
																	object.obj.pbCfgs = items;
																})}
															/>
														)}
													/>
												</Box>
											</Grid>
											{!isShadow && (
												<>
													<Grid item>
														<PopupSelector
															title="Other placements"
															selected={object.shadows}
															size="sm"
															noLinkNbsp
															form={form}
															content={(popup) => (
																<SiteSelect
																	isPlacements
																	expanded
																	fullWidth
																	selected={object.shadows.map((shadow) => shadow.placementId)}
																	getPublishersFn={() => [publisherNode.obj]}
																	onChange={(placementIds) => popup.formUpdate(() => {
																		const shadows = object.shadows.filter((shadow) => _.includes(placementIds, shadow.placementId));
																		const existing = _.keyBy(shadows, 'placementId');
																		placementIds.filter((id) => !existing[id]).forEach((placementId) => {
																			const placement = publisherNode.byId[placementId];
																			const pbCfgs = placement.getDefaultPrebidConfigs().map(({ id }) => id);
																			if (placementId !== parentNode.id) {
																				shadows.push(new SystemData.models.ShadowSspPlacement({
																					placementId,
																					pbCfgs,
																				}));
																			}
																		});
																		object.obj.shadows = shadows;
																	})}
																/>

															)}
														/>
													</Grid>
													<Grid item>
														{canSelectMediaType && (
															<Select
																style={{ minWidth: 150 }}
																label="Limit media type"
																isolated
																nonSelected={`Auto (${_.find(MediaTypes, {
																	value: object.defaultMediaTypeLimiter,
																}).label})`}
																{...field('mediaTypeLimiter')}
																items={MediaTypes}
															/>
														)}
													</Grid>
												</>
											)}
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={1}>
							<Tooltip title="Delete SSP">
								<IconButton
									style={{ padding: 0 }}
									onClick={async () => {
										const ok = await Base.renderGlobal((closeFn) => (
											<ConfirmDialog
												open
												text="Do you want to delete this SSP placement"
												onAny={closeFn}
											/>
										));
										if (ok) {
											form.update(() => object.removeNode());
										}
									}}
									size="large"
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	}
}

SSPForm.propTypes = {
	object: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	placementNode: PropTypes.object.isRequired,
};

SSPForm.defaultProps = {

};

export default SSPForm;
