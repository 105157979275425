import React from 'react';
import {
	IconButton,
	ListSubheader,
	Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export default function ExpandedSidebarListSubheader({
	children,
	expanded,
	onExpandChange,
}) {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				bgcolor: 'primary.main',
				color: 'primary.light',
				justifyContent: 'space-between',
				pr: 2,
			}}
		>
			<ListSubheader
				sx={{
					ml: 9.5, // + 4 px to account for padding
					pl: 0,
					bgcolor: 'primary.main',
					color: 'primary.light',
					cursor: onExpandChange ? 'pointer' : 'text',
					// smaller padding than default to accomodate possible scrollbar
					pr: 1,
				}}
				onClick={onExpandChange || undefined}
			>
				{ children }
			</ListSubheader>
			<div>
				{ onExpandChange && (
					<IconButton
						size="small"
						color="inherit"
						onClick={onExpandChange}
					>
						{ expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon /> }
					</IconButton>
				)}
			</div>
		</Box>

	);
}

ExpandedSidebarListSubheader.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	expanded: PropTypes.bool,
	onExpandChange: PropTypes.func,
};

ExpandedSidebarListSubheader.defaultProps = {
	expanded: false,
	onExpandChange: undefined,
};
