import React from 'react';
import AudienceCustomizer from 'relevant-shared/reportData/customizers/audienceCustomizer';
import { stores } from '../../../stores';

class FrontendAudienceCustomizer extends AudienceCustomizer {
	getReportWarning() {
		const lnk = () => (
			<a target="_blank" href="https://audience.relevant-digital.com">
				audience.relevant-digital.com
			</a>
		);
		if (stores.identity.isAdministrator()) {
			return null;
		}
		return (
			<Alert severity="warning">
				<AlertTitle>Warning</AlertTitle>
				Please login using your <i>existing credentials</i> at {lnk()} to access audience report data past <b>2023-10-01</b>
			</Alert>
		);
	}
}

export default FrontendAudienceCustomizer;
