import React, {
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import Constants from 'relevant-shared/reportData/constants';
import {
	FormControlLabel,
	Checkbox,
	Grid,
} from '@mui/material';
import Report from '../Report';
import JobButton from '../JobButton';
import { ActionButton } from '../ActionButton/ActionButton';
import DateUtils from '../../lib/dateUtils';
/**
 * Wrapper for report rendered from alarms
 */
export default function AlarmsReport({
	isNew,
	type,
	report,
	onCancel,
	saveAndExit,
	displayPrivateCheckbox,
}) {
	const reportComponentRef = useRef();
	const [isPrivate, setIsPrivate] = useState(!!((report && report.userId) || isNew));
	const [settingsOpen, setSettingsOpen] = useState(isNew);

	const fallback = ({
		showTable: true,
		start: DateUtils.fullDay(new Date(), -31),
		end: DateUtils.fullDay(new Date(), -1),
		type,
		...(Constants[type].getDefaultProperties
			? Constants[type].getDefaultProperties(true)
			: null),
	});

	return (
		<>
			<Grid item xs={12}>
				<Report
					ref={reportComponentRef}
					onSettingsOpenToggle={(isOpen) => setSettingsOpen(isOpen)}
					isActualReport={false}
					{...(report ? report.settings : fallback)}
					settingsOpen={settingsOpen}
				/>
			</Grid>
			<>
				{displayPrivateCheckbox && (
					<Grid item>
						<FormControlLabel
							label="Private"
							control={(
								<Checkbox
									name="isPrivate"
									label="Private"
									checked={isPrivate}
									onChange={({ target }) => setIsPrivate(target.checked)}
								/>
							)}
						/>
					</Grid>
				)}

				<Grid item>
					<JobButton
						label="Save"
						fn={() => reportComponentRef?.current && saveAndExit(
							report,
							reportComponentRef.current.getCurrentProperties(),
							isPrivate,
						)}
						color="primary"
					/>
				</Grid>
				<Grid item>
					<ActionButton
						label="Cancel"
						onClick={onCancel}
						color="primary"
					/>
				</Grid>
			</>
		</>
	);
}

AlarmsReport.propTypes = {
	report: PropTypes.shape({}),
	onCancel: PropTypes.func,
	type: PropTypes.string.isRequired,
	saveAndExit: PropTypes.func.isRequired,
	displayPrivateCheckbox: PropTypes.bool,
	isNew: PropTypes.bool,
};

AlarmsReport.defaultProps = {
	report: null,
	displayPrivateCheckbox: false,
	isNew: true,
	onCancel: null,
};
