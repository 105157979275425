import React, { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import BuildIcon from '@mui/icons-material/Build';
import {
	Box, ListItem, ListItemAvatar, ListItemText, Avatar,
} from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PrebidBuildConfiguration } from '../../api/relevant';
import { Link } from '../Link/Link';
import useRequest from '../../hooks/useRequest/useRequest';
import PopupSelect from '../PopupSelect';
import useIsMounted from '../../hooks/useIsMounted';

const PREFIX = 'PrebidBuildOptions';

const classes = {
	buildAvatar: `${PREFIX}-buildAvatar`,
	addButton: `${PREFIX}-addButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({
	theme: { palette },
}) => ({
	[`& .${classes.buildAvatar}`]: {
		background: palette.info.main,
	},
	[`& .${classes.addButton}`]: {
		background: palette.primary.light,
	},
}));

const additionalActionsStyle = {
	marginTop: 8,
};

function PrebidBuildOptions({ field, disabled }) {
	const isMounted = useIsMounted();
	const { pathname, search } = useLocation();
	const [setRequest] = useRequest();
	const [prebidBuildConfigurations, setPrebidBuildConfigurations] = useState([]);
	const [selectedBuildConfig, setSelectedBuildConfig] = useState(null);
	const history = useHistory();

	useEffect(() => {
		if (isMounted.current) {
			setRequest({
				requestFunction: () => PrebidBuildConfiguration.list(),
				requestCallback: (res) => {
					if (isMounted.current) {
						const buildConfigs = res.map(({
							name, id, version, built,
						}) => ({
							label: name, value: id, version, built,
						}));
						setPrebidBuildConfigurations(buildConfigs);
						const config = buildConfigs.find((c) => c.value === field.value);
						if (config) {
							setSelectedBuildConfig(config);
						} else {
							setSelectedBuildConfig(null);
						}
					}
				},
			});
		}
	}, [isMounted, setRequest, field.value]);

	const onSelectedBuildChange = (config) => {
		field.onChange({ target: { name: field.name, value: config && config.value } });
	};

	return (
		<Root>
			<PopupSelect
				disabled={disabled}
				options={prebidBuildConfigurations}
				selectedOption={selectedBuildConfig}
				onChange={(newValue) => onSelectedBuildChange(newValue)}
				label="Prebid build"
				dialogTitle="Select a Prebid build"
				dialogProps={{ maxWidth: 'xs' }}
				renderOption={({
					value,
					label,
					version,
					built,
				}, props) => (
					<ListItem
						button
						key={value}
						value={value}
						{...props}
						disabled={!built}
					>
						<ListItemAvatar>
							<Avatar sx={{ bgcolor: built ? 'info.main' : '' }}>
								<BuildIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={label}
							secondary={`v${version}`}
						/>
					</ListItem>
				)}
				renderAdd={(props) => (
					<ListItem button {...props}>
						<ListItemAvatar>
							<Avatar sx={{ bgcolor: 'primary.light' }}>
								<AddIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText>New Prebid build</ListItemText>
					</ListItem>
				)}
				onAddItem={() => {
					history.push(`${pathname}/add-prebid-build${search}`);
				}}
				createable
				clearable
			/>
			<Box display="flex" position="absolute" pl="15px">
				{' '}
				{/* 16 - 1px to account for PopupSelect left border */}
				<Link
					to={`${pathname}/add-prebid-build${search}`}
					style={{ marginRight: 16, ...additionalActionsStyle }}
				>
					New build
				</Link>
				<Link
					to={`${pathname}/manage-prebid-builds${search}`}
					style={additionalActionsStyle}
				>
					Manage builds
				</Link>
			</Box>
		</Root>
	);
}

PrebidBuildOptions.propTypes = {
	field: PropTypes.shape({
		value: PropTypes.string,
		name: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
	}).isRequired,
	disabled: PropTypes.bool,
};

PrebidBuildOptions.defaultProps = {
	disabled: false,
};

export default PrebidBuildOptions;
