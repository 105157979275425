import React from 'react';
import { Box, Typography } from '@mui/material';
import SystemData from '../../lib/systemData';
import { drawerWidth } from '../Sidebar/config';

/**
 * Page footer that should be visible to white-labeled customers only,
 * to show who is behind the product.
 */
export default function PageFooter() {
	const { footerLogoUrl } = SystemData.genericData.systemSettings;
	if (!footerLogoUrl) {
		return null;
	}
	return (
		<Box sx={{
			position: 'absolute',
			bottom: '16px',
			ml: { md: `${drawerWidth}px` },
			left: { xs: '50%', md: `calc((100% - ${drawerWidth}px) / 2)` },
			transform: 'translateX(-50%)',
		}}
		>
			<Box sx={{
				display: 'flex',
				alignItems: 'center',
			}}
			>
				<Typography
					variant="h4"
					sx={{ mr: 1.5 }}
				>
					Powered by
				</Typography>
				<Box
					component="a"
					sx={{ display: 'flex' }}
					href="https://www.relevant-digital.com/"
					target="_blank"
				>
					<img width="136" src={footerLogoUrl} alt="Footer logo" />
				</Box>
			</Box>
		</Box>
	);
}
