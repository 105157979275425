import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Check } from '@mui/icons-material';
import {
	Box, Typography, MenuItem, ListItemIcon, ListItemText,
} from '@mui/material';
import { availReportTypes, byAvailReportType } from 'relevant-shared/reportData/reportType';
import { ReportTemplates } from '../../components/ReportTemplates/ReportTemplates';
import classes from '../../api/classes';
import { Details } from '../../components/Details/Details';

const { ReportTemplate } = classes;

export function ReportTemplatesPage() {
	const [templatesByType, setTemplatesByType] = useState({});
	const [showHiddenTemplates, setShowHiddenTemplates] = useState(false);

	useEffect(() => {
		(async () => {
			const templates = await ReportTemplate.list();
			setTemplatesByType({
				..._.mapValues(byAvailReportType, () => []),
				..._.groupBy(templates, (t) => t.settings.type),
			});
		})();
	}, []);

	return (
		<>
			<Details
				title="Templates"
				expanded
				menu={(
					<>
						<MenuItem onClick={() => { setShowHiddenTemplates(!showHiddenTemplates); }}>
							{showHiddenTemplates && <ListItemIcon><Check /></ListItemIcon>}
							<ListItemText inset={!showHiddenTemplates}>Show hidden</ListItemText>
						</MenuItem>
					</>
				)}
			>
				{!_.isEmpty(templatesByType) && availReportTypes.map((type) => (
					<div key={type.type}>
						<Typography variant="h3" sx={{ mt: 3, mb: 2 }}>{type.label}</Typography>
						<Box sx={{ display: 'flex' }}>
							<ReportTemplates
								templates={templatesByType[type.type]}
								showHidden={showHiddenTemplates}
								copyToDashboard
								type={type.type}
							/>
						</Box>
					</div>
				))}
			</Details>
		</>
	);
}
