import { useState, useEffect } from 'react';

/**
 * @param {*} value Value to debounce
 * @param {*} delay Delay time in ms
 */
function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setDebouncedValue(() => value);
		}, delay);

		return () => clearTimeout(timeout);
	}, [delay, value]);

	return debouncedValue;
}

export default useDebounce;
