import React, { useEffect, useRef, useState } from 'react';
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';
import clsx from 'clsx';

export type SearchBarProps = {
	placeholder?: string;
	hasResults?: boolean;
	selectOnFocus?: boolean;
	onChange?: (value: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	icon?: React.ReactNode | null;
	value?: string;
	className?: React.HTMLProps<HTMLInputElement>['className'];
};

export function SearchBar({
	placeholder,
	hasResults = false,
	selectOnFocus = true,
	onChange,
	onFocus,
	onBlur,
	icon,
	value,
	className,
} : SearchBarProps) {
	const input = useRef<HTMLInputElement>(null);
	const [hasValue, setHasValue] = useState(false);

	useEffect(() => {
		setHasValue(!!value);
	}, [value]);

	const handleClickToFocus = (e: React.MouseEvent) => {
		input.current?.focus()
	}

	const handleInputFocus = (e) => {
		onFocus?.();
		if (selectOnFocus) {
			setTimeout(() => {
				input.current?.select()
			}, 0)
		}
	}

	const handleOnChange = (e) => {
		setHasValue(!!e.target.value)
		onChange?.(e.target.value)
	}

	const handleClear = () => {
		input.current.value = ''
		setHasValue(false)
		onChange?.('')
	}

	return (
		<div 
			className={clsx(
				"flex cursor-text items-center gap-4 rounded-md border px-4 py-3 transition",
				"border-grey-200 bg-[#F9F9F9] hover:shadow-inner",
				// Inner input state style
				"focus-within:border-grey-600 focus-within:bg-white focus-within:shadow focus-within:hover:shadow",
				// Remain styled like focus while has value
				hasValue && "border-grey-600 !bg-white !shadow",
				hasResults && "!rounded-b-none",
				className,
			)}
			onClick={handleClickToFocus}
		>
			{icon}
			{!icon && icon !== null && (
				<SearchIcon className="text-grey-600 text-2xl" />
			)}
			<input
				ref={input}
				type="text"
				placeholder={placeholder}
				className="w-full bg-transparent outline-none text-grey-600 placeholder-grey-500 focus:placeholder-opacity-0 font-normal text-sm"
				onFocus={handleInputFocus}
				onBlur={onBlur}
				onChange={handleOnChange}
				value={value}
				tabIndex={0}
			/>
			{hasValue && (
				<button 
					type="button"
					className={clsx(
						"text-grey-600 transition-opacity duration-75",
						hasValue ? "opacity-100" : "opacity-0"
					)}
					onClick={handleClear}
					tabIndex={-1}
				>
					<CloseIcon className="block fill-current text-[22px]" />
				</button>
			)}
		</div>
	);
}
