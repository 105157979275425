/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Constants from 'relevant-shared/reportData/constants';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import styles from './styles.css';
import { getTotals } from './utils';
import {
	combineLabels,
	COMP_PFX,
	Divider,
	getIndentation,
	joinNestedDataObjectKeysToStrings,
	keepOnlyUpToDateTableStates,
	getSortClickCount,
} from './reportTableUtils';
import { ActionButton } from '../ActionButton/ActionButton';
import Select from '../Select';
import { fmtNum } from '../../lib/numberUtils';
import BrowserUtils from '../../lib/browserUtils';
import DimensionDropdown from './dimensionDropdown';
import TriggerFilterEdit from './triggerFilterEdit';
import Base from '../../layouts/Base';
import {Scope } from '../Wrappers';
import ReactUtils from '../../lib/reactUtils';
import Switch from '../Switch';
import { ConfirmDialog } from '../ConfirmDialog';

const getStatusStyle = (data) => {
	if (!data || data.failures === undefined) {
		return undefined;
	}
	return { color: data.failures ? 'red' : 'green' };
};

class ReportTable extends React.Component {
	constructor(props) {
		super(props);
		Object.assign(this, Constants[props.type], this.props.funcs);
		this.fld = ReactUtils.fld(this);

		const { expandAll } = this.props.tableState;
		const { collapsed, expanded } = this.removeOutdatedTableStates();
		const { sortSum, sortReversed } = this.props;

		this.state = {
			pageNr: 0,
			expanded,
			collapsed,
			expandAll,
			showDimensionPopUpForLevel: null,
			sortBy: this.props.sortSum || null,
			sortClickCount: getSortClickCount(sortReversed, sortSum),
			lastClickedToggleButton: null,
			filterTriggerFailed: false,
		};
	}

	getAssignableDimensionIDs(levelIndex, includeCurrent = true) {
		const { props } = this;
		const currentDimensionId = props.groupBy[levelIndex];
		const dimensionIdsInReport = props.groupBy;
		const allDimensionIds = Object.keys(props.funcs.availableOptionsFor(undefined, props));

		return [
			..._.difference(allDimensionIds, dimensionIdsInReport),
			...(includeCurrent ? [currentDimensionId] : []),
		];
	}

	getAllSumInfos() {
		// eslint-disable-next-line no-underscore-dangle
		if (this.__sumInfoCache) {
			// eslint-disable-next-line no-underscore-dangle
			return this.__sumInfoCache;
		}
		const { sums, useCompareTo } = this.props;

		const sumInfo = [];
		sums.forEach((sum) => {
			const name = this.SUM_DESCRIPTIONS[sum];
			const abbr = (this.SUM_DESCRIPTION_ABBREVIATIONS && this.SUM_DESCRIPTION_ABBREVIATIONS[sum]) || null;
			const singleInfo = {
				sum,
				key: sum,
				name,
				abbr,
			};
			sumInfo.push(singleInfo);
			if (useCompareTo) {
				sumInfo.push({
					sum,
					isComp: true,
					key: `${COMP_PFX}${sum}`,
					name: `${name}*`,
					abbr: abbr && `${abbr}*`,
					orgSumInfo: singleInfo,
				});
			}
		});
		// eslint-disable-next-line no-underscore-dangle
		this.__sumInfoCache = sumInfo;
		return sumInfo;
	}

	getVisible(rootArr) {
		const { rowsPerTab } = this.props;
		if (rowsPerTab === -1) return rootArr;

		let rowCounter = 0;
		const skipRowsBefore = this.state.pageNr * rowsPerTab;
		const skipRowsAfter = skipRowsBefore + rowsPerTab;

		const isInRange = (elementRow) => (
			elementRow >= skipRowsBefore && elementRow < skipRowsAfter
		);

		const copy = (array, levelIndex = 0, parentLabel = '') => {
			const isFinalLevel = (levelIndex === this.props.groupBy.length - 1);
			const isFirstLevel = (levelIndex === 0);
			const getLabel = _.partial(combineLabels, parentLabel, _, this.getDimensionLabel(levelIndex));

			const currentPageDataOrNull = (element, index) => {
				if (!this.isVisible(parentLabel) && !isFirstLevel) {
					return null;
				}

				const elementRow = rowCounter;
				rowCounter += 1;

				const elementData = {
					...element,
					isAfterPageBreak: Boolean(index) && (elementRow === skipRowsBefore),
					isBeforePageBreak: (elementRow === skipRowsAfter - 1) && (index < array.length - 1),
				};

				if (isFinalLevel) {
					return isInRange(elementRow) ? elementData : null;
				}

				const children = copy(element.data, levelIndex + 1, getLabel(element.label));

				const hasChildInRange = (elementRow < skipRowsBefore) && (rowCounter > skipRowsBefore);
				return (isInRange(elementRow) || hasChildInRange)
					? { ...elementData, data: children }
					: null;
			};
			return array
				.map(currentPageDataOrNull)
				.filter((e) => e);
		};
		return copy(rootArr);
	}

	getAllDimensionLabels = () => this.props.groupBy
		.map((dimension) => this.props.groupByOptions[dimension]);

	getDimensionLabel = (levelIndex) => (
		this.props.groupByOptions[this.props.groupBy[levelIndex]]
	);

	getNumberOfDimensions = () => this.props.groupBy.length;

	getSumName = (sumId) => {
		const sumInfo = this.getAllSumInfos().find(({ key }) => key === sumId);
		return (sumInfo)
			? sumInfo.name
			: undefined;
	};

	goToPage = (pageNr) => this.setState({ pageNr });

	goToFirstPage = () => this.goToPage(0);

	goToNextPage = () => this.goToPage(this.state.pageNr + 1);

	goToPreviousPage = () => this.goToPage(this.state.pageNr - 1);

	// TODO: logic related to page selection should be in a higher level component
	scrollToClickedToggleButton = (redirectFromPreviousPage = false) => {
		const element = document.getElementById(this.state.lastClickedToggleButton);
		if (element && redirectFromPreviousPage) {
			element.scrollIntoView({ behavior: 'smooth' });
			this.setState({ lastClickedToggleButton: null });
		} else if (!element && this.state.pageNr > 0) {
			this.setState((prevState) => ({
				...prevState,
				pageNr: prevState.pageNr - 1,
			}), () => this.scrollToClickedToggleButton(true));
		}
	};

	toggleExpandAll = () => {
		this.props.onSettingsChanged({
			tableState: {
				collapsed: {},
				expanded: {},
				expandAll: !this.state.expandAll,
			},
		});
		this.setState((prevState) => ({
			...prevState,
			collapsed: {},
			expanded: {},
			expandAll: !prevState.expandAll,
		}));
		this.goToFirstPage();
	};

	countNumberOfRows(rootArr, maxLevel) {
		const count = (array, levelIndex = 0, parentLabel = '') => {
			if (levelIndex === maxLevel) {
				return array.length;
			}
			return _.sumBy(array, (element) => {
				const label = combineLabels(
					parentLabel,
					element.label,
					this.getDimensionLabel(levelIndex),
				);
				return this.isVisible(label)
					? 1 + count(element.data, levelIndex + 1, label)
					: 1;
			});
		};
		return count(rootArr);
	}

	isVisible(label) {
		const isNotCollapsed = (this.state.expandAll && Boolean(!this.state.collapsed[label]));
		const isExpandedExplicitly = (!this.state.expandAll && Boolean(this.state.expanded[label]));
		return isNotCollapsed || isExpandedExplicitly;
	}

	removeOutdatedTableStates() {
		const entryWithUpToDateTableState = (entry) => keepOnlyUpToDateTableStates(
			entry,
			this.getAllDimensionLabels(),
			Object.keys(joinNestedDataObjectKeysToStrings(this.props.data.obj)),
		);

		const toObjectOfUpToDateTableStates = (prev, curr) => (
			(curr.upToDate)
				? { ...prev, [curr.entry]: true }
				: prev
		);

		const expandedUpToDate = Object.keys(this.props.tableState.expanded)
			.map(entryWithUpToDateTableState)
			.reduce(toObjectOfUpToDateTableStates, {});

		const collapsedUpToDate = Object.keys(this.props.tableState.collapsed)
			.map(entryWithUpToDateTableState)
			.reduce(toObjectOfUpToDateTableStates, {});

		this.props.onSettingsChanged({
			tableState: {
				expanded: expandedUpToDate,
				collapsed: collapsedUpToDate,
				expandAll: this.props.tableState.expandAll,
			},
		});

		return {
			collapsed: collapsedUpToDate,
			expanded: expandedUpToDate,
		};
	}

	exportToCsv() {
		const { calculator, title } = this.props;
		BrowserUtils.downloadTextFile(calculator.reportToCsv(), `${title} ${new Date()}.csv`);
	}

	// eslint-disable-next-line consistent-return
	async resolveConflictingMetricsAndDimensions(groupBy, successCallback = () => {}) {
		const sums = this.props.funcs.limitSumsByGrouping(this.props.sums, groupBy);
		const differenceInSums = _.difference(this.props.sums, sums);

		const updateGroupByAndSums = async (newSums) => this.props.onSettingsChanged(
			{ groupBy, sums: newSums },
			true,
		);

		if (_.isEmpty(differenceInSums)) {
			await successCallback();
			return updateGroupByAndSums(sums);
		}

		const onOk = async (closeFn, newSums) => {
			await successCallback();
			await updateGroupByAndSums(newSums);
			closeFn();
		};

		const onCancel = (closeFn) => {
			closeFn();
		};

		const sumNames = differenceInSums.map(this.getSumName);
		const remainingSums = _.difference(sums, differenceInSums);
		const sumsIsEmpty = _.isEmpty(remainingSums);
		const newSums = sumsIsEmpty
			? this.props.funcs.getInitSums()
			: remainingSums;

		Base.renderGlobal((closeFn) => (
			<ConfirmDialog
				open
				title="Metrics incompatible with dimensions"
				text={(
					<div>
						<span>
							The following metrics are incompatible with the
							selected dimensions and will be removed:
						</span>
						<ul>
							{ sumNames.map((name) => <li key={name}>{name}</li>) }
						</ul>
						{sumsIsEmpty && (
							<span>
								The default metrics for the currently selected
								dimensions will be restored.
								<br />
								<br />
							</span>
						)}
						<span>Do you want to proceed?</span>
					</div>
				)}
				onConfirm={async () => onOk(closeFn, newSums)}
				onCancel={() => onCancel(closeFn)}
			/>
		));
	}

	getDimensionDropDownItems(levelIndex, includeCurrentLevel) {
		const toDropdownItems = (id) => ({ value: id, label: this.props.groupByOptions[id] });
		const descendingByLabel = (a, b) => (a.label > b.label ? 1 : -1);
		return this.getAssignableDimensionIDs(levelIndex, includeCurrentLevel)
			.map(toDropdownItems)
			.sort(descendingByLabel);
	}

	renderToggleButton(label, parent, dimensionLabel) {
		const buttonId = combineLabels(parent, label, dimensionLabel);

		const expandOrCollapse = () => {
			const valueIsTrueAndParentIsVisible = (value, key) => {
				const keyIsChild = key.startsWith(buttonId);
				return value && (key === buttonId || !keyIsChild);
			};

			const expanded = _.pickBy({
				...this.state.expanded,
				[buttonId]: !this.state.expandAll && !this.state.expanded[buttonId],
			}, valueIsTrueAndParentIsVisible);

			const collapsed = _.pickBy({
				...this.state.collapsed,
				[buttonId]: this.state.expandAll && !this.state.collapsed[buttonId],
			}, valueIsTrueAndParentIsVisible);

			this.setState(
				{ collapsed, expanded, lastClickedToggleButton: buttonId },
				this.scrollToClickedToggleButton,
			);

			this.props.onSettingsChanged({
				tableState: {
					expanded,
					collapsed,
					expandAll: this.state.expandAll,
				},
			});
		};

		const isExpanded = this.isVisible(buttonId);
		const toggleLabel = isExpanded ? 'Collapse' : 'Expand';
		const Icon = isExpanded ? KeyboardArrowDown : ChevronRight;

		return (
			<Tooltip title={toggleLabel} placement="left">
				<IconButton
					aria-label={toggleLabel}
					onClick={expandOrCollapse}
					size="small"
					className={styles.groupExpanderButton}
				>
					<Icon
						id={buttonId}
						ref={(this.state.lastClickedToggleButton === buttonId)
							? () => this.scrollToClickedToggleButton()
							: () => {}}
					/>
				</IconButton>
			</Tooltip>
		);
	}

	renderHeaderSum() {
		const { onSettingsChanged, triggerFilters, trigger, useCompareTo } = this.props;
		const sumInfos = this.getAllSumInfos();

		const sortByColumnHeader = (sum, isComp, key) => {
			const sortClickCount = (key === this.state.sortBy)
				? this.state.sortClickCount
				: 0;

			const mod3 = sortClickCount % 3;
			const isSum = mod3 <= 1;
			const isReverse = mod3 === 0;

			onSettingsChanged({
				sortSum: isSum ? sum : null,
				sortReversed: isReverse,
				sortSumIsComp: isComp,
			});
			this.setState({
				sortBy: isSum ? key : null,
				sortClickCount: mod3 + 1,
			});
		};

		const getToolTip = (name) => (
			(this.state.sortClickCount !== 2)
				? `Sort by ${name}`
				: 'Reset to default sorting'
		);

		const handleDrop = (e, scope, dropSum) => {
			e.preventDefault();
			if (scope.dragSum !== null) {
				const { sums } = this.props;
				const dragIndex = sums.indexOf(scope.dragSum);
				const dropIndex = sums.indexOf(dropSum);
				if (dragIndex !== dropIndex) {
					const newSums = [...sums];
					newSums.splice(dragIndex, 1);
					newSums.splice(dropIndex, 0, sums[dragIndex]);
					this.props.onSettingsChanged({ sums: newSums });
				}
			}
			scope.setState({ dragOver: null });
		};

		const sumInfoColumnHeader = (
			{ sum, isComp, key, name, abbr, orgSumInfo },
			scope,
			{ dragOver },
		) => {
			const dragIndex = sumInfos.findIndex(({ sum: s }) => scope.dragSum === s);
			const dropIndex = sumInfos.findIndex(({ sum: s }) => dragOver === s);
			let indicatorSide;
			if (dragOver === sum) {
				if (dragIndex >= dropIndex && !isComp) {
					indicatorSide = 'left';
				} else if (dragIndex < dropIndex && (!useCompareTo || isComp)) {
					indicatorSide = 'right';
				}
			}
			return (
				<TableCell
					key={key}
					variant="head"
					component="th"
					align="right"
					className={styles.dataHeader}
					sortDirection={this.state.sortBy === key ? (this.state.sortClickCount === 1 ? 'desc' : 'asc') : false}
					onDragEnter={(e) => {
						e.preventDefault();
						e.dataTransfer.dropEffect = 'move';
						scope.setState({ dragOver: sum });
					}}
					onDragOver={(e) => {
						e.preventDefault();
						e.dataTransfer.dropEffect = 'move';
					}}
					onDrop={(e) => handleDrop(e, scope, sum)}
					sx={{ position: 'relative' }}
				>
					{indicatorSide && (
						<div
							style={{
								position: 'absolute',
								top: 0,
								[indicatorSide]: 0,
								bottom: 0,
								width: 2,
								background: '#ddd',
							}}
						/>
					)}
					{trigger && (
						<>
							<div style={{ display: 'inline-block' }}>
								<TriggerFilterEdit
									triggerFilters={triggerFilters}
									sum={sum}
									name={name}
									isComp={isComp}
									orgSumInfo={orgSumInfo}
									settings={this}
									onUpdate={(newFilters) => onSettingsChanged({ triggerFilters: newFilters })}
								/>
							</div>
							<div />
						</>
					)}
					<TableSortLabel
						title={getToolTip(name)}
						active={this.state.sortBy === key}
						direction={this.state.sortBy === key ? (this.state.sortClickCount === 1 ? 'desc' : 'asc') : 'desc'}
						onClick={() => sortByColumnHeader(sum, isComp, key)}
						draggable
						onDragStart={() => { scope.dragSum = sum; }}
						onDragEnd={() => { scope.dragSum = null; scope.setState({ dragOver: null }); }}
					>
						{abbr || name}
					</TableSortLabel>
				</TableCell>
			);
		}

		return (
			<Scope
				content={(scope, scopeState) => sumInfos.map((info) => sumInfoColumnHeader(info, scope, scopeState))}
			/>
		);
	}

	renderAddDimensionButton(levelIndex, parent) {
		const addDimension = () => {
			this.setState({ showDimensionPopUpForLevel: parent });
		};

		const closeDialogAndEnableScrolling = () => {
			this.setState({ showDimensionPopUpForLevel: null });
		};

		const onDropdownSelectionChange = async (event) => {
			const dimensionsBefore = this.props.groupBy.slice(0, levelIndex + 1);
			const dimensionsAfter = this.props.groupBy.slice(levelIndex + 1);
			const groupBy = [...dimensionsBefore, event.target.value, ...dimensionsAfter];

			const successCallback = _.partial(this.props.onSettingsChanged, { tableState: this.state.tableState });
			await this.resolveConflictingMetricsAndDimensions(groupBy, successCallback);
			this.setState({ showDimensionPopUpForLevel: null });
		};

		return (
			<>
				<Dialog
					open={this.state.showDimensionPopUpForLevel === parent}
					onClose={closeDialogAndEnableScrolling}
					fullWidth
				>
					<DialogTitle>Select dimension to insert</DialogTitle>
					<DialogContent dividers>
						<Select
							name="dimension"
							label="Dimension"
							value={null}
							onChange={onDropdownSelectionChange}
							items={this.getDimensionDropDownItems(levelIndex, false)}
							fullWidth
						/>
					</DialogContent>
				</Dialog>
				<Tooltip title="Insert dimension...">
					<IconButton onClick={addDimension} size="small" aria-label="Insert dimension...">
						<Add />
					</IconButton>
				</Tooltip>
			</>
		);
	}

	renderRemoveDimensionButton(levelIndex) {
		const removeDimension = () => {
			const groupBy = this.props.groupBy
				.filter((dimension, index) => index !== levelIndex);
			this.props.onSettingsChanged({ groupBy }, true);
		};

		return (
			<Tooltip
				title={`Remove dimension '${this.getDimensionLabel(levelIndex)}'`}
			>
				<IconButton onClick={removeDimension} size="small" aria-label={`Remove dimension '${this.getDimensionLabel(levelIndex)}'`}>
					<Remove />
				</IconButton>
			</Tooltip>
		);
	}

	renderSumValues(element, isTotal) {
		const { calculateSumVal } = this.props;
		const fmtVal = (v) => (_.isNumber(v) ? fmtNum(v) : v);
		const fallbackIfInvalid = isTotal ? 'n/a' : undefined;
		const sumInfo = this.getAllSumInfos();

		return (
			sumInfo.map(({ key, sum: sumVal, isComp }) => (
				<TableCell
					key={key}
					align="right"
				>
					<Tooltip title={this.getSumName(key)}>
						<span aria-label={this.getSumName(key)} style={getStatusStyle(element)}>
							{element
								? fmtVal(calculateSumVal(element, sumVal, fallbackIfInvalid, isComp))
								: '...'}
						</span>
					</Tooltip>
				</TableCell>
			))
		);
	}

	renderPageBreakIndicator(levelIndex, atBottom = false) {
		const switchPage = (atBottom)
			? this.goToNextPage
			: this.goToPreviousPage;

		const toolTip = `Go to ${atBottom ? 'next' : 'previous'} page`;

		return (
			<TableRow>
				<TableCell
					onClick={switchPage}
					style={getIndentation(levelIndex, this.props.groupBy.length)}
				>
					<Tooltip title={toolTip}>
						<span aria-label={toolTip} className={styles.clickable}>
							...
						</span>
					</Tooltip>
				</TableCell>
				{this.getAllSumInfos().map(({ key }) => (
					<TableCell
						key={key}
						align="right"
						onClick={switchPage}
					>
						<Tooltip title={toolTip}>
							<span aria-label={toolTip} className={styles.clickable}>
								...
							</span>
						</Tooltip>
					</TableCell>
				))}
			</TableRow>
		);
	}

	renderDimensionLabel(levelIndex, isFirstLevel, parent) {
		const onDropdownSelectionChange = async (event) => {
			const groupBy = this.props.groupBy.map((element, index) => (
				(index !== levelIndex)
					? element
					: event.target.value
			));
			await this.resolveConflictingMetricsAndDimensions(groupBy);
		};

		const isOnlyLevel = this.props.groupBy.length === 1;

		return (
			<TableRow>
				<TableCell
					variant="head"
					component="th"
					className={styles.dimensionLabelHeaderCell}
					style={getIndentation(levelIndex, this.props.groupBy.length)}
					colSpan={isFirstLevel ? 1 : this.getAllSumInfos().length + 1}
				>
					<Box display="flex" alignItems="center">
						<DimensionDropdown
							onChange={onDropdownSelectionChange}
							currentDimensionId={this.props.groupBy[levelIndex]}
							items={this.getDimensionDropDownItems(levelIndex, true)}
						/>
						<div>
							{this.renderAddDimensionButton(levelIndex, parent)}
							{(!isOnlyLevel) && this.renderRemoveDimensionButton(levelIndex)}
						</div>
					</Box>
				</TableCell>
				{isFirstLevel && this.renderHeaderSum()}
			</TableRow>
		);
	}

	renderBlockOfDataRows(arr, levelIndex, parent = '') {
		const { reportData, groupBy, attributes } = this.props;
		const isFinalLevel = (levelIndex === this.props.data.levels - 1);
		const isFirstLevel = (levelIndex === 0);
		const dim = groupBy[levelIndex];
		const attribByLabel = reportData.attribsByLabel[dim] || {};
		const attribs = attributes ? _.keys(_.pickBy(attributes[dim])) : [];

		const DataRow = ({ label, sum, data }) => (
			<TableRow hover>
				<TableCell
					className={styles.dataRow}
					style={getIndentation(levelIndex, groupBy.length)}
				>
					{ !isFinalLevel && this.renderToggleButton(label, parent, this.getDimensionLabel(levelIndex)) }
					<span
						style={getStatusStyle(isFinalLevel ? data : sum)}
						title={label}
					>
						{label}
						{!!attribs.length && (
							<span style={{ color: 'gray' }}>
								{` (${attribs.map((a) => (attribByLabel[label] || {})[a] || 'n/a').join(', ')})`}
							</span>
						)}
					</span>
				</TableCell>
				{ isFinalLevel
					? this.renderSumValues(data, false)
					: this.renderSumValues(sum, true)}
			</TableRow>
		);

		const row = ({
			label, totals, isAfterPageBreak, data, isBeforePageBreak,
		}) => (
			<Fragment key={label}>
				{isAfterPageBreak && this.renderPageBreakIndicator(levelIndex)}
				<DataRow
					label={label}
					sum={totals}
					data={data}
				/>
				{ !isFinalLevel && this.renderBlockOfDataRows(
					data,
					levelIndex + 1,
					combineLabels(parent, label, this.getDimensionLabel(levelIndex)),
				)}
				{isBeforePageBreak && this.renderPageBreakIndicator(levelIndex, true)}
			</Fragment>
		);

		return (
			(isFirstLevel || this.isVisible(parent)) && (
				<>
					{ this.renderDimensionLabel(levelIndex, isFirstLevel, parent) }
					{ arr.map(row) }
				</>
			)
		);
	}

	renderBottomLine(rootArr) {
		const { filterTriggerFailed } = this.state;
		const { importanceTabPerc, serverSums, groupBy } = this.props;
		const isFiltering = importanceTabPerc || filterTriggerFailed;
		return (
			<TableFooter>
				<TableRow>
					<TableCell style={getIndentation(0, groupBy.length)}>
						<strong>{`Total${isFiltering ? ' (filtered)' : ''}`}</strong>
					</TableCell>
					{this.renderSumValues(
						getTotals(rootArr, serverSums),
						true,
					)}
				</TableRow>
			</TableFooter>
		);
	}

	render() {
		// eslint-disable-next-line no-underscore-dangle
		this.__sumInfoCache = null;
		const {
			rowsPerTab,
			importanceTabPerc,
			onSettingsChanged,
			trigger,
			data,
		} = this.props;
		const {
			pageNr,
			filterTriggerFailed,
		} = this.state;

		let rootArr = data.arr;
		if (filterTriggerFailed) {
			const filterFailed = (arr) => arr.map((obj) => {
				if (obj.totals) {
					return obj.totals.failures && { ...obj, data: filterFailed(obj.data) };
				}
				return obj.data.failures ? obj : null;
			}).filter((obj) => obj);
			rootArr = filterFailed(rootArr);
		}

		// TODO: The logic related to finding 'visibleArr' shouldn't be in
		//  this file, but rather in 'Reports/index'
		const visibleArr = this.getVisible(rootArr);
		if (visibleArr.length === 0 && this.state.pageNr > 0) {
			this.setState((currentState) => ({
				...currentState,
				pageNr: currentState.pageNr - 1,
			}));
		}

		const rows = this.countNumberOfRows(rootArr, this.props.groupBy.length - 1);
		const actualRowsPerTab = rowsPerTab > -1 ? rowsPerTab : (rows || 1);

		return (
			<div>
				<Box
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
				>
					{ (this.props.groupBy.length > 1) && (
						<Box flexGrow={1}>
							<Button
								onClick={this.toggleExpandAll}
								endIcon={this.state.expandAll ? <KeyboardArrowDown /> : <ChevronRight />}
							>
								{this.state.expandAll ? 'Collapse all' : 'Expand all'}
							</Button>
						</Box>
					)}
					{trigger && (
						<Switch
							style={{ flexGrow: 0.5 }}
							label="Filter failures"
							{...this.fld('filterTriggerFailed')}
						/>
					)}
					<Box sx={{
						display: 'flex',
						alignItems: 'center',
						pt: 4,
						pb: 1,
					}}
					>
						<Box sx={{ color: 'text.secondary', pr: 1.5 }}>
							Filter by
						</Box>
						<FormControl>
							<InputLabel id="importanceTabPercSelect-label">
								% of total
							</InputLabel>
							<MuiSelect
								variant="outlined"
								labelId="importanceTabPercSelect-label"
								name="importanceTabPercSelect"
								value={importanceTabPerc}
								label="% of total"
								onChange={(ev) => {
									this.setState({ pageNr: 0 });
									onSettingsChanged({ importanceTabPerc: ev.target.value });
								}}
							>
								<MenuItem value={0}>Show all</MenuItem>
								<MenuItem value={99}>&gt; 99%</MenuItem>
								<MenuItem value={95}>&gt; 95%</MenuItem>
								<MenuItem value={90}>&gt; 90%</MenuItem>
								<MenuItem value={80}>&gt; 80%</MenuItem>
								<MenuItem value={70}>&gt; 70%</MenuItem>
								<MenuItem value={60}>&gt; 60%</MenuItem>
								<MenuItem value={50}>&gt; 50%</MenuItem>
							</MuiSelect>
						</FormControl>
					</Box>
				</Box>
				<Divider />
				<div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
					<Table size="small">
						<TableBody>
							{this.renderBlockOfDataRows(visibleArr, 0)}
						</TableBody>
						{this.renderBottomLine(rootArr)}
					</Table>
					<TablePagination
						component="div"
						count={rows}
						onPageChange={(ev, page) => this.goToPage(page)}
						onRowsPerPageChange={(ev) => {
							const fstSeen = actualRowsPerTab * pageNr;
							const newRowsPerPage = ev.target.value;
							this.setState({ pageNr: newRowsPerPage > -1 ? Math.floor(fstSeen / newRowsPerPage) : 0 });
							onSettingsChanged({ rowsPerTab: newRowsPerPage });
						}}
						page={pageNr}
						rowsPerPage={rowsPerTab}
						rowsPerPageOptions={[10, 20, 50, 100, 250, 500, 1000, { value: -1, label: 'All' }]}
					/>
				</div>
				<ActionButton
					label="Export as .CSV"
					color="primary"
					onClick={() => this.exportToCsv()}
					style={{ margin: '25px 0 0' }}
				/>
			</div>
		);
	}
}

ReportTable.propTypes = {
	data: PropTypes.exact({
		obj: PropTypes.objectOf(
			PropTypes.objectOf(
				PropTypes.any,
			),
		),
		arr: PropTypes.arrayOf(PropTypes.exact({
			data: PropTypes.any,
			label: PropTypes.string,
			totals: PropTypes.any,
		})),
		levels: PropTypes.number,
	}).isRequired,
	tableState: PropTypes.exact({
		expanded: PropTypes.objectOf(PropTypes.bool),
		collapsed: PropTypes.objectOf(PropTypes.bool),
		expandAll: PropTypes.bool,
	}),
	sums: PropTypes.arrayOf(PropTypes.string).isRequired,
	serverSums: PropTypes.arrayOf(PropTypes.string).isRequired,
	groupBy: PropTypes.arrayOf(PropTypes.string).isRequired,
	title: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	calculator: PropTypes.object.isRequired,
	calculateSumVal: PropTypes.func.isRequired,
	onSettingsChanged: PropTypes.func.isRequired,
	triggerFilters: PropTypes.array,
	sortSum: PropTypes.string,
	attributes: PropTypes.object,
	sortSumIsComp: PropTypes.bool.isRequired,
	sortReversed: PropTypes.bool.isRequired,
	onSettingsChanged: PropTypes.func.isRequired,
	rowsPerTab: PropTypes.number.isRequired,
	importanceTabPerc: PropTypes.number.isRequired,
	useCompareTo: PropTypes.bool.isRequired,
	groupByOptions: PropTypes.objectOf(PropTypes.string).isRequired,
	funcs: PropTypes.exact({
		limitSumsByGrouping: PropTypes.func,
		availableOptionsFor: PropTypes.func,
		getInitSums: PropTypes.func,
	}).isRequired,
	useForecast: PropTypes.bool.isRequired,
	reportData: PropTypes.object.isRequired,
	trigger: PropTypes.object,
};

ReportTable.defaultProps = {
	sortSumIsComp: false,
	sortSum: undefined,
	triggerFilters: undefined,
	tableState: {
		collapsed: {},
		expanded: {},
		expandAll: false,
	},
	trigger: undefined,
};

export default ReportTable;
