import PropTypes from 'prop-types';
import React from 'react';
import Container from '@mui/material/Container';
import styles from './styles.css';
import UserEditor from '../../containers/UserEditor';

class AdministratorEdit extends React.Component {
	render() {
		const { id } = this.props.match.params;

		return (
			<div className={styles.pageContentWrapper}>
				<Container>
					<UserEditor userId={id} isAdmin />
				</Container>
			</div>
		);
	}
}

AdministratorEdit.propTypes = {
	match: PropTypes.object.isRequired,
};

AdministratorEdit.defaultProps = {
};

export default AdministratorEdit;
