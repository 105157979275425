import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import Spinner from '../Spinner';
import { GmailAccount } from '../../api/relevant';

class GmailAccountEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	updateState(newVals = {}, cb) {
		this.setState(Object.assign(_.cloneDeep(this.state), newVals), cb);
	}

	async applyVerificationCode() {
		const { form, model } = this.props;
		const { verificationCode } = this.state;
		let account;
		this.updateState({ isVerifying: true });
		try {
			account = await GmailAccount.call('authenticate', {
				verificationCode,
				authDataType: model.authDataType || 'GmailAccount',
			});
			this.updateState({ verificationCode: '' });
			Object.assign(model, { ...account, tokenVersion: 1 });
			form.validateAllFields();
		} catch (e) {
			this.updateState({
				verificationError: 'Access not granted for this verification code',
				isVerifying: false,
			});
			return;
		}
		this.updateState({ isVerifying: false, hasVerified: true }, this.props.onSubmit && (() => this.props.onSubmit(account.token)));
	}

	render() {
		const { field, model, style } = this.props;
		const { verificationCode, verificationError, hasVerified } = this.state;
		const { authUrl, hasEmailAddress } = model;
		return (
			<Card style={style}>
				<CardContent>
					<Typography variant="h2">
						Gmail account
					</Typography>
					{ hasVerified && !verificationError
						&& (
							<Box component="span" color="success.main">
								Verification OK
							</Box>
						)}
					{hasEmailAddress
						&& (
							<TextField
								{...field('emailAddress')}
								nonEditable
								required
								label="Email address"
								fullWidth
								margin="normal"
							/>
						)}
					<TextField
						type="hidden"
						label=""
						required={model.isNew}
						{...field('token')}
					/>
					<a
						target="_blank"
						href={authUrl}
						rel="noreferrer"
					>
						Click here to select Gmail account and get verification code
					</a>
					<TextField
						error={verificationError}
						name="verificationCode"
						value={verificationCode}
						label="Verification code"
						onChange={(ev) => this.updateState({ verificationCode: ev.target.value, verificationError: '' })}
						fullWidth
						margin="normal"
					/>
					{this.state.isVerifying ? <Spinner delay />
						: (
							<ActionButton
								label="Apply verification code"
								color="primary"
								disabled={!verificationCode}
								onClick={() => this.applyVerificationCode()}
							/>
						)}
				</CardContent>
			</Card>
		);
	}
}

GmailAccountEditor.propTypes = {
	model: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	style: PropTypes.object,
};

GmailAccountEditor.defaultProps = {
	style: {},
};

export default GmailAccountEditor;
