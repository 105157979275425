import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	styled,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UnstyledLink from '../UnstyledLink';
import useSidebar from '../../hooks/useSidebar';

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
	'&.MuiListItemButton-root': {
		borderLeft: '4px solid transparent',
		'&.Mui-selected': {
			borderLeft: `4px solid ${theme.palette.secondary.main}`, // invisible border
			transition: 'border-width 0.2s ease-in-out',
			color: theme.palette.primary.contrastText,
		},
	},
}));

export default function SidebarButton({
	path,
	text,
	icon,
	secondaryIcon,
	selected,
	dense,
	sx,
	ListItemTextProps,
}) {
	const { mobileOpen, handleDrawerToggle } = useSidebar();

	return (
		<Box onClick={mobileOpen ? handleDrawerToggle : undefined}>
			<UnstyledLink to={path}>
				<StyledListItemButton
					dense={dense}
					sx={{ color: 'primary.tonedDownText', ...sx }}
					selected={selected}
				>
					{ icon && (
						<ListItemIcon
							sx={{
								color: selected ? 'primary.contrastText' : 'primary.tonedDownText',
							}}
						>
							{icon}
						</ListItemIcon>
					)}
					<ListItemText
						primary={text}
						{...ListItemTextProps}
					/>
					{secondaryIcon}
				</StyledListItemButton>
			</UnstyledLink>
		</Box>
	);
}

SidebarButton.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.node,
	path: PropTypes.string.isRequired,
	secondaryIcon: PropTypes.node,
	selected: PropTypes.bool,
	dense: PropTypes.bool,
	sx: PropTypes.shape({}),
	ListItemTextProps: PropTypes.shape({}),
};

SidebarButton.defaultProps = {
	icon: null,
	secondaryIcon: null,
	selected: false,
	dense: false,
	sx: {},
	ListItemTextProps: {},
};
