import React, { Fragment } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { changePassword } from '../../api/relevant';
import storeContainer from '../storeContainer';
import PasswordForm from '../../components/PasswordForm';
import { stores } from '../../stores';
import { Dialog } from '../../components/Dialog';

@storeContainer()
class PasswordEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: {
				password: '',
				repeated: '',
				new: '',
				newRepeated: '',
			},
			isSubmitting: false,
			message: null,
			messageColor: 'error',
		};
	}

	componentWillUnmount() {
		this.setState({ isSubmitting: false });
	}

	setEmpty() {
		this.setState({
			model: {
				password: '',
				repeated: '',
				new: '',
				newRepeated: '',
			},
			isSubmitting: false,
			message: null,
			messageColor: 'error',
		});
	}

	handleSubmitError(e) {
		let error = e;
		if (e.error && e.error.message) {
			error = e.error.message;
		} else if (e.error && _.isString(e.error)) {
			error = e.error;
		} else if (e.error && e.error.errors && _.isString(e.error.errors)) {
			error = e.error.errors;
		} else {
			error = 'An error has occured.';
		}
		this.setState({ message: error, messageColor: 'error' });
	}

	async changePassword(model) {
		this.setState({ isSubmitting: true, message: null });
		try {
			const { result } = await changePassword(model);
			stores.identity.setAuthToken(result);
			this.setState({ message: 'Password changed successfully.', messageColor: undefined });
		} catch (e) {
			this.handleSubmitError(e);
		}
	}

	render() {
		const {
			model, isSubmitting, message, messageColor,
		} = this.state;
		const { identity } = this.props;
		return (
			<>
				<PasswordForm
					identity={identity}
					model={model}
					onSubmit={(newModel) => this.changePassword(newModel)}
				/>
				<Dialog
					open={isSubmitting}
					text={message}
					status={messageColor}
					onClose={() => this.setEmpty()}
				/>
			</>
		);
	}
}

export default withRouter(PasswordEditor);
