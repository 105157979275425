import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import BoltIcon from '@mui/icons-material/Bolt';
import { onSuccess } from '../api';
import Base from '../layouts/Base';
import { ActionButton } from '../components/ActionButton/ActionButton';
import classes from '../api/classes';
import SystemData from './systemData';
import { stores } from '../stores';
import { ConfirmDialog } from '../components/ConfirmDialog';
import { Toast } from '../components/Toast/Toast';
import { Button } from '../components/Button/Button';

const { Reports } = classes;

const isAdmin = () => stores.identity.isAdministrator();

const confirm = async () => {
	const ok = await Base.renderGlobal((closeConfirm) => (
		<ConfirmDialog
			open
			title="Confirm rebuiding of API report data"
			text="This action might affect responsiveness of the system during the following minutes, are you sure?"
			onAny={closeConfirm}
		/>
	));
	if (ok) {
		await Reports.invalidateCache({ force: true });
	}
};

onSuccess.add((resp) => resp.delayedCacheInvalidation && isAdmin() && Base.renderGlobal((closeFn) => (
	<Toast
		open
		message={(close) => (
			<div className="flex flex-col gap-2">
				Possible updates of API report-data that reflects changes you&apos;ve just done is delayed until the next daily API-import.
				<ConfirmDialog
					title="Confirm rebuiding of API report data"
					text="This action might affect responsiveness of the system during the following minutes, are you sure?"
				>
					<Button variant="outlined" className="block" onClick={() => { Reports.invalidateCache({ force: true }); close(); closeFn(); }}>Apply updates now</Button>
				</ConfirmDialog>
			</div>
		)}
		timeout={5}
		status="info"
		onClose={closeFn}
	/>
)));

class DelayedCacheInvalidation extends React.Component {
	constructor(props) {
		super(props);
		const getState = () => ({ delayed: !!SystemData.genericData.delayedInvalidations });
		Object.assign(this, {
			state: getState(),
			listener: () => this.setState(getState()),
		});
		SystemData.onGenericDataUpdated.add(this.listener, true);
	}

	componentWillUnmount() {
		SystemData.onGenericDataUpdated.remove(this.listener());
	}

	render() {
		const { delayed } = this.state;
		if (!delayed || !isAdmin()) {
			return null;
		}
		return (
			<Tooltip
				title={(
					<>
						Updates have been done in the system that have not yet been reflected in API-reports.
						Examples of updates that may affect the content of API-reports include:
						<ul className="list-disc list-inside">
							<li>Mapping of SSP-placements</li>
							<li>Changes of share % for publsher accounts / SSPs</li>
						</ul>
						Press this button to ensure that API report data is updated. These updates are otherwise delayed until the next daily API-import.
					</>
				)}
			>
				<div>
					<ActionButton
						label="Apply updates in API reports"
						onClick={confirm}
						sx={{ fontSize: 'small', width: '100%' }}
						icon={<BoltIcon style={{ color: 'yellow' }} />}
						size="large"
					/>
				</div>
			</Tooltip>
		);
	}
}

export default DelayedCacheInvalidation;
