import React from 'react';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { GlobalSettingsObject, GlobalUserData } from '../../api/relevant';
import ExpandSelector from '../ExpandSelector';
import TriggerCheckList from './triggerCheckList';
import InfoChannelPopup from './infoChannelPopup';
import TriggerList from './triggerList';
import TriggerFilter from './triggerFilter';
import { SysTriggerFailedMessage } from './dialogues';

function TriggerWorkspaceLayout({
	workspace,
	triggers,
	globalInfoChannel,
	userInfoChannel,
	checks,
	selectedChecks,
	selectedTriggers,
	onSelectionChanged,
	failedSysTriggerInfos,
}) {
	return (
		<Grid container spacing={2}>
			<Grid item xs={2}>
				<Box marginBottom={3}>
					<Card>
						<CardContent>
							<Typography variant="h3">
								Generic settings
							</Typography>
							<Grid container spacing={2}>
								{workspace.isAdmin && (
									<InfoChannelPopup
										workspace={workspace}
										reload
										label="Edit global alarm recipients"
										infoChannel={globalInfoChannel}
										save={async (data) => {
											const globalSettings = await GlobalSettingsObject.listOne();
											await globalSettings.update({
												defaultTriggerChannel: data,
												updateParams: { noReportInvalidate: true },
											});
										}}
									/>
								)}
								<InfoChannelPopup
									workspace={workspace}
									reload
									label="Edit my alarm recipients"
									infoChannel={userInfoChannel}
									save={async (data) => {
										const userData = await GlobalUserData.call('getMyData');
										await userData.update({ triggerInfoChannel: data });
									}}
								/>
							</Grid>
						</CardContent>
					</Card>
				</Box>
				<Card>
					<CardContent>
						<Typography variant="h3">
							Show checks
						</Typography>
						<TriggerFilter field={workspace.fld} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={10}>
				<Card>
					<CardContent>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<ExpandSelector
									title="Alarms"
									customHeader={(
										<Box color="success.main">
											{triggers.filter((t) => t.active).length}
											/
											{triggers.length}
											{' '}
											active
										</Box>
									)}
								>
									<TriggerList
										selectedTriggers={selectedTriggers}
										checks={checks}
										onSelectionChanged={(selected) => onSelectionChanged({ selectedTriggers: selected })}
										workspace={workspace}
										triggers={triggers}
									/>
								</ExpandSelector>
							</Grid>
							{!!failedSysTriggerInfos.length && (
								<Grid item xs={12}>
									<Alert severity="warning">
										<SysTriggerFailedMessage
											failedSysTriggerInfos={failedSysTriggerInfos}
											workspace={workspace}
										/>
									</Alert>
								</Grid>
							)}
							<Grid item xs={12}>
								<TriggerCheckList
									checks={checks}
									onSelectionChanged={(selected) => onSelectionChanged({ selectedChecks: selected })}
									selectedChecks={selectedChecks}
									workspace={workspace}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

TriggerWorkspaceLayout.propTypes = {
	workspace: PropTypes.object.isRequired,
	triggers: PropTypes.array.isRequired,
	globalInfoChannel: PropTypes.object,
	userInfoChannel: PropTypes.object,
	checks: PropTypes.array.isRequired,
	selectedChecks: PropTypes.array.isRequired,
	selectedTriggers: PropTypes.array.isRequired,
	onSelectionChanged: PropTypes.func.isRequired,
	failedSysTriggerInfos: PropTypes.array.isRequired,
};

TriggerWorkspaceLayout.defaultProps = {
	globalInfoChannel: undefined,
	userInfoChannel: undefined,
};

export default TriggerWorkspaceLayout;
