import _ from 'lodash';
import Mutex from 'relevant-shared/misc/mutex';
import {
	MiscTasks, addPublisher,
} from '../api/relevant';
import classes from '../api/classes';
import MiscUtils from '../lib/miscUtils';

class PublisherStore {

	constructor() {
		this.mutex = new Mutex();
		this.onChangeEvent = MiscUtils.listeners();
	}

	loadAll(forceReload) {
		return this.mutex.runIf(() => !this.publishers || forceReload, async () => {
			const publishers = await classes.Publisher.list();
			this.set(_.sortBy(publishers, 'name'));
		});
	}

	async add(publisher) {
		await addPublisher(publisher);
		this.loadAll(true);
	}

	isReady() {
		return !!this.publishers;
	}

	get all() {
		return this.publishers;
	}

	async edit(publisher) {
		const editedPublisher = await publisher.update(null, { updateUsingDiff: true });
		const publishers = (this.all || []).map((pub) => {
			if (pub.id === editedPublisher.id) {
				pub = editedPublisher;
			}
			return pub;
		});
		this.set(_.sortBy(publishers, 'name'));
	}

	async delete(id) {
		await MiscTasks.call('deletePublisher', { id });
		const publishers = this.all.filter((pub) => pub.id !== id);
		this.set(_.sortBy(publishers, 'name'));
	}

	set(publishers) {
		this.publishers = publishers;
		this.onChangeEvent.notify();
		return true;
	}

	byId(id) {
		return _.find(this.publishers, { id });
	}
}

export default PublisherStore;
