import React, {
	useState,
	useCallback,
	useRef,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DragIcon from '@mui/icons-material/DragHandle';
import styles from './styles.css';
import Report from '../../components/Report';
import { stores } from '../../stores';
import { useDashboardTopBar } from './hooks/useDashboardTopBar';
import { useDashboardWidgets } from './hooks/useDashboardWidgets';

/**
	Note that 'Widgets' may in some places be called 'Dashboards'.
	When in "reality", we have ONE dashboard with multiple "widgets" in it.
 */
function Dashboard({ type }) {
	const reportWidgetWrapper = useRef(null);
	const [widgets, updateWidgets] = useDashboardWidgets();
	const [settingsOpenIndex, setSettingsOpenIndex] = useState(false);
	const [isDragging, setIsDragging] = useState(false);

	const addWidget = useCallback(async (widget) => {
		const newWidget = {
			component: 'Report',
			componentProperties: {
				...widget.settings,
				isActualReport: false,
			},
			id: Date.now(),
		};
		// The new element does not have its final coords immediately
		// after entering the DOM, probably due to the SVG charts being
		// rerendered.
		setTimeout(() => {
			const el = reportWidgetWrapper.current;
			if (el) {
				el.scrollIntoView({
					block: 'start',
					inline: 'nearest',
					behavior: 'smooth',
				});
			}
		}, 1000);
		const updatedWidgets = {
			...widgets,
			[type]: [...widgets[type], newWidget],
		};
		updateWidgets(updatedWidgets);
	}, [type, updateWidgets, widgets]);

	useDashboardTopBar(type, addWidget);

	const onDragEnd = (result) => {
		const { destination, source } = result;
		if (!destination) {
			setIsDragging(true);
		} else if (destination.droppableId === 'selectedDashboards') {
			// Update position
			const [draggedWidget] = widgets[type].splice(source.index, 1);
			widgets[type].splice(destination.index, 0, draggedWidget);
			updateWidgets(widgets);
		}
		setIsDragging(false);
	};

	const onSaveWidgetClick = (widget) => {
		const widgetToEdit = widgets[type]
			.find(({ id }) => id === widget.id);
		const newProps = {
			...widgetToEdit.componentProperties,
			...(widget.element ? widget.element.getCurrentProperties() : {}),
		};
		widgetToEdit.componentProperties = _.cloneDeep(newProps);
		const updatedSelectedWidgets = widgets[type]
			.map((d) => (d.id === widgetToEdit.id ? widgetToEdit : d));

		const updatedDashboards = {
			...widgets,
			[type]: updatedSelectedWidgets,
		};
		updateWidgets(updatedDashboards);
	};

	const onRemoveWidgetClick = (dashboard) => {
		const selectedWidgets = widgets[type];
		const remainingWidgets = selectedWidgets.filter((d) => d.id !== dashboard.id);
		const updatedWidgets = {
			...widgets,
			[type]: remainingWidgets,
		};
		updateWidgets(updatedWidgets);
	};

	const widgetsToRender = widgets?.[type] || [];
	for (const widget of widgetsToRender) {
		widget.ref = React.createRef();
	}

	const isEmpty = widgetsToRender?.length < 1;
	let droppableClassName = null;
	if (isEmpty || isDragging) {
		droppableClassName = styles.dashboardPlaceholderDroppable;
	}

	return (
		<DragDropContext
			onDragStart={() => setIsDragging(true)}
			onDragEnd={onDragEnd}
		>
			<Grid item xs={12}>
				<Droppable droppableId="selectedDashboards">
					{(provided) => (
						<div ref={provided.innerRef} className={droppableClassName} {...provided.droppableProps}>
							{widgetsToRender.map((widget, i) => (
								<Draggable draggableId={String(widget.id)} index={i} key={widget.id}>
									{(draggableProvided) => (
										<Box
											ref={reportWidgetWrapper}
											mb={3}
											display="flex"
											flexDirection="column"
										>
											<Box
												ref={draggableProvided.innerRef}
												{...draggableProvided.draggableProps}
												sx={{
													userSelect: 'none',
													...draggableProvided.draggableProps.style,
												}}
											>
												{/*
													Widget was created with a 'component' field in order
													for this to be generic.
													However, in reality, only 'Report' currently exists
												*/}
												{ widget.component === 'Report' && (
													<Report
														{...widget.componentProperties}
														isAdministrator={stores.identity.isAdministrator()}
														user={() => stores.identity.user()}
														ref={(elm) => {
															widget.element = elm;
														}}
														warnOldData
														onRemove={() => onRemoveWidgetClick(widget)}
														onSave={() => onSaveWidgetClick(widget)}
														settingsOpen={settingsOpenIndex === i}
														onSettingsOpenToggle={(isOpen) => {
															setSettingsOpenIndex(isOpen ? i : null);
														}}
														header={(
															<Box
																bgcolor="primary.main"
																color="primary.contrastText"
																display="flex"
																justifyContent="center"
																className={styles.dragHandle}
																{...draggableProvided.dragHandleProps}
															>
																<DragIcon className={styles.dragIcon} />
															</Box>
														)}
													/>
												)}
											</Box>
										</Box>
									)}
								</Draggable>
							))}
							{isEmpty ? (
								<h2
									className={styles.dashboardPlaceholderText}
								>
									Drag a widget here
								</h2>
							) : provided.placeholder}
						</div>
					)}
				</Droppable>
			</Grid>
		</DragDropContext>
	);
}

Dashboard.propTypes = {
	type: PropTypes.oneOf(['audience', 'programmatic']).isRequired,
};

export default Dashboard;
