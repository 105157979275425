import React from 'react';
import PropTypes from 'prop-types';
import {
	FormControlLabel,
	Switch,
	Box,
	Button,
	Autocomplete,
	TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function EventSettings({
	showEventLines,
	onShowEventLinesChange,
	onAddEventClick,
	tags,
	selectedTags,
	onTagFilterChange,
}) {
	return (
		<div>
			<Box sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
			/>
			<div>
				<FormControlLabel
					label="Show events"
					control={(
						<Switch
							name="Show event lines"
							checked={showEventLines}
							color="primary"
							onChange={onShowEventLinesChange}
						/>
					)}
				/>

				<Autocomplete
					sx={{ mt: 2 }}
					options={tags}
					value={selectedTags}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Filter by tags"
							InputProps={{
								...params.InputProps,
								type: 'search',
							}}
						/>
					)}
					onChange={(_, value) => {
						onTagFilterChange(value);
					}}
					autoSelect
					fullWidth
					multiple
				/>

			</div>
			<Button
				color="info"
				variant="text"
				sx={{ mt: 2 }}
				startIcon={<AddIcon />}
				onClick={onAddEventClick}
			>
				New event
			</Button>
		</div>
	);
}

EventSettings.propTypes = {
	tags: PropTypes.arrayOf(PropTypes.string),
	selectedTags: PropTypes.arrayOf(PropTypes.string),
	showEventLines: PropTypes.bool.isRequired,
	onShowEventLinesChange: PropTypes.func.isRequired,
	onAddEventClick: PropTypes.func.isRequired,
	onTagFilterChange: PropTypes.func.isRequired,
};

EventSettings.defaultProps = {
	tags: [],
	selectedTags: [],
};

export default EventSettings;
