import React, {
	createContext,
	useState,
	useMemo,
	useCallback,
} from 'react';
import PropTypes from 'prop-types';
import useRouteChange from '../../hooks/useRouteChange';

export const TopBarContext = createContext({});

export default function TopBarProvider({ children }) {
	const [title, setTitle] = useState();
	const [titleNavigation, setTitleNavigation] = useState();
	// Settings contains stuff that might be unique for a given page.
	const [settings, setSettings] = useState({});
	const [status, setStatus] = useState(null);

	// Reset settings when route changes, unless its reports or dashboard
	const resetSettings = useCallback(() => {
		if (settings && Object.keys(settings).length) {
			setSettings({});
		}
	}, [settings]);

	useRouteChange(resetSettings, [
		{ route: '/reports', exact: false },
		{ route: '/accounts', exact: true },
		{ route: '/dashboard', exact: false },
		{ route: '/settings/events', exact: true },
	]);

	const providerValue = useMemo(() => ({
		title,
		setTitle,
		titleNavigation,
		setTitleNavigation,
		settings,
		setSettings,
		status,
		setStatus,
	}), [title, titleNavigation, settings, status]);

	return (
		<TopBarContext.Provider value={providerValue}>
			{ children }
		</TopBarContext.Provider>
	);
}
TopBarProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	settings: PropTypes.shape({
		type: PropTypes.oneOf(['report', 'other']),
	}),
};

TopBarProvider.defaultProps = {
	settings: {
		type: 'other',
	},
};
