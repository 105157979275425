import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DemoSettings from 'relevant-shared/misc/demoSettings';
import OperationWrapper from '../OperationWrapper';
import { FormOf, withUpdate } from '../Wrappers';
import {
	Ssp, Adserver, KeyValue, editPublisher, listOfPublishers,
} from '../../api/relevant';
import TextField from '../TextField';
import { stores } from '../../stores';
import TreeSelect from '../TreeSelect';
import Checkbox from '../Checkbox';
import FormCollection from '../../lib/formCollection';
import Base from '../../layouts/Base';
import ExpandSelector from '../ExpandSelector';
import validation from '../../lib/validation';
import { Dialog } from '../Dialog';

const DEMO_IMPORT_SETTINGS_KEY = 'demoSettings';

const readAllPubs = async () => (await listOfPublishers()).result.filter((p) => p.programmaticAccess);

@withUpdate
class DemoImport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.forms = new FormCollection();
	}

	async load() {
		const [publishers, ssps, adservers, keyRes] = await Promise.all([
			readAllPubs(),
			Ssp.list(),
			Adserver.list(),
			KeyValue.list({ key: DEMO_IMPORT_SETTINGS_KEY }),
		]);
		publishers.forEach((pub) => {
			pub.__type = 'Publisher';
			pub.__sitesById = {};
			(pub.websites || []).forEach((site) => {
				site.__type = 'Site';
				site.__parent = pub;
				pub.__sitesById[site.id] = site;
				(site.placements || []).forEach((placement) => {
					placement.__type = 'Placement';
					placement.__parent = site;
				});
			});
		});
		this.setState({
			publishers,
			ssps,
			adservers,
			settings: new DemoSettings({
				publishers,
				ssps,
				adservers,
				settings: JSON.parse((keyRes[0] || {}).value || '{}'),
				editPublisher: (pub) => editPublisher(pub.id, pub),
			}),
		});
	}

	renderGenericSettings(settingsPath, p) {
		const { current } = this.state;
		const isNumber = (n) => validation.float().valid(n);
		const propsOf = (name) => {
			const res = p.field(name);
			if (settingsPath.length > 1) {
				for (let i = settingsPath.length - 2; i >= 0; i--) {
					if (!settingsPath[i]) {
						continue;
					}
					if (isNumber((settingsPath[i].generic || {})[name])) {
						res.help = `${settingsPath[i].generic[name]}`;
						break;
					}
				}
				res.required = false;
			} else {
				res.required = true;
			}
			return res;
		};
		return (
			<Card>
				<CardContent>
					<Typography variant="h2">
						Generic settings:
						{' '}
						{current.name || current.domain}
					</Typography>
					<TextField
						between={{ low: 0, high: 100 }}
						float
						label="Fill Rate %"
						{...propsOf('fillRatePerc')}
						fullWidth
						margin="normal"
					/>
					<TextField
						between={{ low: 0, high: 100 }}
						float
						label="Viewability %"
						{...propsOf('ismPerc')}
						fullWidth
						margin="normal"
					/>
					<TextField
						between={{ low: 0, high: 100 }}
						float
						label="Click Rate %"
						{...propsOf('clickPerc')}
						fullWidth
						margin="normal"
					/>
					<TextField
						required
						float
						label="eCPM Multiplier"
						{...propsOf('ecpm')}
						fullWidth
						margin="normal"
					/>
				</CardContent>
			</Card>
		);
	}

	renderPublisherSettings() {
		const { settings, current } = this.state;
		const pubSettings = settings.publisherSettings(current.id);
		const { generic } = pubSettings || {};
		let formProps;
		return (
			<>
				<Grid item xs={12}>
					<Checkbox
						label="Enable demo data generation"
						name="isEnabled"
						value={!!pubSettings}
						onChange={(ev) => this.update(() => {
							settings.setEnabled(current.id, ev.target.value);
						})}
					/>
				</Grid>
				{pubSettings && (
					<FormOf
						model={pubSettings.generic}
						formCollection={this.forms}
						onFieldUpdate={(name, value) => {
							formProps.form.update(() => settings.normalize(current.id, name, value));
						}}
						content={(p) => {
							formProps = p;
							return (
								<>
									<Grid item xs={12}>
										{this.renderGenericSettings([pubSettings], p)}
									</Grid>
									<Grid item xs={12}>
										<ExpandSelector title="Traffic % by type" form={p.form}>
											<TextField
												required
												between={{ low: 0, high: 100 }}
												float
												label="Open RTB"
												{...p.field('openRtbPerc')}
												fullWidth
												margin="normal"
											/>
											<TextField
												required
												between={{ low: 0, high: 100 }}
												float
												label="Deals"
												{...p.field('dealsPerc')}
												fullWidth
												margin="normal"
											/>
										</ExpandSelector>
									</Grid>
									<Grid item xs={12}>
										<ExpandSelector title="Settings by SSP" form={p.form}>
											{_.sortBy(settings.ssps, 'name').map((ssp) => {
												const sspSettings = generic.bySsp[ssp.id];
												return (
													<Card key={ssp.id}>
														<CardContent>
															<Typography variant="h2">
																{ssp.name}
															</Typography>
															<Checkbox
																label="Enable for publisher"
																name={`enabled_${ssp.id}`}
																value={!!sspSettings}
																style={{ marginTop: 30 }}
																onChange={(ev) => this.update(() => {
																	settings.setSspEnabled(current.id, ssp.id, ev.target.value);
																})}
															/>
															{sspSettings && (
																<TextField
																	{...p.field(`bySsp.${ssp.id}.impSharePerc`)}
																	between={{ low: 0, high: 100 }}
																	float
																	label="Impression %"
																	required
																	fullWidth
																	margin="normal"
																/>
															)}
														</CardContent>
													</Card>
												);
											})}
										</ExpandSelector>
									</Grid>
									<Grid item xs={12}>
										<ExpandSelector title="Adserver In Per Week-Day" form={p.form}>
											{['Monday', 'Thuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, idx) => (
												<TextField
													key={day}
													{...p.field(`adInPerDay.${(idx + 1) % 7}`)}
													float
													label={day}
													required
													fullWidth
													margin="normal"
												/>
											))}
										</ExpandSelector>
									</Grid>
									<Grid item xs={12}>
										<ExpandSelector title="Adserver In % by Site" form={p.form}>
											{_.sortBy(current.websites || [], 'domain').map((site) => (
												<TextField
													key={site.id}
													{...p.field(`bySite.${site.id}.impSharePerc`)}
													between={{ low: 0, high: 100 }}
													float
													label={site.domain}
													required
													fullWidth
													margin="normal"
												/>
											))}
										</ExpandSelector>
									</Grid>
								</>
							);
						}}
					/>
				)}
			</>
		);
	}

	renderSiteSettings() {
		const { settings, current } = this.state;
		const pub = current.__parent;
		const pubSettings = settings.publisherSettings(pub.id);
		const siteSettings = settings.getOrAddChild(pubSettings, current.id);
		return (
			<FormOf
				model={siteSettings.generic}
				formCollection={this.forms}
				content={(p) => this.renderGenericSettings([pubSettings, siteSettings], p)}
			/>
		);
	}

	renderPlacementSettings() {
		const { settings, current } = this.state;
		const pub = current.__parent.__parent;
		const pubSettings = settings.publisherSettings(pub.id);
		const siteSettings = settings.getOrAddChild(pubSettings, current.__parent.id);
		const placementSettings = settings.getOrAddChild(siteSettings, current.id);
		return (
			<FormOf
				model={placementSettings.generic}
				formCollection={this.forms}
				content={(p) => this.renderGenericSettings([pubSettings, siteSettings, placementSettings], p)}
			/>
		);
	}

	showFormErrorMessage() {
		Base.renderGlobal((done) => (
			<Dialog
				open
				status="error"
				text="Please correct the errors first"
				onClose={done}
			/>
		));
	}

	async save(cancel) {
		const { settings } = this.state;
		if (!settings) {
			return; // not loaded yet, ignore..
		}
		if (this.forms.checkErrors()) {
			cancel();
			this.showFormErrorMessage();
			return;
		}
		const value = JSON.stringify(settings.getObject());
		const arr = await KeyValue.list({ key: DEMO_IMPORT_SETTINGS_KEY });
		if (arr[0]) {
			await arr[0].update({ value });
		} else {
			await KeyValue.add({ key: DEMO_IMPORT_SETTINGS_KEY, value });
		}
		const pubs = await readAllPubs();
		await settings.syncSystemsToPublishers(pubs);
		stores.publishers.loadAll(true);
	}

	render() {
		const { publishers, settings, current } = this.state;
		return (
			<OperationWrapper
				fn={async (op) => {
					this.op = op;
					await this.load();
				}}
			>
				{settings && (
					<Card>
						<CardContent>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="h2">
										Fabricated data settings
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<TreeSelect
										getChildren={(n) => {
											if (n.__type === 'Publisher' && !settings.publisherSettings(n.id)) {
												return [];
											}
											return n.websites || n.placements || [];
										}}
										getLabel={(n) => n.name || n.domain || 'Error'}
										getNodeId={(n) => n.id}
										rootChildren={_.sortBy(publishers, 'name')}
										getIsLeaf={(n) => !n.__parent}
										onChange={(n, cancel) => {
											if (this.forms.checkErrors()) {
												cancel();
												this.showFormErrorMessage();
											} else {
												this.setState({ current: n });
											}
										}}
										render={(n, label) => (n === current ? <strong>{label}</strong> : label)}
									/>
								</Grid>
								<Grid item xs={8} key={(current || {}).id}>
									<Grid container spacing={3} style={{ minHeight: 800 }}>
										{ current && current.__type === 'Publisher' && this.renderPublisherSettings()}
										{ current && current.__type === 'Site' && this.renderSiteSettings()}
										{ current && current.__type === 'Placement' && this.renderPlacementSettings()}
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				)}
			</OperationWrapper>
		);
	}
}

DemoImport.propTypes = {

};

DemoImport.defaultProps = {

};

export default DemoImport;
