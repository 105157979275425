import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes, { string } from 'prop-types';
import Menu from '@mui/material/Menu';
import uuid from 'uuid';

function AnchoredMenu({
	id, icon, iconProps, iconLabel, tooltipTitle, children,
}) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const menuId = id || uuid.v4();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const elemId = open ? `${menuId}-anchored-menu` : undefined;
	const buttonId = `${menuId}-button`;
	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
				<Tooltip title={tooltipTitle}>
					<IconButton
						id={buttonId}
						onClick={handleClick}
						size="small"
						sx={{ ml: 2 }}
						aria-controls={open ? elemId : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						{...iconProps}
					>
						{icon || (<Avatar sx={{ width: 32, height: 32 }}>{iconLabel}</Avatar>)}
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				id={elemId}
				aria-labelledby={buttonId}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{children}
			</Menu>
		</>
	);
}
export default AnchoredMenu;

AnchoredMenu.propTypes = {
	id: PropTypes.string,
	iconProps: PropTypes.objectOf(PropTypes.any),
	iconLabel: string,
	icon: PropTypes.node,
	tooltipTitle: PropTypes.string,
	children: PropTypes.node,

};

AnchoredMenu.defaultProps = {
	id: undefined,
	iconProps: undefined,
	iconLabel: '?',
	icon: undefined,
	tooltipTitle: 'Menu',
	children: undefined,
};
