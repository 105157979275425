import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function AlertBalloon({ sx, label }) {
	return (
		<Box
			sx={{
				bgcolor: 'error.main',
				pt: 0.5,
				pb: 0.5,
				pl: 1,
				pr: 1,
				fontSize: '14px',
				borderRadius: '6px',
				color: 'offWhite.main',
				...sx,
			}}
		>
			{label}
		</Box>
	);
}

AlertBalloon.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
	sx: PropTypes.shape({}),
};

AlertBalloon.defaultProps = {
	sx: {},
};

export default AlertBalloon;
