import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import SuccessIcon from '@mui/icons-material/Done';
import CircularProgress from '@mui/material/CircularProgress';
import deployStatuses from 'relevant-shared/misc/deployStatuses';
import styles from './styles.css';
import { isDeployDone } from '../../lib/globalAdminUtils';

function getPrioritizedDeployState(deploys) {
	const instanceBeingUpdatedState = deploys.find(({ status }) => status === deployStatuses.ONGOING);
	if (instanceBeingUpdatedState) {
		return instanceBeingUpdatedState;
	}
	const instanceFailedDeployState = deploys.find(({ status }) => status === deployStatuses.FAILED);
	if (instanceFailedDeployState) {
		return instanceFailedDeployState;
	}
	const instancePendingUpdateState = deploys.find(({ status }) => status === deployStatuses.PENDING);
	if (instanceBeingUpdatedState) {
		return instancePendingUpdateState;
	}
	const successfullyDeployed = deploys.every(({ status }) => status === deployStatuses.SUCCESS);
	if (successfullyDeployed && deploys.length) {
		// Its not possible right now to restart and deploy so we just check the first branch name if its a restart
		const isRestart = deploys[0].branch === 'restart';
		return {
			status: deployStatuses.SUCCESS,
			message: isRestart ? `Successfully restarted ${deploys.length} instances`
				: `Successfully deployed to ${deploys.length} instances`,
		};
	}
	return {
		status: undefined,
		message: 'Unknown deploy status',
	};
}

function AppInstanceStatus({ data, deploysAreDone, deployTime }) {
	const deploys = data
		.filter(({ deploy }) => deploy && Object.keys(deploy).length > 0)
		.map(({ deploy }) => deploy);

	const renderLoadingIcon = (message) => (
		<Box className={styles.statusContainer}>
			{/* size={35} corresponds to fontSize large for SuccessIcon and ErrorIcon */}
			<CircularProgress className={`${styles.statusIcon}`} size={35} />
			<Typography>
				{message}
			</Typography>
		</Box>
	);

	const renderErrorIcon = (message) => (
		<Box className={styles.statusContainer}>
			<ErrorIcon className={styles.statusIcon} fontSize="large" color="error" />
			<Typography>
				{message}
			</Typography>
		</Box>
	);

	const renderSuccessIcon = (message) => (
		<Box
			className={styles.statusContainer}
			color="success.main"
		>
			<SuccessIcon className={styles.statusIcon} fontSize="large" />
			<Typography>
				{message}
			</Typography>
		</Box>
	);
	// Show deploy status if ongoing, or has finished less than a day ago.
	const showDeployStatus = (deploysAreDone && deployTime && ((Date.now() - deployTime) < 86400000))
		|| (!deploysAreDone && deploys.length > 0);
	if (showDeployStatus) {
		const state = getPrioritizedDeployState(deploys);
		if (!isDeployDone(state)) {
			return renderLoadingIcon(state.message);
		} if (state.status === deployStatuses.FAILED) {
			return renderErrorIcon(state.message);
		} if (state.status === deployStatuses.SUCCESS) {
			return renderSuccessIcon(state.message);
		}
	}
	const numInstances = data.length;
	const numFailedInstances = data.filter(({ isUp }) => !isUp).length;
	if (numFailedInstances > 0) {
		return renderErrorIcon(`${numFailedInstances}/${numInstances} instances failed to respond`);
	}
	return renderSuccessIcon('All instances successfully responded');
}

export default AppInstanceStatus;

AppInstanceStatus.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
	deploysAreDone: PropTypes.bool,
	deployTime: PropTypes.number,
};

AppInstanceStatus.defaultProps = {
	data: [],
	deploysAreDone: undefined,
	deployTime: undefined,
};
