import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { PublisherNode, SspPlacementNode } from 'relevant-shared/objects/pubTree';
import Form from '../../containers/Form';
import PublisherForm from '../PublisherForm';
import WebsiteForm from '../WebsiteForm';
import PlacementForm from '../PlacementForm';
import SSPForm from '../SSPForm';
import { ActionButton } from '../ActionButton/ActionButton';
import SystemData from '../../lib/systemData';
import PrebidConfigEdit from '../PrebidConfigEdit';
import classes from '../../api/classes';
import NodeDragDrop from '../Wrappers/nodeDragDrop';

@Form
class SinglePublisherForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			placementLabelOptions: this.props.placementLabels,
		};
		this.dirtyBidParamSet = new Set();
		this.goToSiteRef = null;
		this.goToPlacementRef = null;
	}

	updateDirtyBidParams() {
		for (const bidEdit of this.dirtyBidParamSet) {
			bidEdit.applyChanges();
		}
	}

	renderPlacementChild(child, placementNode) {
		const { field, form } = this.props;
		return (
			<NodeDragDrop
				form={form}
				id={child.id}
				publisherNode={placementNode.publisherNode}
				disabled={!(child instanceof SspPlacementNode)}
			>
				<SSPForm
					placementNode={placementNode}
					object={child}
					field={(name) => field(`publisher.${child.dbPath}.${name}`)}
					form={form}
				/>
			</NodeDragDrop>
		);
	}

	renderPlacementForm(placement, websiteIndex, placementIndex, publisherNode, expand, prebidConfigs, siteId) {
		const {
			globalSettings,
			model,
			field,
			value,
			collectionAdd,
			collectionRemove,
			SSPs,
			validateFields,
			systems,
			form,
		} = this.props;
		const placementNode = publisherNode.byId[placement.id];
		return (
			<>
				{this.props.goToPlacementId === placement.id && (
					<div ref={(ref) => this.goToPlacementRef = ref} style={{scrollMarginTop: "80px"}} />
				)}
				<PlacementForm
					form={form}
					field={(name) => field(`publisher.websites.${websiteIndex}.placements.${placementIndex}.${name}`)}
					value={(name) => value(`publisher.websites.${websiteIndex}.placements.${placementIndex}.${name}`)}
					collectionAdd={(collection) => collectionAdd(`publisher.websites.${websiteIndex}.placements.${placementIndex}.ssps`, collection)}
					collectionRemove={() => collectionRemove(`publisher.websites[${websiteIndex}].placements`, placementIndex)}
					SSPs={SSPs}
					adservers={systems.adservers}
					checkForErrors={() => _.some(validateFields(), (error, key) => key.indexOf(`publisher.websites.${websiteIndex}.placements.${placementIndex}`) > -1)}
					globalSettings={globalSettings}
					publisher={model.publisher}
					placementNode={placementNode}
					dirtyBidParamSet={this.dirtyBidParamSet}
					expand={!!expand}
					placementLabelOptions={this.state.placementLabelOptions}
					sitePrebidConfigs={prebidConfigs}
					siteId={siteId}
					onPlacementLabelUpdate={(newLabel) => {
						// Display recently added labels that has not yet been saved.
						// One drawback of this is that placementLabelOptions might include new (unsaved)
						// labels that have been entered, and then directly removed.
						// I don't think that is a huge issue though.
						if (newLabel && !this.state.placementLabelOptions.includes(newLabel)) {
							this.setState({ placementLabelOptions: [...this.state.placementLabelOptions, newLabel] });
						}
					}}
					content={() => placementNode.children.filter((obj) => obj.ssp).map((child) => (
						<Grid item xs={12} key={child.id} style={{ padding: 12 }}>
							{this.renderPlacementChild(child, placementNode)}
						</Grid>
					))}
				/>
			</>
		);
	}

	renderWebsiteForm(website, websiteIndex, publisherNode) {
		const {
			form,
			field,
			systems,
			value,
			collectionAdd,
			collectionRemove,
			validateFields,
			history,
			globalSettings,
		} = this.props;
		return (
			<>
				{this.props.goToSiteId === website.id && (
					<div ref={(ref) => this.goToSiteRef = ref} style={{scrollMarginTop: "80px"}} />
				)}
				<WebsiteForm
					form={form}
					field={(name) => field(`publisher.websites.${websiteIndex}.${name}`)}
					value={(name) => value(`publisher.websites.${websiteIndex}.${name}`)}
					collectionAdd={(collection) => collectionAdd(`publisher.websites.${websiteIndex}.placements`, collection)}
					collectionRemove={() => collectionRemove('publisher.websites', websiteIndex)}
					checkForErrors={() => _.some(validateFields(), (error, key) => key.indexOf(`publisher.websites.${websiteIndex}`) > -1)}
					publisher={value('publisher')}
					systems={systems}
					website={website}
					history={history}
					globalSettings={globalSettings}
					publisherNode={publisherNode}
					expanded={website.id === this.props.goToSiteId}
					content={({ showMissingRequiredPlacId }) => (
						website.placements && website.placements.map((placement, placementIndex) => (
							<Grid item xs={12} key={placementIndex} style={{ padding: '6px 0px 6px 12px', marginLeft: '12px' }}>
								{this.renderPlacementForm(
									placement,
									websiteIndex,
									placementIndex,
									publisherNode,
									showMissingRequiredPlacId === placement.id || placement.id === this.props.goToPlacementId,
									website.prebidConfigs,
									website.id,
								)}
							</Grid>
						))
					)}
				/>
			</>
		);
	}

	renderWebsites(publisherNode, websites) {
		const {
			collectionAdd, systems, globalSettings, value,
		} = this.props;
		return (
			<Paper>
				<Box padding={2}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h2">
								Websites/Apps
							</Typography>
						</Grid>
						{websites && websites.map((website, websiteIndex) => (
							<Grid item xs={12} key={`${website.id}_${websiteIndex}`}>
								{this.renderWebsiteForm(website, websiteIndex, publisherNode)}
							</Grid>
						))}
						<Grid item xs={12}>
							<Tooltip title="New website">
								<IconButton
									onClick={() => collectionAdd('publisher.websites', new SystemData.models.Site({
										domain: 'New website',
										placements: [],
										prebidConfigs: [],
									}))}
									size="large"
								>
									<AddIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		);
	}

	renderPublisherForm(publisherNode) {
		const {
			field, value, collectionAdd, collectionRemove, validateFields, hasValue, docTemplates, systems, history, form,
		} = this.props;
		const { publisher } = this.props.model;
		return (
			<PublisherForm
				field={field}
				value={value}
				form={form}
				collectionAdd={collectionAdd}
				collectionRemove={collectionRemove}
				cxDmpSites={publisher.cxDmpSites}
				checkForErrors={() => _.some(validateFields(), (error, key) => key.indexOf('publisher.cxDmpSites') > -1)}
				isNew={!hasValue('user.id')}
				systems={systems}
				publisherId={publisher.id}
				publisher={publisher}
				history={history}
				docTemplates={docTemplates}
				publisherNode={publisherNode}
			/>
		);
	}

	renderFormButtons() {
		const { submit } = this.props;
		return (
			<Grid item>
				<ActionButton
					label="Save"
					color="primary"
					onClick={() => {
						this.updateDirtyBidParams();
						submit(null, { checkFormCollection: true });
					}}
				/>
			</Grid>
		);
	}

	render() {
		const {	systems, globalSettings, model } = this.props;
		const { publisher } = model;
		const { websites } = publisher;
		const publisherNode = new PublisherNode(publisher, ({ ...systems, globalSettings }));
		const { programmaticAccess } = publisher;
		window.customFrontendListeners?.onPubRender({ pubForm: this, publisherNode, classes });
		return (
			<PrebidConfigEdit.PrebidConfigOpenListener.Provider
				value={Object.assign(() => this.updateDirtyBidParams(), {
					dirtyBidParamSet: this.dirtyBidParamSet,
				})}
			>
				<Grid container spacing={3}>
					{this.renderPublisherForm(publisherNode)}
					{programmaticAccess ? (
						<Grid item xs={12}>
							{this.renderWebsites(publisherNode, websites)}
						</Grid>
					) : null}
					{this.renderFormButtons()}
				</Grid>
			</PrebidConfigEdit.PrebidConfigOpenListener.Provider>
		);
	}

	componentDidMount() {
		// Scroll to placement/website if goToSiteId or goToPlacementId is set
		const self = this;
		setTimeout(() => {
			(self.goToPlacementRef ?? self.goToSiteRef)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, 1000);
	}
}

SinglePublisherForm.propTypes = {
	model: PropTypes.shape({
		publisher: PropTypes.object.isRequired,
	}).isRequired,
	SSPs: PropTypes.array.isRequired,
	systems: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	docTemplates: PropTypes.array.isRequired,
	globalSettings: PropTypes.object.isRequired,
	placementLabels: PropTypes.arrayOf(PropTypes.string),
	goToSiteId: PropTypes.string,
	goToPlacementId: PropTypes.string,
};

SinglePublisherForm.defaultProps = {
	placementLabels: [],
};

export default SinglePublisherForm;
