import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import { Redirect, withRouter } from 'react-router-dom';
import {
	Ssp,
	Adserver,
	AnalyticsSystems,
	UserIdModule,
	DocumentTemplate,
	GlobalSettingsObject,
	Publisher,
} from '../../api/relevant';
import SystemData from '../../lib/systemData';
import storeContainer from '../storeContainer';
import Spinner from '../../components/Spinner';
import SinglePublisherForm from '../../components/SinglePublisherForm';
import FormCollection from '../../lib/formCollection';
import { CommentContextWrapper } from '../../components/CommentsPopup/CommentContext';
import MiscUtils from '../../lib/miscUtils';
import { QueryInvalidate } from '../../components/Wrappers';
import { Dialog } from '../../components/Dialog';
// import styles from './styles.css';

@storeContainer()
class PublisherEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			model: null,
			isSubmitting: false,
			submitted: false,
			error: null,
			SSPs: null,
		};
		this.forms = new FormCollection();
	}

	componentDidMount() {
		const { id } = this.props;
		if (id === 'add') {
			this.setEmpty();
		} else {
			this.setModel();
		}
	}

	componentDidUpdate(prevProps) {
		const { id } = this.props;
		if (id === 'add' && prevProps.id !== 'add') {
			this.setState({ model: null }, () => this.setEmpty());
		}
	}

	componentWillUnmount() {
		this.setState({ isSubmitting: false });
	}

	async setEmpty() {
		await this.loadObjects();
		this.setState({
			model: {
				publisher: new SystemData.models.Publisher({
					name: '',
					notes: '',
					logo: '',
					dateformat: 'DD-MM-YYYY',
					audienceAccess: false,
					userReportOptions: {},
					programmaticAccess: !!SystemData.genericData.disableAudience,
					openRTBContractualCut: 0,
					dealsContractualCut: 0,
					directContractualCut: 0,
					raContractualCut: 0,
					cxDmpSitegroupId: '',
					cxDmpSites: [],
					websites: [],
					placementTypes: [],
				}),
			},
		});
	}

	async setModel() {
		const { id, publishers } = this.props;
		await this.loadObjects(id);
		if (!publishers.isReady()) {
			try {
				await publishers.loadAll();
			} catch (e) {
				this.handleSubmitError(e);
			}
		}
		this.setState({ model: { publisher: publishers.byId(id) } });
	}

	async loadObjects(publisherId) {
		const [
			ssps,
			adservers,
			analyticsSystems,
			userIdModules,
			docTemplates,
			globalSettings,
			placementLabels,
		] = await Promise.all([
			Ssp.call('getAll', { publisherId }),
			Adserver.call('getAll', { publisherId }),
			AnalyticsSystems.call('getAll', { publisherId }),
			UserIdModule.call('getAll', { publisherId }),
			DocumentTemplate.list({ sortby: 'name' }),
			GlobalSettingsObject.listOne(),
			Publisher.call('getExistingPlacementLabels'),
		]);
		const SSPs = [
			{ label: 'Add SSP', value: 'new', disabled: true },
			...MiscUtils.alphaSorted(ssps.map((ssp) => ({
				label: `${ssp.name} (${ssp.friendlyTypeName})`,
				value: ssp.id,
				type: ssp.type,
			})), 'label'),
		];

		this.setState({
			SSPs,
			systems: {
				ssps, adservers, analyticsSystems, userIdModules,
			},
			docTemplates,
			globalSettings,
			placementLabels,
		});
	}

	goBack() {
		this.props.history.push('/accounts');
	}

	handleSubmitError(e) {
		let error = e;
		if (e.error && e.error.message) {
			error = e.error.message;
		} else if (e.error && _.isString(e.error)) {
			error = e.error;
		} else if (e.error && e.error.errors && _.isString(e.error.errors)) {
			error = e.error.errors;
		} else {
			error = 'An error has occured.';
		}
		this.setState({ error });
	}

	async submitModel(model) {
		this.setState({ isSubmitting: true, error: null });
		const { publisher } = model;
		const { publishers } = this.props;
		try {
			if (publisher.isNew) {
				await publishers.add(publisher);
				await publishers.loadAll();
			} else {
				await publishers.edit(publisher);
			}
			this.setState({ isSubmitting: false, submitted: true });
		} catch (e) {
			this.handleSubmitError(e);
		}
	}

	render() {
		const {
			model, SSPs, systems, docTemplates, globalSettings, isSubmitting, error,
		} = this.state;
		const { history, goToSiteId, goToPlacementId } = this.props;
		if (!model || !SSPs) return <Spinner delay />;
		return (
			<CommentContextWrapper context={model.publisher.id}>
				<SinglePublisherForm
					model={model}
					formCollection={this.forms}
					onSubmit={(newModel) => this.submitModel(newModel)}
					SSPs={SSPs}
					systems={systems}
					history={history}
					docTemplates={docTemplates}
					globalSettings={globalSettings}
					placementLabels={this.state.placementLabels}
					goToSiteId={goToSiteId}
					goToPlacementId={goToPlacementId}
				/>
				{this.state.submitted && (
					<>
						<QueryInvalidate query="publishers" />
						<Redirect to="/accounts" push />
					</>
				)}
				<Dialog
					open={isSubmitting && error}
					text={error}
					status="error"
					onClose={() => this.setState({ isSubmitting: false })}
				/>
			</CommentContextWrapper>
		);
	}
}

PublisherEditor.propTypes = {
	id: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
	publishers: PropTypes.object,
	goToSiteId: PropTypes.string,
	goToPlacementId: PropTypes.string,
};

PublisherEditor.defaultProps = {
	publishers: {},
};

export default withRouter(PublisherEditor);
