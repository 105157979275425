import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Publisher } from "../../api/relevant";

export function usePublishersQuery() {
	const queryClient = useQueryClient();
	return useQuery<Publisher[]>({ 
		queryKey: ['publishers'],
		queryFn: async () => {
			return Publisher.list();
		}
	});
}