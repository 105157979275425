import { useContext, useEffect, useState } from 'react';
import { CommentContext } from '../components/CommentsPopup/CommentContext';

export default function useComments(connectedTo) {
	const {
		comments: allComments,
		loading,
		error,
		addComment,
		deleteComment,
		dismissErrors,
	} = useContext(CommentContext);
	const [comments, setComments] = useState([]);

	useEffect(() => {
		const commentsForConnectedToId = allComments.filter((comment) => comment.connectedTo === connectedTo);
		setComments(commentsForConnectedToId);
	}, [allComments, connectedTo]);

	return {
		comments, loading, error, addComment, deleteComment, dismissErrors,
	};
}
