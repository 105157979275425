export default function filterItems(reports, sharingState) {
	const filtered = reports.filter(({ userId }) => {
		if (sharingState === 'public') {
			return !userId;
		} if (sharingState === 'private') {
			return Boolean(userId);
		}
		return true;
	});
	return [filtered, sharingState !== 'all'];
}
