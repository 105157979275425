import PropTypes from 'prop-types';
import React, {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	getAvailableReportTypes,
	getAvailableReports,
} from './utils';

export const ReportsContext = createContext({});

export default function ReportsProvider({ children, productType }) {
	const [reports, setReports] = useState(null);

	// Not sure if there might be some unexpected stuff if not doing this on every render given how SystemData works...?
	// hence the rule disable
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const availableReportTypes = getAvailableReportTypes();

	// Fetch and update reports
	const updateReports = useCallback(async () => {
		const fetchedReports = await getAvailableReports(productType);
		setReports(fetchedReports);
	}, [productType]);

	useEffect(() => {
		updateReports();
	}, [updateReports]);

	const providerValue = useMemo(() => ({
		reports,
		updateReports,
		availableReportTypes,
	}), [availableReportTypes, reports, updateReports]);

	return (
		<ReportsContext.Provider value={providerValue}>
			{ children }
		</ReportsContext.Provider>
	);
}

ReportsProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	productType: PropTypes.string.isRequired,
};
