import {
	useEffect, useMemo,
} from 'react';
import useTopBar from '../../../hooks/useTopbar';
import classes from '../../../api/classes';
import { filterUserAvailableTemplates } from '../../../components/ReportTemplates/utils';
import { stores } from '../../../stores';
const { ReportTemplate } = classes;

export function useDashboardTopBar(type, addWidget) {
	const { setSettings } = useTopBar();

	const availableWidgets = useMemo(async () => {
		if (type === 'audience') {
			// Deprecated audience templates
			return [];
		} else {
			let templates = await ReportTemplate.fnCache.listAvailable({ isDashboardTemplate: true });
			const debugUnavailability = stores.identity.isGhosting();
			templates = filterUserAvailableTemplates(templates, { debugUnavailability });
			return templates.map((t) => ({ ...t, label: t.settings.title, value: t.id }));
		}
	}, [type]);

	useEffect(() => {
		(async () => {
			setSettings({
				type: 'dashboard',
				availableWidgets: await availableWidgets,
				onSelect: (d) => addWidget(d),
			});
		})();
	}, [addWidget, setSettings, availableWidgets]);
}
