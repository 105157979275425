/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import {
	Grid,
} from '@mui/material';
import { isDbIdType } from 'relevant-shared/objects/objectStore';
import { MaxByObjectNesting } from 'relevant-shared/objects/tagFieldBase';
import FieldArray from './fieldArray';
import Select from '../Select';
import { ContextWrapper } from '../Wrappers';
import Checkbox from '../Checkbox';
import {
	selectOptionsProps, typeToDescription, typeOptions,
} from './utils';
import ObjectData from './objectData';

const renderData = (p) => (
	<ObjectData
		{...p}
		title={`Per ${typeToDescription(p.byObjectType)}`}
	/>
);

const renderEdit = (p) => (
	<Grid container spacing={3}>
		<Grid item xs={3}>
			<ContextWrapper
				name="fieldEditor"
				content={({ prev }) => (
					<Select
						{...selectOptionsProps('byObjectType', p, prev)}
						onFieldUpdate={() => prev[0]?.cleanupInvalid()}
						label="Object type"
						fullWidth
					/>
				)}
			/>
		</Grid>
		{isDbIdType(p.model.byObjectType) && (
			<Grid item>
				<Checkbox
					label="Include objects not present in file"
					textFieldAlign
					{...p.field('includeNonPresent')}
				/>
			</Grid>
		)}
		<Grid item xs={12}>
			<FieldArray model={p.model} field={p.field} inArray={!!p.inArray} />
		</Grid>
	</Grid>
);

const ByObjectType = {
	defaultValue: {},
	isContainer: true,
	canInnerDisable: true,
	render: renderEdit,
	renderData,
	renderSummary: () => null,
	typeForbidden: (prevFields) => {
		const { length } = _.filter(prevFields, { type: 'ByObject' });
		// At most 3 nested "ByObject" and there must still be some type available,
		// as they can only be added once per object type.
		return length >= MaxByObjectNesting || length >= typeOptions().byObjectType.length;
	},
};

export default ByObjectType;
