import { clsx } from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Link } from './Link/Link';
import autoAnimate from '@formkit/auto-animate';
import { More, MoreVert } from '@mui/icons-material';
import { Icon, IconButton, Menu, MenuList } from '@mui/material';
import { Button } from './Button/Button';

export type TableRowType = {
	id: string;
	cols: (string | React.ReactNode)[];
	to?: string;
	href?: string;
};

type TableProps = {
	headers: string[];
	rows: TableRowType[] | undefined;
	// Style per column. Use this to set column width, text alignment, etc.
	columnClass?: string[];
	actions?: (row) => React.ReactNode;
	skeletonRows?: number;
	menu?: React.ReactNode;
};

export function SimpleTable({ headers, rows, columnClass, actions, skeletonRows, menu }: TableProps) {
	const [animationParent] = useAutoAnimate();
	const [menuOpen, setMenuOpen] = useState(false);
	const menuAnchor = useRef<HTMLButtonElement>(null);

	return (
		<>
			{/* Header */}
			<div className="relative">
				<div className="flex py-3 px-6 justify-between items-center border rounded-t-md border-grey-200 bg-white">
					{headers.map((header, i) => (
						<span key={i} className={clsx(
							"w-96 text-sm font-medium text-grey-600",
							columnClass?.[i]
						)}>
							{header}
						</span>
					))}
				</div>
				<div className="absolute right-0 top-0 bottom-0 h-full flex items-center mr-2">
					{menu && (
						<>
							<IconButton ref={menuAnchor} size="small" onClick={(e) => { setMenuOpen(true); }}>
								<MoreVert />
							</IconButton>
							<Menu open={menuOpen} onClose={() => { setMenuOpen(false); }} anchorEl={menuAnchor.current}>
								<MenuList>
									{menu}
								</MenuList>
							</Menu>
						</>
					)}
				</div>
			</div>
			{/* Rows */}
			<div ref={animationParent}>
				{rows ? (
					<>
						{/* Columns */}
						{rows.map((row, i) => (
							// TODO: clickable = a, not clickable = div
							<TableRow key={row.id} row={row} headers={headers} columnClass={columnClass} actions={actions} />
						))}
						{/* No rows */}
						{rows.length === 0 && (
							<div className="flex py-4 px-6 justify-center border border-t-0 last:rounded-b-md border-grey-200 bg-white">
								<span className="text-center p-8 text-grey-300 select-none">No publishers found</span>
							</div>
						)}
					</>
				) : (
					<>
						{/* Skeleton while loading */}
						{Array.from({ length: skeletonRows }).map((_, i) => (
							<TableRow key={i.toString()} row={{ id: i.toString() }} headers={headers} columnClass={columnClass} />
						))}
					</>
				)}
			</div>
		</>
	);
}

function TableRow({ row, headers, columnClass, actions }: Pick<TableProps, 'headers' | 'columnClass' | 'actions'> & { row: Partial<TableProps['rows'][0]> }) {
	const inner = (
		<>
			{/* Columns */}
			<div className="flex py-4 px-6 items-center justify-between text-grey-600">
				{row.cols ? (
					row.cols.map((col, j) => (
						<span
							key={j}
							className={clsx(
								'w-96 overflow-hidden whitespace-nowrap text-ellipsis text-sm relative',
								'first:group-hover:font-medium', // TODO: Find an alternative to this as changing the font weight on hover looks bad b/c it makes the text shift
								columnClass?.[j],
							)}
						>
							{col ?? <span className="text-grey-300">—</span>}
						</span>
					))
				) : (
					<>
						{/* Skeleton while loading */}
						{headers.map((_, j) => (
							<span
								key={j}
								className={clsx(
									'w-96 overflow-hidden whitespace-nowrap text-ellipsis text-sm relative',
									columnClass?.[j],
								)}
							>
								<div className="animate-pulse bg-grey-200 h-4 w-1/2" />
							</span>
						))}
					</>
				)}
			</div>
			{/* Actions */}
			{actions && (
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
				<div
					className={clsx(
						'absolute pr-2 right-0 top-0 bottom-0 hidden group-hover:flex items-center justify-end gap-2',
						'pl-16 bg-gradient-to-r from-transparent from-0% to-30% to-neutral-highlight', // Fade out background
					)}
					onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} // Block click from propagating to the row
				>
					{actions(row)}
				</div>
			)}
		</>
	);

	return (
		<div
			className={clsx(
				'block group relative border border-t-0 last:rounded-b-md border-grey-200 bg-white active:bg-neutral-highlight hover:bg-neutral-highlight focus:bg-neutral-highlight',
			)}
		>
			{(row.to ?? row.href) ? (
				<Link to={row.to} href={row.href} className="block" unstyled>
					{inner}
				</Link>
			) : (
				inner
			)}
		</div>
	)
};


// Default props
SimpleTable.defaultProps = {
	skeletonRows: 3,
};