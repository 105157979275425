import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import {
	Dialog,
	Box,
	DialogContent,
	DialogActions,
	Button,

} from '@mui/material';
import styles from './styles.css';
import RadioButtonGroup from '../RadioButtonGroup';
import Select from '../Select';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import DateUtils from '../../lib/dateUtils';

const PREDEFINED_OPTIONS = [
	{
		label: '1 day back',
		value: '-1',
	},
	{
		label: '7 days back',
		value: '-7',
	},
	{
		label: '14 days back',
		value: '-14',
	},
	{
		label: '30 days back',
		value: '-30',
	},
	{
		label: 'Select days back',
		value: 'custom',
	},
];

const PREDEFINED_AHEAD_OPTIONS = [
	{
		label: '1 day ahead',
		value: '1',
	},
	{
		label: '7 days ahead',
		value: '7',
	},
	{
		label: '14 days ahead',
		value: '14',
	},
	{
		label: '30 days ahead',
		value: '30',
	},
	{
		label: 'Select days ahead',
		value: 'custom',
	},
];

class DateOrDaysPicker extends React.Component {
	constructor(props) {
		super(props);
		const { allowZeroDaysBack } = props;
		this.state = {
			mode: 'exact',
			showSettings: false,
			selectedDaysBack: allowZeroDaysBack ? 0 : -1,
			customDaysBack: 1,
			hovering: false,
		};
		this.select = this.select.bind(this);
		this.onDismiss = this.onDismiss.bind(this);
		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
	}

	componentDidMount() {
		this.adjustState(this.props);
	}

	componentDidUpdate(prevProps) {
		// TODO: This is very brittle due to the original design of the component
		const { allowZeroDaysBack, canSelectAhead, value } = this.props;
		if (value !== prevProps.value
			|| canSelectAhead !== prevProps.canSelectAhead
			|| allowZeroDaysBack !== prevProps.allowZeroDaysBack) {
			this.adjustState(this.props);
		}
	}

	onMouseEnter() {
		this.setState({ hovering: true });
	}

	onMouseLeave() {
		this.setState({ hovering: false });
	}

	onDismiss() {
		this.setState({ showSettings: false });
		this.adjustState(this.props);
	}

	isDynamicAheadMode() {
		const { value, canSelectAhead } = this.props;
		return canSelectAhead && DateUtils.isRelativeDate(value) && parseInt(value, 10) >= 0;
	}

	predefinedOptions(settings) {
		const { allowZeroDaysBack } = this.props;
		const { ahead } = this.state;
		let options = (ahead || settings?.ahead) ? PREDEFINED_AHEAD_OPTIONS : PREDEFINED_OPTIONS;
		if (allowZeroDaysBack) {
			options = [{ label: 'Today', value: '0' }, ...options];
		}
		return options;
	}

	adjustState(props) {
		if (DateUtils.isRelativeDate(props.value)) {
			const ahead = this.isDynamicAheadMode();
			const custom = !_.find(this.predefinedOptions({ ahead }), (o) => o.value === props.value.toString());
			if (custom) {
				this.setState({
					mode: 'dynamic',
					ahead,
					customDaysBack: Math.abs(props.value).toString(),
					selectedDaysBack: 'custom',
				});
			} else {
				this.setState({
					mode: 'dynamic',
					ahead,
					selectedDaysBack: props.value.toString(),
				});
			}
		} else {
			this.setState({ mode: 'exact' });
		}
	}

	select(d) {
		const date = d ? new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())).getTime() : DateUtils.today();
		const { onSelect, name } = this.props;
		const {
			mode, ahead, selectedDaysBack, customDaysBack,
		} = this.state;
		if (mode === 'exact') {
			onSelect({ target: { name, value: date } });
		} else if (mode === 'dynamic') {
			onSelect({
				target: {
					name,
					value: Math.abs(parseInt(selectedDaysBack === 'custom' ? customDaysBack : selectedDaysBack, 10)) * (ahead ? 1 : -1),
				},
			});
		}
		this.setState({ showSettings: false });
	}

	renderDynamicDateOptions() {
		const { selectedDaysBack } = this.state;
		const updateState = (value) => this.setState(
			{ selectedDaysBack: value },
			() => {
				if (value !== 'custom') {
					this.select();
				}
			},
		);
		return (
			<Select
				name="Select"
				label="Select"
				items={this.predefinedOptions().map((obj) => ({
					...obj,
					label: (
						<Box
							style={{ width: '100%' }}
							onClick={(ev) => {
								// Handling click-events here is a solution to the problem where we're not getting
								// any events for Select.onChange when clicking the already selected value. We still
								// keep the onChange event handler in <Select> below as we won't capture clicks in
								// the padding of the container element here (when clicking near border of option)
								updateState(obj.value);
								ev.stopPropagation();
							}}
						>
							{obj.label}
						</Box>
					),
				}))}
				value={selectedDaysBack.toString()}
				onChange={(ev) => updateState(ev.target.value)}
				fullWidth
				margin="normal"
			/>
		);
	}

	renderCustomField() {
		const { ahead } = this.state;
		const minVal = ahead ? 0 : 1;
		const label = `Number of days ${ahead ? 'ahead' : 'back'}`;
		return (
			<TextField
				name={label}
				label={label}
				value={this.state.customDaysBack}
				onChange={(ev) => this.setState({ customDaysBack: !isNaN(parseInt(ev.target.value, 10)) && ev.target.value >= minVal ? ev.target.value : minVal })}
				type="number"
				fullWidth
				margin="normal"
			/>
		);
	}

	renderOkButton() {
		return (
			<ActionButton
				label="Ok"
				onClick={() => this.select()}
			/>
		);
	}

	render() {
		const {
			name,
			label,
			value,
			autoOk,
			minDate,
			maxDate: originalMaxDate,
			className,
			canSelectAhead,
			canSelectBehind,
			textFieldProps,
			allowZeroDaysBack,
			disabled,
			now: nowDate,
		} = this.props;
		const {
			mode, selectedDaysBack, customDaysBack, showSettings, hovering, ahead,
		} = this.state;
		const daysBackToLastData = allowZeroDaysBack ? 0 : 1;
		const modes = {
			exact: 'Exact date',
			...(canSelectBehind ? { dynamic: 'Days back' } : null),
			...(canSelectAhead ? { dynamicAhead: 'Days ahead' } : null),
		};
		const now = new Date(nowDate || new Date());
		const maxDate = originalMaxDate != null ? DateUtils.toDate(originalMaxDate, now) : undefined;
		// Don't include today if false
		if (maxDate && DateUtils.isToday(maxDate, now) && !allowZeroDaysBack && !canSelectAhead) {
			maxDate.setDate(maxDate.getDate() - 1);
		}
		const toLocalSameDate = (date) => {
			const d = DateUtils.fullDay(date);
			return moment(new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
		};
		return (
			<>
				<Dialog
					open={showSettings}
					onClose={this.onDismiss}
					maxWidth="xs"
					fullWidth
				>
					<DialogContent dividers>
						<Box display="flex" flexDirection="column" alignItems="flex-start">
							<RadioButtonGroup
								name="Dynamic date range"
								items={_.map(modes, (v, k) => ({ name: v, value: k }))}
								selected={ahead ? 'dynamicAhead' : mode}
								defaultValue="exact"
								onChange={(ev) => {
									const options = ev.target.value === 'dynamicAhead'
										? { mode: 'dynamic', ahead: true }
										: { mode: ev.target.value, ahead: false };
									if (options.mode === 'dynamic' && (selectedDaysBack < 0) !== !options.ahead) {
										options.selectedDaysBack = options.ahead ? 1 : -daysBackToLastData;
									}
									options.customDaysBack = Math.max(options.ahead ? 0 : daysBackToLastData, customDaysBack);
									this.setState(options);
								}}
							/>
							{mode === 'dynamic' ? this.renderDynamicDateOptions() : null}
							{mode === 'dynamic' && selectedDaysBack === 'custom' ? this.renderCustomField() : null}
							{mode === 'exact' && (
								<Box alignSelf="center">
									<StaticDatePicker
										orientation="portrait"
										openTo="day"
										onChange={(date) => {
											this.select(date.toDate());
										}}
										value={toLocalSameDate(DateUtils.toDate(value, now))}
										autoOk={autoOk}
										minDate={toLocalSameDate(minDate || '1970-01-01')}
										maxDate={maxDate ? toLocalSameDate(maxDate) : undefined}
										renderInput={() => (
										// We don't allow user to edit date with keyboard
										// (for validation issues after updating to this new MUI datepicker)
										// However, would be great if we could in the future.
											null
										)}
									/>
								</Box>
							)}
						</Box>
					</DialogContent>
					<DialogActions>
						{mode === 'dynamic' && selectedDaysBack === 'custom' ? this.renderOkButton() : null}
						<Button onClick={this.onDismiss}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
				<TextField
					className={`${styles.input} ${className}`}
					name={name}
					label={_.isString(label) ? label : name}
					value={moment.utc(DateUtils.toDate(value, now)).format('YYYY-MM-DD')}
					onClick={disabled ? null : () => this.setState({ showSettings: true })}
					onChange={() => {}}
					underlineShow={false}
					onMouseEnter={this.onMouseEnter}
					onMouseLeave={this.onMouseLeave}
					disabled={disabled}
					{...textFieldProps}
					{...(hovering ? { color: 'secondary' } : null)}
				/>
			</>
		);
	}
}

DateOrDaysPicker.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]).isRequired,
	autoOk: PropTypes.bool,
	minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
	maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
	now: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
	onSelect: PropTypes.func,
	label: PropTypes.string,
	className: PropTypes.string,
	canSelectAhead: PropTypes.bool,
	canSelectBehind: PropTypes.bool,
	textFieldProps: PropTypes.object,
	allowZeroDaysBack: PropTypes.bool,
	disabled: PropTypes.bool,
};

DateOrDaysPicker.defaultProps = {
	autoOk: false,
	minDate: undefined,
	maxDate: undefined,
	onSelect: () => {},
	label: null,
	className: undefined,
	canSelectAhead: false,
	canSelectBehind: true,
	textFieldProps: null,
	allowZeroDaysBack: false,
	disabled: false,
};

function DateOrDaysPickerWrapper(p) {
	return <DateOrDaysPicker {...p} key={`internal_date_${JSON.stringify(p)}`} />;
}

export default DateOrDaysPickerWrapper;
