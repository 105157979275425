import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FieldArray from '../FieldArray';
import SystemData from '../../lib/systemData';
import DatePicker from '../DatePicker';
import DateUtils from '../../lib/dateUtils';
import { withUpdate } from '../Wrappers';
import Select from '../Select';
import TextField from '../TextField';
import CheckboxGroup from '../CheckboxGroup';
import PopupSelector from '../PopupSelector';
import { Ssp } from '../../api/relevant';

@withUpdate
class TechFeeSetup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { model, field, form } = this.props;
		const { CURRENCIES } = SystemData.genericData;
		return (
			<FieldArray
				model={model}
				field={field}
				name="periods"
				update={() => this.update()}
				noSwapBtns
				expandableElements={model.periods.length > 1}
				form={form}
				addBtnText="Add new period"
				deleteBtnText="Delete period"
				afterHeader={(__, elm, idx, arr) => {
					const { fees, startMonth } = elm;
					const endDate = idx < (arr.length - 1) ? DateUtils.lastDayOfMonthChange(arr[idx + 1].startMonth, -1) : null;
					const startStr = startMonth ? moment.utc(startMonth).format('YYYY-MM-DD') : null;
					const endStr = endDate ? moment.utc(endDate).format('YYYY-MM-DD') : null;
					let str;
					if (startStr && endStr) {
						str = `${startStr} to ${endStr}`;
					} else if (startStr) {
						str = `From ${startStr}`;
					} else if (endStr) {
						str = `Until ${endStr}`;
					} else {
						str = 'Always';
					}
					return (
						<Box component="em" color="grey.600">
							{`${str} (${fees.length} fees)`}
						</Box>
					);
				}}
				createNew={() => {
					if (form.hasErrors()) {
						throw Error('Please correct errors first');
					}
					const lastPeriod = _.last(model.periods);
					const res = new SystemData.models.TechFeePeriod(lastPeriod, !!lastPeriod);
					let nextFree = DateUtils.firstDayOfMonthChange(undefined, 1);
					if (lastPeriod && lastPeriod.startMonth) {
						const prevNextFree = DateUtils.firstDayOfMonthChange(lastPeriod.startMonth, 1);
						nextFree = new Date(Math.max(prevNextFree, nextFree));
					}
					res.startMonth = lastPeriod ? nextFree : null;
					return res;
				}}
				render={(outerFld, elm, idx, arr) => {
					let minDate;
					let maxDate;
					if (idx && arr[idx - 1].startMonth) {
						minDate = DateUtils.firstDayOfMonthChange(arr[idx - 1].startMonth, 1);
					}
					if (arr[idx + 1]) {
						maxDate = DateUtils.lastDayOfMonthChange(arr[idx + 1].startMonth, -1);
					}
					return (
						<div>
							<DatePicker
								floatingLabelText="Start month"
								minDate={minDate}
								maxDate={maxDate}
								name="startMonth"
								value={elm.startMonth}
								autoOk
								canClear={!idx}
								onChange={(ev) => {
									const { value } = ev.target;
									elm.startMonth = value ? DateUtils.firstDayOfMonth(ev.target.value) : null;
									this.update();
								}}
								fullWidth
								margin="normal"
							/>
							<FieldArray
								model={elm}
								field={outerFld}
								name="fees"
								update={() => this.update()}
								noSwapBtns
								addBtnText="Add new fee"
								deleteBtnText="Delete fee"
								deleteBtnColor="secondary"
								createNew={() => new SystemData.models.TechFee()}
								render={(fld, fee) => (
									<Grid container spacing={3} alignItems="center">
										<Grid item xs={4}>
											<TextField
												{...fld('description')}
												label="Description"
												required
												fullWidth
												margin="normal"
											/>
										</Grid>
										<Grid item xs={4}>
											<Select
												label="Fee type"
												{...fld('feeType')}
												items={[
													{ label: 'Percentage', value: 'percentage' },
													{ label: 'Fixed', value: 'fixed' },
												]}
												fullWidth
												margin="normal"
											/>
										</Grid>
										<Grid item xs={4}>
											<PopupSelector
												fn={() => Ssp.list()}
												title={`Limit to SSPs (${fee.ssps.length ? fee.ssps.length : 'all'})`}
												containerStyle={{ marginTop: 45 }}
												content={(__, ssps) => (
													<CheckboxGroup
														items={ssps.map((ssp) => ({ id: ssp.id, text: ssp.name }))}
														selected={fee.ssps}
														onChange={(items) => this.update(() => { fee.ssps = items; })}
													/>
												)}
											/>
										</Grid>
										<Grid item xs={4}>
											{fee.feeType === 'percentage' && (
												<TextField
													{...fld('percentage')}
													between={{ low: 0, high: 100 }}
													float
													label="Percentage"
													required
													fullWidth
													margin="normal"
												/>
											)}
											{fee.feeType === 'fixed' && (
												<TextField
													{...fld('fixedAmount')}
													between={{ low: 0 }}
													float
													label="Fixed monthly cost"
													required
													fullWidth
													margin="normal"
												/>
											)}
										</Grid>
										<Grid item xs={fee.feeType === 'fixed' ? 4 : 8}>
											<Select
												label="Apply when"
												{...fld('applyWhen')}
												items={[
													{ label: 'Before Revenue Correction', value: 'beforeRevCorrect' },
													{ label: 'After Revenue Correction', value: 'afterRevCorrect' },
												]}
												fullWidth
												margin="normal"
											/>
										</Grid>
										{fee.feeType === 'fixed' && (
											<Grid item xs={4}>
												<Select
													label="Currency"
													{...fld('currency')}
													items={_.map(CURRENCIES, (v, k) => ({ label: `${v.name} (${k})`, value: k }))}
													fullWidth
													margin="normal"
												/>
											</Grid>
										)}
									</Grid>
								)}
							/>
						</div>
					);
				}}
			/>

		);
	}
}

TechFeeSetup.propTypes = {
	model: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
};

TechFeeSetup.defaultProps = {
};

export default TechFeeSetup;
