/* eslint-disable import/prefer-default-export */
const locales = 'en-EN';

const float = new Intl.NumberFormat(locales, {
	style: 'decimal',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

const int = new Intl.NumberFormat(locales, {
	style: 'decimal',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const fmtNum = (number) => {
	if (number === 0) {
		return 0;
	}
	if (Math.round(number) === number) {
		return int.format(number);
	}
	return float.format(number);
};

export const fmtNumCsv = (number) => number.toFixed(2);
