import React, { useState, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
	List,
	FormControl,
	InputLabel,
	OutlinedInput,
	ListItemText,
	ListItem,
	IconButton,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import SimpleDialog from '../SimpleDialog';

const PREFIX = 'index';

const classes = {
	inputAdornment: `${PREFIX}-inputAdornment`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
	[`& .${classes.inputAdornment}`]: {
		opacity: 0.54, // Same as MUI default for similar adornments
	},
}));

/**
* A variant of normal <Select>, but displaying a popup instead of a simple dropdown
* TODO: Would be good to have this component allow "simple" options/selectedOption in the future,
* i.e strings only instead of objects with { value, label }
*/
export default function PopupSelect({
	selectedOption,
	options,
	label,
	onChange,
	createable,
	clearable,
	renderAdd,
	renderOption,
	addButtonProps,
	dialogTitle,
	onAddItem,
	dialogProps,
	disabled,
}) {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [inputIsFocused, setInputIsFocused] = useState(false);
	const [displayClearInputButton, setDisplayClearInputButton] = useState(false);

	const handleInputClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!disabled) {
			setInputIsFocused(true);
			setModalIsOpen(true);
		}
	};

	const onSelectItem = (option) => {
		onChange(option);
		setModalIsOpen(false);
		setInputIsFocused(false);
	};

	const onAddClick = () => {
		onAddItem();
	};

	let createEl = null;
	if (createable) {
		if (renderAdd) {
			createEl = renderAdd({ onClick: onAddClick, ...addButtonProps });
		} else {
			const { title, ...restProps } = addButtonProps;
			createEl = (
				<ListItem button onClick={onAddClick} {...restProps}>
					<AddIcon />
					<ListItemText>{title || 'Add a new item'}</ListItemText>
				</ListItem>
			);
		}
	}

	const selectedOptionLabel = selectedOption && selectedOption.label;
	let labelBugFix = {};
	if (selectedOptionLabel || (!selectedOptionLabel && modalIsOpen)) {
		// Fixes https://github.com/mui-org/material-ui/issues/14530
		labelBugFix = {
			backgroundColor: 'white',
			zIndex: 1000,
			paddingLeft: 8,
			paddingRight: 8,
		};
	}

	return (
		<Root>
			<FormControl focused={inputIsFocused}>
				<InputLabel
					style={labelBugFix}
					variant="outlined"
				>
					{label}
				</InputLabel>
				<OutlinedInput
					disabled={disabled}
					inputProps={{ style: { cursor: disabled ? 'default' : 'pointer' } }}
					value={(selectedOptionLabel) || ''}
					onClick={handleInputClick}
					onMouseLeave={() => setDisplayClearInputButton(false)}
					onMouseOver={() => {
						if (!disabled) {
							setDisplayClearInputButton(true);
						}
					}}
					readOnly
					endAdornment={(
						<>
							{ clearable && (
								<IconButton
									size="small"
									style={{ visibility: (selectedOptionLabel && displayClearInputButton) ? 'visible' : 'hidden' }}
									onClick={(e) => {
										onChange(null);
										e.stopPropagation();
									}}
								>
									<ClearIcon
										className={classes.inputAdornment}
									/>
								</IconButton>
							)}
							<IconButton size="small" disabled={disabled}>
								<ArrowDropDownIcon className={classes.inputAdornment} />
							</IconButton>
						</>
					)}
				/>
			</FormControl>
			<SimpleDialog
				open={modalIsOpen}
				onClose={() => {
					setModalIsOpen(false);
					setInputIsFocused(false);
				}}
				title={dialogTitle}
				dialogProps={dialogProps}
			>
				<List style={{ maxHeight: 600 }}>
					{ options.map((option) => (
						<Fragment key={option.value}>
							{ renderOption ? renderOption(option, { onClick: () => onSelectItem(option) })
								:							(
									<ListItem
										button
										value={option.value}
										onClick={() => onSelectItem(option)}
									>
										<ListItemText
											primary={option.label}
										/>
									</ListItem>
								)}
						</Fragment>
					))}
					{ createEl }
				</List>
			</SimpleDialog>
		</Root>
	);
}

PopupSelect.propTypes = {
	selectedOption: PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
	}),
	options: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	})).isRequired,
	label: PropTypes.string.isRequired,
	createable: PropTypes.bool,
	clearable: PropTypes.bool,
	addButtonProps: PropTypes.objectOf(PropTypes.any),
	dialogTitle: PropTypes.string.isRequired,
	dialogProps: PropTypes.objectOf(PropTypes.any),
	onChange: PropTypes.func,
	renderOption: PropTypes.func,
	renderAdd: PropTypes.func,
	onAddItem: PropTypes.func,
	disabled: PropTypes.bool,
};

PopupSelect.defaultProps = {
	selectedOption: null,
	onChange: () => undefined,
	onAddItem: () => undefined,
	renderOption: undefined,
	renderAdd: undefined,
	createable: false,
	clearable: false,
	addButtonProps: {},
	disabled: false,
};
