const Node = require('./node');
const EnvReportInterface = require('../../reportData/envReportInterface');

class PrebidConfigOwnerNode extends Node {
	constructor(cfg, ...rest) {
		super(cfg, ...rest);
		this.pbConfigNodes = [];
		if (!this.isChildConfig && cfg.prebidConfigs) {
			this.pbConfigNodes = cfg.prebidConfigs.map((child) => new Node.Types.PrebidConfigNode(child, this));
		}
	}

	createPrebidConfig(...settings) {
		const types = EnvReportInterface.typeMap();
		const newTypeName = this.type === 'PrebidConfigNode' ? 'PrebidConfigChild' : 'PrebidConfig';
		const obj = new types[newTypeName](...settings);
		this.obj.prebidConfigs.push(obj);
		this.pbConfigNodes.push(new Node.Types.PrebidConfigNode(obj, this));
		return obj;
	}
}

module.exports = PrebidConfigOwnerNode;
