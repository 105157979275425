import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import escape from 'escape-html';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/AddCircle';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Constants from 'relevant-shared/reportData/constants';
import AllMappingDimensions from 'relevant-shared/mappingDimensions/allMappingDimension';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './styles.css';
import { Reports } from '../../api/relevant';
import SelectorDropdown from '../SelectorDropdown';
import Select from '../Select';
import { stores } from '../../stores';
import Checkbox from '../Checkbox';
import { ActionButton } from '../ActionButton/ActionButton';
import DeleteButton from '../DeleteButton';
import ExpandSelector from '../ExpandSelector';
import TextField from '../TextField';
import BrowserUtils from '../../lib/browserUtils';
import SystemData from '../../lib/systemData';
import MiscUtils from '../../lib/miscUtils';
import PieChartNotSupportedAlert from './PieChartNotSupportedAlert';
import {
	CompareToSwitch,
	StartEndPicker,
	ForecastToggle,
	ReportTypeSelector,
	CorrectionSelector,
	isForecastAvailable,
} from './utils';
import StandardFilters from './standardFilters';
import TimezoneSelect from '../TimezoneSelect';
import { DynamicDateRangeSelector } from './dynamicDateRangeSelector';
import { Button } from '../Button/Button';

import Switch from '../Switch';

function EmptyIcon() {
	return <div style={{ width: '48px' }} />;
}

const relEnc = (str) => `rel64encStart${btoa(str)}rel64encStop`;

class ReportSettings extends React.Component {
	constructor(props) {
		super(props);
		Object.assign(this, Constants[props.settings.type], this.props.funcs);
		this.state = _.pick(props.settings, props.settingsFilter);
	}

	setSumSelected(sum, value) {
		const { sums } = this.state;
		// Store sums in the order they were clicked
		let newSums = [...sums, sum].filter((s) => (
			Object.keys(this.SUM_DESCRIPTIONS).includes(s)
			&& (s === sum ? value : sums.indexOf(s) >= 0)));
		if (!newSums.length) {
			newSums = _.clone(sums);
		}
		this.setState({ sums: newSums });
	}

	macroSettingsStr(escapeDoubleQuotes) {
		const res = JSON.stringify(_.pick(this.state, Constants.invoiceMacroFields)).replace(/'/g, '\\u0027');
		return escapeDoubleQuotes ? res.replace(/"/g, '&quot;') : res;
	}

	invoiceTableText() {
		const { groupBy, sums } = this.state;
		const { groupByOptions } = this.props;
		const style = (txt) => `<span style="font-family:Trebuchet MS,Helvetica,sans-serif;">${txt}</span>`;
		const colsNeeded = groupBy.length + sums.length;
		const renderRows = (idx) => {
			const isLast = idx === groupBy.length - 1;
			let res = `
				<tr>
					${'<td></td>'.repeat(idx)}
					<td>${style(relEnc(`{{reportLabelOf '${groupBy[idx]}' @key}}`))}</td>
					${isLast ? sums.map((sum) => `<td>${style(`{{toFixed ${sum}}}`)}</td>`).join('\n') : '<td></td>'.repeat(sums.length + (groupBy.length - idx - 1))}
				</tr>`;
			if (!isLast) {
				res += `
					<tr>
						<td colSpan="${colsNeeded}">
							relReplaceTableRow:{{#each this}}
						</td>
					</tr>
						${renderRows(idx + 1)}
					<tr>
						<td colSpan="${colsNeeded}">
							relReplaceTableRow:{{/each}}
						</td>
					</tr>					
				`;
			}
			return res;
		};
		const txt = `
			<table border="1" cellpadding="1" cellspacing="1" style="border-collapse:collapse;width:80%;">
				<colgroup>
					${'<col />'.repeat(groupBy.length)}
					${'<col width="120" />'.repeat(sums.length)}
				</colgroup>
				<thead>
					<tr>
						${groupBy.map((group) => `<th scope="col">${style(groupByOptions[group])}</th>`).join('\\n')}
						${sums.map((sum) => `<th scope="col">${style(this.SUM_DESCRIPTIONS[sum])}</th>`).join('\\n')}
					</tr>
				</thead>
				<tbody>
					<tr>
						<td colspan="${colsNeeded}">
							[Macro start] relReplaceTableRow:{{#reportOf settings='${this.macroSettingsStr(true)}'}}{{#each this}}
						</td>
					</tr>
						${renderRows(0)}
					<tr>
						<td colspan="${colsNeeded}">
							[Macro end] relReplaceTableRow:{{/each}}{{/reportOf}}
						</td>
					</tr>
				</tbody>
			</table>
		`;
		return _.trim(txt);
	}

	/**
 <tr>
	<td>${style(relEnc(`{{reportLabelOf '${groupBy[0]}' @key}}`))}</td>
 	${sums.map(sum => `<td>${style(`{{toFixed ${sum}}}`)}</td>`).join('\n')}
 </tr>
	 */

	async loadPublishers() {
		const pubs = await Reports.call('getPublishers');
		this.setState({ pubs });
	}

	updateSumsAndGroupBy(groupBy, ...args) {
		const sums = this.updateGroupBy(groupBy, this.state.sums);
		this.setState({ groupBy, sums }, ...args);
	}

	renderAttributes(attributeMappings) {
		const { groupBy } = this.state;
		const attributes = this.state.attributes || {};
		const { groupByOptions } = this.props;
		const current = _.pick(attributes || {}, groupBy);
		return (
			<ExpandSelector
				title="Attributes"
				selected={Array(_.sumBy(_.values(current), (o) => _.keys(_.pickBy(o)).length)).fill()}
			>
				{_.entries(attributeMappings).map(([dim, options]) => (
					<Card key={dim}>
						<CardContent>
							<Typography variant="h4">
								{groupByOptions[dim]}
							</Typography>
							{Object.entries(options).map(([attrName, desc]) => (
								<Checkbox
									key={attrName}
									label={desc}
									name={`${dim}_${attrName}`}
									value={!!(current[dim] || {})[attrName]}
									onChange={(ev) => {
										const { value } = ev.target;
										const newAttribs = _.cloneDeep(attributes);
										newAttribs[dim] = newAttribs[dim] || {};
										newAttribs[dim][attrName] = value;
										newAttribs[dim] = MiscUtils.rearrangeObj(newAttribs[dim], Object.keys(options));
										this.setState({ attributes: newAttribs });
									}}
								/>
							))}
						</CardContent>
					</Card>
				))}
			</ExpandSelector>
		);
	}

	render() {
		const {
			groupBy,
			sums,
			whereIn,
			granularity,
			type,
			title,
			description,
			trigger,
			useForecast,
			filterSelectionType,
		} = this.state;
		const {
			onSave,
			onCancel,
			initSums,
			advMappings,
			report,
			reportData,
			groupByOptions,
			settings,
		} = this.props;

		const { placementLabels } = settings.reportData.report;
		const attributeMappings = _.pick(reportData.report.attributeMappings, groupBy);
		const isAdmin = stores.identity.isAdministrator();
		const isAnyHbType = type === 'hb' || type === 'summary_hb';
		const isFutureType = type === 'future';
		const { CURRENCIES, systemSettings, userReportOptions } = SystemData.genericData;
		const { programmaticOverrideUserSumOptions, hide } = systemSettings;
		const { HIDE_SUM = {} } = report;
		const swapped = (i1, i2) => {
			const arr = _.clone(groupBy);
			const tmp = arr[i1];
			arr[i1] = arr[i2];
			arr[i2] = tmp;
			return arr;
		};
		const filterKeys = _.without(
			isAdmin ? Object.keys(groupByOptions) : this.getUserGroupByOptions(),
			'date',
			...this.getDimensionExclude(this.state),
			...reportData.customizer.getExcludedFilterKeys(),
		);

		const filters = _.zipObject(filterKeys, Array(filterKeys.length).fill(true));
		const selectOptions = (fieldName) => ({
			selected: (whereIn || {})[fieldName] || [],
			onChange: (items) => this.setState({ whereIn: { ...whereIn || {}, [fieldName]: items } }),
			type: reportData.customizer.siteSelectType(),
		});
		const field = (name) => ({
			name,
			value: this.state[name],
			onChange: (ev) => this.setState({ [name]: ev.target.value }),
		});
		const avail = this.availableOptionsFor(undefined, this.state);
		const granularityOptions = report.getGranularityOptions();
		let sumDescriptions = this.SUM_DESCRIPTIONS;
		if (!isAdmin) {
			const { USER_SUM_OPTIONS = [] } = userReportOptions[type] || {};
			sumDescriptions = _.pick(sumDescriptions, type === 'programmatic'
				? (programmaticOverrideUserSumOptions || USER_SUM_OPTIONS)
				: USER_SUM_OPTIONS);
		}
		sumDescriptions = _.pick(sumDescriptions, this.limitSumsByGrouping(_.keys(sumDescriptions), groupBy));
		const trendMetrics = _.pickBy(sumDescriptions, (v, k) => ((this.CALCULATED_SUMS || {})[k] || {}).trendMetric);
		const normalMetrics = _.pickBy(sumDescriptions, (v, k) => !trendMetrics[k]);
		const metricGroups = reportData.customizer.splitMetricsToUiGroups(normalMetrics, this.state);
		const filterSettings = { ...this.OTHER_SETTINGS?.filters, filterByFuture: isFutureType };

		const MetricsList = (p) => (
			<Card>
				<CardContent>
					<Typography variant="h2">
						{p.title}
					</Typography>
					{Object.entries(p.metrics).map(([sum, desc]) => !HIDE_SUM[sum] && (
						<Checkbox
							key={sum}
							label={desc}
							name="showChart"
							value={sums.indexOf(sum) >= 0}
							onChange={(ev) => this.setSumSelected(sum, ev.target.value)}
							fullWidth
						/>
					))}
					{p.after}
				</CardContent>
			</Card>
		);

		const pieChartAlertMessage = groupBy.length > 1
			? 'The pie chart visualisation is not supported when multiple dimensions are selected'
			: groupBy[0] === 'date'
				? 'The pie chart visualisation is not supported for the "date" dimension'
				: this.state.useCompareTo
					? 'The pie chart visualisation is not supported when a comparison date range is selected'
					: sums.some((sum) => trendMetrics.hasOwnProperty(sum))
						? 'The pie chart visualisation is not supported for trend metrics'
						: null;

		return (
			<>
				<DialogTitle>{trigger ? 'Alarm settings' : 'Report settings'}</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="space-between"
									>
										<TextField
											{...field('title')}
											label={trigger ? 'Alarm name' : 'Title'}
											fullWidth
										/>
										{ isForecastAvailable(isAdmin) && (
											<ForecastToggle
												state={this.state}
												update={(settings) => this.setState(settings)}
											/>
										)}
									</Box>
									{description && (
										<Box>
											<TextField
												{...field('description')}
												label="Description"
												fullWidth
											/>
										</Box>
									)}
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={6}>
							<Card>
								<CardContent>
									<Typography variant="h3">
										Display
									</Typography>
									<Box display="flex" alignItems="flex-start" mt={2}>
										<ReportTypeSelector
											state={this.state}
											update={(s) => this.setState(s)}
										/>
									</Box>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<Typography variant="h3">
										Date-range
									</Typography>
									<Grid
										container
										spacing={4}
									>
										<Grid item xs={6}>
											<Box display="flex" justifyContent="space-between">
												<StartEndPicker
													report={report}
													isActive={!this.state.dynamicDateRange}
													state={this.state}
													update={(settings) => this.setState({
														...settings,
														dynamicDateRange: undefined,
													})}
													margin="normal"
												/>
											</Box>
										</Grid>
										<Grid item xs={6}>
											<Select
												name="Show"
												label="Show"
												items={_.map(granularityOptions, (v, k) => ({ label: v, value: k }))}
												value={granularity}
												onChange={(ev) => this.setState({ granularity: ev.target.value })}
												underlineShow={false}
												margin="normal"
											/>
										</Grid>
										<Grid item xs={12}>
											<Grid container spacing={4} sx={{ mt: -7 }}>
												{reportData.customizer.hasTimezoneSupport() && (
													<Grid item xs={2} sx={{ mr: 4.5 }}>
														<TimezoneSelect
															width={120}
															{...field('timezone')}
														/>
													</Grid>
												)}
												<Grid item xs={3}>
													<DynamicDateRangeSelector
														update={(settings) => this.setState(settings)}
														currentDynamicDateRange={this.state.dynamicDateRange}
														dateRanges={reportData.customizer.getDynamicDateRangesValues()}
														supportedGranularities={reportData.customizer.supportedGranularities()}
														allowToday={reportData.customizer.hasDataForToday()}
													/>
												</Grid>
												<Grid item xs={1} />
												<Grid item xs={6}>
													<CompareToSwitch
														state={this.state}
														update={(settings) => this.setState(settings)}
														margin="normal"
													/>
												</Grid>
											</Grid>
										</Grid>

									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<Typography variant="h3">
										What to report
									</Typography>
									{this.state.groupBy.map((group, idx) => (
										<Box
											key={`${group}_${idx}`}
											display="flex"
											marginTop={idx === 0 ? 2 : 1}
										>
											<SelectorDropdown
												title={idx ? 'Then by' : 'First by'}
												items={_.map(this.availableOptionsFor(idx, this.state), (v, k) => ({
													name: v,
													value: k,
												}))}
												selected={group}
												onChange={(ev, i, val) => {
													const newGroupBy = _.clone(this.state.groupBy);
													newGroupBy[idx] = val;
													this.updateSumsAndGroupBy(newGroupBy);
												}}
												className={styles.groupBySelector}
											/>
											{idx !== 0 ? (
												<IconButton
													onClick={() => this.updateSumsAndGroupBy(swapped(idx, idx - 1))}
													size="large"
												>
													<UpIcon />
												</IconButton>
											) : <EmptyIcon />}
											{idx < groupBy.length - 1 ? (
												<IconButton
													onClick={() => this.updateSumsAndGroupBy(swapped(idx, idx + 1))}
													size="large"
												>
													<DownIcon />
												</IconButton>
											) : <EmptyIcon />}
											{groupBy.length > 1 ? (
												<DeleteButton
													title="Delete"
													onClick={() => this.updateSumsAndGroupBy(this.state.groupBy.filter((g, i) => i !== idx))}
												/>
											) : <EmptyIcon />}
										</Box>
									))}
									{!_.isEmpty(avail)
									&& (
										<Box textAlign="center">
											<IconButton
												onClick={() => this.updateSumsAndGroupBy(_.clone(groupBy).concat([Object.keys(avail)[0]]))}
												size="large"
											>
												<AddIcon />
											</IconButton>
										</Box>
									)}
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={6}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<MetricsList
										title="Metrics"
										{...metricGroups[0]}
									/>
								</Grid>
								{!_.isEmpty(attributeMappings) && (
									<Grid item xs={12}>{this.renderAttributes(attributeMappings)}</Grid>)}
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid container spacing={3}>
								{metricGroups.slice(1).filter((g) => !_.isEmpty(g.metrics)).map((p, i) => (
									<Grid item xs={12} key={`mg_${i}`}>
										<MetricsList {...p} />
									</Grid>
								))}
								{!_.isEmpty(trendMetrics) && (
									<Grid item xs={12}>
										<MetricsList
											title="Trend Metrics"
											metrics={trendMetrics}
											after={(
												<div>
													<Select
														label="Method"
														{...field('trendMethod')}
														items={[
															{ label: 'Median of previous periods', value: 'median' },
															{ label: 'Average of previous periods', value: 'avg' },
														]}
														margin="normal"
														fullWidth
													/>
													<Select
														label="Number of previous periods"
														{...field('trendPeriods')}
														items={_.range(1, 6).map((i) => ({
															label: `${i} period${i > 1 ? 's' : ''}`,
															value: i,
														}))}
														margin="normal"
														fullWidth
													/>
												</div>
											)}
										/>
									</Grid>
								)}
								{!(!isAdmin && hide.otherOptions) && (
									<Grid item xs={12}>
										<ExpandSelector title="Other options">
											<Grid container spacing={3}>
												<Grid item xs={12}>
													<Checkbox
														label="Hide header"
														{...field('hideSettings')}
													/>
												</Grid>
												{(type === 'programmatic' || type === 'future') && (
													<>
														<Grid item xs={12}>
															<Select
																label="Currency"
																{...field('currency')}
																nonSelected="(default)"
																items={_.map(CURRENCIES, (v, k) => ({
																	label: `${v.name} (${k})`,
																	value: k,
																}))}
																fullWidth
															/>
														</Grid>
														{ (isAdmin || !hide.advertiserMappings)
																&& (
																	<>
																		{AllMappingDimensions.map((mappingDim) => (
																			<Grid item xs={12} key={mappingDim.name}>
																				<Select
																					label={`${mappingDim.uiCName} Mapping`}
																					{...field(mappingDim.reportProp)}
																					nonSelected="(default)"
																					items={advMappings.filter((
																						(m) => m.dimType === mappingDim.name
																					)).map((m) => ({
																						label: m.name,
																						value: m.id,
																					}))}
																					fullWidth
																				/>
																			</Grid>
																		))}
																		<Grid item xs={12}>
																			<TextField
																				{...field('maxAdvertisers')}
																				label="Max adv. / buyer / etc (approx.)"
																				integer
																				fullWidth
																			/>
																		</Grid>
																	</>
																)}
														{isAdmin && (
															<Grid item xs={12}>
																<Checkbox
																	label="Include non-allocated data in Sales Rep. reports"
																	{...field('includeNoUserAdvertisers')}
																/>
															</Grid>
														)}
														{type === 'programmatic' && (
															<Grid item xs={12}>
																{ (isAdmin || (userReportOptions.programmatic.USER_OTHER_OPTIONS && userReportOptions.programmatic.USER_OTHER_OPTIONS.includes('revenueCorrection')))
																&& <CorrectionSelector field={field} />}
															</Grid>
														)}
													</>
												)}
												{isAnyHbType && (
													<Grid item xs={12}>
														<Checkbox
															label="Split by auction runner (client/server)"
															{...field('splitByAuctionRunner')}
														/>
													</Grid>
												)}
												{isAdmin && (
													<>
														<Grid item xs={12}>
															<Checkbox
																label="Merge user-hidden objects"
																{...field('mergePublicHidden')}
															/>
														</Grid>
														<Grid item xs={12}>
															<ActionButton
																label="Copy as invoice link"
																onClick={() => {
																	const macro = `{{reportUrlOf settings='${this.macroSettingsStr(true)}'}}`;
																	const html = `<a href="${location.origin}/${relEnc(macro)}" target="_blank">${escape(title)}</a>`;
																	BrowserUtils.copyTextToClipboard(html, true);
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<ActionButton
																label="Copy as invoice table"
																onClick={() => BrowserUtils.copyTextToClipboard(this.invoiceTableText(), true)}
															/>
														</Grid>
													</>
												)}
											</Grid>
										</ExpandSelector>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Paper>
								<Box padding={2}>
									<Grid container spacing={3} alignItems="center">
										<Grid item xs={12}>
											<Box sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-between',
											}}
											>
												<Typography variant="h3">
													Filter by
													{' '}
													{reportData.customizer.renderFilterHeader({
														selectOptions,
														filters,
														settings: this,
													})}
												</Typography>
												<Button
													variant="text"
													className="text-red-500"
													onClick={() => {
														this.setState({
															whereIn: {},
															filterSelectionType: {},
														});
													}}
												>
													Clear
													{' '}
													<ClearIcon />
												</Button>
											</Box>
										</Grid>
										<StandardFilters
											selectOptions={selectOptions}
											filters={filters}
											initSums={initSums}
											whereIn={whereIn}
											placementLabels={placementLabels || {}}
											includeSourceDbId={isAnyHbType}
											filterSelectionType={filterSelectionType}
											filterSettings={filterSettings}
											customizer={reportData.customizer}
											onChangeFilterSelectionType={(filterType, selectionType) => {
												const updatedSelectionType = { ...filterSelectionType };
												updatedSelectionType[filterType] = selectionType;
												// Only include the exclusive ones,
												// to make shared report URL shorter.
												const newFilterSelectionType = _.pickBy(
													updatedSelectionType,
													(t) => t === 'EXCLUSIVE',
												);
												this.setState({
													filterSelectionType: newFilterSelectionType,
												});
											}}
											{..._.pick(this.state, _.map(AllMappingDimensions, 'reportProp'))}
										/>
									</Grid>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</DialogContent>
				{(this.state.showPieChart && pieChartAlertMessage) && (
					<DialogContent className={styles.alertContainer}>
						<Box marginTop={2}>
							<PieChartNotSupportedAlert>
								{pieChartAlertMessage}
							</PieChartNotSupportedAlert>
						</Box>
					</DialogContent>
				)}
				<DialogActions>
					<ActionButton
						label="Cancel"
						onClick={() => onCancel()}
						variant="text"
					/>
					<ActionButton
						label="Ok"
						color="primary"
						demoAllow
						onClick={() => onSave(this.state)}
					/>
				</DialogActions>
			</>
		);
	}
}

ReportSettings.propTypes = {
	report: PropTypes.object.isRequired,
	reportData: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	settingsFilter: PropTypes.array.isRequired,
	initSums: PropTypes.array.isRequired,
	advMappings: PropTypes.array.isRequired,
	groupByOptions: PropTypes.object.isRequired,
	funcs: PropTypes.objectOf(PropTypes.func).isRequired,
};

ReportSettings.defaultProps = {};

export default ReportSettings;
