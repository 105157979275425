import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
	Typography,
	Card,
	CardContent,
	AccordionSummary,
	AccordionDetails,
	Accordion,
	styled,
	Box,
	Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { stores } from '../../stores';
import EventSettings from './EventSettings';
import GeneralChartSettings from './GeneralChartSettings';
import ChartDimensionSettings from './ChartDimensionSettings';
import EventForm from '../EventForm';
import { Effect } from '../Effects';

const ShadowlessAccordion = styled(Accordion)(() => ({
	'&.MuiPaper-root': {
		boxShadow: 'none',
	},
}));

function ChartSettingsPopout({
	open,
	chartSettings,
	autoChartType,
	canShowEvents,
	groupByOptions,
	groupBy,
	onShowEventLinesChange,
	displayMergeDimensionSettings,
	eventTags,
	onChartSettingsChange,
	onEventsChange,
	onClose,
	showChartTypeSelector,
}) {
	const [addEventViewIsOpen, setAddEventViewIsOpen] = useState(false);
	const [currentAnimation, setCurrentAnimation] = useState();
	const [editedEventData, setEditedEventData] = useState({
		title: '',
		description: '',
		date: null,
		tags: [],
		public: false,
	});
	if (!open) {
		return null;
	}

	return (
		<Effect
			startOnLoad
			duration={200}
			loadTimeout={0}
			animate={({ style }, val, { animation }) => {
				Object.assign(style, {
					right: `${(val - 1) * 100}px`,
					opacity: val,
				});
				if (animation !== currentAnimation) {
					setCurrentAnimation(animation);
				}
			}}
			style={{
				position: 'absolute',
				zIndex: 100,
				right: 0,
			}}
		>
			{ addEventViewIsOpen ? (
				<EventForm
					data={editedEventData}
					availableTags={eventTags}
					onSubmit={async (updatedEvents) => {
						onEventsChange(updatedEvents);
						setAddEventViewIsOpen(false);
					}}
					onFormDataChange={(data) => setEditedEventData(data)}
					onClose={() => {
						setAddEventViewIsOpen(false);
					}}
				/>
			) : (
				<Card>
					<CardContent>
						<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								variant="text"
								onClick={() => currentAnimation?.toMin(onClose)}
								sx={{
									color: 'info.main',
									mb: 1,
								}}
								endIcon={<CloseIcon />}
							>
								Close
							</Button>
						</Box>

						<ShadowlessAccordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography>Chart</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<GeneralChartSettings
									autoChartType={autoChartType}
									showChartTypeSelector={showChartTypeSelector}
									displayMergeDimensionSettings={displayMergeDimensionSettings}
									chartSettings={chartSettings}
									dimensions={groupBy
										.filter((dim) => dim !== 'date')
										.map((dim) => ({ label: groupByOptions[dim], value: dim }))}
									onChange={onChartSettingsChange}
								/>
							</AccordionDetails>
						</ShadowlessAccordion>

						{ displayMergeDimensionSettings && (
							<ShadowlessAccordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography>Dimensions</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<ChartDimensionSettings
										dimensions={groupBy
											.filter((dim) => dim !== 'date')
											.map((dim) => ({ label: groupByOptions[dim], value: dim }))}
										groupedDimensions={chartSettings.groupedDimensions}
										onChange={onChartSettingsChange}
									/>
								</AccordionDetails>
							</ShadowlessAccordion>
						)}

						{canShowEvents && (
							<ShadowlessAccordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography>Events</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<EventSettings
										selectedTags={chartSettings.selectedEventTags}
										tags={eventTags}
										showEventLines={!!chartSettings.showEventLines}
										onShowEventLinesChange={onShowEventLinesChange}
										onTagFilterChange={(selectedEventTags) => {
											onChartSettingsChange({ selectedEventTags });
										}}
										onAddEventClick={() => {
											setAddEventViewIsOpen(true);
										}}
									/>
								</AccordionDetails>
							</ShadowlessAccordion>
						)}
					</CardContent>
				</Card>
			)}
		</Effect>
	);
}

ChartSettingsPopout.propTypes = {
	open: PropTypes.bool.isRequired,
	chartSettings: PropTypes.shape({
		fill: PropTypes.bool,
		stacked: PropTypes.bool,
		showEventLines: PropTypes.bool,
		groupedDimensions: PropTypes.arrayOf(PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})),
		selectedEventTags: PropTypes.arrayOf(PropTypes.string),
		barChartType: PropTypes.oneOf(['horizontal', 'vertical']),
	}).isRequired,
	autoChartType: PropTypes.oneOf(['auto', 'bar', 'line']).isRequired,
	canShowEvents: PropTypes.bool.isRequired,
	groupByOptions: PropTypes.objectOf(PropTypes.string).isRequired,
	displayMergeDimensionSettings: PropTypes.bool,
	groupBy: PropTypes.arrayOf(PropTypes.string).isRequired,
	eventTags: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChartSettingsChange: PropTypes.func.isRequired,
	onShowEventLinesChange: PropTypes.func.isRequired,
	onEventsChange: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	showChartTypeSelector: PropTypes.bool,
};

ChartSettingsPopout.defaultProps = {
	displayMergeDimensionSettings: false,
	showChartTypeSelector: true,
};

export default ChartSettingsPopout;
