import React, { useEffect, useState } from "react";
import { FilterList } from "@mui/icons-material";
import { SearchBar, SearchBarProps } from "./SearchBar";
import { useRef } from "react";
import Fuse, { FuseResult } from "fuse.js";
import clsx from "clsx";

export type FuzzyFilterData = {
	id: string;
	text: string;
	data?: any;
};

export type FuzzyFilterResults = FuseResult<FuzzyFilterData>[] | undefined;

type FuzzyFilterBarProps = {
	data: FuzzyFilterData[];
	onFilter: (params: { results: FuzzyFilterResults, value: string}) => void;
	placeholder?: string;
	icon?: React.ReactNode;
	limit?: number;
	value?: string;
};

export function FuzzyFilterBar({
	data,
	onFilter,
	placeholder = 'Filter',
	icon,
	limit,
	value: initialValue,
	className,
	...rest
}: FuzzyFilterBarProps & SearchBarProps & React.HTMLAttributes<HTMLDivElement>) {
	const [value, setValue] = useState<string>('');

	const fuse = useRef<Fuse<FuzzyFilterData> | null>(null);
	useEffect(() => {
		if (data) {
			fuse.current = new Fuse(data, {
				keys: ['text'],
				includeScore: true,
				includeMatches: true,
				ignoreLocation: true,
				useExtendedSearch: true,
				minMatchCharLength: 1,
			});
			setValue(initialValue ?? '');
		}
	}, [data]);

	useEffect(() => {
		if (value) {
			const results = fuse.current?.search(value, { limit });
			const sorted = results?.sort((a, b) => a.score! - b.score!);
			onFilter({ results: sorted, value });
		} else {
			onFilter({ results: undefined, value });
		}
	}, [value]);

	const handleChange = (v: string) => {
		setValue(v);
	};

	return (
		<SearchBar
			className={clsx(
				'!gap-2 !px-3 h-[35px]',
				className
			)}
			icon={icon ?? <FilterList className="text-lg" />}
			placeholder={placeholder}
			onChange={handleChange}
			value={value}
			{...rest}
		/>
	);
}