import { useState, useEffect } from 'react';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import filterItems from './filterPropertyItems';

function usePropertyFilter(reports) {
	const [filteredByPropertyReports, setFilteredByPropertyReports] = useState([]);
	const [currentSharingStateFilter, setCurrentSharingStateFilter] = useLocalStorage(
		'reportsOverviewCurrentSharingStateFilter',
		'all',
	);

	const [activeFilters, setActiveFilters] = useState([]);

	const onSharingStateChange = (e) => {
		setCurrentSharingStateFilter(e.target.value);
	};

	useEffect(() => {
		if (reports) {
			const [filtered, isActive] = filterItems(reports, currentSharingStateFilter);
			setFilteredByPropertyReports(filtered);
			const filterName = 'sharingState';
			if (isActive) {
				if (!activeFilters.includes(filterName)) {
					setActiveFilters([...activeFilters, filterName]);
				}
			} else if (activeFilters.includes(filterName)) {
				setActiveFilters(activeFilters.filter((filter) => filter !== filterName));
			}
		}
	}, [reports, currentSharingStateFilter, activeFilters]);

	return {
		filteredByPropertyReports,
		activeFilters,
		currentSharingStateFilter,
		onSharingStateChange,
	};
}

export default usePropertyFilter;
