import _ from 'lodash';
import React from 'react';
import { byReportType, byAvailReportType } from 'relevant-shared/reportData/reportType';
import { makeRelativeDates } from 'relevant-shared/reportData/utils';
import SystemData from '../../lib/systemData';
import { stores } from '../../stores';
import { getUserGroupByOptions } from '../Report/utils';
import { demoMessage, isDemoUser } from '../DemoImport/utils';
import Base from '../../layouts/Base';
import { Toast } from '../Toast/Toast';
import classes from '../../api/classes';
import { ConfirmDialog } from '../ConfirmDialog';

const { ReportTemplate } = classes;

// This logic is intentionally different from how reports are filtered in the Report component.
// We choose not to bother with the 'additional' group-by options here, since those aren't known
// until report render time.
export function filterUserAvailableTemplates(templates, {
	debugUnavailability,
	isAdmin = stores.identity.isAdministrator(),
	genericData = SystemData.genericData,
	user,
} = {}) {
	return templates.filter((t) => {
		if (!byAvailReportType[t.settings.type]) {
			if (debugUnavailability) {
				t.unavailable = [...(t.unavailable ?? []), `Report type "${t.settings.type}" is not available to this user.`];
			} else {
				return false;
			}
		}

		if (!isAdmin) {
			const userReportOptions = genericData.userReportOptions[t.settings.type];
			if (t.settings.sums && userReportOptions) {
				const { USER_SUM_OPTIONS } = userReportOptions;
				if (t.settings.sums.some((s) => !USER_SUM_OPTIONS.includes(s))) {
					if (debugUnavailability) {
						t.unavailable = [...(t.unavailable ?? []), `Report sums "${_.difference(t.settings.sums, USER_SUM_OPTIONS).map((s) => `"${s}"`).join(', ')}" are not available to this user.`];
					} else {
						return false;
					}
				}
			}

			if (t.settings.groupBy) {
				const groupByOptions = getUserGroupByOptions(t.settings.type, { genericData, user });
				if (t.settings.groupBy.some((g) => !groupByOptions.includes(g))) {
					if (debugUnavailability) {
						t.unavailable = [...(t.unavailable ?? []), `Report dimensions "${_.difference(t.settings.groupBy, groupByOptions).map((s) => `"${s}"`).join(', ')}" are not available to this user.`];
					} else {
						return false;
					}
				}
			}

			// No `whereIn` filtering here, since the only special case that filters it
			// requires knowledge of the 'additional' group-by options.
		}

		return true;
	});
}

export function reportUrlFromSettings(settings) {
	const newSettings = makeRelativeDates(settings);
	const settingsStr = encodeURIComponent(btoa(JSON.stringify(newSettings)));
	const { reportLocation } = byReportType[newSettings.type];
	return `${reportLocation}/add?settings=${settingsStr}&ignoreCheck`;
}

export async function maybeCopyToDashboard(settings) {
	if (isDemoUser()) {
		demoMessage();
		return;
	}
	const ok = await Base.renderGlobal((closeFn) => (
		<ConfirmDialog
			open
			text="Do you want to add this widget to your dashboard?"
			onAny={closeFn}
		/>
	));
	if (!ok) {
		return;
	}
	await ReportTemplate.copyToDashboard(settings);
	Base.renderGlobal((closeFn) => (
		<Toast
			open
			message="Added to dashboard"
			timeout={3}
			status="success"
			onClose={closeFn}
		/>
	));
}
