const _ = require('lodash');
const { AS_FLAGS } = require('../misc/sharedConstants');

const FlagDimensions = [
	{
		name: 'mediaType',
		desc: 'Media type',
		values: [
			{ name: 'banner', desc: 'Banner', flag: 0 },
			{ name: 'video', desc: 'Video', flag: 'isVideo' },
			{ name: 'native', desc: 'Native', flag: 'isNative' },
		],
	},
	{
		name: 'revenueType',
		desc: 'Revenue type',
		values: [
			{ name: 'openRtb', desc: 'OpenRTB', flag: 0 },
			{ name: 'deals', desc: 'Deals', flag: 'isDeal' },
			{ name: 'direct', desc: 'Direct', flag: 'isDirect' },
		],
	},
	{
		name: 'demandChannel',
		desc: 'Demand channel',
		values: [
			{ name: 'headerBidding', desc: 'Headerbidding', flag: 0 },
			{ name: 'adserver', desc: 'Adserver', flag: 'isAdserverBid' },
		],
	},
];

FlagDimensions.forEach((dim) => {
	dim.values.forEach((val) => {
		const idx = AS_FLAGS.indexOf(val.flag);
		if (idx >= 0) {
			val.flag = 2 ** idx;
		}
	});
	dim.codeToDesc = _.mapValues(_.keyBy(dim.values, 'flag'), 'desc');
	dim.codeToName = _.mapValues(_.keyBy(dim.values, 'flag'), 'name');
	dim.valuesByName = _.keyBy(dim.values, 'name');
});

FlagDimensions.FlagDimsByName = _.keyBy(FlagDimensions, 'name');

module.exports = FlagDimensions;
