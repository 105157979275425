import React from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';
import linkable from '../linkable';

function RestartButton(props) {
	return (
		<IconButton {...props} size="large">
			<ReplayIcon />
		</IconButton>
	);
}

RestartButton.propTypes = {};

export default linkable(RestartButton);
