import { SECTION, NON_ADHERING_TO_SECTION_RULES_PAGES } from './config';
/**
 * Find next report to navigate to after deleting the current one.
 * If no report left in same group, navigate to dashboards.
 */
export function findNextReportPath(currentIndex, reportType, reports) {
	const basePath = `/reports/${reportType}/`;
	if (currentIndex === 0 && reports.length > 1) { // Go to the report after in list
		return `${basePath}${reports[1].id}`;
	} if (currentIndex > 0 && reports.length > 1) { // Go to the report before in list
		return `${basePath}${reports[currentIndex - 1].id}`;
	}
	return '/dashboard';
}

export function getSectionToExpand(path) {
	const pathParts = path.split('/');
	const section = pathParts[1];
	const page = pathParts[2];
	// Check if it's a 'special case' page
	const nonAdheringSection = NON_ADHERING_TO_SECTION_RULES_PAGES[page];
	if (nonAdheringSection) {
		return nonAdheringSection;
	}
	if (Object.values(SECTION).map((s) => s.toLowerCase()).includes(section.toLowerCase())) {
		return section.toLowerCase();
	}
	return null;
}

export function isReportsOverviewPath(path, availableReportPaths) {
	return path.includes('/reports')
		&& availableReportPaths.some((type) => path.endsWith(type));
}
