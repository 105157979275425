import { computed, extendObservable } from 'mobx';
import _ from 'lodash';
import BaseStore from './BaseStore';

const metaProperties = {
	__saving: false,
	__error: null,
};

function isArrayish(o) {
	return _.isArray(o)
		|| (o.constructor && o.constructor.name && o.constructor.name === 'ObservableArray');
}

class CollectionStore extends BaseStore {
	constructor(data = null) {
		super(data);
	}

	set(payload) {
		if (!isArrayish(payload)) throw new Error('This store accepts arrays (or ObservableArrays) only.');
		super.set(payload);

		for (const item of payload) {
			extendObservable(item, metaProperties);
		}
	}

	async loadAllWith(forceReload, loadFn) {
		if (forceReload) {
			this.loadPromise = null;
		}
		await (this.loadPromise = this.loadPromise || (async () => {
			if (this.status === 'loading') {
				throw Error('This wasn\'t supposed to happen!?');
			}
			super.loading();
			await loadFn();
		})());
	}

	@computed get all() {
		return super._data;
	}

	saving(item) {
		item.__saving = true;
	}

	saved(item) {
		item.__saving = false;
	}

	error(item, message) {
		item.__error = message;
	}

	noErrors(item) {
		item.__error = null;
	}

	js(preserveMeta = false) {
		return preserveMeta ? super.js() : super.js().map((item) => _.omit(item, _.keys(metaProperties)));
	}
}

export default CollectionStore;
