import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import DateUtils from '../../lib/dateUtils';
import OperationWrapper from '../OperationWrapper';
import { Alarms } from '../../api/relevant';
import JobButton from '../JobButton';
import DatePicker from '../DatePicker';
import DataTable from '../DataTable';
import Select from '../Select';
import Checkbox from '../Checkbox';

const cmp = (flds) => (e1, e2) => {
	for (const fld of flds) {
		const v1 = e1[fld];
		const v2 = e2[fld];
		if (v1 < v2) {
			return -1;
		} if (v2 < v1) {
			return 1;
		}
	}
	return 0;
};

const CATEGORIES = {
	normal: {
		label: 'Normal alarms',
		is: (alarm) => !alarm.isTrafficCheck,
	},
	traffic: {
		label: 'Traffic alarms',
		is: (alarm) => alarm.isTrafficCheck,
	},
	all: {
		label: 'All alarms',
		is: () => true,
	},
};

class AlarmList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			date: props.initDate,
			alarms: [],
			category: props.initCategory,
		};
	}

	async loadAlarms() {
		const { date } = this.state;
		const alarms = await Alarms.call('getAlarms', { start: date, end: date });
		alarms.forEach((alarm) => {
			alarm.date = new Date(alarm.date);
		});
		this.setState({ alarms });
	}

	shouldShow(alarm) {
		const { showAcknowledged, category } = this.state;
		if (!showAcknowledged && alarm.acknowledged) {
			return false;
		}
		const cat = CATEGORIES[category];
		if (!cat) {
			throw Error(`Invalid category '${category}'`);
		}
		if (!cat.is(alarm)) {
			return false;
		}
		return true;
	}

	render() {
		const { alarms, date } = this.state;
		const fld = (name) => ({ name, value: this.state[name], onChange: (ev) => this.setState({ [name]: ev.target.value }) });
		return (
			<Grid item xs={12}>
				<Paper>
					<Box padding={2}>
						<Typography variant="h2">
							List of alarms
						</Typography>
						<OperationWrapper
							fn={(op) => {
								this.op = op;
								return this.loadAlarms();
							}}
							reloadable
							load={date}
						>
							<Grid container spacing={3} alignItems="center">
								<Grid item xs={3}>
									<DatePicker
										floatingLabelText="Select date"
										maxDate={DateUtils.yesterday()}
										autoOk
										{...fld('date')}
										fullWidth
									/>
								</Grid>
								<Grid item xs={9}>
									<JobButton
										label="Check date again"
										fn={async () => {
											await Alarms.call('updateDate', { date });
											this.op.reload();
										}}
										color="primary"
									/>
								</Grid>
								<Grid item xs={3}>
									<Select
										label="AlarmCategory"
										{...fld('category')}
										items={_.entries(CATEGORIES).map(([value, obj]) => ({ label: obj.label, value }))}
										fullWidth
									/>
								</Grid>
								<Grid item xs={9}>
									<Checkbox
										label="Show acknowledged"
										{...fld('showAcknowledged')}
									/>
								</Grid>
								<Grid item xs={12}>
									<DataTable
										showCheckboxes={false}
										selectableRows={false}
										identifier={(row) => row.id}
										definitions={[
											{
												key: 'date',
												title: 'Date',
												style: {
													whiteSpace: 'nowrap',
												},
												format: (d) => <span style={{ fontWeight: 'bold' }}>{moment(d).format('YYYY-MM-DD')}</span>,
											},
											{
												key: 'type',
												title: 'Type',
											},
											{
												key: 'message',
												title: 'Message',
											},
											{
												key: 'acknowledged',
												title: 'Acknowledged',
												format: (acknowledged, alarm) => {
													if (acknowledged) {
														return `YES${alarm.autoAcked ? ' (auto)' : ''}`;
													}
													return (
														<JobButton
															color="secondary"
															label="Acknowledge"
															fn={async () => {
																await alarm.acknowledge();
																this.op.reload();
															}}
														/>
													);
												},
											},
											{
												key: 'autoAcking',
												title: 'Auto-ack type',
												format: (autoAcking) => (autoAcking ? 'YES' : 'NO'),
											},
											{
												key: 'autoAcking',
												title: '',
												format: (autoAcking, alarm) => (
													<JobButton
														color="primary"
														label={autoAcking ? 'Disable auto-ack' : 'Enable auto-ack'}
														fn={async () => {
															await alarm.autoAckType({ ack: !autoAcking });
															this.op.reload();
														}}
													/>
												),
											},
										]}
										data={alarms.filter((a) => this.shouldShow(a)).sort(cmp(['date', 'category', 'type', 'message']))}
									/>
								</Grid>
							</Grid>
						</OperationWrapper>
					</Box>
				</Paper>
			</Grid>
		);
	}
}

AlarmList.propTypes = {
	initCategory: PropTypes.string.isRequired,
	initDate: PropTypes.instanceOf(Date),
};

AlarmList.defaultProps = {
};

export default AlarmList;
