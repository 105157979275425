const DiffConstants = {
	DELETE_MARKER: '__delEte__', // This value in a diff-object means that we should delete the key
	OBJECT_DIFF_MARKER: '__obj_diFf__', // This key in an object indicates it's an object-diff
	ARRAY_DIFF_MARKER: '__arr_diFf__', // This key in an object indicates it's an array-diff
	ARRAY: 1, // Type: Array
	PRIMITIVE: 2, // Type: Primitive (String, Number, etc)
	ID_STRING: 3, // Type: A string (or ObjectId) that is (probably) a MongoDb ID
	ID_OBJECT: 4, // Type: A database object (object with ._id set)
	OTHER_OBJECT: 5, // Type: Another object

	MODE: { // "Modes" (.mode field) of DiffObjRange instances.
		NEW: 1, // Object is new
		REPLACE: 2, // replace all 'nonObjs' elements (see DiffObjRange)
		APPEND: 3, // previous 'nonObjs' wasn't changed, just append some element(s) (see DiffObjRange)
		UNCHANGED: 4, // nonObjs unchanged, although there might still be a 'diff' for 'obj'
	},
};

module.exports = DiffConstants;
