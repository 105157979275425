const _ = require('lodash');
const Node = require('./node');
const SspPlacementNode = require('./sspPlacementNode');

class PlacementNode extends Node {
	constructor(placement, parentNode) {
		super(placement, parentNode, 'PlacementNode');
		placement.ssps.forEach((sspPlac) => {
			this.children.push(new SspPlacementNode(sspPlac, this));
		});
	}

	/** Returns prebid configs used on > 60% of children */
	getDefaultPrebidConfigs() {
		const counts = [];
		this.children.forEach((child) => {
			child.prebidConfigs.forEach((cfg) => {
				counts[cfg.id] = counts[cfg.id] || { cfg, count: 0 };
				counts[cfg.id].count += 1;
			});
		});
		const defaults = _.pickBy(counts, ({ count }) => count >= this.children.length * 0.6);
		return _.values(defaults).map(({ cfg }) => cfg);
	}

	get adserver() {
		const { adserverId } = this.obj.adservers[0] || {};
		return this.publisherNode.adserversById[(adserverId || '').toString()];
	}

	get adserverSettings() {
		return (this.obj.adservers[0] || {}).settings;
	}

	get placementType() {
		const typeId = this.obj.placementType ? this.obj.placementType.toString() : null;
		return typeId ? this.publisherNode.placementTypes.find((p) => p.id === typeId) : null;
	}
}
PlacementNode.parentPath = 'placements';

module.exports = PlacementNode;
