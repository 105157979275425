import React from 'react';
import { Link } from '../Link/Link';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckedIcon from '@mui/icons-material/Check';
import UnCheckedIcon from '@mui/icons-material/Close';
import {
	Ssp,
	Dsp,
	Adserver,
	UserIdModule,
	AnalyticsSystems,
	BuySystem,
} from '../../api/relevant';
import { ActionButton } from '../ActionButton/ActionButton';
import DataTable from '../DataTable';
import RecordTableTools from '../RecordTableTools';
import BrowserUtils from '../../lib/browserUtils';
import OperationWrapper from '../OperationWrapper';

class SystemList extends React.Component {
	render() {
		const {
			publisherId,
			desc,
			basePath,
			readOnly,
			editLink,
			list,
			onSystemsChange,
		} = this.props;

		return (
			<OperationWrapper fn={async (op) => {
				this.op = op;
			}}
			>
				<Grid container direction="column" spacing={3}>
					<Grid item>
						<Paper>
							<Box
								display="flex"
								alignItems="center"
								padding={2}
							>
								<SettingsIcon />
								{' '}
								<Typography variant="h4">
									{desc}
									s
								</Typography>
							</Box>
							<Divider />
							<TableContainer>
								<DataTable
									showCheckboxes={false}
									identifier={(row) => row.id}
									definitions={[
										{ key: 'name', title: 'Name' },
										{ key: 'friendlyTypeName', title: 'Type' },
										{
											key: 'active',
											title: 'Active',
											format: (active) => (active ? <span><CheckedIcon /></span> : <span><UnCheckedIcon /></span>),
										},
										{
											key: 'id',
											title: 'Local',
											format: (id, sys) => (sys.publisherId ? <span><CheckedIcon /></span> : <span />),
										},
										{
											key: 'id',
											title: '',
											align: 'right',
											whenNull: () => null,
											format: (id, system) => (
												<RecordTableTools
													editHref={BrowserUtils.makeQs(`${basePath}${id}`, { publisherId })}
													deleteAction={() => this.op.reload(async () => {
														await system.delete();
														onSystemsChange();
													})}
												/>
											),
										},
									].filter((d) => !readOnly || d.key !== 'id')}
									data={_.sortBy(list || [], 'name').filter((s) => !publisherId || s.publisherId === publisherId)}
								/>
							</TableContainer>
						</Paper>
					</Grid>
					{(!readOnly || editLink)
					&& (
						<Grid item data-debug-id={`add${desc}Btn`}>
							<Link
								style={{ textDecoration: 'none' }}
								to={readOnly ? editLink : BrowserUtils.makeQs(`${basePath}add`, { publisherId })}
							>
								<ActionButton
									label={readOnly ? 'Edit' : `New ${desc}`}
									icon={!readOnly && <AddIcon />}
									color="primary"
								/>
							</Link>
						</Grid>
					)}
				</Grid>
			</OperationWrapper>
		);
	}
}

SystemList.propTypes = {
	publisherId: PropTypes.string,
	desc: PropTypes.string.isRequired,
	basePath: PropTypes.string.isRequired,
	list: PropTypes.array,
	editLink: PropTypes.string,
	readOnly: PropTypes.bool,
	onSystemsChange: PropTypes.func,
};

SystemList.defaultProps = {
	publisherId: null,
	list: null,
	editLink: null,
	readOnly: false,
	onSystemsChange: () => undefined,
};

export function DspList(props) {
	return (
		<SystemList
			type={Dsp}
			desc="Dsp"
			basePath="/settings/audience-configurations/dsps/"
			{...props}
		/>
	);
}

export function SspList(props) {
	return (
		<SystemList
			type={Ssp}
			desc="Ssp"
			basePath="/settings/programmatic-configurations/ssps/"
			{...props}
		/>
	);
}

export function AdserverList(props) {
	return (
		<SystemList
			type={Adserver}
			desc="Adserver"
			basePath="/settings/programmatic-configurations/adservers/"
			{...props}
		/>
	);
}

export function AnalyticsList(props) {
	return (
		<SystemList
			type={AnalyticsSystems}
			desc="Analytics system"
			basePath="/settings/programmatic-configurations/analytics/"
			{...props}
		/>
	);
}

export function UserIdModulesList(props) {
	return (
		<SystemList
			type={UserIdModule}
			desc="User ID module"
			basePath="/settings/programmatic-configurations/user-id-modules/"
			{...props}
		/>
	);
}

export function BuySystemList(props) {
	return (
		<SystemList
			type={BuySystem}
			desc="Buy system"
			basePath="/settings/buysystems/"
			{...props}
		/>
	);
}
