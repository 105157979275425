/* eslint-disable react/destructuring-assignment */
import { AllObjectNames, CustomObjectNames, byTypeInfo } from 'relevant-shared/objects/objectStore';
import _ from 'lodash';

const TypeToDesc = {
	BaseAdserver: 'Adserver',
	BaseSsp: 'SSP',
};

const ToPlural = {
	Country: 'Countries',
};

export function typeOptions() {
	return {
		optionType: [null, ...AllObjectNames],
		// Let's skip Publisher/Site/Placement along with Country as by-object-type for now, to avoid too large objects.
		byObjectType: ['BaseSsp', 'BaseAdserver', ...CustomObjectNames],
	};
}

/** Object/option type to user-friendly name */
export function typeToDescription(type, plural) {
	const { name: customName } = byTypeInfo(type) || {};
	const name = customName || type;
	const res = TypeToDesc[name] || name;
	if (plural) {
		if (ToPlural[res]) {
			return ToPlural[res];
		}
		return `${res}s`;
	}
	return res;
}

// Pick a valid .byOptionType option - nested fields of the same .byOptionType isn't allowed
export function availSelectOptions(fld, prev, currentVal) {
	let forbidden = [];
	if (fld === 'byObjectType' && prev) {
		forbidden = _.map(_.filter(prev, { type: 'ByObject' }), 'byObjectType');
	}
	const avail = typeOptions()[fld] || [];
	return avail.filter((ty) => ty && (ty === currentVal || !forbidden?.includes(ty)));
}

/** retun props for option type selector (.byObjectType / .optionType) */
export function selectOptionsProps(fld, { field }, prev) {
	const fieldRes = field(fld);
	const { value } = fieldRes;
	const avail = availSelectOptions(fld, prev, value);
	const items = avail.map((opt) => ({
		label: typeToDescription(opt),
		value: opt,
	}));
	if (value && !avail.includes(value)) {
		items.push(({ value, label: '[Deleted type]' }));
	}
	return {
		...fieldRes,
		items,
		...(items.length !== avail.length && { nonSelected: '(none)' }),
	};
}

// Set a valid .byOptionType option - nested fields of the same .byOptionType isn't allowed
export function makeValidByObjectType(model, prev) {
	const avail = availSelectOptions('byObjectType', prev);
	if (model.byObjectType && !avail.includes(model.byObjectType)) {
		model.byObjectType = avail[0] || null;
	}
	return model;
}
