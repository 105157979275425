import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Form from '../../containers/Form';
import { ActionButton } from '../ActionButton/ActionButton';
import PricelistEditor from '../PricelistEditor';

@Form
class PricelistEditForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = { };
	}

	render() {
		const { field, priceListContext } = this.props;
		return (
			<Paper>
				<Box padding={2}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h2">
								Pricelist Info
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<PricelistEditor
								priceListContext={priceListContext}
								field={field}
								hasName
							/>
						</Grid>
						<Grid item>
							<ActionButton
								label="Save"
								color="primary"
								onClick={this.props.submit}
							/>
						</Grid>
						<Grid item>
							<ActionButton
								label="Cancel"
								onClick={this.props.onCancel}
								variant="text"
								color="primary"
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		);
	}
}

PricelistEditForm.wrappedComponent.propTypes = {
	field: PropTypes.func.isRequired,
	priceListContext: PropTypes.object.isRequired,
	submit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

PricelistEditForm.wrappedComponent.defaultProps = {

};

export default PricelistEditForm;
