import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import TextField from '../TextField';
import Select from '../Select';
import BrowserUtils from '../../lib/browserUtils';
import Base from '../../layouts/Base';
import { IfHasHbManager } from '../Wrappers';
import PrebidConfigEdit from '../PrebidConfigEdit';
import SystemData from '../../lib/systemData';
import BidParamEdit from '../BidParamEdit';
import ExpandSelector from '../ExpandSelector';
import AnalyticsSystemForm from '../AnalyticsSystemForm';
import CommentsPopup from '../CommentsPopup';
import { Link } from '../Link/Link';
import NodeDragDrop from '../Wrappers/nodeDragDrop';
import classes from '../../api/classes';
import { ConfirmDialog } from '../ConfirmDialog';

Object.assign(classes.GoogleAnalytics.prototype, {
	getCustomAnalyticsSystemMessage(profiles, { byProfileId }) {
		const ga4Profiles = profiles.filter((p) => p.propertyId || byProfileId?.[p.profileId]?.propertyId);
		if (ga4Profiles.length && ga4Profiles.length !== profiles.length) {
			return (
				<>
					<span style={{ color: 'red' }}>Warning: </span>
					<span style={{ fontSize: 'smaller' }}>
						<i>Non </i>
						GA4 page views will be omitted in reports when mixing Google Analytics versions - but
						<i> only </i>
						for days where page views has been imported for the GA4 properties selected
						for this site.
					</span>
				</>
			);
		}
		return null;
	},
	friendlyProfileName(profile) {
		return `${profile.name} - ${profile.url}`;
	},
});

const countries = [
	{
		label: 'Any',
		value: 'Any',
	},
	{
		label: 'Denmark',
		value: 'Denmark',
	},
	{
		label: 'Finland',
		value: 'Finland',
	},
	{
		label: 'France',
		value: 'France',
	},
	{
		label: 'Germany',
		value: 'Germany',
	},
	{
		label: 'Hungary',
		value: 'Hungary',
	},
	{
		label: 'Iceland',
		value: 'Iceland',
	},
	{
		label: 'Netherland',
		value: 'Netherland',
	},
	{
		label: 'Norway',
		value: 'Norway',
	},
	{
		label: 'Spain',
		value: 'Spain',
	},
	{
		label: 'Sweden',
		value: 'Sweden',
	},
	{
		label: 'UK',
		value: 'UK',
	},
];

class WebsiteForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: props.expanded ?? false
		};
	}

	createNewPlacement() {
		const { publisherNode, website } = this.props;
		const websiteNode = publisherNode.byId[website.id];
		const info = {};
		const params = websiteNode.bidderParams({
			sspId: 'genericPlacementData',
			opts: {
				onlyForOwnAsDefault: true,
				infoDst: info,
			},
		});
		const plac = new SystemData.models.Placement({ name: 'New placement' });
		if (!_.isEmpty(params)) {
			publisherNode.obj.bidParams.push({
				unitId: plac.id,
				sspId: 'genericPlacementData',
				params,
			});
		}
		if (info.hasMissingRequired) {
			this.showMissingRequiredPlacId = plac.id;
		}
		return plac;
	}

	shouldComponentUpdate(__, { expanded: newExpanded }) {
		const { expanded } = this.state;
		return !!(newExpanded || expanded || this.renderBidParamGeneration !== BidParamEdit.applyGeneration);
	}

	renderDeleteButton() {
		const { collectionRemove } = this.props;
		return (
			<Tooltip title="Delete website">
				<IconButton
					onClick={async () => {
						const ok = await Base.renderGlobal((closeFn) => (
							<ConfirmDialog
								open
								text="Do you want to delete this Web site"
								onAny={closeFn}
							/>
						));
						if (ok) {
							collectionRemove();
						}
					}}
					size="large"
				>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		);
	}

	render() {
		const {
			content,
			form,
			field,
			value,
			collectionAdd,
			publisher,
			website,
			history,
			systems,
			publisherNode,
		} = this.props;
		const { expanded } = this.state;
		this.renderBidParamGeneration = BidParamEdit.applyGeneration;
		const websiteNode = publisherNode.byId[website.id];
		const siteTagUrl = BrowserUtils.makeQs('/settings/tag-data-edit', {
			type: 'sspSiteId',
			accountId: publisher.id,
			id: website.id,
		});
		const { showMissingRequiredPlacId } = this;
		this.showMissingRequiredPlacId = null;

		const analyticsSystemOptions = systems.analyticsSystems;

		return (
			<ExpandSelector
				forms={form.formCollection()}
				style={{ padding: '0px 10px', backgroundColor: '#ECECEC' }}
				expanded={expanded}
				onExpandChange={(newExpanded, { hasErrors }) => {
					if (newExpanded || !hasErrors()) {
						this.setState({ expanded: newExpanded });
					}
				}}
				title={(
					<NodeDragDrop
						form={form}
						id={website.id}
						publisherNode={publisherNode}
					>
						<div style={{ display: 'flex' }}>
							<div
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								{value('domain') !== '' ? value('domain') : 'Website placeholder'}
							</div>
							<div
								style={{
									marginLeft: 'auto', display: 'flex', fontSize: 'medium', alignItems: 'center',
								}}
								onClick={(ev) => ev.stopPropagation()}
							>
								<div style={{ marginRight: 20 }}>
									<BidParamEdit
										form={form}
										node={websiteNode}
										dstObj={publisher}
										onDone={() => form.update()}
										ssps={systems.ssps}
										userIdModules={systems.userIdModules}
										checkbox={!!publisher.bidParams.find((p) => (
											p.unitId === website.id && !_.isEmpty(p.params)
										))}
									/>
								</div>
								<IfHasHbManager>
									<div style={{ marginRight: 20 }}>
										<PrebidConfigEdit
											publisher={publisher}
											site={website}
											form={form}
											field={field}
											systems={systems}
											publisherNode={publisherNode}
										/>
									</div>
								</IfHasHbManager>
								{!website.isNew && (
									<Link to={siteTagUrl}>Programmatic tag</Link>
								)}
								<div style={{ marginLeft: 8 }}>
									<CommentsPopup
										connectedTo={website.id}
										context={publisher.id}
									/>
								</div>
								{this.renderDeleteButton()}
							</div>
						</div>
					</NodeDragDrop>
				)}
				content={() => (
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<TextField
								{...field('domain')}
								label="Domain"
								required
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<Select
								{...field('country')}
								label="Country"
								items={countries}
								required
								fullWidth
							/>
						</Grid>
						{ analyticsSystemOptions && analyticsSystemOptions.length > 0
							&& (analyticsSystemOptions.some(({ knownProfiles }) => knownProfiles && knownProfiles.length > 0)) && (
							<Grid item xs={12}>
								<AnalyticsSystemForm
									analyticsSystemOptions={analyticsSystemOptions}
									analyticsSystem={website.analyticsSystem}
									onChange={(val) => {
										const { name, onChange } = this.props.field('analyticsSystem');
										onChange({ target: { name, value: val } });
									}}
								/>
							</Grid>
						)}
						<Grid item xs={6} />
						<Grid item xs={12}>
							<Typography variant="h3">
								Placements
							</Typography>
						</Grid>
						{content({ showMissingRequiredPlacId })}
						<Grid item xs={12}>
							<Tooltip title="New placement">
								<IconButton
									onClick={() => collectionAdd(this.createNewPlacement())}
									size="large"
								>
									<AddIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				)}
			/>
		);
	}
}

WebsiteForm.propTypes = {
	publisher: PropTypes.object.isRequired,
	website: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	value: PropTypes.func.isRequired,
	collectionAdd: PropTypes.func.isRequired,
	collectionRemove: PropTypes.func.isRequired,
	systems: PropTypes.object.isRequired,
	content: PropTypes.func.isRequired,
	publisherNode: PropTypes.object.isRequired,
	expanded: PropTypes.bool,
};

export default WebsiteForm;
