import { useCallback } from 'react';
import { byReportType } from 'relevant-shared/reportData/reportType';
import BrowserUtils from '../../../lib/browserUtils';
import { stores } from '../../../stores';

function useShareReport({
	reportComponentRef,
	setShareUrl,
	type,

}) {
	return useCallback(() => {
		const { reportLocation, genericReportLocation } = byReportType[type] || {};
		const reportLoc = reportLocation ? genericReportLocation || `${reportLocation}/add` : null;
		const parentState = reportComponentRef.current.getCurrentProperties(true);
		const path = BrowserUtils.makeQs(reportLoc, {
			settings: btoa(JSON.stringify(parentState)),
			admin: stores.identity.isAdministrator(),
		});
		const url = `${window.location.origin}${path}`;
		setShareUrl(url);
	}, [reportComponentRef, setShareUrl, type]);
}

export default useShareReport;
