import PropTypes from 'prop-types';
import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

class RadioButtonGroup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	change(event) {
		const { name, onChange } = this.props;
		onChange({ target: { name, value: event.target.value } }, this.props);
	}

	render() {
		const {
			name, selected, items, defaultValue, labelPosition, heading,
		} = this.props;

		return (
			<FormControl component="fieldset">
				{heading && <FormLabel component="legend">{heading}</FormLabel>}
				<RadioGroup
					name={name}
					value={selected}
					onChange={(event) => this.change(event)}
					defaultValue={defaultValue}
				>
					{items.map((item) => (
						<FormControlLabel
							key={item.value}
							label={item.name}
							value={item.value}
							disabled={item.disabled}
							style={{ marginBottom: '0.5em' }}
							labelPlacement={labelPosition}
							control={<Radio color="primary" />}
						/>
					))}
				</RadioGroup>
			</FormControl>
		);
	}
}

RadioButtonGroup.propTypes = {
	name: PropTypes.string.isRequired,
	selected: PropTypes.string,
	onChange: PropTypes.func,
	items: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
		disabled: PropTypes.bool,
	})),
	defaultValue: PropTypes.string,
	labelPosition: PropTypes.oneOf(['start', 'end']).isRequired,
	heading: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
	labelPosition: 'end',
};

export default RadioButtonGroup;
