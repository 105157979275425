import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import {
	fmtDate, renderAckErrString, execStatusCol, checkboxOf, OwnershipIcon, ShareSelector,
} from './utils';
import { TriggerActions } from './actions';
import DataTableWithMemory from '../DataTable/dataTableWithMemory';
import Icon from '../Icon';
import { CELL_DEFAULT_STYLE } from './constants';
import TriggerActionButton from './triggerActionButton';
import SystemData from '../../lib/systemData';
import { PopupBox } from '../Wrappers';
import { ActionButton } from '../ActionButton/ActionButton';
import NewTriggerTree from './newTriggerTree';
import { hbaEnabledForNormalUsers, futureEnabledForNormalUsers } from '../Report/utils';

function TriggerList({
	workspace, triggers, checks, selectedTriggers, onSelectionChanged,
}) {
	const { hbaEnabled, futureEnabled, PREDEFINED_REPORT_TRIGGERS: templateArrs } = SystemData.genericData;
	const showHba = hbaEnabled && (workspace.isAdmin || hbaEnabledForNormalUsers());
	const showFuture = futureEnabled && (workspace.isAdmin || futureEnabledForNormalUsers());
	const addNewButton = ({ label, templateArr, reTriggerMode }) => (
		<PopupBox
			opener={(({ openFn }) => (
				<ActionButton
					label={label}
					onClick={openFn}
					icon={<AddIcon />}
					color="primary"
				/>
			))}
			content={(p) => (
				<NewTriggerTree
					closeFn={p.closeFn}
					templateArr={templateArr}
					reTriggerMode={reTriggerMode}
					workspace={workspace}
				/>
			)}
		/>
	);

	return (
		<Grid container spacing={3}>
			{TriggerActions.filter((a) => !a.hideInTop).map((a) => {
				const matches = selectedTriggers.filter((trigger) => !a.cond || a.cond({
					trigger,
					workspace,
				}));
				return <TriggerActionButton key={a.name} matches={matches} action={a} workspace={workspace} />;
			})}
			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item>
						{addNewButton({
							label: 'New API Insights alarm',
							templateArr: templateArrs.programmatic[workspace.isAdmin ? 'admin' : 'user'],
							reTriggerMode: true,
						})}
					</Grid>
					{showHba && (
						<Grid item>
							{addNewButton({
								label: 'New HB Analytics alarm',
								templateArr: templateArrs.hb[workspace.isAdmin ? 'admin' : 'user'],
								reTriggerMode: false,
							})}
						</Grid>
					)}
					{showFuture && (
						<Grid item>
							{addNewButton({
								label: 'New Adserver forecast alarm',
								templateArr: templateArrs.future[workspace.isAdmin ? 'admin' : 'user'],
								reTriggerMode: false,
							})}
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<DataTableWithMemory
					showCheckboxes
					identifier={(row) => row.id}
					onChange={(selected) => onSelectionChanged(selected)}
					selected={selectedTriggers}
					cellDefaultStyle={CELL_DEFAULT_STYLE}
					definitions={[ // Active | Is private | Owner|  Ack/Err | Actions (check report)
						{
							key: 'name',
							title: 'Name',
						},
						{
							key: 'active',
							title: 'Active',
							format: (active) => checkboxOf(active),
						},
						...(workspace.isAdmin ? [
							{
								key: 'id',
								title: 'Owner',
								format: (id, trigger) => OwnershipIcon(workspace.getOwnership(trigger)),
							},
							{
								key: 'id',
								title: 'Sharing',
								format: (id, trigger) => (trigger.userId === workspace.myUserId && (
									<ShareSelector
										workspace={workspace}
										model={trigger}
										useButtonStyle
										onSave={async () => {
											await trigger.update(_.pick(trigger, 'sharedWithUserIds'));
											await workspace.op.reload(() => workspace.load());
										}}
									/>
								)),
							},
						] : []),
						{
							key: 'id',
							title: 'Re-trigger',
							format: (id, trigger) => checkboxOf(trigger.reTriggerMode),
						},
						{
							key: 'id',
							title: 'Acknowledged/Errors',
							format: (id) => renderAckErrString(_.filter(checks, { triggerId: id })),
						},
						execStatusCol({ workspace }),
						{
							key: 'id',
							title: 'Last run',
							format: (__, { lastRunTs }) => (lastRunTs ? fmtDate(lastRunTs) : 'NEVER'),
						},
						{
							key: 'id',
							title: 'Actions',
							format: (id, trigger) => TriggerActions.filter((a) => !a.cond || a.cond({
								trigger,
								workspace,
							})).map((a) => (
								<Icon
									pic={a.icon}
									tooltip={a.name}
									key={a.name}
									onClick={() => workspace.performAction(a, [trigger])}
								/>
							)),
						},
					]}
					data={triggers}
				/>
			</Grid>
		</Grid>
	);
}

TriggerList.propTypes = {
	workspace: PropTypes.object.isRequired,
	triggers: PropTypes.array.isRequired,
	checks: PropTypes.array.isRequired,
	selectedTriggers: PropTypes.array.isRequired,
	onSelectionChanged: PropTypes.func.isRequired,
};

TriggerList.defaultProps = {
};

export default TriggerList;
