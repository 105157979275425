import _ from 'lodash';
import BuiltInPlacementFields from 'relevant-shared/prebid/builtInPbConfigTagFields';
import BuiltInPbConfigTagFields from 'relevant-shared/prebid/builtInPlacementTagFields';
import SystemData from './systemData';

SystemData.onGenericDataUpdated.add(() => {
	const { optiDisabled } = SystemData.genericData;
	if (optiDisabled) {
		[BuiltInPlacementFields, BuiltInPbConfigTagFields].forEach((arr) => {
			_.remove(arr, 'optiField');
		});
	}
});
