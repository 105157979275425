import PropTypes from 'prop-types';
import React from 'react';

import TextField from '../TextField';
import RangeSelect from './rangeSelect';
import MiscUtils from '../../lib/miscUtils';

import {
	nrToCut,
	getCutOf,
	getEncCutDate,
	getCutRanges,
	getCutNrFromRanges,
} from '../../lib/cutUtils';

class CutField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getValidatorProps() {
		const { lastError } = this.state;
		return { satisfy: { valid: () => !lastError, message: lastError, checkOnEmpty: true } };
	}

	render() {
		const { onChange, name, useEmptyString } = this.props;
		const { txtValue, rangeUpdateCounter = 0 } = this.state;
		let { value } = this.props;
		if (!isNaN(parseFloat(value))) {
			value = parseFloat(value);
		}
		const ranges = !isNaN(parseFloat(value)) ? getCutRanges(value) : null;
		const singleRangeTextValue = () => {
			if (txtValue) {
				return txtValue;
			}
			if (useEmptyString) {
				if (txtValue === '') {
					return '';
				}
				if (value === undefined || value === '') {
					return '';
				}
			}
			return nrToCut(value || 0);
		};
		const txtProps = {
			...this.props,
			...(ranges && ranges.length > 1 ? {
				nonEditable: true,
				value: getCutOf(value, getEncCutDate(new Date())),
			} :	{
				onChange: (ev) => {
					const cut = ev.target.value;
					let cutNr;
					let err;
					try {
						cutNr = getCutNrFromRanges([{ cut }]);
					} catch (e) {
						err = cut && MiscUtils.errorMsg(e);
					}
					this.setState({ lastError: err, txtValue: cut });
					const newVal = useEmptyString && !cut ? '' : cutNr || 0;
					onChange({ target: { name, value: err ? value : newVal } }, {
						satisfy: {
							valid: () => !err,
							message: err,
							checkOnEmpty: true,
						},
					});
				},
				value: singleRangeTextValue(),
			}),
		};
		return (
			<TextField
				{...txtProps}
				endAdornment={ranges ? (
					<RangeSelect
						key={rangeUpdateCounter}
						model={{ ranges }}
						onCancel={() => this.setState({ rangeUpdateCounter: rangeUpdateCounter + 1 })}
						onSubmit={({ ranges }) => {
							onChange({ target: { name, value: getCutNrFromRanges(ranges) } }, {});
							this.setState({ txtValue: null, lastError: null });
						}}
					/>
				) : null}
			/>
		);
	}
}

CutField.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	useEmptyString: PropTypes.bool,
};

CutField.defaultProps = {
	value: undefined,
	useEmptyString: false,
};

export default CutField;
