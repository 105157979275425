import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

class DataTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	shouldComponentUpdate(nextProps) {
		return !this.props.onlyUpdateWhenDataChanges || !_.isEqual(this.props.data, nextProps.data);
	}

	getFilteredDefinitions() {
		return this.props.definitions.filter((definition) => definition.show !== false);
	}

	getFilteredRows() {
		const data = (this.props.data && this.props.data.slice()) || [];
		const { filters } = this.props;
		if (!filters.length) {
			return data;
		}

		const stack = this.props.filterMatch === 'all' ? _.every : _.some;

		return data.filter((row) => stack(filters, (passes) => passes(row)));
	}

	produceSummary(key, summary, rows) {
		const data = rows.map((row) => ((key in row) ? row[key] : null));
		return summary(data, rows);
	}

	renderNoData() {
		const cols = this.props.definitions
			.filter((definition) => definition.show !== false)
			.length;
		return (
			<TableBody>
				<TableRow>
					<TableCell colSpan={cols}>
						{this.props.noDataMessage}
					</TableCell>
				</TableRow>
			</TableBody>
		);
	}

	renderHeader(definitions, selectedRows, rows) {
		const titles = definitions
			.map((definition, index) => (
				<TableCell
					{...(definition.headerProps || {})}
					key={index}
					align={definition.align}
					style={_.merge({ ...this.props.cellDefaultStyle }, _.pickBy(definition.style, (v, k) => _.includes(['width'], k)))}
				>
					{definition.headerElm ? definition.headerElm : definition.title}
				</TableCell>
			));
		const maxSelectedRows = rows.length - this.props.disabledCheckboxesRowIndices.length;
		const indeterminate = selectedRows.length > 0 && selectedRows.length < maxSelectedRows;
		const checked = rows.length > 0 && selectedRows.length === maxSelectedRows;
		return (
			<TableHead>
				<TableRow {...(this.props.headerProps || {})}>
					{this.props.showCheckboxes && (
						<TableCell padding="checkbox">
							<Checkbox
								indeterminate={indeterminate}
								checked={checked}
								onChange={() => this.props.onRowSelection(checked ? 'none' : 'all')}
								inputProps={{ 'aria-label': 'Select all rows' }}
							/>
						</TableCell>
					)}
					{titles}
				</TableRow>
			</TableHead>
		);
	}

	renderFooter(definitions, rows) {
		if (!this.props.showFooter) return null;

		const aggregates = definitions
			.map((definition, index) => (
				<TableCell
					style={{ textAlign: definition.align || 'left', ...this.props.cellDefaultStyle }}
					key={index}
				>
					{definition.summary ? this.produceSummary(definition.key, definition.summary, rows) : null}
				</TableCell>
			));

		return <TableFooter><TableRow>{aggregates}</TableRow></TableFooter>;
	}

	getRows(definitions, rows) {
		const selectedRows = [];
		const rowElements = rows.map((row, rowIdx) => {
			const {
				identifier,
				isSelected,
				hoverable,
				selectableRows,
				showCheckboxes,
				onCellClick,
				onRowSelection,
				disabledCheckboxesRowIndices,
				onRowHoverChange,
			} = this.props;
			const selected = Boolean(isSelected(row));
			if (selected) {
				selectedRows.push(rowIdx);
			}

			function toggleSelect() {
				onRowSelection(selected ? _.without(selectedRows, rowIdx) : [...selectedRows, rowIdx]);
			}

			function handleCellClick(event) {
				if (selectableRows) {
					toggleSelect();
				}
				onCellClick(event);
			}

			const handleRowHoverChange = (hovering, rowIdx) => {
				if (hoverable && onRowHoverChange) {
					onRowHoverChange(hovering, rowIdx);
				}
			};

			const columns = definitions
				.map((definition, index) => {
					let raw;
					let formatted;

					if (definition.key in row) {
						raw = _.get(row, definition.key);
						formatted = definition.format ? definition.format(raw, row, rowIdx) : raw;
					} else if (definition.whenNull) {
						raw = definition.whenNull(row);
						formatted = raw;
					} else {
						raw = '<Column not in row>';
						formatted = raw;
					}

					return (
						<TableCell
							key={index}
							onClick={handleCellClick}
							align={definition.align}
							padding={definition.padding}
							style={({ ...this.props.cellDefaultStyle, ...definition.style })}
						>
							{formatted}
						</TableCell>
					);
				});
			return (
				<TableRow
					key={identifier(row)}
					selected={selected}
					hover={hoverable}
					onMouseEnter={() => handleRowHoverChange(true, rowIdx)}
					onMouseLeave={() => handleRowHoverChange(false, rowIdx)}
				>
					{showCheckboxes && (
						<TableCell padding="checkbox">
							<Checkbox
								checked={selected}
								onChange={toggleSelect}
								inputProps={{ 'aria-label': 'Select row' }}
								color="default"
								disabled={disabledCheckboxesRowIndices.includes(rowIdx)}
							/>
						</TableCell>
					)}
					{columns}
				</TableRow>
			);
		});
		return {
			rowElements,
			selectedRows,
		};
	}

	render() {
		const { deselectOnClickaway, onRowSelection } = this.props;
		const filteredData = this.getFilteredRows();
		const filteredDefinitions = this.getFilteredDefinitions();
		const { rowElements, selectedRows } = this.getRows(filteredDefinitions, filteredData);
		return (
			<ClickAwayListener
				onClickAway={() => deselectOnClickaway && onRowSelection([])}
			>
				<Table>
					{!this.props.noHeader && this.renderHeader(filteredDefinitions, selectedRows, filteredData)}
					{rowElements.length === 0 ? (
						this.renderNoData()
					) : (
						<TableBody>{rowElements}</TableBody>
					)}
					{this.renderFooter(filteredDefinitions, filteredData)}
				</Table>
			</ClickAwayListener>
		);
	}
}

DataTable.propTypes = {
	definitions: PropTypes.arrayOf(PropTypes.shape({
		key: PropTypes.string.isRequired,
		title: PropTypes.node.isRequired,
		show: PropTypes.bool,
		format: PropTypes.func,
		style: PropTypes.object,
		summary: PropTypes.func,
		align: PropTypes.oneOf(['left', 'right']),
		padding: PropTypes.oneOf(['none', 'default', 'checkbox']),
		whenNull: PropTypes.func,
	})),

	filters: PropTypes.arrayOf(PropTypes.func),
	filterMatch: PropTypes.oneOf(['any', 'all']),

	identifier: PropTypes.func.isRequired,
	isSelected: PropTypes.func,
	onRowSelection: PropTypes.func,

	data: PropTypes.any,

	showHeader: PropTypes.bool.isRequired,
	showFooter: PropTypes.bool.isRequired,

	onlyUpdateWhenDataChanges: PropTypes.bool.isRequired,

	showCheckboxes: PropTypes.bool.isRequired,
	selectableRows: PropTypes.bool.isRequired,
	deselectOnClickaway: PropTypes.bool.isRequired,

	noDataMessage: PropTypes.element.isRequired,

	hoverable: PropTypes.bool,
	onCellClick: PropTypes.func,
	cellDefaultStyle: PropTypes.objectOf(PropTypes.any),
	headerProps: PropTypes.objectOf(PropTypes.any),
	noHeader: PropTypes.bool,
	disabledCheckboxesRowIndices: PropTypes.arrayOf(PropTypes.number),
	onRowHoverChange: PropTypes.func,
};

DataTable.defaultProps = {
	definitions: [],
	filters: [],
	data: [],
	showCheckboxes: true,
	selectableRows: true,
	deselectOnClickaway: false,
	showHeader: true,
	showFooter: false,
	onlyUpdateWhenDataChanges: false,
	filterMatch: 'any',
	noDataMessage: <p>Set is empty.</p>,
	isSelected: () => {},
	onRowSelection: () => {},
	onCellClick: () => {},
	hoverable: false,
	cellDefaultStyle: {},
	headerProps: {},
	noHeader: false,
	disabledCheckboxesRowIndices: [],
	onRowHoverChange: undefined,
};

export default DataTable;
