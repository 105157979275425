import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { byReportType } from 'relevant-shared/reportData/reportType';
import { demoMessage, isDemoUser } from '../../DemoImport/utils';
import { Reports } from '../../../api/relevant';
import useReports from '../../../hooks/useReports';

function useSaveReport({
	isPrivate,
	report,
	reportComponentRef,
	type,
	subscriptions,
	setStatus,
}) {
	const history = useHistory();
	const { updateReports } = useReports();
	return useCallback(async () => {
		setStatus('saving');
		if (isDemoUser()) {
			demoMessage();
		} else if (reportComponentRef?.current) {
			const settings = reportComponentRef.current.getCurrentProperties();
			const formattedSubscriptions = subscriptions.map((subscription) => ({
				recipientUserIds: subscription.recipients.map((recipient) => recipient.userId),
				occurences: subscription.occurences,
				addedByUserId: subscription.addedByUserId,
			}));
			try {
				if (report) {
					await report.updateReport({
						settings,
						subscriptions: formattedSubscriptions,
						isPrivateReport: isPrivate,
					});
				} else {
					const { id } = await Reports.call('addReport', {
						settings,
						tag: byReportType[type].tag,
						isPrivateReport: isPrivate,
						subscriptions: formattedSubscriptions,
					});
					// Navigate to the newly created report, without keeping 'add' in history
					const path = history.location.pathname.replace('/add', '');
					history.replace(`${path}/${id}?status=saved`);
				}
				updateReports();
				setStatus('saved');
			} catch (error) {
				setStatus('saveError');
			}
		}
	}, [history, isPrivate, report, reportComponentRef, setStatus, subscriptions, type, updateReports]);
}

export default useSaveReport;
