import React from 'react';
import Base from '../../layouts/Base';
import PopupSelector from '../PopupSelector';
import { HtmlContainer } from '../Wrappers';

const MESSAGE_HTML = `
<div style="font-family: sans-serif; text-align: center; font-weight: bold; color: red;">
	Thanks for trying Relevant Yield. In this demo-version you can't make any changes.
</div>
<iframe style="width: 100%; height: 100%; border: 0;" src='https://relevant.fi/en/relevantyield/'></iframe>
`;

const fn = () => Base.renderGlobal((done) => {
	let started;
	return (
		<PopupSelector
			size="lg"
			customLink={(show) => {
				if (!started) {
					started = true;
					setTimeout(show);
				}
			}}
			onApplyChanges={done}
			onCancel={done}
			actionButtonProps={{ demoAllow: true }}
		>
			<HtmlContainer html={MESSAGE_HTML} noResizing style={{ minHeight: 800 }} />
		</PopupSelector>
	);
});

export default fn;
