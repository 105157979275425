import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SettingsIcon from '@mui/icons-material/Settings';
import styles from './styles.css';
import storeContainer from '../storeContainer';
import Spinner from '../../components/Spinner';
import ProgrammaticSettingsForm from '../../components/ProgrammaticSettingsForm';
import { ProgrammaticSettings } from '../../api/relevant';

const inlineStyles = {
	relevantBlackBorderColor: {
		borderColor: '#282828',
	},
	relevantBlackColor: {
		color: '#282828',
	},
	greyBorderBottomColor: {
		borderBottom: '1px solid rgb(224, 224, 224)',
	},
};

// TODO: This component is never rendered and can hence probably be removed.
@storeContainer()
class ProgrammaticCommonSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = null;
	}

	componentDidMount() {
		this.fetchCommonSettings();
	}

	async fetchCommonSettings() {
		const newState = {};
		await Promise.all([
			(async () => { newState.settings = (await ProgrammaticSettings.list())[0]; })(),
		]).then(() => {
			this.setState(newState);
		});
	}

	async submitCommonSettings(model) {
		console.log('Submitting ', model);

		this.setState({ ...this.state, spinner: true });
		// editProgrammaticsettings(model).then(() => {
		// 	alert('Successfully Upated the settings!');
		// 	this.setState(Object.assign({}, this.state, { spinner: false }));
		// }).catch((err) => alert(err));
	}

	render() {
		if (!this.state || this.state.spinner) return <Spinner delay />;
		return (
			<Card className={styles.card}>
				<CardContent>
					<Typography
						variant="h4"
						className={styles.cardTitle}
						style={inlineStyles.greyBorderBottomColor}
					>
						<div className={styles.cardHeader}>
							<SettingsIcon />
							Programmatic Common Settings
						</div>
					</Typography>
					<ProgrammaticSettingsForm
						model={this.state.settings}
						onSubmit={(model) => this.submitCommonSettings(model)}
					/>
				</CardContent>
			</Card>
		);
	}
}

ProgrammaticCommonSettings.propTypes = {

};

ProgrammaticCommonSettings.defaultProps = {

};

export default ProgrammaticCommonSettings;
