const _ = require('lodash');

class Granularity {
	static getOptions() {
		return {
			per10min: 'Per 10 Min',
			perhour: 'Per Hour',
			perday: 'Per Day',
			perweek: 'Per Week',
			permonth: 'Per Month',
			peryear: 'Per Year',
		};
	}

	static getDatabaseKey(value) {
		return _.invert(Granularity.getOptions())[value];
	}
}

module.exports = Granularity;
