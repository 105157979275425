const AudienceCustomizer = require('./audienceCustomizer');
const ProgrammaticCustomizer = require('./programmaticCustomizer');
const HbCustomizer = require('./hbCustomizer');
const SummaryHbCustomizer = require('./summaryHbCustomizer');
const LogCustomizer = require('./logCustomizer');
const FutureCustomizer = require('./futureCustomizer');
const ReportType = require('../reportType');

const CUSTOMIZERS = {
	audience: AudienceCustomizer,
	programmatic: ProgrammaticCustomizer,
	missingSspEntries: ProgrammaticCustomizer, // yes, should be same as programmatic for now..
	hb: HbCustomizer,
	summary_hb: SummaryHbCustomizer,
	log: LogCustomizer,
	future: FutureCustomizer,
};

class AllCustomizers {
	static init(customizers) {
		Object.assign(CUSTOMIZERS, customizers);
		ReportType.allCustomizers = this;
	}

	static createByType(type, reportData) {
		const Type = type.startsWith('log_') ? CUSTOMIZERS.log : CUSTOMIZERS[type]; // support log_[id] types
		if (!Type) {
			throw Error(`No type customizer for '${type}'`);
		}
		return new Type(reportData, type);
	}
}

module.exports = AllCustomizers;
