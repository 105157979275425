import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Trigger } from '../../api/relevant';
import OperationWrapper from '../OperationWrapper';
import SystemData from '../../lib/systemData';
import Base from '../../layouts/Base';
import { isAcked, isRecovered, isFailure } from './utils';
import { FailureModal, ConfirmActionModal } from './dialogues';
import { TriggerActions } from './actions';
import ReactUtils from '../../lib/reactUtils';
import TriggerWorkspaceLayout from './triggerWorkspaceLayout';
import { stores } from '../../stores';

class TriggerWorkspace extends React.Component {
	constructor(props) {
		super(props);
		Object.assign(this, {
			state: {
				selectedTriggers: [],
				selectedChecks: [],
				checks: [],
				triggers: [],
				failedSysTriggerInfos: [],
				checkSortOrder: ['status', 'trigger', 'identifier'],
				showUnacknowledged: true,
				showAcknowledged: true,
			},
			fld: ReactUtils.fld(this),
			lastExecStatus: {},
			myUserId: stores.identity.userId(),
			isAdmin: stores.identity.isAdministrator(),
		});
	}

	getOwnership(trigger) {
		const { otherUserIdToData } = this.state;
		let data;
		if (!trigger.userId) {
			data = { type: 'global' };
		} else if (trigger.userId === this.myUserId) {
			data = { type: 'my' };
		} else {
			data = { type: 'shared', otherUserData: otherUserIdToData[trigger.userId] };
		}
		return { ...data, trigger };
	}

	getVisibleChecks() {
		const {
			checks, showAcknowledged, showUnacknowledged, showRecovered, checkSortOrder,
		} = this.state;
		const filtered = checks.filter((check) => {
			if (!this.triggerOf(check)) {
				return false;
			}
			if (!showUnacknowledged && isFailure(check) && !isAcked(check)) {
				return false;
			}
			if (!showRecovered && isRecovered(check)) {
				return false;
			}
			if (!showAcknowledged && isAcked(check)) {
				return false;
			}
			return true;
		});
		const prio = (check) => {
			if (isRecovered(check)) {
				return 2;
			}
			return isAcked(check) ? 1 : 0;
		};
		const strCmp = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase());
		const cmpFuncs = {
			status: (c1, c2) => prio(c1) - prio(c2),
			trigger: (c1, c2) => strCmp(this.triggerOf(c1).name, this.triggerOf(c2).name),
			identifier: (c1, c2) => strCmp(c1.humanIdentifier, c2.humanIdentifier),
			date: (c1, c2) => new Date(c1.date).getTime() - new Date(c2.date).getTime(),
		};
		filtered.sort((c1, c2) => {
			for (const key of checkSortOrder) {
				const res = cmpFuncs[key](c1, c2);
				if (res) {
					return res;
				}
			}
			return 0;
		});
		return filtered;
	}

	initialLoadDone() {
		return !!this.state.initialLoadDone;
	}

	async load(extraState) {
		const { globalComponents } = this.props;
		const {
			checks,
			triggers,
			globalInfoChannel,
			userInfoChannel,
			numUnacknowledgedTriggerChecks,
			failedSysTriggerInfos,
			otherUserIdToData,
		} = await Trigger.call('getTriggerDataChunk');
		const convert = (selected, newAvail) => {
			const availById = _.keyBy(newAvail, 'id');
			return selected.filter(({ id }) => availById[id]).map(({ id }) => availById[id]);
		};
		const { selectedChecks, selectedTriggers } = this.state;
		checks.forEach((check) => (check.history || []).forEach((entry) => {
			entry.check = check;
		}));
		this.setState({
			checks,
			triggers,
			globalInfoChannel,
			userInfoChannel,
			failedSysTriggerInfos,
			otherUserIdToData,
			selectedChecks: convert(selectedChecks, checks),
			selectedTriggers: convert(selectedTriggers, triggers),
			initialLoadDone: true,
			...extraState,
		});
		// updates menu bar "balloon"
		SystemData.genericData.numUnacknowledgedTriggerChecks = numUnacknowledgedTriggerChecks;
		const { numErrorsBalloon } = globalComponents;
		if (numErrorsBalloon) {
			numErrorsBalloon.update();
		}
	}

	async runTriggers(params) {
		const { execStatus } = await Trigger.call('runTriggers', {
			noRetrigger: true,
			runInactive: true,
			...params,
		});
		this.lastExecStatus = execStatus;
		const failures = _.pickBy(execStatus, { ok: false });
		if (!_.isEmpty(failures)) {
			Base.renderGlobal((done) => (
				<FailureModal failures={failures} done={done} workspace={this} />
			));
		}
	}

	triggerOf(checkOrId) {
		const { checks, triggers } = this.state;
		const triggerById = (id) => _.find(triggers, { id });
		if (_.isString(checkOrId)) {
			let obj = triggerById(checkOrId);
			if (obj) {
				return obj;
			}
			obj = _.find(checks, { id: checkOrId });
			if (obj) {
				return triggerById(obj.triggerId);
			}
		}
		if (checkOrId.triggerId) {
			return triggerById(checkOrId.triggerId);
		}
		if (checkOrId.check && checkOrId.check.triggerId) {
			return triggerById(checkOrId.check.triggerId);
		}
		return null;
	}

	async performAction(action, matches) {
		const isTrigger = _.includes(TriggerActions, action);
		const triggers = _.filter(isTrigger ? matches : _.uniq(matches.map((m) => this.triggerOf(m))));
		const ok = action.noConfirm || (await Base.renderGlobal((closeFn) => (
			<ConfirmActionModal
				otherOwnersWarn={action.noSharedWarn ? [] : _.filter(
					triggers.map(
						(t) => this.getOwnership(t),
					),
					{ type: 'shared' },
				)}
				closeFn={closeFn}
				matches={matches}
				action={action}
				isTrigger={isTrigger}
			/>
		)));
		if (!ok) {
			return;
		}
		const perform = async () => {
			const { fn, multiFn } = action;
			if (multiFn) {
				await multiFn({
					[isTrigger ? 'triggers' : 'checks']: matches,
					workspace: this,
				});
			} else {
				for (const elm of matches) {
					await fn({
						[isTrigger ? 'trigger' : 'check']: elm,
						workspace: this,
					});
				}
			}
		};
		if (action.noReload) {
			perform();
		} else {
			await this.op.reload(async () => {
				await perform();
				await this.load();
			});
		}
	}

	renderContent() {
		const {
			triggers, globalInfoChannel, userInfoChannel, checks, selectedChecks,
			selectedTriggers, failedSysTriggerInfos,
		} = this.state;
		return (
			<TriggerWorkspaceLayout
				workspace={this}
				triggers={triggers}
				globalInfoChannel={globalInfoChannel}
				userInfoChannel={userInfoChannel}
				checks={checks}
				selectedChecks={selectedChecks}
				selectedTriggers={selectedTriggers}
				onSelectionChanged={(newState) => this.setState(newState)}
				failedSysTriggerInfos={failedSysTriggerInfos}
			/>
		);
	}

	render() {
		return (
			<OperationWrapper
				style={{ width: '100%' }}
				disableMode
				fn={(op) => {
					this.op = op;
					return this.load();
				}}
			>
				{this.renderContent()}
			</OperationWrapper>
		);
	}
}

TriggerWorkspace.propTypes = {
	globalComponents: PropTypes.object.isRequired,
};

TriggerWorkspace.defaultProps = {
};

export default TriggerWorkspace;
