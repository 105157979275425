import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-chartjs-2';

class LineChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div>
				<Line data={this.props.data} options={this.props.options} />
			</div>
		);
	}
}

LineChart.propTypes = {
	data: PropTypes.object.isRequired,
	options: PropTypes.object,
};

LineChart.defaultProps = {
	options: {
		plugins: {
			tooltip: {
				position: 'nearest',
				mode: 'x',
				intersect: false,
			},
		},
		hover: {
			mode: 'x',
			intersect: false,
		},
		displayLegend: true,
	},
};

export default LineChart;
