import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DataTable from '../DataTable';
import Checkbox from '../Checkbox';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import ExpandSelector from '../ExpandSelector';
import StatisticDialog from './statisticDialog';
import JobButton from '../JobButton';
import Spinner from '../Spinner';
import ApproveSettings from '../SegmentList/approveSettings';
import Report from '../Report';
import ExternalDataImport from '../ExternalDataImport';

const OWN_SETTINGS = ['hideFromPublic', 'noPubRevenue'];

class Filter {
	constructor(parent, path, obj, editor) {
		Object.assign(this, obj);
		this.editor = editor;
		this.field = editor.props.field;
		this.path = path;
		if (!this.filters && this.filter) {
			this.filters = [this.filter];
			this.singleFilter = true;
		} else {
			this.filters = this.filters || [];
		}
		this.parent = parent;
		for (let i = 0; i < this.filters.length; i++) {
			const subPath = this.singleFilter ? `${path}.filter` : `${path}.filters[${i}]`;
			this.filters[i] = new Filter(this, subPath, this.filters[i], editor);
		}
		this.renderFunctions = {
			or: this.renderOr,
			and: this.renderAnd,
		};
	}

	toJSON() {
		const exclude = ['editor', 'field', 'path', 'renderFunctions', 'parent', 'singleFilter'];
		if (!this.filters.length || this.singleFilter) {
			exclude.push('filters');
		}
		const res = _.pickBy(this, (val, key) => !_.includes(exclude, key));
		if (this.singleFilter) {
			res.filter = this.filters[0];
		}
		return res;
	}

	renderItem(item, nr) {
		return (
			<div style={{ fontWeight: 'bold' }} key={`_${nr}_${item}`}>
				{(() => {
					if (this.type === 'segment') {
						return this.editor.renderSegmentLink(item);
					}
					return item;
				})()}
			</div>
		);
	}

	renderGeneric() {
		const specificFn = this.renderFunctions[this.type];
		if (specificFn) {
			return specificFn.call(this);
		}
		const textVar = (name, label) => (this[name]
			&& (
				<div style={{ margin: '10px' }} key={name}>
					<span style={{ fontWeight: 'bold' }}>{`${label}: `}</span>
					<span>{this[name]}</span>
				</div>
			)
		);
		const res = (
			<div key="genericRoot">
				{textVar('type', 'Type')}
				{textVar('group', 'Group')}
				<Card style={{ marginLeft: '20px' }} key="items">
					{(this.items || []).map((item, i) => this.renderItem(item, i))}
				</Card>
			</div>
		);
		const parts = [res];
		return parts.concat(this.filters.map((filter) => filter.render()));
	}

	renderComposition(compositor) {
		const parts = [];
		for (let i = 0; i < this.filters.length; i++) {
			if (i) {
				parts.push(<div key={`composor_${i}`}>{compositor}</div>);
			}
			parts.push(this.filters[i].render());
		}
		return parts;
	}

	renderAnd() {
		return this.renderComposition(
			<Box component="strong" color="success.main">
				AND
			</Box>,
		);
	}

	renderOr() {
		return this.renderComposition(
			<Box component="strong" color="error.main">
				OR
			</Box>,
		);
	}

	render() {
		return (
			<Card style={{ paddingLeft: '20px' }} key={this.path || 'rootFilter'}>
				<CardContent>
					<StatisticDialog segment={this.editor.props.model} path={this.path} />
					{this.renderGeneric()}
				</CardContent>
			</Card>
		);
	}
}

class SegmentEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.segByDmpId = _.keyBy(props.segments, 'dmpId');
		const segment = props.model;
		if (segment.type === 'traffic') {
			const { filters } = segment;
			if (!filters || filters.length !== 1 || (!filters[0].filter && !segment.isLookalike)) {
				throw Error('The segment filters seems wrong somehow..');
			}
			this.rootFilter = new Filter(null, 'filters[0].filter', filters[0].filter || {}, this);
		}
	}

	render() {
		const {
			model, segments, history, field,
		} = this.props;

		const goBack = () => (history.length > 2 ? history.goBack() : history.push('/settings/segment-list'));
		const save = () => Object.assign(model, this.getSegmentSettings(), _.pick(model, OWN_SETTINGS)).update();

		return (
			<>
				<Grid item xs={5}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<Typography variant="h2">
										{model.name}
									</Typography>
									<ApproveSettings
										segments={segments}
										segment={model}
										fnTarget={this}
									/>
								</CardContent>
							</Card>
						</Grid>
						<Grid item>
							<JobButton
								label="Ok"
								color="primary"
								fn={async () => {
									await save();
									goBack();
								}}
							/>
						</Grid>
						<Grid item>
							<JobButton
								label="Apply"
								color="primary"
								fn={save}
							/>
						</Grid>
						<Grid item>
							<ActionButton
								label="Cancel"
								color="primary"
								onClick={goBack}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={7}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Report
								title="Revenue last 30 days"
								start={-30}
								end={-1}
								whereIn={{ segmentDmpId: [model.dmpId] }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ExpandSelector
								title="Users and filters"
							>
								<StatisticDialog segment={model} />
								{this.rootFilter && !model.isLookalike && this.rootFilter.render()}
								{model.isLookalike && (
									<p>
										<b>Base segment:</b>
										{' '}
										{this.renderSegmentLink(model.lookalikeTargetSegmentId)}
									</p>
								)}
							</ExpandSelector>
						</Grid>
						<Grid item xs={12}>
							<ExpandSelector
								title="Re-import revenue shares"
							>
								<ExternalDataImport
									model={model}
									dataCols={[{ key: 'totalRevenue', title: 'Rev shares calculated' }]}
									importFn={(__, from, to) => model.updateMyRevShares({ from, to, recalculate: true })}
								/>
							</ExpandSelector>
						</Grid>
						<Grid item xs={12}>
							<ExpandSelector
								title="Raw JSON"
							>
								<pre>
									{JSON.stringify(model.filters, null, 2)}
								</pre>
							</ExpandSelector>
						</Grid>
						<Grid item xs={12}>
							<ExpandSelector
								title="Other settings"
							>
								<Checkbox
									label="Hide from public users"
									{...field('hideFromPublic')}
								/>
								<Checkbox
									label="Disable publisher revenue"
									{...field('noPubRevenue')}
								/>
							</ExpandSelector>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	}

	renderSegmentLink(dmpSegId) {
		const { history } = this.props;
		const seg = this.segByDmpId[dmpSegId];
		if (!seg) {
			return `UNKNOWN (id: ${dmpSegId})`;
		}
		const lnk = `/settings/segments/${seg.id}`;
		return <a href={lnk} onClick={() => history.push(lnk)}>{seg.name}</a>;
	}
}

SegmentEdit.propTypes = {
	model: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	segments: PropTypes.array.isRequired,
	field: PropTypes.func.isRequired,
};

SegmentEdit.defaultProps = {

};

export default SegmentEdit;
