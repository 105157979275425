import _ from 'lodash';
import { normalize, normalizedPaths } from 'relevant-shared/misc/tagUtils';
import MiscUtils from '../../lib/miscUtils';
import classes from '../../api/classes';

const { SspPlacement, Placement } = classes;

const asWildcards = (placIds) => {
	if (placIds.length < 2) {
		return placIds;
	}
	const infos = placIds.map((id) => ({ id, lower: id.toLowerCase() }));
	const sorted = _.sortBy(infos, ({ id }) => id.length);
	sorted.forEach((info, idx) => {
		for (let i = 0; i < idx; i += 1) {
			const other = sorted[i];
			if (!other.skip && info.lower.startsWith(other.lower)) {
				other.widcard = true;
				info.skip = true;
			}
		}
	});
	const res = sorted.reduce((acc, curr) => {
		if (!curr.skip) {
			acc.push(curr.widcard ? `${curr.id}*` : curr.id);
		}
		return acc;
	}, []);
	return res;
};

const getPlacInfo = (plac, {
	publisherNode,
	placFieldAppend,
	placCache,
	useWildcards,
	otherPlacementsName,
	onlyOther,
	targetSsp,
}) => {
	const { sspsById } = publisherNode;
	const { adserver, adserverSettings } = plac;
	const { correspondingSspId, placIdField } = adserver || {};
	const sspId = targetSsp || correspondingSspId;
	const ssp = sspId ? sspsById[sspId] : null;
	if (!ssp || !adserverSettings || !placIdField) {
		return null;
	}
	if (otherPlacementsName && plac.name.includes(otherPlacementsName)) {
		return null; // Let's skip placements that "seems" to be 'other traffic' placements
	}
	// For *other* SSPs, only add placements if they've already been added (probably with id '0')
	const isOtherSsp = sspId !== correspondingSspId;
	if (isOtherSsp && !_.find(plac.obj.ssps, { source: sspId })) {
		return null;
	}
	let fldAppend;
	if (placFieldAppend) {
		const { id } = plac;
		placCache[id] = placCache[id] || plac.genericPlacementData();
		fldAppend = placCache[id][placFieldAppend];
	}
	let placIds = normalizedPaths(adserverSettings[placIdField], true, true);
	if (useWildcards) {
		placIds = asWildcards(placIds);
	}
	let otherPlacIds = [];
	if (fldAppend) {
		if (otherPlacementsName) {
			otherPlacIds = placIds;
		}
		placIds = onlyOther ? [] : placIds.map((id) => `${id}/${fldAppend.toLowerCase()}`);
	}
	return {
		otherPlacIds,
		placIds,
		sspId,
		correspondingSspId,
		isOtherSsp,
	};
};

const getSyncUpdates = (params) => {
	const {
		publisherNode, onlyCheckLocal, otherPlacementsName, includePublisher, includeSite,
	} = params;
	const placAdds = [];
	const makeExistingMap = (node) => _.mapValues(_.groupBy(node.byType.SspPlacementNode, 'sspId'), (arr) => (
		_.keyBy(arr, ((b) => normalize(b.sspPlacementId)))
	));
	let existingMap = onlyCheckLocal ? null : makeExistingMap(publisherNode);
	publisherNode.byType.PlacementNode.forEach((plac) => {
		existingMap = onlyCheckLocal ? makeExistingMap(plac) : existingMap;
		const {
			placIds = [],
			otherPlacIds = [],
			sspId,
			isOtherSsp,
		} = getPlacInfo(plac, params) || {};
		[placIds, otherPlacIds].forEach((ids) => {
			ids.forEach((placId) => {
				const norm = normalize(placId);
				if (!existingMap[sspId]?.[norm]) {
					const isOther = ids === otherPlacIds;
					let placName = plac.name;
					if (isOther) {
						placName = _.filter([
							includePublisher && plac.publisherNode.name,
							includeSite && plac.parentNode.name,
							otherPlacementsName,
						]).join(' - ');
					}
					placAdds.push({
						newSspPlac: new SspPlacement({
							source: sspId,
							id: placId,
							_id: MiscUtils.objectIdStr(), // Special case as ssp-placements have *different* id/_id
							pbCfgs: [],
						}),
						key: `${plac.id}_${sspId}_${placId}_${isOther}`,
						plac,
						isOther,
						isOtherSsp, // true => not SSP corresponding to ad server
						placName,
					});
					if (!onlyCheckLocal) {
						existingMap[sspId] = existingMap[sspId] || {};
						existingMap[sspId][norm] = true;
					}
				}
			});
		});
	});
	return placAdds;
};

const addUpdate = ({
	plac, newSspPlac, isOther, placName, isOtherSsp,
}) => {
	if (!isOther) {
		plac.obj.ssps.push(newSspPlac);
		return;
	}
	const site = plac.parentNode.obj;
	let otherPlac = _.find(site.placements, { name: placName });
	const { adserver } = plac;
	const { placIdField } = adserver;
	if (!otherPlac) {
		otherPlac = new Placement({
			name: placName,
			adservers: [],
		});
		site.placements.push(otherPlac);
	}
	otherPlac.ssps.push(newSspPlac);
	if (!isOtherSsp) {
		if (_.isEmpty(otherPlac.adservers)) {
			otherPlac.adservers = [{
				adserverId: adserver.id,
				settings: {},
			}];
		}
		if (otherPlac.adservers[0]?.adserverId === adserver.id) {
			const { settings } = otherPlac.adservers[0];
			if (settings) {
				const existing = normalizedPaths(settings[placIdField]);
				if (!existing.includes(normalize(newSspPlac.id))) {
					settings[placIdField] = settings[placIdField] ? `${settings[placIdField]}\n` : '';
					settings[placIdField] += newSspPlac.id;
				}
			}
		}
	}
};

export {
	getSyncUpdates,
	addUpdate,
};
