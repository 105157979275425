import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import CheckedIcon from '@mui/icons-material/Check';
import UnCheckedIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import DoneIcon from '@mui/icons-material/Done';
import PublicIcon from '@mui/icons-material/Public';
import ShareIcon from '@mui/icons-material/Share';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Icon from '../Icon';
import Base from '../../layouts/Base';
import SystemData from '../../lib/systemData';
import { MenuErrorBalloon, Scope } from '../Wrappers';
import PopupSelector from '../PopupSelector';
import { LINK_DEFAULT_STYLE } from './constants';
import { listOfAdministrators } from '../../api/relevant';
import CheckboxGroup from '../CheckboxGroup';

export const isAcked = ({ ackState }) => ackState !== 'none';
export const isRecovered = ({ status }) => status === 'recovered';
export const isFailure = ({ status }) => status === 'failed';

export const fmtDate = (date) => moment(date).format('YYYY-MM-DD HH:mm');

export const renderStatus = ({ status, message, ackState }) => {
	const options = {
		err: {
			pic: ErrorIcon,
			style: { color: 'red' },
		},
		ack: {
			pic: AlarmOffIcon,
			style: { color: 'orange' },
		},
		ok: {
			pic: AlarmOffIcon,
			style: { color: 'green' },
		},
	};
	let type;
	if (status === 'failed') {
		type = options[ackState && isAcked({ ackState }) ? 'ack' : 'err'];
	} else {
		type = options.ok;
	}
	return <Icon {...({ ...type, tooltip: message })} />;
};

export const renderAckErrString = (checks) => {
	const errors = _.filter(checks, { status: 'failed' });
	if (!errors.length) {
		return (<span style={{ color: 'green' }}>ALL OK</span>);
	}
	return (
		<span>
			<span style={{ color: 'orange' }}>
				{errors.filter((e) => e.ackState !== 'none').length}
			</span>
			/
			<span style={{ color: 'red' }}>{errors.length}</span>
		</span>
	);
};

export const execStatusCol = ({ workspace }) => {
	const { lastExecStatus } = workspace;
	return {
		key: 'id',
		title: 'Check run',
		padding: 'none',
		style: { width: '100px' },
		hide: _.isEmpty(lastExecStatus),
		format: (id) => {
			const trigger = workspace.triggerOf(id);
			const status = lastExecStatus[trigger.id];
			if (!status) {
				return null;
			}
			return (
				<div>
					<Icon
						pic={status.ok ? DoneIcon : ErrorIcon}
						color={status.ok ? 'action' : 'error'}
						tooltip={status.errorMsg || 'Check performed'}
					/>
				</div>
			);
		},
	};
};

export const checkboxOf = (val) => (val ? <span><CheckedIcon /></span> : <span><UnCheckedIcon /></span>);

export function OwnershipIcon({ type, otherUserData, trigger }) {
	const numShared = trigger.sharedWithUserIds.length;
	if (type === 'shared') {
		return (
			<Icon
				pic={SupervisorAccountIcon}
				tooltip={`${otherUserData.fullname} (${otherUserData.email})`}
			/>
		);
	}
	if (type === 'global') {
		return (
			<Icon
				pic={PublicIcon}
				tooltip="Global"
			/>
		);
	}
	if (numShared) {
		return (
			<Icon
				pic={SupervisorAccountIcon}
				tooltip={`Me (shared with ${numShared} other user${numShared > 1 ? 's' : ''})`}
			/>
		);
	}
	return null;
}

export function ShareSelector({
	workspace, model, useButtonStyle, onSave,
}) {
	return (
		<Scope
			selected={model.sharedWithUserIds || []}
			content={(scope, { selected }) => (
				<PopupSelector
					linkStyle={LINK_DEFAULT_STYLE}
					title="Share"
					selected={selected}
					size="sm"
					fn={listOfAdministrators}
					onApplyChanges={async () => {
						if (_.isEqual(model.sharedWithUserIds, selected)) {
							return; // nothing to do
						}
						model.sharedWithUserIds = selected;
						if (onSave) {
							await onSave();
						}
						scope.update();
					}}
					onCancel={() => scope.setState({
						selected: model.sharedWithUserIds || [],
					})}
					content={(popup, { result: admins }) => (
						<CheckboxGroup
							items={_.reject(admins, { id: workspace.myUserId })
								.map(({
									id, firstname, lastname, email,
								}) => ({
									id,
									text: `${firstname} ${lastname} (${email})`,
								}))}
							selected={selected}
							onChange={(items) => scope.setState({ selected: items })}
						/>
					)}
					customLink={!useButtonStyle ? undefined : (showFn) => (
						<Icon
							tooltip={_.isEmpty(model.sharedWithUserIds)
								? 'Click to share'
								: `Shared with ${model.sharedWithUserIds.length} other user(s)`}
							pic={ShareIcon}
							color={_.isEmpty(model.sharedWithUserIds) ? 'disabled' : 'primary'}
							onClick={showFn}
						/>
					)}
				/>
			)}
		/>
	);
}

ShareSelector.propTypes = {
	workspace: PropTypes.object.isRequired,
	model: PropTypes.object.isRequired,
	useButtonStyle: PropTypes.bool,
	onSave: PropTypes.func,
};

ShareSelector.defaultProps = {
	useButtonStyle: false,
	onSave: undefined,
};
