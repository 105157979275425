import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import CheckedIcon from '@mui/icons-material/Check';
import UnCheckedIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DocumentTemplate } from '../../api/relevant';
import { ActionButton } from '../ActionButton/ActionButton';
import DataTable from '../DataTable';
import RecordTableTools from '../RecordTableTools';
import OperationWrapper from '../OperationWrapper';

class DocumentTemplateList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { types } = this.props;
		const { list = [] } = this.state;
		const load = async () => this.setState({ list: await DocumentTemplate.list() });
		return (
			<Grid item xs={12}>
				<OperationWrapper fn={async (op) => {
					this.op = op;
					await load();
				}}
				>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Paper>
								<Box padding={2}>
									<Typography variant="h2">
										Templates
									</Typography>
									<DataTable
										showCheckboxes={false}
										identifier={(row) => row.id}
										definitions={[
											{ key: 'name', title: 'Name' },
											{
												key: 'type',
												title: 'Type',
												format: (type) => (types.find((t) => t.name === type) || {}).description,
											},
											{
												key: 'id',
												title: 'Is in use',
												format: (id, tpl) => (tpl.refsRecorded ? <span><CheckedIcon /></span> : <span><UnCheckedIcon /></span>),
											},
											{
												key: 'id',
												title: '',
												align: 'right',
												format: (id, tpl) => (
													<RecordTableTools
														editHref={`/settings/doc-templates/${id}`}
														deleteAction={tpl.refsRecorded ? null : (() => this.op.reload(async () => {
															await tpl.delete();
															await load();
														}))}
													/>
												),
											},
										]}
										data={_.sortBy(list, 'name')}
									/>
								</Box>
							</Paper>
						</Grid>
						<Grid item>
							<ActionButton
								label="Add"
								href="/settings/doc-templates/add"
								icon={<AddIcon />}
								color="primary"
							/>
						</Grid>
					</Grid>
				</OperationWrapper>
			</Grid>
		);
	}
}

DocumentTemplateList.propTypes = {
	types: PropTypes.array.isRequired,
};

DocumentTemplateList.defaultProps = {
};

export default DocumentTemplateList;
