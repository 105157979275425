import React from 'react';
import {
	styled, Box, Typography,
} from '@mui/material';

import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import Form from '../../containers/Form';
import { RecoverPassword } from '../../pages/SimplePages';

const StyledDiv = styled('div')(({
	theme: { spacing },
}) => ({
	display: 'flex',
	marginBottom: spacing(2),
}));
@Form
class PasswordForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = { displayRecoverPassword: false };
	}

	displayRecoverPassword = () => {
		const { displayRecoverPassword } = this.state;
		this.setState({
			displayRecoverPassword: !displayRecoverPassword,
		});
		console.log(this.state);
	};

	renderRecoverButtton() {
		return (
			<ActionButton label="Forgot password?" onClick={() => this.displayRecoverPassword()} />
		);
	}

	render() {
		const {
			field, value, submit, identity,
		} = this.props;
		const recoverEmail = identity?.email();
		const { displayRecoverPassword } = this.state;
		return (
			<Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
				<Typography variant="h6" sx={{ color: 'text.sub', mb: 3 }}>
					Change password
				</Typography>
				<StyledDiv>
					<Box sx={{ mr: 2 }}>
						<TextField
							{...field('password')}
							label="Your password"
							sx={{ mb: 1 }}
							password
							required
						/>
					</Box>
					<TextField
						{...field('repeated')}
						label="Repeat your password"
						password
						match={{ pattern: value('password'), message: 'Passwords must match.' }}
						required
					/>

				</StyledDiv>
				<StyledDiv>
					<Box sx={{ mr: 2 }}>
						<TextField
							{...field('new')}
							label="New password"
							password
							required
						/>
					</Box>
					<TextField
						{...field('newRepeated')}
						label="Repeat new password"
						password
						match={{ pattern: value('new'), message: 'Passwords must match.' }}
						required
					/>
				</StyledDiv>
				<Box sx={{ mt: 0.5, mb: 3 }}>
					<ActionButton
						label="Save"
						color="primary"
						variant="outlined"
						onClick={() => submit()}
					/>
				</Box>
				<Box sx={{ width: '100%', mb: 3 }}>
					{(displayRecoverPassword && <RecoverPassword disabled recoverEmail={recoverEmail} />)}
					{!displayRecoverPassword && this.renderRecoverButtton()}
				</Box>
			</Box>
		);
	}
}

export default PasswordForm;
