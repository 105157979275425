import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Animation from '../../lib/animation';

const ENTER_EVENS = ['onTouchStart', 'onTouchMove', 'onMouseEnter'];
const EXIT_EVENS = ['onTouchEnd', 'onTouchCancel', 'onMouseOut', 'onMouseLeave'];

const DEFAULT_DURATION = 500;

export class Effect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.listeners = {};
		if (props.startOnOver) {
			Object.assign(this.listeners, _.zipObject(ENTER_EVENS, Array(ENTER_EVENS.length).fill(() => this.onEnter())));
			if (!props.backForth) {
				Object.assign(this.listeners, _.zipObject(EXIT_EVENS, Array(EXIT_EVENS.length).fill(() => this.onExit())));
			}
		}
	}

	onEnter() {
		const { backForth } = this.props;
		this.animation.toMax(() => {
			if (backForth) {
				this.onExit();
			}
		});
	}

	onExit() {
		this.animation.toMin();
	}

	callAnimate(val) {
		const { animate } = this.props;
		this.lastVal = val;
		if (this.elm) {
			animate(this.elm, val, this);
		}
	}

	init() {
		const {
			duration, startOnLoad, loadTimeout, initVal, minVal, maxVal,
		} = this.props;
		if (!this.elm) {
			return;
		}
		const oldAnim = this.animation;
		this.animation = new Animation(initVal, minVal, maxVal, duration || DEFAULT_DURATION, this.callAnimate.bind(this));
		if (startOnLoad && !oldAnim) {
			setTimeout(() => this.onEnter(), loadTimeout);
		}
	}

	render() {
		const { style } = this.props;
		return (
			<div
				style={style}
				ref={(elm) => {
					this.elm = elm;
					if (!this.animation) {
						this.init();
					} else if (elm && _.isNumber(this.lastVal)) {
						this.callAnimate(this.lastVal);
					}
				}}
				{...this.listeners}
			>
				{this.props.children}
			</div>
		);
	}
}

Effect.propTypes = {
	duration: PropTypes.number,
	animate: PropTypes.func.isRequired,
	startOnLoad: PropTypes.bool,
	startOnOver: PropTypes.bool,
	backForth: PropTypes.bool,
	loadTimeout: PropTypes.number,
	initVal: PropTypes.number,
	minVal: PropTypes.number,
	maxVal: PropTypes.number,
	style: PropTypes.object,
};

Effect.genericProps = ['duration', 'startOnLoad', 'startOnOver', 'backForth'];

Effect.defaultProps = {
	duration: DEFAULT_DURATION,
	startOnLoad: false,
	startOnOver: false,
	backForth: false,
	loadTimeout: 400,
	initVal: 0,
	minVal: 0,
	maxVal: 1,
	style: undefined,
};

const SHAKE_STEPS = [-32, 64, -48, 16];// 32, -24, 16, -12, 4];
const SHAKE_DEG_MULTIPLIER = 90 / _.max(SHAKE_STEPS);

const SHAKER_DEFAULTS = {
	scale: {
		min: 1,
		max: 0.8,
		timeStart: 0,
		timeStop: 0.3,
	},
	shake: {
		min: 0,
		max: _.sumBy(SHAKE_STEPS.map((v) => Math.abs(v))),
		timeStart: 0.1,
		timeStop: 1,
		maxDegree: 20,
	},
};

export function Shaker(props) {
	return (
		<Effect
			{..._.pick(props, Effect.genericProps)}
			animate={(elm, val) => {
				const settings = _.merge({}, SHAKER_DEFAULTS, props.settings);
				if ((val === 0 && settings.scale.min === 1) || (val === 1 && settings.scale.max === 1)) {
					Object.assign(elm.style, { transform: '', ...props.extraStyle });
					return;
				}
				const calc = ({
					timeStart, timeStop, min, max,
				}) => {
					let factor;
					if (val <= timeStart) {
						factor = 0;
					} else if (val >= timeStop) {
						factor = 1;
					} else {
						factor = (val - timeStart) / (timeStop - timeStart);
					}
					return min + (factor * (max - min));
				};
				const scale = calc(settings.scale);
				const shakeStep = calc(settings.shake);
				let currentDeg = 0;
				let currentAbs = 0;
				let shakeDeg = 0;
				for (const step of SHAKE_STEPS) {
					const next = Math.abs(step);
					const nextAbs = currentAbs + next;
					if (nextAbs >= shakeStep) {
						shakeDeg = currentDeg + (((shakeStep - currentAbs) / next) * step);
						shakeDeg *= SHAKE_DEG_MULTIPLIER;
						shakeDeg *= settings.shake.maxDegree / 90;
						break;
					}
					currentDeg += step;
					currentAbs = nextAbs;
				}
				const transform = `rotate(${shakeDeg}deg) scale(${scale})`;
				// console.info(`${new Date().getTime()}: shakeStep(${shakeStep}) transform - ${transform}`);
				elm.style.transform = transform;
				Object.assign(elm.style, { transform, ...props.extraStyle });
			}}
		>
			{props.children}
		</Effect>
	);
}

Shaker.propTypes = Object.assign(_.pick(Effect.propTypes, Effect.genericProps), {
	settings: PropTypes.object,
	extraStyle: PropTypes.object,
});

Shaker.defaultProps = Object.assign(_.pick(Effect.defaultProps, Effect.genericProps), {
	settings: undefined,
	extraStyle: undefined,
});

Shaker.of = (settings) => function ({ children }) {
	return <Shaker {...settings}>{children}</Shaker>;
};

export const ShakeIn = Shaker.of({
	startOnLoad: true,
	settings: {
		scale: { min: 0.1, max: 1 },
	},
});
export const LoadBubble = Shaker.of({
	startOnLoad: true,
	backForth: true,
	duration: 200,
	settings: {
		scale: { min: 1, max: 1.1, timeStop: 1 },
		shake: { maxDegree: 0 },
	},
});
export const LoadMoveDown = Shaker.of({
	startOnLoad: true,
	duration: 200,
	extraStyle: { transformOrigin: 'top' },
	settings: {
		scale: { min: 0, max: 1, timeStop: 1 },
		shake: { maxDegree: 0 },
	},
});
