import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import GhostingButton from '../GhostingButton';
import UsersButton from '../UsersButton';
import { ConfirmDialog } from '../ConfirmDialog';

function RecordTableTools(props) {
	const ConfirmDelete = (
		<ConfirmDialog
			title="Danger"
			text="Are you sure you want to delete this item?"
			onConfirm={props.deleteAction}
		>
			<DeleteButton />
		</ConfirmDialog>
	);
	const DeleteBtn = props.noConfirmDelete ? <DeleteButton onClick={props.deleteAction} /> : ConfirmDelete;

	return (
		<Box whiteSpace="nowrap">
			{props.ghostAction ? <GhostingButton onClick={props.ghostAction} /> : null}
			{props.userListHref ? <UsersButton href={props.userListHref} /> : null}
			{props.editHref ? <EditButton href={props.editHref} /> : null}
			{props.editAction ? <EditButton onClick={props.editAction} /> : null}
			{props.deleteAction && DeleteBtn}
		</Box>
	);
}

RecordTableTools.propTypes = {
	editHref: PropTypes.string,
	editAction: PropTypes.func,
	userListHref: PropTypes.string,
	deleteAction: PropTypes.func,
	ghostAction: PropTypes.func,
	noConfirmDelete: PropTypes.bool,
};

RecordTableTools.defaultProps = {
	editHref: undefined,
	editAction: undefined,
	userListHref: undefined,
	deleteAction: undefined,
	ghostAction: undefined,
	noConfirmDelete: false,
};

export default RecordTableTools;
