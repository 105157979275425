import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import MuiDatePicker from '@mui/lab/DatePicker';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import DateUtils from 'relevant-shared/misc/dateUtils';
import PopupSelector from '../PopupSelector';

class DatePicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isOpen: false };
		this._change = this.change.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.toggleOpen = this.toggleOpen.bind(this);
	}

	change(value) {
		const date = value ? value.toDate() : value;
		const { onChange, name } = this.props;
		onChange({ target: { name, value: date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null } }, this.props);
	}

	componentWillUnmount() {
		this.stopListen?.();
	}

	handleClear(event) {
		event.stopPropagation();
		this.change();
	}

	toggleOpen(isOpen) {
		// PopupSelector is doing ev.stopPropagation() for clicks - which stop's DatePicker's backdrop click events.
		// To make it possible to close the date-picker we therefore need to listen for clicks from the PopupSelector's
		// main div instead
		const popupDiv = PopupSelector.getPopupDiv();
		if (isOpen && popupDiv && !this.stopListen) {
			const clickListener = () => {
				this.stopListen?.();
				this.setState({ isOpen: false });
			};
			popupDiv.addEventListener('click', clickListener);
			this.stopListen = () => {
				popupDiv.removeEventListener('click', clickListener);
				this.stopListen = null;
			};
		} else if (!isOpen) {
			this.stopListen?.();
		}
		this.setState({ isOpen });
	}

	render() {
		const {
			name, help, value, canClear, autoOk, floatingLabelText, minDate, maxDate, style, disabled, margin, fullWidth,
		} = this.props;
		const { isOpen } = this.state;
		return (
			<MuiDatePicker
				open={isOpen}
				onOpen={() => this.toggleOpen(true)}
				onClose={() => this.toggleOpen(false)}
				inputFormat="YYYY-MM-DD"
				mask="____-__-__"
				name={name}
				helperText={help}
				value={value ? DateUtils.toUiDate(value) : null}
				onChange={this._change}
				autoOk={autoOk}
				label={floatingLabelText}
				minDate={minDate ? DateUtils.toUiDate(minDate) : moment('1900-01-01')}
				maxDate={maxDate ? DateUtils.toUiDate(maxDate) : moment('2100-01-01')}
				style={style}
				disabled={disabled}
				margin={margin}
				fullWidth={fullWidth}
				renderInput={(params) => {
					// Date picker button
					const { endAdornment, ...restInputProps } = params.InputProps;
					// We don't allow user to edit date with keyboard
					// (for validation issues after updating to this new MUI datepicker)
					// However, would be great if we could in the future.
					return (
						<TextField
							onClick={() => this.toggleOpen(true)}
							onKeyDown={(e) => e.preventDefault()}
							{...params}
							InputProps={{
								endAdornment: (
									<>
										{ !canClear || !value ? null : (
											<Tooltip title="Clear input">
												<IconButton
													onClick={this.handleClear}
													size="small"
													aria-label="Clear input"
												>
													<ClearIcon />
												</IconButton>
											</Tooltip>
										)}
										{endAdornment}
									</>
								),
								...restInputProps,
							}}
						/>
					);
				}}
			/>
		);
	}
}

DatePicker.propTypes = {
	name: PropTypes.string.isRequired,
	help: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]),
	onChange: PropTypes.func,
	autoOk: PropTypes.bool,
	floatingLabelText: PropTypes.string,
	minDate: PropTypes.instanceOf(Date),
	maxDate: PropTypes.instanceOf(Date),
	canClear: PropTypes.bool,
	style: PropTypes.object,
	disabled: PropTypes.bool,
	margin: PropTypes.oneOf(['none', 'normal', 'dense']),
	fullWidth: PropTypes.bool,
};

DatePicker.defaultProps = {
	value: undefined,
	help: undefined,
	onChange: () => {},
	autoOk: false,
	floatingLabelText: undefined,
	minDate: undefined,
	maxDate: undefined,
	canClear: false,
	style: {},
	disabled: false,
	margin: undefined,
	fullWidth: false,
};

export default DatePicker;
