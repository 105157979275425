import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function Issue({
	header,
	description,
	detailedDescription,
	borderColor,
}) {
	return (
		<Box sx={{
			minWidth: 376,
			pb: 1,
			pt: 1,
			pl: 2,
			borderLeft: `3px solid ${borderColor}`,
		}}
		>
			<Box component="h4" sx={{ m: 0 }}>{header}</Box>
			{' '}
			<Box sx={{ color: 'text.secondary', maxWidth: 400 }}>
				{detailedDescription || description}
			</Box>
		</Box>
	);
}

Issue.propTypes = {
	header: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	borderColor: PropTypes.string.isRequired,
	detailedDescription: PropTypes.string,
};

Issue.defaultProps = {
	detailedDescription: null,
};
export default Issue;
