const _ = require('lodash');

const BULK_IMPORT_BASE_COLS = {
	publisherName: 'Publisher name',
	site: 'Site',
	placementName: 'Placement name',
	placementType: 'Placement type',
	adserver: 'Adserver',
	adserverPlacement: 'Adserver placement',
};

const validateSystemsEligable = ({ adservers, ssps, publishers }) => {
	[['adserver', adservers], ['SSP', ssps], ['publisher', publishers]].forEach(([desc, arr]) => {
		const tooMany = Object.values(_.groupBy(arr, (o) => o.name.toLowerCase())).find((a) => a.length > 1);
		if (tooMany) {
			throw Error(`\
There are ${tooMany.length} ${desc}s named '${tooMany[0].name}' \
please make sure that each ${desc} has a unique name (case insensitive) \
in order to use this functionality
`);
		}
	});
};

module.exports = {
	validateSystemsEligable,
	BULK_IMPORT_BASE_COLS,
};
