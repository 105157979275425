import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import uuid from 'uuid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import CheckedIcon from '@mui/icons-material/Check';
import { ActionButton } from '../ActionButton/ActionButton';
import UploadButton from '../UploadButton';
import OperationWrapper from '../OperationWrapper';
import { listOfPublishers } from '../../api/relevant';
import DataTable from '../DataTable';
import JobButton from '../JobButton';
import MiscUtils from '../../lib/miscUtils';

class SegmentUpdate {
	constructor(segment, siteIds) {
		Object.assign(this, {
			segment,
			siteIds,
			imported: false,
			name: segment.name,
		});
		this.segment = segment;
	}

	async import() {
		if (!this.needsUpdate) {
			await new Promise((r) => setTimeout(r));
		} else {
			const updated = this.updatedSegment;
			await updated.update();
			Object.assign(this.segment, updated);
		}
		this.imported = true;
	}

	get updatedSegment() {
		return {
			...this.segment,
			isApproved: true,
			revenueSites: this.siteIds,
			revShareMethod: 'whitelist',
		};
	}

	get needsUpdate() {
		return !_.isEqual(this.updatedSegment, this.segment);
	}
}

class MultiRevenueImport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.segByDmpId = _.keyBy(props.segments, 'dmpId');
	}

	async readUpdates(file) {
		const reader = new FileReader();
		const read = new Promise((resolve) => {
			reader.onload = (ev) => resolve(ev.target.result);
		});
		reader.readAsText(file);
		const txt = await read;
		let csvPubs;
		const unknownSegments = [];
		const allPubs = (await listOfPublishers()).result;
		const pubByName = _.keyBy(allPubs, 'name');
		const rows = MiscUtils.loadCsv(txt, (headers) => { /** Cols like ..., [empty], name1, name2, .., Provider2, ... */
			const CSV_COLS = {};
			const updateHeader = (nr, name) => { CSV_COLS[name] = headers[nr] = name; };
			const end = _.findIndex(headers, (h) => h.match(/^Provider\d+$/));
			const start = _.findLastIndex(headers.slice(0, end), (s) => !s) + 1;
			const names = headers.slice(start, end);
			csvPubs = _.zipObject(names, names);
			updateHeader(2, 'segmentName');
			updateHeader(4, 'dmpId');
			return { ...csvPubs, ...CSV_COLS };
		});
		rows.splice(0, 2); // skip first 2 rows that are not segments
		const data = [];
		rows.forEach((row) => {
			const segment = this.segByDmpId[row.dmpId];
			if (!segment) {
				unknownSegments.push(row.segmentName);
				return;
			}
			const sites = _.flatten(allPubs.filter((p) => row[p.name]).map((p) => p.cxDmpSites || []));
			if (!sites.length) {
				return;
			}
			data.push(new SegmentUpdate(segment, sites.map((s) => s.id)));
		});
		return {
			csvUnknown: Object.keys(csvPubs).filter((n) => !pubByName[n]),
			csvMissing: allPubs.map((p) => p.name).filter((n) => !csvPubs[n]),
			unknownSegments,
			data: MiscUtils.alphaSorted(data, 'name'),
		};
	}

	async runImport() {
		const updates = this.state.updates.data.filter((u) => !u.imported && u.needsUpdate);
		if (!updates.length) {
			throw Error('Nothing to import');
		}
		try {
			for (const update of updates) {
				await update.import();
				this.forceUpdate();
			}
		} finally {
			this.props.onSegmentsUpdated();
		}
	}

	render() {
		const { importFile, updates } = this.state;
		const close = () => this.setState({ importFile: null, updates: null });
		const numNeedsUpdate = updates ? _.sumBy(updates.data, (u) => (u.needsUpdate ? 1 : 0)) : 0;
		const numImported = updates ? _.sumBy(updates.data, (u) => (u.imported ? 1 : 0)) : 0;

		const NameList = (props) => (
			<>
				<Grid item xs={12}>
					<Typography variant="h4">
						{props.title}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TableContainer component={Paper}>
						<DataTable
							showCheckboxes={false}
							selectableRows={false}
							identifier={(row) => row.name}
							definitions={[
								{ key: 'name', title: 'Name' },
							]}
							data={_.uniq(props.names).map((n) => ({ name: n }))}
						/>
					</TableContainer>
				</Grid>
			</>
		);

		return (
			<>
				<UploadButton
					accept=".csv"
					onChange={(ev) => this.setState({ importFile: ev.target.files[0] })}
					color="secondary"
				>
					Import Revenue Settings from .csv
				</UploadButton>
				<OperationWrapper
					load={importFile || false}
					reloadable
					fn={async () => {
						this.setState({ updates: await this.readUpdates(this.state.importFile) });
					}}
				>
					{updates
						&& (
							<Dialog
								fullWidth
								open
								onClose={close}
							>
								<DialogTitle>
									Multi-import of revenue whitelists
								</DialogTitle>
								<DialogContent dividers>
									<Grid container spacing={3}>
										{updates.csvUnknown && <NameList title="Unknown publishers in .csv" names={updates.csvUnknown} />}
										{updates.csvMissing && <NameList title="Publishers missing in .csv" names={updates.csvMissing} />}
										{updates.unknownSegments && <NameList title="Unknown segments in .csv" names={updates.unknownSegments} />}
										<Grid item xs={12}>
											<Typography variant="h4">
												Segments to approve with whitelists (needs update:
												{' '}
												{numNeedsUpdate}
												/
												{updates.data.length}
												)
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<TableContainer component={Paper}>
												<DataTable
													showCheckboxes={false}
													selectableRows={false}
													identifier={(row) => row.name}
													definitions={[
														{
															key: 'imported',
															title: 'Imported',
															format: (imported) => imported && <Box component="span" color="success.main"><CheckedIcon /></Box>,
														},
														{ key: 'name', title: 'Name' },
														{
															key: 'siteIds',
															title: 'Numbers of site',
															format: (sites) => (<span>{sites.length}</span>),
														},
														{
															key: 'needsUpdate',
															title: 'Needs update',
															format: (needsUpdate) => needsUpdate && (<Box component="span" color="warning.main"><CheckedIcon /></Box>),
														},
													]}
													data={updates.data}
											/>
											</TableContainer>
										</Grid>
									</Grid>
								</DialogContent>
								<DialogActions>
									Completed&nbsp;
									<strong>
										{numImported}
										{' '}
										/
										{numNeedsUpdate}
									</strong>
								&nbsp;
									<JobButton
										label="Run import"
										fn={() => this.runImport()}
										color="primary"
									/>
								</DialogActions>
							</Dialog>
						)}
				</OperationWrapper>
			</>
		);
	}
}

MultiRevenueImport.propTypes = {
	segments: PropTypes.array.isRequired,
	onSegmentsUpdated: PropTypes.func,
};

MultiRevenueImport.defaultProps = {
	onSegmentsUpdated: () => {},
};

export default MultiRevenueImport;
