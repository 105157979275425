import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';

function TopBarMenu({
	children,
	icon,
	open,
	title,
	onClick,
	fontWeight,

}) {
	const anchorEl = useRef(null);
	const labelStyle = {
		fontWeight,
	};
	return (
		<>
			<IconButton
				color="offWhite"
				ref={anchorEl}
				aria-label={title}
				style={labelStyle}
				onClick={onClick}
			>
				{icon}
			</IconButton>
			<Menu
				open={open}
				onClose={onClick}
				anchorEl={anchorEl.current}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
			>
				{children}
			</Menu>
		</>
	);
}

TopBarMenu.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
	icon: PropTypes.node,
	fontWeight: PropTypes.string,
};

TopBarMenu.defaultProps = {
	icon: null,
	fontWeight: 'bold',
};

export default TopBarMenu;
