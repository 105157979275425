import React, {
	useEffect,
	useState,
	useCallback,
	useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
	Card,
	Box,
	IconButton,
	Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import useReports from '../../hooks/useReports';
import useTopBar from '../../hooks/useTopbar';
import ReportCard from './ReportCard';
import getReportPreviewImage from './utils';
import UnstyledLink from '../UnstyledLink';
import { Toast } from '../Toast/Toast';
import useFilters from './Filters/context/hooks/useFilters';
import { stores } from '../../stores';
import useLocalStorage from '../../hooks/useLocalStorage';
import classes from '../../api/classes';
import { maybeCopyToDashboard } from '../ReportTemplates/utils';

const { Reports } = classes;

function ReportsOverview({ type }) {
	const { reports, availableReportTypes, updateReports } = useReports();
	const { filteredReports, setReports: setFilterContextReports } = useFilters();
	const [storedSelectedReportType, setSelectedReportType] = useLocalStorage(
		'reportsOverviewSelectedReportType',
		null,
	);
	const [status, setStatus] = useState({ status: null, id: null });
	const {
		setSettings,
		setTitleNavigation,
	} = useTopBar();
	const history = useHistory();
	const currentUser = stores.identity.user();

	const [availTypes, selectedReportType] = useMemo(() => {
		const avail = availableReportTypes.filter(({ noSaveType }) => !noSaveType);
		const selected = avail.find(({ type: t }) => (
			// Support old stored settings where an object is stored instead of the type-name
			t === (storedSelectedReportType?.type || storedSelectedReportType)
		));
		return [avail, selected];
	}, [availableReportTypes, storedSelectedReportType]);

	// Set initially selected report type
	useEffect(() => {
		const preSelectedType = type && availTypes.find(({ type: t }) => t === type);
		if (availTypes?.length > 0) {
			if (preSelectedType) {
				setSelectedReportType(preSelectedType.type);
			} else if (selectedReportType) {
				// If no type is provided, redirect to cached type
				history.push(selectedReportType.path);
			} else { // or the first available report type if nothing is cached
				history.push(availTypes[0].path);
			}
		}
	}, [type, history, setSelectedReportType, availTypes, selectedReportType]);

	useEffect(() => {
		// Close the toast after 10 seconds if action is completed
		const timeout = setTimeout(() => {
			if (status.status === 'deleted') {
				setStatus({ status: null, id: null });
			}
		}, 10000);
		return () => clearTimeout(timeout);
	}, [status]);

	const onChange = useCallback((path) => {
		history.push(path);
	}, [history]);

	useEffect(() => {
		if (selectedReportType?.label) {
			setSettings({
				type: 'reportsOverview',
				addLink: `${selectedReportType?.path}/add`,
				reportIds: filteredReports.map(({ id }) => id),
				allReportsArePinned: filteredReports
					.every(({ pinnedByUsers }) => pinnedByUsers.includes(currentUser.id)),
			});
			setTitleNavigation({
				label: selectedReportType?.label,
				options: availableReportTypes
					.map(({ label, path }) => ({ value: path, label })),
				onChange,
			});
		}
	}, [
		availableReportTypes,
		onChange,
		selectedReportType?.label,
		selectedReportType?.path,
		selectedReportType?.type,
		currentUser.id,
		filteredReports,
		setSettings,
		setTitleNavigation,
	]);

	useEffect(() => {
		if (reports && reports[selectedReportType?.type]) {
			setFilterContextReports(reports[selectedReportType?.type]);
		}
	}, [reports, selectedReportType?.type, setFilterContextReports]);

	const handleDelete = async (id) => {
		try {
			setStatus({ status: 'deleting', id });
			await Reports.deleteReport({ id });
			updateReports();
			setStatus({ status: 'deleted' });
		} catch (err) {
			setStatus({ status: 'error' });
		}
	};

	const handleCopyToDashboard = async (id) => {
		const report = await Reports.getReport({ id });
		await maybeCopyToDashboard({ settings: report.settings });
	};

	const allReports = (reports && reports[selectedReportType?.type]) || [];

	let statusMessage = '';
	let severity = 'info';
	if (status.status === 'deleted') {
		statusMessage = 'Report deleted.';
	} else if (status.status === 'error') {
		statusMessage = 'Failed deleting report.';
		severity = 'error';
	}
	// TODO: add animation for rendering list?
	return (
		allReports.length > 0 ? (
			<Box>
				<Grid container spacing={3}>
					{filteredReports.map(({
						id,
						settings,
						createdDate,
						userId,
						pinnedByUsers,
					}) => (
						<Grid
							key={id}
							item
							xs={6}
							sm={3}
							md={4}
							lg={3}
							xl={2}
						>
							<ReportCard
								id={id}
								title={settings.title}
								isPublic={Boolean(userId) === false}
								createdDate={createdDate}
								link={`${selectedReportType?.path}/${id}`}
								image={getReportPreviewImage(
									settings.showPieChart,
									settings.showTable,
									settings.showChart,
									settings.groupBy,
								)}
								onDelete={() => handleDelete(id)}
								onCopyToDashboard={() => handleCopyToDashboard(id)}
								deleting={status?.id === id && status?.status === 'deleting'}
								pinnedToSidebar={pinnedByUsers.includes(currentUser.id)}
							/>
						</Grid>
					))}
				</Grid>
				<Toast
					open={status.status === 'deleted' || status.status === 'error'}
					message={statusMessage}
					status={severity}
					timeout={3}
					onClose={() => setStatus({ status: null, id: null })}
				/>
			</Box>
		) : (
			// Same size as ReportCard
			<Card
				variant="outlined"
				sx={{
					width: 207,
					height: 282,
					ml: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<UnstyledLink to={`${selectedReportType?.path}/add`}>
					<Tooltip title="New report">
						<IconButton size="large">
							<AddIcon />
						</IconButton>
					</Tooltip>
				</UnstyledLink>
			</Card>
		)
	);
}

ReportsOverview.propTypes = {
	type: PropTypes.string,
};

ReportsOverview.defaultProps = {
	type: null,
};

export default ReportsOverview;
