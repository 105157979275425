import PropTypes from 'prop-types';
import React from 'react';
import Container from '@mui/material/Container';
import styles from './styles.css';
import UserEditor from '../../containers/UserEditor';

class UserEdit extends React.Component {
	render() {
		const { id, userId } = this.props.match.params;

		return (
			<div className={styles.pageContentWrapper}>
				<Container>
					<UserEditor publisherId={id} userId={userId} />
				</Container>
			</div>
		);
	}
}

UserEdit.propTypes = {
	match: PropTypes.object.isRequired,
};

UserEdit.defaultProps = {
};

export default UserEdit;
