import React, { useState } from 'react';
import {
	FormControl,
	Menu,
	MenuItem,
	Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function TopBarNavigation({
	label,
	options,
	onChange,
}) {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpen = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = (value) => {
		onChange(value);
		handleClose();
	};

	const open = Boolean(anchorEl);
	return (
		<FormControl>
			<Button
				variant="text"
				color="offWhite"
				sx={{
					fontSize: '20px', // Same as header to the left.
				}}
				onClick={handleOpen}
				endIcon={<ArrowDropDownIcon />}
			>
				{label}
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{ options.map((option) => (
					<MenuItem
						key={option.value}
						onClick={() => handleSelect(option.value)}
					>
						{option.label}
					</MenuItem>
				))}
			</Menu>
		</FormControl>
	);
}

TopBarNavigation.propTypes = {
	label: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	})).isRequired,
	onChange: PropTypes.func.isRequired,
};
