import React from 'react';
import PropTypes from 'prop-types';
import FilterProvider from '../../components/ReportsOverview/Filters/context/FilterProvider';
import ReportsOverview from '../../components/ReportsOverview';
import Filters from '../../components/ReportsOverview/Filters';

function ReportsOverviewPage({ type }) {
	return (
		<FilterProvider>
			<Filters />
			<ReportsOverview type={type} />
		</FilterProvider>
	);
}

ReportsOverviewPage.propTypes = {
	type: PropTypes.string,
};

ReportsOverviewPage.defaultProps = {
	type: null,
};

export default ReportsOverviewPage;
