import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
// import styles from './styles.css';
import { ActionButton } from '../ActionButton/ActionButton';
import Spinner from '../Spinner';
import MiscUtils from '../../lib/miscUtils';
import { demoMessage, isDemoUser } from '../DemoImport/utils';
import { Dialog } from '../Dialog';

class JobButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	async clicked() {
		try {
			const { fn, demoAllow } = this.props;
			if (!this.state.isWorking) {
				this.setState({ isWorking: true });
				if (isDemoUser() && !demoAllow) {
					demoMessage();
				} else {
					await fn();
				}
			}
		} catch (e) {
			this.setState({ errorMsg: MiscUtils.errorMsg(e) });
		} finally {
			this.setState({ isWorking: false });
		}
	}

	render() {
		const { style } = this.props;
		const { isWorking, errorMsg } = this.state;
		if (isWorking) {
			return <Spinner barsStyle={{ margin: 0 }} style={style} />;
		}
		const childProps = _.omit({ ...this.props, onClick: () => this.clicked() }, _.keys(JobButton.propTypes));
		return (
			<div style={style}>
				<ActionButton {...childProps} />
				<Dialog
					open={!!errorMsg}
					text={errorMsg}
					onClose={() => this.setState({ errorMsg: null })}
				/>
			</div>
		);
	}
}

JobButton.propTypes = {
	fn: PropTypes.func.isRequired,
	demoAllow: PropTypes.bool,
	style: PropTypes.object,
};

JobButton.defaultProps = {
	demoAllow: false,
	style: undefined,
};

export default JobButton;
