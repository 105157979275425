const _ = require('lodash');
const Countries = require('../reportData/countries');

let byType; // To be set once by initStore()

const setArr = (dst, arr) => {
	dst.splice(0);
	dst.push(...arr);
};

/**
 * Class for "environment-independent" (backend/frontend) access to some important objects.
 * It is created as a singleton that is invalidated/deleted whenever there are any changes that
 * might affect the "byId" maps - for example a publisher-update that makes our list of sites invalid.
 * The "singleton" is then "re-calculated" whenever needed by any of the static functions. All member functions
 * are turned into static function for that purpose (see below class).
 * */
class ObjectStore {
	/** All cached models that are store in their own db collection */
	static RootModelNames = ['BaseSsp', 'BaseAdserver', 'Publisher', 'GlobalSettingsObject'];

	/** Includes all models, inculding the subdocuments we're storing here */
	static AllModelNames = [...ObjectStore.RootModelNames, 'Site', 'Placement'];

	/** ALl own object types that can be used for settings ('ByObject' fields and as option types for 'String') */
	static AllBuiltInObjectNames = _.without([...ObjectStore.AllModelNames, 'Country'], 'GlobalSettingsObject');

	/** Will be updated to include CustomObjectNames below when available */
	static AllObjectNames = [...ObjectStore.AllBuiltInObjectNames];

	// Will be updated later, the "names" are actually the ids for objects in GlobalSettingsObject.customTagObjects
	static CustomObjectNames = [];

	static ObjectTypeSettings = {
		BaseSsp: {
			omitInTags: (v) => !v.bidderName, // Don't include GAM SSP objects, etc
		},
	};

	constructor() {
		if (!byType) {
			throw Error('Can\'t create ObjectStore instance yet as initStore() has not been called');
		}
		this.byType = _.mapValues({ ..._.pick(byType, ObjectStore.RootModelNames) }, ({ byId }) => ({ byId }));
		// Create maps of Sites+Placements
		[['Site', 'Publisher', 'websites'], ['Placement', 'Site', 'placements']].forEach(([model, parent, fld]) => {
			this.byType[model] = {
				byId: _(this.byType[parent].byId)
					.values()
					.map(fld)
					.flatten()
					.keyBy('id')
					.value(),
			};
		});
		// ..and Countries
		this.byType.Country = { byId: _.mapValues(Countries, (name) => ({ name })) };

		// ..and the custom object types
		const gs = this.getGlobalSettings();
		const custom = _(gs.customTagObjects)
			.keyBy('id')
			.mapValues(({ name, options }) => ({
				name,
				byId: _(options)
					.filter('name')
					.keyBy('name')
					.mapValues((opt) => ({ id: opt.name, name: opt.description }))
					.value(),
			}))
			.value();
		Object.assign(this.byType, custom);
		const customOrder = _.uniq(_.map(gs.customTagObjects, 'id'));

		// Update the static arrays with the custom object types
		setArr(ObjectStore.CustomObjectNames, customOrder);
		setArr(ObjectStore.AllObjectNames, [...ObjectStore.AllBuiltInObjectNames, ...customOrder]);
	}

	/** To be called whenever our mappings might not be correct anymore (for example when a Publisher updates) */
	static invalidateStore() {
		ObjectStore.instance = null;
	}

	/** To be called once (values will be ModelCache objects server-side) */
	static initStore(newByType) {
		byType = newByType;
		ObjectStore.invalidateStore();
	}

	/** Get singleton after creating it if needed */
	static getInstance() {
		if (!ObjectStore.instance) {
			ObjectStore.instance = new ObjectStore();
		}
		return ObjectStore.instance;
	}

	/** Is the type a db-model? */
	static isDbIdType(type) {
		return ObjectStore.AllModelNames.includes(type);
	}

	/** "Convenience" function to get the GlobalSettingsObject instance */
	getGlobalSettings() {
		const [res] = _.values(this.byType.GlobalSettingsObject.byId);
		return res;
	}

	/** object from an id */
	idToObject(id, type) {
		return this.byIdMap(type)[id];
	}

	/** name from an id */
	idToName(id, type, defaultWhenEmptyString = '[No name]') {
		const obj = this.idToObject(id, type);
		if (!obj) {
			return null;
		}
		return obj.name || obj.domain || defaultWhenEmptyString;
	}

	/** the by-id map for any type */
	byIdMap(type) {
		return this.byType[type]?.byId || {};
	}

	/** values of the the by-id map for any type */
	objectArray(type, forTagsOnly) {
		let res = Object.values(this.byIdMap(type));
		if (forTagsOnly) {
			const excludeFn = ObjectStore.ObjectTypeSettings[type]?.omitInTags;
			if (excludeFn) {
				res = res.filter((o) => !excludeFn(o));
			}
		}
		return res;
	}

	/** The object for a type, it might be just { byId: { ... } } - but custom types will also contain a .name */
	byTypeInfo(type) {
		return this.byType[type];
	}
}

// Create static methods to simplify usage from outside, will create a "re-calculated" ObjectStore if needed
Object.getOwnPropertyNames(ObjectStore.prototype).filter((k) => k !== 'constructor').forEach((fnName) => {
	ObjectStore[fnName] = (...args) => ObjectStore.getInstance()[fnName](...args);
});

module.exports = ObjectStore;
