import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Base from '../../layouts/Base';
import TriggerReport from './triggerReport';
import SystemData from '../../lib/systemData';

const PREFIX = 'NewTriggerTree';

const classes = {
	bold: `${PREFIX}-bold`,
};

const StyledTreeView = styled(TreeView)({
	[`& .${classes.bold}`]: {
		fontWeight: 'bold',
	},
});

function NewTriggerTree({
	workspace, closeFn, templateArr, reTriggerMode,
}) {
	const byCat = _.groupBy(templateArr, (r) => r.category || '');

	const alarmAllowed = (({ sums, groupBy, type }) => {
		if (workspace.isAdmin) {
			return true;
		}
		const { USER_SUM_OPTIONS, USER_GROUP_BY_OPTIONS } = SystemData.genericData.userReportOptions[type];
		return !sums.find((s) => !USER_SUM_OPTIONS.includes(s))
			&& !groupBy.find((dim) => !USER_GROUP_BY_OPTIONS.includes(dim));
	});
	const renderArr = (arr) => arr.filter(alarmAllowed).map((settings) => {
		const key = JSON.stringify(settings);
		const name = settings.isCustom ? 'New alarm' : settings.title;
		return (
			<TreeItem
				key={key}
				nodeId={key}
				label={(
					<span
						className={`${settings.isCustom ? classes.bold : ''}`}
					>
						{settings.title}
					</span>
				)}
				onClick={() => {
					closeFn();
					Base.renderGlobal((closeGlobal) => (
						<TriggerReport
							closeFn={closeGlobal}
							trigger={new SystemData.models.Trigger({
								name: settings.isCustom ? 'New alarm' : settings.title,
								active: true,
								reTriggerMode,
								triggerTypeName: 'report',
								userId: workspace.myUserId,
								overrideDefaultInfoChannel: false,
								reportSettings: { ...settings, title: name },
							})}
							workspace={workspace}
						/>
					));
				}}
			/>
		);
	});
	return (
		<StyledTreeView
			defaultCollapseIcon={<ExpandMoreIcon />}
			defaultExpandIcon={<ChevronRightIcon />}
		>
			{_.entries(byCat).map(([cat, arr]) => (!cat ? renderArr(arr) : (
				<TreeItem key={cat} nodeId={cat} label={cat}>
					{renderArr(arr)}
				</TreeItem>
			)))}
		</StyledTreeView>
	);
}

NewTriggerTree.propTypes = {
	workspace: PropTypes.object.isRequired,
	closeFn: PropTypes.func.isRequired,
	templateArr: PropTypes.array.isRequired,
	reTriggerMode: PropTypes.bool.isRequired,
};

export default NewTriggerTree;
