const SHARED_PARAMS = ['skip', 'playbackmethod', 'playerSize', 'minduration', 'maxduration', 'linearity'];
const DEFAULT_INSTREAM_PARAMS = ['api', 'mimes', 'protocols'];
// Options that are not prebid specific, mainly for setting player options
const PLAYER_EXCLUSIVE_OPTIONS = [
	'adText',
	'skipText',
	'skipButtonText',
	'allowOverride',
	'expandTime',
	'endBehavior',
	'enableMaxHeight',
	'xandrRenderOptions',
];

const ADSERVER_TARGETING_OPTIONS = [
	'forceBannerAdserverTargeting',
];

const VIDEO_PARAMS_INTERNAL_FIELDS = [
	'playerExclusiveOptions',
	'mediaType',
	'adserverTargetingOptions',
];

function isInstreamVideo({ mediaType }) {
	return mediaType === 'instream';
}

/**
 * Get all the params that are not in sharedKeys + paramsToExclude
 * */
function getCustomVideoParams(videoSettings, paramsToExclude = []) {
	const keysToExclude = [...SHARED_PARAMS, ...paramsToExclude];

	return Object.keys(videoSettings)
		.filter((key) => !keysToExclude.includes(key))
		.reduce((acc, currKey) => {
			acc[currKey] = videoSettings[currKey];
			return acc;
		}, {});
}

function mapSharedVideoSettings(placementType, {
	playerSize,
	playbackmethod,
	minduration,
	maxduration,
	skip,
}) {
	let { width, height } = playerSize || {};
	if ((width || 1) === 1 && (height || 1) === 1) {
		if (placementType.dimensions.length) {
			const dim = placementType.dimensions[0];
			width = dim.width;
			height = dim.height;
		}
	}
	return {
		skip: skip ? 1 : 0,
		playerSize: [width, height],
		...(playbackmethod
			&& { playbackmethod: [parseInt(playbackmethod, 10)] }),
		...(minduration && { minduration }),
		...(maxduration && { maxduration }),
	};
}

function mapOutstreamVideoSettings(placementType, videoSettings) {
	// Options that are not to be passed in mediaTypes.video or bid.params,
	// but only to the player.
	const customParams = getCustomVideoParams(
		videoSettings,
		[...PLAYER_EXCLUSIVE_OPTIONS, ...ADSERVER_TARGETING_OPTIONS],
	);
	// Convert placement string -> number due to the fact that it's a string as we haven't implemented options
	// for 'Number' values yet (just for 'String')
	if (typeof customParams.placement === 'string') {
		customParams.placement = parseInt(customParams.placement, 10) || 2;
	}

	return {
		linearity: 2,
		mimes: ['video/mp4', 'video/webm', 'video/ogg', 'application/javascript'],
		protocols: [1, 2, 3, 4, 5, 6, 7, 8],
		api: [1, 2],
		playerExclusiveOptions: {
			...(videoSettings.adText && { adText: videoSettings.adText }),
			...(videoSettings.skipText && { skipText: videoSettings.skipText }),
			...(videoSettings.skipButtonText && { skipButtonText: videoSettings.skipButtonText }),
			...((videoSettings.expandTime !== undefined) && { expandTime: videoSettings.expandTime }),
			...((videoSettings.allowOverride !== undefined) && { allowOverride: videoSettings.allowOverride }),
			...((videoSettings.endBehavior !== undefined) && { endBehavior: videoSettings.endBehavior }),
			...((videoSettings.enableMaxHeight !== undefined) && { enableMaxHeight: videoSettings.enableMaxHeight }),
			...((videoSettings.xandrRenderOptions !== undefined) && { xandrRenderOptions: videoSettings.xandrRenderOptions }),
		},
		adserverTargetingOptions: {
			...((videoSettings.forceBannerAdserverTargeting !== undefined)
				&& { forceBannerAdserverTargeting: videoSettings.forceBannerAdserverTargeting }),
		},
		...(videoSettings.mediaType && { mediaType: videoSettings.mediaType }),
		...mapSharedVideoSettings(placementType, videoSettings),
		...customParams,
	};
}

function mapInstreamVideoSettings(placementType, videoSettings) {
	const customParams = getCustomVideoParams(videoSettings, DEFAULT_INSTREAM_PARAMS);

	return {
		linearity: 1,
		placement: 1, // "In-stream"
		...(videoSettings.api && Object.keys(videoSettings.api).length > 0
			&& ({
				api: Object.entries(videoSettings.api)
					.filter(([__, include]) => include).map(([apiNum, __]) => parseInt(apiNum, 10)),
			})),
		mimes: videoSettings.mimes ? videoSettings.mimes.map(({ mime }) => mime) : [],
		...(videoSettings.protocols && Object.keys(videoSettings.protocols).length > 0
			&& ({
				protocols: Object.entries(videoSettings.protocols)
					.filter(([__, include]) => include).map(([protocolNum, __]) => parseInt(protocolNum, 10)),
			})),
		...customParams,
		...mapSharedVideoSettings(placementType, videoSettings),
	};
}

/**
 * Get video settings in prebid-mapped format.
 * If not video -> return undefined
 * We actually want it in formats like,
 * playbackmethod: ['number'] and not playbackmethod: playbackMethod: string as defined in prebidDataObjects.js
 * ... and other data structure conversions
 * Also clean out stuff that is empty.
 */
function getVideoSettings(placement, placementType, pbjsConfigId) {
	if (isInstreamVideo(placementType)) {
		return mapInstreamVideoSettings(
			placementType,
			placement.bidderParams({ sspId: 'instreamVideoSettings', pbjsConfigId }),
		);
	}
	const outstreamParams = placement.bidderParams({ sspId: 'outstreamVideoSettings', pbjsConfigId });
	if (outstreamParams.mediaType === 'outstream' || outstreamParams.mediaType === 'bannerOutstream') {
		return mapOutstreamVideoSettings(
			placementType,
			outstreamParams,
		);
	}
	return undefined;
}

module.exports = {
	VIDEO_PARAMS_INTERNAL_FIELDS,
	getVideoSettings,
	isInstreamVideo,
};
