import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import CKEditor from 'react-ckeditor-component';

class HtmlEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	shouldComponentUpdate(nextProps) {
		return this.props.content !== nextProps.content;
	}

	render() {
		return (
			<CKEditor
				{...this.props}
				scriptUrl="/src/assets/ckeditor/ckeditor.js"
			/>
		);
	}
}

HtmlEditor.propTypes = { ...CKEditor.propTypes };

HtmlEditor.defaultProps = { ...CKEditor.defaultProps };

export default HtmlEditor;
