import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';

function AutocompleteInput({
	value,
	getSuggestions,
	suggestions: initialSuggestions,
	onChange,
	autocompleteProps,
}) {
	const [searchStr, updateSearchStr] = useState('');
	const [suggestions, setSuggestions] = useState(initialSuggestions);
	const timeoutRef = useRef(null);
	// What the user has typed
	const latestInput = useRef('');
	// What search string the current results are based on
	const latestSearchedInput = useRef();
	const handleInputChange = (_, val) => {
		updateSearchStr(val);
		latestInput.current = val;
	};

	useEffect(() => {
		async function updateSuggestions(searchInput) {
			// If input has changed since initializing request, don't run it.
			if (searchInput === latestInput.current) {
				const res = await getSuggestions(searchInput);
				setSuggestions(res);
				latestSearchedInput.current = searchInput;
			}
		}
		if (getSuggestions) {
			const initSuggestionsFetch = (search) => {
				timeoutRef.current = setTimeout(() => {
					updateSuggestions(search);
				}, 500);
			};
			// Don't fetch new results if search string is a subset of the latest search
			if (latestSearchedInput.current === undefined || !searchStr.includes(latestSearchedInput.current)) {
				initSuggestionsFetch(searchStr);
			}
		}
		return () => {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		};
	}, [searchStr, getSuggestions]);
	return (
		<div className="AutocompleteInput">
			<Autocomplete
				value={value}
				onInputChange={handleInputChange}
				onChange={({ type }, v) => type !== 'blur' && onChange(v)}
				options={suggestions}
				selectOnFocus
				autoSelect
				blurOnSelect
				autoHighlight
				handleHomeEndKeys
				{...autocompleteProps}
			/>
		</div>
	);
}

AutocompleteInput.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.objectOf(PropTypes.any),
		PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
	]),
	// If you have an already fetched list of suggestions
	suggestions: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
	),
	// Optional if need to fetch from API
	getSuggestions: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	autocompleteProps: PropTypes.shape({
		renderInput: PropTypes.func.isRequired,
		filterOptions: PropTypes.func,
		renderOption: PropTypes.func,
		getOptionLabel: PropTypes.func,
		multiple: PropTypes.bool,
	}),
};

AutocompleteInput.defaultProps = {
	value: null,
	filterOptions: undefined,
	autocompleteProps: {
		renderInput: () => undefined,
	},
	getSuggestions: undefined,
	suggestions: [],
};

export default AutocompleteInput;
