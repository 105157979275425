/* eslint radix: 0 */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import { Link } from '../Link/Link';
import moment from 'moment';
import withTheme from '@mui/styles/withTheme';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import PreviewIcon from '@mui/icons-material/OpenWith';
import EditIcon from '@mui/icons-material/Edit';
import PlayIcon from '@mui/icons-material/PlayCircleFilled';
import MailIcon from '@mui/icons-material/MailOutline';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ReorderIcon from '@mui/icons-material/Reorder';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import UndoIcon from '@mui/icons-material/Undo';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Constants from 'relevant-shared/reportData/constants';
import { limiter as limiterFn, createCsv } from 'relevant-shared/misc/misc';
import {
	Invoice, Ssp, Dsp, Reports, GlobalSettingsObject,
} from '../../api/relevant';
import { HtmlContainer, FormOf, IfHasAudience, SimpleAlert } from '../Wrappers';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import Select from '../Select';
import DatePicker from '../DatePicker';
import ExpandSelector from '../ExpandSelector';
import SiteSelect from '../SiteSelect';
import SystemData from '../../lib/systemData';
import JobButton from '../JobButton';
import PopupSelector from '../PopupSelector';
import ReactUtils from '../../lib/reactUtils';
import FieldArray from '../FieldArray';
import MiscUtils from '../../lib/miscUtils';
import DateUtils from '../../lib/dateUtils';
import BrowserUtils from '../../lib/browserUtils';
import OperationWrapper from '../OperationWrapper';
import Checkbox from '../Checkbox';
import Icon from '../Icon';
import DataTableWithMemory from '../DataTable/dataTableWithMemory';
import { demoMessage, isDemoUser } from '../DemoImport/utils';
import { ConfirmDialog } from '../ConfirmDialog';
import { Dialog } from '../Dialog';

const monthName = (month) => new Date(1970, parseInt(month) - 1).toLocaleString('en-us', { month: 'long' });
const firstDay = (d) => DateUtils.firstDayOfMonth(d);
const lastDay = (d) => DateUtils.lastDayOfMonth(d);
const invType = (inv) => (inv.isBalanceInfo ? 'BALANCE_INFO' : 'NORMAL_INVOICE');
const desc = (valInfo) => (_.isString(valInfo) ? valInfo : valInfo.desc);
const toDate = (year, month) => new Date(Date.UTC(year, month - 1, 1, 0, 0, 0, 0));

const fmtVal = (valInfo) => {
	if (valInfo) {
		if (_.isString(valInfo)) {
			return valInfo;
		}
		if (valInfo.color) {
			return <Box component="span" color={valInfo.color}>{valInfo.desc}</Box>;
		}
	}
	return 'ERROR';
};

const reportGeneric = async (year, month, sysId, fnName, idKey, metric, whereIn) => {
	const res = await Reports.call(fnName, {
		groupBy: [idKey],
		sums: [metric],
		start: toDate(year, month),
		end: lastDay(toDate(year, month)),
		correctionType: 'none',
		whereIn,
	});
	const val = (res.data[sysId] || {})[metric] || 0;
	return Object.assign(val, { currency: res.currency });
};

const reportAudience = (year, month, dspId, whereIn) => reportGeneric(year, month, dspId, 'reportAudience', 'dsp', 'revenue', whereIn);

const reportProgrammatic = (year, month, sspId, whereIn) => reportGeneric(year, month, sspId, 'reportProgrammatic', 'sspId', 'revenueAfterSsp', whereIn);

const STATUSES = {
	NON_EXISTING: {
		desc: 'Non-generated',
		color: 'grey.600',
		show: ({ includeNonExisting }) => includeNonExisting,
	},
	INIT: {
		desc: 'Ready',
		color: 'warning.main',
	},
	PUBLISHED: {
		desc: 'Sent',
		color: 'error.main',
	},
	INVOICED: {
		desc: 'Settled',
		color: 'success.main',
	},
};

const TYPES = {
	NORMAL_INVOICE: 'Invoice',
	BALANCE_INFO: {
		desc: 'Info',
		color: 'grey.600',
	},
};

const PRODUCTS = {
	audience: 'Audience',
	programmatic: 'Programmatic',
};

const Actions = [
	{
		name: 'Generate',
		cond: (inv) => inv.status === 'NON_EXISTING',
		fn: (inv) => Invoice.call('generateFromData', { invoiceData: inv }),
		icon: PlayIcon,
	},
	{
		name: 'Send',
		cond: (inv) => inv.status === 'INIT',
		fn: async (inv) => (await Invoice.get(inv.id)).publish(),
		icon: MailIcon,
	},
	{
		name: 'Settle',
		cond: (inv) => inv.status === 'PUBLISHED' && !inv.isBalanceInfo,
		fn: async (inv) => (await Invoice.get(inv.id)).settle(),
		icon: CheckIcon,
	},
	{
		name: 'Delete',
		cond: (inv) => inv.status === 'INIT',
		fn: async (inv) => (await Invoice.get(inv.id)).delete(),
		icon: DeleteIcon,
	},
	{
		name: 'Undo',
		cond: (inv) => inv.status === 'PUBLISHED' || inv.status === 'INVOICED',
		fn: async (inv) => (await Invoice.get(inv.id)).undo(),
		icon: UndoIcon,
	},
	{
		name: '.CSV Export',
		cond: () => true,
		noConfirm: true,
		noReload: true,
		noLineIcon: true,
		onFinished: (ctx) => {
			let lines = _.cloneDeepWith(ctx.lines, (val) => (_.isNumber(val) ? _.trimEnd(_.trimEnd(val.toFixed(2), '0'), '.') : undefined));
			lines = _.sortBy(lines, [(line) => -(new Date(line.end)), (line) => line.publisher, 'product']);
			const csv = createCsv(lines, {
				publisher: 'Account',
				start: 'Start month',
				end: 'End month',
				status: 'Status',
				product: 'Product',
				type: 'Document type',
				invoiceEUR: 'Invoice EUR',
				reportUncorrectedEUR: 'Reported EUR',
				reportEUR: 'Auto-corrected reported EUR',
				prevCorrectionEUR: 'Previous invoice corrections EUR',
				otherCorrections: 'Other corrections EUR',
			});
			BrowserUtils.downloadTextFile(csv, 'Invoice.csv');
		},
		createContext: (invoices, ws) => ({ ws, lines: [] }),
		fn: (inv, ctx) => ctx.lines.push({
			publisher: ctx.ws.pubsById[inv.publisherId].name,
			start: moment.utc(inv.start).format('YYYY-MM'),
			end: moment.utc(inv.end).format('YYYY-MM'),
			status: desc(STATUSES[inv.status]),
			product: desc(PRODUCTS[inv.product]),
			type: desc(TYPES[invType(inv)]),
			otherCorrections: inv.invoiceEUR - (inv.reportEUR + inv.prevCorrectionEUR),
			..._.pick(inv, ['reportEUR', 'reportUncorrectedEUR', 'prevCorrectionEUR', 'invoiceEUR']),
		}),
		icon: ReorderIcon,
	},
];

function InvoiceNumber(p) {
	const invoices = _.isArray(p.invoices) ? p.invoices : [p.invoices];
	const invoiceEUR = _.sumBy(invoices, 'invoiceEUR');
	const correction = _.sumBy(invoices, (inv) => inv.invoiceEUR - inv.reportUncorrectedEUR);
	const correctionStr = correction.toFixed(2);
	const showCorrection = !!parseFloat(correctionStr);
	return (
		<span>
			<b>{`${invoiceEUR.toFixed(2)}`}</b>
			<Box component="span" color={correction > 0 ? 'success.main' : 'error.main'}>
				{showCorrection ? ` (${correctionStr})` : ''}
			</Box>
		</span>
	);
}

class InvoiceWorkspace extends React.Component {
	constructor(props) {
		super(props);
		const { isAdmin } = this.props;
		const endOfLastMonth = lastDay(moment.utc().subtract(1, 'month'));
		this.state = {
			minEnd: firstDay(moment.utc().subtract(isAdmin ? 3 : 12, 'months')),
			maxEnd: endOfLastMonth,
			invoices: [],
			selectedPublishers: [],
			selectedInvoices: [],
			successOps: {},
			failedOps: {},
			generateUntilMonth: endOfLastMonth,
			..._.mapValues({ ...STATUSES, ...TYPES, ...this.availProducts() }, () => true),
		};
		this.sysLookups = {};
		this.pubsById = _.keyBy(props.publishers, 'id');
	}

	availProducts() {
		const { onlyProduct } = this.props;
		return onlyProduct ? _.pick(PRODUCTS, [onlyProduct]) : PRODUCTS;
	}

	async reportProgrammatic(year, month, sspId) {
		const { globalSettings = {} } = this.state;
		let whereIn;
		if (globalSettings.noDirectRevenueInvoicing) {
			whereIn = { revenueType: _.without(Object.keys(Constants.programmatic.REVENUE_TYPES), 'direct') };
		}
		return reportProgrammatic(year, month, sspId, whereIn);
	}

	renderRevenueCorrections(p) {
		const REVENUE_CORRECTION_START_YEAR = 2017;
		const { ssps, dsps } = this.state;
		const nameOfField = (f) => _.trim(f('').name, '.');
		const defObj = {};
		const lastMonth = new DateUtils.lastMonth().end;
		_.range(lastMonth.getFullYear(), REVENUE_CORRECTION_START_YEAR - 1).forEach((year) => {
			const perMonth = {};
			_.range(1, year === lastMonth.getFullYear() ? lastMonth.getMonth() + 2 : 13).forEach((month) => {
				perMonth[month] = null;
			});
			defObj[year] = perMonth;
		});
		const counter = (fld, filter, stopFn) => {
			let count = 0;
			_.cloneDeepWith(_.get(p.model, nameOfField(fld)), (v, k) => {
				if (stopFn && stopFn(v, k)) {
					return null;
				}
				if (!isNaN(parseFloat(v)) && !_.isArray(v) && (!filter || filter(v, k))) {
					if (!(_.isString(v) && v.length === 24)) { // Exclude object-ids
						count += 1;
					}
				}
			});
			return { selected: Array(count) };
		};

		const sysRender = (mainTitle, type, title, systems, fld, year, month, reportFn) => {
			const sysFilter = (v, k) => systems.find((s) => s.id === k);
			return (
				<ExpandSelector
					title={mainTitle}
					form={p.form}
					{...counter(fld, (v, k) => sysFilter(v, k) || k === 'cost', (v, k) => _.isArray(v) && k !== type)}
				>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<ExpandSelector
								title="Miscellaneous costs"
								form={p.form}
								{...counter(() => fld(type))}
							>

								<FieldArray
									model={_.get(p.model, nameOfField(fld)) || {}}
									field={fld}
									name={type}
									update={() => p.form.forceUpdate()}
									createNew={() => ({ cost: 0, pubs: [] })}
									createNewArray={() => p.form.setStateValue(fld(type).name, [])}
									render={(costFld) => (
										<div>
											<SiteSelect
												selected={_.get(p.model, costFld('pubs').name) || []}
												onChange={(items) => {
													_.set(p.model, costFld('pubs').name, items);
													p.form.forceUpdate();
												}}
												type={type}
												title="Limit to certain publishers"
												selectorType="PopupSelector"
											/>
											<TextField
												{...costFld('cost')}
												required
												float
												label="Miscellaneous invoicable costs EUR"
												fullWidth margin="normal"
											/>
											<TextField
												{...costFld('desc')}
												label="Description (optional)"
												fullWidth
												margin="normal"
											/>
											<Checkbox
												{...costFld('useTotal')}
												label={<>Deduct cost from <b>Total revenue</b> instead of <b>Publisher revenue</b></>}
												fullWidth
												margin="normal"
											/>
										</div>
									)}
								/>
							</ExpandSelector>
						</Grid>
						<Grid item xs={12}>
							<ExpandSelector
								title={title}
								form={p.form}
								key={title}
								{...counter(fld, sysFilter)}
							>
								{systems.map((sys) => {
									const reportKey = `${year} ${month} ${sys.id}`;
									const reportVal = this.sysLookups[reportKey];
									return (
										<Grid key={sys.id} container spacing={3} alignItems="center">
											<Grid item xs={6}>
												<TextField
													key={sys.id}
													float
													{...fld(sys.id)}
													label={sys.name}
													fullWidth
												/>
											</Grid>
											<Grid item xs={6}>
												<>
													{reportVal === undefined && (
														<JobButton
															label="Check report revenue"
															color="primary"
															fn={async () => {
																this.sysLookups[reportKey] = await reportFn(year, month, sys.id);
																p.form.forceUpdate();
															}}
														/>
													)}
													{reportVal !== undefined && (
														<Box component="span" color="success.main">{`${reportVal.toFixed(2)} ${reportVal.currency}`}</Box>
													)}
												</>
											</Grid>
										</Grid>
									);
								})}
							</ExpandSelector>
						</Grid>
					</Grid>
				</ExpandSelector>
			);
		};

		return (
			<Grid container spacing={3}>
				{ReactUtils.fldMapObj({ revenueCorrections: defObj }, p.field, 'revenueCorrections', (obj, fld, key) => (
					<Grid key={key} item xs={12}>
						<ExpandSelector title={key} expanded={key === `${lastMonth.getFullYear()}`} {...counter(fld)} form={p.form}>
							<Grid container spacing={3}>
								{ReactUtils.fldMapObj(obj, fld, (obj_, fld_, key_) => (
									<Grid key={key_} item xs={12}>
										<ExpandSelector title={monthName(key_)} {...counter(fld_)} form={p.form}>
											<Grid container spacing={3}>
												<Grid item xs={12}>
													{sysRender('Programmatic', 'programmatic', 'SSPs', ssps, fld_, parseInt(key), parseInt(key_), this.reportProgrammatic.bind(this))}
												</Grid>
												<IfHasAudience>
													<Grid item xs={12}>
														{sysRender('Audience', 'audience', 'DSPs', dsps, fld_, parseInt(key), parseInt(key_), reportAudience)}
													</Grid>
												</IfHasAudience>
											</Grid>
										</ExpandSelector>
									</Grid>
								)).reverse()}
							</Grid>
						</ExpandSelector>
					</Grid>
				)).reverse()}
			</Grid>
		);
	}

	renderGenericGlobalSettings(p) {
		const { docTemplates } = this.props;
		const PRODUCT_TEMPLATES = {
			audience: {
				audienceInvoiceTemplate: 'Audience Invoice Statement Template',
				audienceBalanceInfoTemplate: 'Audience Short Balance Info Template',
			},
			programmatic: {
				programmaticInvoiceTemplate: 'Programmatic Invoice Statement Template',
				programmaticBalanceInfoTemplate: 'Programmatic Short Balance Info Template',
			},
		};
		if (SystemData.genericData.disableAudience) {
			delete PRODUCT_TEMPLATES.audience;
		}
		return (
			<Grid container spacing={3}>
				{Object.keys(PRODUCT_TEMPLATES).map((product) => (
					<Grid key={product} item xs={12}>
						<ExpandSelector
							title={product.charAt(0).toUpperCase() + product.slice(1)}
							form={p.form}
						>
							<Card>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Typography variant="h3">
												Default templates
											</Typography>
										</Grid>
										{ReactUtils.fldMapObj(p.model, p.field, 'defaultTemplates', (obj, fld, key) => (
											<Grid key={key} item xs={12}>
												<ExpandSelector title={(SystemData.genericData.LANGUAGES[key] || {}).name}>
													{_.map(PRODUCT_TEMPLATES[product], (desc, fldName) => (
														<Select
															key={fldName}
															label={desc}
															{...fld(fldName)}
															nonSelected=""
															items={docTemplates.filter((t) => t.type === 'InvoiceData').map((t) => ({ label: t.name, value: t.id }))}
															fullWidth
															margin="normal"
														/>
													))}
												</ExpandSelector>
											</Grid>
										))}
									</Grid>
								</CardContent>
							</Card>
							<TextField
								label="Minimum Invoice (EUR)"
								{...p.field(`${product}MinInvoiceEUR`)}
								integer
								fullWidth
								margin="normal"
							/>
							<TextField
								label="Invoice Mail CC address"
								{...p.field(`${product}InvoiceCc`)}
								emails
								fullWidth
								margin="normal"
							/>
							{product === 'programmatic' && (
								<Checkbox
									label="Ignore direct revenue in invoicing"
									{...p.field('noDirectRevenueInvoicing')}
								/>
							)}
						</ExpandSelector>
					</Grid>
				))}
			</Grid>
		);
	}

	renderGlobalSettings(renderer, formProps) {
		const { globalSettings } = this.state;
		if (!globalSettings) {
			return <div />;
		}
		const langs = Object.keys(SystemData.genericData.LANGUAGES);
		const defaultLangs = _.zipObject(langs, Array(langs.length).fill({}));

		const model = _.merge(globalSettings, { defaultTemplates: defaultLangs });
		model.defaultTemplates = MiscUtils.rearrangeObj(model.defaultTemplates, langs);
		return (
			<FormOf
				model={model}
				onSubmit={(data, __, settings) => (
					globalSettings.update(_.pick(data, settings.form.rootFields()))
				)}
				content={(p) => {
					this.globalSettingsSubmit = p.submit;
					return renderer(p);
				}}
				{...formProps}
			/>
		);
	}

	visibleInvoices() {
		const { isAdmin } = this.props;
		const {
			invoices, minEnd, maxEnd, selectedPublishers,
		} = this.state;
		const filtered = invoices.filter((inv) => (!isAdmin || this.pubsById[inv.publisherId])
			&& (!selectedPublishers.length || selectedPublishers.indexOf(inv.publisherId) >= 0)
			&& this.state[inv.product]
			&& this.state[inv.status]
			&& this.state[invType(inv)]
			&& new Date(inv.end) >= minEnd
			&& new Date(inv.end) <= maxEnd);
		const sorted = _.sortBy(filtered, [
			(inv) => -(new Date(inv.end)),
			(inv) => (isAdmin ? this.pubsById[inv.publisherId].name : ''),
			'product',
		]);
		return sorted;
	}

	async performAction(action, invoices, collapseListOnLoad) {
		if (isDemoUser()) {
			demoMessage();
			return;
		}
		const perform = async () => {
			const limiter = limiterFn(10);
			const successOps = {};
			const failedOps = {};
			const actionCtx = (action.createContext || (() => {}))(invoices, this);

			const runIndividual = async () => {
				await Promise.all(invoices.map((invoice) => limiter(async () => {
					try {
						await action.fn(invoice, actionCtx);
						successOps[invoice.id] = true;
					} catch (e) {
						failedOps[invoice.id] = MiscUtils.errorMsg(e);
					}
				})));
			};
			if (action.noReload) {
				await runIndividual();
			} else {
				await this.op.reload(runIndividual, !collapseListOnLoad);
				await this.op.reload(null, !collapseListOnLoad);
			}
			let errorMsg;
			if (!_.isEmpty(failedOps)) {
				errorMsg = `${Object.keys(failedOps).length} of ${invoices.length} operation(s) failed, please see the individual error message(s)`;
			}
			(action.onFinished || (() => {}))(actionCtx, successOps, failedOps, errorMsg);
			this.setState({ successOps, failedOps, errorMsg });
		};
		const close = () => this.setState({ confirmation: null });
		const confirmation = {
			open: true,
			text: <span>
				Are you sure that you want to&nbsp;
				<b><Box component="span" color="error.main">{action.name.toLowerCase()}</Box></b>
				{' '}
				{invoices.length}
				{' '}
				invoice(s)
         </span>,
			onConfirm: () => {
				close();
				perform();
			},
			onCancel: close,
		};
		if (action.noConfirm) {
			perform();
		} else {
			this.setState({ confirmation });
		}
	}

	async readInvoices() {
		const { isAdmin } = this.props;
		const { hide } = SystemData.genericData.systemSettings;
		const { generateUntilMonth, selectedInvoices, includeNonExisting } = this.state;
		const TO_COPY = ['id', '_id']; // re-create same IDs for NON-EXISTING invoices after reload̈́
		const KEY = ['start', 'end', 'publisherId', 'product'];
		const invKey = (inv) => JSON.stringify(_.pick(inv, KEY));
		const visibleIds = {};
		this.visibleInvoices().forEach((inv) => {
			visibleIds[invKey(inv)] = _.pick(inv, TO_COPY);
		});
		const invoices = !isAdmin && hide.invoices ? [] : await Invoice.call('getInvoiceInfos', {
			generateUntilMonth,
			includeNonExisting,
		});
		invoices.filter((inv) => inv.status === 'NON_EXISTING').forEach((inv) => {
			Object.assign(inv, visibleIds[invKey(inv)]);
		});
		const selectById = _.keyBy(selectedInvoices, 'id');
		this.setState({ invoices, selectedInvoices: invoices.filter((inv) => selectById[inv.id]) });
	}

	renderNonExistingAlert() {
		const { isAdmin } = this.props;
		const { includeNonExisting } = this.state;
		return !includeNonExisting && isAdmin && (
			<SimpleAlert
				title="New (Non-generated) invoices might be available"
				content="To calculate and load these invoices, press the button to the right"
				action={(
					<ActionButton
						label="Load new (Non-generated) invoices"
						variant="outlined"
						onClick={() => this.setState({ includeNonExisting: true }, () => this.op.reload())}
					/>
				)}
			/>
		);
	}

	render() {
		const { isAdmin, theme } = this.props;
		const {
			minEnd,
			maxEnd,
			selectedPublishers,
			generateUntilMonth,
			previewInvoice,
			previewDoc,
			selectedInvoices,
			successOps,
			failedOps,
			errorMsg,
			confirmation,
		} = this.state;
		const actions = () => (isAdmin ? Actions : Actions.filter((action) => action.isUserAction));
		const invoices = this.visibleInvoices();
		const fld = (name) => ({ name, value: this.state[name], onChange: (ev) => this.setState({ [name]: ev.target.value }) });

		const CheckboxesByObj = (p) => (
			<Card style={p.style}>
				<CardContent>
					<Typography variant="h4">
						{p.title}
					</Typography>
					{Object.entries(p.obj)
						.filter(([, { show }]) => !show || show(this.state))
						.map(([name, valInfo]) => (
							<Checkbox key={name} {...fld(name)} label={desc(valInfo)} />
						))}
				</CardContent>
			</Card>
		);

		const globalSettingsPopup = (p) => (
			<PopupSelector
				title={p.title}
				fn={async () => {
					await (p.load || (() => {}))();
					this.setState({ globalSettings: await GlobalSettingsObject.listOne() });
				}}
				onApplyChanges={async (param) => {
					if (await this.globalSettingsSubmit()) {
						this.op.reload(null, false);
					} else {
						param.preventDefault();
					}
				}}
				size="sm"
			>
				{this.renderGlobalSettings(p.renderer, p.formProps)}
			</PopupSelector>
		);

		return (
			<>
				<Grid item xs={2}>
					{isAdmin
						&& (
							<Box marginBottom={3}>
								<Card>
									<CardContent>
										<Typography variant="h3">
											Settings
										</Typography>
										<p>
											{globalSettingsPopup({
												title: 'Edit global invoice settings',
												renderer: (p) => this.renderGenericGlobalSettings(p),
											})}
										</p>
										<p>
											{globalSettingsPopup({
												title: 'Edit revenue corrections',
												renderer: (p) => this.renderRevenueCorrections(p),
												load: async () => this.setState({
													ssps: await Ssp.list({ sortby: 'name' }),
													dsps: await Dsp.list({ sortby: 'name' }),
												}),
												formProps: {
													// horrible hack needed as object-keys that are numbers (e.g. "revenueCorrections.2018.3")
													// causes arrays to be created by _.set() used by Form - instead of objects
													setCustomizer: (v) => (v ? undefined : {}),
												},
											})}
										</p>
									</CardContent>
								</Card>
							</Box>
						)}
					<Card>
						<CardContent>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="h3">
										Filter
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<DatePicker
										floatingLabelText="Min End Month"
										maxDate={maxEnd}
										name="minEnd"
										value={minEnd}
										autoOk
										onChange={(ev) => this.setState({ minEnd: firstDay(ev.target.value) })}
									/>
								</Grid>
								<Grid item xs={12}>
									<DatePicker
										floatingLabelText="Max End Month"
										minDate={minEnd}
										maxDate={lastDay(moment.utc().subtract(1, 'month'))}
										name="maxEnd"
										value={maxEnd}
										autoOk
										onChange={(ev) => this.setState({ maxEnd: lastDay(ev.target.value) })}
									/>
								</Grid>
								{isAdmin
								&& (
									<Grid item xs={12}>
										<SiteSelect
											selected={selectedPublishers}
											onChange={(items) => this.setState({ selectedPublishers: items })}
											type="all"
											title="Filter by publisher"
											selectorType="PopupSelector"
										/>
									</Grid>
								)}
								<Grid item xs={12}>
									<CheckboxesByObj title="Statuses" obj={isAdmin ? STATUSES : _.pick(STATUSES, ['PUBLISHED', 'INVOICED'])} />
								</Grid>
								<Grid item xs={12}>
									<CheckboxesByObj title="Type" obj={TYPES} />
								</Grid>
								<IfHasAudience>
									<Grid item xs={12}>
										<CheckboxesByObj style={isAdmin ? {} : { display: 'none' }} title="Product" obj={this.availProducts()} />
									</Grid>
								</IfHasAudience>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={10}>
					<Card>
						<CardContent>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Typography variant="h2">
										Invoices
									</Typography>
								</Grid>
								{isAdmin
								&& (
									<Grid item xs={12}>
										<DatePicker
											floatingLabelText="Generate until"
											maxDate={lastDay(moment.utc().subtract(1, 'month'))}
											name="generateUntilMonth"
											value={generateUntilMonth}
											autoOk
											onChange={(ev) => this.setState({ generateUntilMonth: lastDay(ev.target.value) })}
										/>
									</Grid>
								)}
								<Grid item xs={12}>
									<OperationWrapper
										reloadable
										load={[generateUntilMonth]}
										disableMode={!!invoices.length}
										fn={async (op) => {
											this.op = op;
											await this.readInvoices();
										}}
									>
										{this.renderNonExistingAlert()}
										<Card>
											<CardContent>
												<Grid container spacing={3}>
													<Grid item xs={12}>
														<Typography variant="h2">
															Matching invoices
														</Typography>
													</Grid>
													<Grid item xs={5}>
														<i>
															<b>Invoices</b>
															{' '}
															total (correction):
															{'\xa0'.repeat(8)}
														</i>
														<InvoiceNumber invoices={invoices.filter((i) => !i.isBalanceInfo)} />
														<br />
														<i>
															<b>Invoices</b>
															{' '}
															selected (correction):
															{' '}
														</i>
														<InvoiceNumber invoices={selectedInvoices.filter((i) => !i.isBalanceInfo)} />
														<br />
														<i>
															Infos total (correction):
															{'\xa0'.repeat(14)}
														</i>
														<InvoiceNumber invoices={invoices.filter((i) => i.isBalanceInfo)} />
														<br />
														<i>
															Infos selected (correction):
															{'\xa0'.repeat(7)}
														</i>
														<InvoiceNumber invoices={selectedInvoices.filter((i) => i.isBalanceInfo)} />
													</Grid>
													<Grid item xs={7}>
														<Grid container spacing={3}>
															{actions().map((action) => ((matches) => (
																<Grid key={action.name} item>
																	<ActionButton
																		label={`${action.name} (${matches.length})`}
																		color="primary"
																		disabled={!matches.length}
																		onClick={() => this.performAction(action, matches, true)}
																		icon={React.createElement(action.icon)}
																	/>
																</Grid>
															))(selectedInvoices.filter((inv) => action.cond(inv))))}
														</Grid>
													</Grid>
													<Grid item xs={12}>
														<DataTableWithMemory
															showCheckboxes={isAdmin}
															identifier={(row) => row.id}
															onChange={(selected) => this.setState({ selectedInvoices: selected })}
															selected={selectedInvoices}
															cellDefaultStyle={{ paddingLeft: '3px', paddingRight: '3px' }}
															definitions={[
																{
																	key: 'end',
																	title: 'To (months)',
																	style: { width: '90px' },
																	format: (end, inv) => (
																		<span>
																			<b>{moment.utc(end).format('YYYY-MM')}</b>
																			{` (${moment.utc(end).startOf('month').diff(moment.utc(inv.start).startOf('month'), 'months', true) + 1})`}
																		</span>
																	),
																},
																{
																	key: 'publisherId',
																	title: 'Account',
																	format: (id) => <Link to={`/accounts/${id}`}>{this.pubsById[id].name}</Link>,
																	hide: !isAdmin,
																},
																{
																	key: 'invoiceEUR',
																	title: 'Invoice sum',
																	format: (invoiceEUR, inv) => (
																		<InvoiceNumber invoices={inv} />
																	),
																},
																{
																	key: 'status',
																	title: 'Status',
																	format: (status) => fmtVal(STATUSES[status]),
																},
																{
																	key: 'product',
																	title: 'Product',
																	format: (prod) => fmtVal(PRODUCTS[prod]),
																},
																{
																	key: 'isBalanceInfo',
																	title: 'Type',
																	format: (__, row) => fmtVal(TYPES[invType(row)]),
																},
																{
																	key: 'id',
																	title: 'Result',
																	padding: 'none',
																	hide: _.isEmpty(actions()),
																	format: (id, inv) => (
																		<div>
																			<Icon
																				pic={successOps[id] ? DoneIcon : ErrorIcon}
																				show={successOps[id] || failedOps[id]}
																				color={successOps[id] ? 'success' : 'error'}
																				tooltip={failedOps[id] || 'Operation successful'}
																			/>
																			<Icon
																				pic={WarningIcon}
																				show={!inv.emailAddress && !failedOps[id]}
																				style={{ color: theme.palette.warning.main }}
																				tooltip="Email address missing"
																			/>
																		</div>
																	),
																},
																{
																	key: 'id',
																	title: 'Actions',
																	align: 'right',
																	padding: 'none',
																	format: (id, inv) => (
																		<Box whiteSpace="nowrap">
																			{actions().filter((action) => action.cond(inv) && !action.noLineIcon).map((action) => (
																				<Icon
																					pic={action.icon}
																					tooltip={action.name}
																					key={action.name}
																					onClick={() => this.performAction(action, [inv])}
																				/>
																			))}
																			<Icon
																				pic={EditIcon}
																				tooltip="Edit"
																				show={inv.status === 'INIT'}
																				href={`/invoicing/${inv.id}`}
																			/>
																			<a
																				href={inv.status === 'NON_EXISTING'
																					? Invoice.asUrl('getPdfFromData', { invoiceData: inv })
																					: Invoice.asUrl('getPdf', {}, id)}
																				target="_blank"
																				rel="noreferrer"
																				onClick={(e) => e.stopPropagation()}
																			>
																				<Icon
																					tooltip="Download as PDF"
																					pic={PdfIcon}
																				/>
																			</a>
																			<Icon
																				tooltip="Preview"
																				pic={PreviewIcon}
																				onClick={() => this.setState({ previewInvoice: inv })}
																			/>
																		</Box>
																	),
																},
															].filter((d) => !d.hide)}
															data={invoices}
														/>
													</Grid>
												</Grid>
												<PopupSelector
													title="Preview"
													size="lg"
													hideLink
													expanded={!!previewInvoice}
													onExpandChange={(exp) => !exp && this.setState({ previewInvoice: null })}
													fn={async () => {
														let doc;
														if (previewInvoice.status === 'NON_EXISTING') {
															doc = await Invoice.call('getDocumentByData', { invoiceData: previewInvoice });
														} else {
															doc = (await Invoice.call('getInvoice', { id: previewInvoice.id })).document;
														}
														this.setState({ previewDoc: doc });
													}}
												>
													{previewDoc && previewInvoice
													&& (
														<div>
															<Card>
																<CardContent>
																	<Typography variant="h2">
																		Email recipient(s)
																	</Typography>
																	{previewInvoice.emailAddress}
																</CardContent>
															</Card>
															<Card>
																<CardContent>
																	<Typography variant="h2">
																		Subject
																	</Typography>
																	{previewDoc.subject}
																</CardContent>
															</Card>
															<Card>
																<CardContent>
																	<Typography variant="h2">
																		Body
																	</Typography>
																	<HtmlContainer html={previewDoc.html} />
																</CardContent>
															</Card>
														</div>
													)}
												</PopupSelector>
											</CardContent>
										</Card>
									</OperationWrapper>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<Dialog
					open={!!errorMsg}
					text={errorMsg}
					onClose={() => this.setState({ errorMsg: null })}
				/>
				{confirmation && <ConfirmDialog {...confirmation} />}
			</>
		);
	}
}

InvoiceWorkspace.propTypes = {
	docTemplates: PropTypes.array.isRequired,
	publishers: PropTypes.array.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	onlyProduct: PropTypes.string,
};

InvoiceWorkspace.defaultProps = {
	onlyProduct: null,
};

export default withTheme(InvoiceWorkspace);
