// import PropTypes from 'prop-types';
import React from 'react';
import {
	Container,
	Paper,
	Divider,
	Box,
	Typography,
	Tabs,
	Tab,
} from '@mui/material';

import PasswordEditor from '../../containers/PasswordEditor';
import { stores } from '../../stores';
import UserOptions from '../../components/UserOptions';

function UserPage() {
	return (
		<Container maxWidth="md">
			<Paper>
				<Box sx={{ pt: 3, pb: 3 }}>
					<UserOptions displaySettingsButton={false} />
				</Box>
				{ !stores.identity.isGhosting() && (
					<>
						<Divider />
						<Box sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							mt: 2,
							pl: 2,
							pr: 2,
							pt: 1,
						}}
						>
							<PasswordEditor identity={stores.identity} />
						</Box>
					</>
				)}
			</Paper>
		</Container>
	);
}

export default UserPage;
