import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import OperationWrapper from '../OperationWrapper';
import MiscUtils from '../../lib/miscUtils';
import RecordTableTools from '../RecordTableTools';
import { CintSegment, CintData, CxReports } from '../../api/relevant';
import Select from '../Select';
import DataTable from '../DataTable';
import PopupSelector from '../PopupSelector';
import { withUpdate, FormOf } from '../Wrappers';
import SystemData from '../../lib/systemData';
import FormCollection from '../../lib/formCollection';
import CheckboxGroup from '../CheckboxGroup';
import ExpandSelector from '../ExpandSelector';
import SearchBar from '../SearchBar';
import JobButton from '../JobButton';
import Checkbox from '../Checkbox';

const FILTER_DEFAULT_WIDTH = 400;
const VALUE_SEARCH_MIN = 50;

const newCintSegment = () => {
	const seg = new SystemData.models.CintSegment();
	seg.filter.id = MiscUtils.objectIdStr();
	return seg;
};

const defaultFilter = (type) => {
	const seg = newCintSegment();
	const res = Object.assign(seg.filter, { type });
	if (type === 'not') {
		res.notFilter = defaultFilter('match');
	}
	return res;
};

const isAndOr = (str) => str === 'and' || str === 'or';

@withUpdate
class CintEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.forms = new FormCollection();
		this.byFilterLastCol = new Map();
	}

	parentOf(filter, notNot) {
		const { filter: root } = this.state.current || {};
		if (!root || filter === root) {
			return null;
		}
		const search = (parent) => {
			const { type, subFilters, notFilter } = parent;
			if (type === 'not') {
				if (notFilter === filter) {
					return notNot ? this.parentOf(parent, true) : parent;
				}
				return search(notFilter);
			} if (type === 'or' || type === 'and') {
				for (const sub of subFilters) {
					if (sub === filter) {
						return parent;
					}
					const subRes = search(sub);
					if (subRes) {
						return subRes;
					}
				}
			}
			return null;
		};
		return search(root);
	}

	updateCurrent(newState) {
		const { current } = newState;
		const defaultCreateCxSegs = current && current.isNew;
		this.setState({
			matches: null,
			cxMatches: null,
			createSettings: {
				createExternalSeg: defaultCreateCxSegs,
				createTrafficSeg: defaultCreateCxSegs,
				createLookalikeSeg: defaultCreateCxSegs,
			},
			...newState,
		});
	}

	renderMatchFilter(filter) {
		const { metaData } = this.state;
		const { mapping } = metaData;
		let valuesExpanded = false;
		this.byFilterLastCol.set(filter, filter.col);
		const allQuestions = MiscUtils.alphaSorted(_.map(mapping, ({ count }, question) => ({
			label: `${question} (${count})`,
			value: question,
		})), 'label');
		return (
			<FormOf
				model={filter}
				formCollection={this.forms}
				onFieldUpdate={(name) => {
					if (name === 'col') {
						filter.values = [];
					}
				}}
				content={({ field, form }) => {
					const { col, values } = filter;
					const allValues = col && MiscUtils.alphaSorted(_.map((mapping[col] || {}).values, (valCount, opt) => ({
						text: `${opt} (${valCount})`,
						id: opt,
					})), 'text');
					const showValueSearch = allValues && allValues.length >= VALUE_SEARCH_MIN && allValues.find(({ id }) => parseInt(id, 10).toString() !== id);
					if (col && this.byFilterLastCol.get(filter) !== col) {
						valuesExpanded = !showValueSearch;
						this.byFilterLastCol.set(filter, col);
					}
					return (
						<>
							<Grid item xs={12}>
								<SearchBar
									getIdentifier={(obj) => obj.value}
									getName={(obj) => obj.label}
									loadAllRows={() => allQuestions}
									selected={col ? [col] : []}
									label="Search question"
									onChange={(__, newArr) => form.setVals({ col: newArr.length ? _.last(newArr).value : null })}
									onlySingleSelect
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<Select
									{...field('col')}
									label="All Question"
									required
									items={allQuestions}
									fullWidth
								/>
							</Grid>
							{col && (
								<>
									{showValueSearch && (
										<Grid item xs={12}>
											<SearchBar
												key={col}
												getIdentifier={(obj) => obj.id}
												getName={(obj) => obj.text}
												loadAllRows={() => allValues}
												selected={values}
												label="Search answers"
												onChange={(__, items) => form.update(() => { filter.values = items.map((i) => i.id); })}
												fullWidth
											/>
										</Grid>
									)}
									<Grid item xs={12}>
										<ExpandSelector
											title="Answers"
											selected={values}
											getSingleTitle={(v) => v}
											expanded={valuesExpanded}
											onExpandChange={(expanded) => form.update(() => { valuesExpanded = expanded; })}
										>
											<CheckboxGroup
												items={allValues}
												selected={values}
												onChange={(items) => form.update(() => { filter.values = items; })}
											/>
										</ExpandSelector>
									</Grid>
								</>
							)}
						</>
					);
				}}
			/>
		);
	}

	renderFilter(filter) {
		const {
			subFilters, type, notFilter, valCondition,
		} = filter;
		const parent = this.parentOf(filter);
		const canDelete = parent && (parent.type !== 'not' || this.parentOf(filter, true));
		const renderSubFilters = () => (
			<>
				<Grid item xs={12}>
					<Box
						display="flex"
						flexWrap="wrap"
						marginX={-2}
						marginY={-1}
					>
						{subFilters.map((subFilter, idx) => (
							<Box
								key={subFilter.id}
								flexShrink={1}
								flexGrow={1}
								width="50%"
								minWidth={FILTER_DEFAULT_WIDTH}
								paddingX={2}
								paddingY={1}
							>
								<Box
									component="strong"
									color="success.main"
								>
									{idx === 0 ? <>&nbsp;</> : (type === 'or' ? 'OR' : 'AND')}
								</Box>
								{this.renderFilter(subFilter)}
							</Box>
						))}
					</Box>
				</Grid>
				<Grid item xs={12}>
					<ActionButton
						color="primary"
						label="Add filter"
						icon={<AddIcon />}
						onClick={() => this.update(() => {
							filter.subFilters.push(defaultFilter('match'));
						})}
					/>
				</Grid>
			</>
		);
		const byType = {
			match: () => this.renderMatchFilter(filter),
			or: renderSubFilters,
			and: renderSubFilters,
			not: () => (
				<Grid item xs={12}>
					<Box
						component="strong"
						color="error.main"
					>
						NOT
					</Box>
					{this.renderFilter(notFilter)}
				</Grid>
			),
		};
		return (
			<Box
				border={2}
				borderColor="primary.main"
				borderRadius="10px"
				padding={3}
				position="relative"
				boxShadow={3}
			>
				<Grid container spacing={3}>
					<Grid item xs={type === 'match' ? 6 : 12}>
						<Select
							name="type"
							label="Type"
							items={[
								{ label: 'Match', value: 'match' },
								{ label: 'Or', value: 'or' },
								{ label: 'And', value: 'and' },
								{ label: 'Not', value: 'not' },
							]}
							value={type}
							onChange={(ev) => this.update(() => {
								if (isAndOr(type) && isAndOr(ev.target.value)) {
									filter.type = ev.target.value;
								} else {
									_.keys(filter).forEach((key) => {
										delete filter[key];
									});
									Object.assign(filter, defaultFilter(ev.target.value));
								}
							})}
							fullWidth
						/>
					</Grid>
					{type === 'match' && (
						<Grid item xs={6}>
							<Select
								name="valCondition"
								label="Condition"
								items={[
									{ label: 'Or', value: 'or' },
									{ label: 'And', value: 'and' },
								]}
								value={valCondition}
								onChange={(ev) => this.update(() => { filter.valCondition = ev.target.value; })}
								fullWidth
							/>
						</Grid>
					)}
					{canDelete && (
						<Tooltip title="Delete">
							<IconButton
								style={{ position: 'absolute', right: 0, top: 0 }}
								onClick={() => this.update(() => {
									let p = parent;
									let f = filter;
									while (p.type === 'not') {
										f = p;
										p = this.parentOf(p);
									}
									_.pull(p.subFilters, f);
								})}
								size="large"
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					)}
					{byType[type]()}
				</Grid>
			</Box>
		);
	}

	renderFilterCopy() {
		const { cintSegments, current } = this.state;
		return (
			<FormOf
				model={{}}
				formCollection={this.forms}
				content={({ form, field }) => {
					const { model } = form.props;
					const otherSegments = cintSegments.filter((s) => s.id !== current.id);
					return (
						<PopupSelector
							title="Copy filters"
							onApplyChanges={() => {
								this.update(() => {
									const other = cintSegments.find((s) => s.id === model.segmentId).filter;
									current.filter = _.cloneDeepWith(other, (__, key) => (key === 'id' ? MiscUtils.objectIdStr() : undefined));
									model.segmentId = null;
								});
							}}
							actionButtonProps={() => ({ disabled: !model.segmentId })}
							onCancel={() => this.update(() => {
								model.segmentId = null;
							})}
						>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<SearchBar
										loadAllRows={() => otherSegments}
										selected={model.segmentId ? [model.segmentId] : []}
										label="Search Cint segments"
										onChange={(__, newArr) => form.setVals({ segmentId: newArr.length ? _.last(newArr).id : null })}
										onlySingleSelect
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										{...field('segmentId')}
										label="All Cint segments"
										items={otherSegments.map(({ id, name }) => ({ label: name, value: id }))}
										fullWidth
									/>
								</Grid>
							</Grid>

						</PopupSelector>
					);
				}}
			/>
		);
	}

	renderCxSegmentState() {
		const { current, createSettings } = this.state;
		const { externalSegDmpId, trafficSegDmpId, lookalikeSegDmpId } = current || {};
		return (
			<FormOf
				model={createSettings}
				formCollection={this.forms}
				content={({ field, form }) => {
					const { createExternalSeg, createTrafficSeg } = form.props.model;
					const canHaveTraffic = externalSegDmpId || createExternalSeg;
					const canHaveLookalike = canHaveTraffic && (trafficSegDmpId || createTrafficSeg);
					const labelOf = (type, id) => (
						<div>
							<span style={{ fontStyle: 'italic' }}>{type}</span>
							{' '}
							id:
							<span style={{ fontWeight: 'bold' }}>{id}</span>
						</div>
					);
					return (
						<>
							{externalSegDmpId ? (
								labelOf('External', externalSegDmpId)
							) : (
								<Checkbox
									{...field('createExternalSeg')}
									label="Create External segment"
								/>
							)}
							{trafficSegDmpId && (
								labelOf('Traffic', trafficSegDmpId)
							)}
							{!trafficSegDmpId && canHaveTraffic && (
								<Checkbox
									{...field('createTrafficSeg')}
									label="Create Traffic segment"
								/>
							)}
							{lookalikeSegDmpId && (
								labelOf('Lookalike', lookalikeSegDmpId)
							)}
							{!lookalikeSegDmpId && canHaveLookalike && (
								<Checkbox
									{...field('createLookalikeSeg')}
									label="Create Lookalike segment"
								/>
							)}
						</>
					);
				}}
			/>
		);
	}

	renderSegmentEdit({ field, form }) {
		const {
			current, matches, cxMatches, cintSegments, siteGroups,
		} = this.state;
		return (
			<Grid container spacing={3} alignItems="center">
				<Grid item xs={3}>
					<TextField
						{...field('name')}
						label="Name"
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={2}>
					<JobButton
						label="Check matches"
						fn={async () => {
							if (this.forms.checkErrors({ filter: (f) => f !== form })) {
								throw Error('Please correct all errors before checking matches');
							}
							this.setState({ matches: null, cxMatches: null });
							const { matches: newMatches, cxMatches: newCxMatches } = await CintData.call('getMatchInfo', { filter: current.filter });
							this.setState({ matches: newMatches, cxMatches: newCxMatches });
						}}
						color="secondary"
					/>
					{matches != null && (
						<Box component="strong" color="success.main">{`${matches} (CxIds: ${cxMatches})`}</Box>
					)}
				</Grid>
				<Grid item xs={2}>
					<TextField
						{...field('lookalikePercent')}
						label={'Lookalike\xa0%'}
						float
						required
						between={{ low: 0, high: 100 }}
						fullWidth
					/>
				</Grid>
				<Grid item xs={3}>
					{this.renderCxSegmentState()}
				</Grid>
				<Grid item xs={2}>
					<Grid container spacing={3} alignItems="center">
						<Grid item xs={12}>
							<Select
								{...field('cxSiteGroupId')}
								label="Site group"
								items={siteGroups.map(({ id, name }) => ({ label: name, value: id }))}
							/>
						</Grid>
						<Grid item xs={12}>
							{!!cintSegments.length && this.renderFilterCopy()}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{this.renderFilter(current.filter)}
				</Grid>
			</Grid>
		);
	}

	render() {
		const { onCancel } = this.props;
		const { cintSegments, current } = this.state;
		return (
			<>
				<Grid item xs={12}>
					<Card>
						<CardContent>
							<OperationWrapper
								fn={async (op) => {
									this.op = op;
									const [segments, meta, siteGroups] = await Promise.all([
										CintSegment.list(),
										CintData.call('getMetaData'),
										CxReports.call('getSiteGroups'),
									]);
									this.setState({
										cintSegments: MiscUtils.alphaSorted(segments, 'name'),
										metaData: meta,
										siteGroups: MiscUtils.alphaSorted(_.values(siteGroups.groups), 'name'),
									});
								}}
								content={() => (
									<Grid container spacing={3}>
										{current && (
											<FormOf
												model={current}
												formCollection={this.forms}
												content={(p) => (
													<PopupSelector
														size="lg"
														onApplyChanges={async ({ preventDefault }) => {
															if (this.forms.checkErrors()) {
																preventDefault();
																return;
															}
															let newSegList;
															const params = { ...this.state.createSettings, segment: current };
															if (current.isNew) {
																const newSeg = await CintSegment.call('addCintSegment', params);
																newSegList = cintSegments.concat(newSeg);
															} else {
																const updatedSeg = await current.updateWithSettings(params);
																newSegList = cintSegments.filter((s) => s.id !== current.id).concat(updatedSeg);
															}
															this.op.reload(() => this.updateCurrent({
																current: null,
																cintSegments: MiscUtils.alphaSorted(newSegList, 'name'),
															}));
														}}
														onCancel={() => this.updateCurrent({ current: null })}
														content={() => this.renderSegmentEdit(p)}
														onAfterErrorMsg={() => {
															this.updateCurrent({ current: null });
															this.op.reload();
														}}
														title={current.isNew ? 'New Cint Segment' : 'Edit Cint Segment'}
														hideLink
														expanded
													/>
												)}
											/>
										)}
										<Grid item xs={12}>
											<Typography variant="h2">
												List of Cint Segment
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<DataTable
												showCheckboxes={false}
												selectableRows={false}
												identifier={(row) => row.id}
												definitions={[
													{
														key: 'name',
														title: 'Name',
													},
													...[
														['externalSegDmpId', 'External segment ID'],
														['trafficSegDmpId', 'Traffic segment ID'],
														['lookalikeSegDmpId', 'Lookalike segment ID'],
													].map(([key, title]) => ({
														key: 'id',
														title,
														align: 'right',
														format: (__, seg) => seg[key] || '(none)',
													})),
													{
														key: 'id',
														title: '',
														align: 'right',
														format: (id, cintSeg) => (
															<RecordTableTools
																editAction={() => this.updateCurrent({ current: _.cloneDeep(cintSeg) })}
																deleteAction={() => this.op.reload(async () => {
																	this.op.reload(async () => {
																		await cintSeg.delete();
																		this.setState({ cintSegments: _.without(cintSegments, cintSeg) });
																	});
																})}
															/>
														),
													},
												]}
												data={MiscUtils.alphaSorted(cintSegments, 'name')}
											/>
										</Grid>
										<Grid item>
											<ActionButton
												color="primary"
												label="New Cint segment"
												icon={<AddIcon />}
												onClick={() => this.updateCurrent({ current: newCintSegment() })}
											/>
										</Grid>
									</Grid>
								)}
							/>
						</CardContent>
					</Card>
				</Grid>
				<Grid item>
					<ActionButton
						label="Cancel"
						onClick={() => onCancel()}
						variant="text"
						color="primary"
					/>
				</Grid>
			</>
		);
	}
}

CintEditor.propTypes = {
	onCancel: PropTypes.func.isRequired,
};

CintEditor.defaultProps = {
};

export default CintEditor;
