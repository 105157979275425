import  _ from 'lodash';
import Constants from 'relevant-shared/reportData/constants';
import SummaryHbCustomizer from 'relevant-shared/reportData/customizers/summaryHbCustomizer';
import FrontendHbCustomizer from './frontendHbCustomizer';
import SystemData from '../../../lib/systemData';

class FrontendSummaryHbCustomizer extends SummaryHbCustomizer {
	getCustomFilters(...args) {
		const hbCustomizer = new FrontendHbCustomizer(this.reportData);
		return hbCustomizer.getCustomFilters(...args);
	}

	splitMetricsToUiGroups(metrics, { sums }) {
		const { ADS_METRICS_EXPANDED } = Constants.summary_hb;
		const adsMetrics = _.pickBy(metrics, (v, k) => ADS_METRICS_EXPANDED.includes(k));
		const normalMetrics = _.pickBy(metrics, (v, k) => !adsMetrics[k]);
		const { hasHbSummaryAdsData } = SystemData.genericData;
		const res = [{ metrics: normalMetrics }];
		if (hasHbSummaryAdsData || sums.some((s) => ADS_METRICS_EXPANDED.includes(s))) {
			res.push({ title: 'Adserver metrics', metrics: adsMetrics });
		}
		return res;
	}
}

export default FrontendSummaryHbCustomizer;
