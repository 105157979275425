import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import 'brace/mode/html';
import 'brace/theme/textmate';
import 'brace/ext/searchbox';

class TextEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			onChange, style, mode, error, name,
		} = this.props;
		return (
			<div
				style={{ outline: error ? '3px red solid' : 'none' }}
				{...(name && { name })}
			>
				<AceEditor
					mode={mode}
					theme="textmate"
					name={`rand_${Math.random()}`}
					width="100%"
					style={style}
					editorProps={{ $blockScrolling: true }}
					{...(_.omit(this.props, ['onChange']))}
					onChange={(value) => onChange({ target: { name: this.props.name, value } }, this.props)}
				/>
			</div>
		);
	}
}

TextEditor.propTypes = {
	error: PropTypes.string,
	onChange: PropTypes.func,
	name: PropTypes.string.isRequired,
	style: PropTypes.object,
	mode: PropTypes.string,
	name: PropTypes.string,
};

TextEditor.defaultProps = {
	error: undefined,
	onChange: () => {},
	style: null,
	mode: 'javascript',
	name: undefined,
};

export default TextEditor;
