import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Popover,
	Tabs,
	Tab,
	Box,
} from '@mui/material';
import IssueList from './IssueList';

const issueType = {
	all: 'all',
	warning: 'warning',
	error: 'error',
};

function CsvIssueTrackerPopup({
	isOpen,
	errors,
	warnings,
	anchorEl,
	onClose,
	onIgnore,
}) {
	const [selectedListType, setSelectedListType] = useState(issueType.all);

	let issues = [...errors, ...warnings];

	if (selectedListType === issueType.error) {
		issues = errors;
	} else if (selectedListType === issueType.warning) {
		issues = warnings;
	}

	return (
		<Popover
			open={isOpen}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<Box sx={{
				pl: 4,
				pr: 4,
				pb: 4,
			}}
			>
				<h3>Problems</h3>
				<Tabs
					sx={{
						mb: 2,
					}}
					value={selectedListType}
					aria-label="list of issue types"
					onChange={(e, val) => setSelectedListType(val)}
				>

					<Tab
						sx={{ textTransform: 'capitalize' }}
						label="All"
						value={issueType.all}
					/>
					<Tab
						sx={{ textTransform: 'capitalize' }}
						label="Warnings"
						value={issueType.warning}
					/>
					<Tab
						sx={{ textTransform: 'capitalize' }}
						label="Errors"
						value={issueType.error}
					/>
				</Tabs>
				<IssueList
					issues={issues}
					onIgnore={onIgnore}
				/>
			</Box>
		</Popover>
	);
}

CsvIssueTrackerPopup.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	anchorEl: PropTypes.instanceOf(Element),
	errors: PropTypes.arrayOf(PropTypes.shape({
		type: PropTypes.oneOf(['error']).isRequired,
		header: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
	})),
	warnings: PropTypes.arrayOf(PropTypes.shape({
		type: PropTypes.oneOf(['warning']).isRequired,
		header: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
	})),
	onClose: PropTypes.func.isRequired,
	onIgnore: PropTypes.func.isRequired,
};

CsvIssueTrackerPopup.defaultProps = {
	anchorEl: null,
	errors: {},
	warnings: {},
};

export default CsvIssueTrackerPopup;
