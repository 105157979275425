// Admin, "Full", programmatic or audience user
import { stores } from '../stores';

export const PRODUCT_TYPE = {
	AUDIENCE: 'audience',
	PROGRAMMATIC: 'programmatic',
	FULL: 'full',
	ADMIN: 'admin',
};

export function getProductType() {
	if (stores.identity.isAdministrator()) {
		return 'admin';
	} if (stores.identity.isAudiencePublisher() && stores.identity.isProgrammaticPublisher()
		&& !stores.identity.isSuperAdministrator()) {
		return 'full';
	}
	if (stores.identity.isAudiencePublisher() && !stores.identity.isSuperAdministrator()) {
		return 'audience';
	} if (stores.identity.isProgrammaticPublisher() && !stores.identity.isSuperAdministrator()) {
		return 'programmatic';
	}
	return '';
}
