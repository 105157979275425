import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip
		placement="top-start"
		{...props}
		classes={{ popper: className }}
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		border: 'none',
		backgroundColor: '#DFE1E6',
		padding: '4px 8px',
		fontSize: '13px',
		fontWeight: '400',
		color: '#313233',
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: '#DFE1E6',
	},
	// Offset
	[`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
		marginBottom: '4px',
	},
	[`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
		marginTop: '4px',
	},
	[`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
		marginRight: '4px',
	},
	[`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
		marginLeft: '4px',
	},
}));