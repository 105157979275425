import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@mui/material/Grid';
import SettingsIcon from '@mui/icons-material/Settings';
import { getGlobalSettings } from 'relevant-shared/objects/objectStore';
import FieldArray from './fieldArray';
import GenericFieldArray from '../FieldArray';
import TextEditor from './textEditor';
import OperationWrapper from '../OperationWrapper';
import { ensureLoaded } from '../../lib/objectStoreUtils';
import { ActionButton } from '../ActionButton/ActionButton';
import JobButton from '../JobButton';
import ExpandSelector from '../ExpandSelector';
import { PopupForm, withUpdate } from '../Wrappers';
import TextField from '../TextField';
import MiscUtils from '../../lib/miscUtils';

@withUpdate
class TagEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	/** Edit popup for custom option/object types, saves directly to backend when pressing "Save". */
	renderCustomObjectEdit() {
		return (
			<PopupForm
				size="md"
				title="Global custom option types"
				okLabel="Save"
				customLink={(showFn) => (
					<ActionButton
						style={{ margin: 10 }}
						label="Edit global custom option types"
						icon={<SettingsIcon />}
						onClick={(ev) => {
							ev.stopPropagation();
							showFn();
						}}
					/>
				)}
				getModel={() => _.cloneDeep(_.pick(getGlobalSettings(), 'customTagObjects'))}
				content={(p) => (
					<GenericFieldArray
						model={p.model}
						name="customTagObjects"
						field={p.field}
						update={() => p.form.update()}
						createNew={() => ((id) => ({ id, _id: id }))(MiscUtils.objectIdStr())}
						beforeHeader={(fld)=> (
							<Grid item xs={6}>
								<TextField
									{...fld('name')}
									required
									label="Type name"
									fullWidth
									margin="normal"
								/>
							</Grid>
						)}
						render={(fld, elm) => (
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<ExpandSelector title="Options" style={{ width: '100%' }}>
										<GenericFieldArray
											model={elm}
											name="options"
											field={fld}
											update={() => p.form.update()}
											beforeHeader={(field) => (
												<Grid container spacing={3}>
													<Grid item xs={4}>
														<TextField
															{...field('name')}
															required
															label="Name"
															margin="normal"
														/>
													</Grid>
													<Grid item xs={8}>
														<TextField
															{...field('description')}
															required
															label="Description"
															margin="normal"
														/>
													</Grid>
												</Grid>
											)}
										/>
									</ExpandSelector>
								</Grid>
							</Grid>
						)}
					/>
				)}
				onApplyChanges={({ model }) => this.updateAsync(async () => {
					await getGlobalSettings().update({
						...model,
						updateParams: { returnUpdatedObject: true },
					});
					// Make sure the static fields in ObjectStore (like ObjectStore.CustomObjectNames) are updated
					// So that new/updated options can be selected in the editor we're going back to.
					await ensureLoaded();
				})}
			/>
		);
	}

	renderInternal() {
		const {
			model, field, onCancel, submit, loadDefaults, noJsOutput,
		} = this.props;
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<FieldArray
						model={model}
						field={field}
						startExpanded={noJsOutput}
						title={(
							<div style={{ display: 'flex' }}>
								<div style={{ flexGrow: 1 }}>Fields</div>
								{this.renderCustomObjectEdit()}
							</div>
						)}
					/>
				</Grid>
				{!noJsOutput && (
					<Grid item xs={12}>
						<ExpandSelector title="JavaScript output">
							<TextEditor {...field('jsOutput')} />
						</ExpandSelector>
					</Grid>
				)}
				<Grid item>
					<ActionButton
						label="Save"
						color="primary"
						onClick={submit}
					/>
				</Grid>
				{onCancel && (
					<Grid item>
						<ActionButton
							label="Cancel"
							onClick={() => onCancel()}
							variant="text"
							color="primary"
						/>

					</Grid>
				)}
				{loadDefaults && (
					<Grid item>
						<JobButton
							label="Load defaults"
							color="secondary"
							fn={async () => {
								Object.assign(model, await loadDefaults());
								this.forceUpdate();
							}}
						/>
					</Grid>
				)}
			</Grid>
		);
	}

	render() {
		return (
			<OperationWrapper
				disableMode
				fn={ensureLoaded}
				style={{ width: '100%' }}
				content={() => this.renderInternal()}
			/>
		);
	}
}

TagEditor.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	submit: PropTypes.func.isRequired,
	loadDefaults: PropTypes.func,
	noJsOutput: PropTypes.bool,
};

TagEditor.defaultProps = {
	loadDefaults: undefined,
	onCancel: undefined,
	noJsOutput: false,
};

export default TagEditor;
