import React, { useState, useEffect, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
	Autocomplete, Alert,
	TextField,
	Chip,
	InputAdornment,
	Grid,
	CircularProgress,
	Grow,
	Button,
	Collapse,
} from '@mui/material';
import { relevant } from '../../api';
import { PrebidBuildHandler } from '../../api/relevant';
import useRequest from '../../hooks/useRequest/useRequest';
import useIsMounted from '../../hooks/useIsMounted';
import SimpleOperationWrapper from '../SimpleOperationWrapper';
import { linkColor } from '../../styles/colors';

const PREFIX = 'PrebidBuildForm';

const prebidModulesRequestName = 'prebidModules';
const prebidVersionsRequestName = 'prebidVersions';

function getErrorMessage(hasUnsupportedAutoAddedModules, hasUnsupportedAdditionalModules) {
	if (hasUnsupportedAutoAddedModules && hasUnsupportedAdditionalModules) {
		return `Some of the automatically included as well as selected Prebid modules are
 not available in this version of Prebid.`;
	}
	if (hasUnsupportedAutoAddedModules) {
		return 'Some of the automatically included Prebid modules are not available in this version of Prebid.';
	} if (hasUnsupportedAdditionalModules) {
		return 'Some of the selected modules are not available in this version of Prebid.';
	}
	return '';
}

function PrebidBuildForm({
	version,
	name,
	additionalModules,
	pbjsGlobalName,
	onVersionChange,
	onNameChange,
	onPbjsGlobalNameChange,
	onAdditionalModulesChange,
	renderContent,
	renderActions,
	onLoadStateChange,
}) {
	const isMounted = useIsMounted();
	const [setRequest, loading, error, reset, requestName] = useRequest({});
	const [availableVersions, setAvailableVersions] = useState([]);
	const [availableModules, setAvailableModules] = useState();
	// used ssps, user id modules etc
	const [autoIncludedModules, setAutoIncludedModules] = useState([]);
	const [displayErrorDetails, setDisplayErrorDetails] = useState(false);

	const isLatestVersion = (v) => (availableVersions.indexOf(v) === 0);

	useEffect(() => {
		if (isMounted.current && availableVersions.length === 0) {
			setRequest({
				requestName: prebidVersionsRequestName,
				requestFunction: () => relevant.get('/prebid/versions'),
				requestCallback: ({ versions }) => {
					if (isMounted.current) {
						setAvailableVersions(versions);
					}
				},
			});
		}
	}, [setRequest, isMounted, onVersionChange, version, availableVersions.length]);

	useEffect(() => {
		// Don't allow empty version
		if (!version && availableVersions.length > 0) {
			onVersionChange(availableVersions[0]);
		}
	}, [availableVersions, onVersionChange, version]);

	useEffect(() => {
		if (isMounted.current && version && availableVersions.length > 0) {
			setRequest({
				requestName: prebidModulesRequestName,
				requestFunction: () => Promise.all([
					relevant.get(`/prebid/versions/${version}/modules`),
					PrebidBuildHandler.call('getAutoIncludedModules', { prebidVersions: [version] }),
				]),
				requestCallback: ([{ modules: availModules }, usedModules]) => {
					if (isMounted.current) {
						setAvailableModules(availModules || []);
						setAutoIncludedModules(usedModules[version]);
					}
				},
			});
		}
	}, [version, isMounted, setRequest, availableVersions.length]);

	useEffect(() => {
		if (onLoadStateChange) {
			onLoadStateChange(loading);
		}
	}, [loading, onLoadStateChange]);

	const loadingModuleOptions = loading && requestName === prebidModulesRequestName;
	const loadingVersionOptions = loading && requestName === prebidVersionsRequestName;

	const availableModulesNotInAutoIncluded = (availableModules || []).filter((mod) => autoIncludedModules.indexOf(mod) === -1);

	const unavailableAdditionalModules = additionalModules
		.filter((mod) => availableModulesNotInAutoIncluded.indexOf(mod) === -1);
	const unavailableAutoAddedModules = autoIncludedModules.filter((mod) => availableModules && availableModules.indexOf(mod) === -1);
	const hasErrors = (availableModules !== undefined) && ((unavailableAdditionalModules && unavailableAdditionalModules.length > 0)
		|| (unavailableAutoAddedModules && unavailableAutoAddedModules.length > 0));

	return (
		<SimpleOperationWrapper
			loading={false} // Never show "big" loading symbol
			error={error}
			onErrorModalClick={reset}
		>
			{ renderContent(
				<>
					<Grow in={!!hasErrors} mountOnEnter unmountOnExit>
						<Alert
							severity="error"
							elevation={0}
							style={{ marginBottom: 24, marginTop: -8 }}
							action={(
								<Button
									style={{ color: linkColor }}
									size="small"
									onClick={() => setDisplayErrorDetails(!displayErrorDetails)}
								>
									{ displayErrorDetails ? 'OVERVIEW' : 'DETAILS' }
								</Button>
							)}
						>
							<>
								<Collapse in={!displayErrorDetails} mountOnEnter unmountOnExit>
									{ getErrorMessage(
										unavailableAutoAddedModules.length > 0,
										unavailableAdditionalModules.length > 0,
									)}
								</Collapse>
								<Collapse in={displayErrorDetails} mountOnEnter unmountOnExit>
									<b>Unavailable modules: </b>
									<span>
										{[...unavailableAutoAddedModules, ...unavailableAdditionalModules].join(', ')}
									</span>
								</Collapse>
							</>
						</Alert>
					</Grow>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={4}>
							<Autocomplete
								options={availableVersions}
								value={version || null}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label="Version"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{ loadingVersionOptions && (
														<InputAdornment position="end">
															<CircularProgress />
														</InputAdornment>
													)}
													{ params.InputProps.endAdornment }
												</>
											),
										}}
										disabled={loadingVersionOptions}
									/>
								)}
								getOptionLabel={(option) => (isLatestVersion(option) ? `Latest (${option})` : option)}
								onChange={(_, value) => {
									// Don't allow empty value
									if (value) {
										onVersionChange(value);
									}
								}}
								fullWidth
								disableClearable
							/>
						</Grid>
						<Grid item xs={8}>
							<TextField
								variant="outlined"
								label="Name"
								required
								value={name}
								onChange={({ target }) => onNameChange(target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								placeholder="pbjs"
								variant="outlined"
								label="Override Prebid.js global variable name"
								value={pbjsGlobalName}
								onChange={({ target }) => onPbjsGlobalNameChange(target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								value={additionalModules}
								onChange={(_, v) => onAdditionalModulesChange(v)}
								options={availableModulesNotInAutoIncluded}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label="Extra modules"
										error={(availableModules !== undefined) && unavailableAdditionalModules.length > 0}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{ loadingModuleOptions && (
														<InputAdornment position="end">
															<CircularProgress />
														</InputAdornment>
													)}
													{ params.InputProps.endAdornment }
												</>
											),
										}}
									/>
								)}
								renderTags={(selected, getProps) => (
									selected.map((val, index) => {
										const hasError = ((availableModules !== undefined) && unavailableAdditionalModules.includes(val));
										return (
											<Chip
												sx={{
													bgcolor: hasError ? 'error.main' : 'success.light',
													color: hasError ? 'error.contrastText' : 'success.contrastText',
												}}
												{...getProps(val)}
												// onDelete shouldn't actually be needed
												// but its a workaround for bug https://github.com/mui-org/material-ui/issues/18081
												onDelete={() => {
													onAdditionalModulesChange(additionalModules.filter((v) => v !== val));
												}}
												key={index}
												label={val}
											/>
										);
									})
								)}
								disabled={loadingModuleOptions}
								limitTags={4}
								selectOnFocus
								blurOnSelect
								autoHighlight
								handleHomeEndKeys
								multiple
							/>
						</Grid>
					</Grid>
				</>,
			)}
			{ renderActions && renderActions(
				loading,
				unavailableAutoAddedModules.length === 0 && unavailableAdditionalModules.length === 0,
			)}
		</SimpleOperationWrapper>
	);
}

PrebidBuildForm.propTypes = {
	version: PropTypes.string,
	name: PropTypes.string,
	additionalModules: PropTypes.arrayOf(PropTypes.string),
	onVersionChange: PropTypes.func.isRequired,
	onNameChange: PropTypes.func.isRequired,
	onAdditionalModulesChange: PropTypes.func.isRequired,
	onPbjsGlobalNameChange: PropTypes.func.isRequired,
	renderContent: PropTypes.func,
	renderActions: PropTypes.func,
	onLoadStateChange: PropTypes.func,
};

PrebidBuildForm.defaultProps = {
	version: null,
	name: '',
	additionalModules: [],
	renderContent: (content) => content, // default render as-is
	renderActions: undefined,
	onLoadStateChange: undefined,
};

export default PrebidBuildForm;
