import React, { Fragment } from 'react';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { ActionButton } from '../ActionButton/ActionButton';
import { PopupForm } from '../Wrappers';
import TextField from '../TextField';
import Select from '../Select';
import Icon from '../Icon';
import { Alarm, AlarmOff, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

function TriggerFilterEdit({
	name, sum, isComp, orgSumInfo, triggerFilters, onUpdate,
}) {
	const findIn = (arr) => _.find(arr, { sum, isComp });
	const current = findIn(triggerFilters);
	return (
		<PopupForm
			getModel={() => _.cloneDeep(findIn(triggerFilters) || {
				sum,
				isComp,
				cond: 'lt',
				value: 0,
				...(isComp && {
					compCond: 'gtEq',
					compValue: null,
				}),
			})}
			size="sm"
			onApplyChanges={({ model }) => {
				const newFilters = _.cloneDeep(triggerFilters || []);
				const elm = findIn(newFilters);
				if (elm) {
					Object.assign(elm, model);
				} else {
					newFilters.push(model);
				}
				onUpdate(newFilters);
			}}
			customLink={(okFn) => (
				<Tooltip
					title={current ? `${name} ${current.cond === 'lt' ? '<' : '>='} ${current.value}` : 'Not set'}
				>
					<div>
						<ActionButton
							label={<>
								{current ? <CheckBox	/> : <CheckBoxOutlineBlank />}
								Alarm
							</>}
							onClick={okFn}
							variant={current ? 'contained' : 'outlined'}
						/>
					</div>
				</Tooltip>
			)}
			content={({ field, popup }) => (
				<Grid container spacing={3}>
					<Grid item xs={12}>
						{isComp ? (
							<span>
								Trigger alarm when
								{' '}
								<i>current</i>
								{' '}
								<b>{orgSumInfo.name}</b>
								{' '}
								is:
							</span>
						) : (
							<span>
								Trigger alarm when
								{' '}
								<b>{name}</b>
								{' '}
								is:
							</span>
						)}
					</Grid>
					<Grid item xs={5}>
						<Select
							label="Condition"
							{...field('cond')}
							fullWidth
							required
							items={[
								{ value: 'lt', label: 'Less than' },
								{ value: 'gtEq', label: 'At least' },
							]}
						/>
					</Grid>
					<Grid item xs={5}>
						<TextField
							label={isComp ? '% of this compare value' : 'Value'}
							fullWidth
							required
							float
							{...field('value')}
						/>
					</Grid>
					{current && (
						<Icon
							pic={DeleteIcon}
							tooltip="Delete condition"
							onClick={() => {
								const newFilters = _.cloneDeep(triggerFilters || []);
								const elm = findIn(newFilters);
								popup.forceClose();
								onUpdate(_.without(newFilters, elm));
							}}
						/>
					)}
					{isComp && (
						<>
							<Grid item xs={12}>
								<i>(optional)</i>
								{' '}
								But only when the
								<i>compare value</i>
								{' '}
								<b>{name}</b>
								{' '}
								is:
							</Grid>
							<Grid item xs={5}>
								<Select
									label="Condition"
									{...field('compCond')}
									fullWidth
									required
									items={[
										{ value: 'lt', label: 'Less than' },
										{ value: 'gtEq', label: 'At least' },
									]}
								/>
							</Grid>
							<Grid item xs={5}>
								<TextField
									label="Value"
									fullWidth
									float
									{...field('compValue')}
								/>
							</Grid>
						</>
					)}
				</Grid>
			)}
		/>
	);
}

TriggerFilterEdit.propTypes = {
	name: PropTypes.string.isRequired,
	sum: PropTypes.string.isRequired,
	isComp: PropTypes.bool,
	orgSumInfo: PropTypes.object,
	triggerFilters: PropTypes.array,
	onUpdate: PropTypes.func.isRequired,
};

TriggerFilterEdit.defaultProps = {
	isComp: false,
	orgSumInfo: undefined,
	triggerFilters: undefined,
};

export default TriggerFilterEdit;
