const moment = require('moment');
const _ = require('lodash');

class DateUtils {
	static lastWeek(now) {
		const start = moment(now || undefined).utc()
			.subtract(1, 'week')
			.startOf('week')
			.toDate();

		const end = moment(now || undefined).utc()
			.subtract(1, 'week')
			.endOf('week')
			.toDate();

		return { start, end };
	}

	static dateToEpoch(date, hour) {
		const d = DateUtils.fullDay(date);
		d.setUTCHours(hour);
		return d.getTime();
	}

	static currentHour(now) {
		const start = moment(now || undefined).utc().startOf('hour');
		return start;
	}

	static lastHour(now) {
		const start = DateUtils.currentHour(now).subtract(1, 'hour');
		return start;
	}

	static lastMonth(now) {
		const start = moment(now || undefined).utc()
			.subtract(1, 'month')
			.startOf('month')
			.toDate();

		const end = moment(now || undefined).utc()
			.subtract(1, 'month')
			.endOf('month')
			.toDate();

		return { start, end };
	}

	static lastThreeMonths(now) {
		const start = moment(now || undefined).utc()
			.subtract(3, 'month')
			.startOf('month')
			.toDate();

		const end = moment(now || undefined).utc()
			.subtract(1, 'month')
			.endOf('month')
			.toDate();

		return { start, end };
	}

	static prevThreeMonths(now) {
		const end = DateUtils.fullDay(now || new Date());
		const start = moment.utc(end)
			.startOf('month')
			.subtract(2, 'month')
			.toDate();

		return { start, end };
	}

	static last90Days(now) {
		const start = moment(now || undefined).utc()
			.subtract(90, 'day')
			.startOf('day')
			.toDate();

		const end = moment(now || undefined).utc()
			.subtract(1, 'day')
			.endOf('day')
			.toDate();

		return { start, end };
	}

	static lastSixMonths(now) {
		const start = moment(now || undefined).utc()
			.subtract(6, 'month')
			.startOf('month')
			.toDate();

		const end = moment(now || undefined).utc()
			.subtract(1, 'month')
			.endOf('month')
			.toDate();

		return { start, end };
	}

	static lastQuarter(now) {
		const start = moment(now || undefined).utc()
			.subtract(1, 'quarter')
			.startOf('quarter')
			.toDate();

		const end = moment(now || undefined).utc()
			.subtract(1, 'quarter')
			.endOf('quarter')
			.toDate();

		return { start, end };
	}

	static lastYear(now) {
		const start = moment(now || undefined).utc()
			.subtract(1, 'year')
			.startOf('year')
			.toDate();

		const end = moment(now || undefined).utc()
			.subtract(1, 'year')
			.endOf('year')
			.toDate();

		return { start, end };
	}

	static last30Days(now) {
		const start = DateUtils.fullDay(moment(now || undefined).utc()
			.subtract(29, 'days')
			.toDate());

		const end = DateUtils.fullDay(now || new Date());
		return { start, end };
	}

	static next30Days(now) {
		const d = now || new Date();
		return {
			start: DateUtils.fullDay(d, 0),
			end: DateUtils.fullDay(d, 29),
		};
	}

	static nextWeek(now) {
		const start = moment(now || undefined).utc()
			.add(1, 'week')
			.startOf('week')
			.toDate();

		const end = moment(now || undefined).utc()
			.add(1, 'week')
			.endOf('week')
			.toDate();

		return { start, end };
	}

	static nextMonth(now) {
		const start = moment(now || undefined).utc()
			.add(1, 'month')
			.startOf('month')
			.toDate();

		const end = moment(now || undefined).utc()
			.add(1, 'month')
			.endOf('month')
			.toDate();

		return { start, end };
	}

	static currentWeek(now) {
		const start = moment(now || undefined).utc()
			.startOf('week')
			.toDate();

		const end = DateUtils.fullDay(now || new Date());
		return { start, end };
	}

	static currentMonth(now) {
		const start = moment(now || undefined).utc()
			.startOf('month')
			.toDate();

		const end = DateUtils.fullDay(now || new Date());
		return { start, end };
	}

	static currentQuarter(now) {
		const start = moment(now || undefined).utc()
			.startOf('quarter')
			.toDate();

		const end = DateUtils.fullDay(now || new Date());
		return { start, end };
	}

	static currentYear(now) {
		const start = moment(now || undefined).utc()
			.startOf('year')
			.toDate();

		const end = DateUtils.fullDay(now || new Date());
		return { start, end };
	}

	static firstDayOfMonth(d) {
		return moment.utc(d).startOf('month').toDate();
	}

	static lastDayOfMonth(d) {
		return moment.utc(d)
			.add(1, 'month')
			.startOf('month')
			.subtract(1, 'days')
			.toDate();
	}

	static firstDayOfMonthChange(d, monthDiff) {
		return moment.utc(d)
			.add(monthDiff, 'month')
			.startOf('month')
			.toDate();
	}

	static lastDayOfMonthChange(d, monthDiff) {
		return DateUtils.lastDayOfMonth(DateUtils.firstDayOfMonthChange(d, monthDiff));
	}

	static fullDay(date, dateDiff = 0) {
		const d = new Date(moment.utc(new Date(date)).format('YYYY-MM-DD'));
		if (dateDiff) {
			d.setUTCDate(d.getUTCDate() + dateDiff);
		}
		return d;
	}

	static getTimeDifference(start, end, unit = 'ms') {
		return moment(end).diff(moment(start), unit);
	}

	static addDays(date, dayDiff) {
		const d = new Date(date);
		d.setDate(d.getDate() + dayDiff);
		return d;
	}

	static isRelativeDate(d) {
		const MAX_RELATIVE_DATE_NUM = 100000;
		if (_.isNumber(d)) {
			return d <= MAX_RELATIVE_DATE_NUM;
		}
		if (_.isString(d)) {
			const asInt = parseInt(d, 10);
			return asInt.toString() === d && asInt <= MAX_RELATIVE_DATE_NUM;
		}
		return false;
	}

	static toDate(d, now) {
		if (d === undefined) {
			return undefined;
		} if (DateUtils.isRelativeDate(d)) { // negative integer or integer as a string
			const daysBack = parseInt(d, 10);
			return DateUtils.fullDay(now || new Date(), daysBack);
		} if (moment(d).isValid()) { // e.g. Date object or '2017-10-10'
			return DateUtils.fullDay(d);
		}
		throw Error('Didn\'t find any way to convert d to a date');
	}

	static today() { return DateUtils.fullDay(new Date()); }

	static tomorrow() { return DateUtils.fullDay(new Date(), 1); }

	static yesterday() { return DateUtils.fullDay(new Date(), -1); }

	static daysFromToday(dateDiff) {
		return DateUtils.fullDay(new Date(), dateDiff);
	}

	static dates(from, to) {
		const endDate = DateUtils.fullDay(to);
		const res = [];
		for (let d = DateUtils.fullDay(from); d <= endDate; d.setUTCDate(d.getUTCDate() + 1)) {
			res.push(new Date(d));
		}
		return res;
	}

	static datesByMsPeriod(from, to, ms, breakAtNow, nowDate) {
		const periodsPerDay = (24 * 3600 * 1000) / ms;
		if (ms > 3600 * 1000 && periodsPerDay !== Math.floor(periodsPerDay)) {
			throw Error('Max one hour period supported right now..');
		}
		const res = [];
		const now = nowDate ? new Date(nowDate) : new Date();
		let until = DateUtils.fullDay(to, 1);
		if (breakAtNow && until > now) {
			until = now;
		}
		for (let d = new Date(from).getTime(); d < until; d += ms) {
			res.push(new Date(d));
		}
		return res;
	}

	static isToday(date, now) {
		const today = now || new Date();
		return date.getDate() === today.getDate()
			&& date.getMonth() === today.getMonth()
			&& date.getFullYear() === today.getFullYear();
	}

	static toUiDate(date) {
		let d = new Date(date);
		// Try to fix some time-zone imperfections in the code by assuming that if the UTC hour > 12 then
		// we probably want the next day, *except* if time is 23:59 as then it's probably the result of
		// moment.endOf() and thereby intentional.
		if (d.getUTCHours() > 12 && !(d.getUTCHours() === 23 && d.getUTCMinutes() === 59)) {
			d = new Date(d.getTime() + 20 * 3600 * 1000);
		}
		d = DateUtils.fullDay(d);
		return moment(new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
	}

	static toLocalLookalikeDate(utcDate) {
		const d = new Date(utcDate);
		return new Date(
			d.getUTCFullYear(),
			d.getUTCMonth(),
			d.getUTCDate(),
			d.getUTCHours(),
			d.getUTCMinutes(),
			d.getUTCSeconds(),
			d.getUTCMilliseconds(),
		);
	}

	static toUTCLookalikeDate(localDate) {
		const d = new Date(localDate);
		const dst = new Date();
		dst.setUTCFullYear(d.getFullYear());
		dst.setUTCMonth(d.getMonth());
		dst.setUTCDate(d.getDate());
		dst.setUTCHours(d.getHours());
		dst.setUTCMinutes(d.getMinutes());
		dst.setUTCSeconds(d.getSeconds());
		dst.setUTCMilliseconds(d.getMilliseconds());
		return dst;
	}

	/**
	* Get timezone offset at perticualar timestamp
	* @param {int} params.timestamp ms since Epoch timestamp
	* @param {string} params.timezone the timezone, example: "Europe/Stockholm"
	* @param {boolean} params.acceptInvalid Return 0 instead of throwing exceptions for unknown timezones
	* @returns {int} offset
	*/
	static getTimezoneOffset(params) {
		return DateUtils.getTimezoneOffsetter(params)(params?.timestamp);
	}

	static timezoneOffsetDate(date, timezone) {
		const d = date ? new Date(date) : new Date();
		return new Date(d.getTime() + DateUtils.getTimezoneOffset({ timezone }));
	}

	static getTimezoneOffsetter({ timezone, acceptInvalid = true } = {}) {
		if (!timezone || timezone === 'UTC') {
			return () => 0;
		}
		const formatter = new Intl.DateTimeFormat('en', {
			timeZone: timezone,
			timeZoneName: 'short',
			year: '2-digit',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		});
		return (timestamp) => {
			try {
				const d = new Date(timestamp || new Date());
				d.setUTCMilliseconds(0);
				const str = `${formatter.format(d).split(' ').slice(0, -1).join(' ')} UTC`;
				return new Date(str) - d;
			} catch (e) {
				if (!acceptInvalid) {
					throw e;
				}
				return 0;
			}
		};
	}

	/**
	 * Get a list of time slots for a given time range based on DST or not
	 * @param {int} startTs timestamp
	 * @param {int} endTs timestamp
	 * @param {string} timezone IANA timezone (either "IANA" or "UTC+0")
	 * @returns {[{}]} list of slots
	 */
	static getTimezoneSlots({ startTs: orgStartTs, endTs: orgEndTs, timezone }) {
		if (!timezone || timezone === 'UTC' || timezone === 'UTC+0') {
			return null;
		}
		const [zone] = timezone.split(' ') || timezone;
		if (!zone) {
			return null;
		}
		const getOffset = DateUtils.getTimezoneOffsetter({ timezone: zone });

		const orgStartOfs = getOffset(orgStartTs);
		const startTs = orgStartTs - orgStartOfs;
		const endTs = orgEndTs ? orgEndTs - getOffset(orgEndTs) : new Date().getTime();

		const ranges = [{ startTs, offsetTs: orgStartOfs }];
		const addRanges = (from, to) => {
			const diffMs = to.startTs - from.startTs;
			// Lets assume that DST is only in affect at most once per 100 day range
			if (from.offsetTs === to.offsetTs && diffMs < (100 * 24 * 3600 * 1000)) {
				return;
			}
			let mid = from.startTs + (diffMs / 2);
			mid = Math.round(mid - (mid % (900 * 1000))); // Use 15-minute chunks to support some obscure time-zones
			if (mid <= from.startTs || mid >= to.startTs) {
				ranges.push(to); // we only got this far if to has a different offset than from
				return;
			}
			const midObj = { startTs: mid, offsetTs: getOffset(mid) };
			addRanges(from, midObj);
			addRanges(midObj, to);
		};
		addRanges(ranges[0], { startTs: endTs, offsetTs: getOffset(endTs) });
		ranges.forEach((range, idx) => {
			if (idx === ranges.length - 1) {
				range.endTs = orgEndTs ? endTs : 0;
			} else {
				range.endTs = ranges[idx + 1].startTs;
			}
		});
		return ranges;
	}
}

module.exports = DateUtils;
