import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../DataTable';
import { fmtDate, renderStatus } from './utils';
import Icon from '../Icon';
import { CheckActions } from './actions';

function CheckHistory({ check, workspace, dataTableProps }) {
	const elms = [...check.history, check].reverse();
	const previousChanges = check.totalHistoryCount - check.history.length;
	return (
		<>
			<DataTable
				showCheckboxes={false}
				selectableRows={false}
				identifier={(obj) => obj._id}
				data={elms}
				{...dataTableProps}
				definitions={[
					{
						key: '_id',
						title: 'Status',
						format: (__, obj) => renderStatus(obj),
					},
					{
						key: 'date',
						title: 'Date',
						format: (date) => fmtDate(date),
					},
					{
						key: '_id',
						title: 'Actions',
						format: (__, obj) => CheckActions.filter((a) => a.historyCompatible && (!a.cond || a.cond({
							check: obj,
							workspace,
						}))).map((a) => (
							<Icon
								pic={a.icon}
								tooltip={a.name}
								key={a.name}
								onClick={() => workspace.performAction(a, [obj])}
							/>
						)),
					},
				]}
			/>
			{previousChanges > 0 && (
				<div style={{ marginTop: 20 }}>
					+
					<b>{previousChanges}</b>
					{' '}
					earlier entries
				</div>
			)}
		</>
	);
}

CheckHistory.propTypes = {
	check: PropTypes.object.isRequired,
	workspace: PropTypes.object.isRequired,
	dataTableProps: PropTypes.object,
};

CheckHistory.defaultProps = {
	dataTableProps: undefined,
};

export default CheckHistory;
