import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import styles from './styles.css';

function MenuDivider({ orientation }) {
	return (
		<Divider
			orientation={orientation}
			className={styles[orientation]}
		/>
	);
}

MenuDivider.propTypes = {
	orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

MenuDivider.defaultProps = {
	orientation: 'horizontal',
};

export default MenuDivider;
