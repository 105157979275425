import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import {
	useLocation,
} from 'react-router-dom';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import GoogleG from '../../assets/GoogleG';
import SystemData from '../../lib/systemData';
import { Button } from '../Button/Button';
import { SvgIcon } from '@mui/material';

function GLogin(props) {
	const location = useLocation();
	const { authenticate } = props;

	const login = useGoogleLogin({
		state: `${window.location}`,
		ux_mode: 'redirect',
		redirect_uri: SystemData.genericData.OAUTH_URL,
		flow: 'auth-code',
	});

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const code = urlParams.get('code');
		if (code) {
			authenticate({
				code,
			});
		}
	}, [authenticate, location]);

	return (
		<Button onClick={login} color="secondary">{GoogleG} Sign in with Google</Button>
	);
}

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
		};

		this.onChange = this.onChange.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keypress', this.onKeyPress);
	}

	componentWillUnmount() {
		document.removeEventListener('keypress', this.onKeyPress);
	}

	onKeyPress(e) {
		const key = e.which || e.keyCode;
		if (key === 13) {
			const { username, password } = this.state;
			const { login } = this.props;
			e.preventDefault();
			login(username, password);
		}
	}

	onChange(e, props) {
		const { name } = props;
		const newState = {};
		newState[name] = e.target.value;
		this.setState(newState);
	}

	renderFields() {
		const { isSubmittingForm } = this.props;
		return (
			isSubmittingForm ? <p>Logging in, please wait...</p> : this.renderTextFields()
		);
	}

	renderIdentity() {
		const { username, logout } = this.props;
		return (
			<div>
				Logged in as
				{username}
				{' '}
				(
				<Button onClick={() => logout()}>Logout</Button>
				).
			</div>
		);
	}

	renderForgotPassword() {
		const { forgotPassword } = this.props;
		return (
			<Button onClick={forgotPassword} variant="text">Forgot password?</Button>
		);
	}

	renderAuthFailed() {
		return (
			<Grid item xs={12}>
				<Typography align="center" color="error">Authentication failed. Wrong e-mail or password.</Typography>
			</Grid>
		);
	}

	renderTextFields() {
		const {
			forgotPassword, login, oauth2Login, loginFailed,
		} = this.props;
		const { username, password } = this.state;

		return (
			<div className="flex flex-col gap-6">
				<TextField
					name="username"
					label="Username"
					fullWidth
					onChange={this.onChange}
					value={username}
				/>
				<TextField
					name="password"
					label="Password"
					fullWidth
					onChange={this.onChange}
					password
					value={password}
				/>

				<div className="flex flex-col gap-3">
					{loginFailed ? this.renderAuthFailed() : ''}
					<ActionButton
						label="Login"
						onClick={() => login(username, password)}
					/>
					<GoogleOAuthProvider
						clientId={SystemData.genericData.clientId}
					>
						<GLogin authenticate={oauth2Login} />
					</GoogleOAuthProvider>
					{forgotPassword ? this.renderForgotPassword() : ''}
				</div>
			</div>
		);
	}

	render() {
		const { isLoggedIn } = this.props;
		return (
			isLoggedIn ? this.renderIdentity() : this.renderFields()
		);
	}
}

Login.propTypes = {
	forgotPassword: PropTypes.func,
	login: PropTypes.func,
	oauth2Login: PropTypes.func,
	logout: PropTypes.func,
	isLoggedIn: PropTypes.bool,
	isSubmittingForm: PropTypes.bool,
	loginFailed: PropTypes.bool,
	username: PropTypes.string,
};

Login.defaultProps = {
	isLoggedIn: false,
	isSubmittingForm: false,
	loginFailed: false,
	username: 'username',
	oauth2Login: (token) => console.log(`Login with OAuth token ${token}`), // eslint-disable-line
	login: (username, password) => console.log(`Login with credentials: ${username} // ${password}`), // eslint-disable-line
	logout: () => console.log(`Logging out`), // eslint-disable-line
	forgotPassword: () => console.log(`Forgot Password?`), // eslint-disable-line
};

export default Login;
