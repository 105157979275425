import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ToastContext } from '../providers/ToastProvider/ToastProvider';
import { ToastProps } from '../components/Toast/Toast';

/**
 * Create toasts that stack and persist across page navigations!
 * 
 * Usage:
 *   // Create and show/hide a pre-configured toast
 *   const [toastState, setToastState] = useToast({ message: 'Hello world!', status: 'success' });
 *   ...
 *   onClick={() => setToastState({ ...toastState, open: true })}
 *   onClick={() => setToastState({ ...toastState, open: !toastState.open })}
 * 
 *   // Fire-and-forget toasts
 *   const [, showToast] = useToast();
 *   ...
 *   showToast({ message: 'Hello world!', status: 'success' });
 *   showToast({ message: 'Oops!', status: 'info', timeout: 3 });
 * 
 *   // You may also use the Toast component directly, and a new toast will added to the stack every time the component is rendered with open={true}
 *   <Toast message="Failed to do the thing" status="error" open={mutation.isError} />
 *
 */
export function useToast(toastProps?: ToastProps | {}) {
	const [toasts, setToasts] = useContext(ToastContext);

	const [toastState, setToastState] = useState<Partial<ToastProps> | null>(toastProps);
	const key = useRef(Date.now().toString() + Math.random().toString());

	useEffect(() => {
		if (toastState === undefined) {
			return;
		}

		if (toastProps === undefined) {
			// Create a new toast every time if useToast was called without arguments initially,
			// so you can also use this to do "fire and forget" toasts!
			key.current = Date.now().toString() + Math.random().toString();
		}

		const keyCapture = key.current;
		const onCloseCapture = toastState?.onClose;
		const props = {
			...toastState as ToastProps,
			key: key.current,
			inline: true,
			onClose: () => {
				setToasts((prevToasts) => prevToasts.filter((t) => t.key !== keyCapture));
				setToastState((prevToastState) => ({ ...prevToastState, open: false }));
				onCloseCapture?.();
			}
		};

		if (toastProps === undefined) {
			props.open = toastState?.open ?? true;
		}

		if (!props.open) {
			// Remove
			setToasts((prevToasts) => prevToasts.filter((t) => t.key !== key.current));
			return;
		}

		if (toasts.find((t) => t.key === key.current)) {
			// Replace
			setToasts((prevToasts) => prevToasts.map((t) => t.key === key.current ? props : t));
		} else {
			// Add to end
			setToasts((prevToasts) => [ ...prevToasts, props ]);
		}
	}, [toastState]);

	return [toastState, setToastState] as const;
}