import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Chip,
	TablePagination,
	IconButton,
	Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import PeopleIcon from '@mui/icons-material/People';
import DataTable from '../DataTable';
import classes from '../../api/classes';
import { stores } from '../../stores';
import { ConfirmDialog } from '../ConfirmDialog';

const { Event } = classes;

function EventTable({
	events,
	onDelete,
	onEdit,
	onLoading,
}) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const currentPageEvents = events.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	const userId = stores.identity.userId();

	return (
		<>
			<DataTable
				showCheckboxes={false}
				identifier={(row) => row.id}
				selectableRows={false}
				noDataMessage={(
					<Box sx={{
						textAlign: 'center',
						color: 'text.secondary',
					}}
					>
						No events
					</Box>
				)}
				definitions={[
					{
						key: 'title',
						title: 'Title',
					},
					{
						key: 'description',
						title: 'Description',
					},
					{
						key: 'date',
						title: 'Date',
						format: (date) => {
							const now = moment();
							const days = now.diff(date, 'days');
							if (days < 7) {
								return moment(date).fromNow();
							}
							return moment(date).format('MMM Do YYYY hh:mm');
						},
					},
					{
						key: 'public',
						title: 'Public',
						format: (value) => (value ? (
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<PeopleIcon sx={{ mr: 1 }} />
								{' '}
								<div>Public</div>
							</Box>
						) : ''),
					},
					{
						key: 'authorInfo',
						title: 'Author',
						format: ({ fullname }) => fullname,
					},
					{
						key: 'tags',
						title: 'Tags',
						format: (tags) => (
							tags.map((tag) => (
								<Chip
									key={tag}
									sx={{ mr: 1 }}
									label={tag}
								/>
							))
						),
					},
					// Actions column
					{
						key: 'id',
						title: '',
						// eslint-disable-next-line react/no-unstable-nested-components
						format: (id) => {
							const event = events.find((e) => e.id === id);
							const isAuthor = event.authorInfo.id === userId;
							const showEditButton = isAuthor || event.public;
							return (
								<>
									{ showEditButton && (
										<Tooltip title="Edit">
											<span>
												<IconButton
													aria-label="edit"
													size="large"
													onClick={() => {
														onEdit(id);
													}}
												>
													<EditIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
									{ isAuthor && (
										<Tooltip title="Delete">
											<span>
												<ConfirmDialog
													text="Are you sure you want to delete this event?"
													onConfirm={async () => {
													// Display loading toast if action takes > 500ms
														const timeout = setTimeout(() => {
															onLoading(true);
														}, 500);
														const updatedEvents = await Event.fnCache.reset(() => (
															Event.delete({ ids: [id] })
														));
														clearTimeout(timeout);
														onDelete(updatedEvents);
													}}
												>
													<IconButton
														aria-label="delete"
														size="large"
													>
														<DeleteIcon />
													</IconButton>
												</ConfirmDialog>
											</span>
										</Tooltip>
									)}
								</>
							);
						},
					},
				]}
				data={currentPageEvents}
			/>
			<TablePagination
				component="div"
				count={events.length}
				page={page}
				rowsPerPage={rowsPerPage}
				onPageChange={(__, newPage) => {
					setPage(newPage);
				}}
				onRowsPerPageChange={(e) => {
					const newRows = e.target.value;
					setRowsPerPage(newRows);
					setPage(Math.floor((page * rowsPerPage) / newRows));
				}}
			/>
		</>
	);
}

EventTable.propTypes = {
	events: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		date: PropTypes.instanceOf(Date),
		public: PropTypes.bool,
		createdDate: PropTypes.string,
		tags: PropTypes.arrayOf(PropTypes.string),
		authorInfo: PropTypes.shape({ fullname: PropTypes.string }).isRequired,
	})),
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onLoading: PropTypes.func.isRequired,
};

EventTable.defaultProps = {
	events: [],
};

export default EventTable;
