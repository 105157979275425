import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import Autocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Select from '../Select';
import { DemoHide, FormOf } from '../Wrappers';
import ExpandSelector from '../ExpandSelector';
import FieldDataObject from '../TagEditor/fieldDataObject';
import MiscUtils from '../../lib/miscUtils';
import Base from '../../layouts/Base';
import SystemData from '../../lib/systemData';
import BidParamEdit from '../BidParamEdit';
import InlineBidParamContainer from '../BidParamEdit/inlineBidParamContainer';
import PopoutButton from '../PopoutButton';
import StoredRequestTagInfo from '../StoredRequestTagInfo';
import TextField from '../TextField';
import AmpLogo from '../../assets/amp.svg';
import { ConfirmDialog } from '../ConfirmDialog';
import NodeDragDrop from '../Wrappers/nodeDragDrop';

function Noop() {
	return null;
}

/**
 *  startCu: String,
 startKw: String,
 campaignId: String,

 * */
const AdserverTypes = {
	SmartAdserver: {
		hasPlacData: ({ location, insertionId }) => location || insertionId,
		renderWithoutPlacData: true,
		component: (props) => {
			const { field, renderNoPlacData } = props;
			return (
				<>
					{!renderNoPlacData && (
						<>
							<Grid item xs={12}>
								<TextField
									{...field('location')}
									label="Location - [SiteId]/[PageId]/[FormatId]"
									fullWidth
									isolated
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									{...field('insertionId')}
									label="Insertion Id"
									fullWidth
									isolated
								/>
							</Grid>
						</>
					)}
					<Grid item xs={12}>
						<TextField
							{...field('gamPassbackPath')}
							label="Full GAM passback ad unit path"
							fullWidth
							isolated
						/>
					</Grid>
				</>
			);
		},
		defaults: {
			location: '',
			insertionId: '',
			gamPassbackPath: '',
		},
	},
	DfpAdserver: {
		component: (props) => {
			const { field, adserver } = props;
			return (
				<Grid item xs={12}>
					<TextField
						{...field('lineItemId')}
						label="Line Item Id / Ad Unit Code"
						multiLine
						required={!adserver.useSspPlacementsForIds}
						fullWidth
						isolated
					/>
				</Grid>
			);
		},
		defaults: {
			lineItemId: '',
		},
	},
	DemoAdserver: {
		component: (props) => {
			const { placementAdserverObject } = props;
			return (
				<Grid item xs={12}>
					<span style={{ fontStyle: 'italic' }}>Demo Id:&nbsp;&nbsp;</span>
					<span style={{ color: 'darkgray' }}>
						{placementAdserverObject._id}
					</span>
				</Grid>
			);
		},
	},
	AdformAdserver: {
		component: (props) => (
			<Grid item xs={12}>
				<TextField
					{...props.field('placementId')}
					label="Placement Id"
					multiLine
					required
					fullWidth
					isolated
				/>
			</Grid>
		),
		defaults: { placementId: '' },
	},
	AppnexusAdserver: {
		component: ({ field, adserver }) => (
			<Grid item xs={12}>
				<TextField
					{...field('placementId')}
					label="Placement Id"
					multiLine
					required={!adserver.useSspPlacementsForIds}
					fullWidth
					isolated
				/>
			</Grid>
		),
		defaults: { placementId: '' },
	},
	CxDisplayAdserver: {
		component: (props) => {
			const { field } = props;
			return (
				<>
					<Grid item xs={12}>
						<TextField
							{...field('startCu')}
							label="Start CU"
							required
							integer
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<DemoHide>
							<TextField
								{...field('startKw')}
								label="Start Keyword"
								required
								fullWidth
							/>
						</DemoHide>
					</Grid>
					{/**
						<TextField
							{...field('campaignId')}
							label="Passback Campaign ID"
							integer
						/>
					*/}
				</>
			);
		},
		defaults: {
			startCu: '',
			startKw: '',
		},
	},
	DefaultImplementation: {
		component: (props) => (
			<TextField
				{...props.field('placementId')}
				label="Placement Id"
				multiLine
				required
			/>
		),
		defaults: { placementId: '' },
	},
};

class PlacementForm extends React.Component {
	constructor(props) {
		super(props);
		this.onSSPSelect = this.onSSPSelect.bind(this);
	}

	onSSPSelect(e) {
		const { collectionAdd, placementNode } = this.props;
		if (e.target.value !== 'new') {
			collectionAdd(new SystemData.models.SspPlacement({
				source: e.target.value,
				id: '',
				_id: MiscUtils.objectIdStr(), // Special case as ssp-placements have *different* id/_id
				pbCfgs: placementNode.getDefaultPrebidConfigs().map(({ id }) => id),
			}));
		}
	}

	renderDeleteButton() {
		const { collectionRemove } = this.props;
		return (
			<Tooltip title="Delete placement">
				<IconButton
					onClick={async () => {
						const ok = await Base.renderGlobal((closeFn) => (
							<ConfirmDialog
								open
								text="Do you want to delete this placement?"
								onAny={closeFn}
							/>
						));
						if (ok) {
							collectionRemove();
						}
					}}
					size="large"
				>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		);
	}

	renderFields() {
		const {
			content, field, value, SSPs, adservers, form, globalSettings, publisher,
			placementNode, dirtyBidParamSet, expand,
			placementLabelOptions, onPlacementLabelUpdate, sitePrebidConfigs, siteId,
		} = this.props;
		const adserverItems = _.sortBy(adservers, 'name')
			.map((a) => ({ value: a.id, label: `${a.name} (${a.friendlyTypeName})` }));
		adserverItems.unshift({ value: 'none', label: 'None' });
		const adserverArr = value('adservers');
		const firstAdserver = adserverArr[0];
		const selectedAdserverId = (firstAdserver || {}).adserverId;
		const adserver = adservers.find((a) => a.id === selectedAdserverId);
		let firstAdserverGuiSettings;
		if (adserver && adserver.type) {
			firstAdserverGuiSettings = AdserverTypes[adserver.type] || AdserverTypes.DefaultImplementation;
		}
		const AdserverComponent = (firstAdserverGuiSettings || {}).component || (Noop);
		let hasPlacData;
		if (firstAdserverGuiSettings) {
			firstAdserver.settings = { ...firstAdserverGuiSettings.defaults, ...firstAdserver.settings };
			if (firstAdserverGuiSettings.hasPlacData) {
				hasPlacData = firstAdserverGuiSettings.hasPlacData(firstAdserver.settings);
			} else {
				hasPlacData = _.find(_.values((firstAdserver || {}).settings));
			}
		}
		// Hide placement settings if useSspPlacementsForId is set
		const hidePlacSettings = (adserver || {}).useSspPlacementsForIds && !hasPlacData;
		// And all of adserver-specific component unless there is something else it wants to show
		const hideComponent = hidePlacSettings && !firstAdserverGuiSettings?.renderWithoutPlacData;
		const placementTypes = _.flatten([publisher, globalSettings].map((o) => o.placementTypes));
		const { ampEnabled, mobileEnabled } = placementNode.bidderParams({ sspId: 'generalSettings' });

		// Unique prebid config
		const prebidConfigs = Array.from(new Set(placementNode.children
			.filter(({ obj }) => (obj && obj.pbCfgs))
			.map(({ obj }) => obj.pbCfgs)
			.flat()))
			.reduce((acc, pbConfigId) => {
				const sitePrebidConfig = sitePrebidConfigs.find(({ id }) => id === pbConfigId);
				if (sitePrebidConfig) {
					acc.push({
						id: pbConfigId,
						name: sitePrebidConfig.name,
					});
				}
				return acc;
			}, []);

		const placementId = value('id');
		const placementName = value('name');

		return (
			<ExpandSelector
				forms={form.formCollection()}
				style={{ padding: '0px 10px' }}
				expanded={!!expand}
				title={(
					<NodeDragDrop
						form={form}
						id={placementId}
						publisherNode={placementNode.publisherNode}
					>
						<Box component="div" display="flex" justifyContent="space-between" pt={1} pb={1}>
							<div
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								{value('name') !== '' ? value('name') : 'Placement placeholder'}
							</div>

							<Box
								component="div"
								display="flex"
								alignItems="center"
								onClick={(ev) => ev.stopPropagation()}
							>
								<Box component="div" pr={7}>
									<FormOf
										model={placementNode.bidderParams({
											sspId: 'genericPlacementData',
											withMeta: true,
										})}
										content={({ field: fld, model }) => (
											<FieldDataObject
												model={model}
												field={fld}
												summary
												headerOnly
											/>
										)}
									/>
								</Box>
								<Box component="div" mr={2}>
									{ ampEnabled && (
										<PopoutButton
											renderButton={(params) => (
												<Tooltip title="AMP is enabled">
													<IconButton {...params} size="large">
														<Box component="img" width={24} height={24} src={AmpLogo} alt="AMP" />
													</IconButton>
												</Tooltip>
											)}
										>
											<StoredRequestTagInfo
												prebidConfigs={prebidConfigs}
												placement={{ name: placementName, id: placementId }}
												textFieldLabel="AMP tag ID"
												header="AMP setup"
											/>
										</PopoutButton>
									)}
									{ mobileEnabled && (
										<PopoutButton
											renderButton={(params) => (
												<Tooltip title="Prebid Mobile is enabled">
													<IconButton {...params} size="large">
														<SmartphoneIcon />
													</IconButton>
												</Tooltip>
											)}
										>
											<StoredRequestTagInfo
												prebidConfigs={prebidConfigs}
												placement={{ name: placementName, id: placementId }}
												textFieldLabel="AdUnit config ID"
												header="Prebid Mobile setup"
												pbsAccountId={siteId}
											/>
										</PopoutButton>
									)}
								</Box>
								<Autocomplete
									value={value('label') || ''}
									onChange={(e, val) => {
										// Prevent form from saving
										e.preventDefault();
										const trimmedVal = val ? val.trim() : val;
										const { name, onChange } = field('label');
										onChange({ target: { name, value: trimmedVal } }, this.props);
										onPlacementLabelUpdate(trimmedVal);
									}}
									options={placementLabelOptions}
									style={{ minWidth: 200, paddingRight: 16 }}
									renderInput={(params) => (
										<MuiTextField {...params} label="Placement label" />
									)}
									freeSolo
									selectOnFocus
									autoSelect
									blurOnSelect
									handleHomeEndKeys
								/>

								<Select
									{...field('placementType')}
									style={{ minWidth: 200 }}
									label="Placement type"
									preReaction={BidParamEdit.invalidateInheritedPreviewValues}
									items={MiscUtils.alphaSorted(placementTypes, 'name').map((pt) => ({
										label: pt.name,
										value: pt.id,
									}))}
									nonSelected="(non-prebid)"
								/>
								{this.renderDeleteButton()}
							</Box>
						</Box>
					</NodeDragDrop>
				)}
				content={() => (
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TextField
										{...field('name')}
										label="Name"
										required
										fullWidth
										isolated
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										name="adserverSelect"
										value={selectedAdserverId || 'none'}
										onChange={(ev) => {
											const adserverId = ev.target.value;
											if (adserverId === selectedAdserverId) {
												return;
											}
											if (adserverId === 'none') {
												adserverArr.length = 0;
											} else {
												adserverArr[0] = { adserverId, settings: {} };
											}
											this.forceUpdate();
										}}
										label="Adserver"
										items={adserverItems}
										required
										fullWidth
									/>
								</Grid>
								{!hideComponent && (
									<Grid item xs={12}>
										<AdserverComponent
											field={(name) => field(`adservers[0].settings.${name}`)}
											adserver={adserver}
											placementAdserverObject={firstAdserver}
											renderNoPlacData={hidePlacSettings}
										/>
									</Grid>
								)}
								<Grid item xs={12}>
									<Box component="div" pl={1.5}>
										<BidParamEdit
											form={form}
											node={placementNode}
											dstObj={publisher}
											onDone={() => form.update()}
											ssps={placementNode.publisherNode.ssps}
											checkbox={!!publisher.bidParams.find((p) => (
												p.unitId === placementNode.id
												&& p.sspId !== 'genericPlacementData'
												&& !_.isEmpty(p.params)
											))}
											includeDataFilterFn={({ id }) => id !== 'genericPlacementData'}
										/>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<FieldDataObject.ExpandMode.Provider value={expand}>
										<BidParamEdit
											form={form}
											node={placementNode}
											dstObj={publisher}
											onDone={() => form.update()}
											ssps={placementNode.publisherNode.ssps}
											containerCls={InlineBidParamContainer}
											elementContainerCls={InlineBidParamContainer}
											includeDataFilterFn={({ id }) => id === 'genericPlacementData'}
											autoApplyChanges
											dirtyBidParamSet={dirtyBidParamSet}
											noEmptyExpandCustomParams
										/>
									</FieldDataObject.ExpandMode.Provider>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Select
										name="SSP"
										label="SSPs"
										value="new"
										items={SSPs}
										onChange={this.onSSPSelect}
										required
										fullWidth
									/>
								</Grid>
								{content()}
							</Grid>
						</Grid>
					</Grid>
				)}
			/>
		);
	}

	render() {
		return (
			<div>
				{this.renderFields()}
			</div>
		);
	}
}

PlacementForm.propTypes = {
	field: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	value: PropTypes.func.isRequired,
	collectionAdd: PropTypes.func.isRequired,
	collectionRemove: PropTypes.func.isRequired,
	content: PropTypes.func.isRequired,
	SSPs: PropTypes.array.isRequired,
	adservers: PropTypes.array.isRequired,
	checkForErrors: PropTypes.func.isRequired,
	globalSettings: PropTypes.object.isRequired,
	publisher: PropTypes.object.isRequired,
	placementNode: PropTypes.object.isRequired,
	dirtyBidParamSet: PropTypes.instanceOf(Set).isRequired,
	expand: PropTypes.bool,
	placementLabelOptions: PropTypes.arrayOf(PropTypes.string),
	onPlacementLabelUpdate: PropTypes.func,
	sitePrebidConfigs: PropTypes.arrayOf(PropTypes.object),
};

PlacementForm.defaultProps = {
	placementLabelOptions: [],
	onPlacementLabelUpdate: () => undefined,
	sitePrebidConfigs: [],
};

export default PlacementForm;
