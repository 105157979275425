import React from 'react';
import {
	AppBar,
	Box,
	CssBaseline,
	IconButton,
	Toolbar,
	Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { drawerWidth } from '../Sidebar/config';
import useSidebar from '../../hooks/useSidebar';
import TopBarOptions from './TopBarOptions';
import AnchoredMenu from '../AnchoredMenu';
import AppSelector from '../AppSelector';
import HelpOptions from '../HelpOptions';
import UserOptions from '../UserOptions';
import { stores } from '../../stores';

export default function TopBar({ onSelectProduct }) {
	const { handleDrawerToggle } = useSidebar();

	return (
		<Box sx={{ display: 'flex', mb: 11 }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					width: { md: `calc(100% - ${drawerWidth}px)` },
					ml: { md: `${drawerWidth}px` },
					bgcolor: 'primary.light',
				}}
			>
				<Toolbar>
					<Box sx={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
					}}
					>
						<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ mr: 2, display: { md: 'none' } }}
							>
								<MenuIcon />
							</IconButton>
							<TopBarOptions />
						</Box>
						<Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
							<Divider orientation="vertical" />
							<Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
								{ onSelectProduct && (
									<Box sx={{ mr: 1 }}>
										<AppSelector onProductChange={onSelectProduct} />
									</Box>
								)}
								{stores.identity.isAdministrator() && (
									<AnchoredMenu
										tooltipTitle="Help"
										icon={(
											<HelpOutlineRoundedIcon sx={{
												color: 'white',
											}}
											/>
										)}
									>
										<HelpOptions />
									</AnchoredMenu>
								)}
								<AnchoredMenu
									tooltipTitle="Account"
									icon={<AccountCircleIcon />}
									iconProps={{ color: 'offWhite' }}
								>
									<Box sx={{
										pt: 4,
										pl: 4,
										pr: 4,
										pb: 3,
									}}
									>
										<UserOptions />
									</Box>
								</AnchoredMenu>
							</Box>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
		</Box>

	);
}

TopBar.propTypes = {
	onSelectProduct: PropTypes.func,
};
TopBar.defaultProps = {
	onSelectProduct: undefined,
};
