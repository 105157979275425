class Animation {
	constructor(initVal, minVal, maxVal, duration, setter) {
		this.current = initVal;
		this.minVal = minVal;
		this.maxVal = maxVal;
		this.duration = duration;
		this.setter = setter;
		this.setter(this.current);
	}

	toMin(cb, triggerEvenIfCancelled) {
		const res = this.to !== this.minVal;
		this.startTime = Date.now() - (((this.maxVal - this.current) / (this.maxVal - this.minVal)) * this.duration);
		this.from = this.maxVal;
		this.to = this.minVal;
		this.internalSet(cb, triggerEvenIfCancelled);
		return res;
	}

	isMin() { return this.current === this.minVal; }

	isMax() { return this.current === this.maxVal; }

	toMax(cb, triggerEvenIfCancelled) {
		const res = this.to !== this.maxVal;
		this.startTime = Date.now() - (((this.current - this.minVal) / (this.maxVal - this.minVal)) * this.duration);
		this.from = this.minVal;
		this.to = this.maxVal;
		this.internalSet(cb, triggerEvenIfCancelled);
		return res;
	}

	internalSet(cb, triggerEvenIfCancelled) {
		const oldCb = this.cb;
		this.cb = null;
		if (oldCb && this.triggerEvenIfCancelled) {
			oldCb();
		}
		this.cb = cb;
		this.triggerEvenIfCancelled = triggerEvenIfCancelled;
		this.schedule();
	}

	schedule() {
		if (!this.scheduled) {
			this.update();
		}
	}

	stop(triggerIfWanted) {
		this.stopped = true;
		if (triggerIfWanted && this.cb && this.triggerEvenIfCancelled) {
			this.cb();
		}
	}

	isActive() {
		return !this.stopped && this.to != null && this.current !== this.to;
	}

	isOnWayToMax() {
		return this.isActive() && this.to === this.maxVal;
	}

	isOnWayToMin() {
		return this.isActive() && this.to === this.minVal;
	}

	update() {
		if (this.stopped) {
			return;
		}
		const elapsed = (Date.now() - this.startTime) / this.duration;
		if (elapsed >= 1 || this.current === this.to) {
			this.scheduled = false;
			this.current = this.to;
			this.setter(this.to);
			if (this.cb) {
				this.cb();
				this.cb = null;
			}
			return;
		}
		this.current = this.from + (elapsed * (this.to - this.from));
		this.setter(this.current);
		setTimeout(this.update.bind(this), 10);
	}

	callSetter() {
		this.setter(this.current);
	}
}

export default Animation;
