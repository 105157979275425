import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import {
	Box,
	IconButton,
	Tooltip,
	CircularProgress,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import TopBarHeader from './TopBarHeader';
import TopBarNavigation from './TopBarNavigation';
import UnstyledLink from '../UnstyledLink';
import { Reports } from '../../api/relevant';
import useReports from '../../hooks/useReports';
import useDebounce from '../../hooks/useDebounce';

function TopBarReportsOverviewOptions({
	addLink,
	allReportsArePinned,
	reportIds,
	titleNavigation: {
		label,
		options,
		onChange,
	},
}) {
	const { updateReports } = useReports();
	const [isLoading, setIsLoading] = useState(false);
	// Don't display loading button if action takes < 250ms to avoid stuttering
	const debouncedIsLoading = useDebounce(isLoading, 250);
	return (
		<Box sx={{
			width: '100%',
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'space-between',
		}}
		>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{
					display: { xs: 'none', md: 'flex' },
					alignItems: 'center',
				}}
				>
					<TopBarHeader
						title="Reports"
						color="primary.tonedDownText"
					/>
					<ArrowForwardIcon
						sx={{
							fontSize: '16px',
							ml: 1.5,
							mr: 0.5,
						}}
					/>
				</Box>
				{ options.length > 1 ? (
					<TopBarNavigation
						label={label}
						options={options}
						onChange={onChange}
					/>
				) : (

					<Box
						sx={{
							color: 'primary.contrastText',
							fontSize: '20px', // Same as header to the left.
							pl: 1,
						}}
					>
						{label}
					</Box>
				)}
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Tooltip title={allReportsArePinned ? 'Unpin reports from sidebar' : 'Pin reports to sidebar'}>
					<span>
						{ debouncedIsLoading ? (
							<Box sx={{ display: 'flex' }}>
								<CircularProgress size={24} />
							</Box>
						) : (
							<IconButton
								color="offWhite"
								onClick={async () => {
									try {
										setIsLoading(true);
										await Reports.call('updatePinnedToSidebar', {
											reportIds,
											pinned: !allReportsArePinned,
										});
										await updateReports();
									} catch (err) {
										//
									}
									setIsLoading(false);
								}}
							>
								{ allReportsArePinned ? <PushPinIcon /> : <PushPinOutlinedIcon /> }
							</IconButton>
						)}
					</span>
				</Tooltip>
				{ addLink && (
					<UnstyledLink to={addLink}>
						<Tooltip title="New report">
							<IconButton color="offWhite">
								<AddIcon />
							</IconButton>
						</Tooltip>
					</UnstyledLink>
				)}
			</Box>
		</Box>
	);
}

TopBarReportsOverviewOptions.propTypes = {
	addLink: PropTypes.string,
	reportIds: PropTypes.arrayOf(PropTypes.string),
	allReportsArePinned: PropTypes.bool,
	titleNavigation: PropTypes.shape({
		label: PropTypes.string,
		options: PropTypes.arrayOf(PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		})),
		onChange: PropTypes.func,
	}),
};

TopBarReportsOverviewOptions.defaultProps = {
	titleNavigation: {},
	addLink: '',
	reportIds: [],
	allReportsArePinned: false,
};

export default TopBarReportsOverviewOptions;
