const BidderNode = require('./bidderNode');

class SspPlacementNode extends BidderNode {
	constructor(sspPlac, parentNode) {
		super(sspPlac, parentNode, 'SspPlacementNode');
	}

	get id() { return this.obj._id; }

	get sspPlacementId() {
		return this.obj.id;
	}

	get sspPlacNode() {
		return this;
	}

	get name() { return this.obj.id; }

	get sspId() { return this.obj.source; }

	get shadows() {
		return this.obj.shadows.filter((shadow) => this.publisherNode.byId[shadow.placementId]);
	}
}
SspPlacementNode.parentPath = 'ssps';

module.exports = SspPlacementNode;
