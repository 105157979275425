import React, { useState, useEffect, useCallback } from 'react';
import MiscUtils from '../../lib/miscUtils';

/**
 * Custom React hook that handles error and loading states when doing requests.
 * @param {*} req Should contain two functions, requestFunction and requestCallback
 */
const useRequest = (req) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ state: false, message: 'An error occured. Please try again.' });
	const [request, _setRequest] = useState(req);
	const setRequest = useCallback((r) => {
		_setRequest(r);
	}, []);

	const reset = () => {
		_setRequest({});
		setError({});
		setLoading(false);
	};

	useEffect(() => {
		let isCancelled = false;
		const doRequest = async () => {
			let res;
			try {
				setLoading(true);
				res = await request.requestFunction();
				if (request.requestCallback) {
					request.requestCallback(res);
				}
			} catch (err) {
				setError({ state: true, message: MiscUtils.errorMsg(err) });
			} finally {
				// Might be cases where the hook is unmounted before trying to set state,
				// so need to check if thats the case
				if (!isCancelled) {
					setLoading(false);
				}
			}
		};
		if (request && request.requestFunction) {
			doRequest();
		}
		return () => {
			isCancelled = true;
		};
	}, [request]);

	return [setRequest, loading, error, reset, request && request.requestName];
};

export default useRequest;
