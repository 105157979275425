import PropTypes from 'prop-types';
import React from 'react';
import DataTable from '../DataTable';
import RecordTableTools from '../RecordTableTools';
import { stores } from '../../stores';

// import styles from './styles.css';

class TableOfAdministrators extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { administrators, deleteRow } = this.props;
		const { identity } = stores;
		const canGhost = !identity.isGhosting() && identity.isSuperAdministrator();
		const userId = identity.userId();

		return (
			<DataTable
				identifier={(row) => row.id}
				definitions={[
					{ key: 'fullname', title: 'Name' },
					{ key: 'email', title: 'Email' },
					{
						key: 'id',
						title: '',
						align: 'right',
						format: (id) => (
							<RecordTableTools
								ghostAction={canGhost && id !== userId
									? (() => stores.identity.startGhosting(id)) : undefined}
								editHref={`/administrators/${id}`}
								deleteAction={() => deleteRow(id)}
							/>
						),
					},
				]}
				data={administrators}
				showCheckboxes={false}
				selectableRows={false}
			/>
		);
	}
}

TableOfAdministrators.propTypes = {
	administrators: PropTypes.array,
	deleteRow: PropTypes.func.isRequired,
};

TableOfAdministrators.defaultProps = {
	administrators: [],

};

export default TableOfAdministrators;
