import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import usePinnedReports from '../../../hooks/usePinnedReports';

function useExpandedReportSections() {
	const initialReportsFetchDone = useRef(false);
	const pinnedReports = usePinnedReports();
	const [expandedReportSections, setExpandedReportSections] = useState([]);
	const { pathname, search } = useLocation();
	const currentPath = `${pathname}${search}`;
	// Add or remove report section to list of expanded
	const updateExpandedReportSections = (section) => {
		const updated = [...expandedReportSections];
		const index = updated.indexOf(section);
		if (index !== -1) {
			updated.splice(index, 1);
			setExpandedReportSections(updated);
		} else {
			updated.push(section);
			setExpandedReportSections(updated);
		}
	};

	// On mount, set which report sections are expanded.
	// If all contains less than >=7 reports, expand all.
	// If a report is selected and its section contains >=7 reports, expand only that one.
	// Else if non selected and at least one contains > 7, expand only first section
	// IF first section has less than 7 reports.
	useEffect(() => {
		if (pinnedReports !== null && !initialReportsFetchDone.current) {
			initialReportsFetchDone.current = true;
			const expanded = [];
			let reportId;
			const sections = Object.keys(pinnedReports);
			const sectionReports = Object.values(pinnedReports);
			const allLessThanTen = sectionReports.every((reps) => reps.length <= 7);
			let sectionContainingReport;
			if (currentPath.includes('reports')) {
				const pathParts = currentPath.split('/');
				const lastPart = pathParts[pathParts.length - 1];
				reportId = lastPart !== pinnedReports ? lastPart : undefined;
				if (reportId) {
					Object.entries(pinnedReports).forEach(([section, reps]) => {
						const found = reps.find(({ id }) => id === reportId);
						if (found) {
							sectionContainingReport = section;
						}
					});
				}
			}
			if (allLessThanTen) {
				expanded.push(...sections);
			} else if (reportId) {
				expanded.push(sectionContainingReport);
			} else if (sectionReports[0].length <= 10) {
				expanded.push(sections[0]);
			}
			setExpandedReportSections(expanded);
		}
	}, [currentPath, pinnedReports]);

	return [expandedReportSections, updateExpandedReportSections];
}

export default useExpandedReportSections;
