import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LoginContainer from '../../containers/LoginContainer';
import BrowserUtils from '../../lib/browserUtils';
import SystemData from '../../lib/systemData';

class LoginPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { loginLogoUrl, loginBoxStyle } = SystemData.genericData.systemSettings;
		return (
			<>
				<Box
					py={5}
					marginBottom={10}
					textAlign="center"
					bgcolor="white"
					border={10}
					borderTop={0}
					borderRight={0}
					borderLeft={0}
					borderColor="secondary.main"
					style={loginBoxStyle}
				>
					<img src={loginLogoUrl} alt="logo" style={{ width: '50%', display: 'inline' }} />
				</Box>
				<Container maxWidth="md">
					<Paper>
						<Box padding={4}>
							<LoginContainer redirect={BrowserUtils.urlParams().redirect} />
						</Box>
					</Paper>
				</Container>
			</>
		);
	}
}

LoginPage.propTypes = {
	children: PropTypes.node,
};

LoginPage.defaultProps = {

};

export default LoginPage;
