import React, {
	useState,
	useEffect,
} from 'react';
import {
	Typography,
	IconButton,
	Chip,
	Box,
	List,
	ListItem,
	ListItemText,
	Tooltip,
	Collapse,
	Fade,
	styled,
} from '@mui/material';
import PropTypes from 'prop-types';
import MergeIcon from '@mui/icons-material/Merge';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { TransitionGroup } from 'react-transition-group';
import usePrevious from '../../hooks/usePrevious';

const StyledChip = styled(Chip)(() => ({
	'&.MuiChip-root': {
		maxWidth: '100%',
	},
}));

function ChartDimensionSettings({
	dimensions,
	groupedDimensions,
	onChange,
}) {
	const [editedGroupedDimensions, setEditedGroupedDimensions] = useState(groupedDimensions);
	const previousDimensions = usePrevious(dimensions);

	// Handle dimensions changing
	useEffect(() => {
		const dimensionsHasChanged = previousDimensions !== undefined
			&& (previousDimensions.length !== dimensions.length
				|| dimensions.some((dimension, index) => dimension.value !== previousDimensions[index].value));
		// When there are no previously set grouped dimensions
		// Or user has set new dimensions in report settings
		if ((editedGroupedDimensions.length === 0 && dimensions?.length) || dimensionsHasChanged) {
			const state = [dimensions[0]];
			setEditedGroupedDimensions(state);
			onChange({ groupedDimensions: state });
		}
	}, [dimensions, editedGroupedDimensions, previousDimensions, onChange]);

	const mergeDimensions = (dimension) => {
		const updated = [...editedGroupedDimensions, dimension];
		setEditedGroupedDimensions(updated);
		onChange({ groupedDimensions: updated });
	};

	const unmergeDimensions = () => {
		// Back to initial state
		const updated = [dimensions[0]];
		setEditedGroupedDimensions(updated);
		onChange({ groupedDimensions: updated });
	};

	const groupedDimensionValues = editedGroupedDimensions.map(({ value }) => value);
	const activeDimensions = dimensions
		.filter(({ value }) => groupedDimensionValues.includes(value));

	const nonActiveDimensions = dimensions
		.filter(({ value }) => !groupedDimensionValues.includes(value));
	return (
		<div>
			<Box display="flex" justifyContent="space-between" mt={2} alignItems="center">
				<Typography variant="h5">
					Group by
				</Typography>
				{ editedGroupedDimensions.length > 1 && (
					<Tooltip title="Ungroup">
						<IconButton
							onClick={unmergeDimensions}
						>
							<LinkOffIcon />
						</IconButton>
					</Tooltip>
				)}
			</Box>
			<Box
				flexWrap="wrap"
				mb={1}
				ml={0.5}
			>
				<TransitionGroup>
					{ activeDimensions.map(({ label, value }) => (
						// Graph only shows the first dimension, unless merged
						<Fade
							key={value}
							sx={{ marginRight: 0.5, marginLeft: 0.5, marginTop: 1 }}
							mountOnEnter
							unmountOnExit
						>
							<StyledChip
								color="info"
								label={label}
								key={value}
								variant="filled"
							/>
						</Fade>
					))}
				</TransitionGroup>
			</Box>
			<List disablePadding>
				<TransitionGroup>
					{ nonActiveDimensions.map(({ label, value }, index) => (
						<Collapse
							key={value}
							mountOnEnter
							unmountOnExit
						>
							<ListItem
								secondaryAction={(
									index === 0 && (
										<Tooltip title="Merge">
											<IconButton
												onClick={() => mergeDimensions({ label, value })}
											>
												<MergeIcon />
											</IconButton>
										</Tooltip>
									)
								)}
							>
								<ListItemText sx={{ color: index > 0 ? 'disabled.main' : 'text.primary' }}>
									{label}
								</ListItemText>
							</ListItem>
						</Collapse>
					))}
				</TransitionGroup>
			</List>
		</div>
	);
}

ChartDimensionSettings.propTypes = {
	dimensions: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	})).isRequired,
	groupedDimensions: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	})),
	onChange: PropTypes.func.isRequired,
};

ChartDimensionSettings.defaultProps = {
	groupedDimensions: [],
};

export default ChartDimensionSettings;
