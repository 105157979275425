import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import { FormOf } from '../Wrappers';
import { Trigger } from '../../api/relevant';
import PopupSelector from '../PopupSelector';
import Checkbox from '../Checkbox';
import { ConfirmNoFiltersModal, ConfirmChangeForOtherModal } from './dialogues';
import InfoChannelPopup from './infoChannelPopup';
import Base from '../../layouts/Base';
import { ShareSelector } from './utils';
import AlarmsReport from './AlarmsReport';

function TriggerReport({ closeFn, trigger, workspace }) {
	const model = {
		..._.cloneDeep(trigger),
	};
	const { reportSettings } = trigger;
	const settings = {
		...reportSettings,
		settingsOpen: true,
		trigger,
	};
	const { type, otherUserData } = workspace.getOwnership(trigger);
	const isOthersAlarm = type === 'shared';
	const saveAndExit = async (__, newSettings, isPrivate) => {
		// Confirm if chaning someone else's alarm
		if (isOthersAlarm && !(await Base.renderGlobal((close) => ConfirmChangeForOtherModal({
			closeFn: close,
			otherUserData,
		})))) {
			return;
		}
		// Confirm if alarm doesn't do anything
		if (_.isEmpty(newSettings.triggerFilters)) {
			const ok = await Base.renderGlobal((close) => ConfirmNoFiltersModal({ closeFn: close }));
			if (!ok) {
				return;
			}
		}
		Object.assign(trigger, {
			...model,
			userId: isPrivate ? model.userId || workspace.myUserId : null,
			name: newSettings.title,
			reportSettings: newSettings,
		});
		if (!isPrivate) {
			trigger.sharedWithUserIds = [];
		}
		await (trigger.isNew ? Trigger.add(trigger) : trigger.update());
		await workspace.runTriggers({
			triggerIds: [trigger.id],
		});
		await workspace.load();
		closeFn();
	};

	const isPrivate = !!trigger.userId;

	return (
		<FormOf
			model={model}
			content={({ field }) => (
				<PopupSelector
					expanded
					size="lg"
					okBtnStyle={{ display: 'none' }}
					onStateUpdate={({ expanded }) => expanded === false && closeFn()}
					content={() => (
						<Grid container spacing={3}>
							<AlarmsReport
								isNew={trigger.isNew}
								type={reportSettings.type}
								report={{ settings, userId: trigger.userId }}
								onCancel={closeFn}
								saveAndExit={saveAndExit}
								displayPrivateCheckbox={workspace.isAdmin || !isOthersAlarm}
							/>
							<Grid item>
								<Checkbox
									label="Use custom recepients"
									{...field('overrideDefaultInfoChannel')}
								/>
							</Grid>
							{model.overrideDefaultInfoChannel && (
								<InfoChannelPopup
									workspace={workspace}
									label="Edit custom recepients"
									infoChannel={() => model.infoChannel}
									save={async (data) => {
										model.infoChannel = data;
									}}
								/>
							)}
							{workspace.isAdmin && isPrivate && !isOthersAlarm && (
								<Grid item>
									<ShareSelector workspace={workspace} model={model} />
								</Grid>
							)}
						</Grid>
					)}
				/>
			)}
		/>
	);
}

TriggerReport.propTypes = {
	closeFn: PropTypes.func.isRequired,
	trigger: PropTypes.object.isRequired,
	workspace: PropTypes.object.isRequired,
};

TriggerReport.defaultProps = {
};

export default TriggerReport;
