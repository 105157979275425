const DynamicDateRanges = require('../dynamicDateRanges');
const ProgrammticCutomizer = require('./programmaticCustomizer');

class FutureCustomizer extends ProgrammticCutomizer {
	getInitSums() {
		return ['revenue'];
	}

	canSelectAhead() {
		return true;
	}

	canSelectBehind() {
		return false;
	}

	hasDataForToday() {
		return true;
	}

	transformedReportParams(params) {
		params.useFutureData = true;
		return params;
	}

	getDynamicDateRanges() {
		return DynamicDateRanges.getFutureRanges();
	}
}

module.exports = FutureCustomizer;
