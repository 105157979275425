import React, { useState } from 'react';
import {
	MenuItem, ListItemText, ListItemIcon, Popover,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PropTypes from 'prop-types';
import SubscriptionsEditor from './SubscriptionsEditor';

function SubscriptionsMenuButton({
	popoverAnchorEl,
	subscriptions,
	hasDynamicDateRange,
	onSubscriptionsChange,
	onClose,
}) {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<MenuItem onClick={() => {
				setIsOpen(true);
			}}
			>
				<ListItemIcon>
					<NotificationsIcon />
				</ListItemIcon>
				<ListItemText>Subscribe</ListItemText>
			</MenuItem>
			<Popover
				open={isOpen}
				anchorEl={popoverAnchorEl}
				onClose={() => {
					onClose();
					setIsOpen(false);
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<SubscriptionsEditor
					subscriptions={subscriptions}
					hasDynamicDateRange={hasDynamicDateRange}
					onSubscriptionsChange={onSubscriptionsChange}
				/>
			</Popover>
		</>
	);
}

SubscriptionsMenuButton.propTypes = {
	popoverAnchorEl: PropTypes.instanceOf(Element),
	subscriptions: PropTypes.arrayOf(PropTypes.shape({
		recipients: PropTypes.arrayOf(PropTypes.shape({
			userId: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
		})),
		occurences: PropTypes.arrayOf(PropTypes.shape({
			type: PropTypes.oneOf(['daily', 'weekly', 'monthly', 'yearly']).isRequired,
		})).isRequired,
	})).isRequired,
	hasDynamicDateRange: PropTypes.func.isRequired,
	onSubscriptionsChange: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

SubscriptionsMenuButton.defaultProps = {
	popoverAnchorEl: null,
};

export default SubscriptionsMenuButton;
