import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Constants from 'relevant-shared/reportData/constants';
import Form from '../../containers/Form';
import ExpandSelector from '../ExpandSelector';
import SystemData from '../../lib/systemData';
import Checkbox from '../Checkbox';

@Form
class UserReportOptions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultOptions: null,
		};
	}

	renderSection({
		label, options, selected, allowNoSelection,
	}) {
		const { form } = this.props;
		return (
			<Card>
				<CardContent>
					<Typography variant="h5">
						{label}
					</Typography>
					{_.entries(options).map(([key, desc]) => (
						<Checkbox
							value={_.includes(selected, key)}
							name={key}
							label={desc}
							onChange={(ev) => form.update(() => {
								if (!ev.target.value && selected.length === 1 && selected[0] === key && !allowNoSelection) {
									return; // we need 1+ checkbox to always be ticked, except for otherOptions.
								}
								_.pull(selected, key);
								if (ev.target.value) {
									selected.push(key);
								}
							})}
							key={key}
							fullWidth
						/>
					))}
				</CardContent>
			</Card>
		);
	}

	render() {
		const { model, field, getDefaultsFn } = this.props;
		const { defaultOptions } = this.state;
		const { hbaEnabled, disableAudience, futureEnabled } = SystemData.genericData;
		const types = ['programmatic', hbaEnabled && 'hb', !disableAudience && 'audience', futureEnabled && 'future'].filter((ty) => ty);
		const isDisabled = getDefaultsFn && !model.overrideDefaults;
		return (
			<ExpandSelector
				title="User visible report options"
				fn={async () => this.setState({
					defaultOptions: getDefaultsFn && (await getDefaultsFn()),
				})}
				content={() => (
					<div>
						{!!getDefaultsFn && (
							<Checkbox
								{...field('overrideDefaults')}
								label="Override default values"
								preReaction={(override) => {
									if (override) { // copy default values when enabling override
										Object.assign(model, _.omit(_.cloneDeep(defaultOptions), ['overrideDefaults']));
									}
								}}
							/>
						)}
						<Grid container spacing={3}>
							{types.map((type) => {
								const {
									DESCRIPTION, SUM_DESCRIPTIONS, GROUP_BY_OPTIONS, OTHER_OPTIONS, NON_USER_OPTIONS = [],
								} = Constants[type];
								const reportTypeOptions = (isDisabled ? defaultOptions : model)[type];
								const sx = isDisabled ? { pointerEvents: 'none', opacity: 0.38 } : {};
								return (
									<Box component={Grid} key={type} item xs={12} sx={sx}>
										<Card>
											<CardContent>
												<Grid container spacing={3}>
													<Grid item xs={12}>
														<Typography variant="h4">
															{DESCRIPTION}
														</Typography>
													</Grid>
													<Grid item xs={12}>
														{this.renderSection({
															label: 'Dimensions',
															options: _.omit(GROUP_BY_OPTIONS, NON_USER_OPTIONS),
															selected: reportTypeOptions.USER_GROUP_BY_OPTIONS,
															allowNoSelection: false,
														})}
													</Grid>
													<Grid item xs={12}>
														{this.renderSection({
															label: 'Metrics',
															options: SUM_DESCRIPTIONS,
															selected: reportTypeOptions.USER_SUM_OPTIONS,
															allowNoSelection: false,
														})}
													</Grid>
													<Grid item xs={12}>
														{OTHER_OPTIONS && this.renderSection({
															label: 'Other options',
															options: OTHER_OPTIONS,
															selected: reportTypeOptions.USER_OTHER_OPTIONS,
															allowNoSelection: true,
														})}
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Box>
								);
							})}

						</Grid>
					</div>
				)}
			/>
		);
	}
}

UserReportOptions.propTypes = {
	model: PropTypes.object.isRequired,
	getDefaultsFn: PropTypes.func,
};

UserReportOptions.defaultProps = {
	getDefaultsFn: undefined,
};

export default UserReportOptions;
