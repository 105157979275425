import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Segment } from '../../api/relevant';
import SegmentList from '../SegmentList/index';
import ExpandSelector from '../ExpandSelector';
import MiscUtils from '../../lib/miscUtils';
import PopupSelector from '../PopupSelector';

const SELECTOR_BY_TYPE = {
	ExpandSelector,
	PopupSelector,
};

class SegmentSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = { };
		this.byDmpId = {};
	}

	async loadSegments() {
		let { segments } = this.props;
		const { onlyWithWhitelists } = this.props;
		if (!segments) {
			segments = await Segment.call('getSegments', {
				noFilters: true,
				calculateExtras: true,
				onlyRevenueGeneratingForUser: true,
			});
		}
		this.byDmpId = _.keyBy(segments, 'dmpId');
		let newList = MiscUtils.alphaSorted(segments, 'name');
		if (onlyWithWhitelists) {
			newList = newList.filter((seg) => seg.revShareMethod === 'whitelist');
		}
		this.setState({ segments: newList });
	}

	getSelected() {
		const { selected, selectByWhitelistedSites } = this.props;
		const { segments } = this.state;
		if (!segments || !selectByWhitelistedSites) {
			return selected;
		}
		const selectMap = _.zipObject(selected, Array(selected.length).fill(true));
		const siteMap = _.zipObject(selectByWhitelistedSites, Array(selectByWhitelistedSites.length).fill(true));
		const extra = segments
			.filter((seg) => !selectMap[seg.dmpId] && seg.revShareMethod === 'whitelist' && (seg.revenueSites || []).find((s) => siteMap[s]))
			.map((seg) => seg.dmpId);
		return [].concat(selected, extra);
	}

	render() {
		const { segments, selectedInternal } = this.state;
		const {
			onChange, title, innerStyle, selectable, singleSelect, deselectOnClickaway,
			selectorType, byWhitelistedSites, fields, extraFilters, noSelectCount,
		} = this.props;
		const Selector = SELECTOR_BY_TYPE[selectorType];
		const allSelected = this.getSelected();
		return (
			<Selector
				title={title}
				selected={noSelectCount ? [] : allSelected}
				fn={() => this.loadSegments()}
				innerStyle={innerStyle}
				getSingleTitle={(dmpId) => (this.byDmpId[dmpId] || {}).name}
				onApplyChanges={() => this.state.selectedInternal && onChange(this.state.selectedInternal)}
			>
				{segments
					&& (
						<SegmentList
							segments={segments}
							selected={selectedInternal || allSelected}
							onChange={(items) => {
								const ids = items.map((seg) => seg.dmpId);
								if (Selector.useChangeOnOk) {
									this.setState({ selectedInternal: ids });
								} else {
									onChange(ids);
								}
							}}
							noContainer
							selectable={selectable}
							deselectOnClickaway={deselectOnClickaway}
							singleSelect={singleSelect}
							byWhitelistedSites={byWhitelistedSites}
							fields={fields}
							extraFilters={extraFilters}
						/>
					)}
			</Selector>
		);
	}
}

SegmentSelect.propTypes = {
	onChange: PropTypes.func.isRequired,
	selected: PropTypes.array,
	segments: PropTypes.array,
	title: PropTypes.string,
	innerStyle: PropTypes.object,
	selectable: PropTypes.bool,
	singleSelect: PropTypes.bool,
	deselectOnClickaway: PropTypes.bool,
	selectorType: PropTypes.oneOf(['ExpandSelector', 'PopupSelector']),
	byWhitelistedSites: PropTypes.array,
	selectByWhitelistedSites: PropTypes.array,
	fields: PropTypes.array,
	extraFilters: PropTypes.bool,
	onlyWithWhitelists: PropTypes.bool,
	noSelectCount: PropTypes.bool,
};

SegmentSelect.defaultProps = {
	selected: [],
	onChange: () => {},
	title: 'Segments',
	segments: undefined,
	innerStyle: {},
	selectable: true,
	singleSelect: false,
	deselectOnClickaway: false,
	selectorType: 'ExpandSelector',
	byWhitelistedSites: undefined,
	selectByWhitelistedSites: undefined,
	fields: undefined,
	extraFilters: false,
	onlyWithWhitelists: false,
	noSelectCount: false,
};

export default SegmentSelect;
