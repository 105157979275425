import PropTypes from 'prop-types';
import React from 'react';
import Container from '@mui/material/Container';
import styles from './styles.css';
import PublisherEditor from '../../containers/PublisherEditor';

class PublisherEdit extends React.Component {
	render() {
		const { id, siteId, placementId } = this.props.match.params;

		return (
			<div className={styles.pageContentWrapper}>
				<Container maxWidth="xl">
					<PublisherEditor id={id} goToSiteId={siteId} goToPlacementId={placementId} />
				</Container>
			</div>
		);
	}
}

PublisherEdit.propTypes = {
	match: PropTypes.object.isRequired,
};

PublisherEdit.defaultProps = {

};

export default PublisherEdit;
