import {
	useState,
	useEffect,
	useCallback,
} from 'react';
import {
	getUserData,
	addUserData,
} from '../../../api/relevant';
import { stores } from '../../../stores';
import SystemData from '../../../lib/systemData';
import { demoMessage, isDemoUser } from '../../../components/DemoImport/utils';

export function useDashboardWidgets() {
	const [widgets, setWidgets] = useState({ audience: [], programmatic: [] });

	// Fetch widgets from server on mount
	useEffect(() => {
		async function fetchWidgets() {
			const { id } = stores.identity.user();
			const { result } = await getUserData(id);
			if (result && result.dashboards) {
				const { hbaEnabled } = SystemData.genericData;
				const { dashboards: fetchedWidgets } = result;
				if (_.isArray(fetchedWidgets.programmatic) && !hbaEnabled) {
					fetchedWidgets.programmatic = fetchedWidgets.programmatic.filter((d) => (d.componentProperties || {}).type !== 'hb');
				}
				const finalWidgets = {
					audience: fetchedWidgets?.audience || [],
					programmatic: fetchedWidgets?.programmatic || [],
				};
				setWidgets(finalWidgets);
			}
		}
		fetchWidgets();
	}, []);

	const updateWidgets = useCallback(async (updatedWidgets) => {
		const { id } = stores.identity.user();

		const requestBody = { dashboards: updatedWidgets };
		if (isDemoUser()) {
			demoMessage();
		} else {
			await addUserData(id, requestBody);
		}
		setWidgets(updatedWidgets);
	}, []);

	return [widgets, updateWidgets];
}
