const _ = require('lodash');
const { setupDimension } = require('./mappingDimensionSetup');

const AdvertiserDimension = {
	mappingEnabled: true,
	name: 'advertiser',
	reportProp: 'advMappingId',
	defaultMappingKv: 'defaultAdvMappingId',
	dimension: 'advertiserNr',
	isCustomImportField: true,
	futureEnabled: true,
	importExcludeFilter: (u) => u.isDirect,
	importExcludeDescription: 'Exclude data from direct Advertiser(s)',
};

const BuyerDimension = {
	mappingEnabled: true,
	name: 'buyer',
	dimension: 'buyerNr',
	isCustomImportField: true,
	importExcludeFilter: () => true,
	importExcludeDescription: 'Exclude data from Buyer(s) including RTB',
};

const DealDimension = {
	sysSpecific: true,
	name: 'deal',
	dimension: 'dealNr',
	sysIdFld: 'dealId', // For example Deal ID / Line item ID in SSP
	uniqFld: 'uniqueDealId', // Unique name that includes the SSP - "[sspName]-[id]"
	isCustomImportField: true,
};

const BidderDimension = {
	name: 'bidder',
	uiName: 'DSP',
	dimension: 'bidderNr',
	mappingEnabled: true,
	isCustomImportField: true,
};

const SizeDimension = {
	isStatic: true,
	uiName: 'Creative Size',
	name: 'size',
	dimension: 'size',
};

const PaymentTypeDimension = {
	isStatic: true,
	uiName: 'Payment Type',
	name: 'paymentTypeId',
	dimension: 'paymentTypeId',
};

const MediaTypeDimension = {
	isStatic: true,
	uiName: 'Media Type',
	name: 'mediaTypeId',
	dimension: 'mediaTypeId',
};

const DealTypeDimension = {
	isStatic: true,
	uiName: 'Deal Type',
	name: 'dealTypeId',
	dimension: 'dealTypeId',
};

const LineItemDimension = {
	sysSpecific: true,
	uiName: 'Line Item',
	name: 'lineItem',
	dimension: 'lineItemNr',
	isCustomImportField: true,
	futureEnabled: true,
	importExcludeFilter: () => true,
};

const OrderDimension = {
	sysSpecific: true,
	name: 'order',
	dimension: 'orderNr',
	isCustomImportField: true,
	futureEnabled: true,
	importExcludeFilter: () => true,
};

const CustomImportDimension = {
	name: 'customImportDim',
	dimension: 'customImportDimNr',
	hideAsDefault: true,
};

/** WARNING: Must match order CombinationNr.numbers - don't change order */
const AllDimensions = [
	AdvertiserDimension,
	BuyerDimension,
	DealDimension,
	BidderDimension,
	SizeDimension,
	PaymentTypeDimension,
	MediaTypeDimension,
	DealTypeDimension,
	LineItemDimension,
	OrderDimension,
	CustomImportDimension,
];

const AllStaticDimensions = AllDimensions.filter((d) => d.isStatic);

const AllSeqObjectDimensions = AllDimensions.filter((d) => !d.isStatic);

AllDimensions.forEach((d, idx) => setupDimension(d, idx));

AllSeqObjectDimensions.byName = (name) => _.find(AllSeqObjectDimensions, { name }) || AdvertiserDimension;

const AllMappingDimensions = _.filter(AllSeqObjectDimensions, 'mappingEnabled');

AllMappingDimensions.byName = AllSeqObjectDimensions.byName;

const CustomImportFields = [
	{
		desc: 'SSP id',
		fld: 'source',
	},
	{
		desc: 'SSP placement ID',
		fld: 'placement',
	},
	{
		desc: 'SSP placement name',
		fld: 'placementName',
	},
	{
		desc: 'Is Direct ("true" means yes)',
		fld: 'isDirect',
	},
	...AllDimensions.filter((d) => d.isCustomImportField).map((d) => ({ desc: d.uiCName, fld: d.unitNameFld })),
];

module.exports = {
	AllSeqObjectDimensions,
	AllMappingDimensions,
	AllDimensions,
	AllStaticDimensions,
	CustomImportFields,
};
