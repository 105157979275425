import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const PREFIX = 'SelectorDropdown';

const classes = {
	root: `${PREFIX}-root`,
	textColor: `${PREFIX}-textColor`,
};

const StyledBox = styled(Box)(({
	theme: { palette: { primary } },
}) => ({
	[`& .${classes.root}`]: {
		background: primary.main,
	},

	[`& .${classes.textColor}`]: {
		color: primary.contrastText,
	},
}));

let instanceCounter = 0;

function SelectorDropdown({
	title, items, selected, onChange, className,
}) {
	const [labelId] = useState(`SelectorDropdown-${++instanceCounter}`);
	const [open, setOpen] = useState(false);
	const handleClose = useCallback(() => setOpen(false));
	const handleOpen = useCallback(() => setOpen(true));
	const handleChange = useCallback((event) => {
		const { value } = event.target;
		const index = items.findIndex((item) => item.value === value);
		onChange(event, index, value);
	}, []);
	return (
		<StyledBox
			display="flex"
			border={2}
			borderColor="primary.main"
			borderRadius="8px"
			bgcolor="primary.main"
			alignItems="center"
			boxShadow={1}
			textAlign="center"
			className={`${classes.root} ${className}`}
		>
			<Box
				component="label"
				id={labelId}
				color="primary.contrastText"
				flexGrow={1}
				borderRight={2}
				borderColor="primary.contrastText"
				fontSize="1rem"
				fontWeight="fontWeightLight"
				onClick={handleOpen}
			>
				{title}
			</Box>
			<Box flexGrow={1}>
				<Select
					labelId={labelId}
					value={selected}
					open={open}
					onClose={handleClose}
					onOpen={handleOpen}
					onChange={handleChange}
					className={classes.textColor}
					classes={{ icon: `MuiSelect-icon ${classes.textColor}` }}
					fullWidth
				>
					{items.map((item) => (
						<MenuItem
							key={item.value}
							value={item.value}
						>
							{item.name}
						</MenuItem>
					))}
				</Select>
			</Box>
		</StyledBox>
	);
}

SelectorDropdown.propTypes = {
	title: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	selected: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
};

SelectorDropdown.defaultProps = {
	className: '',
};

export default SelectorDropdown;
