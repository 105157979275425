const _ = require('lodash');
const uuid = require('uuid');

const roundNum = (n) => parseFloat(n.toFixed(2));

class DemoSettings {
	constructor({
		publishers, ssps, adservers, settings, editPublisher,
	}) {
		this.ssps = ssps.filter((ssp) => ssp.type === 'DemoSsp');
		this.sspsById = _.keyBy(ssps, 'id');
		this.adserver = adservers.find((ads) => ads.type === 'DemoAdserver');
		this.pubsById = _.keyBy(publishers, 'id');
		this.recentlyDisabledPubSettings = {};
		if (this.ssps.length === 0 || !this.adserver) {
			throw Error('No Demo SSPs and/or Adserver is created, please make sure they exist');
		}
		Object.assign(this, { children: {}, editPublisher }, settings);
		_.forOwn(this.children, ({ generic = {} }, pubId) => {
			let { bySite = {}, bySsp = {} } = generic;
			const pub = this.pubsById[pubId];
			this.ssps.forEach((ssp) => {
				if (!bySsp[ssp.id]) {
					bySsp[ssp.id] = { impSharePerc: 0 };
				}
			});
			(pub.websites || []).forEach((site) => {
				if (!bySite[site.id]) {
					bySite[site.id] = { impSharePerc: 0 };
				}
			});
			bySsp = _.pickBy(bySsp, (__, sspId) => this.sspsById[sspId]);
			bySite = _.pickBy(bySite, (__, siteId) => pub.__sitesById[siteId]);
			Object.assign(generic, { bySite, bySsp });
			_.defaults(generic, DemoSettings.defaultGenericBase());
		});
	}

	getOrAddChild(parentSettings, childId) {
		parentSettings.children = parentSettings.children || {};
		parentSettings.children[childId] = parentSettings.children[childId] || {};
		const dst = parentSettings.children[childId];
		_.defaults(dst, { children: {}, generic: {} });
		return dst;
	}

	static defaultGenericBase() {
		return {
			openRtbPerc: 80,
			dealsPerc: 20,
			bySsp: {},
			adInPerDay: Array(7).fill(0),
			fillRatePerc: 75,
			clickPerc: 0.5,
			ismPerc: 50,
			ecpm: 1.0,
			bySite: {},
		};
	}

	defaultGeneric(pubId) {
		const res = DemoSettings.defaultGenericBase();
		const pub = this.pubsById[pubId];
		if (!pub) {
			throw Error('Invalid publisher');
		}
		this.ssps.forEach((ssp) => {
			res.bySsp[ssp.id] = { impSharePerc: roundNum(100 / this.ssps.length) };
		});
		const { websites = [] } = pub;
		websites.forEach((site) => {
			res.bySite[site.id] = { impSharePerc: roundNum(100 / websites.length) };
		});
		return res;
	}

	async syncSystemsToPublishers(pubs) {
		const allFakeSsps = _.keyBy(this.ssps, 'id');
		for (const pub of pubs) {
			const pubSettings = this.publisherSettings(pub.id);
			let changed = false;
			const { generic = {} } = pubSettings || {};
			(pub.websites || []).forEach((site) => {
				(site.placements || []).forEach((placement) => {
					const ads = (placement.adservers || [])[0];
					const hasFakeAds = ads && ads.adserverId === this.adserver.id;
					if (pubSettings && !hasFakeAds) {
						placement.adservers = [{ adserverId: this.adserver.id, settings: { dummy: true } }];
						changed = true;
					} else if (!pubSettings && hasFakeAds) {
						placement.adservers = []; // reset
						changed = true;
					}
					placement.ssps = placement.ssps || [];
					const toHave = generic.bySsp || {};
					const existing = _.keyBy(placement.ssps, 'source');
					placement.ssps = placement.ssps.filter((sspPlac) => {
						if (allFakeSsps[sspPlac.source] && !toHave[sspPlac.source]) {
							changed = true;
							return false;
						}
						return true;
					});
					Object.keys(toHave).forEach((toHaveId) => {
						if (existing[toHaveId]) {
							return;
						}
						changed = true;
						placement.ssps.push({
							source: toHaveId,
							id: `demo-${uuid().toString()}`,
						});
					});
				});
			});
			if (changed) {
				await this.editPublisher(pub.id, pub);
			}
		}
	}

	normalize(pubId, triggerName, triggerValue) {
		const pubSettings = this.publisherSettings(pubId, true);
		const pub = this.pubsById[pubId];
		const { generic } = pubSettings;
		const groups = [
			{
				total: 100,
				members: ['openRtbPerc', 'dealsPerc'],
			}, {
				total: 100,
				members: _.sortBy(_.keys(generic.bySsp), (sspId) => this.sspsById[sspId].name).map((sspId) => `bySsp.${sspId}.impSharePerc`),
			},
			{
				total: 100,
				members: _.sortBy(_.keys(generic.bySite), (siteId) => pub.__sitesById[siteId].domain).map((siteId) => `bySite.${siteId}.impSharePerc`),
			},
		];
		let valAsNum = 0;
		if (triggerName) {
			valAsNum = parseFloat(triggerValue);
			if (isNaN(valAsNum)) {
				return; // ignore
			}
		}
		const numericOf = (name) => {
			const val = _.get(generic, name);
			if (val == null) {
				return 0;
			}
			const res = parseFloat(val);
			return isNaN(res) ? 0 : res;
		};
		groups.forEach((group) => {
			let toShare = group.total;
			let toChange = group.members;
			if (triggerName) {
				const idx = group.members.indexOf(triggerName);
				if (idx < 0) {
					return; // other group changed
				}
				toShare -= valAsNum;
				const beforeChange = group.members.slice(0, idx);
				if (idx === group.members.length - 1) {
					toChange = beforeChange;
				} else {
					toChange = group.members.slice(idx + 1);
					toShare -= _.sumBy(beforeChange, (n) => numericOf(n));
				}
			}
			const currentOtherTotal = _.sumBy(toChange, (n) => numericOf(n));
			const mult = currentOtherTotal ? toShare / currentOtherTotal : 1;
			toChange.forEach((name) => {
				_.set(generic, name, roundNum(numericOf(name) * mult));
			});
		});
	}

	setEnabled(pubId, enabled) {
		if (!enabled && this.children[pubId]) {
			this.recentlyDisabledPubSettings[pubId] = this.children[pubId];
			delete this.children[pubId];
		} else if (enabled) {
			this.children[pubId] = this.recentlyDisabledPubSettings[pubId] || { generic: this.defaultGeneric(pubId) };
			delete this.recentlyDisabledPubSettings[pubId];
		}
	}

	setSspEnabled(pubId, sspId, enabled) {
		const pubSettings = this.publisherSettings(pubId, true);
		const { generic } = pubSettings;
		if (enabled) {
			if (!generic.bySsp[sspId]) {
				generic.bySsp[sspId] = { impSharePerc: 0 };
			}
		} else {
			delete generic.bySsp[sspId];
		}
		this.normalize(pubId);
	}

	publisherSettings(id, require) {
		const res = this.children[id];
		if (require && !res) {
			throw Error('No such publisher..');
		}
		return res;
	}

	getObject() {
		return 	_.pick(this, ['children', 'generic']);
	}
}

module.exports = DemoSettings;
