import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { ActionButton } from '../ActionButton/ActionButton';

function TriggerActionButton({ action, matches, workspace }) {
	return (
		<Grid key={action.name} item>
			<ActionButton
				label={`${action.name} (${matches.length})`}
				color="primary"
				disabled={!matches.length}
				onClick={() => workspace.performAction(action, matches)}
				icon={React.createElement(action.icon)}
			/>
		</Grid>
	);
}

TriggerActionButton.propTypes = {
	action: PropTypes.object.isRequired,
	matches: PropTypes.array.isRequired,
	workspace: PropTypes.object.isRequired,
};

TriggerActionButton.defaultProps = {
};

export default TriggerActionButton;
