import { useMemo } from 'react';
import useLocalStorage from '../../../../../hooks/useLocalStorage';

function sortReports(reports, sortProperty, sortDirectionAscending) {
	switch (sortProperty) {
		case 'createdDate':
			return reports.sort((a, b) => {
				const aDate = new Date(a.createdDate);
				const bDate = new Date(b.createdDate);
				if (sortDirectionAscending) {
					if (!a.CreatedDate) {
						return -1;
					}
					return aDate.getTime() - bDate.getTime();
				}
				if (!a.CreatedDate) {
					return 1;
				}
				return bDate.getTime() - aDate.getTime();
			});
		case 'name':
			return reports.sort((a, b) => {
				const res = a.settings.title.localeCompare(b.settings.title);
				return sortDirectionAscending ? res : -res;
			});
		default:
			return reports;
	}
}

function useSort(reports) {
	const [sortDirectionAscending, setSortDirectionAscending] = useLocalStorage(
		'reportsOverviewSortDirectionAscending',
		false,
	);
	const [sortProperty, setSortProperty] = useLocalStorage('reportsOverviewSortProperty', 'createdDate');

	const onSortPropertyChange = (value) => {
		setSortProperty(value);
	};

	const onSortDirectionAscendingChange = () => {
		setSortDirectionAscending(!sortDirectionAscending);
	};

	const sortedReports = useMemo(() => {
		if (reports) {
			return sortReports([...reports], sortProperty, sortDirectionAscending);
		}
		return [];
	}, [reports, sortDirectionAscending, sortProperty]);

	return {
		sortedReports,
		sortProperty,
		sortDirectionAscending,
		onSortPropertyChange,
		onSortDirectionAscendingChange,
	};
}

export default useSort;
