import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import SwapIcon from '@mui/icons-material/SwapHoriz';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ActionButton } from '../ActionButton/ActionButton';
import PopupSelector from '../PopupSelector';
import TreeSelect from '../TreeSelect';
import { objFns } from './utils';

const LEVEL_BY_TYPE = {
	Publisher: 1,
	Site: 2,
	Placement: 3,
};

class MoveSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dst: null,
		};
	}

	moveToDst(doRemove) {
		let { dst } = this.state;
		if (doRemove) {
			dst = {}; // move out to nowhere with this empty object that will be discarded
		}
		const {
			selected, onMoved, onDeleted, candRoot,
		} = this.props;
		if (!dst) {
			return;
		}

		const parentOf = (node) => (node.__type === 'Publisher' ? candRoot : node.__parent);

		const updateHasNew = (node) => {
			const old = node.__hasNew;
			node.__hasNew = !!objFns.getChildren(node).find((n) => n.__hasNew);
			if (!old !== !node.__hasNew && parentOf(node)) {
				updateHasNew(parentOf(node));
			}
		};

		const remove = (node) => {
			const parent = parentOf(node);
			if (!parent) {
				return;
			}
			const arr = objFns.getChildren(parent);
			_.pull(arr, node);
			if (parent.isNew && !arr.length) {
				remove(parent);
			} else if (!parent.isNew) {
				updateHasNew(parent);
			}
		};

		const dstArrName = objFns.childArrName(dst);
		dst[dstArrName] = dst[dstArrName] || [];
		const dstArr = dst[dstArrName];
		selected.forEach((node) => {
			if (node.__parent === dst) {
				return;
			}
			remove(node);
			node.__parent = dst;
			dstArr.push(node);
		});
		if (!dst.isNew) {
			updateHasNew(dst);
		}
		this.setState({ dst: null });
		if (doRemove) {
			onDeleted();
		} else {
			onMoved(dst);
		}
	}

	render() {
		const { dst } = this.state;
		const {
			candRoot, dstLevel, desc, selected, disabled,
		} = this.props;
		const rootNode = { ...candRoot, name: `Select destination ${dstLevel}` };
		return (
			<Card>
				<CardContent>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h2">
								<strong>{selected.length}</strong>
&nbsp;selected new
								{desc}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<PopupSelector
								onApplyChanges={() => this.moveToDst()}
								customLink={(showFn) => (
									<ActionButton
										label="Move"
										disabled={disabled || !selected.length}
										icon={<SwapIcon />}
										color="primary"
										onClick={() => {
											showFn();
										}}
									/>
								)}
								content={() => (
									<TreeSelect
										getChildren={(n) => {
											if (n.__type === dstLevel) {
												return [];
											}
											const children = objFns.getChildren(n);
											return n.__type === 'Root' ? children.filter((p) => p.programmaticAccess) : children;
										}}
										getLabel={(n) => objFns.getLabel(n) || 'Error'}
										getNodeId={objFns.getNodeId}
										rootNode={rootNode}
										selectedObjects={dst ? [dst] : []}
										expandOnce={[rootNode]}
										onSelectionChange={(__, { node, isAdd }) => this.setState({
											dst: node.__type === dstLevel && isAdd ? node : null,
										})}
										selectAllStartLevel={LEVEL_BY_TYPE[dstLevel] || 0}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<ActionButton
								label="Exclude from this import"
								disabled={disabled || !selected.length}
								color="primary"
								icon={<DeleteIcon />}
								onClick={() => this.moveToDst(true)}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	}
}

MoveSelector.propTypes = {
	candRoot: PropTypes.object.isRequired,
	dstLevel: PropTypes.string.isRequired,
	desc: PropTypes.string.isRequired,
	selected: PropTypes.array.isRequired,
	onMoved: PropTypes.func.isRequired,
	onDeleted: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

MoveSelector.defaultProps = {
	disabled: false,
};

export default MoveSelector;
