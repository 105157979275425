import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	List,
	Collapse,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import Subscription from './Subscription';

function SubscriptionList({ subscriptions, onDeleteSubscription }) {
	return (
		<div>
			{ subscriptions.length === 0 ? (
				<Box
					component="p"
					color="text.secondary"
					sx={{ fontStyle: 'italic' }}
				>
					No subscriptions
				</Box>
			) : (

				<List>
					<TransitionGroup>
						{ subscriptions.map(({ id, occurences, recipients }, index) => (
							<Collapse
								key={id}
							>
								<Subscription
									key={id}
									onDelete={() => onDeleteSubscription(index)}
									occurences={occurences}
									recipients={recipients}
								/>
							</Collapse>
						))}
					</TransitionGroup>
				</List>
			)}
		</div>
	);
}

export default SubscriptionList;

SubscriptionList.propTypes = {
	subscriptions: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		recipients: PropTypes.arrayOf(PropTypes.shape({
			userId: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
		})),
		occurences: PropTypes.arrayOf(PropTypes.shape({
			type: PropTypes.oneOf(['daily', 'weekly', 'monthly', 'yearly']).isRequired,
		})).isRequired,
	})).isRequired,
	onDeleteSubscription: PropTypes.func.isRequired,
};
