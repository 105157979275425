import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ActionButton } from '../ActionButton/ActionButton';
import { PrebidBuildConfiguration } from '../../api/relevant';
import useRequest from '../../hooks/useRequest/useRequest';
import SimpleOperationWrapper from '../SimpleOperationWrapper';
import PrebidBuildForm from './PrebidBuildForm';
import SimpleDialog from '../SimpleDialog';

function PrebidBuildFormModal({ match, location, onSave }) {
	const [setRequest, loading, error, reset] = useRequest({});
	const [name, setName] = useState('');
	const [pbjsGlobalName, setPbjsGlobalName] = useState('');
	const [version, setVersion] = useState(null);
	const [additionalModules, setAdditionalModules] = useState([]);
	const history = useHistory();

	const onNameChange = useCallback((n) => setName(n), []);
	const onPbjsGlobalChange = useCallback((n) => setPbjsGlobalName(n), []);
	const onVersionChange = useCallback((v) => setVersion(v), []);
	const onAdditionalModulesChange = useCallback((modules) => setAdditionalModules(modules), []);

	const parentPath = `${match.path}${location.search}`;

	const onSubmit = () => {
		setRequest({
			requestFunction: async () => (
				PrebidBuildConfiguration.call('create', ({
					name, version, additionalModules, pbjsGlobalName,
				}))
			),
			requestCallback: () => {
				history.push(parentPath);
				if (onSave) {
					onSave();
				}
			},
		});
	};

	return (
		<SimpleDialog
			open
			title="Add a new Prebid build"
			onSubmit={onSubmit}
			dialogProps={{
				maxWidth: 'sm',
				fullWidth: true,
			}}
			parentPath={parentPath}
		>
			<SimpleOperationWrapper
				loading={loading}
				error={error}
				onErrorModalClick={reset}
			>
				<PrebidBuildForm
					version={version}
					name={name}
					pbjsGlobalName={pbjsGlobalName}
					additionalModules={additionalModules}
					onVersionChange={onVersionChange}
					onNameChange={onNameChange}
					onPbjsGlobalNameChange={onPbjsGlobalChange}
					onAdditionalModulesChange={onAdditionalModulesChange}
					renderContent={(content) => (
						<DialogContent style={{ paddingTop: '8px' }}>{content}</DialogContent>
					)}
					renderActions={(isLoading, submittable = true) => {
						const disabled = isLoading || !name || !submittable;
						return (
							<DialogActions>
								<ActionButton
									variant="text"
									label="Cancel"
									onClick={() => history.push(parentPath)}
									disabled={isLoading}
								/>
								<ActionButton
									label="Save"
									onClick={onSubmit}
									disabled={disabled}
								/>
							</DialogActions>
						);
					}}
				/>
			</SimpleOperationWrapper>
		</SimpleDialog>
	);
}

PrebidBuildFormModal.propTypes = {
	match: PropTypes.shape({
		path: PropTypes.string.isRequired,
	}).isRequired,
	location: PropTypes.shape({
		search: PropTypes.string.isRequired,
	}).isRequired,
	onSave: PropTypes.func,
};

PrebidBuildFormModal.defaultProps = {
	onSave: null,
};

export default PrebidBuildFormModal;
