import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import '../styles/base.css';
import PageFooter from '../components/PageFooter';
import {
	MySegmentsPage, CopyAuthCodePage, SelectPasswordPage, RecoverPasswordPage, OAuthSimplePage,
} from '../pages/SimplePages';

class Public extends React.Component {
	render() {
		const { match } = this.props;
		return (
			<div>
				<Switch>
					<Route exact path={`${match.path}/copy-auth-code`} render={() => <CopyAuthCodePage />} />
					<Route exact path={`${match.path}/my-segments`} render={() => <MySegmentsPage isPublic />} />
					<Route exact path={`${match.path}/selectPassword/:token`} render={() => <SelectPasswordPage />} />
					<Route exact path={`${match.path}/recoverPassword`} render={() => <RecoverPasswordPage />} />
					<Route path={`${match.path}/oauth`} render={() => <OAuthSimplePage />} />
					<Redirect to="/login" />
				</Switch>
			</div>
		);
	}
}

Public.propTypes = {
	match: PropTypes.object.isRequired,
};

export default Public;
