const _ = require('lodash');
const EnvReportInterface = require('relevant-shared/reportData/envReportInterface');
const TypeCustomizer = require('./typeCustomizer');

class ProgrammaticCustomizer extends TypeCustomizer {
	getInitSums({ isAdministrator }) {
		const options = EnvReportInterface.getGenericData().userReportOptions.programmatic.USER_SUM_OPTIONS;
		if (isAdministrator) {
			return ['revenueAfterSsp'];
		} if (options.includes('publisherRevenue')) {
			return ['publisherRevenue'];
		} if (options.includes('revenueAfterSsp')) {
			return ['revenueAfterSsp'];
		}
		return [options[0]];
	}

	getGroupByOptions({ GROUP_BY_OPTIONS }, { placementLabels }) {
		const hasLabels = placementLabels && Object.keys(placementLabels).length > 1;
		// Don't display placement label option if there are no labels
		// (Might be one 'Unlabelled' label, but interpret as empty)
		return hasLabels ? GROUP_BY_OPTIONS : _.omit(GROUP_BY_OPTIONS, 'placementLabel');
	}
}

module.exports = ProgrammaticCustomizer;
