import _ from 'lodash';
import validator from 'validator';

const validators = {
	required: (bool) => ({
		valid: (value) => {
			if (!bool) {
				return true;
			}
			if (value === null || value === undefined) {
				return false;
			}
			if (_.isString(value) || Array.isArray(value)) {
				return value.length > 0;
			}
			return true;
		},
		message: () => 'Required.',
		checkOnEmpty: true,
	}),
	email: () => ({
		valid: (value) => validator.isEmail(value || ''),
		message: () => 'Valid e-mail required.',
	}),
	emails: () => ({
		valid: (value) => !((value || '').split(';').find((part) => !validator.isEmail(part))),
		message: () => 'Valid e-mail address(es) required.',
	}),
	textonly: () => ({
		valid: (value) => value.match(/^[^-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\d´`@#€¨\\]*$/),
		message: () => 'Letters and spaces only.',
	}),
	alphanumeric: () => ({
		valid: (value) => (!value ? true : value.match(/^[a-å0-9/\s/]*$/i)),
		message: () => 'Letters, numbers and spaces only.',
	}),
	domain: () => ({
		valid: (value) => validator.isFQDN(value),
		message: () => 'Valid domain required.',
	}),
	minlen: (length) => ({
		valid: (value) => value.toString().length >= parseInt(length, 10),
		message: () => `Minimum ${length} characters.`,
	}),
	maxlen: (length) => ({
		valid: (value) => value.toString().length <= parseInt(length, 10),
		message: () => `Maximum ${length} characters.`,
	}),
	integer: () => ({
		valid: (value) => value !== null && value !== undefined && value !== 'NaN' && parseInt(value, 10).toString() === value.toString(),
		message: () => 'Whole number required.',
	}),
	float: () => ({
		valid: (value) => value !== null && value !== undefined && value !== 'NaN' && parseFloat(value).toString() === value.toString(),
		message: () => 'Number required.',
	}),
	match: ({ pattern, message = 'Wrong format.' }) => ({
		valid: (value) => (_.isString(pattern) ? new RegExp(pattern) : pattern).test(value),
		message: () => message,
	}),
	satisfy: ({ valid = (value) => value, message = 'Rules broken.', checkOnEmpty = false }) => ({
		valid,
		message: () => message,
		checkOnEmpty,
	}),
	between: ({ low, high }) => ({
		valid: (value) => !isNaN(parseFloat(value)) && (low == null || parseFloat(value) >= low) && (high == null || parseFloat(value) <= high),
		message: () => `Number between ${low} and ${high} required.`,
	}),
	url: () => ({
		valid: (value) => validator.isURL(value),
		message: () => 'Invalid URL',
	}),

};

export default validators;
