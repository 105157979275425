import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CountryList from 'country-list';
import withTheme from '@mui/styles/withTheme';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckedIcon from '@mui/icons-material/Check';
import PlusIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DataTable from '../DataTable';
import Checkbox from '../Checkbox';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import RadioButtonGroup from '../RadioButtonGroup';
import OperationWrapper from '../OperationWrapper';
import ApproveSettings from './approveSettings';
import MultiRevenueImport from './multiRevenueImport';
import SiteSelect from '../SiteSelect';
import ExpandSelector from '../ExpandSelector';

function NoWrapper(props) {
	return <div>{props.children}</div>;
}

const GUI_COUNTRY_CODES = {
	fi: 'Finland',
	dk: 'Denmark',
	se: 'Sweden',
	no: 'Norway',
};

class Container extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			title, noContainer, segments, children,
		} = this.props;
		if (noContainer) {
			return (<div>{children}</div>);
		}
		return (
			<Card>
				<CardContent>
					<Typography variant="h2">
						{title || 'Segments'}
					</Typography>
					<MultiRevenueImport segments={segments} />
					{children}
				</CardContent>
			</Card>
		);
	}
}

class SegmentList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			siteFilter: props.byWhitelistedSites || null,
			calcFilter: {
				requires: [], contains: [], absent: [], present: [],
			},
			filter: '',
		};
		this.onSegClick = this.onSegClick.bind(this);
		this.onSegApprove = this.onSegApprove.bind(this);
		this.countries = new CountryList();
	}

	onSegClick(e) {
		e.preventDefault();
		this.props.history.push(e.currentTarget.pathname);
	}

	onSegApprove(e) {
		const { segments } = this.props;
		e.preventDefault();
		const str = e.currentTarget.pathname;
		const id = str.substring(str.lastIndexOf('/') + 1);
		this.setState({ approveSegment: segments.find((s) => s.id === id) });
	}

	getSegmentsToDisplay(segments, selectedMap) {
		const {
			showInactive, filter, siteFilter, calcFilter,
		} = this.state;
		let siteMap;
		if (siteFilter && siteFilter.length) {
			siteMap = _.zipObject(siteFilter, Array(siteFilter.length).fill(true));
		}
		const filterParts = (filter || '').trim().toLowerCase().split(' ');
		const stayAlive = [];
		const shouldKeep = (seg) => {
			if (!showInactive && !seg.isActive) {
				return false;
			}
			if (filter) {
				const nameParts = seg.name.trim().toLowerCase().split(' ');
				if (filterParts.find((part) => !nameParts.find((p) => p.startsWith(part)))) {
					return false;
				}
			}
			if (siteMap) {
				if (seg.revShareMethod !== 'whitelist') {
					return false;
				}
				if (!(seg.revenueSites || []).find((s) => siteMap[s])) {
					return false;
				}
			}
			const { calculated } = seg;
			if (calculated) {
				if (calcFilter.country && calcFilter.country !== calculated.country) {
					return false;
				}
				for (const opt of calcFilter.contains) {
					if (!calculated.contains[opt] || calculated.requires[opt]) {
						return false;
					}
				}
				for (const opt of calcFilter.present) {
					if (!calculated.contains[opt] && !calculated.requires[opt]) {
						return false;
					}
				}
				for (const opt of calcFilter.requires) {
					if (!calculated.requires[opt]) {
						return false;
					}
				}
				for (const opt of calcFilter.absent) {
					if (calculated.contains[opt]) {
						return false;
					}
				}
			}
			return true;
		};

		const res = segments.filter((seg) => {
			if (!shouldKeep(seg)) {
				if (selectedMap[seg.dmpId]) {
					stayAlive.push(seg);
				}
				return false;
			}
			return true;
		});
		return { toDisplay: res, stayAlive };
	}

	renderExtraFilters() {
		const { calcFilter, extraExpanded } = this.state;
		const changeCountry = (code) => this.setState({
			calcFilter: { ...calcFilter, country: GUI_COUNTRY_CODES[code] ? code : null },
		});

		const changeBoolOpt = (name, value) => {
			const newCalc = _.cloneDeep(calcFilter);
			['absent', 'present', 'contains', 'requires'].forEach((opt) => _.pull(newCalc[opt], name));
			const target = newCalc[value];
			if (target) {
				target.push(name);
			}
			this.setState({ calcFilter: newCalc });
		};

		const BoolOpt = (props) => (
			<Grid item xs>
				<Card>
					<CardContent>
						<Typography variant="h2">
							{props.title}
						</Typography>
						<RadioButtonGroup
							items={[
								{ value: 'none', name: 'Disabled' },
								{ value: 'absent', name: 'Absent' },
								{ value: 'present', name: 'Present' },
								{ value: 'contains', name: 'Optional' },
								{ value: 'requires', name: 'Requires' },
							]}
							name={props.name}
							selected={['absent', 'present', 'contains', 'requires'].find((opt) => _.includes(calcFilter[opt], props.name)) || 'none'}
							onChange={(ev) => changeBoolOpt(props.name, ev.target.value)}
						/>
					</CardContent>
				</Card>
			</Grid>
		);
		return (
			<ExpandSelector
				title="More filters"
				expanded={extraExpanded}
				onExpandChange={(exp) => this.setState({ extraExpanded: exp })}
			>
				<Grid container spacing={3}>
					<Grid item xs>
						<Card>
							<CardContent>
								<Typography variant="h2">
									Required country
								</Typography>
								<RadioButtonGroup
									items={[['none', 'Disabled']].concat(Object.entries(GUI_COUNTRY_CODES)).map(([value, name]) => ({ value, name }))}
									name="country"
									selected={calcFilter.country || 'none'}
									onChange={(ev) => changeCountry(ev.target.value)}
								/>
							</CardContent>
						</Card>
					</Grid>
					<BoolOpt name="isRdeCategories" title="Cx Categories" />
					<BoolOpt name="isEnreachInterest" title="Enreach interests" />
					<BoolOpt name="isEnreach1stParty" title="Enreach 1st party" />
					<BoolOpt name="isCountry" title="Country" />
				</Grid>

			</ExpandSelector>
		);
	}

	render() {
		const {
			segments, history, selectable, singleSelect, selected, noContainer, onChange, fields, deselectOnClickaway, extraFilters, title, theme,
		} = this.props;
		const { approveSegment, siteFilter, calcFilter } = this.state;
		const selectedMap = _.zipObject(selected, Array(selected.length).fill(true));
		const { toDisplay, stayAlive } = this.getSegmentsToDisplay(segments, selectedMap);
		const allVisibleSelected = !!(toDisplay.length && !toDisplay.find((seg) => !selectedMap[seg.dmpId]));

		/** The following is about forcing a full refresh of the element because bogus behaviour in table selection
		 *  when everything filtered is first selected but filtering changes so not everything is selected :( */
		const tableBugHack = {};
		const filterState = _.pick(this.state, 'filter', 'showInactive', 'siteFilter', 'calcFilter');
		if (!this.allVisibleSelected !== !allVisibleSelected && !_.isEqual(filterState, this.lastFilterState)) {
			tableBugHack.key = new Date().getTime();
		}
		this.allVisibleSelected = allVisibleSelected;
		this.lastFilterState = filterState;

		const onRowSelection = (items) => {
			let indexes = items;
			if (items === 'all') {
				indexes = _.range(toDisplay.length);
			} else if (items === 'none') {
				indexes = [];
			}
			let selectedSegments = indexes.map((idx) => toDisplay[idx]);
			if (stayAlive) {
				selectedSegments = selectedSegments.concat(stayAlive);
			}
			onChange(selectedSegments);
		};

		const approvalText = (seg) => {
			if (!seg.isApproved) {
				return 'NO';
			} if (seg.revShareMethod !== 'auto') {
				const what = seg.revShareMethod === 'whitelist' ? 'Whitelisted' : 'Blacklisted';
				return `${what} (${(seg.revenueSites || []).length} sites)`;
			}
			return 'Auto';
		};

		const fieldDef = (colTitle, name) => ({
			key: 'calculated',
			title: colTitle,
			style: { width: '60px' },
			align: 'right',
			fieldDefName: name,
			format: (calculated) => {
				if (calculated.requires[name]) {
					return <span style={{ color: theme.palette.success.main }}><CheckedIcon /></span>;
				} if (calculated.contains[name]) {
					return <span style={{ color: theme.palette.success.light }}><PlusIcon /></span>;
				} if (_.isEmpty(calculated.contains)) {
					return <span style={{ color: theme.palette.error.main }}><ClearIcon /></span>;
				}
				return undefined;
			},
		});

		/* const Container = noContainer ? NoWrapper : (p => (
			<Card style={{ maxWidth: '1300px' }}>
				<CardHeader title={title || 'Segments'} />
				<CardText>
					<MultiRevenueImport segments={segments} />
					{p.children}
				</CardText>
			</Card>
		)); */
		return (
			<Container {...tableBugHack} noContainer={noContainer} title={title} segments={segments}>
				<OperationWrapper
					fn={(op) => { this.op = op; }}
				>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Checkbox
								label="Show inactive segments"
								name="showInactive"
								value={this.state.showInactive}
								onChange={(ev) => this.setState({ showInactive: ev.target.value })}
								fullWidth
							/>
						</Grid>
						{ siteFilter
						&& (
							<Grid item xs={12}>
								<SiteSelect
									title="Filter by whitelisted in sites"
									isSites
									selected={siteFilter}
									onChange={(items) => this.setState({ siteFilter: items })}
									selectorType="PopupSelector"
								/>
							</Grid>
						)}
						{ extraFilters && <Grid item xs={12}>{this.renderExtraFilters()}</Grid> }
						<Grid item xs={12}>
							<TextField
								label="Filter"
								name="filter"
								value={this.state.filter}
								onChange={(ev) => this.setState({ filter: ev.target.value })}
								fullWidth
							/>
						</Grid>
						{approveSegment
						&& (
							<Dialog
								maxWidth="sm"
								fullWidth
								open
								onClose={() => this.setState({ approveSegment: null })}
							>
								<DialogTitle>{approveSegment.name}</DialogTitle>
								<DialogContent dividers>
									<ApproveSettings
										segments={segments}
										segment={approveSegment}
										fnTarget={this}
									/>
								</DialogContent>
								<DialogActions>
									<ActionButton
										label="Ok"
										color="primary"
										onClick={() => this.op.reload(async () => {
											Object.assign(approveSegment, this.getSegmentSettings());
											await approveSegment.update();
											this.setState({ approveSegment: null });
										})}
									/>
								</DialogActions>
							</Dialog>
						)}
						<Grid item xs={12}>
							<DataTable
								showCheckboxes={selectable}
								selectableRows={singleSelect || selectable}
								hoverable={singleSelect}
								onRowSelection={onRowSelection}
								deselectOnClickaway={deselectOnClickaway}
								identifier={(row) => row.id}
								isSelected={(row) => selectedMap[row.dmpId]}
								definitions={[
									{
										key: 'name',
										title: 'Name',
										format: !history ? null : (__, seg) => (<a href={`/settings/segments/${seg.id}`} onClick={this.onSegClick}>{seg.name}</a>),
									},
									{
										key: 'isApproved',
										title: 'Approved',
										align: 'right',
										format: (isApproved, seg) => (
											<a href={`/settings/segments/${seg.id}`} onClick={this.onSegApprove}>
												<span style={{ color: isApproved ? theme.palette.success.main : theme.palette.error.main }}>
													{approvalText(seg)}
												</span>
											</a>
										),
									},
									{
										key: 'isActive',
										title: 'Status',
										align: 'right',
										format: (isActive, row) => (
											<span style={{ color: isActive ? theme.palette.success.main : theme.palette.error.main }}>
												{isActive ? 'Active' : (row.isRemoved ? 'REMOVED' : 'Inactive')}
											</span>
										),
									},
									{
										key: 'calculated',
										fieldDefName: 'country',
										title: 'Country',
										align: 'right',
										format: (c) => (c.country ? this.countries.getName(c.country) || c.country : ''),
									},
									fieldDef('Cx Categories', 'isRdeCategories'),
									fieldDef('Enreach interests', 'isEnreachInterest'),
									fieldDef('Enreach 1st party', 'isEnreach1stParty'),
								].filter((f) => (fields.indexOf(f.key) >= 0) || (f.fieldDefName && fields.indexOf(f.fieldDefName) >= 0))}
								data={toDisplay}
							/>
						</Grid>
					</Grid>
				</OperationWrapper>
			</Container>
		);
	}
}

SegmentList.allFields = [
	'name',
	'isActive',
	'isApproved',
	'country',
	'isRdeCategories',
	'isEnreachInterest',
	'isEnreach1stParty',
	'isCountry',
];

SegmentList.propTypes = {
	segments: PropTypes.array.isRequired,
	history: PropTypes.object,
	selectable: PropTypes.bool,
	singleSelect: PropTypes.bool,
	selected: PropTypes.array,
	onChange: PropTypes.func,
	noContainer: PropTypes.bool,
	fields: PropTypes.array,
	deselectOnClickaway: PropTypes.bool,
	byWhitelistedSites: PropTypes.array,
	extraFilters: PropTypes.bool,
	title: PropTypes.string,
};

SegmentList.defaultProps = {
	history: null,
	selectable: false,
	singleSelect: false,
	selected: [],
	onChange: () => {},
	noContainer: false,
	fields: ['name', 'isActive'],
	deselectOnClickaway: false,
	byWhitelistedSites: undefined,
	extraFilters: false,
	title: undefined,
};

export default withTheme(SegmentList);
