import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '../TextField';

function InfoChannelEdit({ field }) {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h3">
							Email
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									{...field('mailAddresses')}
									fullWidth
									label="Mail address(es)"
									emails
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

InfoChannelEdit.propTypes = {
	field: PropTypes.func.isRequired,
};

InfoChannelEdit.defaultProps = {
};

export default InfoChannelEdit;
