import React from 'react';
import PropTypes from 'prop-types';
import { PopupForm } from '../Wrappers';
import { addUpdate } from './syncFns';
import AdserverPlacementSyncSettings from './adserverPlacementSyncSettings';

function SyncAdserverPlacements({ publisherNode, form }) {
	return (
		<PopupForm
			title="Sync Adserver to SSP Placements"
			size="lg"
			getModel={() => ({
				publisherNode,
				onlyCheckLocal: false,
				useWildcards: false,
				selected: [],
				placCache: {},
				otherPlacementsName: 'Other traffic',
				includePublisher: false,
				includeSite: true,
				onlyOther: false,
				targetSsp: undefined,
			})}
			content={(p) => (
				<AdserverPlacementSyncSettings
					{...p}
					publisherNode={publisherNode}
				/>
			)}
			onApplyChanges={({ model }) => form.update(() => (
				model.selected.forEach(addUpdate)
			))}
		/>
	);
}

SyncAdserverPlacements.propTypes = {
	publisherNode: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
};

export default SyncAdserverPlacements;
