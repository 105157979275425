import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Toolbar,
} from '@mui/material';
import { Link } from '../Link/Link';
import AudienceLogo from '../../assets/relevant-audience-light.png';

export default function SidebarTopArea({ type, logo, logoStyle }) {
	return (
		<Toolbar>
			<Link to="/">
				{ type === 'yield' ? (
					<Box
						component="img"
						sx={{
							display: 'block',
							width: '100%',
							...logoStyle,
						}}
						src={logo}
						alt="Relevant Yield"
					/>

				) : (
					<Box
						component="img"
						sx={{
							display: 'block',
							width: '72%',
							// A bit wonky alignment due to logo proportions
							marginTop: '18px',
							marginBottom: '22px',
							marginLeft: '18px',
						}}
						src={AudienceLogo}
						alt="Relevant Audience"
					/>
				)}
			</Link>
		</Toolbar>
	);
}

SidebarTopArea.propTypes = {
	logo: PropTypes.string,
	type: PropTypes.oneOf(['yield', 'audience']).isRequired,
	logoStyle: PropTypes.shape({}),
};
SidebarTopArea.defaultProps = {
	logo: '',
	logoStyle: {},
};
