/* eslint-disable
	no-useless-escape,
	no-await-in-loop,
*/
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MinusIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';

import Constants from 'relevant-shared/reportData/constants';
import AllMappingDimension from 'relevant-shared/mappingDimensions/allMappingDimension';
import DeleteButton from '../DeleteButton';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import DataTable from '../DataTable';
import DateUtils from '../../lib/dateUtils';
import OperationWrapper from '../OperationWrapper';
import { Reports } from '../../api/relevant';
import SystemData from '../../lib/systemData';
import JobButton from '../JobButton';
import Base from '../../layouts/Base';
import classes from '../../api/classes';
import { ConfirmDialog } from '../ConfirmDialog';
import { Dialog } from '../Dialog';

const CANDIDATE_CHUNK = 5;
const MAX_CHECK_FIRST_WORD_CHARS = 4;
const MAX_ONLY_CHECK_FIRST_WORD_CHARS = 2;
const MAX_ADVERTISERS = 20000;
const HISTORY_DAYS_BACK = 60;
const MAX_ADV_SETUP_CHUNK_MS = 20;
const SPLIT_REGEXP = /[\s\,\.\-\[\]\(\)\_]/;
const FORBIDDEN_WORDS = Object.getOwnPropertyNames(Object.getPrototypeOf({}));
const FORBIDDEN_WORD_MAP = _.zipObject(FORBIDDEN_WORDS, Array(FORBIDDEN_WORDS.length).fill(true));

const splitStr = (str) => str
	.toLowerCase()
	.split(SPLIT_REGEXP)
	.filter((w) => w.length && !FORBIDDEN_WORD_MAP[w]);

// TODO: This component should be redesigned and renamed. Currently works with
//  "Advertiser" and "Buyer", but variable names still follow the "Advertiser"
//  naming convention
class DynamicSearchFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchStr: '',
			candidates: [],
			chunkSz: CANDIDATE_CHUNK,
			dimensionData: {
				byNr: {},
				byFirstWord: {},
				byAnyWord: {},
				byAnyWordFull: {},
			},
		};
		this.searchContainerElm = null;
		this.initAdvDataPromise = new Promise((initResolve, initReject) => {
			Object.assign(this, { initResolve, initReject });
		});
	}

	componentDidMount() {
		this.globalListener = (event) => {
			if (!this.state.showSearchResult) {
				return;
			}
			if (!this.searchContainerElm) {
				return;
			}
			if (event.type === 'keyup') {
				if (event.keyCode !== 27) {
					return;
				}
			} else {
				let element = event.target;
				while (element) {
					if (element === this.searchContainerElm) return;
					element = element.parentNode;
				}
			}
			event.stopPropagation();
			event.preventDefault();
			this.updateState({ showSearchResult: false });
		};
		addEventListener('mousedown', this.globalListener, true);
		addEventListener('keyup', this.globalListener, true);
	}

	componentWillUnmount() {
		if (this.globalListener) {
			window.removeEventListener('click', this.globalListener, true);
			window.removeEventListener('keydown', this.globalListener, true);
		}
	}

	getCommonTableProps() {
		return {
			showCheckboxes: false,
			selectableRows: false,
			cellDefaultStyle: { paddingLeft: '3px', paddingRight: '3px' },
			identifier: (row) => row[this.props.dimension],
		};
	}

	setSearchString(str) {
		const newCandidates = this.findByString(str);
		this.updateState({
			searchStr: str,
			candidates: newCandidates,
			candidatesTotal: _.sumBy(newCandidates, 'metric'),
			chunkSz: CANDIDATE_CHUNK,
			showSearchResult: true,
		});
	}

	updateState(newState) {
		const { onSearchStringChanged } = this.props;
		if (onSearchStringChanged && ('searchStr' in newState)) {
			onSearchStringChanged(newState.searchStr);
		}
		this.setState(newState);
	}

	async initAdvData(reportPromise, preSelectedAdvs) {
		let hasResolved;
		let report;
		const resolveIfNeeded = () => {
			if (!hasResolved) {
				this.initResolve();
				hasResolved = true;
			}
		};
		const { metric, objectIdFld, excludeIdCb } = this.props;
		const dimensionData = {
			...this.state.dimensionData,
			byNr: _.mapValues(_.keyBy(preSelectedAdvs, objectIdFld), (adv) => ({
				[this.props.dimension]: adv[objectIdFld].toString(),
				name: adv.name,
				externalId: adv.externalId,
				metric: 0,
			})),
		};
		this.updateState({ dimensionData });
		try {
			report = await reportPromise;
		} catch (e) {
			this.initReject(e);
		}
		const advArr = _.map(report.data, (obj, advNr) => ({
			name: report.labels[this.props.label][advNr],
			metric: obj[metric],
			[this.props.dimension]: advNr,
			externalId: this.props.includeExternalId
				? (report.attributes[this.props.label][advNr] || {}).externalId
				: null,
		}));
		advArr.sort((a1, a2) => a2.metric - a1.metric);
		let start = new Date();
		const addTo = (dst, key, adv) => {
			const arrDst = dst[key];
			if (arrDst) {
				arrDst.push(adv);
			} else {
				dst[key] = [adv];
			}
		};
		const SMALL = SystemData.genericData.ADVERTISERS.ADVERTISER_TOO_SMALL_FOR_REPORT.toString();
		for (const adv of advArr) {
			if (excludeIdCb) {
				if (excludeIdCb(adv[this.props.dimension])) {
					continue;
				}
			} else if (adv[this.props.dimension] === SMALL) {
				continue;
			}
			dimensionData.byNr[adv[this.props.dimension]] = adv;
			adv.wordParts = {};
			splitStr(adv.name).forEach((word, wordIdx) => {
				for (let i = 1; i <= word.length; i++) {
					const wordPart = word.substr(0, i);
					if (!adv.wordParts[wordPart]) {
						adv.wordParts[wordPart] = true;
						addTo(dimensionData.byAnyWord, wordPart, adv);
						if (!wordIdx) {
							if (adv.name === "IKEA" && wordPart === 'ikea') {
								console.info(wordPart);
							}
							addTo(dimensionData.byFirstWord, wordPart, adv);
						}
					}
					adv.wordParts[wordPart] = true;
				}
				addTo(dimensionData.byAnyWordFull, word, adv);
			});
			if (new Date() - start > MAX_ADV_SETUP_CHUNK_MS) {
				resolveIfNeeded();
				await new Promise((r) => setTimeout(r));
				start = new Date();
			}
		}
		resolveIfNeeded();
		this.setSearchString(this.state.searchStr || '');
		console.info('Advertiser data loaded');
	}

	async createNew(searchStr) {
		const {
			createUsingDimObject: dim, checkForbidden, objectIdFld, dimension, selected, noObjectCreate,
		} = this.props;
		const { dimensionData } = this.state;
		const cls = classes[dim.clsName];
		let [adv] = await cls.getObjects({ objNames: [searchStr] });
		if (!adv) {
			if (noObjectCreate) {
				Base.renderGlobal((done) => (
					<Dialog
						open
						status="error"
						text={`No ${dim.name} named ${searchStr} exists in the system`}
						onClose={done}
					/>
				));
				return null;
			}
			const ok = await Base.renderGlobal((closeFn) => (
				<ConfirmDialog
					open
					text={(
						<span>
							{/* eslint-disable-next-line react/jsx-one-expression-per-line */}
							No {dim.name} named <b>{searchStr}</b>&nbsp;
							exists in the system, do you want to create it?
						</span>
					)}
					onAny={closeFn}
				/>
			));
			if (!ok) {
				return null;
			}
			[adv] = await cls.updateObjects({ objects: [{ name: searchStr }], returnObjects: true });
		}
		const advNr = adv[objectIdFld].toString();
		if (selected.includes(advNr)) {
			return null;
		}
		const err = checkForbidden ? checkForbidden(advNr) : null;
		if (err) {
			Base.renderGlobal((done) => (
				<Dialog
					open
					status="error"
					text={_.isString(err) ? err : 'Not allowed'}
					onClose={done}
				/>
			));
			return null;
		}
		dimensionData.byNr[advNr] = {
			[dimension]: advNr,
			name: adv.name,
			externalId: adv.externalId,
			metric: 0,
		};
		return advNr;
	}

	async load() {
		const {
			type, selected, advMappingId, noMapping, metric, groupBy, includeExternalId, customizer, overrideHistoryDaysBack,
		} = this.props;
		const mappingProp = (_.find(AllMappingDimension, { dimension: groupBy }) || {}).reportProp || 'advMappingId';
		const params = {
			groupBy: [groupBy],
			sums: [metric],
			maxAdvertisers: MAX_ADVERTISERS,
			start: DateUtils.fullDay(new Date(), -(overrideHistoryDaysBack || HISTORY_DAYS_BACK)),
			end: DateUtils.yesterday(),
			[mappingProp]: noMapping ? SystemData.genericData.ADVERTISERS.DUMMY_NO_MAPPING : advMappingId,
			attributes: includeExternalId ? { [this.props.label]: { externalId: true } } : null,
		};
		if (!params.attributes) { // Don't circumvent report-cache just because an advertiers has been added/updated
			params.noSaveGenerationCacheClear = true;
		}
		const reportPromise = Reports.call(Constants[type].API_FN, customizer ? customizer.transformedReportParams(params) : params);
		const preSelectedAdvs = (selected.length)
			? await this.props.serverCall(selected)
			: [];
		this.initAdvData(reportPromise, preSelectedAdvs);
	}

	findByString(str) {
		const sorted = (advArr) => _.sortBy(advArr, (adv) => -adv.metric);
		const { dimensionData } = this.state;
		const words = splitStr(str);
		if (!words.length) {
			return [];
		}
		const endsWithSpace = !!str[str.length - 1].match(SPLIT_REGEXP);

		/**
		 * When we're starting writing a word - during the first few characters (MAX_CHECK_FIRST_WORD_CHARS)
		 * only check 'byFirstWord' primarily. If we don't find anything and search string is VERY short
		 * (MAX_ONLY_CHECK_FIRST_WORD_CHARS) => give up and return nothing.
		 * */
		if (!endsWithSpace && words.length === 1 && words[0].length <= MAX_CHECK_FIRST_WORD_CHARS) {
			const res = dimensionData.byFirstWord[words[0]] || [];
			if (res.length || words[0].length <= MAX_ONLY_CHECK_FIRST_WORD_CHARS) {
				return sorted(res);
			}
		}
		let candidateSets = words.map((word, idx) => {
			if (idx === words.length - 1 && !endsWithSpace) {
				return dimensionData.byAnyWord[word] || []; // only last word without space after we'll match partially
			}
			return dimensionData.byAnyWordFull[word] || []; // other words must be 'full'
		});
		if (candidateSets.find((c) => !c.length)) {
			return [];
		}
		candidateSets = _.sortBy(candidateSets, 'length'); // start with smallest matching set for better performance
		let survivors;
		candidateSets.forEach((arr, idx) => { // For an advertiser to "survive" it must match all words
			const stillSurvives = {};
			arr.forEach((adv) => {
				if (!idx || survivors[adv[this.props.dimension]]) {
					stillSurvives[adv[this.props.dimension]] = adv;
				}
			});
			if (_.isEmpty(stillSurvives)) {
				survivors = {};
				return; // no match
			}
			survivors = stillSurvives;
		});
		return sorted(_.values(survivors));
	}

	render() {
		const {
			selected, onChange, onlySingleSelect, onSingleSelect, hideEmptySelected, extraTableDefs, textFieldLabel,
			includeExternalId, fullWidth, margin, checkForbidden, createUsingDimObject,
		} = this.props;
		const {
			candidates, candidatesTotal, showSearchResult, searchStr, chunkSz, dimensionData,
		} = this.state;
		const selectedMap = _.zipObject(selected, Array(selected.length).fill(true));

		const numsToAdvs = (arr) => arr.reduce((accumulator, nr) => (
			dimensionData.byNr[nr]
				? [...accumulator, dimensionData.byNr[nr]]
				: accumulator
		), []);

		const closeSearch = () => this.updateState({ showSearchResult: false, searchStr: '' });

		const notify = (arr) => {
			const options = { closeSearch };
			const advArr = numsToAdvs(arr);
			onChange(arr, advArr, options);
			onSingleSelect(arr[0], advArr[0], options);
		};

		const selectedArr = (dimensionData)
			? _.sortBy(numsToAdvs(selected), ({ name }) => name.toLowerCase())
			: [];

		const renderIcon = (advNr) => {
			const forbidden = checkForbidden && checkForbidden(advNr);
			if (forbidden) {
				return (
					<Tooltip title={_.isString(forbidden) ? forbidden : 'Not allowed'}>
						<InfoIcon color="info" />
					</Tooltip>
				);
			}
			if (selectedMap[advNr]) {
				return (
					<IconButton onClick={() => notify(_.without(selected, advNr))} size="large">
						<MinusIcon color="error" />
					</IconButton>
				);
			}
			return (
				<IconButton
					onClick={() => {
						notify(selected.concat(advNr));
					}}
					size="large"
				>
					<Box component="span" color="success.light">
						<AddIcon />
					</Box>
				</IconButton>
			);
		};

		return (
			<OperationWrapper fn={() => this.load()}>
				<div
					onBlur={(ev) => {
						ev.preventDefault();
						this.searchResClicked = false;
						setTimeout(() => {
							if (!this.searchResClicked) {
								this.updateState({ showSearchResult: false });
							}
						});
					}}
					onFocus={() => this.updateState({ showSearchResult: true })}
					ref={(elm) => {
						if (elm) {
							const input = elm.getElementsByTagName('INPUT')[0];
							if (input) {
								input.setAttribute('autocomplete', 'off');
							}
						}
					}}
				>
					<TextField
						label={textFieldLabel}
						name="searchAdvertiser"
						value={searchStr}
						onChange={(ev) => this.setSearchString(ev.target.value)}
						fullWidth={fullWidth}
						margin={margin}
						{...(createUsingDimObject && searchStr && {
							endAdornment: (
								<JobButton
									label="Use name"
									sx={{ width: 100 }}
									fn={async () => {
										const advNr = await this.createNew(searchStr);
										if (advNr) {
											notify(selected.concat(advNr));
											closeSearch();
										}
									}}
								/>
							),
						})}
					/>
					{showSearchResult && !!(searchStr || '').trim().length
					&& (
						<OperationWrapper
							fn={async () => {
								await this.initAdvDataPromise;
								this.setSearchString(searchStr);
							}}
						>
							<div style={{ position: 'relative' }}>
								<div
									role="none"
									style={{ position: 'absolute', zIndex: 2 }}
									ref={(elm) => { this.searchContainerElm = elm; }}
									onMouseDown={() => {
										setTimeout(() => {
											this.searchResClicked = true;
										});
									}}
								>
									<Card style={{ marginTop: -10, marginBottom: 0 }}>
										<CardContent>
											<DataTable
												{...this.getCommonTableProps()}
												noHeader
												definitions={[
													{
														key: this.props.dimension,
														title: 'Add',
														style: { width: '40px' },
														padding: 'none',
														format: renderIcon,
													},
													{
														key: 'name',
														title: 'Name',
													},
													{
														key: 'metric',
														title: 'Daily revenue',
														format: (rev) => `${((rev / candidatesTotal) * 100).toFixed(2)}%`,
													},
												].concat(includeExternalId ? [
													{
														key: 'advNr',
														title: 'External Id',
														format: (advNr, adv) => ((adv.externalId)
															? (<span style={{ fontWeight: 'bold' }}>{adv.externalId}</span>)
															: (<Box component="em" color="grey.600">(No external id)</Box>)),
													},
												] : [], extraTableDefs)}
												data={_.take(candidates, chunkSz)}
											/>
											{candidates.length > chunkSz
										&& (
											<Button
												onClick={() => {
													this.updateState({ chunkSz: chunkSz + CANDIDATE_CHUNK });
												}}
												fullWidth
											>
												{`Show ${candidates.length - chunkSz} more ${this.props.showMoreLabel}`}
											</Button>
										)}
										</CardContent>
									</Card>
								</div>
							</div>
						</OperationWrapper>
					)}
				</div>
				{!onlySingleSelect && !(!selectedArr.length && hideEmptySelected) && (
					<DataTable
						{...this.getCommonTableProps()}
						definitions={[
							{
								key: this.props.dimension,
								title: '',
								style: { width: 150 },
								headerElm: selectedArr.length && (
									<ActionButton
										label="Clear all"
										color="primary"
										onClick={() => notify([])}
										icon={<DeleteIcon />}
									/>
								),
								format: (advNr) => (
									<DeleteButton
										onClick={() => notify(_.without(selected, advNr))}
									/>
								),
							},
							{
								key: 'name',
								title: '',
							},
						]}
						data={selectedArr}
					/>
				)}
			</OperationWrapper>
		);
	}
}

DynamicSearchFilter.propTypes = {
	selected: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func,
	groupBy: PropTypes.string,
	onSingleSelect: PropTypes.func,
	type: PropTypes.string.isRequired,
	metric: PropTypes.string,
	advMappingId: PropTypes.string,
	onlySingleSelect: PropTypes.bool,
	hideEmptySelected: PropTypes.bool,
	noMapping: PropTypes.bool,
	textFieldLabel: PropTypes.string,
	includeExternalId: PropTypes.bool,
	dimension: PropTypes.string.isRequired,
	serverCall: PropTypes.func.isRequired,
	label: PropTypes.string,
	extraTableDefs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
	fullWidth: PropTypes.bool,
	margin: PropTypes.oneOf(['none', 'dense', 'normal']),
	showMoreLabel: PropTypes.string,
	customizer: PropTypes.object,
	objectIdFld: PropTypes.string,
	excludeIdCb: PropTypes.func,
	overrideHistoryDaysBack: PropTypes.number,
	checkForbidden: PropTypes.func,
	onSearchStringChanged: PropTypes.func,
	createUsingDimObject: PropTypes.object,
	noObjectCreate: PropTypes.bool,
};

DynamicSearchFilter.defaultProps = {
	selected: [],
	groupBy: 'advertiserNr',
	metric: 'revenue',
	advMappingId: null,
	onlySingleSelect: false,
	onChange: () => {},
	onSingleSelect: () => {},
	serverCall: () => {},
	hideEmptySelected: false,
	noMapping: false,
	dimension: 'advNr',
	label: 'advertiserNr',
	extraTableDefs: [],
	textFieldLabel: 'Add Advertiser(s)',
	includeExternalId: false,
	fullWidth: false,
	margin: undefined,
	showMoreLabel: 'advertisers',
	customizer: undefined,
	objectIdFld: 'seq',
	excludeIdCb: undefined,
	overrideHistoryDaysBack: 0, // 0 => Use default
	checkForbidden: undefined,
	onSearchStringChanged: undefined,
	createUsingDimObject: undefined,
	noObjectCreate: false,
};

export default DynamicSearchFilter;
export { HISTORY_DAYS_BACK };
