import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import { withRouter } from 'react-router-dom';
import styles from './styles.css';
import UserCollection from '../../containers/UserCollection';
import { ActionButton } from '../../components/ActionButton/ActionButton';

class UserList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { pathname } = this.props.location;
		return (
			<div className={styles.pageContentWrapper}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<UserCollection />
					</Grid>
					<Grid item>
						<ActionButton
							color="primary"
							label="New user"
							icon={<AddIcon />}
							href={`${pathname}/add`}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

UserList.propTypes = {
	location: PropTypes.object.isRequired,
};

UserList.defaultProps = {

};

export default withRouter(UserList);
