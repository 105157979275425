import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import styles from './styles.css';
import Dashboard from '../../containers/Dashboard';

function AudiencePublisherDashboard() {
	return (
		<div className={styles.pageContentWrapper}>
			<Container maxWidth="xl">
				<Grid container spacing={3}>
					<Dashboard type="audience" />
				</Grid>
			</Container>
		</div>
	);
}

AudiencePublisherDashboard.propTypes = {
};

AudiencePublisherDashboard.defaultProps = {
};

export default AudiencePublisherDashboard;
