import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
	IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './Carousel.css';

export function Carousel({ children, scrollStep, fadeDuration, lingerDuration, scrollBarVisible }) {
	const scrollArea = useRef(null);
	const [scroll, setScroll] = useState(0);
	const [scrollMax, setScrollMax] = useState(0);
	const lastScroll = useRef(0);

	// Subscribe to manual scroll events
	useEffect(() => {
		setScroll(scrollArea.current.scrollLeft);
		setScrollMax(scrollArea.current.scrollWidth - scrollArea.current.clientWidth);

		const handleScroll = () => {
			const newScroll = scrollArea.current.scrollLeft;
			const lastScrollVal = lastScroll.current;
			lastScroll.current = newScroll;
			setScroll((val) => {
				// Only update scroll state if scrolling away from it, so we can differentiate between user scrolling and programmatic smooth
				// scrolling and hide the arrows immediately, before the smooth scrolling is complete!
				if ((newScroll > lastScrollVal && val < newScroll) || (newScroll < lastScrollVal && val > newScroll)) {
					return newScroll;
				}
				return val;
			});
		};
		scrollArea.current.addEventListener('scroll', handleScroll);

		const resize = new ResizeObserver(() => {
			setScroll(scrollArea.current.scrollLeft);
			setScrollMax(scrollArea.current.scrollWidth - scrollArea.current.clientWidth);
		});
		resize.observe(scrollArea.current);

		const mutation = new MutationObserver(() => {
			setScroll(scrollArea.current.scrollLeft);
			setScrollMax(scrollArea.current.scrollWidth - scrollArea.current.clientWidth);
		});
		mutation.observe(scrollArea.current, { childList: true, subtree: true });

		const { current } = scrollArea;
		return () => {
			current.removeEventListener('scroll', handleScroll);
			resize.disconnect();
			mutation.disconnect();
		};
	}, [scrollArea]);

	const handleScrollLeft = useCallback(() => {
		const step = scrollStep || scrollArea.current.clientWidth * 0.8;
		scrollArea.current.scrollTo({ left: scrollArea.current.scrollLeft - step, behavior: 'smooth' });
		setScroll(scrollArea.current.scrollLeft - step);
	}, [scrollArea, scrollStep]);

	const handleScrollRight = useCallback(() => {
		const step = scrollStep || scrollArea.current.clientWidth * 0.8;
		scrollArea.current.scrollTo({ left: scrollArea.current.scrollLeft + step, behavior: 'smooth' });
		setScroll(scrollArea.current.scrollLeft + step);
	}, [scrollArea, scrollStep]);

	return (
		<div style={{ width: '100%', position: 'relative' }}>
			<Arrow side="left" scroll={scroll} scrollMax={scrollMax} fadeDuration={fadeDuration} lingerDuration={lingerDuration}>
				<IconButton onClick={handleScrollLeft}>
					<ChevronLeft sx={{ fontSize: 50 }} />
				</IconButton>
			</Arrow>
			<Arrow side="right" scroll={scroll} scrollMax={scrollMax} fadeDuration={fadeDuration} lingerDuration={lingerDuration}>
				<IconButton onClick={handleScrollRight}>
					<ChevronRight sx={{ fontSize: 50 }} />
				</IconButton>
			</Arrow>
			<div ref={scrollArea} className={!scrollBarVisible && styles.HideScrollbars} style={{ position: 'relative', overflowX: 'scroll' }}>
				<div style={{ display: 'block' }}>
					{children}
				</div>
			</div>
		</div>
	);
}

Carousel.propTypes = {
	children: PropTypes.node.isRequired,
	// How far to scroll when clicking the left/right arrows (default is one "page")
	scrollStep: PropTypes.number,
	// Fade duration for the left/right arrows
	fadeDuration: PropTypes.number,
	// How long the clickable area of the left/right arrows should linger after
	// they fade out, to prevent repeated clicks from accidentally clicking the
	// element underneath
	lingerDuration: PropTypes.number,
	scrollBarVisible: PropTypes.bool,
};

Carousel.defaultProps = {
	scrollStep: undefined,
	fadeDuration: 0.5,
	lingerDuration: 0.5,
	scrollBarVisible: false,
};

function Arrow({ side, scroll, scrollMax, fadeDuration, lingerDuration, children }) {
	const showCondition = (side === 'left') ? (scroll > 0) : (Math.ceil(scroll) < scrollMax);
	return (
		<div
			style={{
				visibility: showCondition ? 'visible' : 'hidden',
				opacity: showCondition ? 1 : 0,
				zIndex: '1',
				position: 'absolute',
				left: side === 'left' && 0,
				right: side === 'right'	&& 0,
				width: 100,
				height: '100%',
				background: `linear-gradient(to ${side}, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)`,
				transition: showCondition ? `visibility 0s 0s, opacity ${fadeDuration}s linear` : `visibility 0s ${fadeDuration + lingerDuration}s, opacity ${fadeDuration}s linear`,
			}}
		>
			<div style={{ display: 'flex', justifyContent: (side === 'left') ? 'flex-start' : 'flex-end', alignItems: 'center', height: '100%' }}>
				{children}
			</div>
		</div>
	);
}

Arrow.propTypes = {
	side: PropTypes.oneOf(['left', 'right']).isRequired,
	scroll: PropTypes.number.isRequired,
	scrollMax: PropTypes.number.isRequired,
	fadeDuration: PropTypes.number.isRequired,
	lingerDuration: PropTypes.number.isRequired,
	children: PropTypes.node,
};

Arrow.defaultProps = {
	children: undefined,
};
