import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import storeContainer from '../storeContainer';
import Spinner from '../../components/Spinner';
import UserForm from '../../components/UserForm';
import withIdentity from '../withIdentity';
import Base from '../../layouts/Base';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { Dialog } from '../../components/Dialog';
// import styles from './styles.css';

@withIdentity()
@storeContainer()
class UserEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isSubmitting: false,
			error: null,
		};
	}

	async componentDidMount() {
		const { userId } = this.props;

		// Set publisher for both new and existing users
		await this.setPublisher();

		if (userId === 'add') {
			this.setEmpty();
		} else {
			this.setModel();
		}
	}

	componentWillUnmount() {
		this.setState({ isSubmitting: false });
	}

	handleSubmitError(e) {
		let error = e;
		if (e.error && e.error.message) {
			error = e.error.message;
		} else if (e.error && _.isString(e.error)) {
			error = e.error;
		} else if (e.error && e.error.errors && _.isString(e.error.errors)) {
			error = e.error.errors;
		} else {
			error = 'An error has occured.';
		}
		this.setState({ error });
	}

	async setPublisher() {
		const { publisher } = this.state;
		if (publisher) {
			return publisher;
		}
		const { publishers, publisherId } = this.props;

		if (!publishers.isReady()) {
			await publishers.loadAll();
		}
		const pub = publishers.byId(publisherId || this.props.user().profile.publisherId);
		this.setState({ publisher: pub });
		return pub;
	}

	async setEmpty() {
		this.setState({
			user: {
				email: '',
				firstname: '',
				lastname: '',
				password: '',
				passwordRepeat: '',
				permissions: {
					canEditUsers: true,
				},
			},
		});
	}

	async setModel() {
		const { userId, users } = this.props;
		if (!users.isReady()) {
			try {
				await users.loadAll();
			} catch (e) {
				this.handleSubmitError(e);
			}
		}
		this.setState({ user: users.byId(userId) });
	}

	goBack() {
		const { history } = this.props;
		history.goBack();
	}

	async submitUser(user, neverConfirm) {
		if (!neverConfirm && user.mailCredentials) {
			Base.renderGlobal((closeFn) => (
				<ConfirmDialog
					open
					text={(
						<span>
							Are you sure you want to mail credentials to&nbsp;
							<b>{user.email}</b>
						</span>
					)}
					onConfirm={() => {
						closeFn();
						this.submitUser(user, true);
					}}
					onCancel={closeFn}
				/>
			));
			return;
		}
		const { users, publisherId, isAdmin } = this.props;
		this.setState({ isSubmitting: true, error: null });
		try {
			if (!user._id) {
				const pubId = isAdmin ? null : publisherId || this.props.user().profile.publisherId;
				const newUserBody = _.merge({}, user, { publisherId: pubId });
				await users.addToPublisher(newUserBody);
				this.goBack();
			} else {
				delete user.fullname;
				await users.edit(user);
				this.goBack();
			}
		} catch (e) {
			this.handleSubmitError(e);
		}
	}

	render() {
		const {
			user, isSubmitting, error, publisher,
		} = this.state;
		const { isAdmin } = this.props;
		if (!user) return <Spinner delay />;

		return (
			<>
				<UserForm
					model={user}
					draft
					isAdmin={isAdmin}
					onSubmit={(values) => this.submitUser(values)}
					publisher={publisher}
				/>
				<Dialog
					open={isSubmitting}
					text={error}
					status="error"
					onClose={() => this.setState({ isSubmitting: false })}
				/>
			</>
		);
	}
}

UserEditor.propTypes = {
	userId: PropTypes.string.isRequired,
	publisherId: PropTypes.string,
	history: PropTypes.object.isRequired,
	user: PropTypes.func,
	isAdmin: PropTypes.bool,
};

UserEditor.defaultProps = {
	publisherId: null,
	isAdmin: false,
};

export default withRouter(UserEditor);
