import React, { useState } from 'react';
import {
	Box,
	Divider,
	Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import CsvTable from './CsvTable';
import CsvIssueTracker from '../CsvIssueTracker';
import { Publisher } from '../../../api/relevant';
import { Button } from '../../../components/Button/Button';
import { ConfirmDialog } from '../../../components/ConfirmDialog';

function CsvImporter({
	data,
	columns,
	dataIssues,
	expectedResult,
	missingSspErrors,
	file,
	onCancel,
	onDone,
	onIgnore,
}) {
	const [isLoading, setIsLoading] = useState(false);
	let hasErrors = false;
	Object.values(dataIssues).flat().forEach((iss) => {
		const issuesForProperty = Object.values(iss).flat();
		issuesForProperty.forEach(({ type }) => {
			if (type === 'error') {
				hasErrors = true;
			}
		});
	});

	const { numToCreate, numToUpdate } = expectedResult;

	return (
		<Box sx={{ width: '100%' }}>
			<CsvIssueTracker
				dataIssues={dataIssues}
				missingSspErrors={missingSspErrors}
				onIgnore={onIgnore}
			/>
			<CsvTable
				rows={data}
				headers={columns}
				dataIssues={dataIssues}
			/>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
				<Button
					className="mr-1"
					variant="text"
					onClick={onCancel}
				>
					Cancel
				</Button>
				{ hasErrors ? (
					<Tooltip title="Please fix all errors before importing">
						<span>
							<Button
								disabled
								variant="contained"
							>
								Import
							</Button>
						</span>
					</Tooltip>
				) : (
					<ConfirmDialog
						open
						title="Expected result"
						text={(
							<div>
								<Box sx={{ display: 'flex', mb: 2 }}>
									<Box sx={{ mr: 8 }}>
										<div>
											New publishers:
											{' '}
											{numToCreate.publishers}
										</div>
										<div>
											New websites:
											{' '}
											{numToCreate.websites}
										</div>
										<div>
											New placements:
											{' '}
											{numToCreate.placements}
										</div>
									</Box>
									<div>
										<div>
											Modified publishers:
											{' '}
											{numToUpdate.publishers}
										</div>
										<div>
											Modified websites:
											{' '}
											{numToUpdate.websites}
										</div>
										<div>
											Modified placements:
											{' '}
											{numToUpdate.placements}
										</div>
									</div>
								</Box>
								<Divider />
								<Box sx={{ mt: 2 }}>Are you sure you want to continue?</Box>
							</div>
						)}
						onConfirm={async () => {
							setIsLoading(true);
							let success = true;
							try {
								await Publisher.call('importPlacements', { csv: file });
							} catch {
								success = false;
							}
							onDone(success);
							setIsLoading(false);
						}}
					>
						<Button
							variant="contained"
							disabled={isLoading}
						>
							Import
						</Button>
					</ConfirmDialog>
				)}
			</Box>
		</Box>
	);
}

CsvImporter.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	columns: PropTypes.objectOf(PropTypes.string).isRequired,
	dataIssues: PropTypes.objectOf(PropTypes.shape({
		type: PropTypes.oneOf(['error', 'warning']),
		message: PropTypes.string,
		error: PropTypes.string,
	})),
	missingSspErrors: PropTypes.objectOf(PropTypes.string),
	file: PropTypes.string.isRequired,
	expectedResult: PropTypes.shape({
		numToCreate: PropTypes.shape({
			publishers: PropTypes.number,
			websites: PropTypes.number,
			placements: PropTypes.number,
		}),
		numToUpdate: PropTypes.shape({
			publishers: PropTypes.number,
			websites: PropTypes.number,
			placements: PropTypes.number,
		}),
	}).isRequired,
	onCancel: PropTypes.func.isRequired,
	onDone: PropTypes.func.isRequired,
	onIgnore: PropTypes.func.isRequired,
};

CsvImporter.defaultProps = {
	dataIssues: {},
	missingSspErrors: {},
};

export default CsvImporter;
