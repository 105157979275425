import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/AddCircle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { GenericTagField } from 'relevant-shared/objects/tagFieldBase';
import DeleteButton from '../DeleteButton';
import ExpandSelector from '../ExpandSelector';
import ReactUtils from '../../lib/reactUtils';
import FieldEditor from './fieldEditor';
import classes from '../../api/classes';
import { ContextWrapper } from '../Wrappers';
import { availSelectOptions, makeValidByObjectType } from './utils';

const { TagField } = classes;

const swap = (arr, i1, i2) => {
	const tmp = arr[i1];
	arr[i1] = arr[i2];
	arr[i2] = tmp;
};

class FieldArray extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			model, field, startExpanded, inArray, title,
		} = this.props;
		const update = (fn) => (() => {
			fn();
			// Fix for issues swapping <TextField value={undefined}> with <TextField value="str">
			// TODO: figure out potental issues with a fix in TextField instead => value={value == null ? '' : value}
			this.updateKey = Math.random();
			this.forceUpdate();
		});
		return (
			<ExpandSelector title={title} {...(startExpanded ? { expanded: true } : {})}>
				<Grid container spacing={3} key={this.updateKey}>
					{ReactUtils.fldMap(model, field, 'fields', (elm, fld, idx, arr) => (
						<Grid key={`fld_${idx}`} item xs={12}>
							<Card>
								<CardContent>
									<DeleteButton
										title="Delete"
										onClick={update(() => arr.splice(idx, 1))}
									/>
									{idx !== 0 && (
										<IconButton onClick={update(() => swap(arr, idx, idx - 1))} size="large">
											<UpIcon />
										</IconButton>
									)}
									{ idx < arr.length - 1 && (
										<IconButton onClick={update(() => swap(arr, idx, idx + 1))} size="large">
											<DownIcon />
										</IconButton>
									)}
									<FieldEditor field={fld} model={elm} inArray={inArray} />
								</CardContent>
							</Card>
						</Grid>
					))}
					<Grid item xs={12}>
						<ContextWrapper
							name="fieldEditor"
							content={({ all } = {}) => (
								<IconButton
									onClick={update(() => model.fields.push(
										makeValidByObjectType(new GenericTagField(new TagField()), all),
									))}
									size="large"
								>
									<AddIcon />
								</IconButton>
							)}
						/>
					</Grid>
				</Grid>
			</ExpandSelector>
		);
	}
}

FieldArray.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	startExpanded: PropTypes.bool,
	inArray: PropTypes.bool,
	title: PropTypes.any,
};

FieldArray.defaultProps = {
	startExpanded: false,
	inArray: false,
	title: 'Fields',
};

export default FieldArray;
