import React from 'react';
import { Box, Tooltip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import TopBarHeader from './TopBarHeader';

function TopBarEventsOptions({ title, onAddClick }) {
	return (
		<Box sx={{
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		}}
		>
			<TopBarHeader
				title={title}
			/>
			<Tooltip title="New event">
				<IconButton
					color="offWhite"
					onClick={onAddClick}
				>
					<AddIcon />
				</IconButton>
			</Tooltip>
		</Box>
	);
}

TopBarEventsOptions.propTypes = {
	title: PropTypes.string.isRequired,
	onAddClick: PropTypes.func.isRequired,
};

export default TopBarEventsOptions;
