import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import SuccessIcon from '@mui/icons-material/Done';
import CircularProgress from '@mui/material/CircularProgress';
import DataTable from '../../DataTable';
import { Link } from '../../Link/Link';
import PopoutButton from '../../PopoutButton';
import PrebidBuildDeleteButton from '../PrebidBuildDeleteButton';
import generateTableData from './utils';

export default function PrebidBuildTable({
	buildConfigs,
	buildStatuses,
	autoIncludedModules,
	onDeleteBuild,
}) {
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		const data = generateTableData(buildConfigs, buildStatuses, autoIncludedModules);
		setTableData(data);
	}, [buildConfigs, buildStatuses, autoIncludedModules]);
	const [hoveringIndex, setHoveringIndex] = useState(false);

	const onRowHoverChange = (hovering, rowIndex) => {
		if (hovering) {
			setHoveringIndex(rowIndex);
		} else {
			setHoveringIndex(undefined);
		}
	};

	return (
		<DataTable
			data={tableData}
			identifier={({ id }) => id}
			selectableRows={false}
			showCheckboxes={false}
			hoverable
			onRowHoverChange={onRowHoverChange}
			definitions={[
				{
					key: 'name',
					title: 'Name',
				},
				{
					key: 'status',
					title: 'Status',
					format: (status) => {
						switch (status) {
							case 'SUCCESS':
								return (
									<Tooltip title="Build succeeded">
										<Box color="success.main">
											<SuccessIcon />
										</Box>
									</Tooltip>
								);
							case 'FAILED':
								return (
									<Tooltip title="Build failed">
										<ErrorIcon color="error" />
									</Tooltip>
								);
							case 'RUNNING':
								return (
									<Tooltip title="Building...">
										<CircularProgress size={24} />
									</Tooltip>
								);
							default:
								return status;
						}
					},
				},
				{
					key: 'currentVersion',
					title: 'Build version',
					whenNull: () => 'N/A',
				},
				{
					key: 'version',
					title: 'Expected version',
				},
				{
					key: 'currentBuildModules',
					title: 'Build modules',
					format: (modules) => (
						<>
							{ modules.length === 0 ? '0 modules' : (
								<PopoutButton
									renderButton={(params) => (
										<Link {...params}>
											{modules.length}
											{' '}
											{modules.length > 1 ? 'modules' : 'module'}
										</Link>
									)}

								>
									<Box p={2}>
										{ modules.map((module) => <div key={module}>{module}</div>)}
									</Box>
								</PopoutButton>
							)}
						</>
					),
				},
				{
					key: 'expectedModules',
					title: 'Expected modules',
					format: (modules) => (
						<>
							{ modules.length === 0 ? '0 modules' : (
								<PopoutButton
									renderButton={(params) => (
										<Link {...params}>
											{modules.length}
											{' '}
											{modules.length > 1 ? 'modules' : 'module'}
										</Link>
									)}
								>
									<Box p={2}>
										{ modules.map((module) => <div key={module}>{module}</div>)}
									</Box>
								</PopoutButton>
							)}
						</>
					),
				},
				{
					key: 'lastUpdatedDate',
					title: 'Last updated',
					format: (date) => (
						moment(date).fromNow()
					),
				},
				{
					key: 'id',
					title: '',
					format: (id, __, index) => (
						<Box visibility={hoveringIndex === index ? 'visible' : 'hidden'}>
							<PrebidBuildDeleteButton onClick={() => onDeleteBuild(id)} useTooltip />
						</Box>
					),
				},
			]}
		/>
	);
}

PrebidBuildTable.propTypes = {
	buildConfigs: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		version: PropTypes.string.isRequired,
		currentVersion: PropTypes.string,
		currentBuildModules: PropTypes.arrayOf(PropTypes.string),
		expectedModules: PropTypes.arrayOf(PropTypes.string),
	})).isRequired,
	buildStatuses: PropTypes.objectOf(PropTypes.shape({
		configName: PropTypes.string.isRequired,
		created: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
	})).isRequired,
	autoIncludedModules: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
	onDeleteBuild: PropTypes.func.isRequired,
};
