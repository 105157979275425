import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function Circle({
	size,
	style,
	children,
}) {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: size,
				height: size,
				borderRadius: '50%',
				...style,
			}}
		>
			{children}
		</Box>
	);
}

Circle.propTypes = {
	size: PropTypes.number,
	style: PropTypes.objectOf(PropTypes.any),
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

Circle.defaultProps = {
	size: 32,
	style: {},
	children: null,
};

export default Circle;
