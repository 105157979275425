import {
	Card,
	Grid,
	CardContent,
	IconButton,
	Tooltip,
	MenuItem,
	MenuList,
	Popper,
	Paper,
	ListSubheader,
	ClickAwayListener,
	Dialog,
} from '@mui/material';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import React, {
	useState, useEffect, useRef, useCallback, Fragment,
} from 'react';
import deployStatuses from 'relevant-shared/misc/deployStatuses';
import { AppInstance } from '../../api/relevant';
import AppInstanceEditor from '../AppInstanceEditor';
import AppInstanceList from '../AppInstanceList';
import { isDeployDone } from '../../lib/globalAdminUtils';
import AppInstanceToolbar from '../AppInstanceToolbar';
import SimpleOperationWrapper from '../SimpleOperationWrapper';
import SystemData from '../../lib/systemData';
import useRequest from '../../hooks/useRequest/useRequest';
import UserList from './UserList';
import styles from './styles.css';
import { ConfirmDialog } from '../ConfirmDialog';

function getFormattedInstancesToDeployNames(instances, ids) {
	return instances && instances.length > 0 ? instances
		.filter(({ id }) => ids.includes(id))
		.map(({ name }) => name)
		.join(', ') : '';
}
/**
 * View of all our instances
 * Note that a "restart" is essentially a deploy but with special branch name "restart"
 * So any mentions of deploy here can also be a restart
 */
function GlobalAdmin() {
	const [appInstances, setAppInstances] = useState();
	const [appInstanceInfo, setAppInstanceInfo] = useState();
	const [instanceBeingEdited, setInstanceBeingEdited] = useState();
	const [selectedInstanceIds, setSelectedInstanceIds] = useState([]);
	const [popperData, setPopperData] = useState({ instanceIds: [] });
	const [availableBranches, setAvailableBranches] = useState();
	const [popperIsOpen, setPopperIsOpen] = useState(false);
	const [deployState, setDeployState] = useState();
	const [instanceUsers, setInstanceUsers] = useState(null);
	const [isUserModalOpen, setIsUserModalOpen] = useState(false);
	const existingInstanceUrls = useRef();
	const popperButtonRef = useRef();
	const [setRequest, loading, error, reset] = useRequest();
	// On initial render, fetch app instances and available branches
	useEffect(() => {
		const requests = [
			AppInstance.list(),
			AppInstance.call('getAvailableBranches'),
			AppInstance.call('getDeployState', { noAccessLogging: true }),
		];
		setRequest({
			requestFunction: () => Promise.all(requests),
			requestCallback: ([appInstancesRes, branchesRes, deployStateRes]) => {
				const sortedInstances = appInstancesRes
					? appInstancesRes.sort((a, b) => (a.name > b.name ? 1 : -1)) : [];
				setAppInstances(sortedInstances);
				setAvailableBranches(branchesRes || []);
				setDeployState(deployStateRes || {});
			},
		});
	}, [setRequest]);

	const getInstanceInfo = useCallback(async () => {
		const promises = [];
		for (const { id } of appInstances) {
			// Resolve all promises as we dont really want to have any error showing up
			const res = new Promise((resolve) => (
				AppInstance.call('getInstanceInfo', { serverInstanceId: id })
					.catch(() => resolve({ serverInstanceId: id }))
					.then((r) => resolve({ serverInstanceId: id, ...r }))
			));
			promises.push(res);
		}
		setRequest({
			requestFunction: () => Promise.all(promises),
			requestCallback: (res) => {
				const instanceInfo = {};
				res.forEach((instance) => {
					if (instance.result) {
						instanceInfo[instance.serverInstanceId] = instance.result;
						instanceInfo[instance.serverInstanceId].isCurrentInstance = instance.result.genericData
							&& instance.result.genericData.systemId === SystemData.genericData.systemId;
					}
				});
				setAppInstanceInfo(instanceInfo);
			},
		});
	}, [appInstances, setRequest]);
	// Get instance info whenever appInstances is changed
	useEffect(() => {
		if (appInstances) {
			getInstanceInfo();
		}
	}, [appInstances, getInstanceInfo]);

	useEffect(() => {
		let interval;
		async function initDeployStatePinging() {
			interval = setInterval(async () => {
				if (!document.hidden) {
					const state = await AppInstance.call('getDeployState');
					// Update instance info if all deploys are done or deploys are partly done
					if (state.deploysAreDone) {
						getInstanceInfo();
					} else if (state.deploysAreDone === false) {
						const partlySuccess = Object.keys(deployState.deploys).some((id) => (
							state.deploys[id].status === deployStatuses.SUCCESS
								&& deployState.deploys[id].status !== deployStatuses.SUCCESS
						));
						if (partlySuccess) {
							getInstanceInfo();
						}
					}
					setDeployState(state);
				}
			}, 10000);
		}
		if (deployState) {
			// Clear deploy status pinging if deploy is done, otherwise start/continue it.
			if (deployState.deploysAreDone) {
				clearInterval(interval);
			} else if (!interval && deployState.deploysAreDone === false) {
				initDeployStatePinging();
			}
		}
		// Cleanup interval on unmount
		return () => clearInterval(interval);
	}, [deployState, getInstanceInfo]);

	const onEditClick = (instanceId) => {
		const instanceToEdit = appInstances.find(({ id }) => instanceId === id);
		existingInstanceUrls.current = appInstances
			.map(({ url }) => url)
			.filter((url) => instanceToEdit.url !== url);
		setInstanceBeingEdited({ ...instanceToEdit });
	};

	const onDeleteClick = (ids) => {
		setRequest({
			requestFunction: () => AppInstance.call('deleteInstances', { ids }),
			requestCallback: ({ instances }) => {
				const sortedInstances = instances ? instances.sort((a, b) => (a.name > b.name ? 1 : -1)) : [];
				setAppInstances(sortedInstances);
				// There might be cases where the user has selected some items but only deletes one of them
				// Therefore we want to do this to keep the old selection.
				setSelectedInstanceIds(selectedInstanceIds.filter((id) => !ids.includes(id)));
			},
		});
	};

	const onAddClick = () => {
		const instance = new SystemData.models.AppInstance();
		existingInstanceUrls.current = appInstances.map(({ url }) => url);
		setInstanceBeingEdited(instance);
	};

	const onViewUsersClick = (instanceIds, targetElement) => {
		// Used to determine what content to display
		targetElement.id = 'viewUsers';
		popperButtonRef.current = targetElement;
		// Filter out unresponding instances
		const filteredIds = instanceIds.filter((id) => (
			appInstanceInfo[id] && Object.keys(appInstanceInfo[id]).length > 0
		));
		setPopperData({ instanceIds: filteredIds });
		setPopperIsOpen(true);
	};

	const fetchUsers = (instanceIds, adminOnly = false) => {
		// Visa både flera lastLogin och flera instanser
		setRequest({
			requestFunction: () => AppInstance.call('getUsers', { instanceIds, adminOnly }),
			requestCallback: (res) => {
				const uniqueUsers = [];
				res.forEach((user) => {
					const existingUserIndex = uniqueUsers.findIndex(({ email }) => email === user.email);
					const lastLogin = user.lastLogin ? moment(user.lastLogin).format('YYYY-MM-DD HH:mm') : 'Unknown';
					const userBase = {
						_id: user._id,
						email: user.email,
						name: user.name,
					};
					// First occurence
					if (existingUserIndex === -1) {
						uniqueUsers.push({ ...userBase, instances: [{ name: user.instance, lastLogin }] });
					} else { // Already added
						const existingUser = uniqueUsers[existingUserIndex];
						existingUser.instances.push({ name: user.instance, lastLogin });
					}
				});
				setInstanceUsers(uniqueUsers);
				setIsUserModalOpen(true);
				setPopperIsOpen(false);
			},
		});
	};

	const onCancelClick = () => {
		setInstanceBeingEdited(undefined);
	};

	const onEditDone = ({ instances }) => {
		const sortedInstances = instances ? instances.sort((a, b) => (a.name > b.name ? 1 : -1)) : [];
		setAppInstances(sortedInstances);
		setInstanceBeingEdited(undefined);
	};

	const onSelectInstance = (selectedRowIndices) => {
		let ids;
		if (selectedRowIndices === 'all') {
			// Filter out the ones that are deploying. We don't want to allow any actions on those.
			ids = appInstances
				.map(({ id }) => id)
				.filter((id) => (
					!deployState || Object.keys(deployState).length === 0
					|| !deployState.deploys
					|| !deployState.deploys[id]
					|| isDeployDone(deployState.deploys[id])
				));
		} else if (selectedRowIndices === 'none') {
			ids = [];
		} else {
			ids = selectedRowIndices.map((index) => appInstances[index].id);
		}
		setSelectedInstanceIds(ids);
	};

	const initUpdate = (ids) => {
		const filteredIds = ids.filter((id) => (
			appInstanceInfo[id] && Object.keys(appInstanceInfo[id]).length > 0
			&& !appInstanceInfo[id].isCurrentInstance
		));
		let deployWarning = '';
		if (ids.length > filteredIds.length) {
			deployWarning = 'Some of the selected instances are not eligible for deploy'
			+ ' (does not respond or is the same as the current instance), and will therefore be ignored.';
		}
		return [filteredIds, deployWarning];
	};

	const deployOrRestart = (instancesToDeploy, branch) => {
		setRequest({
			requestFunction: () => AppInstance.call('deployInstances', { instances: instancesToDeploy, branch }),
			requestCallback: (state) => {
				setDeployState(state);
				setPopperIsOpen(false);
				setSelectedInstanceIds([]);
			},
		});
	};

	const onDeployClick = (ids, targetElement) => {
		targetElement.id = 'deploy';
		popperButtonRef.current = targetElement;
		// Used to determine what content to display
		// Filter out instances that are unresponsive, or the "current" instance.
		// We don't want to try to deploy to those.
		const [filteredIds, deployWarning] = initUpdate(ids);
		setPopperData({ instanceIds: filteredIds, deployWarning });
		setPopperIsOpen(!popperIsOpen);
	};

	const onRestartClick = (ids) => {
		const [filteredIds, deployWarning] = initUpdate(ids);
		const deployInfo = { instanceIds: filteredIds, deployWarning };
		setPopperData(deployInfo);
		const instancesToDeploy = appInstances
			.filter(({ id }) => deployInfo.instanceIds.includes(id))
			.map(({ id, name }) => ({ id, name }));

		deployOrRestart(instancesToDeploy, 'restart');
	};

	const onSelectBranch = async (branch) => {
		const instancesToDeploy = appInstances
			.filter(({ id }) => popperData.instanceIds.includes(id))
			.map(({ id, name }) => ({ id, name }));
		deployOrRestart(instancesToDeploy, branch);
	};

	const onPopperClickAway = () => {
		setPopperData({ instanceIds: [] });
		setPopperIsOpen(false);
	};

	const toolbarContainerClassName = `${selectedInstanceIds.length > 0 ? styles.appInstanceToolbarContainer
		: styles.appInstanceToolbarContainerHidden}`;

	const instanceList = getFormattedInstancesToDeployNames(appInstances, popperData.instanceIds);

	return (
		<Grid
			container
			justifyContent="center"
		>
			<Grid xs={12} item>
				<Card>
					<CardContent>
						<SimpleOperationWrapper
							loading={loading}
							error={error}
							onErrorModalClick={reset}
						>
							<Tooltip title="Add new instance">
								<IconButton onClick={onAddClick} size="large">
									<AddIcon />
								</IconButton>
							</Tooltip>
							{ appInstances && appInstanceInfo && deployState && (
								<>
									<div className={toolbarContainerClassName}>
										<AppInstanceToolbar
											selectedInstanceIds={selectedInstanceIds}
											instanceInfo={appInstanceInfo}
											deployState={deployState}
											onDeleteClick={() => onDeleteClick(selectedInstanceIds)}
											onDeployClick={(targetEl) => onDeployClick(selectedInstanceIds, targetEl)}
											onRestartClick={() => onRestartClick(selectedInstanceIds)}
											onViewUsersClick={(targetEl) => onViewUsersClick(selectedInstanceIds, targetEl)}
										/>
										<Popper
											open={popperIsOpen}
											anchorEl={popperButtonRef.current}
											className={styles.popper}
										>
											<ClickAwayListener onClickAway={onPopperClickAway}>
												<Paper>
													{ popperButtonRef && popperButtonRef.current && popperButtonRef.current.id === 'deploy' ? (
														<MenuList variant="menu">
															<ListSubheader
																disableSticky
															>
																Select branch to deploy
															</ListSubheader>
															<div className={styles.popperMenu}>
																{ availableBranches && availableBranches.map((branch) => (
																	<ConfirmDialog
																		key={branch}
																		onConfirm={() => onSelectBranch(branch)}
																		text={`${popperData.deployWarning} Are you sure you want to deploy the branch ${branch} to ${instanceList}?`}
																	>
																		<MenuItem>{branch}</MenuItem>
																	</ConfirmDialog>
																))}
															</div>
														</MenuList>
													) : (
														<MenuList variant="menu">
															<ListSubheader
																disableSticky
															>
																Select users
															</ListSubheader>
															<div className={styles.popperMenu}>
																<MenuItem onClick={() => fetchUsers(popperData.instanceIds)}>All users</MenuItem>
																<MenuItem onClick={() => fetchUsers(popperData.instanceIds, true)}>Admins only</MenuItem>
															</div>
														</MenuList>
													)}
												</Paper>
											</ClickAwayListener>
										</Popper>
									</div>
									<AppInstanceList
										instances={appInstances}
										instanceInfo={appInstanceInfo}
										deployState={deployState}
										selectedInstanceIds={selectedInstanceIds}
										onSelect={onSelectInstance}
										onEditClick={onEditClick}
										onDeleteClick={onDeleteClick}
										onDeployClick={onDeployClick}
										onRestartClick={onRestartClick}
										onViewUsersClick={onViewUsersClick}
										loading={loading}
									/>
								</>
							)}
							{ instanceBeingEdited
								&& (
									<AppInstanceEditor
										instance={instanceBeingEdited}
										onCancel={onCancelClick}
										onDone={onEditDone}
										existingInstanceUrls={existingInstanceUrls.current}
									/>
								)}

							<Dialog
								open={instanceUsers !== null && isUserModalOpen}
								onClose={() => setIsUserModalOpen(false)}
								scroll="paper"
								maxWidth="md"
							>
								<UserList onClose={() => setIsUserModalOpen(false)} users={instanceUsers} />
							</Dialog>

						</SimpleOperationWrapper>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

export default GlobalAdmin;
