const TypeCustomizer = require('./typeCustomizer');
const DateUtils = require('../../misc/dateUtils');

class SummaryHbCustomizer extends TypeCustomizer {
	getInitSums() {
		return ['revenue'];
	}

	transformedReportParams(params) {
		return {
			...params,
			startTs: new Date(params.start).getTime(),
			endTs: DateUtils.fullDay(params.end, 1).getTime(),
		};
	}

	siteSelectType() {
		return 'programmatic';
	}

	normalizeDate(dateStr) {
		return new Date(parseInt(dateStr, 10));
	}
}

module.exports = SummaryHbCustomizer;
