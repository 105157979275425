import React, {
	useState, useEffect, useRef, useLayoutEffect,
} from 'react';
import { styled } from '@mui/material/styles';
import {
	Tooltip, Box, IconButton, Fab,
} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { PropTypes } from 'prop-types';
import PopoutButton from '../PopoutButton';
import CommentList from './CommentList';
import CommentEditor from './CommentEditor';
import SimpleOperationWrapper from '../SimpleOperationWrapper';
import useComments from '../../hooks/useComments';
import useDelay from '../../hooks/useDelay';

const PREFIX = 'CommentsPopup';

const classes = {
	comment: `${PREFIX}-comment`,
	addButton: `${PREFIX}-addButton`,
	commentListContainer: `${PREFIX}-commentListContainer`,
};

const Root = styled('div')(({
	theme: { spacing },
}) => ({
	[`& ´.${classes.comment}`]: {
		padding: spacing(4),
		minWidth: spacing(48),
	},

	[`& .${classes.addButton}`]: {
		position: 'absolute',
		bottom: spacing(4),
		right: spacing(4),
		zIndex: 100,
	},

	[`& .${classes.commentListContainer}`]: {
		overflowY: 'auto',
	},
}));

export default function CommentsPopup({ connectedTo, context }) {
	const [showEdit, setShowEdit] = useState(false);

	const {
		comments, loading, error, addComment, deleteComment, dismissErrors,
	} = useComments(connectedTo);
	const [popoutOpen, setPopoutOpen] = useState(false);
	const popoverAnchorEl = useRef();
	const popoverActions = useRef();
	// Delay loading icon to avoid wonkiness in the ui. Only display loading if request takes more than 0.2s
	const displayLoading = useDelay(loading, 0.2);

	const handleSubmit = (title, body) => {
		addComment(title, body, connectedTo, context);
		setShowEdit(false);
	};

	// Show add box by default when there are no comments
	useEffect(() => {
		if (comments.length === 0) {
			setShowEdit(true);
		} else {
			setShowEdit(false);
		}
	}, [comments]);

	const onCancel = () => {
		if (comments.length === 0) {
			setPopoutOpen(false);
		} else {
			setShowEdit(false);
		}
	};

	// Reposition popover when adding comments so that it doesn't overflow parent
	const onTransitionsDone = () => {
		if (popoverActions.current) {
			popoverActions.current.updatePosition();
		}
	};

	const hasComments = comments.length > 0;

	const buttonTooltip = hasComments ? `${comments.length} comment${comments.length > 1 ? 's' : ''}` : 'Add comment';
	return (
		<Root>
			<PopoutButton
				anchorEl={popoverAnchorEl.current}
				renderButton={(params) => (
					<Tooltip title={buttonTooltip}>
						<IconButton {...params} ref={popoverAnchorEl} size="large">
							{ hasComments ? <CommentIcon /> : <AddCommentIcon /> }
						</IconButton>
					</Tooltip>
				)}
				open={popoutOpen}
				onOpenStateChange={(state) => {
					if (state !== popoutOpen) {
						setPopoutOpen(state);
					}
				}}
				popoverActions={popoverActions}
			>
				<Root>
					{ (!showEdit && !loading) && (
						<div className={classes.addButton}>
							<Tooltip title="Add comment">
								<span>
									<Fab
										color="primary"
										aria-label="Add comment"
										onClick={() => setShowEdit((prev) => !prev)}
									>
										<AddCommentIcon />
									</Fab>
								</span>
							</Tooltip>
						</div>
					) }
					<SimpleOperationWrapper
						loading={displayLoading}
						error={error}
						containerClass={classes.commentListContainer}
						onErrorModalClick={() => dismissErrors()}
					>
						<Box component="div" p={2} maxHeight={500}>
							{showEdit ? (
								<CommentEditor
									onSubmit={handleSubmit}
									onCancel={onCancel}
								/>
							) : (
								<CommentList
									comments={comments}
									onDeleteComment={(id) => deleteComment(id)}
									onTransitionsDone={onTransitionsDone}
								/>
							)}
						</Box>
					</SimpleOperationWrapper>
				</Root>
			</PopoutButton>
		</Root>
	);
}

CommentsPopup.propTypes = {
	connectedTo: PropTypes.string.isRequired,
	context: PropTypes.string.isRequired,
};
