import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import mercutio from 'mercutio';
import BaseStore from './BaseStore';
import authToken from '../lib/auth-token';
import FrontendStorage from '../lib/frontendStorage';
import { login, getUser } from '../api/relevant';
import SystemData from '../lib/systemData';
import api from '../api';

const anonymousObject = {
	authenticated: false,
	token: null,
	profile: null,
	adminProfile: null,
	ghosting: false,
};

class IdentityStore extends BaseStore {
	constructor() {
		super(_.merge({}, anonymousObject));
		this.getAuthToken();
		const ghostStr = FrontendStorage.getItem('ghostingProfile');
		if (ghostStr) {
			this.startGhostingWithUser(JSON.parse(ghostStr));
		}
	}

	async tryLogin(email, password, oauth) {
		try {
			const data = await login(email, password, oauth) || {};
			const { success, result } = data;
			if (success) {
				this.setAuthToken(result.token);
			}
			return data;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	isAuthenticated() {
		return super._data.authenticated;
	}

	get roles() {
		const js = super.js();
		const roles = (js && js.profile && js.profile.roles) || [];
		return mercutio(roles);
	}

	isAdministrator() {
		return this.roles.is('admin@relevant');
	}

	isSuperAdministrator() {
		const js = super.js();
		const email = (js && js.profile && js.profile.email) || null;
		return email === 'admin@mugs.info';
	}

	isAudiencePublisher() {
		return this.roles.is('publisher@audience');
	}

	isProgrammaticPublisher() {
		return this.roles.is('publisher@programmatic');
	}

	isNoUser() {
		return !super.js().profile;
	}

	canEditUsers() {
		return super.js().profile.permissions.canEditUsers;
	}

	hasAllPubAccess() {
		return super.js().profile.permissions.hasAllPubAccess;
	}

	allPubAccess() {
		const { publisherId } = super.js().profile;
		const { additionalPubAccess } = SystemData.genericData;
		const res = additionalPubAccess || [];
		if (publisherId) {
			res.push(publisherId);
		}
		return _.uniq(res);
	}

	additionalPubAccess() {
		const { publisherId } = super.js().profile;
		const { additionalPubAccess } = SystemData.genericData;
		return _.without(additionalPubAccess || [], publisherId);
	}

	isDemoUser() {
		const { profile } = super.js();
		return (profile || {}).isDemoUser;
	}

	user() {
		const { profile } = super.js();
		return profile;
	}

	userId() {
		const { profile } = super.js();
		return profile ? profile.id : null;
	}

	email() {
		const { profile } = super.js();
		return profile ? profile.email : null;
	}

	name() {
		const { profile } = super.js();
		return profile ? profile.fullname : null;
	}

	publisherId() {
		return super.js().profile.publisherId;
	}

	getAuthToken() {
		const token = authToken.get();

		if (token) {
			const decoded = jwtDecode(token);

			super._data.authenticated = true;
			super._data.token = token;
			super._data.profile = decoded;

			if (this.isAdministrator()) {
				super._data.adminProfile = decoded;
			}
		}
	}

	getUrlPathPrefix() {
		const { profile } = super.js();
		return this.isAdministrator() ? '' : `users/${profile ? profile.id : 'public'}/`;
	}

	setAuthToken(token) {
		authToken.set(token);
		this.getAuthToken();
	}

	logout() {
		this.stopGhosting();
		authToken.clear();
		_.merge(super._data, anonymousObject);
	}

	async startGhosting(id) {
		const user = (await getUser(id)).result;
		this.startGhostingWithUser(user);
	}

	startGhostingWithUser(user) {
		super._data.ghosting = true;
		super._data.profile = user;
		api.extraHeaders['X-Ghosting-UserId'] = user.id;
		FrontendStorage.setItem('ghostingProfile', JSON.stringify(user));
	}

	isGhosting() {
		return super._data.ghosting;
	}

	stopGhosting() {
		super._data.ghosting = false;
		super._data.profile = super._data.adminProfile;
		delete api.extraHeaders['X-Ghosting-UserId'];
		FrontendStorage.removeItem('ghostingProfile');
	}
}

export default IdentityStore;
