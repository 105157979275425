import { Grid, TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import prettyBytes from 'pretty-bytes';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { base64Utf8Decode, base64Utf8Encode } from 'relevant-shared/misc/misc';
import { MAX_FILE_UPLOAD_SIZE } from 'relevant-shared/misc/sharedConstants';
import classes from '../../api/classes';
import { LoadingWrapper } from '../../components/LoadingWrapper';
import Select from '../../components/Select';
import { useCdnFileQuery, useCdnFilesQuery } from './query';
const { CdnFile } = classes;

type CdnFileEditorHandle = {
	submit: () => void;
}

interface CndFileEditorProps {
	file: typeof CdnFile;
	onChange?: (file: typeof CdnFile) => void;
	onSuccess?: () => void;
	onError?: (e: unknown) => void;
}

const CdnFileTypes = [
	{
		label: 'ads.txt (Authorized Digital Sellers)',
		data: {
			isTextType: true,
			path: 'ads.txt',
			content: '# Example ads.txt\n# https://iabtechlab.com/wp-content/uploads/2022/04/Ads.txt-1.1.pdf\nplaceholder.example.com, placeholder, DIRECT, placeholder',
		},
	},
	{
		label: 'sellers.json (https://iabtechlab.com/sellers-json)',
		data: {
			isTextType: true,
			path: 'sellers.json',
			content: JSON.stringify({
				"contact_email": "adops@advertisingsystem.com",
				"contact_address": "Advertising System Inc., 101 Main Street, New York, NY 10101",
				"version": "1.0",
				"identifiers": [
					{
						"name": "TAG-ID",
						"value": "28cb65e5bbc0bd5f"
					}
				],
				"sellers": [
					{
						"seller_id": "1942009976",
						"name": "Publisher1",
						"domain": "publisher1.com",
						"seller_type": "PUBLISHER"
					},
					{
						"seller_id": "1397382429",
						"name": "Exchange1",
						"domain": "exchange1.com",
						"seller_type": "INTERMEDIARY"
					},
					{
						"seller_id": "20000000",
						"name": "Seller And Intermediary, Inc",
						"domain": "sellerandintermediary.com",
						"seller_type": "PUBLISHER",
						"comment": "NorthAmerica O&O inventory"
					},
					{
						"seller_id": "20000001",
						"name": "Seller And Intermediary, Inc",
						"domain": "sellerandintermediary.com",
						"seller_type": "PUBLISHER",
						"comment": "APAC O&O inventory"
					},
					{
						"seller_id": "20000002",
						"name": "Seller And Intermediary, Inc",
						"domain": "sellerandintermediary.com",
						"seller_type": "INTERMEDIARY",
						"comment": "Non O&O inventory"
					},
					{
						"seller_id": "101010101",
						"name": "Hybrid Seller",
						"domain": "hybridseller.com",
						"seller_type": "BOTH",
						"comment": "Sells both O&O and other sellers' inventory"
					},
					{
						"seller_id": "00000001",
						"seller_type": "INTERMEDIARY",
						"is_confidential": 1
					},
					{
						"seller_id": "EB_0001",
						"name": "Passthrough Publisher",
						"domain": "passthroughpublisher.com",
						"seller_type": "PUBLISHER",
						"is_passthrough": 1,
						"comment": "direct buyer/seller of this inventory must establish an account relationship with Passthrough Publisher"
					}
				]
			}, null, 2),
		},
	},
	{ label: 'Text File', data: { isTextType: true, path: 'example.txt', content: '' } },
	{ label: 'JSON File', data: { isTextType: true, path: 'example.json', content: '' } },
	// { label: 'Other', data: { isTextType: false, path: '', content: '' } },
];

export const CdnFileEditor = forwardRef<CdnFileEditorHandle, CndFileEditorProps>(({
	file,
	onChange,
	onSuccess,
	onError,
}, ref) => {
	const { isLoading, isError, error, data: originalFile } = useCdnFileQuery(file);
	const { data: allFiles } = useCdnFilesQuery();
	const [data, setData] = useState<Partial<typeof CdnFile>>({});
	const [typePicker, setTypePicker] = useState(null);
	const queryClient = useQueryClient();
	// const uploadButtonRef = React.useRef(null);

	const contentSize = data.isTextType ? new TextEncoder().encode(data.content ?? '').length : (data.content?.length ?? 0);
	useEffect(() => {
		if (!originalFile) {
			return;
		}
		let text = '';
		if (originalFile?.isTextType) {
			try {
				text = base64Utf8Decode(originalFile.content);
			} catch (e) { } // eslint-disable-line no-empty
		}
		setData({
			...originalFile,
			content: text,
		});
		const type = originalFile?.isTextType ? 'Text File' : 'Other';
		setTypePicker(type);
	}, [originalFile]);

	const saveMutation = useMutation({
		mutationFn: async (f: Partial<typeof CdnFile>) => {
			const content = f.isTextType ? base64Utf8Encode(f.content) : f.content; // Binary data would already be base64 encoded by the file picker
			if (contentSize > MAX_FILE_UPLOAD_SIZE) {
				throw new Error(`File exceeds the maximum allowed size of ${prettyBytes(MAX_FILE_UPLOAD_SIZE)}`);
			}
			Object.assign(originalFile, {
				...f,
				content,
			});
			await CdnFile.save(originalFile);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['cdnFiles'] });
			onSuccess?.();
		},
		onError,
	});

	useImperativeHandle(ref, () => ({
		submit: () => {
			saveMutation.mutate(data);
		},
	}));

	function setFields(fields) {
		setData({ ...data, ...fields });
		onChange?.({ ...data, ...fields });
	}

	return (
		<LoadingWrapper loading={isLoading} error={isError && (error?.message ?? 'Internal error')} transparent>
			<Grid item xs={12} className="!pt-0">
				{/*
					Arbitrary file upload functionality is disabled until we find a customer with a use case for it,
					so that we don't encourage using us as a CDN :)
				*/}
				{/* <div className="flex justify-center w-full mb-4">
					<Button
						variant="outlined"
						color="primary"
						icon={<Upload />}
						onClick={() => uploadButtonRef.current.click()}
					>
						Upload File
					</Button>
					<input
						type="file"
						accept="*"
						ref={uploadButtonRef}
						style={{ display: 'none' }}
						onChange={async (e) => {
							const file = e.target.files[0];
							const reader = new FileReader();
							reader.onload = (e) => {
								const base64 = e.target.result.slice(e.target.result.indexOf(',') + 1);
								setFields({
									path: file.name,
									type: file.type,
									content: base64,
								});
								if (file.type.startsWith('text/')) {
									setTypePicker('Text File');
								} else {
									setTypePicker('Other');
								}
							}
							reader.readAsDataURL(file);
						}}
					/>
				</div>
				<div className="flex flex-row w-full gap-4 items-center">
					<span className="h-px flex-1 border-t border-grey-300 border-dashed"></span>
					<span className="text-sm text-grey-600">OR</span>
					<span className="h-px flex-1 border-t border-grey-300 border-dashed"></span>
				</div> */}
				<Select
					label="Type"
					required
					items={CdnFileTypes.map((t) => ({ label: t.label, value: t.label }))}
					fullWidth
					margin="normal"
					value={typePicker}
					onChange={(e) => {
						const lastTypeDef = CdnFileTypes.find((t) => t.label === typePicker);
						const typeDef = CdnFileTypes.find((t) => t.label === e.target.value);
						setTypePicker(e.target.value);
						setFields({
							isTextType: typeDef.data.isTextType,
							path: ((lastTypeDef?.data.path !== data.path) && data.path) || typeDef.data.path,
							content: ((lastTypeDef?.data.content !== data.content) && data.content) || typeDef.data.content,
						});
					}}
				/>
				<TextField
					label="Path"
					required
					value={data.path ?? ''}
					onChange={(e) => {
						setFields({
							path: e.target.value,
						});
						if (!typePicker) {
							setTypePicker('Text File');
						}
					}}
					fullWidth
					margin="normal"
					error={allFiles?.some((f) => f.path === data.path && f.id !== data.id)}
					helperText={allFiles?.some((f) => f.path === data.path && f.id !== data.id) && 'File already exists'}
				/>
				<TextField
					label="Public URL"
					fullWidth
					disabled
					margin="normal"
					value={data.path ? `${data.cdnBaseUrl}/${data.path}` : ''}
				/>
				<TextField
					label="Content"
					fullWidth
					multiline
					rows={10}
					margin="normal"
					disabled={!data.isTextType}
					value={data.isTextType ? data.content : 'n/a'}
					onChange={(e) => data.isTextType && setFields({ content: e.target.value })}
					error={contentSize > MAX_FILE_UPLOAD_SIZE}
					helperText={contentSize > MAX_FILE_UPLOAD_SIZE && `Content exceeds the maximum allowed size of ${prettyBytes(MAX_FILE_UPLOAD_SIZE)}`}
				/>
			</Grid>
		</LoadingWrapper>
	);
});
