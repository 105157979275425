import React, {
	createContext,
	useState,
	useMemo,
	useCallback,
} from 'react';
import PropTypes from 'prop-types';

export const SidebarContext = createContext({});

export default function SidebarProvider({ children }) {
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = useCallback(() => {
		setMobileOpen(!mobileOpen);
	}, [mobileOpen, setMobileOpen]);

	const providerValue = useMemo(() => ({
		mobileOpen,
		handleDrawerToggle,
	}), [mobileOpen, handleDrawerToggle]);

	return (
		<SidebarContext.Provider value={providerValue}>
			{ children }
		</SidebarContext.Provider>
	);
}

SidebarProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
