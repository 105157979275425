import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import SystemData from '../../lib/systemData';
import { stores } from '../../stores';

export function ReportTemplateSettings({ template, onChange }) {
	const [values, setValues] = useState({
		description: template.description,
		isPublic: !template.userId,
		isAdminTemplate: template.isAdminTemplate,
		isUserTemplate: template.isUserTemplate,
		isReportTemplate: template.isReportTemplate,
		isDashboardTemplate: template.isDashboardTemplate,
		isDefaultDashboard: template.isDefaultDashboard,
		masterShared: template.masterShared,
	});

	const isMasterInstance = !!SystemData.genericData.isMasterInstance;
	const isAdmin = stores.identity.isAdministrator();

	function atLeastOneOf(prevVals, newVals, keys) {
		// Make sure at least one of props is always checked
		if (keys.every((p) => !newVals[p])) {
			const key = keys.find((p) => !prevVals[p]);
			return { ...newVals, [key]: true };
		} else {
			return newVals;
		}
	}

	function handleChange(prop) {
		return (e) => {
			let newValues = { ...values, [prop]: e.target.type === 'checkbox' ? e.target.checked : e.target.value };
			newValues = atLeastOneOf(values, newValues, ['isReportTemplate', 'isDashboardTemplate']);
			newValues = atLeastOneOf(values, newValues, ['isAdminTemplate', 'isUserTemplate']);
			setValues(newValues);
			onChange?.(newValues);
		};
	}

	return (
		<>
			<FormGroup>
				<TextField label="Description (optional)" value={values.description ?? ''} onChange={handleChange('description')} />
				<FormControlLabel control={<Checkbox checked={!!values.isReportTemplate} onChange={handleChange('isReportTemplate')} />} label="Is report template" />
				<FormControlLabel control={<Checkbox checked={!!values.isDashboardTemplate} onChange={handleChange('isDashboardTemplate')} />} label="Is dashboard template" />
				{ isAdmin && (
					<>
						<FormControlLabel control={<Checkbox checked={!!values.isAdminTemplate} onChange={handleChange('isAdminTemplate')} />} label="Admin visible template" />
						<FormControlLabel control={<Checkbox checked={!!values.isUserTemplate} onChange={handleChange('isUserTemplate')} />} label="User visible template" />
					</>
				) }
				{ isAdmin && (
					<FormControlLabel control={<Checkbox checked={!!values.isDefaultDashboard} disabled={!values.isDashboardTemplate} onChange={handleChange('isDefaultDashboard')} />} label="Default dashboard for new users" />
				) }
				{ (isAdmin && isMasterInstance) && (
					<FormControlLabel control={<Checkbox checked={!!values.masterShared} onChange={handleChange('masterShared')} />} label="Shared across all instances" />
				) }
			</FormGroup>
			<Alert severity="info">Keep in mind that certain dimensions and metrics may not be available to all users.</Alert>
		</>
	);
}

ReportTemplateSettings.propTypes = {
	template: PropTypes.object.isRequired,
	onChange: PropTypes.func,
};

ReportTemplateSettings.defaultProps = {
	onChange: undefined,
};
