import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.css';

class Spinner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			timeoutExpired: false,
		};
	}

	componentDidMount() {
		const { delay } = this.props;
		const timeout = delay === true ? 600 : delay;

		this.timer = setTimeout(() => this.setState({ timeoutExpired: true }), timeout);
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	doShow() {
		return (!('delay' in this.props)) || this.state.timeoutExpired;
	}

	renderBars() {
		const { color, barsStyle } = this.props;

		return (
			<div className={styles.bars} style={barsStyle}>
				<div className={styles.barsRect1} style={{ backgroundColor: color }} />
				<div className={styles.barsRect2} style={{ backgroundColor: color }} />
				<div className={styles.barsRect3} style={{ backgroundColor: color }} />
				<div className={styles.barsRect4} style={{ backgroundColor: color }} />
				<div className={styles.barsRect5} style={{ backgroundColor: color }} />
			</div>
		);
	}

	renderDots() {
		const { color } = this.props;

		return (
			<div className={styles.dots}>
				<div className={styles.bounce1} style={{ backgroundColor: color }} />
				<div className={styles.bounce2} style={{ backgroundColor: color }} />
				<div className={styles.bounce3} style={{ backgroundColor: color }} />
			</div>
		);
	}

	renderSignal() {
		const { color } = this.props;

		return (
			<div className={styles.signal} style={{ backgroundColor: color }} />
		);
	}

	renderByType(type) {
		if (type === 'dots') return this.renderDots();
		if (type === 'signal') return this.renderSignal();
		return this.renderBars();
	}

	renderText(text) {
		if (!text) return null;

		return (
			<div
				style={{
					margin: '10px auto',
					textAlign: 'center',
					color: '#444',
					position: 'relative',
					zIndex: 1000,
				}}
			>
				{this.props.text}
			</div>
		);
	}

	render() {
		const { text, type, style } = this.props;

		if (!this.doShow()) return null;

		return (
			<div style={style}>
				{this.renderByType(type)}
				{this.renderText(text)}
			</div>
		);
	}
}

Spinner.propTypes = {
	type: PropTypes.oneOf(['bars', 'signal', 'dots']),			// the style of spinner
	color: PropTypes.string,									// the color of the spinner
	text: PropTypes.string,										// text to show beneath the spinner
	style: PropTypes.object,
	barsStyle: PropTypes.object,
	delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),		// whether the spinner should only show up after a moment has passed (for long actions)
};

Spinner.defaultProps = {
	type: 'bars',
	style: {},
	barsStyle: {},
};

export default Spinner;
