const genericDataListeners = []; // will be used before EnvReportInterface.init()

const EnvReportInterface = {
	init(obj) {
		Object.assign(this, obj);
		genericDataListeners.forEach((cb) => this.onGenericDataUpdated(cb));
	},
	onGenericDataUpdated: (cb) => genericDataListeners.push(cb),
};

module.exports = EnvReportInterface;
