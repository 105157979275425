import { useMemo } from 'react';
import useReports from '../useReports';
import { stores } from '../../stores';

function usePinnedReports() {
	const currentUser = stores.identity.user();
	const { reports } = useReports();
	return useMemo(() => {
		if (reports === null) {
			return null;
		}
		const res = {};
		Object.entries(reports || {}).forEach(([reportType, reportsForType]) => {
			res[reportType] = reportsForType
				.filter((report) => report.pinnedByUsers.includes(currentUser.id))
				.sort((a, b) => a.settings.title.localeCompare(b.settings.title));
		});
		return res;
	}, [reports, currentUser]);
}

export default usePinnedReports;
