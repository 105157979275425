import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 *
 * Runs a callback function on route change
 * Pass exclude routes to exclude them from the callback
 * excludRoutes uses pattern matching, i.e you don't have to pass the full path
 * just pass a string containing the path, i.e '/reports'
 */
export default function useRouteChange(callback, excludeRoutes = []) {
	const { pathname } = useLocation();

	useEffect(() => {
		const isExcluded = excludeRoutes.find(({ route, exact }) => {
			if (exact) {
				return route === pathname;
			}
			return pathname.includes(route);
		}) !== undefined;

		if (callback && !isExcluded) {
			callback();
		}
	}, [callback, excludeRoutes, pathname]);
}
