import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	ListItemSecondaryAction,
	IconButton,
	Avatar,
	Tooltip,
} from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from '../../Link/Link';

const initialState = {
	showAllRecipients: false,
	transitionsDone: false,
};

function reducer(state, action) {
	switch (action.type) {
		case 'clickUsers':
			return {
				...state,
				showAllRecipients: !state.showAllRecipients,
				transitionsDone: false,
			};
		case 'closedUsers':
			return {
				...state,
				transitionsDone: true,
				showAllRecipients: false,
			};
		default:
			throw new Error(`Unhandled action type ${action.type}`);
	}
}

function Subscription({
	recipients,
	occurences,
	onDelete,
}) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { showAllRecipients, transitionDone } = state;

	const firstUserName = `${recipients[0].firstName} ${recipients[0].lastName[0].toUpperCase()}`;
	const recipientText = recipients.length > 1 ? `${firstUserName} + ${recipients.length - 1} others`
		: `${recipients[0].firstName} ${recipients[0].lastName}`;

	const capitalizedFirstOccurence = `${occurences[0].type.charAt(0).toUpperCase()}${occurences[0].type.slice(1)}`;
	const occurenceText = occurences.length > 1
		? [capitalizedFirstOccurence, ...occurences.slice(1).map(({ type }) => type)].join(', ')
		: capitalizedFirstOccurence;

	return (
		<>
			<ListItem sx={{ ...(showAllRecipients && transitionDone && { pb: 0, mb: 0 }) }}>
				<ListItemIcon>
					<Avatar sx={{ bgcolor: 'info.main' }}>
						<NotificationsActiveIcon />
					</Avatar>
				</ListItemIcon>
				<ListItemText>
					{occurenceText}
					{' '}
					{ recipients.length > 1 ? (
						<Link onClick={() => {
							dispatch({ type: 'clickUsers' });
						}}
						>
							{recipientText}
						</Link>
					) : (
						<Box sx={{ fontSize: '14px' }}>{recipientText}</Box>
					)}
				</ListItemText>
				<ListItemSecondaryAction>
					<Tooltip title="Delete">
						<IconButton onClick={onDelete}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</ListItemSecondaryAction>
			</ListItem>
			<Collapse
				in={showAllRecipients}
				onExited={() => dispatch({ type: 'closedUsers' })}
			>
				<Box sx={{
					fontSize: '14px',
					pb: 1,
					ml: 9,
					mb: 0.5,
				}}
				>
					{recipients.map(({ firstName, lastName, userId }) => (
						<div key={userId}>
							{firstName}
							{' '}
							{lastName}
						</div>
					))}
				</Box>
			</Collapse>
		</>
	);
}

Subscription.propTypes = {
	recipients: PropTypes.arrayOf(PropTypes.shape({
		userId: PropTypes.string.isRequired,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
	})).isRequired,
	occurences: PropTypes.arrayOf(PropTypes.shape({
		type: PropTypes.oneOf(['daily', 'weekly', 'monthly', 'yearly']).isRequired,
	})).isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default Subscription;
