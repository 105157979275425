import EnvReportInterface from 'relevant-shared/reportData/envReportInterface';
import AllCustomizers from 'relevant-shared/reportData/customizers/allCustomizers';
import ReportType from 'relevant-shared/reportData/reportType';
import FrontendReportType from './frontendReportType';
import SystemData from '../../lib/systemData';
import { Reports } from '../../api/relevant';
import classes from '../../api/classes';
import { stores } from '../../stores';
import MiscUtils from '../../lib/miscUtils';
import FrontendAudienceCustomizer from './customizers/frontendAudienceCustomizer';
import FrontendHbCustomizer from './customizers/frontendHbCustomizer';
import FrontendSummaryHbCustomizer from './customizers/frontendSummaryHbCustomizer';
import FrontendProgrammaticCustomizer from './customizers/frontendProgrammaticCustomizer';
import FrontendFutureCustomizer from './customizers/frontendFutureCustomizer';
import FrontendMissingSspEntriesCustomizer from './customizers/frontendMissingSspEntriesCustomizer';
import FrontendLogCustomizer from './customizers/frontendLogCustomizer';

const FrontendReportInterface = {
	callReport: (fnName, params) => Reports.call(fnName, params),
	onGenericDataUpdated: (cb) => SystemData.onGenericDataUpdated.add(cb),
	getGenericData: () => SystemData.genericData || {},
	isAdministrator: () => stores.identity.isAdministrator(),
	isProgrammaticPublisher: () => stores.identity.isProgrammaticPublisher(),
	isAudiencePublisher: () => stores.identity.isAudiencePublisher(),
	typeMap: () => classes,
	MiscUtils,
};

AllCustomizers.init({
	audience: FrontendAudienceCustomizer,
	hb: FrontendHbCustomizer,
	summary_hb: FrontendSummaryHbCustomizer,
	programmatic: FrontendProgrammaticCustomizer,
	missingSspEntries: FrontendMissingSspEntriesCustomizer,
	log: FrontendLogCustomizer,
	future: FrontendFutureCustomizer,
});

EnvReportInterface.init(FrontendReportInterface);

ReportType.setReportTypeClass(FrontendReportType);
