const _ = require('lodash');
const { paramKey, getTagFields, bidderParams } = require('../../prebid/bidParamUtils');

const Types = {}; // Will contain all node types

class Node {
	constructor(obj, parentNode, type, rest) {
		Object.assign(this, {
			obj,
			children: [],
			parentNode,
			type,
			byId: { [obj.id]: this },
			byType: _.mapValues(Types, () => []),
			bidderNodes: [],
			parents: [],
			...rest,
		});
		for (let node = parentNode; node; node = node.parentNode) {
			this.parents.push(node);
			node.register(this);
		}
		this.root = _.last(this.parents);
	}

	register(node) {
		this.byId[node.id] = node;
		this.byType[node.type].push(node);
	}

	getTagFields(sspId) {
		return getTagFields(sspId, this.publisherNode);
	}

	getParentOfType(type, includeSelf) {
		if (includeSelf && this.type === type) {
			return this;
		}
		return this.parents.find((p) => (p.type === type));
	}

	getBidParamArr({ sspId: systemId, pbjsConfigId, includeSelf = true }) {
		const pub = this.publisherNode;
		const { globalParams, pubParams } = pub;
		const pbConfigNode = pub.byId[pbjsConfigId];
		const parentConfigNode = pbConfigNode?.parentNode;

		// Possible appearence: [parentConfigId, childConfigId], [childConfigId], [undefined]
		const pbConfigIdArr = parentConfigNode ? [parentConfigNode.id, pbjsConfigId] : [pbjsConfigId];

		const system = pub.sspsById[systemId] || pub.userIdModulesById[systemId];
		const paramArr = [];
		const add = (param) => param && paramArr.push(param);

		// Add default parameters provided by e.g. SSP (networkId etc configured in settings)
		add((system || {}).defaultBidParams);

		add(globalParams[paramKey(null, systemId)]); // Global prebid paramters
		const { placementType } = [this, ...this.parents].find((n) => n.type === 'PlacementNode') || {};
		if (placementType) { // Add placement type prebid paramters
			const typeParams = placementType.bidParams.find((p) => p.sspId === systemId);
			if (typeParams) {
				add(typeParams.params);
			}
		}
		_.forEachRight(this.parents, ({ id }) => { // Add Publisher/Site/Placement prebid parameters
			add(pubParams[paramKey(id, systemId)]);
		});
		if (pbjsConfigId) {
			add(pubParams[paramKey(this.id, systemId)]);
			pbConfigIdArr.forEach((pbConfigId, idx) => {
				const nodes = parentConfigNode && !idx ? [this, ...this.parents] : this.parents;
				_.forEachRight(nodes, ({ id }) => {
					add(pubParams[paramKey(id, systemId, pbConfigId)]);
				});
			});
		}
		if (includeSelf) {
			add(this.individualBidderParams({ sspId: systemId, pbjsConfigId }));
		}
		return paramArr;
	}

	/**
	 *
	 * @param {*} sspId Note that sspId has grown into a more general system id, might not actually be an SSP id.
	 */
	bidderParams({
		sspId,
		pbjsConfigId,
		includeSelf = true,
		withMeta,
		onlyNonFields,
		opts,
	}) {
		const arr = this.getBidParamArr({ sspId, pbjsConfigId, includeSelf });
		const tagFields = this.getTagFields(sspId);
		return bidderParams(arr, tagFields, {
			includesFinal: includeSelf,
			withMeta,
			onlyNonFields,
			opts,
		});
	}

	genericPlacementData(settings) {
		return this.bidderParams({ sspId: 'genericPlacementData', ...settings });
	}

	individualBidderParams({ sspId, pbjsConfigId }) {
		return { ...this.publisherNode.pubParams[paramKey(this.id, sspId, pbjsConfigId)] };
	}

	registerBidderNode(node) {
		this.bidderNodes.push(node);
	}

	get dbPath() {
		const myPath = this.cls.parentPath;
		if (!myPath) {
			return null;
		}
		const dbParent = this.dbParentNode;
		const parentPath = dbParent.dbPath;
		const myIdx = dbParent.obj[myPath].indexOf(this.obj);
		return `${parentPath ? `${parentPath}.` : ''}${myPath}[${myIdx}]`;
	}

	removeNode() {
		this.children.forEach((child) => {
			child.removeNode();
		});
		const { id, dbParentNode, type } = this;
		this.parents.forEach((parent) => {
			delete parent.byId[id];
			_.pull(parent.byType[type], id);
			_.pull(parent.bidderNodes, this);
		});
		if (dbParentNode) {
			_.pull(dbParentNode.obj[this.cls.parentPath], this.obj);
		}
	}

	get parentDbArray() {
		return this.parentNode?.obj?.[this.cls.parentPath];
	}

	get cls() {
		return Types[this.type];
	}

	get dbParentNode() {
		return this.parentNode;
	}

	get publisherNode() {
		return this.root;
	}

	get id() {
		return this.obj.id;
	}

	get name() {
		return this.obj.name;
	}

	descendants() {
		return this.children.concat(...this.children.map(((c) => c.descendants())));
	}
}

Node.Types = Types;
module.exports = Node;
