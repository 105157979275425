import React from 'react';
import MuiTextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import AutocompleteInput from '../AutocompleteInput';
import TzList from './tzList';

const TimezoneOptions = TzList.map((value) => ({ value }));

function TimezoneSelect(props) {
	const { onChange, value, width } = props;
	return (
		<AutocompleteInput
			suggestions={TimezoneOptions}
			onChange={(ev) => {
				onChange({ target: { ...ev } });
			}}
			value={value}
			autocompleteProps={{
				renderInput: (params) => (
					<MuiTextField {...params} label="Timezone" />
				),
				isOptionEqualToValue: (option, val) => (option.value === (val.value || val)),
				getOptionLabel: (option) => option.value || option,
				sx: { width },
				componentsProps: {
					paper: {
						sx: {
							width,
						},
					},
				},
			}}
		/>
	);
}

TimezoneSelect.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	value: PropTypes.any,
	width: PropTypes.number,
	onChange: PropTypes.func.isRequired,
};

TimezoneSelect.defaultProps = {
	value: { value: 'UTC' },
	width: null,
};

export default TimezoneSelect;
