import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import {
	TextField, Box, Paper, IconButton,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const PREFIX = 'CommentEditor';

const classes = {
	bodyInput: `${PREFIX}-bodyInput`,
	comment: `${PREFIX}-comment`,
};

const Root = styled('div')(({
	theme: { spacing },
}) => ({
	[`& .${classes.comment}`]: {
		padding: spacing(4),
		minWidth: spacing(48),
	},
	[`& .${classes.bodyInput}`]: {
		marginTop: spacing(2),
	},
}));

export default function CommentEditor({ onSubmit, onCancel }) {
	const [title, setTitle] = useState('');
	const [body, setBody] = useState('');

	const [formError, setFormErrror] = useState();

	const handleSubmit = () => {
		if (title.length > 0 && body && body.length > 0) {
			onSubmit(title, body);
			setFormErrror('');
		}
		if (title.length === 0 && body.length === 0) {
			setFormErrror('both');
		} else if (title.length === 0) {
			setFormErrror('title');
		} else if (body.length === 0) {
			setFormErrror('body');
		}
	};

	const hasError = (field) => {
		if (formError === 'both') {
			return true;
		}
		return (field === 'title' && formError === 'title')
			|| (field === 'body' && formError === 'body');
	};

	return (
		<Root>
			<Paper
				className={classes.comment}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						e.preventDefault();
						e.stopPropagation();
						handleSubmit();
					}
				}}
			>
				<Box component="div" display="flex" flexDirection="column">
					<TextField
						autoFocus
						variant="outlined"
						label="Title"
						value={title}
						onChange={({ target }) => setTitle(target.value)}
						error={hasError('title')}
						helperText={hasError('title') && 'Please enter a title'}
					/>
					<TextField
						className={classes.bodyInput}
						variant="outlined"
						rows={3}
						value={body}
						onChange={({ target }) => setBody(target.value)}
						error={hasError('body')}
						helperText={hasError('body') && 'Please enter some text'}
						multiline
					/>
					<Box marginLeft="auto">
						<IconButton onClick={handleSubmit} size="large">
							<CheckIcon />
						</IconButton>
						<IconButton onClick={onCancel} size="large">
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
			</Paper>
		</Root>
	);
}

CommentEditor.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};
