import React from 'react';
import _ from 'lodash';
import ProgrammaticCustomizer from 'relevant-shared/reportData/customizers/programmaticCustomizer';
import Checkbox from '../../Checkbox';

class FrontendProgrammaticCustomizer extends ProgrammaticCustomizer {
	renderFilterHeader({ selectOptions, settings }) {
		const { myPortfolioUserNr } = this.reportData.report.meta || {};
		if (!myPortfolioUserNr || _.includes(settings.getDimensionExclude(settings.state), 'userNr')) {
			return null;
		}
		const id = myPortfolioUserNr.toString();
		const { selected, onChange } = selectOptions('userNr');
		return (
			<div style={{ position: 'absolute', right: 20, top: 13 }}>
				<Checkbox
					label="Filter by my advertisers"
					name="filterByPortfolio"
					value={selected.indexOf(id) >= 0}
					onChange={(ev) => {
						const items = _.without(selected, id);
						if (ev.target.value) {
							items.push(id);
						}
						onChange(items);
					}}
				/>
			</div>
		);
	}
}

export default FrontendProgrammaticCustomizer;
