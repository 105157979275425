import React, {
	useEffect,
	useState,
	useCallback,
	Fragment,
	useRef,
	useContext,
} from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
	List,
	ListItem,
	IconButton,
	ListItemAvatar,
	Avatar,
	ListItemText,
	ListItemSecondaryAction,
	DialogActions,
	DialogContent,
	Box,
	Divider,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import BuildIcon from '@mui/icons-material/Build';
import AddIcon from '@mui/icons-material/Add';
import { PrebidBuildConfiguration, PrebidBuildHandler } from '../../api/relevant';
import useRequest from '../../hooks/useRequest/useRequest';
import SimpleDialog from '../SimpleDialog';
import SimpleOperationWrapper from '../SimpleOperationWrapper';
import PrebidBuildForm from './PrebidBuildForm';
import { ActionButton } from '../ActionButton/ActionButton';
import useIsMounted from '../../hooks/useIsMounted';
import { Link } from '../Link/Link';
import PrebidBuildDeleteButton from './PrebidBuildDeleteButton';
import { BuildContext } from '../../providers/BuildProvider/context';
import { ConfirmDialog } from '../ConfirmDialog';

const initialDialogTitle = 'Prebid builds';

const maxNumItemsInList = 6;

function PrebidBuildManager({ match, location }) {
	const [setRequest, loading, error, reset] = useRequest();
	const [prebidBuildConfigurations, setPrebidBuildConfigurations] = useState([]);
	const isMounted = useIsMounted();
	const [dialogTitle, setDialogTitle] = useState(initialDialogTitle);
	const [configToEdit, setConfigToEdit] = useState(null);
	const [selectedPrebidBuildPage, setSelectedPrebidBuildPage] = useState(1);
	const listRef = useRef(null);
	// Avoid resizing if next page contains fewer items than last
	const [listHeight, setListHeight] = useState();
	const { notificationTrigger: [, setTriggerBuildNotification] } = useContext(BuildContext);
	const onNameChange = useCallback((n) => setConfigToEdit({ ...configToEdit, name: n }), [configToEdit]);
	const onPbjsGlobalNameChange = useCallback(
		(n) => setConfigToEdit({ ...configToEdit, pbjsGlobalName: n }),
		[configToEdit],
	);
	const onVersionChange = useCallback((v) => setConfigToEdit({ ...configToEdit, version: v }), [configToEdit]);
	const onAdditionalModulesChange = useCallback(
		(modules) => (
			setConfigToEdit({ ...configToEdit, additionalModules: modules })),
		[configToEdit],
	);

	useEffect(() => {
		if (isMounted.current) {
			setRequest({
				requestFunction: () => PrebidBuildConfiguration.list(),
				requestCallback: (buildConfigs) => {
					if (isMounted.current) {
						setPrebidBuildConfigurations(buildConfigs);
					}
				},
			});
		}
	}, [setRequest, isMounted]);

	const onEditSubmit = () => {
		setRequest({
			requestFunction: async () => (PrebidBuildHandler.call('updatePrebidBuild', { ...configToEdit })),
			requestCallback: (res) => {
				setPrebidBuildConfigurations(res);
				setConfigToEdit(null);
				setDialogTitle(initialDialogTitle);
				setTriggerBuildNotification(true);
			},
		});
	};

	const onAddSubmit = () => {
		setRequest({
			requestFunction: async () => (PrebidBuildConfiguration.call('create', ({ ...configToEdit }))),
			requestCallback: (result) => {
				const updated = [...prebidBuildConfigurations, result];
				setPrebidBuildConfigurations(updated);
				setConfigToEdit(null);
				setDialogTitle(initialDialogTitle);
				setTriggerBuildNotification(true);
			},
		});
	};

	const deleteBuildConfig = (id) => {
		setRequest({
			requestFunction: () => PrebidBuildHandler.call('deletePrebidBuild', { id }),
			requestCallback: (res) => {
				setPrebidBuildConfigurations(res);
			},
		});
	};

	const toggleEdit = (buildConfigId) => {
		const config = prebidBuildConfigurations.find(({ id }) => id === buildConfigId);
		setDialogTitle('Edit Prebid build');
		setConfigToEdit({ ...config });
	};

	const toggleAdd = () => {
		setDialogTitle('Add a new Prebid build');
		setConfigToEdit({
			name: '',
			version: null,
			additionalModules: [],
		});
	};

	const onPageChange = (page) => {
		const { height } = listRef.current.getBoundingClientRect();
		if (!listHeight) {
			setListHeight(height);
		}
		setSelectedPrebidBuildPage(page);
	};

	const visiblePrebidBuildConfigs = prebidBuildConfigurations
		.slice((maxNumItemsInList * (selectedPrebidBuildPage - 1)), maxNumItemsInList * selectedPrebidBuildPage);

	const buildListStyleProps = {
		...(listHeight && { minHeight: listHeight }),
	};
	return (
		<SimpleDialog
			open
			title={dialogTitle}
			parentPath={`${match.path}${location.search}`}
			dialogProps={{
				maxWidth: 'sm',
				fullWidth: true,
			}}
			dialogHeaderAction={
				!configToEdit && (
					<IconButton onClick={() => toggleAdd()} size="large">
						<AddIcon />
					</IconButton>
				)
			}
		>
			<SimpleOperationWrapper
				loading={loading}
				error={error}
				onErrorModalClick={reset}
			>
				{ configToEdit ? (
					<PrebidBuildForm
						version={configToEdit.version}
						name={configToEdit.name}
						pbjsGlobalName={configToEdit.pbjsGlobalName}
						additionalModules={configToEdit.additionalModules}
						onVersionChange={onVersionChange}
						onNameChange={onNameChange}
						onPbjsGlobalNameChange={onPbjsGlobalNameChange}
						onAdditionalModulesChange={onAdditionalModulesChange}
						renderContent={(content) => (
							/** Not sure why this one gets a larger padding-top than the one in the add-form.
							 * So adjust. */
							<DialogContent style={{ paddingTop: '8px' }}>{content}</DialogContent>
						)}
						renderActions={(isLoading, submittable = true) => {
							const disabled = isLoading || !configToEdit.name || !submittable;
							return (
								<DialogActions>
									<ActionButton
										color="primary"
										variant="text"
										label="Cancel"
										onClick={() => {
											setConfigToEdit(null);
											setDialogTitle(initialDialogTitle);
										}}
									/>
									{/* config.id exists if editing, in that case we want confirmbutton */}
									{ configToEdit.id ? (
										<ConfirmDialog
											text="The changes will be applied to all websites where this Prebid build is used. Are you sure you want to continue?"
											onConfirm={onEditSubmit}
										>
											<ActionButton
												disabled={disabled}
												label="Save"
												color="primary"
											/>
										</ConfirmDialog>
									) : (
										<ActionButton
											label="Save"
											color="primary"
											onClick={onAddSubmit}
											disabled={disabled}
										/>
									)}
								</DialogActions>
							);
						}}
					/>
				)
					: (
						<Box overflow="hidden">
							{/* Counter list item paddings with negative margins */}
							{ (prebidBuildConfigurations.length > 0) ? (
								<DialogContent dividers style={{ marginLeft: -16, marginRight: -16, paddingTop: 0 }}>
									<List ref={listRef} style={buildListStyleProps}>
										{ visiblePrebidBuildConfigs.map((buildConfig) => (
											<ListItem
												key={buildConfig.id}
												button
												onClick={() => toggleEdit(buildConfig.id)}
											>
												<ListItemAvatar>
													<Avatar sx={{ bgcolor: 'info.main' }}>
														<BuildIcon />
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													primary={buildConfig.name}
													secondary={`v${buildConfig.version}`}
												/>
												<ListItemSecondaryAction>
													<PrebidBuildDeleteButton
														onClick={() => deleteBuildConfig(buildConfig.id)}
													/>
												</ListItemSecondaryAction>
											</ListItem>
										))}
									</List>
									<Box display="flex" justifyContent="center">
										{ prebidBuildConfigurations.length > 6 && (
											<Pagination
												count={Math.ceil(prebidBuildConfigurations.length / maxNumItemsInList)}
												page={selectedPrebidBuildPage}
												onChange={(_, page) => onPageChange(page)}
											/>
										)}
									</Box>
								</DialogContent>
							)
								: (
									!loading && (
										<>
											<Divider />
											<Box
												component="div"
												p={2}
												mt={1}
												display="flex"
												alignItems="center"
												justifyContent="center"
											>
												<Box display="flex">
													<Box component="span" whiteSpace="pre">You have no builds. </Box>
													<Link
														style={{ verticalAlign: 'baseline', fontSize: '16px' }}
														onClick={() => toggleAdd()}
													>
														Add build
													</Link>
												</Box>
											</Box>
										</>
									)
								)}
						</Box>
					)}
			</SimpleOperationWrapper>
		</SimpleDialog>
	);
}

PrebidBuildManager.propTypes = {
	match: PropTypes.shape({
		path: PropTypes.string.isRequired,
	}).isRequired,
	location: PropTypes.shape({
		search: PropTypes.string.isRequired,
	}).isRequired,
};

export default PrebidBuildManager;
