import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '../TextField';
import Select from '../Select';
import { ActionButton } from '../ActionButton/ActionButton';
import HtmlEditor from '../HtmlEditor';

class DocumentTemplateEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = { allowSave: true };
	}

	render() {
		const {
			model, types, onCancel, submit, field,
		} = this.props;
		const macros = ((model.type ? types.find((t) => t.name === model.type) : null) || {}).fields;
		return (
			<>
				<Grid item xs={12}>
					<Paper>
						<Box padding={2}>
							<Typography variant="h2">
								Template
							</Typography>
							<TextField label="Name" required {...field('name')} fullWidth margin="normal" />
							<Select
								label="Type"
								required
								{...field('type')}
								items={types.map((t) => ({ label: t.description, value: t.name }))}
								fullWidth
								margin="normal"
							/>
							<TextField label="Subject" required {...field('subjectTemplateString')} fullWidth margin="normal" />
							{macros
							&& (
								<Select
									label="Insert macro in content"
									name="insertMacro"
									items={_.map(macros, (v, k) => ({ label: v, value: k }))}
									value=""
									onChange={(ev) => {
										const macro = ev.target.value;
										if (macro) {
											setTimeout(() => {
												this.editor.insertText(`{{${macro}}}`);
											});
										}
									}}
									fullWidth
									margin="normal"
								/>
							)}
							<HtmlEditor
								content={model.contentTemplateString}
								config={{ height: 600 }}
								events={{
									change: ({ editor }) => {
										model.contentTemplateString = editor.getData();
									},
									mode: ({ editor }) => {
										// Don't allow save in source mode, as change event is not fired when editing source.
										this.setState({ allowSave: editor.mode === 'wysiwyg' });
									},
									instanceReady: (ev) => {
										this.editor = ev.editor;
									},
								}}
							/>
						</Box>
					</Paper>
				</Grid>
				<Grid item>
					<ActionButton
						disabled={!this.state.allowSave}
						label="Ok"
						color="primary"
						onClick={submit}
					/>
				</Grid>
				<Grid item>
					<ActionButton
						label="Cancel"
						onClick={() => onCancel()}
						variant="text"
						color="primary"
					/>
				</Grid>
			</>
		);
	}
}

DocumentTemplateEdit.propTypes = {
	model: PropTypes.object.isRequired,
	types: PropTypes.array.isRequired,
	field: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
};

DocumentTemplateEdit.defaultProps = {
};

export default DocumentTemplateEdit;
