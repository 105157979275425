import {
	initStore, invalidateStore, RootModelNames, AllModelNames, getInstance,
} from 'relevant-shared/objects/objectStore';
import Mutex from 'relevant-shared/misc/mutex';
import classes from '../api/classes';

// To be supplied to ObjectStore.initStore()
// This acts like a "poor-mans version" of ModelCache in backend. There is a .byId object for every model that is
// updated whenever objects are created. This includes Site/Publisher and objects that are not saved in backend yet.
// Purpose is if you for example have a "Site select" setting - then it should be possible to select
// a site that was just created on the Publisher-page without saving, etc.
const modelCollections = {};

// Collects all models that have been .list()'ed without any query-parameters (which means we have all available)
const modelsReady = {};

AllModelNames.forEach((clsName) => {
	const cls = classes[clsName];
	const byId = {};
	cls.models = { byId };
	modelCollections[clsName] = cls.models;
	cls.events.onCreated.add((obj) => {
		byId[obj.id] = obj;
		invalidateStore();
	});
	cls.events.onDeleted.add((obj) => {
		delete byId[obj.id];
		invalidateStore();
	});
	cls.events.onAllListed.add(() => {
		modelsReady[clsName] = true;
	});
});

initStore(modelCollections);
const loadMutex = new Mutex();

// Ensures that ObjectStore contains all objects, by .list()'ing them when needed
export async function ensureLoaded() {
	const nonLoaded = () => RootModelNames.filter((name) => !modelsReady[name]).map((name) => classes[name]);
	if (nonLoaded().length) {
		await loadMutex.run(async () => {
			const toLoad = nonLoaded();
			if (toLoad.length) { // check again as someone else might have been quicker
				await Promise.all(toLoad.map((cls) => cls.list()));
			}
		});
	}
	getInstance(); // "Force" a new instance that will set static arrays (AllModelNames, etc)
}
