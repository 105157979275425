import {
	Box, Card, CardActionArea, Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useRef } from 'react';
import Constants from 'relevant-shared/reportData/constants';
import classes from '../../api/classes';
import { demoMessage, isDemoUser } from '../DemoImport/utils';
import Report from '../Report';
import getReportPreviewImage from '../ReportsOverview/utils';
import { ReportTemplateCard } from './ReportTemplateCard';
import { ReportTemplateSettings } from './ReportTemplateSettings';
import PopupSelector from '../PopupSelector';
import SimpleOperationWrapper from '../SimpleOperationWrapper';
import useRequest from '../../hooks/useRequest/useRequest';
import { reportUrlFromSettings, maybeCopyToDashboard } from './utils';
import DateUtils from '../../lib/dateUtils';
import { useToast } from '../../hooks/useToast';

const { ReportTemplate } = classes;

export function ReportTemplates({
	templates: templatesInit,
	onClick,
	showHidden,
	type,
	copyToDashboard,
}) {
	const [templates, setTemplates] = useState(templatesInit);
	const [editing, setEditing] = useState(null);
	const templateSettings = useRef({});
	const [settingsOpen, setSettingsOpen] = useState(false);
	const reportComponentRef = useRef(null);
	const [, showToast] = useToast();

	const filteredTemplates = templates
		.filter((t) => (type === t.settings.type) && !(t.hidden && showHidden === false))
		.sort((a, b) => a.settings.title.localeCompare(b.settings.title)); // Sorted alphabetically for now

	const handleDelete = (id) => (async () => {
		if (isDemoUser()) {
			demoMessage();
			return;
		}
		try {
			const allTemplates = await ReportTemplate.deleteTemplate({ id });
			ReportTemplate.fnCache.reset();
			setTemplates(allTemplates);
			showToast({ message: 'Report template deleted.', status: 'success', timeout: 3 });
		} catch (e) {
			showToast({ message: 'Failed to delete report template', status: 'error' });
		}
	});

	const handleRestore = (id) => (async () => {
		if (isDemoUser()) {
			demoMessage();
			return;
		}
		try {
			const allTemplates = await ReportTemplate.restoreTemplate({ id });
			ReportTemplate.fnCache.reset();
			setTemplates(allTemplates);
			showToast({ message: 'Report template restored.', status: 'success', timeout: 3 });
		} catch (e) {
			showToast({ message: 'Failed to restore report template', status: 'error' });
		}
	});

	const handleCopyToDashboard = (id) => () => maybeCopyToDashboard({ id });

	const handleEdit = useCallback((id) => (async () => {
		if (isDemoUser()) {
			demoMessage();
			return;
		}
		setEditing(templates.find((t) => t.id === id));
	}), [templates]);

	const [setSaveRequest, saveLoading, saveError, saveReset] = useRequest();
	const handleSave = useCallback((template) => (async ({ preventDefault }) => {
		preventDefault();
		const { isNew } = template;
		setSaveRequest({
			requestFunction: async () => {
				const settings = reportComponentRef.current.getCurrentProperties();
				Object.assign(template, { settings, ...templateSettings.current });
				await ReportTemplate.save(template, { updateUsingDiff: true });
				ReportTemplate.fnCache.reset();
			},
			requestCallback: () => {
				if (isNew) {
					setTemplates([...templates, template]);
				} else {
					setTemplates([...templates]);
				}
				setEditing(null);
			},
		});
	}), [setSaveRequest, setEditing, setTemplates, templates]);

	const handleNew = useCallback(() => {
		if (isDemoUser()) {
			demoMessage();
			return;
		}
		setEditing(new ReportTemplate({
			settings: {
				type,
				showTable: true,
				start: DateUtils.fullDay(new Date(), -31),
				end: DateUtils.fullDay(new Date(), -1),
				...Constants[type]?.getDefaultProperties?.(false),
			},
		}));
	}, [type]);

	return (
		<>
			<div style={{ width: '100%' }}>
				<div style={{
					display: 'grid',
					gap: 15,
					gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
				}}>
					{ filteredTemplates.map(({
						id,
						description,
						settings,
						hidden,
						isAdminTemplate,
						isUserTemplate,
						isReportTemplate,
						isDashboardTemplate,
						userId,
					}) => (
						<Box key={id}>
							<ReportTemplateCard
								id={id}
								title={settings.title}
								description={description}
								isPublic={!userId}
								isHidden={hidden}
								isAdminTemplate={isAdminTemplate}
								isUserTemplate={isUserTemplate}
								isReportTemplate={isReportTemplate}
								isDashboardTemplate={isDashboardTemplate}
								link={reportUrlFromSettings(settings)}
								image={getReportPreviewImage(
									settings.showPieChart,
									settings.showTable,
									settings.showChart,
									settings.groupBy,
								)}
								onClick={onClick}
								onDelete={handleDelete(id)}
								onRestore={handleRestore(id)}
								onCopyToDashboard={copyToDashboard ? handleCopyToDashboard(id) : undefined}
								onEdit={handleEdit(id)}
							/>
						</Box>
					)) }

					{/* "New" button */}
					<Box sx={{ height: '100%' }}>
						<Card
							variant="outlined"
							sx={{ border: '1px solid #E0E0E0', height: '100%' }}
							onClick={handleNew}
						>
							<CardActionArea sx={{ p: '13px', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<Add sx={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: 55 }} />
							</CardActionArea>
						</Card>
					</Box>
				</div>
			</div>
			{(editing) && (
				<PopupSelector
					expanded
					size="lg"
					canCancel
					okLabel="Save"
					onStateUpdate={({ expanded }) => { if (expanded === false) setEditing(null); }}
					onApplyChanges={handleSave(editing)}
				>
					<SimpleOperationWrapper
						loading={saveLoading}
						error={saveError}
						onErrorModalClick={saveReset}
						hideOnError={false}
					>
						<Typography variant="h2" sx={{ mt: 2, mb: 1 }}>Report</Typography>
						<Report
							ref={reportComponentRef}
							settingsOpen={settingsOpen}
							onSettingsOpenToggle={(isOpen) => setSettingsOpen(isOpen)}
							{...editing.settings}
							isActualReport={false}
						/>

						<Typography variant="h2" sx={{ mt: 2, mb: 1 }}>Template Settings</Typography>
						<ReportTemplateSettings template={editing} onChange={(values) => templateSettings.current = { ...values }} />
					</SimpleOperationWrapper>
				</PopupSelector>
			)}
		</>
	);
}

ReportTemplates.propTypes = {
	templates: PropTypes.array.isRequired,
	onClick: PropTypes.func,
	showHidden: PropTypes.bool,
	copyToDashboard: PropTypes.bool,
	type: PropTypes.string.isRequired,
};

ReportTemplates.defaultProps = {
	onClick: () => {},
	showHidden: false,
	copyToDashboard: true,
};
