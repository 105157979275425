/** Ok, so this is not actually a "mutex" but it should work as long as there is only one node-process */
class Mutex {
	// keepMutex => only if there is no exception, hasMutex => will release mutex
	async run(fn, { keepMutex, hasMutex } = {}) {
		let noCleanup;
		try {
			if (!hasMutex) {
				while (this.mutex) {
					await this.mutex;
				}
				this.mutex = new Promise((r) => {
					this.resolver = r;
				});
			}
			const res = await fn();
			noCleanup = keepMutex;
			return res;
		} finally {
			if (!noCleanup) {
				this.mutex = null;
				const { resolver } = this;
				this.resolver = null;
				resolver();
			}
		}
	}

	// Run fn if condFn() is true, both before and after obtaining mutex
	// Purpose it to avoid these double-checks in the code (as condition might have changed after waiting mutex)
	async runIf(condFn, fn, ...rest) {
		if (condFn()) {
			await this.run(() => (condFn() ? fn() : undefined), ...rest);
		}
	}
}

module.exports = Mutex;
