import PropTypes from 'prop-types';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import styles from './styles.css';

const placeHolderText = 'Select dimension';

/**
 * Dense dropdown for use within tables
 */
function DimensionDropdown({ onChange, items, currentDimensionId }) {
	return (
		<FormControl>
			<Select
				name="dimension"
				value={currentDimensionId}
				inputProps={{ 'aria-label': placeHolderText }}
				className={styles.selector}
				onChange={onChange}
				// Variant: standard shouldn't really be used acc. to new Material Design guidelines
				// but other variants doesn't look very good in tables
				variant="standard"
				disableUnderline
			>
				{items.map((item) => (
					<MenuItem
						key={item.value}
						value={item.value}
					>
						{item.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

DimensionDropdown.propTypes = {
	onChange: PropTypes.func.isRequired,
	items: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	})).isRequired,
	currentDimensionId: PropTypes.string,
};

DimensionDropdown.defaultProps = {
	currentDimensionId: '',
};

export default DimensionDropdown;
