import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

function PieChartNotSupportedAlert({ children, suggestion, onFix }) {
	return (
		<Alert
			action={suggestion ? (
				<Button
					color="inherit"
					onClick={onFix}
					size="small"
				>
					{suggestion.toUpperCase()}
				</Button>
			) : undefined}
			severity="warning"
		>
			{children}
		</Alert>
	);
}

PieChartNotSupportedAlert.propTypes = {
	children: PropTypes.node.isRequired,
	onFix: PropTypes.func,
	suggestion: PropTypes.string,
};

PieChartNotSupportedAlert.defaultProps = {
	onFix: undefined,
	suggestion: undefined,
};

export default PieChartNotSupportedAlert;
