import PropTypes from 'prop-types';
import React from 'react';
// import styles from './styles.css';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

function linkable(Component) {
	class Linkable extends React.Component {
		render() {
			const transformedProps = _.merge({}, this.props);

			if (transformedProps.href) {
				transformedProps.onClick = () => this.props.history.push(this.props.href);
				delete transformedProps.href;
			}

			// Delete router props before passing on
			delete transformedProps.history;
			delete transformedProps.location;
			delete transformedProps.match;
			delete transformedProps.staticContext;

			return (
				<Component {...transformedProps} />
			);
		}
	}

	Linkable.propTypes = {
		href: PropTypes.string,
		history: PropTypes.object.isRequired,
	};

	Linkable.defaultProps = {
	};

	return withRouter(Linkable);
}

export default linkable;
