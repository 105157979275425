import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '../TextField';
import Select from '../Select';
import { GlobalSettingsObject } from '../../api/relevant';
import PopupSelector from '../PopupSelector';
import DataTable from '../DataTable';
import State from '../KitchenSink/State';

const VAR = 'maxMonthlyDataUsage';

const UNITS = ['bytes', 'KB', 'MB', 'GB', 'TB'];

const unitSz = (u) => {
	const idx = UNITS.indexOf(u);
	if (idx < 0) {
		throw Error(`Invalid unit '${u}'`);
	}
	return 1024 ** idx;
};

const toUnit = (bytes, preSelectedUnit) => {
	let unit;
	if (preSelectedUnit) {
		unit = preSelectedUnit;
	} else {
		const idx = _.findIndex(UNITS, (u) => unitSz(u) > bytes);
		unit = !idx ? UNITS[0] : UNITS[idx < 0 ? UNITS.length - 1 : idx - 1];
	}
	return { unit, num: parseFloat((bytes / unitSz(unit)).toFixed(2)) };
};

const colCode = (used, maxAllowed) => {
	if (!used && !maxAllowed) {
		return undefined;
	} if (!maxAllowed || used / maxAllowed < 0.5) {
		return 'success.main';
	} if (used / maxAllowed < 0.90) {
		return 'warning.main';
	}
	return 'error.main';
};

const toBytes = (unit, num) => Math.round(unitSz(unit) * num);

const elapsedOfMonth = (date) => {
	const now = date ? new Date(date) : new Date();
	const start = moment(now).startOf('month');
	const stop = moment(start).add(1, 'month');
	return (now.getTime() - start.toDate().getTime()) / (stop.toDate().getTime() - start.toDate().getTime());
};

class ApiUsageSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			unit: toUnit(props.model[VAR] || 0).unit,
		};
	}

	render() {
		const { unit, byMonth } = this.state;
		const { model, field } = this.props;
		const { apiUsageThisMonth } = model;
		const currentVal = model[VAR] || 0;
		const current = toUnit(currentVal, unit);
		const txtProps = Object.assign(field(VAR), {
			value: current.num + (this.endsWithDot ? '.' : ''),
		});
		const SPACE = Array(4).join('\xa0');
		const virtualLimit = currentVal * elapsedOfMonth();
		let trendPerc = 'Unknown';
		if (!currentVal) {
			trendPerc = 'No limit';
		} else if (virtualLimit || apiUsageThisMonth) {
			if (apiUsageThisMonth >= (virtualLimit * 10)) {
				trendPerc = 10;
			} else {
				trendPerc = apiUsageThisMonth / virtualLimit;
			}
			trendPerc = trendPerc >= 10 ? '1000%+' : `${(trendPerc * 100).toFixed(1)} %`;
		}
		return (
			<Card>
				<CardContent>
					<Grid container spacing={3} alignItems="center">
						<Grid item xs={12}>
							<Typography variant="h2">
								Api usage settings
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Monthly API cut access limit (0 = unlimited)"
								{...txtProps}
								float
								between={{ low: 0 }}
								filter={(val) => {
									this.endsWithDot = val.endsWith('.');
									return (parseFloat(val) >= 0 ? toBytes(unit, parseFloat(val)) : val);
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<Select
								name="unit"
								label="Unit"
								items={UNITS.map((u) => ({ label: u, value: u }))}
								value={unit}
								onChange={(ev) => this.setState({ unit: ev.target.value })}
								fullWidth
							/>
						</Grid>
						<Grid item>
							This month:
							<Box component="strong" color={colCode(apiUsageThisMonth, currentVal)}>
								{SPACE}
								{currentVal
									? `${toUnit(apiUsageThisMonth, current.unit).num} / ${current.num} ${current.unit}`
									: `${toUnit(apiUsageThisMonth, current.unit).num} ${current.unit} / Unlimited`}
							</Box>
							<br />
							Trend this month:
							<Box component="strong" color={colCode(apiUsageThisMonth, virtualLimit)}>
								{SPACE}
								{trendPerc}
							</Box>
						</Grid>
						<Grid item>
							{model.getMonthlyApiUsage && (
								<PopupSelector
									title="Recent statistics"
									fn={async () => this.setState({
										byMonth: await model.getMonthlyApiUsage({
											from: moment().subtract(1, 'year'),
											to: new Date(),
										}),
									})}
									size="xs"
								>
									{byMonth && (
										<DataTable
											showCheckboxes={false}
											definitions={[
												{
													key: 'month',
													title: 'Month',
													format: (month) => moment.utc(month).format('YYYY-MM'),
												},
												{
													key: 'bytes',
													title: 'API Usage',
													format: (bytes) => ((p) => `${p.num} ${p.unit}`)(toUnit(bytes, unit)),
												},
											]}
											identifier={(row) => row.month}
											data={_.sortBy(byMonth, (d) => -new Date(d.month).getTime())}
										/>
									)}
								</PopupSelector>
							)}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	}
}

ApiUsageSettings.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
};

ApiUsageSettings.defaultProps = {
};

export default ApiUsageSettings;
