import React, { useState, Fragment } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';
import { createCsv } from 'relevant-shared/misc/misc';
import DataTable from '../DataTable';
import BrowserUtils from '../../lib/browserUtils';
import { ActionButton } from '../ActionButton/ActionButton';

function UserList({ users, onClose }) {
	const [selectedUsers, setSelectedUsers] = useState(users);

	const onSelect = (selectedRowIndices) => {
		if (selectedRowIndices === 'all') {
			setSelectedUsers(users);
		} else if (selectedRowIndices === 'none') {
			setSelectedUsers([]);
		} else {
			setSelectedUsers(selectedRowIndices.map((i) => users[i]));
		}
	};

	const exportCsv = () => {
		const rows = [];
		selectedUsers.forEach((user) => {
			rows.push({
				name: user.name,
				email: user.email,
				instance: user.instances.map(({ name }) => name).join('\n'),
				lastLogin: user.instances.map(({ lastLogin }) => lastLogin).join('\n'),
			});
		});
		const csv = createCsv(rows, {
			email: 'Email',
			name: 'name',
			instance: 'Instance',
			lastLogin: 'Last login-prompt login',
		});
		BrowserUtils.downloadTextFile(csv, 'users.csv');
	};

	return (
		<>
			<DialogTitle>
				Users
			</DialogTitle>
			<DialogContent dividers>
				<DataTable
					selectableRows
					identifier={(obj) => obj._id}
					data={users}
					isSelected={({ _id }) => selectedUsers.find((user) => user._id === _id)}
					onRowSelection={onSelect}
					definitions={[
						{
							key: 'name',
							title: 'Name',
						},
						{
							key: 'email',
							title: 'Email',
						},
						{
							key: 'instances',
							title: 'Instance',
							format: (instances) => (
								instances.map(({ name }) => <div key={name}>{name}</div>)
							),
						},
						{
							key: 'instances',
							title: 'Last login-prompt login',
							format: (instances) => (
								instances.map(({ lastLogin }, index) => (
									<div key={`${lastLogin}${index}`}>{lastLogin === 'Unknown' ? <i>{lastLogin}</i> : lastLogin}</div>
								))
							),
						},
					]}
				/>
			</DialogContent>
			<DialogActions>
				<ActionButton
					label="Cancel"
					onClick={onClose}
					variant="text"
					color="primary"
				/>
				<ActionButton
					label="Export to CSV"
					color="primary"
					onClick={() => exportCsv()}
				/>
			</DialogActions>
		</>
	);
}

UserList.propTypes = {
	users: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string,
		_id: PropTypes.string,
		email: PropTypes.string,
		instance: PropTypes.string,
		lastLogin: PropTypes.string,
	})),
	onClose: PropTypes.func.isRequired,
};

UserList.defaultProps = {
};

export default UserList;
