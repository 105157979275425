import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ActionButton } from '../ActionButton/ActionButton';

const PREFIX = 'Modal';

const classes = {
	cancelButton: `${PREFIX}-cancelButton`,
};

const StyledDialog = styled(Dialog)(({
	theme: { palette },
}) => ({
	[`& .${classes.cancelButton}`]: {
		color: palette.link.main,
	},
}));

/**
 *
 * Modal component
 * (For now?) it only works when directly rendered, i.e usually when rendered as its own "page", with its own URL,
 * as it uses an internal open/close mechanism
 * In the future we might want to be able to control this properly from outside the component.
 * Potentially we could use the hook 'useContext' to allow both parent and this component
 * to update the same 'open' value
 * There's two ways of rendering content inside the modal,
 * either by using the children prop, or the renderContent() render prop.
 */
function Modal({
	title,
	dialogProps,
	dialogContentProps,
	open,
	parentPath,
	onSubmit: onSubmitCallback,
	onCancel,
	cancelButtonProps,
	submitButtonProps,
	renderContent,
	submittable,
	children,
}) {
	const [modalIsOpen, setModalIsOpen] = useState(open);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const onClose = () => {
		if (onCancel) {
			onCancel();
		}
		setModalIsOpen(false);
		if (parentPath) {
			history.push(parentPath);
		}
	};

	const onSubmit = async () => {
		if (onSubmitCallback) {
			// TOOD: can this be done some nicer way?
			const submitted = await onSubmitCallback();
			if (submitted) {
				setModalIsOpen(false);
				if (parentPath) {
					history.push(parentPath);
				}
			}
		} else {
			setModalIsOpen(false);
			if (parentPath) {
				history.push(parentPath);
			}
		}
	};

	const onLoadStateChange = useCallback((isLoading) => {
		setLoading(isLoading);
	}, []);

	const disabled = loading || !submittable;

	return (
		<StyledDialog
			{...dialogProps}
			open={modalIsOpen}
			onClose={onClose}
			onKeyPress={(e) => {
				if (submittable && e.key === 'Enter') {
					onSubmit();
				}
			}}
		>
			<DialogTitle>
				{ title }
			</DialogTitle>
			<DialogContent {...dialogContentProps}>
				{ renderContent({ onLoadStateChange })}
				{ children }
			</DialogContent>
			<DialogActions>
				<ActionButton
					className={classes.cancelButton}
					variant="text"
					onClick={onClose}
					{...cancelButtonProps}
				/>
				<ActionButton
					variant={disabled ? 'outlined' : 'contained'}
					disabled={disabled}
					color="primary"
					onClick={onSubmit}
					{...submitButtonProps}
				/>
			</DialogActions>
		</StyledDialog>
	);
}

Modal.propTypes = {
	title: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	dialogProps: PropTypes.objectOf(PropTypes.any),
	dialogContentProps: PropTypes.objectOf(PropTypes.any),
	parentPath: PropTypes.string,
	submittable: PropTypes.bool,
	renderContent: PropTypes.func,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
	cancelButtonProps: PropTypes.objectOf(PropTypes.any),
	submitButtonProps: PropTypes.objectOf(PropTypes.any),
	children: PropTypes.objectOf(PropTypes.any),
};

Modal.defaultProps = {
	dialogProps: {},
	dialogContentProps: {},
	parentPath: '',
	submittable: true,
	renderContent: () => null,
	onSubmit: () => undefined,
	onCancel: () => undefined,
	cancelButtonProps: { label: 'Cancel' },
	submitButtonProps: { label: 'Submit' },
	children: null,
};

export default Modal;
