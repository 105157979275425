import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import CopyableTextField from '../CopyableTextField';
import styles from './styles.css';

function StoredRequestTagInfo({
	prebidConfigs, placement, textFieldLabel, header, pbsAccountId,
}) {
	return (

		<div className={styles.container}>
			<Typography variant="h2">{ header }</Typography>
			<div>
				{ pbsAccountId && (
					<Box
						component="div"
						pb={0.5}
						pt={2}
					>
						<CopyableTextField
							textFieldProps={{
								readOnly: true,
								name: `textField_${pbsAccountId}`,
								value: `${pbsAccountId}`,
								label: 'Prebid Server Account ID',
							}}
						/>
					</Box>
				)}
				<Box component="h4" mt={1.5}>Tags</Box>
				{ prebidConfigs.map(({ name, id }) => (
					<Box
						component="div"
						pb={2}
						key={`${id}_${placement.id}`}
					>
						<CopyableTextField
							textFieldProps={{
								readOnly: true,
								name: `textField_${id}`,
								value: `${id}_${placement.id}`,
								label: textFieldLabel,
								helperText: `${name} - ${placement.name}`,
							}}
						/>
					</Box>
				))}
			</div>
		</div>
	);
}

export default StoredRequestTagInfo;

StoredRequestTagInfo.propTypes = {
	prebidConfigs: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
	})).isRequired,
	placement: PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
	}).isRequired,
	textFieldLabel: PropTypes.string.isRequired,
	header: PropTypes.string.isRequired,
	pbsAccountId: PropTypes.string,
};

StoredRequestTagInfo.defaultProps = {
	pbsAccountId: undefined,
};
