import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import _ from 'lodash';

export default function MultiSelect({
	value,
	options,
	label,
	onChange,
	renderOption,
	renderValue,
	MenuProps,
	SelectProps,
	FormControlProps,
	children
}) {
	const [isOpen, setIsOpen] = useState(false);
	const [labelId] = useState(_.uniqueId('MultiSelect-'));

	const handleChange = ({ target }) => {
		const updated = [...target.value];
		onChange(updated);
	};

	return (
		<FormControl {...FormControlProps}>
			<InputLabel id={labelId}>{ label }</InputLabel>
			<Select
				open={isOpen}
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
				multiple
				value={value}
				onChange={handleChange}
				labelId={labelId}
				label={label}
				renderValue={renderValue ?? ((selected) => (
					<div>
						{selected.map((option) => (
							<Chip key={option.value} label={option.label} style={{ height: 'auto' }} />
						))}
					</div>
				))}
				MenuProps={MenuProps}
				{...SelectProps}
			>
				{ children ?? options.map((option) => (
					renderOption
						? renderOption(option, { onClick: () => setIsOpen(false) })
						:							(
							<MenuItem onClick={() => setIsOpen(false)} key={option.value} value={option.value}>
								{ option.label }
							</MenuItem>
						)
				))}
			</Select>
		</FormControl>
	);
}

MultiSelect.propTypes = {
	value: PropTypes.arrayOf(PropTypes.any),
	options: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.any.isRequired,
	})).isRequired,
	onChange: PropTypes.func,
	renderValue: PropTypes.func, // null to use default Select rendering, undefined for Chip style rendering, or a function for custom rendering
	renderOption: PropTypes.func,
	MenuProps: PropTypes.objectOf(PropTypes.any),
	SelectProps: PropTypes.objectOf(PropTypes.any),
	FormControlProps: PropTypes.objectOf(PropTypes.any),
	label: PropTypes.string.isRequired,
};

MultiSelect.defaultProps = {
	value: [],
	MenuProps: {},
	SelectProps: {},
	FormControlProps: {},
	renderOption: null,
	renderValue: undefined,
	onChange: undefined,
};
