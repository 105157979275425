import React from 'react';
import useRequest from './useRequest';
/**
* Wrap useRequest hook into a HOC, to be able to use in class-based components * */
function hookWrapper(Component) {
	return function WrappedComponent(props) {
		const [setRequest, loading, error, reset] = useRequest({});
		return <Component {...props} setRequest={setRequest} loading={loading} error={error} reset={reset} />;
	};
}

export default hookWrapper;
