import React from 'react';
import Grid from '@mui/material/Grid';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PreviewIcon from '@mui/icons-material/OpenWith';
import AutoEnableIcon from '@mui/icons-material/BrightnessAuto';
import AutoDisableIcon from '@mui/icons-material/BrightnessAutoOutlined';
import ReTriggerEnableIcon from '@mui/icons-material/OfflineBolt';
import ReTriggerDisableIcon from '@mui/icons-material/OfflineBoltOutlined';
import { Trigger, TriggerReportRun } from '../../api/relevant';
import Base from '../../layouts/Base';
import Report from '../Report';
import PopupSelector from '../PopupSelector';
import { isAcked, isFailure } from './utils';
import TriggerReport from './triggerReport';

export const TriggerActions = [
	{
		name: 'Check now',
		noConfirm: true,
		multiFn: ({ triggers, workspace }) => workspace.runTriggers({
			triggerIds: triggers.map((t) => t.id),
		}),
		icon: PlayIcon,
	},
	{
		name: 'Edit',
		cond: ({ trigger }) => !trigger.isSystemCheck,
		fn: ({ trigger, workspace }) => Base.renderGlobal((closeFn) => (
			<TriggerReport
				closeFn={closeFn}
				trigger={trigger}
				workspace={workspace}
			/>
		)),
		icon: EditIcon,
		hideInTop: true,
		noConfirm: true,
		noReload: true,
	},
	{
		name: 'Activate',
		cond: ({ trigger }) => !trigger.active,
		fn: ({ trigger }) => trigger.update({ active: true }),
		icon: AlarmOnIcon,
	},
	{
		name: 'Deactivate',
		cond: ({ trigger }) => trigger.active,
		fn: ({ trigger }) => trigger.update({ active: false }),
		icon: AlarmOffIcon,
	},
	{
		name: 'Enable re-trigger',
		cond: ({ trigger }) => !trigger.reTriggerMode,
		fn: ({ trigger }) => trigger.update({ reTriggerMode: true }),
		icon: ReTriggerEnableIcon,
	},
	{
		name: 'Disable re-trigger',
		cond: ({ trigger }) => trigger.reTriggerMode,
		fn: ({ trigger }) => trigger.update({ reTriggerMode: false }),
		icon: ReTriggerDisableIcon,
	},
	{
		name: 'Delete',
		cond: ({ trigger, workspace }) => !trigger.isSystemCheck && workspace.getOwnership(trigger).type !== 'shared',
		fn: ({ trigger }) => trigger.delete(),
		icon: DeleteIcon,
	},
	{
		name: 'Unsubscribe',
		cond: ({ trigger, workspace }) => workspace.getOwnership(trigger).type === 'shared',
		fn: ({ trigger }) => trigger.unsubscribe(),
		icon: DeleteOutlinedIcon,
		noSharedWarn: true,
	},
];

export const CheckActions = [
	{
		name: 'Check now',
		noConfirm: true,
		multiFn: ({ checks, workspace }) => workspace.runTriggers({
			checkIds: checks.map((c) => c.id),
		}),
		icon: PlayIcon,
	},
	{
		name: 'View report',
		fn: ({ check, workspace }) => workspace.op.reload(async () => {
			const reportRun = await TriggerReportRun.call('getReportRun', { id: check.reportRunId });
			if (!reportRun) {
				throw Error('Report is missing');
			}
			Base.renderGlobal((closeFn) => (
				<PopupSelector
					expanded
					size="lg"
					onStateUpdate={({ expanded }) => expanded === false && closeFn()}
					content={() => (
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Report
									{...({
										...reportRun.reportSettings,
										trigger: workspace.triggerOf(check),
									})}
								/>
							</Grid>
						</Grid>
					)}
				/>
			));
		}),
		historyCompatible: true,
		cond: ({ check }) => check.reportRunId,
		icon: PreviewIcon,
		hideInTop: true,
		noConfirm: true,
		noReload: true,
	},
	{
		name: 'Acknowledge',
		cond: ({ check }) => check.status === 'failed' && !isAcked(check),
		multiFn: ({ checks }) => Trigger.call('updateChecks', {
			checkUpdates: checks.map(({ id }) => ({ id, ackState: 'ack' })),
		}),
		icon: AlarmOffIcon,
	},
	{
		name: 'Unacknowledge',
		cond: ({ check }) => isAcked(check),
		multiFn: ({ checks }) => Trigger.call('updateChecks', {
			checkUpdates: checks.map(({ id }) => ({ id, ackState: 'none' })),
		}),
		icon: AlarmOnIcon,
	},
	{
		name: 'Enable auto-ack',
		cond: ({ check }) => !check.autoAck && isFailure(check),
		multiFn: ({ checks }) => Trigger.call('updateChecks', {
			checkUpdates: checks.map(({ id }) => ({ id, autoAck: true, ackState: 'ack' })),
		}),
		icon: AutoEnableIcon,
	},
	{
		name: 'Disable auto-ack',
		cond: ({ check }) => check.autoAck,
		multiFn: ({ checks }) => Trigger.call('updateChecks', {
			checkUpdates: checks.map(({ id }) => ({ id, autoAck: false })),
		}),
		icon: AutoDisableIcon,
	},
];
