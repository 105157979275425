import PropTypes from 'prop-types';
import React from 'react';
import OperationWrapper from '../OperationWrapper';

class InlineBidParamContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			fn, content, children, wrapperProps,
		} = this.props;
		return (
			<OperationWrapper
				fn={fn}
				content={content}
				style={{ width: '100%' }}
				{...wrapperProps}
			>
				{children}
			</OperationWrapper>
		);
	}
}

InlineBidParamContainer.propTypes = {
	fn: PropTypes.func,
	content: PropTypes.func,
	wrapperProps: PropTypes.object,
};

InlineBidParamContainer.defaultProps = {
	fn: () => {},
	content: undefined,
	wrapperProps: undefined,
};

export default InlineBidParamContainer;
