import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import useSaveReport from './useSaveReport';
import useDeleteReport from './useDeleteReport';
import useShareReport from './useShareReport';
import { stores } from '../../../stores';
import { Reports } from '../../../api/relevant';
import useReports from '../../../hooks/useReports';
import classes from '../../../api/classes';
import Base from '../../../layouts/Base';
import PopupSelector from '../../PopupSelector';
import { ReportTemplateSettings } from '../../ReportTemplates/ReportTemplateSettings';
import { maybeCopyToDashboard } from '../../ReportTemplates/utils';

const { ReportTemplate } = classes;

function useReportActions({
	title,
	isPrivate,
	noSave,
	pathname,
	report,
	setTopbarSettings,
	setTitle,
	shareUrl,
	type,
	pinnedByUsers,
	reportComponentRef,
	isNew,
	setIsPrivate,
	setStatus,
	setSettingsOpen,
	setShareUrl,
	dynamicDateRange,
}) {
	const currentUser = stores.identity.user();
	const [subscriptions, setSubscriptions] = useState(report?.subscriptions || []);
	const [pinnedToSidebar, setPinnedToSidebar] = useState(pinnedByUsers.includes(currentUser.id));
	const { updateReports } = useReports();
	const onSave = useSaveReport({
		isPrivate,
		report,
		reportComponentRef,
		setStatus,
		type,
		subscriptions,
		dynamicDateRange,
	});

	const history = useHistory();
	const template = useRef(null);
	const onSaveTemplate = useCallback(async () => {
		const settings = reportComponentRef.current.getCurrentProperties();
		template.current = new ReportTemplate({
			settings,
		});

		Base.renderGlobal((close) => (
			<PopupSelector
				forceExpanded
				title="Template Settings"
				onApplyChanges={async () => {
					await ReportTemplate.save(template.current);
					ReportTemplate.fnCache.reset();
					history.push('/settings/report-templates');
					close();
				}}
				onCancel={close}
				content={() => (
					<ReportTemplateSettings
						template={template.current}
						onChange={(values) => {
							Object.assign(template.current, values);
						}}
					/>
				)}
			/>
		));
	}, [reportComponentRef, history]);

	const onCopyToDashboard = useCallback(async () => {
		const settings = reportComponentRef.current.getCurrentProperties();
		await maybeCopyToDashboard({ settings });
	}, [reportComponentRef]);

	const onDelete = useDeleteReport({
		pathname,
		reportId: report?.id,
		setStatus,
	});

	const onShareClick = useShareReport({
		reportComponentRef,
		setShareUrl,
		type,
	});

	useEffect(
		() => {
			setTitle(title);
			setTopbarSettings({
				type: 'report',
				isPublic: isPrivate === false,
				shareUrl,
				...(!noSave && { onSave }),
				onSaveTemplate,
				onCopyToDashboard,
				onDelete: report?.id && onDelete,
				onSettingsClick: () => setSettingsOpen(true),
				onShareClick,
				onIsPublicChange: () => {
					setIsPrivate(!isPrivate);
				},
				...(!isNew && {
					onPinClick: async () => {
						const updatedPinned = !pinnedToSidebar;
						try {
							await Reports.call('updatePinnedToSidebar', {
								reportIds: [report?.id],
								pinned: updatedPinned,
							});
							setPinnedToSidebar(updatedPinned);
							updateReports();
						} catch (err) {
						// Revert if it fails
							setPinnedToSidebar(!updatedPinned);
						}
					},
				}),
				pinnedToSidebar,
				subscriptions,
				// Can't simply look at report.settings sadly,
				// because of the way stuff is done in reports.
				hasDynamicDateRange: () => {
					const settings = reportComponentRef.current.getCurrentProperties();
					return Boolean(settings.dynamicDateRange)
						|| (!Number.isNaN(settings.start) && settings.start < 0)
						|| (!Number.isNaN(settings.end) && settings.end < 0);
				},
				onSubscriptionsChange: (updatedSubscriptions) => {
					setSubscriptions(updatedSubscriptions);
				},
			});
		},
		[
			isPrivate,
			noSave,
			onDelete,
			onSave,
			onShareClick,
			onSaveTemplate,
			onCopyToDashboard,
			subscriptions,
			shareUrl,
			title,
			type,
			reportComponentRef,
			pinnedToSidebar,
			report?.id,
			updateReports,
			isNew,
			setSubscriptions,
			setIsPrivate,
			setTopbarSettings,
			setSettingsOpen,
			setTitle,
		],
	);
}

export default useReportActions;
