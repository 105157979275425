import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ReactUtils from '../../lib/reactUtils';
import ExpandSelector from '../ExpandSelector';
import Base from '../../layouts/Base';
import MiscUtils from '../../lib/miscUtils';
import { ConfirmDialog } from '../ConfirmDialog';
import { Dialog } from '../Dialog';

const swap = (arr, i1, i2) => {
	const tmp = arr[i1];
	arr[i1] = arr[i2];
	arr[i2] = tmp;
};

class FieldArray extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderWithNormal(content, header, title) {
		return (
			<Card>
				<CardContent>
					<Typography variant="h2">
						{title}
						{header}
					</Typography>
					{content}
				</CardContent>
			</Card>
		);
	}

	renderWithExpand(content, header, title, elm) {
		const {
			form, expandStyle, shouldAutoExpand,
		} = this.props;
		return (
			<ExpandSelector
				title={title}
				customHeader={header}
				form={form}
				style={expandStyle}
				expanded={shouldAutoExpand?.(elm)}
			>
				{content}
			</ExpandSelector>
		);
	}

	render() {
		const {
			model, field, name, render: renderFn, update: updateFn, createNew, createNewArray,
			noSwapBtns, expandableElements, beforeHeader, afterHeader, deleteBtnColor, deleteBtnText,
			canDelete, onDeleted, addBtnColor, addBtnText, confirmDelete, noAutoAddRemove,
		} = this.props;
		const update = (fn) => (() => {
			try {
				fn();
				updateFn(this);
			} catch (e) {
				Base.renderGlobal((done) => (
					<Dialog
						open
						status="error"
						text={MiscUtils.errorMsg(e)}
						onClose={done}
					/>
				));
			}
		});
		const renderer = (content, header, title, elm) => (
			expandableElements ? this.renderWithExpand(content, header, title, elm)
				: this.renderWithNormal(content, header, title)
		);
		return (
			<div>
				{ReactUtils.fldMap(model, field, name, (elm, fld, idx, arr) => (
					<Box key={`fld_${idx}`} marginBottom={3}>
						{renderer(
							renderFn(fld, elm, idx, arr), (
								<div style={{ display: 'flex ' }}>
									{beforeHeader && (
										<div style={{ flexGrow: 1 }}>
											{beforeHeader(fld, elm, idx, arr)}
										</div>
									)}
									<div>
										{(!canDelete || canDelete(elm)) && (
											<Tooltip title={deleteBtnText}>
												<IconButton
													onClick={async () => {
														if (confirmDelete) {
															const ok = await Base.renderGlobal((closeFn) => (
																<ConfirmDialog
																	open
																	text={confirmDelete}
																	onAny={closeFn}
																/>
															));
															if (!ok) {
																return;
															}
														}
														if (onDeleted) {
															onDeleted(elm);
														}
														update(() => !noAutoAddRemove && arr.splice(idx, 1))();
													}}
													size="large"
												>
													<DeleteIcon color={deleteBtnColor} />
												</IconButton>
											</Tooltip>
										)}
										{!noSwapBtns && idx !== 0 && (
											<IconButton onClick={update(() => swap(arr, idx, idx - 1))} size="large">
												<UpIcon />
											</IconButton>
										)}
										{!noSwapBtns && idx < arr.length - 1 && (
											<IconButton onClick={update(() => swap(arr, idx, idx + 1))} size="large">
												<DownIcon />
											</IconButton>
										)}
									</div>
								</div>
							), afterHeader && afterHeader(fld, elm, idx, arr),
							elm,
						)}
					</Box>
				))}
				<Tooltip title={addBtnText}>
					<IconButton
						onClick={update(() => {
							if (!model[name]) {
								model[name] = createNewArray();
							}
							const obj = createNew();
							if (!noAutoAddRemove) {
								model[name].push(obj);
							}
						})}
						size="large"
					>
						<AddIcon color={addBtnColor} />
					</IconButton>
				</Tooltip>
			</div>
		);
	}
}

FieldArray.propTypes = {
	model: PropTypes.object.isRequired,
	form: PropTypes.object,
	field: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	render: PropTypes.func,
	update: PropTypes.func.isRequired,
	createNew: PropTypes.func,
	createNewArray: PropTypes.func,
	noSwapBtns: PropTypes.bool,
	expandableElements: PropTypes.bool,
	afterHeader: PropTypes.func,
	beforeHeader: PropTypes.func,
	deleteBtnColor: PropTypes.string,
	deleteBtnText: PropTypes.string,
	canDelete: PropTypes.func,
	onDeleted: PropTypes.func,
	addBtnColor: PropTypes.string,
	addBtnText: PropTypes.string,
	expandStyle: PropTypes.object,
	expanded: PropTypes.object,
	confirmDelete: PropTypes.string,
	noAutoAddRemove: PropTypes.bool,
};

FieldArray.defaultProps = {
	form: undefined,
	createNew: () => ({}),
	createNewArray: () => [],
	render: () => {},
	noSwapBtns: false,
	expandableElements: false,
	beforeHeader: undefined,
	afterHeader: undefined,
	deleteBtnColor: undefined,
	deleteBtnText: 'Delete',
	canDelete: undefined,
	addBtnColor: undefined,
	addBtnText: 'Add new',
	expandStyle: undefined,
	confirmDelete: undefined,
	expanded: null,
	onDeleted: undefined,
	noAutoAddRemove: false,
};

export default FieldArray;
