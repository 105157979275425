import React from 'react';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import styles from './styles.css';
import { ActionButton } from '../ActionButton/ActionButton';
import { drawerWidth } from '../Sidebar/config';
import { stores } from '../../stores';

function GhostingHeader() {
	const history = useHistory();

	return (
		<Box
			bgcolor="secondary.main"
			color="secondary.contrastText"
			sx={{
				width: drawerWidth,
				right: 0,
				p: 2,
			}}
			className={styles.header}
		>
			<div className={styles.sticky}>
				<ActionButton
					label="Exit"
					onClick={() => {
						stores.identity.stopGhosting();
						history.push('/accounts');
					}}
					className="inline-flex"
				/>
				<Box
					component="span"
					paddingLeft={2}
				>
					Ghosting mode
				</Box>
			</div>
		</Box>
	);
}

export default GhostingHeader;
