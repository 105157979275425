import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Divider,
} from '@mui/material';
import SubscriptionForm from './SubscriptionForm';
import SubscriptionList from './SubscriptionList';

function SubscriptionsEditor({ subscriptions, hasDynamicDateRange, onSubscriptionsChange }) {
	const handleAddSubscription = (newSubscription) => {
		// Add temporary id to avoid problems in rendering list (need a unique key)
		newSubscription.id = `temp-${subscriptions.length + 1}`;
		const updatedSubscriptions = [...subscriptions, newSubscription];
		onSubscriptionsChange(updatedSubscriptions);
	};

	const handleDeleteSubscription = (subscriptionListIndex) => {
		const updatedSubscriptions = [...subscriptions];
		updatedSubscriptions.splice(subscriptionListIndex, 1);
		onSubscriptionsChange(updatedSubscriptions);
	};

	return (
		<Box sx={{
			pt: 3,
			pb: 3,
			pl: 2,
			pr: 2,
		}}
		>
			<SubscriptionList
				subscriptions={subscriptions}
				onDeleteSubscription={handleDeleteSubscription}
			/>
			<Divider />
			<Box sx={{ mt: 1 }}>
				<SubscriptionForm
					hasDynamicDateRange={hasDynamicDateRange}
					onAddSubscription={handleAddSubscription}
				/>
			</Box>
		</Box>
	);
}

export default SubscriptionsEditor;

SubscriptionsEditor.propTypes = {
	subscriptions: PropTypes.arrayOf(PropTypes.shape({
		recipients: PropTypes.arrayOf(PropTypes.shape({
			userId: PropTypes.string.isRequired,
		})).isRequired,
		occurences: PropTypes.arrayOf(PropTypes.shape({
			type: PropTypes.oneOf(['daily', 'weekly', 'monthly', 'yearly']).isRequired,
		})).isRequired,
	})),
	hasDynamicDateRange: PropTypes.func.isRequired,
	onSubscriptionsChange: PropTypes.func.isRequired,
};

SubscriptionsEditor.defaultProps = {
	subscriptions: [],
};
