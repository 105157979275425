import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { drawerWidth } from '../../components/Sidebar/config';
import useTitle from '../../hooks/useTitle';

/**
 * Returns wrapped component while updating document.title with the title prop
 * Also makes sure there's margin from the sidebar.
 * */
export default function Page({ title, children }) {
	const setTitle = useTitle();

	useEffect(() => {
		if (title) {
			setTitle(title);
		}
	}, [setTitle, title]);

	return (
		<Box
			sx={{
				ml: { md: `${drawerWidth}px` },
				pl: 2,
				pr: 2,
				pb: 3,
			}}
			className="bg-neutral-page"
		>
			{ children }
		</Box>
	);
}

Page.propTypes = {
	title: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

Page.defaultProps = {
	title: undefined,
};
