import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { stores } from '../../stores';
import { Link } from '../Link/Link';
import { Button } from '../Button/Button';

function UserOptions({ onSettingsClick, displaySettingsButton }) {
	const linkButtonProps = {};
	if (onSettingsClick) {
		linkButtonProps.onClick = onSettingsClick;
	}

	return (
		<div>
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				pl: 2,
				pr: 2,
			}}
			>
				<AccountCircleIcon sx={{
					fontSize: '56px',
					color: 'info.main',
					mb: 1,
				}}
				/>
				<Box sx={{ color: 'text.secondary' }}>{stores.identity.name()}</Box>
				<Box sx={{ color: 'text.sub' }}>{stores.identity.email()}</Box>
			</Box>
			<Box sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: displaySettingsButton ? 'space-between' : 'center',
				mt: 3,
			}}
			>
				{ displaySettingsButton && (
					<Link
						style={{ whiteSpace: 'nowrap', display: 'flex' }}
						to="/user"
						{...linkButtonProps}
					>
						Settings
					</Link>
				)}
				<Button
					onClick={() => stores.identity.logout()}
				>
					Logout
				</Button>
			</Box>
		</div>
	);
}

export default UserOptions;

UserOptions.propTypes = {
	onSettingsClick: PropTypes.func,
	displaySettingsButton: PropTypes.bool,
};
UserOptions.defaultProps = {
	onSettingsClick: undefined,
	displaySettingsButton: true,
};
