import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useFilters from '../context/hooks/useFilters';

function SearchFilter() {
	const { onSearchChange } = useFilters();
	return (
		<TextField
			variant="filled"
			sx={{
				width: '400px',
				mb: 4,
			}}
			onChange={onSearchChange}
			label="Search by name"
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
			}}
		/>
	);
}

export default SearchFilter;
