const BySsp = {
	AdformSsp: {
		adserver: 'AdformAdserver',
		transform: ({ sspArr }) => ({ placementId: sspArr.map((s) => s.id).join('\n') }),
	},
	AppnexusSsp: {
		adserver: 'AppnexusAdserver',
		transform: ({ sspArr }) => ({ placementId: sspArr.map((s) => s.id).join('\n') }),
	},
	DFPSsp: {
		adserver: 'DfpAdserver',
		transform: ({ sspArr }) => ({ lineItemId: sspArr.map((s) => s.id).join('\n') }),
	},
};

class SysMapper {
	static mapperFor(ssp, adserver) {
		if (!ssp || !adserver) {
			return null;
		}
		const mapper = BySsp[ssp.type];
		if (!mapper || mapper.adserver !== adserver.type) {
			return null;
		}
		return mapper;
	}

	static mapPublisher({
		pub, onlyNew, fromSsp, toAdserver, overwrite,
	}) {
		const mapper = this.mapperFor(fromSsp, toAdserver);
		const edited = [];
		delete pub.__wasMapped;
		(pub.websites || []).forEach((site) => {
			delete site.__wasMapped;
			(site.placements || []).forEach((placement) => {
				if (placement.__wasMapped) {
					delete placement.__wasMapped;
					placement.adservers = [];
				}
				if (!mapper || ((placement.adservers || []).length && !overwrite)) {
					return;
				}
				const mySspPlacs = (placement.ssps || []).filter((s) => s.source === fromSsp.id);
				if (!mySspPlacs.length || (onlyNew && !mySspPlacs.find((s) => s.isNew))) {
					return;
				}
				const settings = mapper.transform({
					sspArr: mySspPlacs,
				});
				if (!settings) {
					return;
				}
				Object.assign(placement, {
					adservers: [{ adserverId: toAdserver.id, settings }],
					__wasMapped: true,
				});
				site.__wasMapped = true;
				pub.__wasMapped = true;
				edited.push(placement);
			});
		});
		return edited;
	}
}

export default SysMapper;
