exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".container__src-components-CopyableTextField-styles__3qChA {\n    display: flex;\n    align-items: center;\n}\n\n.textFieldInput__src-components-CopyableTextField-styles__11SDC {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n\n.copyIcon__src-components-CopyableTextField-styles__2o-3p {\n    cursor: pointer;\n    margin-left: 4px;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/digitalenterprises/temp/relevant-yield/relevant-frontend/src/components/CopyableTextField/styles.css"],"names":[],"mappings":"AAAA;IACI,cAAa;IACb,oBAAmB;CACvB;;AAEA;IACI,oBAAmB;IACnB,wBAAuB;CAC3B;;AAEA;IACI,gBAAe;IACf,iBAAgB;CACpB","file":"styles.css","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n}\n\n.textFieldInput {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n\n.copyIcon {\n    cursor: pointer;\n    margin-left: 4px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "container__src-components-CopyableTextField-styles__3qChA",
	"textFieldInput": "textFieldInput__src-components-CopyableTextField-styles__11SDC",
	"copyIcon": "copyIcon__src-components-CopyableTextField-styles__2o-3p"
};