const _ = require('lodash');
const DiffObjRange = require('./diffObjRange');
const DiffFns = require('./diffFns');
const { ARRAY_DIFF_MARKER, MODE } = require('./diffConstants');
const EnvReportInterface = require('../../reportData/envReportInterface');

// Helper function for special-cases when a new object turns out to exist already or changes have been made to an
// object that has been removed. Only used for custom identifiers where the same "id" might resurface. That is
// the case for classes that are using '.identifierFields' - but not when the MongoDb id is the identifier.
const createEmptyObjForDiffRepurpose = ({ type, nonObjs }) => {
	if (nonObjs?.length) {
		// Extremely hairy special-case that won't happen anyway, let's throw an exception..
		throw Error('Arrays of objects using .identifierFields can\'t be mixed with non-identifiable elements');
	}
	const Type = EnvReportInterface.typeMap()[type];
	return new Type();
};

/** This class is used for getting/applying differences between arrays. It will split the array into ranges
 * where the first element is identifiable while the rest is not. See DiffObjRange for more information. */
class DiffArr {
	/** Constructor, 'arr' is the array to use and 'onlyUseIdsFromIdObjects' tells whether we should *only*
	 * treat *objects* with "_id" properties as identifiable. This option will be used as a backup when
	 * duplicates are found when trying to "interpret" strings as MongoDb ids. */
	constructor(arr, onlyUseIdsFromIdObjects, uniquePrimitives) {
		let currRange = new DiffObjRange({ diffArr: this }); // Beginning / start-range (with no object)
		Object.assign(this, {
			arr,
			objRangesById: { [currRange.getId()]: currRange }, // will result in: { undefined: currRange }
			objRanges: [currRange], // Our DiffObjRange instances
			removedOrgRanges: [], // Ranges in the original array that have disappeared and should be removed
			uniquePrimitives, // All elements are primitive values that are unique among each other
			onlyUseIdsFromIdObjects, // Don't try to be smart, just use the ._id property of object as identifier
		});
		arr.forEach((elm) => {
			const id = this.getIdFromElement(elm);
			if (id) { // We've found an identifiable object
				currRange = new DiffObjRange({ diffArr: this, obj: elm });
				if (this.objRangesById[id]) {
					this.hasIdDuplicates = true; // Hmm, the same identifier found >= 2 times..
				}
				this.objRangesById[id] = currRange;
				this.objRanges.push(currRange);
			} else { // Non-identifiable object
				currRange.pushNonObj(elm);
			}
		});
	}

	getIdFromElement(elm) {
		if (this.onlyUseIdsFromIdObjects) {
			return elm?._id;
		}
		return DiffFns.getIdFromValue(elm, this.uniquePrimitives);
	}

	// Initialize settings by checking the difference with the corresponding DiffArr instance for the
	// original array. (notice: used when *getting* a diff, not when applying it)
	applyOrgDiffArray(orgDiffArray) {
		orgDiffArray.objRanges.forEach((orgObjRange) => {
			const newObjRange = this.objRangesById[orgObjRange.getId()];
			if (!newObjRange) { // This range doesn't exist in the new array => should be removed
				this.removedOrgRanges.push(orgObjRange);
			} else { // Initialize the DiffObjRange object from the corresponding one in orignal array
				newObjRange.applyOrgObjRange(orgObjRange);
			}
		});
		// At this stage there might be "untouched" DiffObjRange instances, these are new ranges that we don't need
		// to do anything about as .mode is already set to MODE.NEW for these.
	}

	// Returns the actual (raw) array.
	toArray() {
		const res = [];
		this.objRanges.forEach(({ obj, nonObjs }) => {
			if (obj) {
				res.push(obj);
			}
			if (nonObjs) {
				res.push(...nonObjs);
			}
		});
		return res;
	}

	// Create an array diff object
	getDiff(extraResultFields) {
		const diffs = _.filter(this.objRanges.map((o) => o.getDiff()));
		if (!diffs.length && !this.removedOrgRanges.length) {
			return undefined; // Nothing changed, nothing removed => no diff
		}
		const res = {
			[ARRAY_DIFF_MARKER]: true,
			diffs,
			...extraResultFields,
		};
		const rangesWithIds = this.objRanges.slice(1); // All ranges except start range
		const needOrder = rangesWithIds.find((d) => d.mode === MODE.NEW || d.prevChanged);
		if (needOrder) {
			// We need to provide an array if ids for identifiable elements in the diff object in order for
			// the server to reconstruct the right order.
			res.idOrder = rangesWithIds.map((d) => d.getId());
		}
		const removedIds = this.removedOrgRanges.map((r) => r.getId());
		if (removedIds.length) {
			// List of ids for identifiable elements that should be removed
			res.removedIds = removedIds;
		}
		if (res.uniquePrimitives) {
			// Supply the raw array, as we'll replace the destination if it turns out we need to do a "panic"
			// solution and overwrite destination as it's no longer a unique primitive array
			res.newUniquePrimitiveArr = this.arr;
		}
		return res;
	}

	// To be use in "last resort" situations to simply overwrite an array with the new one
	static createOverwriteDiff(orgRawArr, newRawArr) {
		if (_.isEqual(orgRawArr, newRawArr)) {
			return undefined;
		}
		return {
			[ARRAY_DIFF_MARKER]: true,
			overwriteArray: newRawArr,
		};
	}

	/** When applying the diff, find the identifiable object in our (destination) array that should be before
	 * the identifiable element with 'id'. We might have to "pass by" some ids as they might refer to elements
	 * that has been removed recently. */
	findPrevObjRange(id, idOrder) {
		const idx = idOrder.indexOf(id);
		if (idx < 0 || !id) {
			throw Error('idOrder missing expected id');
		}
		for (let i = idx - 1; i >= 0; i -= 1) {
			const objRange = this.objRangesById[idOrder[i]];
			if (objRange) {
				return objRange;
			} // else, id refers to recently removed object
		}
		return this.objRanges[0]; // start range (no .obj)
	}

	// When applying the diff, insert a new range in the array
	insertNew(diffInfo, idOrder) {
		const {
			id, type, obj, nonObjs = [],
		} = diffInfo;
		const existing = this.objRangesById[id];
		if (existing) {
			// this "new" element already seems to exist. That's fine - but only for custom identifiers.
			if (!DiffFns.isDiffIdentifier(id) || !type) { // This shouldn't be possible..
				if (obj._id) { // MongoDb id for new object already existed in array => not ok..
					throw Error('Diff seems to have been previously applied');
				} else {
					return; // Array of ids or unique identifier, do nothing as it has just been added by someone else
				}
			}
			// we should *merge* settings instead as this object was just created by someone else
			const emptyObj = createEmptyObjForDiffRepurpose(diffInfo);
			const diff = DiffFns.getObjectDiffInternal(emptyObj.asDiffCompatibleObject(), obj);
			// Don't try to remove anything while applying this diff
			DiffFns.withFlag('noRemove', () => DiffFns.applyDiffInternal(existing.obj, diff));
			return;
		}
		const prevRange = this.findPrevObjRange(id, idOrder);
		const newObjRange = new DiffObjRange({ diffArr: this, obj, nonObjs });
		this.objRangesById[id] = newObjRange;
		// Insert new range just after 'prevRange'
		this.objRanges.splice(prevRange.index() + 1, 0, newObjRange);
	}

	// When applying a diff and we're getting a range that have moved - this function will relocate our range
	// 'objRange' to the new position using the 'idOrder' array provided by the client.
	relocate(objRange, idOrder) {
		const prevRange = this.findPrevObjRange(objRange.getId(), idOrder);
		const oldIdx = objRange.index();
		const prevIdx = prevRange.index();
		if (prevIdx !== oldIdx - 1) { // insert just after 'prevRange'
			const newIdx = prevIdx + (oldIdx > prevIdx ? 1 : 0);
			this.objRanges.splice(oldIdx, 1);
			this.objRanges.splice(newIdx, 0, objRange);
		} // else - we're already at the right position
	}

	// Apply the array-diff 'arrDiff' to the original (raw) array 'orgArr', then return
	// the resulting "manipulated" raw array.
	static convertWithDiff(orgArr, arrDiff) {
		const {
			removedIds, idOrder, diffs, onlyUseIdsFromIdObjects, uniquePrimitives,
			newUniquePrimitiveArr, overwriteArray,
		} = arrDiff;
		if (overwriteArray) {
			return overwriteArray;
		}
		if (uniquePrimitives && !DiffFns.isUniquePrimitivesArray(orgArr)) {
			// "Panic", we thought there was just unique primitive values in the array, but we where wrong..
			// Let's simply overwrite the destination array with the new one (that is here for this exact purpose)
			return newUniquePrimitiveArr || [];
		}
		const { noRemove } = DiffFns.ctx;
		let org = orgArr;
		if (removedIds && !noRemove) { // Remove all identifiable elements in 'removedIds'
			const removeMap = _.keyBy(removedIds);
			org = org.filter((elm) => {
				const id = DiffFns.getIdFromValue(elm, uniquePrimitives);
				return !_.isString(id) || !removeMap[id];
			});
		}
		// Make DiffArr from destination raw array
		const orgAsDiff = new DiffArr(org, onlyUseIdsFromIdObjects, uniquePrimitives);
		diffs.forEach((diffInfo) => {
			const {
				id, mode, nonObjs = [], prevChanged, diff, type,
			} = diffInfo;
			if (mode === MODE.NEW) { // New range added
				orgAsDiff.insertNew(diffInfo, idOrder);
			} else { // Range updated
				const objRange = orgAsDiff.objRangesById[id];
				if (!objRange) {
					if (!DiffFns.isDiffIdentifier(id) || !type || !diff) {
						return; // This object has been deleted, ignore
					}
					// For custom identifiers, revive lost object with changes in diff
					const emptyObj = createEmptyObjForDiffRepurpose(diffInfo);
					// set fields used as identifier, first character is '_' marker for custom identifiers
					emptyObj.setByIdentifier(id.slice(1));
					DiffFns.withFlag('createEmptyWhenNeeded', () => DiffFns.applyDiffInternal(emptyObj, diff));
					orgAsDiff.insertNew({ id, obj: emptyObj }, idOrder || [id]); // Insert first in case of no idOrder
					return;
				}
				if (diff) { // There was a diff for identifiable object
					DiffFns.applyDiffInternal(objRange.obj, diff);
				}
				if (prevChanged) { // Order of this range has changed => relocate
					orgAsDiff.relocate(objRange, idOrder);
				}
				const doAppend = mode === MODE.APPEND || (noRemove && mode === MODE.REPLACE);
				if (doAppend) { // New non-identifiable objects has been appended to the range
					objRange.nonObjs.push(...nonObjs);
				} else if (mode === MODE.REPLACE) { // Replace all non-identifiable objects in the range
					objRange.nonObjs = nonObjs;
				}
			}
		});
		return orgAsDiff.toArray(); // Return the resulting rearranged (raw) array
	}
}

module.exports = DiffArr;
