import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import DataTable from './index';

class DataTableWithMemory extends React.Component {
	constructor(props) {
		super(props);
		this.state = { };
	}

	componentDidUpdate() {
		const {
			data, identifier, onChange, selected,
		} = this.props;
		const existing = _.keyBy(data, (obj) => identifier(obj));
		const newSelected = selected.filter((obj) => existing[identifier(obj)]);
		if (newSelected.length !== selected.length) {
			onChange(newSelected);
		}
	}

	render() {
		const {
			data, onChange, identifier, selected,
		} = this.props;
		const selectMap = _.keyBy(selected, (obj) => identifier(obj));
		const onRowSelection = (items) => {
			let indexes = items;
			if (items === 'all') {
				indexes = _.range(data.length);
			} else if (items === 'none') {
				indexes = [];
			}
			const selectedObjs = indexes.map((idx) => data[idx]);
			onChange(selectedObjs);
		};
		const tableBugHack = {};
		const allSelected = data.length === selected.length;
		if (this.allSelected && !allSelected) {
			tableBugHack.key = new Date().getTime();
		}
		this.allSelected = allSelected;

		return (
			<DataTable
				{...this.props}
				{...tableBugHack}
				onRowSelection={onRowSelection}
				isSelected={(row) => !!selectMap[identifier(row)]}
			/>
		);
	}
}

DataTableWithMemory.propTypes = {
	...DataTable.propTypes,
	onChange: PropTypes.func,
	selected: PropTypes.array,
};

DataTableWithMemory.defaultProps = {
	...DataTable.defaultProps,
	onChange: () => {},
	selected: [],
};

export default DataTableWithMemory;
