import React, { useEffect, useState, useRef } from 'react';
import { Box, Container, Paper, Typography, IconButton, Menu, MenuList, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { MoreVert, Check, ExpandMore, ChevronRight } from "@mui/icons-material";
import PropTypes from 'prop-types';

export function Details({ title, expanded, menu, children }) {
	const [isExpanded, setExpanded] = useState(!!expanded);
	const [menuOpen, setMenuOpen] = useState(false);
	const menuAnchor = useRef(null);

	return (
		<Container sx={{ mt: 4 }} maxWidth={false}>
			<Paper>
				<Box p={2}>
					<details open={isExpanded} onToggle={(e) => setExpanded(e.currentTarget.open)}>
						<summary style={{ cursor: 'pointer', userSelect: 'none', listStyle: 'none' }}>
							{/* Flex left and right */}
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<div style={{ display: 'inline-flex', alignItems: 'center', gap: '5px' }}>
									{/* Chevron */}
									{ isExpanded ? (
										<ExpandMore />
									) : (
										<ChevronRight />
									) }
									<Typography variant="h2">{title}</Typography>
								</div>
								<IconButton ref={menuAnchor} size="small" onClick={(e) => { setMenuOpen(true); }}>
									<MoreVert />
								</IconButton>
								{(menu) && (
									<Menu open={menuOpen} onClose={() => { setMenuOpen(false); }} anchorEl={menuAnchor.current}>
										<MenuList>
											{menu.type === React.Fragment ? menu.props.children : menu}
										</MenuList>
									</Menu>
								)}
							</div>
						</summary>

						{children}
					</details>
				</Box>
			</Paper>
		</Container>
	);
}

Details.propTypes = {
	title: PropTypes.string.isRequired,
	expanded: PropTypes.bool,
	menu: PropTypes.node,
	children: PropTypes.node.isRequired,
};

Details.defaultProps = {
	expanded: false,
	menu: null,
};
