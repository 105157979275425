import { useState } from 'react';

function useLocalStorage(key, initialValue) {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.error(error);
			return initialValue;
		}
	});

	const setValue = (value) => {
		const nextState = typeof value === 'function' ? value(storedValue) : value;

		try {
			setStoredValue(nextState);
			window.localStorage.setItem(key, JSON.stringify(nextState));
		} catch (error) {
			console.error(error);
		}
	};

	return [storedValue, setValue];
}

export default useLocalStorage;
