import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import AddHome from '@mui/icons-material/AddToHomeScreen';

import Base from '../../layouts/Base';
import BidParamEdit from '../BidParamEdit';

// eslint-disable-next-line import/no-named-as-default, import/no-cycle
import PrebidConfigEdit from '.';
import TextField from '../TextField';
import Switch from '../Switch';
import TextEditor from '../TagEditor/textEditor';
import {
	filterEnabledForCfg,
	countryFilter,
	percentageTextField,
	percentUsedByChildren,
} from './utils';
import Form from '../../containers/Form';
import { ConfirmDialog } from '../ConfirmDialog';

function PrebidConfigEditForm(properties) {
	const {
		tag,
		fld,
		parent,
		pbjsConfig,
		publisherNode,
		dirtyBidParamUpdater,
	} = properties;
	const hasChildren = !!pbjsConfig.prebidConfigs?.length;

	return (
		<>
			{!parent && (
				<Grid item xs={12}>
					<Card>
						<CardContent>
							<Typography variant="h2">
								Prebid tag
							</Typography>
							<TextEditor
								width="100%"
								height="220px"
								name="prebidTag"
								readOnly
								showGutter={false}
								value={tag}
							/>
						</CardContent>
					</Card>
				</Grid>
			)}
			<Grid item xs={3}>
				<TextField required label="Name" {...fld('name')} />
			</Grid>
			{!parent && hasChildren && (
				<Grid item xs={2}>
					<TextField
						label="Percentage"
						disabled
						name="Mian"
						value={filterEnabledForCfg(pbjsConfig, true)
							? '?' : Math.max(100 - percentUsedByChildren(pbjsConfig), 0)}
					/>
				</Grid>
			)}
			{!parent && hasChildren && (
				<Grid item xs={4}>
					<Tooltip title="Enable this to make sure users will participate in the same A/B test group in-betweeen page-views. This is done by storing a random number in site visitors' local storage.">
						<span style={{ whiteSpace: 'pre' }}>
							<Switch
								label="Use local storage (web)"
								{...fld('abTestLocalStorage')}
							/>
						</span>
					</Tooltip>
				</Grid>
			)}
			{parent && (
				<>
					<Grid item xs={3}>
						{percentageTextField({ parent, child: pbjsConfig, fld })}
					</Grid>
					<Grid item xs={2}>
						{countryFilter({ child: pbjsConfig, form: Form.fromField(fld) })}
					</Grid>
					<Grid item xs={2}>
						<Switch
							label="Enabled"
							{...fld('enabled')}
						/>
					</Grid>
					<Grid item xs={2}>
						<Tooltip title="Make default">
							<IconButton
								onClick={async () => {
									const ok = await Base.renderGlobal((closeFn) => (
										<ConfirmDialog
											open
											text="Do you want to copy the settings for this child configuration to the main configuration?"
											onAny={closeFn}
										/>
									));
									if (ok) {
										dirtyBidParamUpdater();
										publisherNode.transferParams(parent, pbjsConfig);
										BidParamEdit.notifyNodeUpdatedExternally(publisherNode.byId[parent.id]);
									}
								}}
								size="large"
							>
								<AddHome />
							</IconButton>
						</Tooltip>
					</Grid>
				</>
			)}
			{!parent && (
				<Grid item xs={3}>
					<PrebidConfigEdit
						{...properties}
						site={pbjsConfig}
						field={fld}
						parent={pbjsConfig}
						useButtonStyle
						title="Child configurations"
					/>
				</Grid>
			)}
		</>
	);
}

export default PrebidConfigEditForm;
