import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Drawer,
	SwipeableDrawer,
	List,
	ListItemText,
	Collapse,
	Divider,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PublisherIcon from '@mui/icons-material/Newspaper';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AlarmIcon from '@mui/icons-material/Alarm';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useLocation } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import { stores } from '../../stores';
import SidebarButton from './SidebarButton';
import SidebarButtonGroup from './SidebarButtonGroup';
import SystemData from '../../lib/systemData';
import ExpandableSidebarButton from './ExpandableSidebarButton';
import ExpandedSidebarListSubheader from './ExpandedSidebarListSubheader';
import { isReportsOverviewPath } from './utils';
import useSidebar from '../../hooks/useSidebar';
import SidebarTopArea from './SidebarTopArea';
import Base from '../../layouts/Base';

import {
	drawerWidth,
	SECTION,
	audienceMenuItems,
	adminSettingsMenuItems,
	adminHbManagementMenuItems,
	adminMenuItems,
} from './config';
import { PRODUCT_TYPE } from '../../lib/access';
import useReports from '../../hooks/useReports';
import AppSelector from '../AppSelector';
import AlertBalloon from '../AlertBalloon';
import usePinnedReports from '../../hooks/usePinnedReports';
import CreateReportButton from './CreateReportButton';
import useExpandedSections from './hooks/useExpandedSections';
import useExpandedReportSections from './hooks/useExpandedReportSections';
import ReportButton from './ReportButton';
import DelayedCacheInvalidation from '../../lib/delayedCacheInvalidation';

export default function Sidebar({ productType, isAudience, onSelectProduct }) {
	const { pathname, search } = useLocation();
	const {
		mobileOpen,
		handleDrawerToggle,
	} = useSidebar();
	const [expandedSections, updateExpandedSections] = useExpandedSections();
	const [expandedReportSections, updateExpandedReportSections] = useExpandedReportSections();
	const { availableReportTypes } = useReports();
	const pinnedReports = usePinnedReports();
	const currentPath = `${pathname}${search}`;

	const {
		disableAudience,
		systemSettings,
		hasGlobalAdminAccess,
		hbmEnabled,
	} = SystemData.genericData;

	const {
		sidebarLogoUrl,
		sidebarLogoStyle,
		hide,
	} = systemSettings;

	const hasAudienceSettingsAccess = productType === PRODUCT_TYPE.ADMIN && !disableAudience;

	const hideInvoices = hide.invoices
		&& (productType === PRODUCT_TYPE.FULL
			|| productType === PRODUCT_TYPE.PROGRAMMATIC
			|| productType === PRODUCT_TYPE.AUDIENCE);

	const nonAudienceOnlyUser = (productType === PRODUCT_TYPE.ADMIN || productType === PRODUCT_TYPE.PROGRAMMATIC
		|| productType === PRODUCT_TYPE.FULL);

	const user = stores.identity.value;

	const canEditUsers = (user && user.profile && user.profile.permissions && user.profile.permissions.canEditUsers);

	const drawer = (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			height: '100%',
			width: drawerWidth,
		}}
		>
			{/* Audience non-admin user sidebar options */}
			{/* Or "Full" non-admin user sidebar options, with "Audience" selected in AppSelector */}
			{ isAudience ? (
				<div>
					<SidebarTopArea type="audience" />
					<SidebarButtonGroup>
						<SidebarButton
							text="Dashboard"
							icon={<DashboardIcon />}
							selected={currentPath === '/audience/dashboard'}
							path="/audience/dashboard"
						/>
						<SidebarButton
							path="/reports/audience"
							icon={<AssessmentIcon />}
							selected={currentPath === '/reports/audience'}
							text="Reports"
						/>
						{!hide.invoices && (
							<SidebarButton
								text="Invoices"
								icon={<ReceiptIcon />}
								path="/audience/invoicing"
								selected={currentPath === '/audience/invoicing'}
							/>
						)}
						{ canEditUsers && (
							<SidebarButton
								text="Users"
								icon={<PeopleIcon />}
								path="/audience/users"
								selected={currentPath === '/audience/users'}
							/>
						)}
					</SidebarButtonGroup>
				</div>
			) : (
				// All other users sidebar options
				<div>
					<SidebarTopArea
						logo={sidebarLogoUrl}
						type="yield"
						logoStyle={sidebarLogoStyle}
					/>
					{/* -1px to align with topbar edge, for non RY logos. */}
					<SidebarButtonGroup sx={{ mt: '-1px' }}>
						<SidebarButton
							text="Dashboard"
							icon={<DashboardIcon />}
							selected={currentPath === '/dashboard'}
							path="/dashboard"
						/>

						<ExpandableSidebarButton
							text="Reports"
							icon={<AssessmentIcon />}
							expanded={expandedSections.includes(SECTION.REPORTS)}
							onExpandChange={() => updateExpandedSections(SECTION.REPORTS)}
						>
							<List dense>
								<CreateReportButton />
								<SidebarButton
									path="/reports"
									selected={(
										isReportsOverviewPath(currentPath, availableReportTypes.map(({ path }) => path))
									)}
									text="Overview"
									sx={{ pl: 9 }}
									dense
								/>
								{ availableReportTypes.map(({
									type: section,
									SHORT_DESCRIPTION,
									path,
								}) => {
									if (pinnedReports && pinnedReports[section]?.length > 0) {
										return (
											<Box key={section}>
												<ExpandedSidebarListSubheader
													expanded={expandedReportSections.includes(section)}
													onExpandChange={() => updateExpandedReportSections(section)}
												>
													{ SHORT_DESCRIPTION }
												</ExpandedSidebarListSubheader>
												<Collapse
													appear={false}
													in={expandedReportSections.includes(section)}
												>
													{ pinnedReports[section].map((report) => (
														<ReportButton
															key={report.id}
															id={report.id}
															path={`${path}/${report.id}`}
															text={report.settings.title}
															selected={(
																currentPath.includes(`${path}/${report.id}`)
															)}
														/>
													))}
												</Collapse>
											</Box>
										);
									}
									return null;
								})}
							</List>
						</ExpandableSidebarButton>
						{ nonAudienceOnlyUser && (
							<Base.GlobalComponent
								name="numErrorsBalloon"
								content={() => (
									<SidebarButton
										text="Alarms"
										icon={<AlarmIcon />}
										path="/alarms"
										selected={currentPath === '/alarms'}
										secondaryIcon={SystemData.genericData.numUnacknowledgedTriggerChecks > 0 ? (
											<AlertBalloon label={SystemData.genericData.numUnacknowledgedTriggerChecks} />
										) : null}
									/>
								)}
							/>
						)}
						{ productType === PRODUCT_TYPE.ADMIN && (
							<SidebarButton
								text="Publisher Accounts"
								icon={<PublisherIcon />}
								selected={currentPath.startsWith('/accounts')}
								path="/accounts"
							/>
						)}
						{ ((productType === PRODUCT_TYPE.PROGRAMMATIC ||  productType === PRODUCT_TYPE.FULL)
							&& !hide.advertiserMappings) && (
							<SidebarButton
								text="Advertiser mappings"
								icon={<PersonIcon />}
								selected={currentPath.startsWith('/advertiser-mappings')}
								path="/advertiser-mappings"
							>
								<ListItemText>Advertiser mappings</ListItemText>
							</SidebarButton>
						)}
					</SidebarButtonGroup>
					<SidebarButtonGroup>
						{ productType === PRODUCT_TYPE.ADMIN && hbmEnabled && (
							<ExpandableSidebarButton
								text="HB Management"
								icon={<SettingsRemoteIcon />}
								path="/hb-management"
								expanded={expandedSections.includes(SECTION.HB_MANAGEMENT)}
								onExpandChange={() => updateExpandedSections(SECTION.HB_MANAGEMENT)}
							>
								{ adminHbManagementMenuItems.map(({ path, title }) => (
									<SidebarButton
										key={path}
										path={path}
										text={title}
										selected={path === currentPath}
										sx={{ pl: 9 }}
										dense
									/>
								))}
							</ExpandableSidebarButton>
						)}
						{ !hideInvoices && (
							<SidebarButton
								text="Invoices"
								icon={<ReceiptIcon />}
								path="/invoicing"
								selected={currentPath === '/invoicing'}
							/>
						)}
						{ hasAudienceSettingsAccess && (
							<ExpandableSidebarButton
								text="Audience"
								icon={<TheaterComedyIcon />}
								expanded={expandedSections.includes(SECTION.AUDIENCE)}
								onExpandChange={() => updateExpandedSections(SECTION.AUDIENCE)}
							>
								{ audienceMenuItems.map(({ path, title }) => (
									<SidebarButton
										key={path}
										path={path}
										text={title}
										selected={path === currentPath}
										sx={{ pl: 9 }}
										dense
									/>
								))}
							</ExpandableSidebarButton>
						)}
						{ productType === PRODUCT_TYPE.ADMIN && (
							<>
								<ExpandableSidebarButton
									text="Configuration"
									icon={<SettingsIcon />}
									expanded={expandedSections.includes(SECTION.CONFIGURATION)}
									onExpandChange={() => updateExpandedSections(SECTION.CONFIGURATION)}
								>
									{ adminSettingsMenuItems.map(({ path, title }) => (
										<SidebarButton
											key={path}
											path={path}
											selected={path === currentPath}
											text={title}
											sx={{ pl: 9 }}
											dense
										/>
									))}
									{ stores.identity.isSuperAdministrator() && (
										<SidebarButton
											path="/settings/prebid-builds"
											selected={currentPath === '/settings/prebid-builds'}
											text="Prebid builds"
											sx={{ pl: 9 }}
											dense
										/>
									)}
								</ExpandableSidebarButton>
								<ExpandableSidebarButton
									text="Admin"
									icon={<AdminPanelSettingsIcon />}
									expanded={expandedSections.includes(SECTION.ADMIN)}
									onExpandChange={() => updateExpandedSections(SECTION.ADMIN)}
								>
									<SidebarButton
										path="/administrators"
										selected={currentPath === '/administrators'}
										text="Administrators"
										sx={{ pl: 9 }}
										dense
									/>
									{ adminMenuItems.map(({ path, title }) => (
										<SidebarButton
											key={path}
											path={path}
											selected={path === currentPath}
											text={title}
											sx={{ pl: 9 }}
											dense
										/>
									))}
									{/* Only show on our own account */}
									{ hasAudienceSettingsAccess && (
										<SidebarButton
											path="/admin/alarms"
											selected={currentPath === '/admin/alarms'}
											text="System alarms"
											sx={{ pl: 9 }}
											dense
										/>
									)}
									{ hasGlobalAdminAccess && (
										<SidebarButton
											path="/admin/instances"
											selected={currentPath === '/admin/instances'}
											text="Instances"
											sx={{ pl: 9 }}
											dense
										/>
									)}
								</ExpandableSidebarButton>
							</>
						)}
						{ (productType === PRODUCT_TYPE.PROGRAMMATIC || productType === PRODUCT_TYPE.FULL)
						&& canEditUsers && (
							<SidebarButton
								text="Users"
								icon={<PeopleIcon />}
								path="/users"
								selected={currentPath === '/users'}
							/>
						)}
					</SidebarButtonGroup>
				</div>
			)}
			<Box sx={{
				display: { xs: 'block', sm: 'none' },
				mt: 7,
				pb: 1,
			}}
			>
				{ onSelectProduct && (
					<Box sx={{ ml: 1.5 }}>
						<AppSelector onProductChange={onSelectProduct} />
					</Box>
				)}
				<Divider />
				<Box sx={{ mt: 1 }}>
					<SidebarButton
						path="/user"
						icon={<AccountCircleIcon sx={{ fontSize: '32px', color: 'info.wateredLight' }} />}
						text={stores.identity.name()}
					/>
				</Box>
			</Box>
			<DelayedCacheInvalidation />
		</Box>
	);

	return (
		<Box
			component="nav"
			sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
			aria-label="mailbox folders"
		>
			<SwipeableDrawer
				variant="temporary"
				open={mobileOpen}
				onOpen={handleDrawerToggle}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: 'block', sm: 'block', md: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
				PaperProps={{
					sx: {
						backgroundColor: 'primary.main',
						color: 'primary.contrastText',
						overflowX: 'hidden',
					},
				}}
			>
				{drawer}
			</SwipeableDrawer>
			<Drawer
				variant="permanent"
				sx={{
					display: { xs: 'none', sm: 'none', md: 'block' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
				PaperProps={{
					sx: {
						backgroundColor: 'primary.main',
						color: 'primary.contrastText',
						overflowX: 'hidden',
					},
				}}
				open
			>
				{drawer}
			</Drawer>
		</Box>
	);
}

Sidebar.propTypes = {
	productType: PropTypes.oneOf([
		'admin',
		'full',
		'programmatic',
		'audience',
	]).isRequired,
	isAudience: PropTypes.bool,
	onSelectProduct: PropTypes.func,
};

Sidebar.defaultProps = {
	isAudience: false,
	onSelectProduct: undefined,
};
