const _ = require('lodash');

const stringToObject = (str) => {
	if (!str.trim()) {
		return {};
	}
	const obj = eval(`(function(){ return (${str}); })()`);
	if (!_.isPlainObject(obj)) {
		throw Error('Parameters must be an object');
	}
	const res = _.cloneDeepWith(obj, (val) => (_.isFunction(val) ? `__fnEncStart__${val}__fnEncEnd__` : undefined));
	try {
		JSON.stringify(res);
	} catch (e) {
		throw Error('Object can\'t be converted to JSON');
	}
	return res;
};

const objectToString = (obj, prettyPrint = false) => {
	const str = JSON.stringify(obj, null, prettyPrint ? 2 : 0);
	if (str.indexOf('__fnEncStart__') < 0) {
		return str;
	}
	return str.replace(/"__fnEncStart__(.*?)__fnEncEnd__"/g, (__, p1) => JSON.parse(`"${p1}"`));
};

module.exports = {
	stringToObject,
	objectToString,
};
