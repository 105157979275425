import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Add, Delete, RecentActors, SaveAlt, SupervisedUserCircle, UploadFile, Visibility, VisibilityOff } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MiscTasks, Publisher } from '../../api/relevant';
import { ReactComponent as FileTree } from '../../assets/icons/FileTree.svg?component';
import AccessHistory from '../../components/AccessHistory';
import BulkProgrammaticImport from '../../components/BulkProgrammaticImport';
import { Button } from '../../components/Button/Button';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import DemoImport from '../../components/DemoImport';
import PopupSelector from '../../components/PopupSelector';
import { SimpleTable } from '../../components/SimpleTable';
import { StyledTooltip } from '../../components/StyledTooltip';
import { Toast } from '../../components/Toast/Toast';
import { IfDemoMode, IfSuperAdmin } from '../../components/Wrappers';
import { useOperationToast } from '../../hooks/useOperationToast';
import { useToast } from '../../hooks/useToast';
import useTopBar from '../../hooks/useTopbar';
import { CsvImportManager } from './CsvImportManager/CsvImportManager';
import { FuzzyFilterBar, FuzzyFilterResults } from './FuzzyFilterBar';
import { exportPublisherStructureV1 } from './utils';
import { usePublishersQuery } from './query';

export function AdminPublisherList() {
	const [csvUploadVisible, setCsvUploadVisible] = useState(false);
	const [showHidden, setShowHidden] = useState(false);
	const [csvUploadAnimate] = useAutoAnimate();
	const [, showToast] = useToast();
	const { setSettings: setTopBarSettings } = useTopBar();
	const location = useLocation();
	const initialFilter = new URLSearchParams(location.search).get('f') || '';
	let demoImport: DemoImport;

	useEffect(() => {
		setTopBarSettings({
			type: 'publisherAccounts'
		});
	}, []);

	const { isLoading, data: publishers } = usePublishersQuery();
	const queryClient = useQueryClient();

	const deleteMutation = useMutation({
		mutationFn: async ({ id }: { id: string }) => {
			await MiscTasks.call('deletePublisher', { id });
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({ queryKey: ['publishers'] });
		},
	});

	const hideMutation = useMutation({
		mutationFn: async ({ id, hidden }: { id: string, hidden: boolean }) => {
			await Publisher.call('hide', { id, hidden });
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({ queryKey: ['publishers'] });
		},
	});

	const [rows, setRows] = useState<{ id: string; cols: (string | JSX.Element | undefined)[]; to: string; sort: string; hidden?: boolean }[]>([]);
	const [filterData, setFilterData] = useState<{ id: string; text: string }[]>();
	const [filterIds, setFilterIds] = useState<string[]>();

	useEffect(() => {
		const data = publishers?.map((p) => ({
			id: p.id,
			text: p.name,
		}));
		setFilterData(data);
	}, [publishers]);

	useEffect(() => {
		let r = publishers?.map((p) => ({
			id: p.id,
			cols: [
				p.hidden ? <span className="line-through">{p.name}</span> : p.name,
				// TODO: Implement "main contact"?
				p.programmaticInvoiceEmail,
				p.notes?.length ? p.notes : undefined,
			],
			to: `/accounts/${p.id}`,
			sort: p.name,
			hidden: p.hidden,
		})) ?? [];
		// Filter
		r = r.filter((row) => (!row.hidden || showHidden) && (!filterIds || filterIds.includes(row.id)));
		if (filterIds) {
			// Sort by filter score (awfully inefficient, but it's a small list)
			r = r.sort((a, b) => filterIds.indexOf(a.id) - filterIds.indexOf(b.id));
		} else {
			// Sort by name
			r = r.sort((a, b) => a.sort.localeCompare(b.sort));
		}
		// Optimistic delete
		if (deleteMutation.isPending && deleteMutation.variables) {
			const { id } = deleteMutation.variables;
			r = r.filter((row) => row.id !== id);
		}
		setRows(r);
	}, [publishers, showHidden, deleteMutation.isPending, filterIds]);

	const handleFilter = ({ results, value }: { results: FuzzyFilterResults, value: string }) => {
		setFilterIds(results?.map((r) => r.item.id));
		// Update query parameter in URL
		const search = new URLSearchParams(location.search);
		if (value) {
			search.set('f', value);
		} else if (value === '') {
			search.delete('f');
		}
		window.history.replaceState(null, '', `${location.pathname}${search.size ? '?' : ''}${search}`);
	};

	const [csvDownloadOperation, csvDownloadInProgress] = useOperationToast({
		loadingMessage: 'Generating CSV...',
		errorMessage: (e) => `Failed to generate CSV. Please try again. (${e.message})`,
	});
	const handleCsvDownload = csvDownloadOperation(exportPublisherStructureV1);

	return (
		<>
			<Toast
				open={deleteMutation.isError}
				message="Failed to delete publisher"
				status="error"
			/>
			<Toast
				open={deleteMutation.isSuccess}
				message="Publisher deleted"
				status="success"
				timeout={5}
			/>
			<Toast
				open={hideMutation.isError}
				message="Failed to hide publisher"
				status="error"
			/>

			{/* Top Bar */}
			<div className="container mx-auto px-8 pt-8 flex justify-between flex-wrap flex-col gap-2">
				{/* TODO: Break out table header into a variant, maybe? */}
				<div className="flex items-center gap-2">
					<span className="text-xl font-medium text-grey-600 tracking-normal">Your Publishers</span>
				</div>
				<div className="flex items-center justify-end gap-x-8 gap-y-2 flex-grow flex-wrap">
					<div className="w-[190px]">
						<FuzzyFilterBar placeholder="Filter publishers" data={filterData} onFilter={handleFilter} value={initialFilter} />
					</div>
					<div className="flex gap-2">
						<StyledTooltip title={!showHidden ? 'Show hidden publishers' : 'Hide hidden publishers'}>
							<Button icon={!showHidden ? <Visibility /> : <VisibilityOff />} onClick={() => setShowHidden(!showHidden)} />
						</StyledTooltip>
						<IfSuperAdmin>
							<PopupSelector
								size="lg"
								customLink={(showFn) => (
									<StyledTooltip title="Advanced Mapping">
										<Button
											icon={<SvgIcon ><FileTree /></SvgIcon>}
											onClick={showFn}
										/>
									</StyledTooltip>
								)}
							>
								<BulkProgrammaticImport />
							</PopupSelector>
						</IfSuperAdmin>
						<PopupSelector
							size="lg"
							customLink={(openFn) => (
								<StyledTooltip title="Access History">
									<Button icon={<RecentActors />} onClick={openFn} />
								</StyledTooltip>
							)}
						>
							<AccessHistory />
						</PopupSelector>
						{/* TODO: This CSV download is different to the one in CsvImportManager. Should they be the same, with an options dialog maybe? */}
						<StyledTooltip title="Download mapping structure as CSV">
							<Button icon={<SaveAlt />} onClick={handleCsvDownload} disabled={csvDownloadInProgress} />
						</StyledTooltip>
						<StyledTooltip title="CSV Import/Export">
							<Button icon={<UploadFile />} active={csvUploadVisible} onClick={() => setCsvUploadVisible(!csvUploadVisible)} />
						</StyledTooltip>
					</div>
					<Button href="/accounts/add" className="shrink-0">
						<Add /> New publisher
					</Button>
				</div>
			</div>

			{/* CSV Upload */}
			<div ref={csvUploadAnimate}>
				{csvUploadVisible && (
					<div className="container mx-auto px-8 pt-4">
						<div className="border border-grey-200 rounded-md bg-white p-4">
							<CsvImportManager
								onImportComplete={(importSuccessful) => {
									if (importSuccessful) {
										queryClient.invalidateQueries({ queryKey: ['publishers'] });
										showToast({
											message: 'Import successful',
											status: 'success',
											timeout: 3,
										});
									} else {
										showToast({
											message: 'Import failed',
											status: 'error',
										});
									}
								}}
								onError={(error) => {
									showToast({
										message: error,
										status: 'error',
									});
								}}
								onClose={() => setCsvUploadVisible(false)}
							/>
						</div>
					</div>
				)}
			</div>

			{/* Publisher table */}
			<div className="container mx-auto px-8 pt-4">
				<SimpleTable
					headers={['Publisher', 'Main Contact', 'Notes']}
					columnClass={['w-[400px] flex-auto', 'w-[400px] flex-auto', 'w-[400px] flex-auto']}
					rows={isLoading ? undefined : rows}
					skeletonRows={5}
					actions={({ id, cols, hidden }) => (
						<div className="flex gap-2 text-[20px]">
							<StyledTooltip title="View users" disableInteractive>
								<Button icon={<SupervisedUserCircle fontSize="inherit" />} href={`/accounts/${id}/users`} />
							</StyledTooltip>
							{!hidden ? (
								<ConfirmDialog
									text={<>
										<span>Are you sure you want to hide the "<span className="font-medium">{cols[0]}</span>" publisher?</span>
										<span className="text-sm italic">You may view hidden publishers by toggling "Show hidden" in the menu.</span>
									</>}
									confirmLabel="Hide"
									render={(show) => (
										<StyledTooltip title="Hide publisher" disableInteractive>
											<Button icon={<VisibilityOff fontSize="inherit" />} onClick={show} />
										</StyledTooltip>
									)}
									onConfirm={() => { hideMutation.mutate({ id, hidden: true }); }}
								/>
							) : (
								<StyledTooltip title="Unhide publisher" disableInteractive>
									<Button icon={<Visibility fontSize="inherit" />} onClick={() => { hideMutation.mutate({ id, hidden: false }); }} />
								</StyledTooltip>
							)}
							<ConfirmDialog
								text={<>
									<span>Are you sure you want to remove the "<span className="font-medium">{cols[0]}</span>" publisher?</span>
									<span>This operation can not be undone.</span>
								</>}
								confirmLabel='Remove'
								render={(show) => (
									<StyledTooltip title="Remove publisher" disableInteractive>
										<Button icon={<Delete fontSize="inherit" />} onClick={show} disabled={deleteMutation.isPending} />
									</StyledTooltip>
								)}
								onConfirm={() => deleteMutation.mutate({ id })}
							/>
						</div>
					)}
				/>
			</div>

			{/* Keeping this button in its original spot since it's only relevant to demo mode */}
			<IfDemoMode>
				<div className="container mx-auto px-8 pt-4 flex gap-4 items-start">
					<PopupSelector
						onApplyChanges={async ({ preventDefault }) => {
							await demoImport.save(preventDefault);
						}}
						size="lg"
						customLink={(showFn) => (
							<Button variant="outlined" onClick={showFn}>Yield demo setup</Button>
						)}
					>
						<DemoImport ref={(elm) => { demoImport = elm; }} />
					</PopupSelector>
				</div>
			</IfDemoMode>
		</>
	);
}
