import _ from 'lodash';
import CollectionStore from './CollectionStore';
import { MiscTasks, addUser } from '../api/relevant';

class UserStore extends CollectionStore {
	async loadAll(forceReload) {
		await super.loadAllWith(forceReload, async () => {
			const users = await MiscTasks.call('getUsers');
			this.set(_.sortBy(users, 'firstname'));
		});
	}

	async addToPublisher(user) {
		await MiscTasks.call('addUser', user);
		this.loadAll(true);
	}

	async add(user) {
		await addUser(user);
		this.loadAll(true);
	}

	async edit(user) {
		const editedUser = await MiscTasks.call('editUser', user);
		const users = this.all.map((usr) => {
			if (usr.id === editedUser.id) usr = editedUser;
			return usr;
		});
		this.set(_.sortBy(users, 'firstname'));
	}

	async delete(id) {
		await MiscTasks.call('deleteUser', { id });
		const users = this.all.filter((usr) => usr.id !== id);
		this.set(_.sortBy(users, 'firstname'));
	}

	set(users) {
		super.set(users);
		this.ok();
	}

	byId(id) {
		return _.find(this.js(), { id });
	}

	byPublisherId(publisherId) {
		return _.filter(this.js(), { publisherId });
	}
}

export default UserStore;
