const moment = require('moment');
const _ = require('lodash');
const DateUtils = require('../misc/dateUtils');
const { dateNormalized, rearranged } = require('./utils');

class TrendData {
	constructor({
		apiParams, trendMetrics, trendMethod, trendPeriods,
	}) {
		Object.assign(this, {
			apiParams,
			dateIncluded: _.includes(apiParams.groupBy, 'date'),
			start: DateUtils.fullDay(apiParams.start),
			end: DateUtils.fullDay(apiParams.end),
			extraSumMap: {},
			extraSums: [],
			trendMetrics,
			trendMethod,
			trendPeriods,
		});
	}

	fullStartDate() {
		if (!this.dateIncluded) {
			throw Error('Use only when date is included in groupBy');
		}
		return DateUtils.fullDay(this.apiParams.start, -7 * this.trendPeriods);
	}

	splitReportParams() {
		const { start, end, groupBy } = this.apiParams;
		if (this.dateIncluded) {
			return [{ ...this.apiParams, start: this.fullStartDate() }];
		}
		const diff = moment(end).diff(start, 'days') + (start.getDay() - end.getDay()) + (end.getDay() >= start.getDay() ? 7 : 0);
		const dateRanges = _.range(this.trendPeriods, -1).map((i) => ({
			start: DateUtils.fullDay(start, -(i * diff)),
			end: DateUtils.fullDay(end, -(i * diff)),
		}));
		const params = {
			...this.apiParams,
			dateSubRanges: dateRanges,
			groupBy: ['dateRangeIdx'].concat(groupBy),
			start: dateRanges[0].start,
			end: _.last(dateRanges).end,
		};
		return [params];
	}

	/** Fill up empty slots in our 4 reports OR per date in single report so they have the same structure
	 This means the current report contains zero-metrics for values that existed in previous reports */
	normalizeDatas(allDatas, levels, empty) {
		allDatas.forEach((currData) => {
			const others = _.without(allDatas, currData);
			const run = (obj, idx, otherObjs) => {
				const isFinal = idx === levels - 1;
				_.forOwn(obj, (val, key) => {
					if (key === 'isPseudo') {
						return;
					}
					const nextOther = otherObjs.map((otherObj) => {
						if (!otherObj[key]) {
							otherObj[key] = isFinal ? ({ isPseudo: true, ...empty }) : { isPseudo: true };
						}
						return otherObj[key];
					});
					if (!isFinal) {
						run(val, idx + 1, nextOther);
					}
				});
			};
			run(currData, 0, others);
		});
	}

	pseudoObjectIsEmpty(obj) {
		return !Object.entries(this.trendMetrics).find(([key, val]) => this.calculate(obj, key, val));
	}

	clearEmpty(data, levels) {
		const run = (obj, idx) => {
			let hasReal = !obj.isPseudo;
			_.forOwn(obj, (val, key) => {
				if (key === 'isPseudo') {
					return;
				}
				if (idx === levels - 1) {
					if (val.isPseudo && this.pseudoObjectIsEmpty(val)) {
						delete obj[key];
					} else {
						hasReal = true;
					}
					delete val.isPseudo;
				} else if (!run(val, idx + 1)) {
					delete obj[key];
				} else {
					hasReal = true;
				}
			});
			delete obj.isPseudo;
			return hasReal;
		};
		run(data, 0);
		return data;
	}

	calculate(obj, tMetric, { trendMetric: metricObj }) {
		const { getVal, diffVal } = metricObj;
		const start = `avg_${tMetric}_`;
		const avgOld = {};
		for (const key in obj) {
			if (key.startsWith(start)) {
				avgOld[key.slice(start.length)] = obj[key];
			}
		}
		obj.val = getVal(obj);
		avgOld.val = getVal(avgOld);
		const diff = diffVal ? diffVal(avgOld, obj) : obj.val - avgOld.val;
		delete obj.val;
		delete avgOld.val;
		return diff;
	}

	/** data + all elements of prevData must have identical structure */
	processDataChunks({ data, prevDatas, levels }) {
		const { sums } = this.apiParams;
		const extra = (sum) => {
			if (!this.extraSumMap[sum]) {
				this.extraSumMap[sum] = true;
				this.extraSums.push(sum);
			}
			return sum;
		};

		const getTotalObj = (arr) => {
			const res = {};
			arr.forEach((elm) => {
				sums.forEach((sum) => {
					res[sum] = (res[sum] || 0) + (elm[sum] || 0);
				});
			});
			return res;
		};

		const getAvgObj = (arr) => {
			const avg = getTotalObj(arr);
			sums.forEach((sum) => {
				avg[sum] /= arr.length; // yes.. this might turn integers such as adserverIn into decimal numbers
			});
			return avg;
		};

		const run = (obj, idx, currPrevObjects) => {
			if (levels === idx) {
				const totals = getTotalObj(currPrevObjects.concat(obj));
				Object.assign(obj, _.mapKeys(totals, (v, k) => extra(`trend_${k}`)));
				_.forOwn(this.trendMetrics, ({ trendMetric: metricObj }, tMetric) => {
					const { getVal } = metricObj;
					let avgArr;
					if (this.trendMethod === 'avg') {
						avgArr = currPrevObjects;
					} else { // median
						const sorted = _.sortBy(currPrevObjects, getVal);
						const medianIdx = Math.floor((this.trendPeriods - 1) / 2);
						avgArr = [sorted[medianIdx]];
						if (!(this.trendPeriods % 2)) { // "median" => avg of middle elements
							avgArr.push(sorted[medianIdx + 1]);
						}
					}
					const avgOld = getAvgObj(avgArr);
					Object.assign(obj, _.mapKeys(avgOld, (v, k) => extra(`avg_${tMetric}_${k}`)));
				});
				delete obj.val; // was only for temporary usage..
			} else {
				_.forOwn(obj, (val, key) => {
					if (key === 'isPseudo') {
						return;
					}
					const nextPrevObjects = [];
					currPrevObjects.forEach((prevObj, i) => {
						nextPrevObjects[i] = prevObj[key];
					});
					run(val, idx + 1, nextPrevObjects);
				});
			}
		};
		run(data, 0, prevDatas.slice());
	}

	processReports({ report }) {
		const {
			groupBy, sums, start, end,
		} = this.apiParams;
		let { data } = report;
		for (let i = 0; i <= this.trendPeriods; i++) {
			data[i] = data[i] || {}; // make all ranges present even if they are empty (including current range)
		}
		const empty = _.zipObject(sums, Array(sums.length).fill(0));
		if (this.dateIncluded) {
			const dateOnly = groupBy.length === 1;
			const dateIdx = groupBy.indexOf('date');
			if (dateIdx) {
				data = rearranged(data, 0, dateIdx, groupBy.length);
			}
			data = dateNormalized(data, this.fullStartDate(), end, dateOnly ? empty : {});
			const perDate = Object.values(data);
			if (!dateOnly) {
				this.normalizeDatas(perDate, groupBy.length - 1, empty);
			}
			const realDates = DateUtils.dates(start, end);
			realDates.forEach((date) => {
				const cmpDates = _.range(this.trendPeriods, 0).map((i) => DateUtils.fullDay(date, -7 * i));
				this.processDataChunks({
					data: data[date],
					prevDatas: cmpDates.map((d) => data[d]),
					levels: groupBy.length - 1,
				});
			});
			const realDateMap = _.zipObject(realDates, Array(realDates.length).fill(true));
			data = _.pickBy(data, (v, k) => realDateMap[k]);
			if (dateIdx) {
				data = rearranged(data, 0, dateIdx, groupBy.length);
			}
		} else {
			const allDatas = _.sortBy(Object.entries(data), (e) => parseInt(e[0], 10)).map(([k, v]) => v);
			data = _.last(allDatas);
			const prevDatas = _.initial(allDatas);
			this.normalizeDatas(allDatas, groupBy.length, empty);
			this.processDataChunks({
				data,
				prevDatas,
				levels: groupBy.length,
			});
		}
		report.data = data;
		this.clearEmpty(report.data, groupBy.length);
	}
}

module.exports = TrendData;
