module.exports = [
	'UTC',
	'Pacific/Midway',
	'Pacific/Niue',
	'Pacific/Pago_Pago',
	'Pacific/Honolulu',
	'Pacific/Johnston',
	'Pacific/Rarotonga',
	'Pacific/Tahiti',
	'Pacific/Marquesas',
	'America/Adak',
	'Pacific/Gambier',
	'America/Anchorage',
	'America/Juneau',
	'America/Metlakatla',
	'America/Nome',
	'America/Sitka',
	'America/Yakutat',
	'Pacific/Pitcairn',
	'America/Creston',
	'America/Dawson',
	'America/Dawson_Creek',
	'America/Fort_Nelson',
	'America/Hermosillo',
	'America/Los_Angeles',
	'America/Mazatlan',
	'America/Phoenix',
	'America/Santa_Isabel',
	'America/Tijuana',
	'America/Vancouver',
	'America/Whitehorse',
	'America/Bahia_Banderas',
	'America/Belize',
	'America/Boise',
	'America/Cambridge_Bay',
	'America/Chihuahua',
	'America/Costa_Rica',
	'America/Denver',
	'America/Edmonton',
	'America/El_Salvador',
	'America/Guatemala',
	'America/Inuvik',
	'America/Managua',
	'America/Merida',
	'America/Mexico_City',
	'America/Monterrey',
	'America/Regina',
	'America/Swift_Current',
	'America/Tegucigalpa',
	'America/Yellowknife',
	'Pacific/Easter',
	'Pacific/Galapagos',
	'America/Bogota',
	'America/Cancun',
	'America/Cayman',
	'America/Chicago',
	'America/Coral_Harbour',
	'America/Eirunepe',
	'America/Guayaquil',
	'America/Indiana/Knox',
	'America/Indiana/Tell_City',
	'America/Jamaica',
	'America/Lima',
	'America/Matamoros',
	'America/Menominee',
	'America/North_Dakota/Beulah',
	'America/North_Dakota/Center',
	'America/North_Dakota/New_Salem',
	'America/Ojinaga',
	'America/Panama',
	'America/Rainy_River',
	'America/Rankin_Inlet',
	'America/Resolute',
	'America/Rio_Branco',
	'America/Winnipeg',
	'America/Anguilla',
	'America/Antigua',
	'America/Aruba',
	'America/Asuncion',
	'America/Barbados',
	'America/Blanc-Sablon',
	'America/Boa_Vista',
	'America/Campo_Grande',
	'America/Caracas',
	'America/Cuiaba',
	'America/Curacao',
	'America/Detroit',
	'America/Dominica',
	'America/Grand_Turk',
	'America/Grenada',
	'America/Guadeloupe',
	'America/Guyana',
	'America/Havana',
	'America/Indiana/Marengo',
	'America/Indiana/Petersburg',
	'America/Indiana/Vevay',
	'America/Indiana/Vincennes',
	'America/Indiana/Winamac',
	'America/Indianapolis',
	'America/Iqaluit',
	'America/Kentucky/Monticello',
	'America/Kralendijk',
	'America/La_Paz',
	'America/Louisville',
	'America/Lower_Princes',
	'America/Manaus',
	'America/Marigot',
	'America/Martinique',
	'America/Montreal',
	'America/Montserrat',
	'America/Nassau',
	'America/New_York',
	'America/Nipigon',
	'America/Pangnirtung',
	'America/Port-au-Prince',
	'America/Port_of_Spain',
	'America/Porto_Velho',
	'America/Puerto_Rico',
	'America/Santiago',
	'America/Santo_Domingo',
	'America/St_Barthelemy',
	'America/St_Kitts',
	'America/St_Lucia',
	'America/St_Thomas',
	'America/St_Vincent',
	'America/Thunder_Bay',
	'America/Toronto',
	'America/Tortola',
	'America/Araguaina',
	'America/Argentina/La_Rioja',
	'America/Argentina/Rio_Gallegos',
	'America/Argentina/Salta',
	'America/Argentina/San_Juan',
	'America/Argentina/San_Luis',
	'America/Argentina/Tucuman',
	'America/Argentina/Ushuaia',
	'America/Bahia',
	'America/Belem',
	'America/Buenos_Aires',
	'America/Catamarca',
	'America/Cayenne',
	'America/Cordoba',
	'America/Fortaleza',
	'America/Glace_Bay',
	'America/Goose_Bay',
	'America/Halifax',
	'America/Jujuy',
	'America/Maceio',
	'America/Mendoza',
	'America/Moncton',
	'America/Montevideo',
	'America/Paramaribo',
	'America/Punta_Arenas',
	'America/Recife',
	'America/Santarem',
	'America/Sao_Paulo',
	'America/Thule',
	'Antarctica/Palmer',
	'Antarctica/Rothera',
	'Atlantic/Bermuda',
	'Atlantic/Stanley',
	'America/St_Johns',
	'America/Godthab',
	'America/Miquelon',
	'America/Noronha',
	'Atlantic/South_Georgia',
	'Atlantic/Cape_Verde',
	'Africa/Abidjan',
	'Africa/Accra',
	'Africa/Bamako',
	'Africa/Banjul',
	'Africa/Bissau',
	'Africa/Casablanca',
	'Africa/Conakry',
	'Africa/Dakar',
	'Africa/El_Aaiun',
	'Africa/Freetown',
	'Africa/Lome',
	'Africa/Monrovia',
	'Africa/Nouakchott',
	'Africa/Ouagadougou',
	'Africa/Sao_Tome',
	'America/Danmarkshavn',
	'America/Scoresbysund',
	'Atlantic/Azores',
	'Atlantic/Reykjavik',
	'Atlantic/St_Helena',
	'Africa/Algiers',
	'Africa/Bangui',
	'Africa/Brazzaville',
	'Africa/Douala',
	'Africa/Kinshasa',
	'Africa/Lagos',
	'Africa/Libreville',
	'Africa/Luanda',
	'Africa/Malabo',
	'Africa/Ndjamena',
	'Africa/Niamey',
	'Africa/Porto-Novo',
	'Africa/Tunis',
	'Atlantic/Canary',
	'Atlantic/Faeroe',
	'Atlantic/Madeira',
	'Europe/Dublin',
	'Europe/Guernsey',
	'Europe/Isle_of_Man',
	'Europe/Jersey',
	'Europe/Lisbon',
	'Europe/London',
	'Africa/Blantyre',
	'Africa/Bujumbura',
	'Africa/Cairo',
	'Africa/Ceuta',
	'Africa/Gaborone',
	'Africa/Harare',
	'Africa/Johannesburg',
	'Africa/Juba',
	'Africa/Khartoum',
	'Africa/Kigali',
	'Africa/Lubumbashi',
	'Africa/Lusaka',
	'Africa/Maputo',
	'Africa/Maseru',
	'Africa/Mbabane',
	'Africa/Tripoli',
	'Africa/Windhoek',
	'Antarctica/Troll',
	'Arctic/Longyearbyen',
	'Asia/Gaza',
	'Asia/Hebron',
	'Europe/Amsterdam',
	'Europe/Andorra',
	'Europe/Belgrade',
	'Europe/Berlin',
	'Europe/Bratislava',
	'Europe/Brussels',
	'Europe/Budapest',
	'Europe/Busingen',
	'Europe/Copenhagen',
	'Europe/Gibraltar',
	'Europe/Kaliningrad',
	'Europe/Ljubljana',
	'Europe/Luxembourg',
	'Europe/Madrid',
	'Europe/Malta',
	'Europe/Monaco',
	'Europe/Oslo',
	'Europe/Paris',
	'Europe/Podgorica',
	'Europe/Prague',
	'Europe/Rome',
	'Europe/San_Marino',
	'Europe/Sarajevo',
	'Europe/Skopje',
	'Europe/Stockholm',
	'Europe/Tirane',
	'Europe/Vaduz',
	'Europe/Vatican',
	'Europe/Vienna',
	'Europe/Warsaw',
	'Europe/Zagreb',
	'Europe/Zurich',
	'Africa/Addis_Ababa',
	'Africa/Asmera',
	'Africa/Dar_es_Salaam',
	'Africa/Djibouti',
	'Africa/Kampala',
	'Africa/Mogadishu',
	'Africa/Nairobi',
	'Antarctica/Syowa',
	'Asia/Aden',
	'Asia/Amman',
	'Asia/Baghdad',
	'Asia/Bahrain',
	'Asia/Beirut',
	'Asia/Damascus',
	'Asia/Famagusta',
	'Asia/Jerusalem',
	'Asia/Kuwait',
	'Asia/Nicosia',
	'Asia/Qatar',
	'Asia/Riyadh',
	'Europe/Athens',
	'Europe/Bucharest',
	'Europe/Chisinau',
	'Europe/Helsinki',
	'Europe/Istanbul',
	'Europe/Kiev',
	'Europe/Kirov',
	'Europe/Mariehamn',
	'Europe/Minsk',
	'Europe/Moscow',
	'Europe/Riga',
	'Europe/Simferopol',
	'Europe/Sofia',
	'Europe/Tallinn',
	'Europe/Uzhgorod',
	'Europe/Vilnius',
	'Europe/Volgograd',
	'Europe/Zaporozhye',
	'Indian/Antananarivo',
	'Indian/Comoro',
	'Indian/Mayotte',
	'Asia/Tehran',
	'Asia/Baku',
	'Asia/Dubai',
	'Asia/Muscat',
	'Asia/Tbilisi',
	'Asia/Yerevan',
	'Europe/Astrakhan',
	'Europe/Samara',
	'Europe/Saratov',
	'Europe/Ulyanovsk',
	'Indian/Mahe',
	'Indian/Mauritius',
	'Indian/Reunion',
	'Asia/Kabul',
	'Antarctica/Mawson',
	'Asia/Aqtau',
	'Asia/Aqtobe',
	'Asia/Ashgabat',
	'Asia/Atyrau',
	'Asia/Dushanbe',
	'Asia/Karachi',
	'Asia/Oral',
	'Asia/Qyzylorda',
	'Asia/Samarkand',
	'Asia/Tashkent',
	'Asia/Yekaterinburg',
	'Indian/Kerguelen',
	'Indian/Maldives',
	'Asia/Calcutta',
	'Asia/Colombo',
	'Asia/Katmandu',
	'Antarctica/Vostok',
	'Asia/Almaty',
	'Asia/Bishkek',
	'Asia/Dhaka',
	'Asia/Omsk',
	'Asia/Qostanay',
	'Asia/Thimphu',
	'Asia/Urumqi',
	'Indian/Chagos',
	'Asia/Rangoon',
	'Indian/Cocos',
	'Antarctica/Davis',
	'Asia/Bangkok',
	'Asia/Barnaul',
	'Asia/Hovd',
	'Asia/Jakarta',
	'Asia/Krasnoyarsk',
	'Asia/Novokuznetsk',
	'Asia/Novosibirsk',
	'Asia/Phnom_Penh',
	'Asia/Pontianak',
	'Asia/Saigon',
	'Asia/Tomsk',
	'Asia/Vientiane',
	'Indian/Christmas',
	'Asia/Brunei',
	'Asia/Choibalsan',
	'Asia/Hong_Kong',
	'Asia/Irkutsk',
	'Asia/Kuala_Lumpur',
	'Asia/Kuching',
	'Asia/Macau',
	'Asia/Makassar',
	'Asia/Manila',
	'Asia/Shanghai',
	'Asia/Singapore',
	'Asia/Taipei',
	'Asia/Ulaanbaatar',
	'Australia/Perth',
	'Australia/Eucla',
	'Asia/Chita',
	'Asia/Dili',
	'Asia/Jayapura',
	'Asia/Khandyga',
	'Asia/Pyongyang',
	'Asia/Seoul',
	'Asia/Tokyo',
	'Asia/Yakutsk',
	'Pacific/Palau',
	'Australia/Adelaide',
	'Australia/Broken_Hill',
	'Australia/Darwin',
	'Antarctica/DumontDUrville',
	'Antarctica/Macquarie',
	'Asia/Ust-Nera',
	'Asia/Vladivostok',
	'Australia/Brisbane',
	'Australia/Currie',
	'Australia/Hobart',
	'Australia/Lindeman',
	'Australia/Melbourne',
	'Australia/Sydney',
	'Pacific/Guam',
	'Pacific/Port_Moresby',
	'Pacific/Saipan',
	'Pacific/Truk',
	'Australia/Lord_Howe',
	'Antarctica/Casey',
	'Asia/Magadan',
	'Asia/Sakhalin',
	'Asia/Srednekolymsk',
	'Pacific/Bougainville',
	'Pacific/Efate',
	'Pacific/Guadalcanal',
	'Pacific/Kosrae',
	'Pacific/Norfolk',
	'Pacific/Noumea',
	'Pacific/Ponape',
	'Antarctica/McMurdo',
	'Asia/Anadyr',
	'Asia/Kamchatka',
	'Pacific/Auckland',
	'Pacific/Fiji',
	'Pacific/Funafuti',
	'Pacific/Kwajalein',
	'Pacific/Majuro',
	'Pacific/Nauru',
	'Pacific/Tarawa',
	'Pacific/Wake',
	'Pacific/Wallis',
	'Pacific/Chatham',
	'Pacific/Apia',
	'Pacific/Enderbury',
	'Pacific/Fakaofo',
	'Pacific/Tongatapu',
	'Pacific/Kiritimati',
];
