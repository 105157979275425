import React, { PropsWithChildren, ReactElement, cloneElement, useEffect, useRef, useState } from 'react';
import { Button } from './Button/Button';
import { Dialog, DialogProps } from './Dialog';

type ConfirmDialogProps = PropsWithChildren<{
	title?: string;
	text?: string | React.ReactNode;
	open?: boolean;
	confirmLabel?: string;
	cancelLabel?: string;
	primaryAction?: 'confirm' | 'cancel';
	onConfirm?: () => void;
	onCancel?: () => void;
	onAny?: (confirmed: boolean) => void;
	// Use to render the button that will open the dialog in case it can't be the first child. Use the show function to open the dialog.
	render?: ( show: () => void) => ReactElement;
	children?: ReactElement;
} & DialogProps>; 

/**
 * A convenience Dialog component that can wrap a button and hijack its onClick
 * handler to open the confirmation dialog instead. Pressing confirm will call
 * the original onClick handler.
 */
export function ConfirmDialog({
	open = false,
	title,
	text,
	confirmLabel='Confirm',
	cancelLabel='Cancel',
	primaryAction='confirm',
	onConfirm,
	onCancel,
	onAny,
	render,
	children,
	...rest
}: ConfirmDialogProps & React.HTMLAttributes<HTMLDivElement>) {
	// Take control over open state if has children
	const [isOpen, setIsOpen] = useState(children ? false : open);

	// Hijack child onClicks to open the dialog and replay them on confirm
	const child = children && React.Children.only(children);
	const originalOnClick = child?.props.onClick;
	let childClone
	if (children) {
		childClone = cloneElement(children as React.ReactElement, {
			onClick: (e: React.MouseEvent) => {
				e.preventDefault();
				setIsOpen(true);
			}
		});
	}

	const handleCancel = () => {
		onCancel?.();
		onAny?.(false);
		setIsOpen(false);
	}

	const handleConfirm = () => {
		onConfirm?.();
		onAny?.(true);
		originalOnClick?.();
		setIsOpen(false);
	}

	useEffect(() => {
		const onKeyDown = (e) => {
			if (!isOpen) return;
			if (e.key === 'Enter') {
				if (primaryAction === 'confirm') {
					handleConfirm();
				} else if (primaryAction === 'cancel') {
					handleCancel();
				}
			}
		};
		document.addEventListener('keydown', onKeyDown);
		return () => document.removeEventListener('keydown', onKeyDown);
	}, [isOpen]);

	return (
		<>
			{childClone}
			{render && render(() => setIsOpen(true))}
			<Dialog
				open={isOpen}
				title={title}
				text={text}
				onClose={handleCancel}
				buttons={
					<>
						<Button onClick={handleCancel} variant={primaryAction === 'cancel' ? 'contained' : 'outlined'}>{cancelLabel}</Button>
						<Button onClick={handleConfirm} variant={primaryAction === 'confirm' ? 'contained' : 'outlined'}>{confirmLabel}</Button>
					</>
				}
				{...rest}
			/>
		</>
	);
}
