import { Close, SaveAlt } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BULK_IMPORT_BASE_COLS, validateSystemsEligable } from 'relevant-shared/misc/bulkImport';
import { createCsv } from 'relevant-shared/misc/misc';
import { PublisherNode, SspPlacementNode } from 'relevant-shared/objects/pubTree';
import classes from '../../../api/classes';
import { Link } from '../../../components/Link/Link';
import BrowserUtils from '../../../lib/browserUtils';
import { useOperationToast } from '../../../hooks/useOperationToast';
import CsvImporter from './CsvImporter';
import CsvUploader from './CsvUploader';

const {
	BaseAdserver, GlobalSettingsObject, Publisher, BaseSsp,
} = classes;

export const exportPublisherStructureV2 = async () => {
	const [publishers, ssps, adservers, globalSettings] = await Promise.all([
		Publisher.list(),
		BaseSsp.list(),
		BaseAdserver.list(),
		GlobalSettingsObject.listOne(),
	]);
	validateSystemsEligable({ ssps, adservers, publishers });
	const pubNodes = publishers.map((pub) => new PublisherNode(pub, {
		ssps,
		adservers,
		globalSettings,
	}));
	const placs = pubNodes.map((n) => n.byType.PlacementNode).flat();
	const res = [];
	placs.forEach((plac) => {
		const {
			adserver, adserverSettings, placementType,
		} = plac;
		const sspPlacNodes = plac.children.filter((node) => node.ssp && node instanceof SspPlacementNode);
		let placId = '';
		if (adserver) {
			const { useSspPlacementsForIds } = adserver;
			const { correspondingSspId, placIdField } = adserver;
			placId = adserverSettings?.[placIdField];
			if (useSspPlacementsForIds && correspondingSspId) {
				placId = _.map(_.filter(sspPlacNodes, { sspId: correspondingSspId }), 'sspPlacementId').filter((s) => s).join('\n')
					|| placId;
			}
		}
		const rowBase = _.mapValues({
			publisherName: plac.publisherNode.name,
			site: plac.parentNode.name,
			placementName: plac.name,
			placementType: placementType?.name,
			adserver: adserver?.name,
			..._.zipObject(_.map(ssps, 'name'), Array(ssps.length).fill('')),
		}, (v) => v || '');
		const perSsp = Object.values(_.groupBy(sspPlacNodes, 'sspId'));
		const rowCount = _.maxBy(perSsp, 'length')?.length || 0;
		for (let i = 0; i < rowCount; i += 1) {
			const row = { ...rowBase, adserverPlacement: i ? '' : placId || '' };
			perSsp.forEach((arr) => {
				const { ssp, sspPlacementId } = arr[i] || {};
				if (ssp) {
					row[ssp.name] = sspPlacementId;
				}
			});
			res.push(row);
		}
	});
	const csv = createCsv(res, {
		...BULK_IMPORT_BASE_COLS,
		..._.zipObject(_.map(ssps, 'name'), _.map(ssps, 'name')),
	});
	BrowserUtils.downloadTextFile(csv, 'Publisher structure.csv');
};

export function CsvImportManager({ onImportComplete, onError, onClose }) {
	const [uploadedCsv, setUploadedCsv] = useState();

	const [csvDownloadOperation, csvDownloadInProgress] = useOperationToast({
		loadingMessage: 'Generating CSV...',
		errorMessage: (e) => `Failed to generate CSV. Please try again. (${e.message})`,
	});
	const handleCsvDownload = csvDownloadOperation(exportPublisherStructureV2);

	return (
		<div className="flex *:flex-1 justify-center">
			<div/>
			<div className="w-full">
				{ !uploadedCsv ? (
					<CsvUploader
						onUploadFile={(csv, file, err) => {
							if (err) {
								onError(err.message);
							} else {
								setUploadedCsv({ ...csv, file });
							}
						}}
					/>
				) : (
					<CsvImporter
						data={uploadedCsv.rows}
						columns={uploadedCsv.columns}
						dataIssues={uploadedCsv.issues.dataIssues}
						missingSspErrors={uploadedCsv.issues.missingSspErrors}
						expectedResult={uploadedCsv.expectedResult}
						file={uploadedCsv.file}
						onCancel={() => setUploadedCsv(null)}
						onDone={(success) => {
							setUploadedCsv(null);
							onImportComplete(success);
						}}
						onIgnore={(issuesToIgnore) => {
							const { issues: { dataIssues } } = uploadedCsv;
							const updatedDataIssues = { ...dataIssues };

							issuesToIgnore.forEach((issue) => {
								const index = Number(issue.row) - 1;
								updatedDataIssues[index][issue.key] = updatedDataIssues[index][issue.key]
									.filter(({ detailedMessage }) => (
										detailedMessage !== issue.detailedDescription
									));
							});

							setUploadedCsv({
								...uploadedCsv,
								issues: {
									...uploadedCsv.issues,
									dataIssues: updatedDataIssues,
								},
							});
						}}
					/>
				)}
			</div>
			<div>
				{ !uploadedCsv && (
					<div className="flex flex-col justify-between items-end h-full">
						<button className="text-grey-400 hover:text-grey-600" onClick={onClose}><Close /></button>
						<div className="flex flex-col items-end">
							<Link className="no-underline py-1 !font-normal" href="/src/assets/example_csv_import_file.csv">
								<DownloadIcon className="align-[-5pt]" />
								<span className="ml-1">Download example CSV</span>
							</Link>
							<Link className="no-underline py-1" onClick={handleCsvDownload}>
								<SaveAlt className="align-[-4pt]" />
								<span className="ml-1">Export current structure</span>
							</Link>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

CsvImportManager.propTypes = {
	onImportComplete: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
};
