import MuiDivider from '@mui/material/Divider';
import { COMP_PFX as compPfx } from 'relevant-shared/reportData/constants';
import { dbEncodeKeys, dbDecodeKeys } from 'relevant-shared/misc/misc';
import React from 'react';
import _ from 'lodash';

const MARGIN_LEFT = 50;

export const COMP_PFX = compPfx;

export const getIndentation = (depth, groupCount) => (groupCount === 1 ? null : {
	paddingLeft: 30 + (depth * MARGIN_LEFT),
});

export function Divider() {
	return (
		<MuiDivider
			style={{
				marginTop: '2px',
				marginBottom: '2px',
			}}
		/>
	);
}

export const joinNestedDataObjectKeysToStrings = (obj, parent, res = {}) => {
	const firstValueIsObject = (object) => (
		typeof object[_.first(Object.keys(object))] === 'object'
	);

	Object.keys(obj).forEach((key) => {
		const propName = parent
			? [parent, key].join('')
			: key;
		if (typeof obj[key] === 'object' && firstValueIsObject(obj[key])) {
			joinNestedDataObjectKeysToStrings(obj[key], propName, res);
		} else {
			res[propName] = true;
		}
	});
	return res;
};

export const getSortClickCount = (sortReversed, sortSum) => {
	if (!sortSum) return 0;
	if (sortReversed) return 1;
	return 2;
};

// CAUTION: the SEPARATOR is used to separate dimension keys and values in the
// table state. if you change its value, all previously saved table states will
// be lost
const SEPARATOR = ':::>>>';

export const combineLabels = (parentLabel, childLabel, dimensionType) => {
	const currentLabel = `${parentLabel ? SEPARATOR : ''}${dimensionType}${SEPARATOR}${childLabel}`;
	return `${parentLabel}${currentLabel}`;
};

export const keepOnlyUpToDateTableStates = (tableStateEntry, dimensionLabels, validEntryKeys) => {
	const entryKeysAndValues = tableStateEntry.split(SEPARATOR);
	const dimensionKeys = entryKeysAndValues.filter((field, index) => index % 2 === 0);
	const dimensionValues = entryKeysAndValues.filter((field, index) => index % 2 !== 0);

	const keysAreUpToDate = dimensionKeys
		.map((dimension, index) => dimensionLabels[index] === dimension)
		.every((element) => element);

	if (!keysAreUpToDate) {
		return {
			entry: tableStateEntry,
			upToDate: false,
		};
	}

	const valuesAreUpToDate = Boolean(validEntryKeys
		.find((entryKey) => entryKey.startsWith(dimensionValues.join(''))));

	return {
		entry: tableStateEntry,
		upToDate: valuesAreUpToDate,
	};
};

const convertTableState = (tableState, fn) => {
	if (!tableState) {
		return tableState;
	}
	const res = _.cloneDeep(tableState);
	fn(res.collapsed);
	fn(res.expanded);
	return res;
};

export const dbEncodedTableState = (tableState) => convertTableState(tableState, dbEncodeKeys);
export const dbDecodedTableState = (tableState) => convertTableState(tableState, dbDecodeKeys);
