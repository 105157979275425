import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import useRequest from '../../hooks/useRequest/useRequest';
import { Comment } from '../../api/relevant';

export const CommentContext = createContext([]);

export function CommentContextWrapper({ children, connectedTo, context }) {
	const [comments, setComments] = useState([]);
	const [setRequest, loading, error, reset] = useRequest({});
	useEffect(() => {
		async function fetchComments() {
			setRequest({
				requestFunction: () => Comment.call('listComments', { connectedTo, context }),
				requestCallback: (res) => {
					setComments(res);
				},
			});
		}
		fetchComments();
	}, [setRequest, connectedTo]);

	const addComment = async (title, body, connectedToId, context) => {
		const comment = {
			title, body, connectedTo: connectedToId, context,
		};
		setRequest({
			requestFunction: () => Comment.call('create', comment),
			requestCallback: (addedComment) => {
				const updatedCommentsList = [...comments, addedComment];
				setComments(updatedCommentsList);
			},
		});
	};

	const deleteComment = async (commentId) => {
		setRequest({
			requestFunction: () => Comment.call('delete', { id: commentId }),
			requestCallback: ({ id: deletedCommentId }) => {
				const commentsLeft = comments.filter(({ id }) => id !== deletedCommentId);
				setComments(commentsLeft);
			},
		});
	};

	return (
		<CommentContext.Provider
			value={{
				comments,
				loading,
				error,
				// Function to dismiss any request errors
				dismissErrors: reset,
				addComment,
				deleteComment,
			}}
		>
			{ children }
		</CommentContext.Provider>
	);
}

CommentContextWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	connectedTo: PropTypes.arrayOf(PropTypes.string),
	context: PropTypes.string,
};

CommentContextWrapper.defaultProps = {
	connectedTo: undefined,
	context: undefined,
};