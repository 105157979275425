import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '../TextField';
import Select from '../Select';
import ByType from './byType';
import Checkbox from '../Checkbox';
import { ContextWrapper } from '../Wrappers';
import { makeValidByObjectType } from './utils';

class FieldEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderInternal({ all, prev }) {
		const { model, field, inArray } = this.props;
		const type = ByType[model.type];
		const targetVar = type.isContainer ? 'fields' : 'defaultValue';
		const allowedTypes = Object.entries(ByType)
			.filter(([ty, obj]) => (!obj.isInternalType && !obj.typeForbidden?.(prev)) || model.type === ty)
			.map(([ty]) => ty);
		return (
			<>
				<Grid container spacing={3}>
					<Grid item xs={2}>
						<TextField
							{...field('name')}
							label="Name"
							required
							fullWidth
							margin="normal"
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							{...field('description')}
							label="Description"
							required
							fullWidth
							margin="normal"
						/>
					</Grid>
					<Grid item xs={2}>
						<Select
							{...field('type')}
							label="Type"
							items={allowedTypes.map((ty) => ({ label: ty, value: ty }))}
							onFieldUpdate={() => {
								model.resetDefault();
								makeValidByObjectType(model, prev);
								all[0].cleanupInvalid();
							}}
							fullWidth
							margin="normal"
						/>
					</Grid>
					<Grid item xs={type.inlineRender && !type.fieldEditNoInline ? 5 : 12}>
						{type.render({
							name: targetVar,
							model,
							label: 'Default Value',
							field,
							inArray,
						})}
					</Grid>
				</Grid>
				<Grid container spacing={3} sx={{ mt: -2 }}>
					<Grid item xs={3}>
						<TextField
							{...field('uiGroup')}
							label="UI Group"
							fullWidth
							margin="normal"
						/>
					</Grid>
					{!inArray && (
						<>
							<Grid item xs={2}>
								<Checkbox
									label="Display in headers"
									textFieldAlign
									{...field('displayInHeader')}
								/>
							</Grid>
							<Grid item xs={2}>
								<Checkbox
									label="Override as default"
									textFieldAlign
									{...field('ownAsDefault')}
								/>
							</Grid>
						</>
					)}
					{!type.isContainer && model.type !== 'Boolean' && (
						<Grid item xs={2}>
							<Checkbox
								label="Is required"
								textFieldAlign
								{...field('isRequired')}
							/>
						</Grid>
					)}
					<Grid item xs={3}>
						<Checkbox
							label="Omit when default"
							textFieldAlign
							{...field('omitWhenDefault')}
						/>
					</Grid>
				</Grid>
			</>
		);
	}

	render() {
		const { model } = this.props;
		// Keep track of the "stack" of fields that we're rendering
		const nextContext = ({ curr, prev = [] } = {}) => {
			const res = {
				curr: model,
				prev: [...prev, ...(curr ? [curr] : [])],
			};
			res.all = [...res.prev, model];
			return res;
		};
		return (
			<ContextWrapper
				noClone
				name="fieldEditor"
				transform={nextContext}
				content={(obj) => this.renderInternal(obj)}
			/>
		);
	}
}

FieldEditor.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	inArray: PropTypes.bool,
};

FieldEditor.defaultProps = {
	inArray: false,
};

export default FieldEditor;
