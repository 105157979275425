import React from 'react';
import {
	Box,
	FormControlLabel,
	Checkbox,
	Divider,
	Tooltip,
	IconButton,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PropTypes from 'prop-types';
import CopyableTextField from '../../CopyableTextField';

function ReportSharing({ isPublic, onIsPublicChange, shareUrl }) {
	return (
		<Box sx={{
			pt: 3,
			pb: 3,
			pl: 2,
			pr: 2,
		}}
		>
			{(isPublic !== undefined && onIsPublicChange !== undefined) ? (
				<Box sx={{ mb: 4 }}>
					<Box sx={{ mb: 2 }}>
						<FormControlLabel
							label="Public"
							control={(
								<Checkbox
									checked={isPublic}
									onChange={onIsPublicChange}
								/>
							)}
						/>
					</Box>
					<Divider />
				</Box>
			) : null }
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<CopyableTextField
					textFieldProps={{
						readOnly: true,
						name: 'textfield_reportShareUrl',
						value: shareUrl,
						label: 'Report URL',
					}}
				/>
				<Tooltip title="Open in new tab">
					<IconButton
						href={shareUrl}
						target="_blank"
						onClick={() => window.open(shareUrl)}
						size="large"
						sx={{ ml: 0.5 }}
					>
						<OpenInNewIcon />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
	);
}

ReportSharing.propTypes = {
	isPublic: PropTypes.bool,
	onIsPublicChange: PropTypes.func,
	shareUrl: PropTypes.string.isRequired,
};

ReportSharing.defaultProps = {
	isPublic: undefined,
	onIsPublicChange: undefined,
};

export default ReportSharing;
