import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import Constants from 'relevant-shared/reportData/constants';
import DateUtils from 'relevant-shared/misc/dateUtils';
import Grid from '@mui/material/Grid';

import ReportChart from './reportChart';
import PieChartNotSupportedAlert from './PieChartNotSupportedAlert';
import {
	getChartGroupBy,
	getColor,
	reduceOpacity,
} from './utils';
import SystemData from '../../lib/systemData';
import { fmtNum } from '../../lib/numberUtils';
import ReportRow from './ReportRow';

const ignoreFunctions = (a, b) => (
	(typeof a === 'function' && typeof b === 'function')
		? true
		: undefined
);

const isEqualExcludingFunctions = (a, b) => _.isEqualWith(a, b, ignoreFunctions);

class ChartsWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	shouldComponentUpdate(nextProps) {
		const {
			sums,
			referenceData,
			chartSettings,
			chartSettingsOpen,
			events,
			columns,
		} = this.props;

		const hasSameData = _.isEqualWith(
			referenceData,
			nextProps.referenceData,
			isEqualExcludingFunctions,
		);

		const sumsHaveChanged = (sums !== nextProps.sums);
		const columnsChanged = (columns !== nextProps.columns);
		const chartSettingsHasChanged = !_.isEqual(nextProps.chartSettings, chartSettings);
		const displayChartSettings = nextProps.chartSettingsOpen !== chartSettingsOpen;
		const eventsHaveChanged = nextProps.events !== events;
		return !hasSameData || sumsHaveChanged || chartSettingsHasChanged || displayChartSettings || eventsHaveChanged || columnsChanged;
	}

	getEventData() {
		const { chartSettings, calculator } = this.props;
		const { showEventLines, selectedEventTags = [] } = chartSettings;
		if (!showEventLines) {
			return null;
		}
		const { start, end } = calculator.getStartEnd();
		const afterEnd = DateUtils.fullDay(end, 1);
		let { events: availEvents } = this.props;
		if (!availEvents?.length) {
			return null;
		}
		const { timezone } = calculator.settings;
		if (timezone && timezone !== 'UTC') {
			const getOfs = DateUtils.getTimezoneOffsetter({ timezone });
			const adjusted = (d) => new Date(d.getTime() + getOfs(d.getTime()));
			availEvents = availEvents.map((ev) => ({ ...ev, date: adjusted(ev.date) }));
		}
		availEvents = availEvents.filter(({ tags, date }) => {
			if (date < start || date >= afterEnd) {
				return false;
			}
			return !selectedEventTags.length || tags.some((tag) => selectedEventTags.includes(tag));
		});
		if (!availEvents.length) {
			return null;
		}
		const events = [];
		let prevDateFormatted;
		let idx = 0;
		const onDateLabel = (date, formatted) => {
			while (idx < availEvents.length && date > availEvents[idx].date) {
				events.push({
					...availEvents[idx],
					closestDate: prevDateFormatted,
				});
				idx += 1;
			}
			prevDateFormatted = formatted;
		};

		return {
			onDateLabel,
			generateAllEvents: () => {
				onDateLabel(afterEnd);
				return events;
			},
		};
	}

	getRenderData() {
		const {
			sums,
			currencyOf,
			descriptionOf,
			chartSettings,
			calculator,
		} = this.props;
		const { isLineChart, isOnlyDate, chartGroupBy } = calculator.properties();

		const eventData = this.getEventData();

		const chartFormatted = calculator.formatReport(Math.min(2, chartGroupBy.length));
		const labels = calculator.getAllLabels(eventData);

		const showLegend = !chartSettings.hideLegend && isLineChart && !isOnlyDate;
		const { groupBy, granularity } = calculator.settings;
		const shortRange = Constants.SHORT_TIME_RANGES[granularity];
		let containsTrendMetric = false;

		const levels = Math.min(calculator.settings.groupBy.length, 2);

		const renderData = sums.map((sumType) => {
			const { chartData, options, isTrendMetric } = this.getChartData(
				calculator,
				chartFormatted,
				levels,
				sumType,
				labels,
				currencyOf(sumType),
				isLineChart,
			);

			containsTrendMetric = containsTrendMetric || isTrendMetric;
			return {
				chartData,
				options,
				asForecastedPerc: calculator.getAsForecastedPerc(),
				clearBeforePerc: calculator.getClearBeforePerc(sumType),
				currency: currencyOf(sumType),
				description: descriptionOf(sumType),
				isTrendMetric,
			};
		});

		return {
			renderData,
			isLineChart,
			showLegend,
			containsTrendMetric,
			events: eventData?.generateAllEvents?.() || [],
			shortRange,
			groupBy,
			levels,
		};
	}

	getChartData(calculator, data, levels, sumVal, initLabels, currency) {
		const { BACKGROUND_DEFAULT_COL, HOVER_BACKGROUND_DEFAULT_COL } = SystemData.genericData.systemSettings;
		const { pie, chartSettings, columns } = this.props;
		const { groupBy } = calculator.settings;
		const { maxLabels, options } = calculator.getLabelOptionsForColumns(columns);
		const { isLineChart } = calculator.properties();

		const { arr, labels } = calculator.trimExcessLabels(
			data.obj,
			data.arr,
			levels,
			sumVal,
			maxLabels,
			initLabels,
		);

		const isOneLevel = (levels === 1);
		const thisLabels = labels[groupBy[levels - 1]];

		const getOneDimensionalData = (element, isComp) => (
			thisLabels.map((label) => (
				(label === element.label)
					? calculator.calculateSumVal(element.data, sumVal, undefined, isComp)
					: null
			)));

		const getTwoDimensionalData = (element, isComp, byLabel) => (
			thisLabels.map((label) => {
				const obj = byLabel[label];
				return (obj)
					? calculator.calculateSumVal(obj.data, sumVal, undefined, isComp)
					: null;
			})
		);

		const getData = (isOneLevel)
			? getOneDimensionalData
			: getTwoDimensionalData;

		const getBackGroundColor = (isComp) => {
			if (isLineChart && isComp) {
				return 'black';
			}
			return (isComp)
				? reduceOpacity(BACKGROUND_DEFAULT_COL)
				: BACKGROUND_DEFAULT_COL;
		};
		const arrayLength = arr.length;
		const dataToDataSet = (isComp, extraOptions) => arr.map((element, index) => {
			const byLabel = _.keyBy(element.data, 'label');
			const color = getColor(index, isComp, arrayLength);
			const dashSize = 5 / columns;

			return {
				borderColor: isOneLevel ? getBackGroundColor(isComp) : color,
				backgroundColor: isOneLevel ? getBackGroundColor(isComp) : color,
				hoverBackgroundColor: isOneLevel ? HOVER_BACKGROUND_DEFAULT_COL : color,
				hideLegend: isComp,
				label: `${element.label}${isComp ? ' (comparison)' : ''}`,
				pointRadius: 2,
				...(!pie && { stack: isComp ? 'comp' : 'not comp' }),
				tension: 0.5,
				borderDash: isComp ? [dashSize, dashSize] : undefined,
				data: getData(element, isComp, byLabel),
				hoverBorderWidth: isLineChart ? 10 : 0,
				getTooltipText: (tooltipItem) => {
					const { dataset, raw } = tooltipItem;
					return `  ${dataset.label}: ${fmtNum(raw)} ${currency}`;
				},
				...chartSettings,
				...extraOptions,
			};
		});

		const getDataSet = (isComp, extraOptions) => {
			const dataSet = dataToDataSet(isComp, extraOptions);
			const isSingleDimension = (groupBy.length === 1);

			if (!isSingleDimension) {
				return dataSet;
			}
			// if 'date' is only dimension, bar charts might have hundreds of
			// columns. in this case, reduce dataset to a single array
			// (much faster to process; doesn't matter much for line charts)
			return [{
				...dataSet[0],
				data: dataSet.map(({ data: d }) => d.filter((d) => d !== null)).flat(),
				label: isComp ? 'Compared Period' : 'Current Period',
				pointRadius: 1,
				...chartSettings,
				...extraOptions,
			}];
		};

		const chartData = {
			labels: labels[groupBy[levels - 1]],
			datasets: (calculator.settings.useCompareTo)
				// Comparing two dates, make sure that one chart is filled
				? getDataSet(false, { fill: isOneLevel	 }).concat(getDataSet(true))
				: getDataSet(false),
		};

		const sums = Constants[calculator.settings.type].CALCULATED_SUMS;
		const isTrendMetric = sums && sumVal in sums && 'trendMetric' in sums[sumVal];
		return { chartData, options, isTrendMetric };
	}

	render() {
		const {
			pie,
			onDisableChartType,
			chartSettings,
			calculator,
			columns,
		} = this.props;

		const {
			renderData,
			showLegend,
			containsTrendMetric,
			events,
			shortRange,
			levels,
		} = this.getRenderData();

		const { chartType } = calculator.properties({ pie });

		const charts = renderData.map(({
			chartData,
			options,
			asForecastedPerc,
			clearBeforePerc,
			currency,
			description,
			isTrendMetric,
		}) => (pie && isTrendMetric ? null : (
			{
				value: (
					<ReportRow
						aspectRatio={pie ? (options?.pie?.aspectRatio || 1) : (options?.aspectRatio || 1)}
						scaleByHeight={pie}
					>
						<ReportChart
							key={`chart-${currency}`}
							groupBy={calculator.settings.groupBy}
							type={chartType}
							data={chartData}
							options={{
								...options, asForecastedPerc, clearBeforePerc,
							}}
							currency={currency}
							description={description}
							showLegend={showLegend}
							stacked={chartSettings.stacked}
							barChartType={chartSettings.barChartType}
							showEventLines={!!chartSettings.showEventLines}
							events={events}
							shortRange={shortRange}
							levels={levels}
						/>
					</ReportRow>
				),
				id: `chart-${currency}`,
			}
		)));

		const renderRows = () => (
			<Grid
				container
				spacing={{
					xs: 1,
				}}
			>
				{charts.map((chart) => (
					<Grid
						item
						xs={12 / columns}
						key={`chartItem-${chart.id}}`}
						sx={{ mb: 2 }}
					>
						{chart.value}
					</Grid>
				))}
			</Grid>
		);

		return (
			<>

				{pie && containsTrendMetric && (
					<Box marginTop={2}>
						<PieChartNotSupportedAlert
							onFix={() => onDisableChartType(chartType)}
							suggestion="Turn off pie chart"
						>
							The pie chart visualisation is
							not supported for trend metrics
						</PieChartNotSupportedAlert>
					</Box>
				)}

				{renderRows()}
			</>
		);
	}
}

ChartsWrapper.propTypes = {
	calculator: PropTypes.objectOf(PropTypes.any).isRequired,
	sums: PropTypes.arrayOf(PropTypes.string).isRequired,
	currencyOf: PropTypes.func.isRequired,
	descriptionOf: PropTypes.func.isRequired,
	referenceData: PropTypes.arrayOf(PropTypes.any).isRequired,
	onDisableChartType: PropTypes.func,
	pie: PropTypes.bool,
	chartSettings: PropTypes.shape({
		stacked: PropTypes.bool.isRequired,
		// Fill is not actually used except for comparison check in shouldComponentUpdate
		// Weird I know...
		fill: PropTypes.bool.isRequired,
		groupedDimensions: PropTypes.arrayOf(PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})),
		selectedEventTags: PropTypes.arrayOf(PropTypes.string),
		barChartType: PropTypes.oneOf(['horizontal', 'vertical']),
		showEventLines: PropTypes.bool,
		hideLegend: PropTypes.bool,
	}).isRequired,
	chartSettingsOpen: PropTypes.bool,
	events: PropTypes.arrayOf(PropTypes.any),
	columns: PropTypes.number,
};

ChartsWrapper.defaultProps = {
	onDisableChartType() {},
	pie: false,
	chartSettingsOpen: false,
	events: [],
	groupBy: [],
	columns: 1,
};

export default ChartsWrapper;
