import FutureCustomizer from 'relevant-shared/reportData/customizers/futureCustomizer';
import FrontendProgrammaticCustomizer from './frontendProgrammaticCustomizer';

class FrontendFutureCustomizer extends FutureCustomizer {
	renderFilterHeader(...args) {
		const customizer = new FrontendProgrammaticCustomizer(this.reportData);
		return customizer.renderFilterHeader(...args);
	}
}

export default FrontendFutureCustomizer;
