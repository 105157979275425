import {
	Box
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { PublisherSearchBar } from '../../pages/AdminPublisherList/PublisherSearchBar';
import TopBarHeader from './TopBarHeader';

function TopBarPublisherAccountsOptions({
	title,
}) {
	return (
		<Box sx={{
			width: '100%',
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'space-between',
		}}
		>
			<TopBarHeader
				title={title}
			/>
			<div className="mr-4 min-w-[450px]">
				{/* Temporary thinner search bar until we update the top bar design */}
				<PublisherSearchBar searchBarClass="h-[40px]" placeholder="Search for publishers, sites, placements, and more" />
			</div>
		</Box>
	);
}

TopBarPublisherAccountsOptions.propTypes = {
	title: PropTypes.string.isRequired,
};

export default TopBarPublisherAccountsOptions;
