import PropTypes from 'prop-types';
import React from 'react';

const style = {
	borderBottom: '1px solid #eee',
	paddingBottom: '0.5em',
	color: 'rgba(0, 0, 0, 0.87)',
};

function SectionHeader(props) {
	return <h2 style={style}>{props.children}</h2>;
}

SectionHeader.propTypes = {
	children: PropTypes.node,
};

export default SectionHeader;
