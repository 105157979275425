import PropTypes from 'prop-types';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import { getFormPropsFor } from '../../containers/Form';

const NO_VALUE_SELECTED = '__no_value_selected';
let instanceCounter = 0;

class Select extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: `Select-${++instanceCounter}`,
		};
		this._change = this.change.bind(this);
	}

	change(event) {
		const { name, onChange } = this.props;
		let { value } = event.target;
		if (value === NO_VALUE_SELECTED) {
			value = null;
		}
		onChange?.({ target: { name, value } }, this.props);
	}

	render() {
		const {
			label,
			nonSelected,
			help,
			name,
			items,
			disabled,
			className,
			style,
			nonEditable,
			fullWidth,
			required,
			margin,
			textFieldAlign,
			hideLabelIfEmpty,
			labelProps,
			selectProps,
		} = this.props;
		const { id } = this.state;
		const { error, value } = getFormPropsFor(this);
		const helpText = error || help;
		return (
			<FormControl
				error={Boolean(error)}
				disabled={disabled || nonEditable}
				className={className}
				style={{ ...(textFieldAlign ? { marginTop: 16 } : null), ...style }}
				fullWidth={fullWidth}
				required={required}
				margin={margin}
			>
				{/* TODO: Remove hideLabelIEmpty.
				InputLabel should not be rendered if empty, even without hideLabelIfEmpty. But there's MANY <Select>, so make sure not to break the design... */}
				{ ((!hideLabelIfEmpty || label) && (
					<InputLabel {...labelProps} id={`${id}-label`}>
						{label}
					</InputLabel>
				))}
				<MuiSelect
					labelId={`${id}-label`}
					aria-describedby={helpText ? `${id}-help` : undefined}
					name={name}
					value={value == null ? (nonSelected ? NO_VALUE_SELECTED : '') : value}
					onChange={this._change}
					autoWidth={!fullWidth}
					// this label is actually hidden,
					// just a trick to make the label not overlined
					// in future we should use Material TextField for this
					label={label}
					{...selectProps}
				>
					{nonSelected != null && <MenuItem value={NO_VALUE_SELECTED}>{nonSelected}</MenuItem>}
					{items.map((item) => (
						<MenuItem
							key={item.value}
							value={item.value}
							disabled={Boolean(item.disabled)}
							style={item.style}
						>
							{item.label}
						</MenuItem>
					))}
				</MuiSelect>
				{helpText && (
					<FormHelperText id={`${id}-help`}>
						{helpText}
					</FormHelperText>
				)}
			</FormControl>
		);
	}
}

Select.propTypes = {
	name: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	label: PropTypes.string.isRequired,
	error: PropTypes.string,
	nonSelected: PropTypes.string,
	help: PropTypes.string,
	items: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.any.isRequired,
		value: PropTypes.any.isRequired,
		disabled: PropTypes.bool,
	})),
	disabled: PropTypes.bool,
	nonEditable: PropTypes.bool,
	className: PropTypes.string,
	style: PropTypes.object,
	fullWidth: PropTypes.bool,
	required: PropTypes.bool,
	margin: PropTypes.oneOf(['none', 'dense', 'normal']),
	textFieldAlign: PropTypes.bool,
	hideLabelIfEmpty: PropTypes.bool,
	selectProps: PropTypes.object,
};

Select.defaultProps = {
	nonSelected: undefined,
	disabled: false,
	nonEditable: false,
	className: '',
	style: {},
	fullWidth: false,
	required: false,
	margin: undefined,
	textFieldAlign: false,
	hideLabelIfEmpty: false,
	selectProps: undefined,
};

export default Select;
