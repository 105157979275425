import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import styles from './styles.css';
import LogsContainer from '../../containers/LogsContainer';

class AdministratorJobLogs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className={styles.pageContentWrapper}>
				<Container maxWidth="xl">
					<LogsContainer />
				</Container>
			</div>
		);
	}
}

AdministratorJobLogs.propTypes = {
	history: PropTypes.object.isRequired,
};

AdministratorJobLogs.defaultProps = {

};

export default AdministratorJobLogs;
