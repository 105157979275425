import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import { Link } from '../Link/Link';
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';

function HelpOptions() {
	return (
		<>
			<MenuItem>
				<ListItemIcon>
					<SupportAgentRoundedIcon fontSize="small" />
				</ListItemIcon>
				<Link
					href="https://help.relevant-digital.com/knowledge/kb-tickets/new"
					target="_blank"
					underline="none"
				>
					Contact support
				</Link>
			</MenuItem>
			<Divider />
			<MenuItem>
				<ListItemIcon>
					<NewReleasesRoundedIcon fontSize="small" />
				</ListItemIcon>
				<Link
					href="https://help.relevant-digital.com/knowledge/release-and-feature-logs"
					target="_blank"
					underline="none"
				>
					Release and Feature logs
				</Link>
			</MenuItem>
			<MenuItem>
				<ListItemIcon>
					<SchoolRoundedIcon fontSize="small" />
				</ListItemIcon>
				<Link
					href="https://help.relevant-digital.com/knowledge"
					target="_blank"
					underline="none"
				>
					Relevant Yield documentation
				</Link>
			</MenuItem>
			<MenuItem>
				<ListItemIcon>
					<InventoryRoundedIcon fontSize="small" />
				</ListItemIcon>
				<Link
					href="https://www.relevant-digital.com/relevantyield"
					target="_blank"
					underline="none"
				>
					Relevant Yield product page
				</Link>
			</MenuItem>
		</>
	);
}

export default HelpOptions;
