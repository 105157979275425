const { S2S_BIDDER_SUFFIX } = require('../misc/sharedConstants');

const RLV_PREFIX = '_rlv_';
const RLV_TARGET_PB_CFG_ID_KEY = 'relevant_pb_cfg_id';
const RLV_TARGET_PB_CFG_NAME_KEY = 'relevant_pb_cfg_name';

/**
 * An easy way to "generate" this list is by opening this URL in the browser:
 * - https://gitlab.relevant-digital.com/einar/prebid-server/-/tree/master/static/bidder-info
 * Then paste the following line in the console

	  console.info([...document.querySelectorAll('.tree-item-file-name > a > [class="position-relative"]')]
			.map((e) => e.innerText)
			.filter((s) => s.includes('yaml'))
			.map((s) => `\t'${s.split('.yaml')[0]}',`)
			.join('\n'))

 * ...and double-check that it seems right.
 */
const PBS_BIDDER_SUPPORT_LIST = [
	'33across',
	'aax',
	'aceex',
	'acuityads',
	'adelement',
	'adf',
	'adform',
	'adgeneration',
	'adhese',
	'adkernel',
	'adkernelAdn',
	'adman',
	'admixer',
	'adnuntius',
	'adocean',
	'adoppler',
	'adot',
	'adpone',
	'adprime',
	'adquery',
	'adrino',
	'adsinteractive',
	'adsyield',
	'adtarget',
	'adtelligent',
	'adtrgtme',
	'advangelists',
	'adview',
	'adxcg',
	'adyoulike',
	'aidem',
	'aja',
	'algorix',
	'alkimi',
	'amx',
	'apacdex',
	'appnexus',
	'appstock',
	'appush',
	'aso',
	'audienceNetwork',
	'automatad',
	'avocet',
	'axis',
	'axonix',
	'bcmint',
	'beachfront',
	'beintoo',
	'bematterfull',
	'between',
	'beyondmedia',
	'bidgency',
	'bidmachine',
	'bidmyadz',
	'bidscube',
	'bidstack',
	'bizzclick',
	'bliink',
	'blue',
	'bluesea',
	'bmtm',
	'boldwin',
	'brave',
	'bwx',
	'cadent_aperture_mx',
	'ccx',
	'coinzilla',
	'colossus',
	'compass',
	'connectad',
	'consumable',
	'conversant',
	'copper6',
	'cpmstar',
	'criteo',
	'cwire',
	'datablocks',
	'decenterads',
	'deepintent',
	'definemedia',
	'dianomi',
	'dmx',
	'dxkulture',
	'e_volution',
	'edge226',
	'embimedia',
	'emtv',
	'emx_digital',
	'eplanning',
	'epom',
	'epsilon',
	'evtech',
	'finative',
	'flipp',
	'freewheel-ssp',
	'freewheelssp',
	'frvradn',
	'gamma',
	'gamoshi',
	'globalsun',
	'gothamads',
	'greedygame',
	'grid',
	'gumgum',
	'huaweiads',
	'iionads',
	'imds',
	'impactify',
	'improvedigital',
	'indicue',
	'infytv',
	'inmobi',
	'interactiveoffers',
	'invibes',
	'iqx',
	'iqzone',
	'ix',
	'janet',
	'jdpmedia',
	'jixie',
	'kargo',
	'kayzen',
	'kidoz',
	'kiviads',
	'krushmedia',
	'lemmadigital',
	'limelightDigital',
	'lm_kiviads',
	'lockerdome',
	'logan',
	'logicad',
	'loyal',
	'lunamedia',
	'mabidder',
	'madvertise',
	'magnite',
	'markapp',
	'marsmedia',
	'mediafuse',
	'medianet',
	'mgid',
	'mgidX',
	'minutemedia',
	'mobfoxpb',
	'mobilefuse',
	'motorik',
	'nextmillennium',
	'nobid',
	'oms',
	'onetag',
	'openweb',
	'openx',
	'operaads',
	'orbidder',
	'outbrain',
	'ownadx',
	'pangle',
	'pgam',
	'pgamssp',
	'playdigo',
	'pubmatic',
	'pubnative',
	'pulsepoint',
	'playdigo',
	'pwbid',
	'quantumdex',
	'readpeak',
	'relevantdigital',
	'revcontent',
	'richaudience',
	'rise',
	'roulax',
	'rtbhouse',
	'rubicon',
	'sa_lunamedia',
	'screencore',
	'seedingAlliance',
	'sharethrough',
	'silvermob',
	'silverpush',
	'smaato',
	'smartadserver',
	'smarthub',
	'smartrtb',
	'smartx',
	'smartyads',
	'smilewanted',
	'smrtconnect',
	'sonobi',
	'sovrn',
	'sovrnXsp',
	'sspBC',
	'streamkey',
	'stroeerCore',
	'suntContent',
	'synacormedia',
	'taboola',
	'tappx',
	'teads',
	'telaria',
	'theadx',
	'tpmn',
	'trafficgate',
	'tredio',
	'triplelift',
	'triplelift_native',
	'trustedstack',
	'trustx',
	'ucfunnel',
	'undertone',
	'unicorn',
	'unruly',
	'valueimpression',
	'videobyte',
	'videoheroes',
	'vidoomy',
	'viewdeos',
	'visiblemeasures',
	'visx',
	'vox',
	'vrtcal',
	'vungle',
	'xeworks',
	'xtrmqb',
	'yahooAds',
	'yahooAdvertising',
	'yahoossp',
	'yandex',
	'yeahmobi',
	'yieldlab',
	'yieldmo',
	'yieldone',
	'zeroclickfraud',
	'zeta_global_ssp',
	'zmaticoo',
];

const rlvPrefix = (name) => `${RLV_PREFIX}${name}`;
const renameToInternalParams = (arr) => arr.map((e) => ({ ...e, name: rlvPrefix(e.name) }));
const s2sBidderSuffix = (name) => `${name}${S2S_BIDDER_SUFFIX}`;
const s2sBidderUnSuffix = (name) => (
	name.endsWith(S2S_BIDDER_SUFFIX) ? name.slice(0, name.length - S2S_BIDDER_SUFFIX.length) : name
);

const BASE_INTERNAL_BID_TAG_FIELDS = [
	{
		type: 'Boolean',
		fields: [],
		description: 'Disable Bidder',
		defaultValue: false,
		name: 'disableBidder',
	},
	{
		type: 'Boolean',
		fields: [],
		description: 'Disable Outstream video in multi-format placements',
		defaultValue: false,
		name: 'mixedFormatDisableVideo',
	},
];

const BASE_BIDDING_TYPE_OPTION = {
	type: 'String',
	fields: [],
	description: 'Bidding type',
	defaultValue: 'client',
	name: 'biddingType',
	hasOptions: true,
	isRequired: true,
	options: [
		{ name: 'client', label: 'Client-side' },
	],
};

const BASE_USER_ID_MODULE_PARAMS = [
	{
		type: 'Object',
		name: 'storage',
		description: 'Storage',
		fields: [
			{
				type: 'String',
				name: 'type',
				description: 'Type',
				hasOptions: true,
				options: [
					{
						name: 'html5',
						label: 'Local storage',
					},
					{
						name: 'cookie',
						label: 'Cookie',
					},
				],
				defaultValue: 'html5',
				isRequired: true,
			},
			{
				type: 'Number',
				name: 'expires',
				description: 'Storage time (days)',
				isRequired: true,
				defaultValue: 30,
			},
			{
				type: 'Number',
				name: 'refreshInSeconds',
				description: 'Time before refresh (seconds)',
				isRequired: false,
				defaultValue: 86400,
			},
		],
	},
	{
		type: 'Array',
		name: 'bidders',
		description: 'Allowed bidders',
		isRequired: false,
		fields: [{
			type: 'String',
			name: 'bidder',
			description: 'Bidder',
			defaultValue: '',
		}],
	},
];

module.exports = {
	RLV_PREFIX,
	RLV_TARGET_PB_CFG_ID_KEY,
	RLV_TARGET_PB_CFG_NAME_KEY,
	BASE_INTERNAL_BID_TAG_FIELDS,
	BASE_BIDDING_TYPE_OPTION,
	rlvPrefix,
	renameToInternalParams,
	S2S_BIDDER_SUFFIX,
	s2sBidderSuffix,
	s2sBidderUnSuffix,
	PBS_BIDDER_SUPPORT_LIST,
	BASE_USER_ID_MODULE_PARAMS,
};
