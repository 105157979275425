import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Disabler from '../Disabler';
import styles from './styles.css';

class TreeSelect extends React.Component {
	constructor(props) {
		super(props);
		const { mustExpand, initSelect, getNodeId } = props;
		this.state = {
			expanded: {},
			current: initSelect,
			showArchived: false,
		};
	}

	onSelectItem(ev, node, nodeId, isLeaf) {
		const orgEv = ev.orgEv || ev; // future-proof if this is turned into a real event object
		const {
			getNodeId,
			onSelectionChange,
			selectedObjects,
			shouldExclude,
		} = this.props;
		const { lastSelectedId, lastSelectedWasAdd } = this.state;
		orgEv.stopPropagation();
		orgEv.preventDefault();
		let newSelected;
		const leafs = this.allLeafs(node);
		const selectState = this.selectStateById[nodeId];
		const isAdd = selectState !== true && !(
			// Something seems to make it impossible to select all nodes, when clicking twice in a row on an
			// "indeterminate" select-box - de-select instead
			lastSelectedId === nodeId && lastSelectedWasAdd
		);
		if (isAdd) {
			newSelected = _.unionBy(selectedObjects, leafs, getNodeId);
		} else {
			newSelected = _.differenceBy(selectedObjects, leafs, getNodeId);
		}
		onSelectionChange(newSelected.filter((n) => !shouldExclude(n)), {
			node, isAdd, isLeaf,
		});
		this.setState({ lastSelectedId: nodeId, lastSelectedWasAdd: isAdd });
	}

	allLeafs(node) {
		const { getIsLeaf, getChildren } = this.props;
		const childs = getChildren(node) || [];
		if (childs.length) {
			return _.flatten(childs.map((c) => this.allLeafs(c)));
		}
		return node.id !== 'select_all' && (!getIsLeaf || getIsLeaf(node)) ? [node] : [];
	}

	renderChildren(children, level) {
		return (
			children.map((n) => this.renderNode(n, level))
		);
	}

	renderNode(node, level) {
		const { expanded, current } = this.state;
		const {
			getNodeId,
			getChildren,
			getLabel,
			getStyle,
			getCssClass,
			getIsLeaf,
			onChange,
			onSelectionChange,
			selectedObjects,
			shouldExclude,
			selectAllStartLevel,
			render,
			noDisabler,
			readOnlyCheckbox,
		} = this.props;
		const nodeId = getNodeId(node);
		const isExpanded = !!expanded[nodeId];
		const children = _.sortBy(getChildren(node) || [], (n) => getLabel(n));
		const hasChildren = !!children.length;
		const isLeaf = getIsLeaf ? getIsLeaf(node) : !hasChildren;
		const toggleExpand = () => {
			this.setState({ expanded: { ...expanded, [nodeId]: !isExpanded } });
		};
		return (
			<Disabler key={nodeId} disabled={!!shouldExclude(node) && !noDisabler}>
				<div
					style={getStyle(node, current)}
					className={getCssClass(node)}
				>
					<ListItem
						button
						onClick={(e) => {
							if (onChange) {
								let cancel = false;
								onChange(node, () => { cancel = true; });
								if (!cancel) {
									this.setState({ current: node });
								}
							} else { // Default behavior: select list item
								this.onSelectItem(e, node, nodeId, isLeaf);
							}
						}}

						onDoubleClick={toggleExpand}
					>
						{selectedObjects && (level >= selectAllStartLevel || isLeaf) && (
							<ListItemIcon>
								<Checkbox
									disabled={readOnlyCheckbox}
									checked={Boolean(this.selectStateById[nodeId])}
									indeterminate={this.selectStateById[nodeId] === null}
									edge="start"
									name="select_all"
									inputProps={{ 'aria-labelledby': `TreeSelect-${nodeId}-label` }}
									onChange={(e) => this.onSelectItem(e, node, nodeId, isLeaf)}
								/>
							</ListItemIcon>
						)}
						<ListItemText id={`TreeSelect-${nodeId}-label`}>{render(node, getLabel(node))}</ListItemText>
						{hasChildren ? (
							<ListItemSecondaryAction>
								<IconButton
									onClick={toggleExpand}
									edge="end"
									aria-label={isExpanded ? 'expand' : 'collapse'}
									size="large"
								>
									{isExpanded ? <ExpandLess /> : <ExpandMore />}
								</IconButton>
							</ListItemSecondaryAction>
						) : null}
					</ListItem>
					{hasChildren && (
						<Collapse in={isExpanded} unmountOnExit>
							<List
								dense
								disablePadding
								className={styles.indent}
							>
								{this.renderChildren(children, level + 1)}
							</List>
						</Collapse>
					)}
				</div>
			</Disabler>
		);
	}

	render() {
		const {
			hideRoot, getChildren, getNodeId, selectedObjects, shouldExclude, expandOnce,
		} = this.props;
		let { rootNode, rootChildren = [] } = this.props;
		const { expanded } = this.state;
		this.selectedById = {};
		if (rootNode && hideRoot) {
			rootChildren = getChildren(rootNode);
			rootNode = null;
		}
		const expandOnceIds = (expandOnce || []).map((n) => getNodeId(n));
		if (!_.isEqual(this.oldExpandOnceIds, expandOnceIds)) {
			expandOnceIds.forEach((id) => {
				expanded[id] = true;
			});
			this.oldExpandOnceIds = expandOnceIds;
		}
		this.selectStateById = {};
		const initSelectState = (node) => {
			const children = getChildren(node) || [];
			const id = getNodeId(node);
			let res;
			if (!children.length) {
				res = !!this.selectedById[id];
			} else {
				const childResults = {};
				children.filter((n) => !shouldExclude(n)).forEach((child) => {
					const childRes = initSelectState(child);
					if (childRes !== undefined) {
						childResults[childRes] = true;
					}
				});
				if (_.isEmpty(childResults)) {
					return undefined;
				}
				const keys = _.keys(childResults);
				res = keys.length > 1 || childResults.null ? null : !!childResults.true;
			}
			this.selectStateById[id] = res;
			return res;
		};
		if (selectedObjects) {
			this.selectedById = _.keyBy(selectedObjects, (n) => getNodeId(n));
			(rootNode ? [rootNode] : rootChildren).filter((n) => !shouldExclude(n)).forEach((node) => initSelectState(node));
		}
		return (
			<List dense>
				{ rootNode ? this.renderNode(rootNode, 0) : this.renderChildren(rootChildren, 0)}
			</List>
		);
	}
}

TreeSelect.propTypes = {
	getChildren: PropTypes.func.isRequired,
	getLabel: PropTypes.func.isRequired,
	getNodeId: PropTypes.func.isRequired,
	getIsLeaf: PropTypes.func,
	rootNode: PropTypes.object,
	rootChildren: PropTypes.array,
	hideRoot: PropTypes.bool,
	expandOnce: PropTypes.array,
	initSelect: PropTypes.object,
	onChange: PropTypes.func,
	onSelectionChange: PropTypes.func,
	selectedObjects: PropTypes.array,
	shouldExclude: PropTypes.func,
	selectAllStartLevel: PropTypes.number,
	render: PropTypes.func,
	noDisabler: PropTypes.bool,
	getStyle: PropTypes.func,
	getCssClass: PropTypes.func,
	readOnlyCheckbox: PropTypes.bool,
};

TreeSelect.defaultProps = {
	rootNode: null,
	rootChildren: null,
	getIsLeaf: undefined,
	hideRoot: false,
	expandOnce: [],
	initSelect: null,
	onChange: undefined,
	onSelectionChange: () => {},
	selectedObjects: undefined,
	shouldExclude: () => false,
	selectAllStartLevel: 0,
	render: (node, label) => label,
	noDisabler: false,
	getStyle: () => ({}),
	getCssClass: () => null,
	readOnlyCheckbox: false,
};

export default TreeSelect;
