import { Delete, Edit, Upload } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import classes from '../../api/classes';
import { Cdn } from '../../api/relevant';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { Button } from '../../components/Button/Button';
import CdnEditor from '../../components/CdnEditor';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import DataGrid from '../../components/DataGrid';
import Modal from '../../components/Modal';
import { StyledTooltip } from '../../components/StyledTooltip';
import { PageContentWrapper, SimpleFormContainer } from '../../components/Wrappers';
import { useToast } from '../../hooks/useToast';
import { CdnFileEditor } from './CdnFileEditor';
import { useCdnFilesQuery } from './query';
const { CdnFile } = classes;

export function CdnPage() {
	const { data: files } = useCdnFilesQuery();
	const [rows, setRows] = useState([]);
	const queryClient = useQueryClient();
	const [editFile, setEditFile] = useState(null);
	const [, showToast] = useToast();

	const deleteMutation = useMutation({
		mutationFn: async (file: typeof CdnFile) => {
			await file.delete();
		},
		onSuccess: () => {
			showToast({
				message: <>
					<p>File deleted successfully.</p>
					<p>CDN cache is being purged. It may take a few minutes for the changes to take effect.</p>
				</>,
				status: 'success',
				timeout: 5,
			});
		},
		onError: (e) => {
			showToast({ message: `Failed to delete file: ${e.message ?? e.error?.message}`, status: 'error' });
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['cdnFiles'] });
		},
	});

	const editDialog = useRef(null);

	useEffect(() => {
		(async () => {
			setRows(await Promise.all(
				files?.map(async (file) => ({
					file,
					id: file.id,
					path: file.path,
					url: file.url,
					size: file.size,
					lastModified: moment(file.lastModified).format('YYYY-MM-DD HH:mm:ss'),
				})) ?? []
			));
		})();
	}, [files]);

	return (
		<PageContentWrapper>

			{editFile && (
				<Modal
					open
					title={`${editFile.isNew ? 'New File' : 'Edit File'}`}
					onSubmit={() => {
						editDialog.current.submit();
					}}
					onCancel={() => {
						setEditFile(null);
					}}
					dialogProps={{
						fullWidth: true,
						maxWidth: 'md',
					}}
				>
					<CdnFileEditor
						ref={editDialog}
						file={editFile}
						onSuccess={() => {
							showToast({
								message: <>
									<p>File uploaded successfully.</p>
									<p>CDN cache is being purged. It may take a few minutes for the changes to take effect.</p>
								</>,
								status: 'success',
								timeout: 5,
							});
							setEditFile(null);
						}}
						onError={(e) => {
							showToast({ message: `Failed to upload file: ${e.message ?? e.error?.message}`, status: 'error' });
						}}
					/>
				</Modal>
			)}

			<Paper className="mb-4">
				<Box className="p-4">
					<Typography variant="h2" className="mb-2">CDN Static Files</Typography>
					<Typography variant="body2" className="mb-4">Manage static files that are served from the CDN.</Typography>
					<DataGrid
						definitions={[
							{ key: 'path', title: 'Name', flex: 2.5 },
							{ key: 'url', title: 'URL', flex: 5, renderCell: ({ value }) => (
								<span className="overflow-hidden text-ellipsis text-nowrap">{value}</span>
							) },
							{ key: 'lastModified', title: 'Last Modified', flex: 2 },
							{ key: 'size', title: 'Size', flex: 1, renderCell: ({ value }) => (
								<span>{value} B</span>
							) },
							{ key: '_actions', title: 'Actions', flex: 0, renderCell: ({ row }) => (
								<>
									<StyledTooltip title="Edit" disableInteractive>
										<Button
											icon={<Edit />}
											onClick={() => {
												setEditFile(row.file);
											}}
										/>
									</StyledTooltip>	
									<ConfirmDialog
										text="Are you sure you want to delete this file?"
										confirmLabel="Delete"
										render={(open) => (
											<StyledTooltip title="Delete" disableInteractive>
												<Button
													icon={<Delete />}
													onClick={open}
												/>
											</StyledTooltip>
										)}
										onConfirm={() => {
											deleteMutation.mutate(row.file);
										}}
									/>
								</>
							) },
						]}
						identifier={(row) => row.id}
						data={rows}
						hideFooter
					/>
					<div className="flex gap-2">
						<ActionButton
							className="mt-4"
							onClick={() => {
								setEditFile(new CdnFile());
							}}
							label="Upload File"
							icon={<Upload />}
						/>
					</div>
				</Box>
			</Paper>

			<SimpleFormContainer
				component={CdnEditor}
				init={async (op) => {
					const cdn = await Cdn.listOne();
					return {
						model: cdn,
						onSubmit: async () => {
							await op.reload(async () => {
								await cdn.update();
								queryClient.invalidateQueries({ queryKey: ['cdnFiles'] });
							});
						},
					};
				}}
			/>
		</PageContentWrapper>
	);
}
