import React from 'react';
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from '@mui/material';
import useFilters from '../context/hooks/useFilters';

function SharingStateFilter() {
	const { currentSharingStateFilter, onSharingStateChange } = useFilters();

	return (
		<FormControl sx={{ mb: 2 }} size="small" fullWidth>
			<InputLabel id="select-sharing-state-label">Sharing state</InputLabel>
			<Select
				labelId="select-sharing-state-label"
				label="Sharing state"
				value={currentSharingStateFilter}
				onChange={onSharingStateChange}
			>
				<MenuItem value="all">All</MenuItem>
				<MenuItem value="public">Public</MenuItem>
				<MenuItem value="private">Private</MenuItem>
			</Select>
		</FormControl>
	);
}

export default SharingStateFilter;
