import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FieldDataObject from './fieldDataObject';
import { ActionButton } from '../ActionButton/ActionButton';
import TextEditor from './textEditor';
import PrebidBuildFormModal from '../PrebidBuild/PrebidBuildFormModal';
import PrebidBuildManager from '../PrebidBuild/PrebidBuildManager';

class TagDataEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderAudienceTag() {
		const { info } = this.props;
		const tagStyle = { backgroundColor: 'lightgray', borderRadius: '10px', padding: '10px' };
		return (
			<Card>
				<CardContent>
					<Typography variant="h4">
						Info
					</Typography>
					Place on site:&nbsp;&nbsp;
					<span style={tagStyle}>
						{'<script type="application/javascript" async src="'}
						<a href={info.cdnUrl} target="_blank" rel="noreferrer">{info.cdnUrl}</a>
						{'"></script>'}
					</span>
				</CardContent>
			</Card>
		);
	}

	renderProgrammaticTag() {
		const { info, model } = this.props;
		const tag = `<script>
(function() {
	// Increase this time in milliseconds to delay loading of the analytics script
	// But notice that doing that might increase the loss of analytic events
	var LOAD_TIMEOUT_MS = 0;

	var EVENTS = [ 'auctionInit','auctionEnd','bidAdjustment','bidTimeout','bidRequested','bidResponse',
		'noBid', 'bidWon','bidderDone','setTargeting','requestBids','addAdUnits','adRenderFailed'];	
	var pbjsName = '${(model.pbjsName || {}).value || 'pbjs'}';
	window[pbjsName] = window[pbjsName] || {};
	var pbjs = window[pbjsName];
	pbjs.que = pbjs.que || [];
	window.relevantDigital = window.relevantDigital || {};
	relevantDigital.pbEventLog = [];
	pbjs.que.push(function () {
        if(pbjs.getEvents) {
            var start = (window.performance || {}).timeOrigin;
            pbjs.getEvents().forEach(function (e) {
                relevantDigital.pbEventLog.push({ ev: e.eventType, args: e.args, ts: start ? new Date(start + e.elapsedTime) : new Date() });
            });
        }
		EVENTS.forEach(function (ev) {
			pbjs.onEvent(ev, function(args) {
				relevantDigital.pbEventLog.push({ ev: ev, args: args, ts: new Date() });
			});
		});
	});
	setTimeout(function() {
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = '${info.cdnUrl}';		
		document.head.appendChild(script);
	}, LOAD_TIMEOUT_MS);
})();
</script>`;
		return (
			<Card>
				<CardContent>
					<Typography variant="h2">
						Analytics tag, place on site before loading prebid.js
					</Typography>
					<TextEditor width="100%" height="420px" name="programmaticTag" readOnly showGutter={false} value={tag} />
				</CardContent>
			</Card>
		);
	}

	render() {
		const {
			model, field, onCancel, submit, info, type, match, location,
		} = this.props;
		return (
			<>
				{info.isLeaf
					&& (
						<Grid item xs={12} style={{ padding: '0 0 24px 0' }}>
							{ type === 'programmatic' ? this.renderProgrammaticTag() : this.renderAudienceTag() }
						</Grid>
					)}
				<FieldDataObject model={model} field={field} />
				<Grid item>
					<ActionButton
						label="Ok"
						color="primary"
						onClick={submit}
					/>
				</Grid>
				<Grid item>
					<ActionButton
						label="Cancel"
						onClick={() => onCancel()}
						variant="text"
						color="primary"
					/>
				</Grid>
				<Route
					exact
					path={`${match.path}/add-prebid-build`}
					render={(p) => (
						<PrebidBuildFormModal
							match={match}
							location={location}
						/>
					)}
				/>
				<Route
					exact
					path={`${match.path}/manage-prebid-builds`}
					render={(p) => <PrebidBuildManager match={match} location={location} />}
				/>
			</>
		);
	}
}

TagDataEditor.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
	info: PropTypes.object.isRequired,
	type: PropTypes.oneOf(['programmatic', 'audience']).isRequired,
};

TagDataEditor.defaultProps = {
};

export default TagDataEditor;
