import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useToast } from './useToast';

export function useOperationToast({
	loadingMessage,
	// Delay before the loading message is shown, in seconds
	loadingMessageDelay = 1,
	successMessage,
	errorMessage,
	// Cooldown period after the operation is complete before disabled is set to false again, in seconds
	cooldown = 0,
}: {
	loadingMessage?: string,
	loadingMessageDelay?: number,
	successMessage?: string,
	errorMessage: string | ((e: Error) => string),
	cooldown?: number,
}) {

	const [toast, setToast] = useToast({});
	const [isLoading, setIsLoading] = useState(false);
	const timeout = useRef(null);

	useEffect(() => {
		return () => clearTimeout(timeout.current);
	}, []);


	const wrapper = (fn: () => Promise<void>) => {
		return async (e: React.MouseEvent) => {
			setIsLoading(true);

			if (loadingMessage) {
				timeout.current = setTimeout(() => {
					setToast({ message: loadingMessage, status: 'info', closeable: false, icon: <CircularProgress size="1.5rem" />, open: true });
				}, loadingMessageDelay * 1000);
			}

			try {
				await fn();
				setToast(successMessage ? { message: successMessage, status: 'success', open: true } : {});
			} catch (e) {
				const message = (errorMessage instanceof Function) ? errorMessage(e) : errorMessage;
				setToast({ message, status: 'error', open: true });
				console.error(e);
			}

			clearTimeout(timeout.current);
			setTimeout(() => {
				setIsLoading(false);
			}, cooldown * 1000);
		}
	}

	return [wrapper, isLoading] as const;
}