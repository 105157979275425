import PropTypes from 'prop-types';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

class BarChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { data, options } = this.props;
		return (
			<div>
				<Bar data={data} options={options} />
			</div>
		);
	}
}

BarChart.propTypes = {
	data: PropTypes.object.isRequired,
	options: PropTypes.object,
};

BarChart.defaultProps = {
	options: {
		legend: {
			display: false,
		},
	},
};

export default BarChart;
