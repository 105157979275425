import React from 'react';
import { observer } from 'mobx-react';
import { stores } from '../../stores';

function storeContainer(fx) {
	return function (ComposedComponent) {
		const ObservingComposedComponent = observer(ComposedComponent);

		@observer
		class StoreContainer extends React.Component {
			render() {
				let props = { ...this.props };

				if (fx) {
					const extraProps = fx(stores);
					props = { ...props, ...extraProps };
				} else {
					props = { ...props, ...stores };
				}

				return (
					<ObservingComposedComponent {...props} />
				);
			}
		}
		StoreContainer.wrappedComponent = ObservingComposedComponent;
		return StoreContainer;
	};
}

export default storeContainer;
