import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
	DateTimePicker,
} from '@mui/lab';
import {
	Autocomplete,
	Grid,
	Switch,
	TextField,
	FormControlLabel,
	FormControl,
	Box,
	Container,
	Paper,
	IconButton,
	Tooltip,
	CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import DateUtils from 'relevant-shared/misc/dateUtils';
import classes from '../../api/classes';
import useDebounce from '../../hooks/useDebounce';
import { stores } from '../../stores';
import { Button } from '../Button/Button';

const { Event } = classes;

function validateForm({ title, date }) {
	const errors = {};
	if (!title) {
		errors.title = 'Title is required';
	}
	if (!date || Number.isNaN(new Date(date).getTime())) {
		errors.date = 'Valid date is required';
	}
	return errors;
}

function EventForm({
	data,
	availableTags,
	onSubmit,
	onClose,
	onFormDataChange,
}) {
	const {
		date,
		title,
		description,
		tags,
		public: isPublic,
	} = data;

	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const isEditing = useRef(Boolean(title));

	const handleFormDataChange = (key, value) => {
		onFormDataChange({
			...data,
			[key]: value,
		});
	};

	// Don't display loading button state if action takes < 250ms to avoid stuttering
	const debouncedIsLoading = useDebounce(isLoading, 250);

	const currentUser = stores.identity.userId();

	return (
		<Paper elevation={5}>
			<Container
				maxWidth="xs"
				sx={{
					pl: 3,
					pr: 3,
					pb: 3,
					pt: 1,
				}}
			>
				<Box sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
				>
					<h2>{ isEditing.current ? 'Edit event' : 'New event' }</h2>
					<Tooltip title="Close">
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							value={title}
							label="Title"
							onChange={(e) => {
								handleFormDataChange('title', e.target.value);
							}}
							error={Boolean(errors.title)}
							helperText={errors.title || ''}
							autoFocus
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<DateTimePicker
							label="Date (UTC)"
							value={moment(DateUtils.toLocalLookalikeDate(date || new Date()))}
							ampm={false}
							onChange={(value) => {
								const val = value ? DateUtils.toUTCLookalikeDate(value) : null;
								handleFormDataChange('date', val);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									error={Boolean(errors.date)}
									helperText={errors.date || ''}
									required
								/>
							)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={description}
							label="Description"
							onChange={(e) => {
								handleFormDataChange('description', e.target.value);
							}}
							fullWidth
							multiline
						/>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							options={availableTags}
							value={tags}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Tags"
									InputProps={{
										...params.InputProps,
										type: 'search',
									}}
								/>
							)}
							onChange={(_, value) => {
								handleFormDataChange('tags', value);
							}}
							autoSelect
							fullWidth
							freeSolo
							multiple
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth>
							<FormControlLabel
								control={(
									<Switch
										name="Public"
										checked={isPublic}
										disabled={data?.authorInfo?.id && (currentUser !== data?.authorInfo?.id)}
										color="primary"
										onChange={(_, checked) => {
											handleFormDataChange('public', checked);
										}}
									/>
								)}
								label="Public"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Button
							onClick={async () => {
								const formErrors = validateForm(data);
								const hasErrors = Object.keys(formErrors).length > 0;
								if (!hasErrors) {
									setIsLoading(true);
									try {
										const res = await Event.fnCache.reset(() => Event.addOrUpdate(data));
										setIsLoading(false);
										onSubmit(res, isEditing.current ? 'update' : 'add');
									} catch {
										setIsLoading(false);
									}
								} else {
									setErrors(formErrors);
								}
							}}
							disabled={debouncedIsLoading}
							className="w-full"
						>
							{debouncedIsLoading ? <CircularProgress size={24} /> : 'Save'}
						</Button>
					</Grid>
				</Grid>
			</Container>
		</Paper>
	);
}

EventForm.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string,
		date: PropTypes.instanceOf(Date),
		description: PropTypes.string,
		tags: PropTypes.arrayOf(PropTypes.string),
		public: PropTypes.bool,
		authorInfo: PropTypes.shape({
			id: PropTypes.string,
		}),
	}).isRequired,
	availableTags: PropTypes.arrayOf(PropTypes.string).isRequired,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormDataChange: PropTypes.func.isRequired,
};
export default EventForm;
