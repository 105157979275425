/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import { MEDIA_TYPES, PAYMENT_TYPES, DEAL_TYPES } from 'relevant-shared/constants';
import { PREBID_CONFIGURATION_DIM } from 'relevant-shared/misc/sharedConstants';
import { sizeToLabel } from 'relevant-shared/reportData/utils';
import AllSeqObjectDimensions from 'relevant-shared/mappingDimensions/allSeqObjectDimensions';
import { FlagDimsByName } from 'relevant-shared/reportData/flagDimensions';
import { DynFilter, SimpleTypeSelect } from './standardFilterHelpers';
import ExpandSelector from '../ExpandSelector';
import * as relevantApi from '../../api/relevant';
import SiteSelect from '../SiteSelect';
import SegmentSelect from '../SegmentSelect';
import { stores } from '../../stores';
import CheckboxGroup from '../CheckboxGroup';
import { Scope } from '../Wrappers';
import FilterTypeSelector from './FilterTypeSelector';
import CountrySelect from '../CountrySelect';
import classes from '../../api/classes';

const { Dsp, Ssp, AdvertiserUserMapping } = relevantApi;
const relevant = relevantApi.DynamicExports;

const getCustomRenderers = ({
	customizer, filters, selectOptions, whereIn,
}) => customizer.getCustomFilters({ filters, selectOptions })
	.filter(({ name, forceShow }) => filters[name] || forceShow)
	.map(({
		label, name, rendered, noExpander, expandSelectorProps,
	}) => ({
		name,
		element: noExpander ? rendered : (
			<Grid item xs={12}>
				<ExpandSelector
					title={label}
					selected={(whereIn || {})[name] || []}
					{...expandSelectorProps}
				>
					{rendered}
				</ExpandSelector>
			</Grid>
		),
	}));

function StandardFilters(props) {
	const {
		selectOptions,
		filters,
		initSums,
		whereIn,
		placementLabels,
		customizer,
		includeSourceDbId,
		filterSelectionType,
		filterSettings,
		onChangeFilterSelectionType,
		...otherSettings
	} = props;
	const genericDynFilterProps = { selectOptions, initSums, otherSettings };
	const genericSimpleTypeSelectProps = { whereIn, selectOptions };
	const { filterByFuture } = filterSettings;
	const renderers = {
		publisherId: (
			<SiteSelect
				selectionType={filterSelectionType.publisherId}
				onChangeSelectionType={(e) => onChangeFilterSelectionType('publisherId', e.target.value)}
				{...selectOptions('publisherId')}
				useSearchFilter
			/>
		),
		cxSiteId: (
			<SiteSelect
				{...selectOptions('cxSiteId')}
				isSites
				useSearchFilter
			/>
		),
		segmentDmpId: (
			<SegmentSelect
				extraFilters={stores.identity.isAdministrator()}
				{...selectOptions('segmentDmpId')}
			/>
		),
		dsp: (
			<Scope
				content={(scope, { dsps }) => (
					<ExpandSelector
						title="DSPs / DMPs"
						selected={(whereIn || {}).dsp || []}
						fn={async () => scope.setState({ dsps: await Dsp.call('getAll') })}
					>
						{dsps && (
							<CheckboxGroup
								items={dsps.map((dsp) => ({ id: dsp.id, text: dsp.name }))}
								{...selectOptions('dsp')}
							/>
						)}
					</ExpandSelector>
				)}
			/>
		),
		siteId: (
			<SiteSelect
				selectionType={filterSelectionType.siteId}
				onChangeSelectionType={(e) => onChangeFilterSelectionType('siteId', e.target.value)}
				{...selectOptions('siteId')}
				isSspSites
				useSearchFilter
			/>
		),
		placementId: (
			<SiteSelect
				selectionType={filterSelectionType.placementId}
				onChangeSelectionType={(e) => onChangeFilterSelectionType('placementId', e.target.value)}
				{...selectOptions('placementId')}
				isPlacements
				useSearchFilter
			/>
		),
		sourceId: (
			<SiteSelect
				title={filterByFuture ? 'Adserver sources' : undefined}
				selectionType={filterSelectionType.sourceId}
				onChangeSelectionType={customizer.excludeNotAllowed('sourceId') ? undefined : (
					(e) => onChangeFilterSelectionType('sourceId', e.target.value)
				)}
				isSources
				getPublishersFn={async () => {
					const [pubs, ssps] = await Promise.all([
						classes.Reports.getPublishers(),
						filterByFuture ? classes.BaseSsp.getAll() : []]);
					return Object.assign(pubs, { extraData: { ssps } });
				}}
				filterFn={filterByFuture && (({ ssps }, { elm, path }) => (
					path.path !== 'ssps' || _.find(ssps, { id: elm.source })?.futureImporter?.enabled
				))}
				includeSourceDbId={includeSourceDbId}
				useSearchFilter
				{...selectOptions('sourceId')}
			/>),
		country: (
			<ExpandSelector
				title="Country"
				selected={(whereIn || {}).country || []}
			>
				<CountrySelect
					{...selectOptions('country')}
					includeTree
				/>
			</ExpandSelector>
		),
		[PREBID_CONFIGURATION_DIM]: (
			<SiteSelect
				{...selectOptions(PREBID_CONFIGURATION_DIM)}
				isPbConfigs
			/>
		),
		sspId: (
			<Scope
				content={(scope, { ssps }) => (
					<ExpandSelector
						title={filterByFuture ? 'Adservers' : 'SSPs'}
						selected={(whereIn || {}).sspId || []}
						fn={async () => scope.setState({ ssps: (await Ssp.call('getAll')).filter(filterSettings?.sspFilter || ((e) => e)) })}
					>
						<>
							<FilterTypeSelector
								onChange={(e) => onChangeFilterSelectionType('sspId', e.target.value)}
								value={filterSelectionType.sspId}
							/>
							{ssps && (
								<CheckboxGroup
									items={ssps.map((ssp) => ({ id: ssp.id, text: ssp.name }))}
									{...selectOptions('sspId')}
								/>
							)}
						</>
					</ExpandSelector>
				)}
			/>
		),
		..._.mapValues(FlagDimsByName, ({ name, desc, values }) => (
			<ExpandSelector
				title={desc}
				selected={(whereIn || {})[name] || []}
			>
				<FilterTypeSelector
					onChange={(e) => onChangeFilterSelectionType(name, e.target.value)}
					value={filterSelectionType.revenueType}
				/>
				<CheckboxGroup
					items={_.map(values, (v) => ({ text: v.desc, id: v.name }))}
					{...selectOptions(name)}
				/>
			</ExpandSelector>
		)),
		..._.transform(AllSeqObjectDimensions, (res, d) => {
			res[d.dimension] = (
				<DynFilter
					{...genericDynFilterProps}
					name={d.uiName}
					dimension={d.dimension}
					serverCall={(selected) => relevant[d.clsName].call('getObjects', { objNrs: selected })}
					otherSettings={otherSettings}
					onChangeFilterSelectionType={onChangeFilterSelectionType}
					filterSelectionType={filterSelectionType}
					createUsingDimObject={d.sysSpecific ? null : d}
					noObjectCreate
				/>
			);
		}, {}),
		size: (
			<DynFilter
				{...genericDynFilterProps}
				name="creative size"
				dimension="size"
				serverCall={(selected) => selected.map((sz) => ({ seq: sz, name: sizeToLabel(sz) }))}
				onChangeFilterSelectionType={onChangeFilterSelectionType}
				filterSelectionType={filterSelectionType}
				otherSettings={otherSettings}
			/>
		),
		placementLabel: (
			<ExpandSelector
				title="Placement label"
				selected={(whereIn || {}).placementLabel || []}
			>
				<CheckboxGroup
					items={_.map(placementLabels, (text, id) => ({ text, id }))}
					{...selectOptions('placementLabel')}
				/>
			</ExpandSelector>
		),
		userNr: (
			<Scope
				content={(scope, { userInfos }) => (
					<ExpandSelector
						title="Sales Representatives"
						selected={(whereIn || {}).userNr || []}
						fn={async () => scope.setState({ userInfos: await AdvertiserUserMapping.call('getUserInfos') })}
					>
						{userInfos && (
							<CheckboxGroup
								items={userInfos.map((info) => ({
									id: info.userNr,
									text: info.fullname,
								}))}
								{...selectOptions('userNr')}
							/>
						)}
					</ExpandSelector>
				)}
			/>
		),
		mediaTypeId: (
			<SimpleTypeSelect
				{...genericSimpleTypeSelectProps}
				title="Media Type"
				dimension="mediaTypeId"
				types={MEDIA_TYPES}
				filterSelectionType={filterSelectionType.mediaTypeId}
				onChangeFilterSelectionType={(e) => onChangeFilterSelectionType('mediaTypeId', e.target.value)}
			/>
		),
		paymentTypeId: (
			<SimpleTypeSelect
				{...genericSimpleTypeSelectProps}
				title="Payment Type"
				dimension="paymentTypeId"
				types={PAYMENT_TYPES}
				filterSelectionType={filterSelectionType.paymentTypeId}
				onChangeFilterSelectionType={(e) => onChangeFilterSelectionType('paymentTypeId', e.target.value)}
			/>
		),
		dealTypeId: (
			<SimpleTypeSelect
				{...genericSimpleTypeSelectProps}
				title="Deal Type"
				dimension="dealTypeId"
				types={DEAL_TYPES}
				filterSelectionType={filterSelectionType.dealTypeId}
				onChangeFilterSelectionType={(e) => onChangeFilterSelectionType('dealTypeId', e.target.value)}
			/>
		),
	};
	const allRenderers = [
		...Object.entries(renderers).filter(([k]) => filters[k]).map(
			([name, element]) => ({
				name,
				element: (
					<Grid item xs={12}>
						{element}
					</Grid>
				),
			}),
		),
		...getCustomRenderers(props),
	];
	// List filters primarily according to order in GROUP_BY_OPTIONS
	const order = _.keys(customizer.reportType?.GROUP_BY_OPTIONS);
	allRenderers.forEach((r, i) => {
		const nr = _.indexOf(order, r.name);
		r.orderNr = nr >= 0 ? nr : 1000 + i;
	});
	return (
		<>

			{_.sortBy(allRenderers, 'orderNr').map(({ name, element }) => (
				<Fragment key={name}>
					{element}
				</Fragment>
			))}
		</>
	);
}

StandardFilters.propTypes = {
	selectOptions: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired,
	initSums: PropTypes.array.isRequired,
	whereIn: PropTypes.object,
	placementLabels: PropTypes.object.isRequired,
	includeSourceDbId: PropTypes.bool.isRequired,
	filterSelectionType: PropTypes.objectOf(PropTypes.oneOf(['INCLUSIVE', 'EXCLUSIVE'])),
	onChangeFilterSelectionType: PropTypes.func.isRequired,
	filterSettings: PropTypes.object,
	customizer: PropTypes.object.isRequired,
};

StandardFilters.defaultProps = {
	whereIn: undefined,
	filterSelectionType: undefined,
	filterSettings: undefined,
};

export default StandardFilters;
