import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ActionButton } from '../ActionButton/ActionButton';
import OperationWrapper from '../OperationWrapper';
import BrowserUtils from '../../lib/browserUtils';
import { Link } from '../Link/Link';

/** When opening a second popup everything might (sometimes) go blank on Chrome Mobile
 * which seems related to the dialog setting document.body.style.overflow = 'hidden'
 * Setting it to auto and then switching back after a timeout seems to mitigate the situation */
const runInsaneOverflowHack = () => setTimeout(() => {
	const prev = document.body.style.overflow;
	document.body.style.overflow = 'auto';
	setTimeout(() => {
		if (document.body.style.overflow === 'auto') {
			document.body.style.overflow = prev;
		}
	});
});

class PopupSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = { expanded: this.props.expanded };
	}

	static getPopupDiv() {
		return _.last(PopupSelector.stack)?.ref;
	}

	hasFormErrors() {
		return BrowserUtils.selectorHasFormErrors(this);
	}

	componentDidMount() {
		this.popupMounted = true;
	}

	componentWillUnmount() {
		this.popupMounted = false;
		this.updateExpansion(false);
	}

	updateExpansion(expanded) {
		if (!!this.lastExpanded === !!expanded) {
			return;
		}
		_.remove(PopupSelector.stack, this);
		if (expanded) {
			if (PopupSelector.stack.length && BrowserUtils.isTouchDevice()) {
				runInsaneOverflowHack(); // See comment of function..
			}
			PopupSelector.stack.push(this);
		}
		this.lastExpanded = expanded;
	}

	updateState(newState) {
		const { onStateUpdate } = this.props;
		if (onStateUpdate) {
			onStateUpdate(newState);
		}
		if (this.popupMounted) {
			this.setState(newState);
		} else {
			Object.assign(this.state, newState);
		}
	}

	update(fn) {
		if (fn) {
			fn();
		}
		this.forceUpdate();
	}

	formUpdate(fn) {
		this.hasPendingFormUpdate = true;
		this.update(fn);
	}

	isExpanded() {
		const { onExpandChange, forceExpanded } = this.props;
		return (onExpandChange ? this.props : this.state).expanded || forceExpanded;
	}

	forceClose() {
		this.updateState(({ expanded: false }));
	}

	render() {
		const {
			title,
			selected,
			fn,
			onExpandChange,
			size,
			form,
			linkStyle,
			getSingleTitle,
			onApplyChanges,
			onCancel,
			hideLink,
			customLink,
			content,
			allowErrorsIfCancel,
			actionButtonProps,
			onAfterErrorMsg,
			okDisabled,
			okLabel,
			cancelLabel,
			okBtnStyle,
			textFieldAlign,
			canCancel,
			noLinkNbsp,
			nonSelectedString,
		} = this.props;
		const expanded = this.isExpanded();
		const { ready, opResult, isLoaded } = this.state;
		const selectedText = () => {
			const space = noLinkNbsp ? ' ' : '\xa0';
			if (!selected.length) {
				return nonSelectedString ? `${space}${nonSelectedString}` : '';
			} if (selected.length === 1 && getSingleTitle) {
				const singleTitle = getSingleTitle(selected[0]);
				if (singleTitle) {
					return `${space}(${singleTitle})`;
				}
			}
			return `${space}(${selected.length} selected)`;
		};
		const change = (newExpanded, isCancel) => {
			if (isCancel && !canCancel) {
				return false;
			}
			if (!newExpanded && (PopupSelector.stack.includes(this) && _.last(PopupSelector.stack) !== this)) {
				return false; // other popup closed
			}
			if (onExpandChange) {
				onExpandChange(newExpanded);
			} else {
				if (!newExpanded && this.hasFormErrors() && !(isCancel && allowErrorsIfCancel)) {
					return false;
				}
				this.updateState({ expanded: newExpanded });
			}
			if (!newExpanded && this.hasPendingFormUpdate && form) {
				this.hasPendingFormUpdate = false;
				form.update();
			}
			return true;
		};
		this.updateExpansion(expanded);

		return (
			<>
				{!hideLink && (
					customLink ? customLink(() => change(true)) : (
						<Link
							style={{
								...(textFieldAlign ? { marginTop: 20, display: 'inline-block' } : null),
								...linkStyle,
							}}
							className="inline-block text-center"
							onClick={(ev) => {
								ev.preventDefault();
								ev.stopPropagation();
								change(true);
							}}
						>
							{`${title}${selectedText()}`}
						</Link>
					))}
				<Dialog
					open={!!expanded}
					onClose={(ev) => {
						ev.stopPropagation();
						if (change(false, true)) {
							onCancel();
						}
					}}
					maxWidth={size || false}
					fullWidth={Boolean(size)}
					PaperProps={{
						onClick: (ev) => {
							ev.stopPropagation();
							ev.preventDefault();
						},
					}}
				>
					{title ? (
						<DialogTitle>
							{title}
						</DialogTitle>
					) : null}
					<DialogContent dividers>
						<OperationWrapper
							fn={async (op) => {
								this.op = op;
								const opResult = await fn();
								this.updateState({ ready: true, opResult, isLoaded: true });
							}}
							load={expanded}
							onAfterErrorMsg={onAfterErrorMsg}
							disableMode={isLoaded}
						>
							{(ready || isLoaded) && expanded && (
								<div
									ref={(elm) => { this.ref = elm; }}
									onClick={(ev) => ev.stopPropagation()}
								>
									{content ? content(this, opResult) : this.props.children}
								</div>
							)}
						</OperationWrapper>
					</DialogContent>
					<DialogActions>
						{!okBtnStyle && canCancel && onApplyChanges && (
							<ActionButton
								color="primary"
								variant="text"
								label={cancelLabel}
								onClick={(ev) => {
									ev.stopPropagation();
									ev.preventDefault();
									if (change(false, true)) {
										onCancel();
									}
								}}
							/>
						)}
						<ActionButton
							style={okBtnStyle}
							label={okLabel}
							color="primary"
							disabled={okDisabled || !ready}
							onClick={async (event) => {
								event.stopPropagation();
								event.preventDefault();
								if (this.hasFormErrors()) {
									return;
								}
								let hasApplied;
								this.updateState({ ready: false });
								try {
									await this.op.reload(async () => {
										if (hasApplied) {
											return;
										}
										hasApplied = true;
										let cancel;
										if (onApplyChanges) {
											await onApplyChanges({
												preventDefault: () => {
													cancel = true;
												},
											}, this);
										}
										if (!cancel) {
											change(false);
										}
									});
								} finally {
									this.updateState({ ready: true });
								}
							}}
							{...(_.isFunction(actionButtonProps) ? actionButtonProps() : actionButtonProps)}
						/>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

PopupSelector.stack = [];

PopupSelector.propTypes = {
	content: PropTypes.func,
	title: PropTypes.string,
	expanded: PropTypes.bool,
	selected: PropTypes.array,
	fn: PropTypes.func,
	onExpandChange: PropTypes.func,
	getSingleTitle: PropTypes.func,
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
	linkStyle: PropTypes.object,
	onApplyChanges: PropTypes.func,
	onCancel: PropTypes.func,
	hideLink: PropTypes.bool,
	form: PropTypes.object,
	forms: PropTypes.object,
	customLink: PropTypes.func,
	allowErrorsIfCancel: PropTypes.bool,
	actionButtonProps: PropTypes.any,
	forceExpanded: PropTypes.bool,
	onAfterErrorMsg: PropTypes.func,
	okDisabled: PropTypes.bool,
	okLabel: PropTypes.string,
	cancelLabel: PropTypes.string,
	okBtnStyle: PropTypes.object,
	textFieldAlign: PropTypes.bool,
	onStateUpdate: PropTypes.func,
	canCancel: PropTypes.bool,
	noLinkNbsp: PropTypes.bool,
	nonSelectedString: PropTypes.string,
};

PopupSelector.defaultProps = {
	content: undefined,
	title: '',
	selected: [],
	expanded: false,
	fn: () => {},
	onExpandChange: undefined,
	getSingleTitle: undefined,
	size: undefined,
	linkStyle: {},
	onApplyChanges: undefined,
	onCancel: () => {},
	hideLink: false,
	form: undefined,
	forms: undefined,
	customLink: undefined,
	allowErrorsIfCancel: false,
	actionButtonProps: {},
	forceExpanded: false,
	onAfterErrorMsg: undefined,
	okDisabled: false,
	okLabel: 'Ok',
	cancelLabel: 'Cancel',
	okBtnStyle: null,
	textFieldAlign: false,
	onStateUpdate: undefined,
	canCancel: true,
	noLinkNbsp: false,
	hideActions: false,
	nonSelectedString: undefined,
};

PopupSelector.useChangeOnOk = true;

export default PopupSelector;
