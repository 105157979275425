import React from 'react';
import PropTypes from 'prop-types';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';
import {
	Avatar,
} from '@mui/material';

import Box from '@mui/material/Box';
import ChartSettingsPopout from './ChartSettingsPopout';

export default function ChartSettings(props) {
	const {
		showChart, showPieChart, onChartSettingsClick, chartSettingsOpen,
	} = props;

	const onlyPie = !showChart && showPieChart;

	return (
		<>
			<Box
				sx={{
					float: 'right',
					left: '0%',
					position: 'relative',
					mr: '-40px',
					mt: '-5vh',
				}}
			>
				<div style={{ position: 'relative' }}>
					<Avatar
						sx={{
							position: 'absolute',
							right: 0,
							top: 120,
							zIndex: 100,
							width: 48,
							height: 48,
							bgcolor: 'white',
							color: 'info.main',
							cursor: 'pointer',
							// Same as paper
							// eslint-disable-next-line max-len
							boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
						}}
						onClick={onChartSettingsClick}
					>
						{chartSettingsOpen ? <ClearIcon /> : <TuneIcon />}
					</Avatar>
				</div>
			</Box>
			<ChartSettingsPopout
				{...props}
				showChartTypeSelector={!onlyPie}
				open={chartSettingsOpen}
			/>
		</>
	);
}

ChartSettings.propTypes = {
	showChart: PropTypes.bool,
	showPieChart: PropTypes.bool,
	onChartSettingsClick: PropTypes.func.isRequired,
	chartSettingsOpen: PropTypes.bool.isRequired,
};

ChartSettings.defaultProps = {
	showChart: false,
	showPieChart: false,
};
