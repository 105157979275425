import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import Circle from '../../../components/Circle';
import CsvIssueTrackerPopup from './CsvIssueTrackerPopup';
import { getFlattenedErrorsAndWarnings } from './utils';

function CsvIssueTracker({
	dataIssues,
	missingSspErrors,
	onIgnore,
}) {
	const [popupIsOpen, setPopupIsOpen] = useState(false);
	const anchorEl = useRef();

	const [dataErrors, dataWarnings] = getFlattenedErrorsAndWarnings(dataIssues);
	const numDataErrors = dataErrors.length;
	const numDataWarnings = dataWarnings.length;
	const numWarnings = Object.keys(missingSspErrors).length + numDataWarnings;
	const sspIssues = Object.keys(missingSspErrors)
		.map((ssp) => ({
			type: 'warning',
			header: ssp,
			description: 'SSP not found in Relevant Yield',
			error: ssp.error,
		}));

	const warnings = [...sspIssues, ...dataWarnings];
	return (
		<Box sx={{ display: 'flex' }}>
			<Button
				variant="outlined"
				ref={anchorEl}
				sx={{
					display: 'flex',
					border: '1px solid #E0E0E0',
					pt: 1,
					pb: 1,
					'&:hover': {
						border: '1px solid #E0E0E0',
					},
				}}
				onClick={() => setPopupIsOpen(!popupIsOpen)}
			>
				{ numWarnings > 0 && (
					<Circle
						size={32}
						style={{
							color: 'offWhite.main',
							bgcolor: 'warning.main',
							mr: 0.25,
						}}
					>
						{numWarnings}
					</Circle>
				)}
				{ numDataErrors > 0 && (
					<Circle
						size={32}
						style={{
							bgcolor: 'error.light',
							color: 'offWhite.main',
						}}
					>
						{numDataErrors}
					</Circle>
				)}
				<Box sx={{ ml: 1 }}>Problems</Box>
			</Button>
			<CsvIssueTrackerPopup
				anchorEl={anchorEl.current}
				isOpen={popupIsOpen}
				onClose={() => setPopupIsOpen(false)}
				errors={dataErrors}
				warnings={warnings}
				onIgnore={onIgnore}
			/>
		</Box>

	);
}

CsvIssueTracker.propTypes = {
	dataIssues: PropTypes.objectOf(PropTypes.shape({
		type: PropTypes.oneOf(['error', 'warning']),
		message: PropTypes.string,
		error: PropTypes.string,
	})),
	missingSspErrors: PropTypes.objectOf(PropTypes.string),
	onIgnore: PropTypes.func.isRequired,
};

CsvIssueTracker.defaultProps = {
	dataIssues: {},
	missingSspErrors: {},
};

export default CsvIssueTracker;
