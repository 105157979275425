import PropTypes from 'prop-types';
import React, { createContext, useState, useCallback, ComponentProps } from 'react';
import { createPortal } from 'react-dom';
import { Toast, ToastProps } from '../../components/Toast/Toast';
import { useAutoAnimate } from '@formkit/auto-animate/react';

export const ToastContext = createContext<[
	(ComponentProps<typeof Toast>)[],
	React.Dispatch<React.SetStateAction<ComponentProps<typeof Toast>[]>>
]>([] as any);

export function ToastProvider({ children }) {
	const [toasts, setToasts] = useState<ComponentProps<typeof Toast>[]>([]);
	const [autoAnimate] = useAutoAnimate();

	return (
		<ToastContext.Provider value={[toasts, setToasts]}>
			{createPortal(
				<div ref={autoAnimate} className="fixed flex flex-col items-end gap-2 m-4 mt-20 top-0 left-0 right-0 z-[1500] pointer-events-none *:pointer-events-auto">
					{toasts.map((t, i) => (
						<Toast
							{...t}
						/>
					))}
				</div>,
			document.body)}
			{children}
		</ToastContext.Provider>
	);
}