import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Constants from 'relevant-shared/reportData/constants';
import AllMappingDimensions from 'relevant-shared/mappingDimensions/allMappingDimension';
import { HISTORY_DAYS_BACK } from '../DynamicSearchFilter';
import PopupSelector from '../PopupSelector';
import OperationWrapper from '../OperationWrapper';
import { Reports } from '../../api/relevant';
import DateUtils from '../../lib/dateUtils';
import DataTable from '../DataTable';

const ADVERTISER_MAPPED_AWAY = 2;

const formatMatchReport = (report, metric, dimension) => {
	const byNr = _.omit(report.data, ADVERTISER_MAPPED_AWAY);
	const labels = report.labels[dimension];
	const total = _.sumBy(Object.values(byNr), metric) || 1;
	return _.sortBy(_.map(byNr, (obj, nr) => ({ name: labels[nr], share: obj[metric] / total })), 'name');
};

const lowerMapping = (mapping) => {
	const m = _.cloneDeep(mapping);
	(m.mappings || []).forEach((single) => {
		(single.byWords || []).forEach((by) => {
			by.word = (by.word || '').toLowerCase();
		});
	});
	return m;
};

class MatchReport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			mapping, metric, type, dimType,
		} = this.props;
		const mappingDim = AllMappingDimensions.byName(dimType);
		const { currentReport } = this.state;
		return (
			<PopupSelector title={`Last ${HISTORY_DAYS_BACK} days`} size="sm">
				<OperationWrapper
					fn={async () => this.setState({
						currentReport: await Reports.call(Constants[type].API_FN, {
							groupBy: [mappingDim.dimension],
							sums: [metric],
							maxAdvertisers: 10000,
							start: DateUtils.fullDay(new Date(), -HISTORY_DAYS_BACK),
							end: DateUtils.yesterday(),
							[mappingDim.customMappingProp]: lowerMapping(mapping),
						}),
					})}
				>
					{currentReport
					&& (
						<DataTable
							showCheckboxes={false}
							selectableRows={false}
							identifier={(row) => row.name}
							definitions={[
								{ key: 'name', title: `Matching ${mappingDim.pluralName}` },
								{
									key: 'share',
									title: 'Share %',
									format: (share) => (share * 100).toFixed(2),
								},
							]}
							data={formatMatchReport(currentReport, metric, mappingDim.dimension)}
						/>
					)}
				</OperationWrapper>
			</PopupSelector>
		);
	}
}

Object.assign(MatchReport, {
	ADVERTISER_MAPPED_AWAY,
});

MatchReport.propTypes = {
	metric: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	mapping: PropTypes.object.isRequired,
	dimType: PropTypes.string.isRequired,
};

MatchReport.defaultProps = {
};

export default MatchReport;
