import React, { Fragment } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ActionButton } from '../ActionButton/ActionButton';
import UploadButton from '../UploadButton';
import OperationWrapper from '../OperationWrapper';
import DataTable from '../DataTable';
import JobButton from '../JobButton';
import MiscUtils from '../../lib/miscUtils';
import DateUtils from '../../lib/dateUtils';
import { FormOf } from '../Wrappers';
import {
	Ssp, Adserver, Reports, KeyValue, listOfPublishers, editPublisher, addPublisher,
} from '../../api/relevant';
import Select from '../Select';
import TreeSelect from '../TreeSelect';
import Base from '../../layouts/Base';
import { splitByChars, getNamedDimensions, objFns } from './utils';
import ImportState from './importState';
import TextEditor from '../TagEditor/textEditor';
import ExpandSelector from '../ExpandSelector';
import TextField from '../TextField';
import MoveSelector from './moveSelector';
import Disabler from '../Disabler';
import { stores } from '../../stores';
import Checkbox from '../Checkbox';
import RadioButtonGroup from '../RadioButtonGroup';
import SysMapper from './sysMapper';
import PopupSelector from '../PopupSelector';
import CutField from '../CutField';
import FormCollection from '../../lib/formCollection';
import SystemData from '../../lib/systemData';
import { ConfirmDialog } from '../ConfirmDialog';
import { Dialog } from '../Dialog';

const DEFAULT_TRANSFORM_STYLE = () => ({ type: 'pageAndSite', settings: {} });
const DEFAULT_EDITOR_PROPS = { width: '100%', height: '200px' };
const BULK_SETTINGS_KEY = 'ProgrammaticBulkImportDefaultSettings';
const ADSERVER_COPY_FIELDS = ['adsCopyTo', 'sspCopyFrom', 'copyAllPlacements', 'overwriteExistingSettings'];

const placementPropsN = (str, n, splitChars) => {
	const parts = splitByChars(str, n, splitChars);
	const dims = getNamedDimensions(str);
	if (!parts || !dims) {
		return null;
	}
	const dimStr = `${dims.width}x${dims.height}`;
	for (let i = 0; i < parts.length - 1; i += 1) { // check all parts except last
		if (parts[i].indexOf(dimStr) >= 0) {
			return null; // dimensions came too early so we can't *really* split in N parts
		}
	}
	return { parts, ...dims };
};

const getOnlySite = (str, splitChars) => {
	const { parts, width, height } = placementPropsN(str, 2, splitChars) || {};
	return parts && {
		pubName: 'New account',
		siteName: parts[0],
		placementName: parts[1],
		width,
		height,
		noPubName: true,
	};
};

const getWithPub = (str, splitChars) => {
	const { parts, width, height } = placementPropsN(str, 3, splitChars) || {};
	if (parts) {
		return {
			pubName: parts[0],
			siteName: parts[1],
			placementName: parts[2],
			width,
			height,
		};
	}
	const res = getOnlySite(str, splitChars); // Ok let's try to use same publisher + site name
	if (res) {
		res.pubName = res.siteName;
	}
	return res;
};

const TransformStyles = {
	pageAndSite: {
		desc: 'Page and Site',
		fn: ({ name }) => getWithPub(name),
	},
	onlySite: {
		desc: 'OnlySite',
		fn: ({ name }) => getOnlySite(name),
	},
	custom: {
		desc: 'Custom',
		fn: (p) => {
			const code = ((p.settings || {}).code || '').trim();
			if (code) {
				return p.importState.evalWithCtx({ ...p, getWithPub }, p.settings.code, true);
			}
			return TransformStyles[DEFAULT_TRANSFORM_STYLE().type].fn(p); // default fallback
		},
	},
};

const initState = () => ({
	publisherNaming: 'CAPITALIZE($.PUBLISHER)',
	siteNaming: '$.noPubName ? CAPITALIZE($.SITE) : CAPITALIZE($.PUBLISHER) + ($.PUBLISHER === $.SITE ? "" : " " + CAPITALIZE($.SITE))',
	placementNaming: '($.noPubName ? CAPITALIZE($.SITE) : CAPITALIZE($.PUBLISHER) + ($.PUBLISHER === $.SITE ? "" : " " + CAPITALIZE($.SITE))) + " " + CAPITALIZE($.PLACEMENT)',
	newName: '',
	selectedCandidates: [],
	minCommonPerc: 0.9,
	checkDays: 30,
	ignoreTopDomains: true,
	siteMatches: '',
	placementMatches: '',
	findCommonPlacementName: true,
	setupCode: '',
	sspCopyFrom: '',
	adsCopyTo: '',
	copyAllPlacements: false,
	overwriteExistingSettings: false,
	adserverSettingsByPub: {},
	noCreateMode: false,
	onlyImportRevPlacements: false,
	newPublisherInitState: {
		openRTBContractualCut: 0,
		dealsContractualCut: 0,
		directContractualCut: 0,
		language: 'en',
	},
});

const EXCLUDE_FROM_SAVE = ['selectedCandidates'];

class BulkProgrammaticImport extends React.Component {
	constructor(props) {
		super(props);
		this.forms = new FormCollection();
		this.state = initState();
	}

	// we need a special case here as it is reported as sourceId-zoneId-sizeId, and we don't want sizeId
	fixRubiconZones(ssp) {
		ssp.children.forEach((entry) => {
			const toRemove = (/-.+(-.+)+$/.exec(entry.sourceId) || [])[1];
			if (!toRemove) {
				throw Error(`Unexpected Rubicon sourceId '${entry.sourceId}'`);
			}
			entry.sourceId = entry.sourceId.slice(0, entry.sourceId.length - toRemove.length);
		});
		ssp.children = _.unionBy(ssp.children, 'sourceId');
	}

	applyTransformStyles(importRoot, transformStyles) {
		importRoot.transformStyle = transformStyles.root || DEFAULT_TRANSFORM_STYLE();
		importRoot.children.forEach((child) => {
			child.transformStyle = (transformStyles.bySsp || {})[child.sspId] || null;
		});
	}

	getTransformStyles(importRoot) {
		const res = {
			root: importRoot.transformStyle || DEFAULT_TRANSFORM_STYLE(),
			bySsp: {},
		};
		importRoot.children.forEach((child) => {
			if (child.transformStyle && child.transformStyle.type) {
				res.bySsp[child.sspId] = child.transformStyle;
			}
		});
		return res;
	}

	async importFromMissing(settings) {
		const {
			preMadeReport, importedFromCsv, dontFixRubicon, bySourceIdInfo = {},
		} = settings || {};
		const { importFromMissingCalled } = this.state;
		const now = new Date();
		const keyValues = await KeyValue.list({ key: BULK_SETTINGS_KEY });
		const savedSettings = keyValues.length ? keyValues[0].value : null;
		const { onlyImportRevPlacements, checkDays } = importFromMissingCalled ? this.state : savedSettings || {};
		const [report, ssps, adservers] = await Promise.all([
			preMadeReport || Reports.call('reportMissingSspEntries', {
				groupBy: ['sspId', 'sourceId'],
				sums: ['revenueAfterSsp'],
				start: DateUtils.fullDay(now, -(checkDays || initState().checkDays)),
				end: DateUtils.fullDay(now, -1),
			}),
			Ssp.list(),
			Adserver.list(),
		]);
		_.pullAll(ssps, ssps.filter((ssp) => !ssp.active));
		const sspsById = _.keyBy(ssps, 'id');
		const adsById = _.keyBy(adservers, 'id');
		const { data, labels } = report;
		_.keys(data).forEach((sspId) => {
			if (!sspsById[sspId]) {
				delete data[sspId];
			}
		});
		if (onlyImportRevPlacements && !preMadeReport) {
			_.forOwn(data, (bySource, sspId) => {
				data[sspId] = _.pickBy(bySource, (obj) => obj.revenueAfterSsp);
			});
		}
		const importRoot = {
			isAll: true,
			name: 'All SSP Placements missing in Apps',
			transformStyle: DEFAULT_TRANSFORM_STYLE(),
		};
		importRoot.children = _.sortBy(_.keys(data).map((sspId) => ({
			sspId,
			name: labels.sspId[sspId],
			parent: importRoot,
			sspObj: sspsById[sspId],
		})), 'name').filter((o) => o.sspObj);
		importRoot.children.forEach((ssp) => {
			ssp.children = _.sortBy(_.keys(data[ssp.sspId]).map((sourceId) => ({
				sourceId,
				name: labels.sourceId[sourceId],
				parent: ssp,
				...(bySourceIdInfo[sourceId]),
			})), 'name');
			if (ssp.sspObj.type === 'RubiconSsp' && !dontFixRubicon) {
				this.fixRubiconZones(ssp);
			}
		});
		if (savedSettings && savedSettings.transformStyles) {
			this.applyTransformStyles(importRoot, savedSettings.transformStyles);
		}
		this.setState({
			importFromMissingCalled: true,
			importRoot,
			ssps,
			adservers,
			selectedImports: _.flatten(importRoot.children.map((ssp) => ssp.children)),
			currentImport: importRoot,
			sspsById,
			adsById,
			importedFromCsv,
			lastCheckDays: checkDays,
			...(savedSettings && !importFromMissingCalled ? _.omit(savedSettings, ['transformStyles']) : {}),
		});
	}

	async runCsvImport(csvData, settings) {
		const { ssps } = this.state;
		const {
			nameCol, idCol, pubNameCol, siteNameCol, sspNameCol, useNameAsId, defaultSspId,
		} = settings;
		const data = {};
		const labels = { sourceId: {}, sspId: {} };
		const bySourceIdInfo = {};
		csvData.forEach((row) => {
			let ssp;
			const nameStr = row[nameCol].trim();
			const idStr = useNameAsId ? nameStr : row[idCol].trim();
			const reportName = `${nameStr} (${idStr})`;
			if (!nameStr || !idStr) {
				return; // just skip (semi) blank lines
			}
			if (sspNameCol) {
				const sspStr = row[sspNameCol].trim();
				ssp = ssps.find(({ id }) => id === sspStr) || ssps.find(({ name }) => name.trim() === sspStr);
				if (!ssp) {
					throw Error(`Unknown SSP '${sspStr}'`);
				}
			} else {
				ssp = ssps.find(({ id }) => id === defaultSspId);
				if (!ssp) {
					throw Error('No matching SSP (should not happen..)');
				}
			}
			const sourceId = `${ssp.id}-${idStr}`;
			labels.sspId[ssp.id] = ssp.name;
			labels.sourceId[sourceId] = reportName;
			data[ssp.id] = data[ssp.id] || {};
			data[ssp.id][sourceId] = {};
			const extraInfo = {};
			bySourceIdInfo[sourceId] = extraInfo;
			if (pubNameCol) {
				extraInfo.pubName = row[pubNameCol].trim();
			}
			if (siteNameCol) {
				extraInfo.siteName = row[siteNameCol].trim();
			}
		});
		await this.importFromMissing({
			preMadeReport: { data, labels },
			importedFromCsv: true,
			dontFixRubicon: true,
			bySourceIdInfo,
		});
	}

	async importFromCsvFileInternal(file) {
		const { ssps } = this.state;
		const reader = new FileReader();
		const read = new Promise((resolve) => {
			reader.onload = (ev) => resolve(ev.target.result);
		});
		reader.readAsText(file);
		const txt = await read;
		let cols;
		const csvData = MiscUtils.loadCsv(txt, (headers) => {
			cols = headers.map((c) => c.trim()).filter((c) => c).map((c, idx) => ({ key: `key_${idx}`, header: c }));
			const csvCols = _.zipObject(cols.map(({ key }) => key), cols.map(({ header }) => header));
			return csvCols;
		});
		if (!cols.length) {
			throw Error('No columns');
		}
		const settings = {};
		const COL_SELECTORS = [
			{
				name: 'nameCol',
				label: 'Placement name column',
				required: true,
			},
			{
				name: 'idCol',
				label: 'Placement ID column',
				showIf: () => !settings.useNameAsId,
				required: true,
			},
			{
				name: 'pubNameCol',
				label: 'Publisher name column',
			},
			{
				name: 'siteNameCol',
				label: 'Site name column',
			},
			{
				name: 'sspNameCol',
				label: 'SSP name column',
				showIf: () => !settings.defaultSspId,
				required: true,
			},
		];
		Base.renderGlobal((done) => (
			<FormOf
				model={settings}
				content={({ field, form }) => (
					<PopupSelector
						forceExpanded
						allowErrorsIfCancel
						onApplyChanges={async () => {
							await this.runCsvImport(csvData, settings);
							done();
						}}
						onCancel={done}
						form={form}
					>
						{!settings.idCol && (
							<Checkbox
								label="Use name as ID"
								{...field('useNameAsId')}
							/>
						)}
						{!settings.sspNameCol && (
							<Select
								{...field('defaultSspId')}
								label="Select SSP for all placements"
								nonSelected="(none)"
								required
								fullWidth
								items={ssps.map(({ id, name }) => ({ label: name, value: id }))}
							/>
						)}
						{COL_SELECTORS.map(({
							name, label, required, showIf,
						}) => !!(!showIf || showIf()) && (
							<Select
								key={name}
								{...field(name)}
								label={label}
								nonSelected="(none)"
								required={required}
								fullWidth
								items={cols
									.filter(({ key }) => !COL_SELECTORS.find((c) => c.name !== name && settings[c.name] === key))
									.map(({ key, header }) => ({ label: header, value: key }))}
							/>
						))}
					</PopupSelector>
				)}
			/>
		));
	}

	async importFromCsvFile(file) {
		try {
			await this.importFromCsvFileInternal(file);
		} catch (e) {
			Base.renderGlobal((done) => (
				<Dialog
					open
					status="error"
					text={`Error loading .csv: ${MiscUtils.errorMsg(e)}`}
					onClose={done}
				/>
			));
		}
	}

	async generateStructure() {
		const { selectedImports, sspCopyFrom } = this.state;
		const byIsAdserver = _.groupBy(selectedImports, (e) => e.parent.sspId === sspCopyFrom);
		const entries = (byIsAdserver.true || []).concat(byIsAdserver.false || []); // add adserver entries first
		const importState = new ImportState(await this.loadPublishers(), _.pick(this.state, _.keys(initState()).concat([
			'importedFromCsv',
		])), entries);
		entries.forEach((entry) => {
			let transformStyle = DEFAULT_TRANSFORM_STYLE();
			for (let e = entry; e; e = e.parent) {
				if (e.transformStyle && e.transformStyle.type) {
					transformStyle = e.transformStyle;
					break;
				}
			}
			const trans = TransformStyles[transformStyle.type];
			importState.addEntry(entry, trans, transformStyle.settings || {});
		});
		const candRoot = {
			__type: 'Root',
			__hasNew: true,
			publishers: importState.publishers,
			name: 'All objects to add',
			isNew: true,
			id: 'root',
		};
		this.setState({
			importState,
			selectedCandidates: [],
			candRoot,
			expandOnce: [candRoot],
			currentEntity: null,
		}, () => this.updateAdserverPlacementCopy());
	}

	async loadPublishers(reload) {
		if (!this.publisherPromise || reload) {
			this.publisherPromise = listOfPublishers();
		}
		const res = (await this.publisherPromise).result.filter((pub) => pub.programmaticAccess);
		return res;
	}

	async performImport() {
		const { candRoot } = this.state;
		const pubsById = _.keyBy(await this.loadPublishers(true), 'id');

		this.updateAdserverPlacementCopy();

		const mergeChildren = (dst, src) => {
			const arrName = objFns.childArrName(src);
			const dstChildrenById = _.keyBy(dst[arrName] || [], '_id'); // don't use 'id' as that is ssp-id for ssp sentries
			src[arrName].forEach((srcChild) => {
				if (srcChild.isNew) {
					dst[arrName] = dst[arrName] || [];
					dst[arrName].push(srcChild);
				} else if (srcChild.__hasNew || srcChild.__wasMapped) {
					const dstChild = dstChildrenById[srcChild._id];
					if (dstChild) {
						mergeChildren(dstChild, srcChild);
					}
				}
			});
			if (src.__type === 'Placement' && src.__wasMapped) {
				dst.adservers = src.adservers;
			}
		};

		const fixed = (pub) => _.cloneDeepWith(pub, (__, key) => (key === '__parent' ? null : undefined));

		for (const pub of candRoot.publishers) {
			if (pub.isNew) {
				this.setState({ statusText: `Creating publisher '${pub.name}'...` });
				await addPublisher(fixed(pub));
			} else if (pub.__hasNew || pub.__wasMapped) {
				this.setState({ statusText: `Updating publisher '${pub.name}'...` });
				const freshPub = pubsById[pub.id];
				mergeChildren(freshPub, pub);
				await editPublisher(freshPub.id, fixed(freshPub));
			}
		}
		stores.publishers.loadAll(true);
	}

	getSelectedSitesPlacements() {
		const selectedCandidates = _.filter(this.state.selectedCandidates, { __type: 'SspPlacement' });
		const entryByRand = _.keyBy(selectedCandidates, '__rand');
		const placByRand = {};
		const siteByRand = {};
		selectedCandidates.forEach((entry) => {
			const placement = entry.__parent;
			if (placement.isNew && placByRand[placement.__rand] === undefined) {
				placByRand[placement.__rand] = !placement.ssps.find((s) => !entryByRand[s.__rand]) && placement;
			}
		});
		selectedCandidates.forEach((entry) => {
			const site = entry.__parent.__parent;
			if (site.isNew && siteByRand[site.__rand] === undefined) {
				siteByRand[site.__rand] = !site.placements.find((p) => !placByRand[p.__rand]) && site;
			}
		});
		return {
			selectedPlacements: Object.values(placByRand).filter((p) => p),
			selectedSites: Object.values(siteByRand).filter((s) => s),
		};
	}

	getCurrentPub() {
		const { currentEntity } = this.state;
		return currentEntity ? objFns.getParentMatching(currentEntity, (n) => n.__type === 'Publisher', true) : null;
	}

	getCurrentPubAdserverSettings() {
		const { adserverSettingsByPub } = this.state;
		const currentPub = this.getCurrentPub();
		if (!currentPub) {
			return null;
		}
		return Object.assign(_.pick(this.state, ADSERVER_COPY_FIELDS), (adserverSettingsByPub || {})[currentPub.id]);
	}

	updateAdserverPlacementCopy(newState, forPub) {
		const { adserverSettingsByPub } = this.state;
		const cfgForPub = (pub) => {
			const objChain = [];
			const pubSettings = (adserverSettingsByPub || {})[pub.id];
			if (pubSettings && pubSettings.override) {
				objChain.push(pubSettings);
			}
			if (forPub) {
				if (forPub.id === pub.id) {
					objChain.push(newState);
				}
			} else {
				objChain.push(newState);
			}
			return Object.assign(_.pick(this.state, ADSERVER_COPY_FIELDS), ...objChain);
		};
		const { candRoot, sspsById, adsById } = this.state;
		const placementsCopied = [];
		const placementsCopiedByPub = {};
		(candRoot.publishers || []).forEach((pub) => {
			const cfg = cfgForPub(pub);
			const edited = SysMapper.mapPublisher({
				pub,
				onlyNew: !cfg.copyAllPlacements,
				overwrite: cfg.overwriteExistingSettings,
				fromSsp: sspsById[cfg.sspCopyFrom],
				toAdserver: adsById[cfg.adsCopyTo],
			});
			placementsCopiedByPub[pub.id] = edited;
			placementsCopied.push(...edited);
		});
		let newStateParam = newState;
		if (forPub) {
			newStateParam = { adserverSettingsByPub: { ...adserverSettingsByPub, [forPub.id]: cfgForPub(forPub) } };
		}
		newStateParam = { ...newStateParam, placementsCopied, placementsCopiedByPub };
		this.setState(newStateParam);
	}

	renderAdserverPlacementCopy({ fld }) {
		const {
			adservers,
			ssps,
			sspsById,
			adsById,
			placementsCopied,
			placementsCopiedByPub,
			adserverSettingsByPub,
		} = this.state;
		if (!(adservers || []).length || !(ssps || []).length) {
			return undefined;
		}
		const currentPub = this.getCurrentPub();
		const pubSettings = this.getCurrentPubAdserverSettings();
		const stateObj = pubSettings && pubSettings.override ? pubSettings : this.state;
		const { sspCopyFrom, adsCopyTo } = stateObj;
		const selectedSsp = sspsById[sspCopyFrom];
		return (
			<Grid item xs={12}>
				<ExpandSelector
					title={`${currentPub ? `${currentPub.name} - ` : ''}Copy SSP Placement settings to missing Adserver settings`}
					selected={(currentPub ? placementsCopiedByPub[currentPub.id] : placementsCopied) || []}
				>
					{currentPub && (
						<Checkbox
							label={`Override adserver settings for '${currentPub.name}'`}
							{...fld('override', ({ override }) => {
								const newSettings = override ? ({ ...pubSettings, override }) : null;
								this.setState({ adserverSettingsByPub: { ...adserverSettingsByPub, [currentPub.id]: newSettings } });
							}, pubSettings)}
							margin="normal"
						/>
					)}
					<Disabler disabled={!!(pubSettings && !pubSettings.override)}>
						<Checkbox
							label="Include all existing placements"
							{...fld('copyAllPlacements', (newState) => this.updateAdserverPlacementCopy(newState))}
							margin="normal"
						/>
						<Checkbox
							label="Overwrite existing placement settings"
							{...fld('overwriteExistingSettings', (newState) => this.updateAdserverPlacementCopy(newState))}
							margin="normal"
						/>
						<Card>
							<CardContent>
								<Typography variant="h2">
									From SSP
								</Typography>
								<RadioButtonGroup
									items={[{ name: 'None', value: '' }].concat(ssps.map((ssp) => ({
										name: ssp.name,
										value: ssp.id,
									})))}
									name="copySsps"
									selected={sspCopyFrom || ''}
									defaultValue={null}
									onChange={(ev) => this.updateAdserverPlacementCopy({ sspCopyFrom: ev.target.value }, currentPub)}
								/>
							</CardContent>
						</Card>
						<Card>
							<CardContent>
								<Typography variant="h2">
									To Adserver
								</Typography>
								<RadioButtonGroup
									items={[{ name: 'None', value: '' }].concat(adservers.map((ads) => ({
										name: ads.name,
										value: ads.id,
										disabled: !SysMapper.mapperFor(selectedSsp, adsById[ads.id]),
									})))}
									name="copyAdservers"
									selected={adsCopyTo || ''}
									defaultValue={null}
									onChange={(ev) => this.updateAdserverPlacementCopy({ adsCopyTo: ev.target.value }, currentPub)}
								/>
							</CardContent>
						</Card>
					</Disabler>
				</ExpandSelector>
			</Grid>
		);
	}

	renderPerformBar() {
		const { statusText } = this.state;

		const reset = async () => {
			this.setState({ ..._.mapValues((this.state), () => undefined), ...initState() });
			await this.op.reload();
		};

		return (
			<>
				<JobButton
					label="Perform import"
					fn={() => new Promise((resolve, reject) => {
						Base.renderGlobal((closeFn) => (
							<ConfirmDialog
								open
								text="Are you sure you want to perform import into system?"
								onAny={async (ok) => {
									closeFn();
									if (ok) {
										try {
											this.setState({ guiDisabled: true });
											await this.performImport();
											await reset();
											Base.renderGlobal((done) => (
												<Dialog
													open
													status="success"
													text="Import done"
													onClose={done}
												/>
											));
										} catch (e) {
											await reset();
											Base.renderGlobal((done) => (
												<Dialog
													open
													status="error"
													text={`Error: ${MiscUtils.errorMsg(e)}`}
													onClose={done}
												/>
											));
											reject(e);
										}
									}
									resolve();
								}}
							/>
						));
					})}
					color="secondary"
				/>
				{statusText && (
					<span style={{ fontWeight: 'bold' }}>
						{statusText}
					</span>
				)}
			</>
		);
	}

	renderNewPublisherSettings() {
		const { newPublisherInitState } = this.state;
		const { LANGUAGES } = SystemData.genericData;
		return (
			<FormOf
				model={newPublisherInitState}
				formCollection={this.forms}
				content={({ field, form }) => (

					<ExpandSelector
						title="Settings for new publishers"
						form={form}
					>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Select
									{...field('language')}
									label="Language"
									items={_.map(LANGUAGES, (v, k) => ({ label: v.name, value: k }))}
									margin="normal"
									fullWidth
								/>
							</Grid>
							<Grid item xs={4}>
								<CutField
									{...field('openRTBContractualCut')}
									label="OpenRTB cut (%)"
								/>
							</Grid>
							<Grid item xs={4}>
								<CutField
									{...field('dealsContractualCut')}
									label="Deals cut (%)"
								/>
							</Grid>
							<Grid item xs={4}>
								<CutField
									{...field('directContractualCut')}
									label="Direct cut (%)"
								/>
							</Grid>
						</Grid>
					</ExpandSelector>
				)}
			/>
		);
	}

	render() {
		const {
			importRoot,
			currentImport,
			selectedImports,
			importState,
			candRoot,
			selectedCandidates,
			currentEntity,
			newName,
			expandOnce,
			guiDisabled,
			showAllOldStructure,
			placementsCopied = [],
			importedFromCsv,
			onlyImportRevPlacements,
			checkDays,
			lastCheckDays,
		} = this.state;
		const { selectedSites, selectedPlacements } = this.getSelectedSitesPlacements();
		const fld = (name, updater, stateObj) => ({
			name,
			value: (stateObj || this.state)[name],
			onChange: (ev) => (updater ? updater({ [name]: ev.target.value }) : this.setState({ [name]: ev.target.value })),
		});
		const objFld = (stateVar) => (name, replaceNoneWith) => ({
			name,
			value: replaceNoneWith && _.get(this.state[stateVar], name) === replaceNoneWith ? '' : _.get(this.state[stateVar], name),
			onChange: (ev) => {
				_.set(this.state[stateVar], name, !replaceNoneWith || ev.target.value ? ev.target.value : replaceNoneWith);
				this.setState({ [stateVar]: this.state[stateVar] });
			},
		});
		const currentImportfld = objFld('currentImport');
		const currentEntityfld = objFld('currentEntity');
		const canEditEntity = (n) => n.__type === 'Publisher' || n.isNew && n.__type !== 'SspPlacement';
		const entityTreeStyle = (n, label) => {
			const suffix = n.__type === 'Placement' && (n.adservers || []).length
				? <Box component="span" color="success.main"> (A)</Box>
				: null;
			if (!n.isNew) {
				return (
					<Box
						component="span"
						color={`grey.${n.__hasNew ? 600 : 400}`}
						bgcolor={n.__type === 'Publisher' ? undefined : 'common.white'}
					>
						{label}
						{suffix}
					</Box>
				);
			} if (n.__type === 'SspPlacement') {
				return (
					<Box
						component="span"
						color="success.main"
						bgcolor="common.white"
					>
						{label}
						{suffix}
					</Box>
				);
			}
			if (n === currentEntity) {
				return (
					<strong>
						{label}
						{suffix}
					</strong>
				);
			}
			return suffix ? (
				<>
					{label}
					{suffix}
				</>
			) : label;
		};

		const changeToEntity = (n, expandSelf, extraState = {}) => {
			const toExpand = [];
			for (let node = expandSelf ? n : n.__parent; node; node = node.__parent) {
				toExpand.push(node);
			}
			this.setState({
				currentEntity: canEditEntity(n) ? n : null,
				newName: '',
				expandOnce: toExpand,
				...extraState,
			});
		};

		const onMoved = (dst) => {
			this.updateAdserverPlacementCopy();
			changeToEntity(dst, true, { selectedCandidates: [] });
		};

		const ByType = {
			Root: {
				addType: 'Publisher',
				add: () => importState.createPublisher(newName),
			},
			Publisher: {
				addType: 'Site',
				add: () => importState.createSite(currentEntity, newName),
			},
			Site: {
				addType: 'Placement',
				add: () => importState.createPlacement(currentEntity, newName),
			},
			Placement: {},
			SspPlacement: {},
		};

		const handler = ByType[(currentEntity || {}).__type];

		return (
			<Disabler disabled={!!guiDisabled}>
				<OperationWrapper
					fn={async (op) => {
						this.op = op;
						await this.importFromMissing();
						this.loadPublishers(true);
					}}
				>
					<Grid container spacing={3}>
						{importRoot && (
							<Grid item xs={12}>
								<Card>
									<CardContent>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<Typography variant="h2">
													Import from
												</Typography>
											</Grid>
											<Grid item xs={12}>
												{!importRoot.children.length && (
													<Box
														color="success.main"
														textAlign="center"
													>
														<em>No missing SSP placements to import</em>
													</Box>
												)}
												{!!importRoot.children.length && (
													<Grid container spacing={3}>
														<Grid item xs={6}>
															<Grid container spacing={3}>
																<Grid item xs={12}>
																	<Card>
																		<CardContent>
																			<Grid container spacing={3}>
																				<Grid item xs={12}>
																					<Typography variant="h3">
																						SSP entries
																					</Typography>
																				</Grid>
																				<Grid item xs={4}>
																					<ActionButton
																						label="Import from missing traffic"
																						color="primary"
																						disabled={!importedFromCsv && checkDays === lastCheckDays}
																						onClick={() => this.importFromMissing()}
																					/>
																				</Grid>
																				<Grid item xs={4}>
																					<UploadButton
																						accept=".csv"
																						onChange={(ev) => this.importFromCsvFile(ev.target.files[0])}
																						fullWidth
																					>
																						Import from .csv
																					</UploadButton>
																				</Grid>
																				<Grid item xs={4}>
																					<Select
																						style={{
																							width: 150,
																							marginTop: -10,
																						}}
																						label="Check days back"
																						{...fld('checkDays')}
																						items={[1, 2, 3, 5, 7, 14, 30, 60, 90, 120].map((n) => ({
																							label: n,
																							value: n,
																						}))}
																					/>
																				</Grid>
																				<Grid item xs={12}>
																					<TreeSelect
																						getChildren={(n) => n.children || []}
																						getLabel={(n) => n.name}
																						getNodeId={(n) => n.sourceId || n.sspId || 'root'}
																						getIsLeaf={(n) => n.sourceId}
																						rootNode={importRoot}
																						selectedObjects={selectedImports}
																						expandOnce={[importRoot]}
																						initSelect={importRoot}
																						onSelectionChange={(items) => this.setState({ selectedImports: items })}
																						onChange={(n) => this.setState({ currentImport: n })}
																						render={(n, label) => (n === currentImport
																							? <strong>{label}</strong> : label)}
																					/>
																				</Grid>
																			</Grid>
																		</CardContent>
																	</Card>
																</Grid>
																<Grid item xs={12}>
																	<Card>
																		<CardContent>
																			<Grid container spacing={3}>
																				<Grid item xs={12}>
																					<Typography variant="h3">
																						Naming of Publishers / Sites /
																						Placement
																					</Typography>
																				</Grid>
																				<Grid item xs={12}>
																					<ExpandSelector
																						title="Publisher naming"
																					>
																						<TextEditor {...DEFAULT_EDITOR_PROPS} {...fld('publisherNaming')} />
																					</ExpandSelector>
																				</Grid>
																				<Grid item xs={12}>
																					<ExpandSelector title="Site naming">
																						<TextEditor {...DEFAULT_EDITOR_PROPS} {...fld('siteNaming')} />
																					</ExpandSelector>
																				</Grid>
																				<Grid item xs={12}>
																					<ExpandSelector
																						title="Placement naming"
																					>
																						<TextEditor {...DEFAULT_EDITOR_PROPS} {...fld('placementNaming')} />
																					</ExpandSelector>
																				</Grid>
																			</Grid>
																		</CardContent>
																	</Card>
																</Grid>
																<Grid item xs={12}>
																	<Box display="flex" justifyContent="space-between">
																		<JobButton
																			label="Generate structure"
																			color="secondary"
																			fn={async () => {
																				if (this.forms.checkErrors()) {
																					throw Error('Please correct all errors');
																				}
																				await new Promise((r) => setTimeout(r, 50)); // to make animation on button start..
																				await this.generateStructure();
																			}}
																		/>
																		<JobButton
																			label="Save as default"
																			color="primary"
																			fn={async () => {
																				const value = _.omit(_.pick(this.state, _.keys(initState())), EXCLUDE_FROM_SAVE);
																				value.transformStyles = this.getTransformStyles(importRoot);
																				const arr = await KeyValue.list({ key: BULK_SETTINGS_KEY });
																				if (arr[0]) {
																					await arr[0].update({ value });
																				} else {
																					await KeyValue.add({
																						key: BULK_SETTINGS_KEY,
																						value,
																					});
																				}
																				Base.renderGlobal((done) => (
																					<Dialog
																						open
																						status="success"
																						text="Settings saved as default for future imports"
																						onClose={done}
																					/>
																				));
																			}}
																		/>
																		<ActionButton
																			label="Factory defaults"
																			color="primary"
																			onClick={() => {
																				this.applyTransformStyles(importRoot, {});
																				this.setState(Object.assign(initState(), { importRoot }));
																			}}
																		/>
																	</Box>
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={6}>
															<Grid container spacing={3}>
																<Grid item xs={12}>
																	<Card>
																		<CardContent>
																			<Typography variant="h3">
																				Settings per SSP Placement:
																				<span
																					style={{ fontWeight: 'bold' }}
																				>
&nbsp;
																					{currentImport.name}
																				</span>
																			</Typography>
																			<Select
																				label="Transform style"
																				{...currentImportfld('transformStyle.type')}
																				items={_.map(TransformStyles, (obj, key) => ({
																					label: obj.desc,
																					value: key,
																				}))}
																				nonSelected={currentImport.parent ? '(inherit)' : undefined}
																				margin="normal"
																				fullWidth
																			/>
																			{(currentImport.transformStyle || {}).type === 'custom' && (
																				<ExpandSelector
																					title="Custom name transform"
																				>
																					<TextEditor {...DEFAULT_EDITOR_PROPS} {...currentImportfld('transformStyle.settings.code')} />
																				</ExpandSelector>
																			)}
																		</CardContent>
																	</Card>
																</Grid>
																<Grid item xs={12}>
																	<Card>
																		<CardContent>
																			<Typography variant="h3">
																				General settings
																			</Typography>
																			<Select
																				label="Name matching strictness"
																				{...fld('minCommonPerc')}
																				items={[
																					{ label: 'Relaxed', value: 0.5 },
																					{ label: 'Strict', value: 0.8 },
																					{ label: 'Very Strict', value: 0.9 },
																					{ label: 'Exact', value: 1.0 },
																				]}
																				nonSelected={currentImport.parent ? '(inherit)' : undefined}
																				margin="normal"
																				fullWidth
																			/>
																			<Checkbox
																				label="Ignore top domains in name matching"
																				{...fld('ignoreTopDomains')}
																				margin="normal"
																			/>
																			<Checkbox
																				label="Approximate new placement names from combined SSP placement names"
																				{...fld('findCommonPlacementName')}
																				margin="normal"
																			/>
																			<Checkbox
																				label="Only merge into pre-existing placements (no new placements will be created)"
																				{...fld('noCreateMode')}
																				margin="normal"
																			/>
																			<Checkbox
																				label="Only import placemens with revenue (not only traffic)"
																				name="onlyImportRevPlacements"
																				value={onlyImportRevPlacements}
																				onChange={(ev) => {
																					Base.renderGlobal((closeFn) => (
																						<ConfirmDialog
																							open
																							text="Reload this dialog?"
																							onAny={async (ok) => {
																								closeFn();
																								if (ok) {
																									this.setState({ onlyImportRevPlacements: ev.target.value }, () => {
																										this.op.reload();
																									});
																								}
																							}}
																						/>
																					));
																				}}
																				margin="normal"
																			/>
																			<Grid container spacing={3}>
																				<Grid item xs={12}>
																					{this.renderNewPublisherSettings()}
																				</Grid>
																				<Grid item xs={12}>
																					<ExpandSelector title="Setup code">
																						<TextEditor {...DEFAULT_EDITOR_PROPS} {...fld('setupCode')} />
																					</ExpandSelector>
																				</Grid>
																				<Grid item xs={12}>
																					<ExpandSelector
																						title="Site matches"
																					>
																						<TextEditor {...DEFAULT_EDITOR_PROPS} {...fld('siteMatches')} />
																					</ExpandSelector>
																				</Grid>
																				<Grid item xs={12}>
																					<ExpandSelector
																						title="Placement matches"
																					>
																						<TextEditor {...DEFAULT_EDITOR_PROPS} {...fld('placementMatches')} />
																					</ExpandSelector>
																				</Grid>
																			</Grid>
																		</CardContent>
																	</Card>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												)}
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						)}
						{candRoot && (
							<Grid item xs={12}>
								<Card>
									<CardContent>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<Typography variant="h2">
													Structure to import
												</Typography>
											</Grid>
											<Grid item xs={6}>
												{!!importState.successCount && (
													<Card>
														<CardContent>
															<Box display="flex">
																<Typography variant="h2">
																	Publishers / Sites / Placements /
																	<Box
																		component="span"
																		color="success.main"
																	>
 SSP
																		Placement
																	</Box>
																</Typography>
																<Checkbox
																	label="Show all of existing"
																	{...fld('showAllOldStructure')}
																/>
															</Box>
															<TreeSelect
																getChildren={(n) => (n.__type === 'Placement' && !n.__hasNew ? [] : objFns.getChildren(n).filter((c) => (showAllOldStructure && c.__type !== 'SspPlacement') || c.__hasNew))}
																getLabel={(n) => objFns.getLabel(n) || 'Error'}
																getNodeId={objFns.getNodeId}
																alwaysReserveExpandSpace
																rootNode={candRoot}
																selectedObjects={selectedCandidates}
																onChange={(n) => changeToEntity(n)} // only edit new publishers+sites+placements
																expandOnce={expandOnce}
																shouldExclude={(n) => !n.__hasNew}
																onSelectionChange={(items) => this.setState({ selectedCandidates: items })}
																render={entityTreeStyle}
																noDisabler
															/>
														</CardContent>
													</Card>
												)}
												{!importState.successCount && (
													<Box
														color="error.main"
														textAlign="center"
													>
														<em>
															None of the missing SSP placements are possible to import
															automatically
														</em>
													</Box>
												)}
												{(!!importState.successCount || !!placementsCopied.length) && this.renderPerformBar()}
											</Grid>
											<Grid item xs={6}>
												<Grid container spacing={3}>
													{!!importState.failedMatches.length && (
														<Grid item xs={12}>
															<ExpandSelector
																title={(
																	<>
																		<Box component="span" color="error.main">
																			<span style={{ fontWeight: '900' }}>
																				{importState.failedMatches.length}
																			</span>
																			&nbsp;&nbsp;Failed matches
																		</Box>
																		&nbsp;&nbsp;(won't be imported!)
																	</>
																)}
															>
																<DataTable
																	identifier={(entry) => entry.name}
																	showCheckboxes={false}
																	selectableRows={false}
																	data={importState.failedMatches}
																	style={{ width: '200px' }}
																	definitions={[
																		{
																			key: 'parent',
																			title: 'SSP',
																			format: (ssp) => ssp.name,
																		},
																		{
																			key: 'name',
																			title: 'Name',
																		},
																	]}
																/>
															</ExpandSelector>
														</Grid>
													)}
													{this.renderAdserverPlacementCopy({ fld })}
													{currentEntity && currentEntity.isNew && (
														<Grid item xs={12}>
															<Card>
																<CardContent>
																	<Typography variant="h2">
																		Settings
																	</Typography>
																	{currentEntity.__type !== 'Root' && (
																		<TextField
																			label="Name"
																			{...currentEntityfld('domain' in currentEntity ? 'domain' : 'name', '(no name)')}
																			margin="normal"
																			fullWidth
																		/>
																	)}
																	{handler.addType && !importState.noCreateMode && (
																		<Box display="flex">
																			<TextField
																				label={`New ${handler.addType}`}
																				{...fld('newName')}
																				margin="normal"
																			/>
																			<ActionButton
																				label="Add"
																				disabled={!newName.trim()}
																				style={{ marginTop: 24 }}
																				onClick={() => {
																					const obj = handler.add(newName.trim);
																					obj.__hasNew = true;
																					obj.__rand = Math.random();
																					changeToEntity(obj);
																				}}
																			/>
																		</Box>
																	)}
																</CardContent>
															</Card>
														</Grid>
													)}
													{!!importState.successCount && (
														<Grid item xs={12}>
															<Card>
																<CardContent>
																	<Grid container spacing={3}>
																		<Grid item xs={12}>
																			<Typography variant="h2">
																				Actions on selected objects
																			</Typography>
																		</Grid>
																		<Grid item xs={12}>
																			<MoveSelector
																				desc="Ssp Placements"
																				selected={selectedCandidates}
																				dstLevel="Placement"
																				candRoot={candRoot}
																				onMoved={onMoved}
																				onDeleted={() => onMoved(candRoot)}
																				disabled={!selectedCandidates.length}
																			/>
																		</Grid>
																		<Grid item xs={12}>
																			<MoveSelector
																				desc="Placements"
																				selected={selectedPlacements}
																				dstLevel="Site"
																				candRoot={candRoot}
																				onMoved={onMoved}
																				onDeleted={() => onMoved(candRoot)}
																				disabled={!selectedCandidates.length}
																			/>
																		</Grid>
																		<Grid item xs={12}>
																			<MoveSelector
																				desc="Sites"
																				selected={selectedSites}
																				dstLevel="Publisher"
																				candRoot={candRoot}
																				onMoved={onMoved}
																				onDeleted={() => onMoved(candRoot)}
																				disabled={!selectedCandidates.length}
																			/>
																		</Grid>
																	</Grid>
																</CardContent>
															</Card>
														</Grid>
													)}
												</Grid>
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						)}
					</Grid>
				</OperationWrapper>
			</Disabler>
		);
	}
}

BulkProgrammaticImport.propTypes = {};

BulkProgrammaticImport.defaultProps = {};

export default BulkProgrammaticImport;
