import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import { ActionButton } from '../ActionButton/ActionButton';
import FilterStatistics from './filterStatistics';

class StatisticDialog extends React.Component {
	render() {
		const { path } = this.props;
		const { open } = (this.state || {});
		return (
			<div>

				<ActionButton
					label="Check users"
					color={path ? 'primary' : 'secondary'}
					onClick={() => this.setState({ open: true })}
				/>
				{!!open && (
					<Dialog
						maxWidth="xl"
						fullWidth
						open
						onClose={() => this.setState({ open: false })}
					>
						<FilterStatistics {...this.props} />
					</Dialog>
				)}
			</div>
		);
	}
}

StatisticDialog.propTypes = FilterStatistics.propTypes;
StatisticDialog.defaultProps = FilterStatistics.defaultProps;

export default StatisticDialog;
