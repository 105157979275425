import React from 'react';
import {
	Box,
	FormControl,
	FormLabel,
	FormControlLabel,
	RadioGroup,
	Radio,
} from '@mui/material';
import PropTypes from 'prop-types';
import HelpIconButton from '../HelpIconButton';

function FilterTypeSelector({ value, onChange }) {
	return (
		<Box sx={{ mt: 4, pl: 1 }}>
			<FormControl>
				<FormLabel id="selection-type-label">
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<div>Filter type</div>
						<HelpIconButton>
							Select whether you want to include or exclude data that matches the selection.
						</HelpIconButton>
					</Box>
				</FormLabel>
				<RadioGroup
					aria-labelledby="selection-type-label"
					value={value}
					name="selectionType"
					onChange={onChange}
				>
					<FormControlLabel value="INCLUSIVE" control={<Radio />} label="Inclusive" />
					<FormControlLabel value="EXCLUSIVE" control={<Radio />} label="Exclusive" />
				</RadioGroup>
			</FormControl>
		</Box>
	);
}

FilterTypeSelector.propTypes = {
	value: PropTypes.oneOf(['INCLUSIVE', 'EXCLUSIVE']),
	onChange: PropTypes.func.isRequired,
};

FilterTypeSelector.defaultProps = {
	value: 'INCLUSIVE',
};

export default FilterTypeSelector;
