import {
	AdminPanelSettings,
	Assessment,
	Dashboard,
	DashboardCustomize as DashboardCustomizeIcon,
	Delete as DeleteIcon,
	Edit,
	Group as GroupIcon,
	RestoreFromTrash as RestoreFromTrashIcon,
} from '@mui/icons-material';
import {
	Box,
	Card,
	CardActionArea,
	CardActions,
	CardHeader,
	CircularProgress,
	Icon,
	IconButton,
	Tooltip,
	Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import UnstyledLink from '../UnstyledLink';
import { ConfirmDialog } from '../ConfirmDialog';

export function ReportTemplateCard({
	id,
	title,
	description,
	image,
	isPublic,
	isAdminTemplate,
	isUserTemplate,
	isReportTemplate,
	isDashboardTemplate,
	isHidden,
	link,
	onDelete,
	onRestore,
	onClick,
	onCopyToDashboard,
	onEdit,
}) {
	const [loading, setLoading] = useState(null);
	const isLoading = !!loading;
	const showLoading = useDebounce(loading, 250);

	function withLoading(fn) {
		return async (...args) => {
			setLoading(() => fn);
			try {
				return await fn(...args);
			} finally {
				setLoading(null);
			}
		};
	}

	return (
		<Card
			variant="outlined"
			sx={{
				backgroundImage: `url(${image})`,
				backgroundSize: 'cover',
				border: '1px solid #E0E0E0',
				height: '100%',
			}}
		>
			<div
				style={{
					backgroundColor: 'rgba(255, 255, 255, 0.6)',
					backdropFilter: 'blur(3px)',
					height: '100%',
				}}
			>
				<CardActionArea sx={{ p: '13px', pb: 0, height: '100%' }}>
					<UnstyledLink
						to={link}
						onClick={onClick}
						sx={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}
					>
						<div>
							<Typography variant="body1" sx={{ WebkitLineClamp: '2', display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{title}
							</Typography>
							<Typography
								variant="body2"
								color="text.secondary"
								sx={{
									WebkitLineClamp: '2',
									display: '-webkit-box',
									WebkitBoxOrient: 'vertical',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{description ?? '\u00A0'}
							</Typography>
						</div>

						<Box sx={{ justifyContent: 'space-between', display: 'flex', width: '100%', p: 0, my: '5px' }}>
							<Box sx={{ visibility: isPublic ? 'visible' : 'hidden', display: 'flex', alignItems: 'center', color: 'text.sub', flexShrink: 1, p: 0, my: '8px' }}>
								{(isReportTemplate !== undefined) && (
									<Tooltip title={<span style={{ textDecoration: isReportTemplate ? 'none' : 'line-through' }}>Report template</span>}>
										<Assessment sx={{ color: 'text.primary' }} />
									</Tooltip>
								)}
								{(isDashboardTemplate !== undefined) && (
									<Tooltip title={<span style={{ textDecoration: isDashboardTemplate ? 'none' : 'line-through' }}>Dashboard template</span>}>
										<Dashboard sx={{ color: isDashboardTemplate ? 'text.primary' : 'text.sub' }} />
									</Tooltip>
								)}
								{(isAdminTemplate !== undefined) && (
									<Tooltip title={<span style={{ textDecoration: isAdminTemplate ? 'none' : 'line-through' }}>Admin visible template</span>}>
										<AdminPanelSettings sx={{ color: isAdminTemplate ? 'text.primary' : 'text.sub' }} />
									</Tooltip>
								)}
								{(isUserTemplate !== undefined) && (
									<Tooltip title={<span style={{ textDecoration: isUserTemplate ? 'none' : 'line-through' }}>User visible template</span>}>
										<GroupIcon sx={{ color: isUserTemplate ? 'text.primary' : 'text.sub' }} />
									</Tooltip>
								)}
							</Box>
							<CardActions
								disableSpacing
								sx={{ flexDirection: 'row-reverse', p: 0 }}
							>
								{ (onDelete && !isHidden) && (
									<ConfirmDialog
										text="Are you sure you want to delete this report template?"
										onConfirm={withLoading(onDelete)}
									>
										<Tooltip title="Delete">
											<IconButton
												disabled={isLoading}
											>
												{ (showLoading === onDelete) ? (
													<CircularProgress size={24} />
												) : (
													<DeleteIcon />
												)}
											</IconButton>
										</Tooltip>
									</ConfirmDialog>
								) }
								{ (isHidden && onRestore) && (
									<Tooltip title="Restore">
										<IconButton
											disabled={isLoading}
											onClick={withLoading((e) => { e.preventDefault(); return onRestore(e); })}
										>
											{ (showLoading === onRestore) ? (
												<CircularProgress size={24} />
											) : (
												<RestoreFromTrashIcon />
											)}
										</IconButton>
									</Tooltip>
								) }
								{ (onEdit) && (
									<Tooltip title="Edit">
										<IconButton
											onClick={(e) => { e.preventDefault(); return onEdit(e); }}
										>
											<Edit />
										</IconButton>
									</Tooltip>
								)}
								{ onCopyToDashboard && (
									<Tooltip title="Copy to Dashboard">
										<IconButton
											disabled={isLoading}
											onClick={withLoading((e) => { e.preventDefault(); return onCopyToDashboard(e); })}
										>
											{ (showLoading === onCopyToDashboard) ? (
												<CircularProgress size={24} />
											) : (
												<DashboardCustomizeIcon />
											)}
										</IconButton>
									</Tooltip>
								) }
							</CardActions>
						</Box>
					</UnstyledLink>
				</CardActionArea>
			</div>
		</Card>
	);
}

ReportTemplateCard.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	image: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	isPublic: PropTypes.bool.isRequired,
	isAdminTemplate: PropTypes.bool,
	isUserTemplate: PropTypes.bool,
	isReportTemplate: PropTypes.bool,
	isDashboardTemplate: PropTypes.bool,
	isHidden: PropTypes.bool,
	onDelete: PropTypes.func,
	onRestore: PropTypes.func,
	onClick: PropTypes.func,
	onCopyToDashboard: PropTypes.func,
	onEdit: PropTypes.func,
};

ReportTemplateCard.defaultProps = {
	description: null,
	isAdminTemplate: undefined,
	isUserTemplate: undefined,
	isReportTemplate: undefined,
	isDashboardTemplate: undefined,
	isHidden: false,
	onDelete: null,
	onRestore: null,
	onClick: null,
	onCopyToDashboard: null,
	onEdit: null,
};
