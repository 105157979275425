import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from '../DataTable';
import RecordTableTools from '../RecordTableTools';

class TableOfUsers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			users, deleteRow, location, ghostUser,
		} = this.props;

		return (
			<DataTable
				identifier={(row) => row.id}
				definitions={[
					{ key: 'fullname', title: 'Name' },
					{ key: 'email', title: 'Email' },
					{
						key: 'id',
						title: '',
						align: 'right',
						format: (id) => (
							<RecordTableTools
								ghostAction={ghostUser ? () => ghostUser(id) : null}
								editHref={`${location.pathname}/${id}`}
								deleteAction={() => deleteRow(id)}
							/>
						),
					},
				]}
				data={users}
				showCheckboxes={false}
				selectableRows={false}
			/>
		);
	}
}

TableOfUsers.propTypes = {
	users: PropTypes.array,
	deleteRow: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	ghostUser: PropTypes.func,
};

TableOfUsers.defaultProps = {
	users: [],
	ghostUser: null,
};

export default withRouter(TableOfUsers);
