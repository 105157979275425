const DateUtils = require('../../misc/dateUtils');
const DynamicDateRanges = require('../dynamicDateRanges');
const { byReportType } = require('../reportType');

class TypeCustomizer {
	constructor(reportData, type) {
		Object.assign(this, {
			reportData,
			type,
		});
	}

	get reportType() {
		return byReportType[this.type];
	}

	// Dimensions that we can't filter by even though they can be grouped by
	getExcludedFilterKeys() {
		return [];
	}

	excludeNotAllowed() {
		return false;
	}

	performCall(params, callFn) {
		return callFn(params);
	}

	transformedReportParams(params) {
		return params;
	}

	transformedReportResult(res) {
		return res;
	}

	getDimensionExclude() {
		return null;
	}

	supportedGranularities() {
		return ['perday', 'perweek', 'permonth', 'peryear'];
	}

	hasTimezoneSupport() {
		return false;
	}

	siteSelectType() {
		return this.reportData.settings.type;
	}

	normalizeDate(dateStr) {
		return DateUtils.fullDay(dateStr);
	}

	hasDataForToday() {
		return false;
	}

	canSelectAhead() {
		return false;
	}

	canSelectBehind() {
		return true;
	}

	getGroupByOptions({ GROUP_BY_OPTIONS }) {
		return GROUP_BY_OPTIONS;
	}

	getAdditionalUserGroupByOptions() {
		return [];
	}

	allowAdditionalUserGroupByOption() {
		return false;
	}

	getCustomFilters() {
		return [];
	}

	splitMetricsToUiGroups(metrics) {
		return [{ metrics }];
	}

	getDynamicDateRanges() {
		return DynamicDateRanges.getLongTermRanges();
	}

	getDynamicDateRangesValues() {
		return Object.values(this.getDynamicDateRanges());
	}

	renderFilterHeader() {
		return null;
	}
}

module.exports = TypeCustomizer;
