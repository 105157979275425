import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import CardContent from '@mui/material/CardContent';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SystemData from '../../lib/systemData';
import BrowserUtils from '../../lib/browserUtils';
// eslint-disable-next-line import/no-cycle
import Base from '../../layouts/Base';

function CopyVerificationCode({
	code,
}) {
	const { loginLogoUrl } = SystemData.genericData.systemSettings;

	const copy = () => {
		BrowserUtils.copyTextToClipboard(code);
		Base.renderGlobal((close) => (
			<Snackbar
				open
				autoHideDuration={10000}
				onClose={close}
				message="Copied, paste this in the Verification Code in the previous tab"
			/>
		));
	};
	return (
		<>
			<Box
				textAlign="center"
				bgcolor="white"
				border={0}
				sx={{ width: '100%' }}
			>
				<img src={loginLogoUrl} alt="logo" style={{ width: '50%' }} />
			</Box>
			<Container sx={{ mt: 5 }}>
				<Card>
					<CardContent>
						<Typography variant="h5">
							Copy Google verification code
						</Typography>
						<Grid container spacing={3} alignItems="center">
							<Grid item xs={12}>
								<TextField
									label="Google verification code"
									fullWidth
									margin="normal"
									value={code}
									sx={{ caretColor: 'transparent' }}
									InputProps={{
										readOnly: true,
										startAdornment: (
											<InputAdornment position="start">
												<Tooltip title="Copy to clipboard">
													<IconButton
														aria-label="Copy to clipboard"
														onClick={copy}
														size="large"
													>
														<ContentCopyIcon />
													</IconButton>
												</Tooltip>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Container>
		</>
	);
}

CopyVerificationCode.propTypes = {
	code: PropTypes.string.isRequired,
};

CopyVerificationCode.defaultProps = {
};

export default CopyVerificationCode;
