import { useState, useCallback, useMemo } from 'react';
import filterItems from './filterSearchItems';

function useSearchFilter(reports) {
	const [searchTerm, setSearchTerm] = useState('');

	const onSearchChange = useCallback((e) => {
		setSearchTerm(e.target.value);
	}, []);

	const filteredBySearchReports = useMemo(() => filterItems(reports, searchTerm), [reports, searchTerm]);

	return { filteredBySearchReports, onSearchChange };
}

export default useSearchFilter;
