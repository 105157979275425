import React from 'react';
import PropTypes from 'prop-types';
import MuiSwitch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { wrapFnComponent } from '../Wrappers';
import styles from './styles.css';

function switchFn(props) {
	const {
		value, color, disabled, label, margin, onChange, size, name, style, hideLabelIfEmpty, labelPlacement,
	} = props;
	return (
		<FormControl style={style} margin={margin} {...(name ? { name } : {})}>
			{/* TODO: Remove hideLabelIEmpty.
				InputLabel should not be rendered if empty, even without hideLabelIfEmpty. But there's MANY <Select>, so make sure not to break the design... */}
			{((!hideLabelIfEmpty || label) ? (
				<FormControlLabel
					classes={{
						root: `MuiFormControlLabel-root ${size === 'small' ? styles.small : ''}`,
					}}
					control={(
						<MuiSwitch
							checked={!!value}
							onChange={() => onChange({ target: { name, value: !value } }, props)}
							color={color}
							size={size}
						/>
					)}
					disabled={disabled}
					label={label}
					labelPlacement={labelPlacement}
				/>
			) : (
				<MuiSwitch
					checked={!!value}
					onChange={() => onChange({ target: { name, value: !value } }, props)}
					color={color}
					size={size}
				/>
			)
			)}
		</FormControl>
	);
}

// Necessary to wrap in class so that 'ref' from Form's field() function works
const Switch = wrapFnComponent(switchFn);

Switch.propTypes = {
	value: PropTypes.bool.isRequired,
	color: PropTypes.oneOf(['default', 'primary', 'secondary']),
	disabled: PropTypes.bool,
	label: PropTypes.any.isRequired,
	onChange: PropTypes.func.isRequired,
	size: PropTypes.oneOf(['small', 'medium']),
	margin: PropTypes.oneOf(['none', 'dense', 'normal']),
	name: PropTypes.string,
	style: PropTypes.object,
	labelPlacement: PropTypes.string,
	hideLabelIfEmpty: PropTypes.bool,
};

Switch.defaultProps = {
	color: 'primary',
	disabled: false,
	size: 'medium',
	margin: undefined,
	name: undefined,
	style: undefined,
	hideLabelIfEmpty: false,
	label: '',
	labelPlacement: 'end',
};

export default Switch;
