import React from 'react';
import { Slide, Box } from '@mui/material';
import PropTypes from 'prop-types';

function SidePopout({
	open,
	sx,
	children,
}) {
	return (
		<Slide direction="left" in={open} mountOnEnter unmountOnExit>
			<Box sx={{
				height: 'calc(100% - 64px)',
				position: 'fixed',
				right: 0,
				top: '64px',
				zIndex: 100,
				...sx,
			}}
			>
				{children}
			</Box>
		</Slide>
	);
}

SidePopout.propTypes = {
	open: PropTypes.bool.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	sx: PropTypes.shape({}),
};

SidePopout.defaultProps = {
	sx: {},
};

export default SidePopout;
