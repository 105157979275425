import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Box, Collapse } from '@mui/material';
import Comment from './Comment';

export default function CommentList({ comments, onDeleteComment, onTransitionsDone }) {
	const sortedComments = comments.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
	return (
		<TransitionGroup>
			{ sortedComments.map((comment) => (
				<Collapse
					key={comment.id}
					onEntered={onTransitionsDone}
				>
					<Box mb={1}>
						<Comment
							{...comment}
							onDelete={onDeleteComment}
						/>
					</Box>
				</Collapse>
			))}
		</TransitionGroup>
	);
}

CommentList.propTypes = {
	comments: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		body: PropTypes.string.isRequired,
		authorInfo: PropTypes.shape({ fullname: PropTypes.string }).isRequired,
		createdDate: PropTypes.string.isRequired,
	})).isRequired,
	onDeleteComment: PropTypes.func.isRequired,
	onTransitionsDone: PropTypes.func.isRequired,
};
