import React, {
	useEffect,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import Constants from 'relevant-shared/reportData/constants';
import {
	Grid,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import DateUtils from '../../lib/dateUtils';
import Report from '../Report';
import useTopBar from '../../hooks/useTopbar';
import useReports from '../../hooks/useReports';
import useTitle from '../../hooks/useTitle';
import { Toast } from '../Toast/Toast';
import useQuery from '../../hooks/useQuery';
import useReportActions from './hooks/useReportActions';

function ViewEditReport({
	report,
	defaultSettings,
	type,
	noSave,
	isNew,
	warnOldData,
}) {
	const { pathname } = useLocation();
	const [isPrivate, setIsPrivate] = useState((Boolean(report && report.userId)) || isNew);

	const fallback = defaultSettings || ({
		showTable: true,
		start: DateUtils.fullDay(new Date(), -31),
		end: DateUtils.fullDay(new Date(), -1),
		type,
		...Constants[type]?.getDefaultProperties?.(true),
	});

	// Can get status from query params,
	// as we may render a new report,
	// while still wanting to show status for "old" deleted report for example
	const query = useQuery();
	// null | 'saving' | 'deleting'
	const [status, setStatus] = useState(query.get('status'));
	const [settingsOpen, setSettingsOpen] = useState(!report && !defaultSettings && fallback.settingsOpen !== false);
	const [shareUrl, setShareUrl] = useState('');
	// TODO: This solution of having the report component as a ref
	// is weird and should be removed at some point.
	const reportComponentRef = useRef();
	const { setSettings: setTopbarSettings, setStatus: setTopbarStatus } = useTopBar();
	const setTitle = useTitle();
	const { updateReports, reports } = useReports();

	useEffect(() => {
		// Close the toast after 10 seconds if action is completed
		const timeout = setTimeout(() => {
			if (status === 'saved' || status === 'deleted') {
				setStatus(null);
				// If status was set by URL query param
				if (query.get('status')) {
					// Silently remove the status from the query params,
					// use "native" api instead of react-router as we don't want to re-render the component
					window.history.replaceState(null, report?.settings?.title, pathname.split('?')[0]);
				}
			}
		}, 10000);
		return () => clearTimeout(timeout);
	}, [pathname, query, report?.settings?.title, status]);

	useReportActions({
		title: isNew ? (fallback.title || 'My report') : report?.settings?.title,
		isPrivate,
		noSave,
		pathname,
		report,
		reports,
		setTopbarSettings,
		setTitle,
		shareUrl,
		type,
		pinnedByUsers: report?.pinnedByUsers || [],
		updateReports,
		reportComponentRef,
		isNew,
		setStatus,
		setIsPrivate,
		setSettingsOpen,
		setShareUrl,
	});

	useEffect(() => {
		setTopbarStatus(status);
	}, [setTopbarStatus, status]);

	let statusMessage = '';
	let severity = 'info';
	if (status === 'saved') {
		severity = 'success';
		statusMessage = 'Report saved.';
	} else if (status === 'deleted') {
		statusMessage = 'Report deleted.';
	} else if (status === 'saveError') {
		statusMessage = 'Failed saving report.';
		severity = 'error';
	} else if (status === 'deleteError') {
		statusMessage = 'Failed deleting report.';
		severity = 'error';
	}

	return (
		<Grid item xs={12}>
			<Report
				ref={reportComponentRef}
				onSettingsOpenToggle={(isOpen) => setSettingsOpen(isOpen)}
				onTitleChange={(title) => setTitle(title)}
				isActualReport
				{...(report ? report.settings : fallback)}
				settingsOpen={settingsOpen}
				warnOldData={warnOldData}
			/>
			<Toast
				open={status === 'saved' || status === 'deleted' || status === 'saveError' || status === 'deleteError'}
				message={statusMessage}
				status={severity}
				timeout={severity === 'success' ? 3 : undefined}
				onClose={() => setStatus(null)}
			/>
		</Grid>
	);
}

ViewEditReport.propTypes = {
	report: PropTypes.object,
	defaultSettings: PropTypes.object,
	onCancel: PropTypes.func,
	type: PropTypes.string.isRequired,
	noSave: PropTypes.bool,
	displayPrivateCheckbox: PropTypes.bool,
	isNew: PropTypes.bool,
	// Is actual report, i.e not a report rendered from alarms page.
	isActualReport: PropTypes.bool,
	warnOldData: PropTypes.bool,
};

ViewEditReport.defaultProps = {
	report: null,
	defaultSettings: null,
	noSave: false,
	displayPrivateCheckbox: false,
	isNew: true,
	onCancel: null,
	isActualReport: true,
	warnOldData: false,
};

export default ViewEditReport;
