import React, { useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
	Button,
	Collapse,
} from '@mui/material';
import SharingStateFilter from './SharingStateFilter';
import AlertBalloon from '../../../AlertBalloon';
import useFilters from '../context/hooks/useFilters';

function PropertyFilter() {
	const [showFilters, setShowFilters] = useState(false);
	const { activeFilters } = useFilters();

	return (
		<div>
			<Button
				variant="outlined"
				sx={{
					height: '56px',
					mr: 2,
					mb: 4,
					...(activeFilters.length > 0 && {
						'.MuiButton-startIcon > *:nth-of-type(1)': { fontSize: '14px' },
					}),
				}}
				startIcon={(
					activeFilters.length === 0 ? <FilterAltIcon />
						: (
							<AlertBalloon
								sx={{
									pl: 0,
									pr: 0,
									width: '22px',
									bgcolor: 'info.main',
								}}
								label={activeFilters.length}
							/>
						)
				)}
				size="large"
				onClick={() => setShowFilters(!showFilters)}
			>
				Filters
			</Button>
			<Collapse in={showFilters}>
				<SharingStateFilter />
			</Collapse>
		</div>
	);
}

export default PropertyFilter;
