import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
	Box,
	Card,
	CardMedia,
	CardActionArea,
	Typography,
	CardActions,
	IconButton,
	Tooltip,
	CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import GroupIcon from '@mui/icons-material/Group';
import UnstyledLink from '../UnstyledLink';
import PinButton from './PinButton';
import useDebounce from '../../hooks/useDebounce';
import { ConfirmDialog } from '../ConfirmDialog';

function ReportCard({
	id,
	title,
	image,
	isPublic,
	createdDate,
	link,
	onDelete,
	pinnedToSidebar,
	onCopyToDashboard,
}) {
	const [loading, setLoading] = useState(null);
	const isLoading = !!loading;
	const showLoading = useDebounce(loading, 250);

	function withLoading(fn) {
		return async (...args) => {
			setLoading(() => fn);
			try {
				await fn(...args);
			} finally {
				setLoading(null);
			}
		};
	}

	return (
		<Card variant="outlined">
			<UnstyledLink to={link}>
				<CardActionArea sx={{ height: '100%' }}>
					<Box
						sx={{
							p: 2,
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						<Typography
							variant="body1"
							sx={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
							title={title}
						>
							{title}
						</Typography>
						<Typography variant="body2" sx={{ color: 'text.sub' }}>
							{' '}
							{ createdDate ? `Created ${moment(createdDate).fromNow()}` : ''}
						</Typography>

					</Box>
					<CardMedia
						component="img"
						alt="Report preview"
						height="100"
						image={image}
					/>
					<Box
						sx={{
							pt: 2,
							pr: 1,
							pb: 1,
							pl: 2,
							color: 'text.sub',
						}}
					>
						<Box sx={{
							visibility: isPublic ? 'visible' : 'hidden',
							display: 'flex',
							alignItems: 'center',
						}}
						>
							<GroupIcon sx={{ mr: 1 }} />
							<Typography variant="body">Public</Typography>
						</Box>
					</Box>
				</CardActionArea>
			</UnstyledLink>
			<CardActions
				disableSpacing
				sx={{ flexDirection: 'row-reverse' }}
			>
				{onDelete && (
					<ConfirmDialog
						text="Are you sure you want to delete this report?"
						onConfirm={withLoading(onDelete)}
					>
						<Tooltip title="Delete report">
							<IconButton aria-label="delete" disabled={isLoading}>
								{(showLoading === onDelete) ? (
									<CircularProgress size={24} />
								) : (
									<DeleteIcon />
								)}
							</IconButton>
						</Tooltip>
					</ConfirmDialog>
				)}
				<PinButton
					reportId={id}
					pinned={pinnedToSidebar}
					disabled={isLoading || !onCopyToDashboard}
				/>
				<Tooltip title="Copy to Dashboard">
					<IconButton
						aria-label="copy to dashboard"
						disabled={isLoading || !onCopyToDashboard}
						onClick={withLoading(onCopyToDashboard)}
					>
						{(showLoading === onCopyToDashboard) ? (
							<CircularProgress size={24} />
						) : (
							<DashboardCustomizeIcon />
						)}
					</IconButton>
				</Tooltip>
			</CardActions>
		</Card>
	);
}

ReportCard.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	createdDate: PropTypes.string,
	isPublic: PropTypes.bool.isRequired,
	onDelete: PropTypes.func.isRequired,
	pinnedToSidebar: PropTypes.bool,
	onCopyToDashboard: PropTypes.func,
};

ReportCard.defaultProps = {
	createdDate: undefined,
	deleting: false,
	pinnedToSidebar: false,
	onCopyToDashboard: undefined,
};

export default ReportCard;
