import React from 'react';
import { styled } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { IconButton, Paper, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const PREFIX = 'Comment';

const classes = {
	comment: `${PREFIX}-comment`,
	commentTitle: `${PREFIX}-commentTitle`,
	commentInfo: `${PREFIX}-commentInfo`,
	deleteButton: `${PREFIX}-deleteButton`,
};

const Root = styled('div')(({
	theme: { palette: { text }, spacing },
}) => ({
	[`& .${classes.comment}`]: {
		position: 'relative',
		maxWidth: spacing(8),
		wordWrap: 'break-word',
		hyphens: 'auto',
		padding: spacing(4),
		minWidth: spacing(48),
	},

	[`& .${classes.commentTitle}`]: {
		marginTop: spacing(0),
	},

	[`& .${classes.commentInfo}`]: {
		color: text.disabled,
	},

	[`& .${classes.deleteButton}`]: {
		position: 'absolute',
		top: 8,
		right: 8,
	},
}));

export default function Comment({
	title,
	body,
	authorInfo: author,
	createdDate,
	id,
	onDelete,
}) {
	return (
		<Root>
			<Paper className={classes.comment}>
				<Tooltip
					className={classes.deleteButton}
					title="Delete comment"
				>
					<IconButton onClick={() => onDelete(id)} size="large">
						<ClearIcon />
					</IconButton>
				</Tooltip>
				<h4 className={classes.commentTitle}>{title}</h4>
				<p>{body}</p>
				<span className={classes.commentInfo}>
					{moment(createdDate).fromNow()}
					{' '}
					by
					{' '}
					{author.fullname}
				</span>
			</Paper>
		</Root>
	);
}

Comment.propTypes = {
	title: PropTypes.string.isRequired,
	body: PropTypes.string.isRequired,
	authorInfo: PropTypes.shape({
		fullname: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
	}).isRequired,
	createdDate: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
};
