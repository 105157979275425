const _ = require('lodash');

const getDenseArray = (rootArr, data, calculateSumVal, groupBy, allSumInfos, dimToAttribs) => {
	const res = [];
	const paths = [null];
	const generate = (array, idx) => {
		const isFinal = (idx === data.levels - 1);
		array.forEach((element) => {
			paths[idx] = element.label;
			if (isFinal) {
				const obj = _.mapValues(
					allSumInfos,
					({ sum, isComp }) => calculateSumVal(element.data, sum, undefined, isComp),
				);
				paths.forEach((path, index) => {
					const dim = groupBy[index];
					obj[dim] = path;
					const dimAttribs = dimToAttribs[dim];
					if (dimAttribs) {
						const { attribs, byLabel } = dimAttribs;
						const attribObj = byLabel[path] || {};
						attribs.forEach(({ csvKey, name }) => {
							obj[csvKey] = attribObj[name] || 'n/a';
						});
					}
				});
				res.push(obj);
			} else {
				generate(element.data, idx + 1);
			}
		});
		paths.pop();
	};
	generate(rootArr, 0);
	return res;
};

module.exports = { getDenseArray };
