import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import Switch from '../Switch';
import ReactUtils from '../../lib/reactUtils';
// eslint-disable-next-line import/no-cycle
import PrebidConfigEdit from '.';
import Form from '../../containers/Form';
import { percentageTextField, countryFilter } from './utils';

function PrebidConfigChildTable(properties) {
	const {
		prebidConfig, title, field, props,
	} = properties;

	if (!prebidConfig.prebidConfigs.length) {
		return null;
	}

	return (
		<TableContainer>
			<Typography
				sx={{ flex: '1 1 100%' }}
				variant="h6"
				id="tableTitle"
				component="div"
			>
				{title}
			</Typography>
			<Table sx={{ minWidth: 650 }} aria-label="Prebid configuration children">
				<TableHead>
					<TableRow>
						<TableCell>
							{prebidConfig?.name}
							{' '}
							Children
						</TableCell>
						<TableCell>Enabled</TableCell>
						<TableCell>Percentage</TableCell>
						<TableCell>Country targeting</TableCell>
						<TableCell>Edit</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{ReactUtils.fldMap(prebidConfig, field, 'prebidConfigs', (row, fld) => (
						<TableRow key={row.id}>
							<TableCell component="th" scope="row">
								{row.name}
							</TableCell>
							<TableCell>
								<Switch {...fld('enabled')} />
							</TableCell>
							<TableCell>
								{percentageTextField({ parent: prebidConfig, child: row, fld })}
							</TableCell>
							<TableCell>
								{countryFilter({ child: row, form: Form.fromField(field) })}
							</TableCell>
							<TableCell align="right">
								<PrebidConfigEdit
									{...props}
									site={prebidConfig}
									parent={prebidConfig}
									title="Edit"
									autoExpandItem={row}
									field={field}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

PrebidConfigChildTable.propTypes = {
	prebidConfig: PropTypes.object.isRequired,
	title: PropTypes.string,
	parent: PropTypes.oneOf([null, Object]),
	field: PropTypes.func.isRequired,
};

PrebidConfigChildTable.defaultProps = {
	title: null,
	parent: null,
};

export default PrebidConfigChildTable;
