import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@mui/material/TextField';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import styles from './styles.css';

const initialTooltipText = 'Copy to clipboard';

function CopyableTextField({ textFieldProps }) {
	const [copyTooltipText, setCopyTooltipText] = useState(initialTooltipText);
	const textFieldInputRef = useRef();

	return (
		<div className={styles.container}>
			<MuiTextField
				{...textFieldProps}
				onFocus={({ target }) => target.select()}
				InputProps={{
					endAdornment: (
						<Tooltip
							title={copyTooltipText}
						>
							<IconButton
								onClick={() => {
									textFieldInputRef.current.select();
									const copySuccess = document.execCommand('copy');
									if (copySuccess) {
										setCopyTooltipText('Copied!');
									}
								}}
								size="large"
							>
								<FileCopyIcon
									className={styles.copyIcon}
									onMouseOut={() => {
										setCopyTooltipText(initialTooltipText);
									}}
								/>
							</IconButton>
						</Tooltip>
					),
				}}
				inputProps={{
					className: styles.textFieldInput,
					ref: textFieldInputRef,
				}}
			/>
		</div>
	);
}

CopyableTextField.propTypes = {
	textFieldProps: PropTypes.objectOf(PropTypes.any),
};

CopyableTextField.defaultProps = {
	textFieldProps: {},
};

export default CopyableTextField;
