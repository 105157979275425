import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CollapseHeader from '../CollapseHeader';
import OperationWrapper from '../OperationWrapper';
import BrowserUtils from '../../lib/browserUtils';

class ExpandSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = { expanded: this.props.expanded };
	}

	render() {
		const {
			title,
			selected,
			fn,
			onExpandChange,
			checkChangeAllowed,
			getSingleTitle,
			customHeader,
			content,
			style,
			paperProps,
			headerProps,
			collapseProps,
		} = this.props;
		const { expanded } = onExpandChange ? this.props : this.state;
		const selectedText = () => {
			if (!selected.length) {
				return '';
			} if (selected.length === 1 && getSingleTitle) {
				const singleTitle = getSingleTitle(selected[0]);
				if (singleTitle) {
					return (
						<Box color="success.main" sx={{ fontSize: '14px' }}>
							(
							{singleTitle}
							)
						</Box>
					);
				}
			}
			return (
				<Box color="success.main">
					(
					{selected.length}
					{' '}
					selected)
				</Box>
			);
		};
		return (
			<Paper ref={(elm) => { this.ref = elm; }} {...paperProps}>
				<Box padding={2} style={style}>
					<Box {...headerProps}>
						<CollapseHeader
							{...collapseProps}
							expanded={expanded}
							onClick={() => {
								const newExpanded = !expanded;
								const hasErrors = () => BrowserUtils.selectorHasFormErrors(this);
								if (onExpandChange) {
									onExpandChange(newExpanded, { hasErrors });
								} else if (!checkChangeAllowed || checkChangeAllowed(newExpanded)) {
									if (!newExpanded && hasErrors()) {
										return;
									}
									this.setState({ expanded: !this.state.expanded });
								}
							}}
							title={title}
							variant="h3"
						>
							{customHeader || selectedText()}
						</CollapseHeader>
					</Box>
					<OperationWrapper
						fn={fn}
						load={expanded}
						content={() => (
							<Collapse in={expanded} unmountOnExit>
								<Box marginTop={1}>
									{content ? content(this) : this.props.children}
								</Box>
							</Collapse>
						)}
					/>
				</Box>
			</Paper>
		);
	}
}

ExpandSelector.propTypes = {
	title: PropTypes.node.isRequired,
	expanded: PropTypes.bool,
	selected: PropTypes.array,
	fn: PropTypes.func,
	onExpandChange: PropTypes.func,
	checkChangeAllowed: PropTypes.func,
	getSingleTitle: PropTypes.func,
	form: PropTypes.object,
	forms: PropTypes.object,
	customHeader: PropTypes.any,
	content: PropTypes.func,
	style: PropTypes.object,
	paperProps: PropTypes.object,
	headerProps: PropTypes.object,
	collapseProps: PropTypes.object,
};

ExpandSelector.defaultProps = {
	selected: [],
	expanded: false,
	fn: () => {},
	onExpandChange: undefined,
	checkChangeAllowed: undefined,
	getSingleTitle: undefined,
	form: undefined,
	forms: undefined,
	customHeader: undefined,
	content: undefined,
	style: undefined,
	paperProps: undefined,
	headerProps: undefined,
	collapseProps: undefined,
};

export default ExpandSelector;
