/* eslint-disable react/jsx-one-expression-per-line */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AllMappingDimensions from 'relevant-shared/mappingDimensions/allMappingDimension';
import { createCsv } from 'relevant-shared/misc/misc';
import * as relevantApi from '../../api/relevant';
import DataGrid from '../DataGrid';
import DataTable from '../DataTable';
import OperationWrapper from '../OperationWrapper';
import { FormOf, Scope } from '../Wrappers';
import TextField from '../TextField';
import SingleMapping from './singleMapping';
import MatchReport from './matchReport';
import { ActionButton } from '../ActionButton/ActionButton';
import JobButton from '../JobButton';
import Select from '../Select';
import PopupSelector from '../PopupSelector';
import DynamicSearchFilter from '../DynamicSearchFilter';
import RecordTableTools from '../RecordTableTools';
import { stores } from '../../stores';
import Checkbox from '../Checkbox';
import CsvImport from '../CsvImport';
import Base from '../../layouts/Base';
import { createFilterFnOptions, filterOptions } from '../SiteSelect/filterOptions';
import MuiDialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import BrowserUtils from '../../lib/browserUtils';
import DatePicker from '../DatePicker';
import DateUtils from '../../lib/dateUtils';
import SystemData from '../../lib/systemData';
import classes from '../../api/classes';
import { ConfirmDialog } from '../ConfirmDialog';
import { Button } from '@mui/material';
import { Dialog } from '../Dialog';

const { Reports } = classes;

const relevant = relevantApi.DynamicExports;

class AdvertiserMappingEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = { mappingListFilter: '' };
		this.knownAdvertisers = {};
		this.mappingListRef = React.createRef();
	}

	async loadAdvertisers() {
		const { model } = this.props;
		const newAdvertisers = {};
		const check = (advNr) => {
			if (!(advNr in newAdvertisers)) {
				newAdvertisers[advNr] = true;
			}
		};
		model.mappings.forEach((mapping) => {
			check(mapping.targetAdvNr);
			(mapping.advertisers || []).forEach((advNr) => check(advNr));
		});
		if (_.isEmpty(newAdvertisers)) {
			return;
		}
		const advNrs = Object.keys(newAdvertisers).map((nr) => parseInt(nr, 10));
		const { clsName } = this.mappingDim();
		const advs = await relevant[clsName].call('getObjects', { objNrs: advNrs });
		advs.forEach((adv) => {
			adv.advNr = adv.seq.toString();
		});
		this.knownAdvertisers = _.keyBy(advs, 'advNr');
		this.updateMappingStructures();
	}

	advName(nr) {
		return (this.knownAdvertisers[nr] || {}).name || '';
	}

	addAdvObjects(advOrAdvs) {
		const advs = _.isArray(advOrAdvs) ? advOrAdvs : [advOrAdvs];
		advs.forEach((adv) => {
			adv.advNr = adv.seq;
			this.knownAdvertisers[adv.advNr] = adv;
		});
	}

	async doImport({ nonExistingNames, rows, updateRowsFromAdvs }) {
		const { model } = this.props;
		const { mappings } = model;
		const mappingDim = this.mappingDim();
		if (!rows.length) {
			return true; // nothing to do..
		}
		if (nonExistingNames.length) {
			const ok = await Base.renderGlobal((closeFn) => (
				<ConfirmDialog
					open
					text={(
						<span>
							We need to create&nbsp;
							<span style={{ fontWeight: 'bold' }}>
								{nonExistingNames.length}
							</span>
							{' '}
							new&nbsp;
							{mappingDim.name}
							(s) in the system, do you want to proceed?
						</span>
					)}
					onAny={closeFn}
				/>
			));
			if (!ok) {
				return false;
			}
			const { clsName } = this.mappingDim();
			const advs = await relevant[clsName].call('updateObjects', {
				objects: nonExistingNames.map((name) => ({ name })),
				returnObjects: true,
			});
			updateRowsFromAdvs(rows, advs);
		}
		if (rows.find((r) => !r.fromAdvNr || !r.toAdvNr)) {
			throw Error('Ehh!?');
		}
		const byTarget = _.keyBy(mappings, 'targetAdvNr');
		const byAdv = {};
		model.mappings.forEach((mapping) => {
			mapping.advertisers.forEach((advNr) => {
				byAdv[advNr] = mapping;
			});
		});
		let mappedCount = 0;
		rows.forEach((row) => {
			let mapping = byTarget[row.toAdvNr];
			if (!mapping) {
				mapping = {
					targetAdvNr: row.toAdvNr,
					advertisers: [row.toAdvNr],
					byWords: [],
					tempRandom: Math.random(),
				};
				byTarget[row.toAdvNr] = mapping;
				mappings.push(mapping);
			}
			const oldMapping = byAdv[row.fromAdvNr];
			if (!oldMapping || oldMapping !== mapping) {
				mappedCount++;
			}
			if (oldMapping) {
				_.pull(oldMapping.advertisers, row.fromAdvNr);
			}
			mapping.advertisers.push(row.fromAdvNr);
		});
		Base.renderGlobal((close) => (
			<Dialog
				open
				status="success"
				text={`Mapped ${mappedCount} ${this.mappingDim().pluralName}`}
				onClose={close}
			/>
		));
		this.updateMappingStructures();
		return true;
	}

	getVisibleMappings() {
		const { mappingListFilter: filter, mappingListFilterStr: str } = this.state;
		const { model } = this.props;
		let { mappings } = model;
		if (filter && str) {
			const include = _.keyBy(filterOptions(str, filter), 'id');
			mappings = mappings.filter((m) => include[m.targetAdvNr]);
		}
		return _.sortBy(mappings, (m) => this.advName(m.targetAdvNr));
	}

	async handleCsvImport(orgRows)	{
		const isAdvName = (name) => name && name !== '#N/A';
		const mappingDim = this.mappingDim();

		const rows = _.uniqBy(orgRows.filter((row) => {
			row.fromAdv = row.fromAdv.trim();
			row.toAdv = row.toAdv.trim();
			return isAdvName(row.fromAdv) && isAdvName(row.toAdv) && row.fromAdv !== row.toAdv;
		}), 'fromAdv');

		let nonExistingNames;

		const updateRowsFromAdvs = (rowsArr, advs) => {
			const advsByName = _.keyBy(advs, 'name');
			rowsArr.forEach((row) => {
				row.fromAdvNr = row.fromAdvNr || (advsByName[row.fromAdv] || {}).seq;
				row.toAdvNr = row.toAdvNr || (advsByName[row.toAdv] || {}).seq;
			});
			this.addAdvObjects(advs);
		};

		const loadAdvData = async () => {
			const allAdvNames = _.uniq(_.flatten(rows.map(({ fromAdv, toAdv }) => ([fromAdv, toAdv]))));

			const existing = await relevant[mappingDim.clsName].call('getObjects', { objNames: allAdvNames });
			updateRowsFromAdvs(rows, existing);
			const existingByName = _.keyBy(existing, 'name');
			nonExistingNames = allAdvNames.filter((n) => !existingByName[n]);
		};
		const renderAdvName = (name, isExisting) => (
			<span style={{ color: isExisting ? 'gray' : 'black' }}>{name}</span>
		);
		Base.renderGlobal((done) => (
			<PopupSelector
				forceExpanded
				fn={() => loadAdvData()}
				onApplyChanges={async ({ preventDefault }) => {
					if (!(await this.doImport({ nonExistingNames, rows, updateRowsFromAdvs }))) {
						preventDefault();
					} else {
						done();
					}
				}}
				onCancel={done}
				content={() => (
					<DataTable
						showCheckboxes={false}
						selectableRows={false}
						identifier={(row) => row.toAdv}
						definitions={[
							{
								key: 'toAdv',
								title: `Target ${mappingDim.name}`,
								format: (__, { fromAdvs }) => renderAdvName(fromAdvs[0].toAdv, !!fromAdvs[0].toAdvNr),
							},
							{
								key: 'toAdv',
								title: `From ${mappingDim.pluralName}`,
								format: (__, { fromAdvs }) => _.sortBy(fromAdvs).map(({ fromAdv, fromAdvNr }) => (
									<div key={fromAdv}>
										{renderAdvName(fromAdv, !!fromAdvNr)}
									</div>
								)),
							},
						]}
						data={_.sortBy(_.entries(_.groupBy(rows, 'toAdv')).map(([toAdv, fromAdvs]) => ({ toAdv, fromAdvs })), 'toAdv')}
					/>
				)}
			/>
		));
	}

	mappingDim() {
		return AllMappingDimensions.byName(this.props.model.dimType);
	}

	checkForbidden(nr, currentMapping) {
		const { advNrToMapping } = this.state;
		const existing = advNrToMapping?.[nr];
		// eslint-disable-next-line eqeqeq
		if (existing && !(currentMapping?.targetAdvNr == existing.targetAdvNr)) {
			return `Already mapped to '${this.advName(existing.targetAdvNr)}'`;
		}
		return false;
	}

	updateMappingStructures() {
		const { model } = this.props;
		const advName = (nr) => this.advName(nr);
		const options = [];
		const advNrToMapping = {};
		const advNameToMapping = {};
		model.mappings?.forEach((mapping) => {
			const { targetAdvNr, byWords, advertisers } = mapping;
			const allAdvs = [targetAdvNr, ...(advertisers || [])];
			options.push({
				id: targetAdvNr,
				path: _.uniq([..._.map(allAdvs, advName), ..._.map(byWords, 'word')]).join(' '),
			});
			allAdvs.forEach((nr) => {
				advNrToMapping[nr] = mapping;
				advNameToMapping[advName(nr)] = mapping;
			});
		});
		this.setState({
			mappingListFilter: createFilterFnOptions(options),
			advNrToMapping,
			advNameToMapping,
		});
	}

	renderSingleMapping(orgMapping) {
		const { model, type, metric } = this.props;
		const { targetAdvNr } = orgMapping;
		return Base.renderGlobal((done) => (
			<FormOf
				model={_.cloneDeep(orgMapping)}
				content={({ field, model: newMapping, form }) => (
					<PopupSelector
						size="lg"
						form={form}
						allowErrorsIfCancel
						forceExpanded
						title={this.advName(targetAdvNr)}
						onApplyChanges={() => {
							const idx = _.findIndex(model.mappings, { targetAdvNr });
							model.mappings[idx] = newMapping;
							this.updateMappingStructures();
							done();
						}}
						onCancel={done}
					>
						<SingleMapping
							field={field}
							type={type}
							mapping={newMapping}
							checkForbidden={(nr) => this.checkForbidden(nr, newMapping)}
							metric={metric}
							knownAdvertisers={this.knownAdvertisers}
							mappingDim={this.mappingDim()}
						/>
					</PopupSelector>
				)}
			/>
		));
	}

	renderAddNewMapping() {
		const {
			model, type, metric,
		} = this.props;
		const mappingDim = this.mappingDim();
		const advsToNums = (arr) => arr.map((adv) => adv.advNr);

		const onAdd = ({ scope, newMappingAdvertisers }) => {
			newMappingAdvertisers.forEach((adv) => {
				this.knownAdvertisers[adv.advNr] = adv;
			});
			const advertisers = newMappingAdvertisers.map((adv) => parseInt(adv.advNr, 10));
			const newMapping = {
				targetAdvNr: advertisers[0],
				advertisers,
				byWords: [],
				tempRandom: Math.random(),
				dimType: mappingDim.name,
			};
			model.mappings.push(newMapping);
			scope.setState({ newMappingAdvertisers: [] });
			if (this.advList) {
				this.advList.setSearchString('');
			}
			this.updateMappingStructures();
			this.renderSingleMapping(newMapping);
		};

		return (
			<Scope
				content={(scope, { newMappingAdvertisers = [] }) => (
					<Card style={{ overflow: 'visible' }}>
						<CardContent>
							<Typography variant="h2">
								Add new mapping from target
								{' '}
								{mappingDim.name}
							</Typography>
							<DynamicSearchFilter
								selected={advsToNums(newMappingAdvertisers)}
								onChange={(__, advs) => scope.setState({ newMappingAdvertisers: advs })}
								type={type}
								serverCall={() => newMappingAdvertisers}
								createUsingDimObject={mappingDim}
								hideEmptySelected
								ref={(advList) => {
									this.advList = advList;
								}}
								metric={metric}
								dimension="advNr"
								label={mappingDim.dimension}
								groupBy={mappingDim.dimension}
								noMapping
								fullWidth
								margin="normal"
								textFieldLabel={`Add ${mappingDim.uiCName}(s)`}
								showMoreLabel={mappingDim.pluralName}
								checkForbidden={(nr) => this.checkForbidden(nr)}
							/>
							<ActionButton
								label="Add"
								disabled={newMappingAdvertisers.length === 0}
								onClick={() => onAdd({ scope, newMappingAdvertisers })}
							/>
						</CardContent>
					</Card>
				)}
			/>
		);
	}

	async exportCsv(perMappedAdv, doneFn, { includeTargetExtId, includeSsps, advReportStart }) {
		const mappingDim = this.mappingDim();
		const { model } = this.props;
		const mappings = _.sortBy(model.mappings, (m) => this.advName(m.targetAdvNr));
		const rows = [];
		let advToSsp;
		if (includeSsps) {
			const { dimension, reportProp } = mappingDim;
			const { data, labels } = await Reports.reportProgrammatic({
				groupBy: [dimension, 'sspId'],
				sums: ['soldImpressions'],
				maxAdvertisers: 100000,
				start: advReportStart,
				end: DateUtils.yesterday(),
				[reportProp]: SystemData.genericData.ADVERTISERS.DUMMY_NO_MAPPING,
			});
			advToSsp = _.mapValues(_.invert(labels[dimension]), (nr) => (
				_.keys(data[nr]).map((sspId) => labels.sspId[sspId])
			));
		}
		mappings.forEach((m) => {
			const { externalId = '', name: target = '' } = this.knownAdvertisers[m.targetAdvNr] || {};
			const advs = m.advertisers?.map((a) => this.advName(a)).filter((a) => a && a !== target);
			if (advToSsp?.[target]) { // include target advertiser when there are SSPs using that name
				advs.push(target);
			}
			const words = m.byWords.map((w) => w?.word).filter((w) => w);
			if (!target || (perMappedAdv && !advs.length) || (!advs.length && !words.length)) {
				return;
			}
			const base = { target, externalId };
			if (perMappedAdv) {
				rows.push(...advs.map((adv) => (
					(advToSsp?.[adv] || ['']).map((ssp) => ({ ...base, adv, ssp }))
				)).flat());
			} else {
				rows.push(({ ...base, advs: advs.join(', '), words: words.join(', ') }));
			}
		});
		const COLS = {
			target: `Target ${mappingDim.name}`,
			...(includeTargetExtId && { externalId: `Target ${mappingDim.name} External ID` }),
			...(perMappedAdv ? {
				adv: `Mapped ${mappingDim.name}`,
			} : {
				advs: 'Direct mappings',
				words: 'Match by words',
			}),
			...(includeSsps && { ssp: 'Source SSP' }),
		};
		BrowserUtils.downloadTextFile(createCsv(rows, COLS), `${model.name || 'mapping'}.csv`);
		doneFn();
	}

	renderExportToCsv() {
		const mappingDim = this.mappingDim();
		Base.renderGlobal((close) => (
			<MuiDialog open onClose={close}>
				<DialogTitle>CSV Export</DialogTitle>
				<DialogContent dividers>
					Do you want to export mappings one line per target {mappingDim.name} (as in the list), or one line
					for each mapped {mappingDim.name}.
				</DialogContent>
				<DialogActions>
					<FormOf
						model={{
							includeTargetExtId: false,
							includeSsps: false,
							advReportStart: DateUtils.daysFromToday(-30),
						}}
						content={({ field, model }) => (
							<Stack spacing={2}>
								<Checkbox
									label={`Include External ID for target ${mappingDim.name}`}
									fullWidth
									{...field('includeTargetExtId')}
								/>
								<Checkbox
									label="Include source SSPs from report data"
									fullWidth
									{...field('includeSsps')}
								/>
								{model.includeSsps && (
									<DatePicker
										floatingLabelText="Read report data for SSPs from"
										maxDate={DateUtils.yesterday()}
										autoOk
										{...field('advReportStart')}
									/>
								)}
								<Stack spacing={10} direction="row">
									<JobButton
										disabled={model.includeSsps}
										label={`Per target ${mappingDim.name}`}
										fn={() => this.exportCsv(false, close, model)}
									/>
									<JobButton
										label={`Per mapped ${mappingDim.name}`}
										fn={() => this.exportCsv(true, close, model)}
									/>
									<Button onClick={close} variant="text">Cancel</Button>
								</Stack>
							</Stack>
						)}
					/>
				</DialogActions>
			</MuiDialog>
		));
	}

	render() {
		const {
			model, submit, field, onCancel, all, type, metric,
		} = this.props;
		const { mappingListFilterStr } = this.state;
		const mappingDim = this.mappingDim();
		const advName = (nr) => this.advName(nr);
		const isAdmin = stores.identity.isAdministrator();
		const mappingIdx = (mapping) => model.mappings.indexOf(mapping);
		return (
			<OperationWrapper
				fn={() => this.loadAdvertisers()}
				style={{ width: '100%' }}
				content={() => (
					<Grid container spacing={3}>
						<Grid item xs={4}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Card>
										<CardContent>
											<Typography variant="h2">
												General settings
											</Typography>
											<TextField label="Name" required {...field('name')} fullWidth margin="normal" />
											<Select
												label="Parent Mapping"
												{...field('parent')}
												nonSelected="(none)"
												items={_.sortBy(all.filter((m) => (
													m.dimType === mappingDim.name && m.id !== model.id
												)), 'name').map((m) => ({ label: m.name, value: m.id }))}
												fullWidth
												margin="normal"
											/>
											{isAdmin && <Checkbox label="User visible" {...field('userVisible')} margin="normal" />}
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12}>
									{this.renderAddNewMapping()}
								</Grid>
								<Grid item>
									<ActionButton
										label="Ok"
										color="primary"
										onClick={submit}
									/>
								</Grid>
								<Grid item>
									<ActionButton
										label="Cancel"
										onClick={onCancel}
										variant="text"
										color="primary"
									/>
								</Grid>
								{this.props.canCreateAdvertisers && (
									<Grid item>
										<CsvImport
											colSelectors={[
												{
													name: 'fromAdv',
													label: `From ${mappingDim.name} column`,
													required: true,
												},
												{
													name: 'toAdv',
													label: `Target ${mappingDim.name} colum`,
													required: true,
												},
											]}
											onCsvImported={(rows) => { this.handleCsvImport(rows); }}
										/>
									</Grid>
								)}
								<Grid item>
									<ActionButton
										label="Export to .csv"
										onClick={() => this.renderExportToCsv()}
										color="primary"
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={8}>
							<Card>
								<CardContent>
									<Typography variant="h2">
										Mappings
									</Typography>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<TextField
												label="Filter mappings"
												uncontrolled
												defaultValue={mappingListFilterStr}
												name="filterMappings"
												startAdornment={<FilterAltOutlinedIcon />}
												onChange={(ev) => {
													this.lastFilterUpdateEvent = ev;
													const setOpacity = (val) => {
														const { style } = this.mappingListRef.current;
														if (style) {
															style.opacity = val;
														}
													};
													setOpacity(0.4);
													setTimeout(() => {
														if (this.lastFilterUpdateEvent === ev) {
															this.setState({ mappingListFilterStr: ev.target.value });
															setOpacity(1);
														}
													}, 500);
												}}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} ref={this.mappingListRef}>
											<DataGrid
												pagination
												disableColumnMenu
												disableSelectionOnClick
												identifier={(row) => row.id || row.tempRandom}
												definitions={[
													{
														key: 'targetAdvNr',
														title: `Target ${mappingDim.uiName}`,
														sortable: true,
														transform: (nr) => advName(nr),
													},
													{
														key: 'advertisers',
														title: 'Direct mappings',
														flex: 1.3,
														sortable: true,
														transform: (advertisers, mapping) => (advertisers || [])
															.filter((nr) => nr !== mapping.targetAdvNr)
															.map(advName)
															.join(', '),
													},
													{
														key: 'byWords',
														title: 'Match by words',
														sortable: true,
														transform: (byWords) => (byWords || []).map((by) => by.word).join(', '),
													},
													{
														key: 'targetAdvNr',
														title: 'Matchings',
														minWidth: 100,
														flex: 0.3,
														format: (__, mapping) => (
															<MatchReport
																type={type}
																metric={metric}
																mapping={{ mappings: [mapping], dimType: mappingDim.name }}
																dimType={mappingDim.name}
															/>
														),
													},
													{
														key: 'targetAdvNr',
														minWidth: 100,
														flex: 0.3,
														title: '',
														format: (__, mapping) => (
															<RecordTableTools
																editAction={() => this.renderSingleMapping(mapping)}
																deleteAction={() => {
																	model.mappings.splice(mappingIdx(mapping), 1);
																	this.updateMappingStructures();
																}}
															/>
														),
													},
												]}
												data={this.getVisibleMappings()}
											/>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				)}
			/>
		);
	}
}

AdvertiserMappingEditor.propTypes = {
	model: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	all: PropTypes.array.isRequired,
	canCreateAdvertisers: PropTypes.bool.isRequired,
	field: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
	type: PropTypes.string,
	metric: PropTypes.string,
};

AdvertiserMappingEditor.defaultProps = {
	type: 'programmatic',
	metric: 'revenue',
};

export default AdvertiserMappingEditor;
