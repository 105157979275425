import _ from 'lodash';

export const DEFAULT_SPLITTERS = ' \t\r\n\f,-–[]()_/';
export const EXTENDED_SPLITTERS = `${DEFAULT_SPLITTERS}.`;

export const splitByChars = (str, nParts, splitChars) => {
	const n = nParts || 0;
	const chars = splitChars || DEFAULT_SPLITTERS;
	const res = [];
	let wordStart = -1;
	if (n && n < 2) {
		throw Error('n must be >= 2');
	}
	const s = _.trim(str, splitChars);
	for (let i = 0; i < s.length; i += 1) {
		if (chars.indexOf(s[i]) >= 0) { // whitespace
			if (wordStart >= 0) { // we have finished a word
				res.push(s.slice(wordStart, i));
				wordStart = -1;
			}
		} else { // normal
			if (n && res.length === n - 1) { // add rest of string as last word
				res.push(s.slice(i));
				break;
			}
			if (wordStart === -1) {
				wordStart = i;
			}
		}
	}
	if (wordStart >= 0) {
		res.push(s.slice(wordStart)); // add end of string as word
	}
	return n && res.length < n ? null : res;
};
/*
const longestCommonSubStr = (str1, str2) => {
	let longest = 0;
	let longestStart = 0;
	for (let i = 0; i < str1.length; i += 1) {
		for (let j = 0; j < str2.length; j += 1) {
			let same = 0;
			const remaining = Math.min(str1.length - i, str2.length - j);
			for (let k = 0; k < remaining; k += 1) {
				if (str1[i + k] === str2[j + k]) {
					same += 1;
				} else {
					break;
				}
			}
			if (same > longest) {
				longest = same;
				longestStart = i;
			}
		}
	}
	return str1.slice(longestStart, longestStart + longest);
};
*/
export const getNamedDimensions = (str) => {
	const dims = /(\d+)[x|X](\d+)/.exec(str);
	if ((dims || []).length < 3) {
		return null;
	}
	const res = {
		width: parseInt(dims[1], 10),
		height: parseInt(dims[2], 10),
	};
	if (isNaN(res.width) || isNaN(res.height)) {
		return null;
	}
	return res;
};

// Copied from => https://github.com/trekhleb/javascript-algorithms/tree/master/src/algorithms/sets/longest-common-subsequence
export function longestCommonSubsequence(set1, set2) {
	// Init LCS matrix.
	const lcsMatrix = Array(set2.length + 1).fill(null).map(() => Array(set1.length + 1).fill(null));

	// Fill first row with zeros.
	for (let columnIndex = 0; columnIndex <= set1.length; columnIndex += 1) {
		lcsMatrix[0][columnIndex] = 0;
	}

	// Fill first column with zeros.
	for (let rowIndex = 0; rowIndex <= set2.length; rowIndex += 1) {
		lcsMatrix[rowIndex][0] = 0;
	}

	// Fill rest of the column that correspond to each of two strings.
	for (let rowIndex = 1; rowIndex <= set2.length; rowIndex += 1) {
		for (let columnIndex = 1; columnIndex <= set1.length; columnIndex += 1) {
			if (set1[columnIndex - 1] === set2[rowIndex - 1]) {
				lcsMatrix[rowIndex][columnIndex] = lcsMatrix[rowIndex - 1][columnIndex - 1] + 1;
			} else {
				lcsMatrix[rowIndex][columnIndex] = Math.max(
					lcsMatrix[rowIndex - 1][columnIndex],
					lcsMatrix[rowIndex][columnIndex - 1],
				);
			}
		}
	}

	// Calculate LCS based on LCS matrix.
	if (!lcsMatrix[set2.length][set1.length]) {
		// If the length of largest common string is zero then return empty string.
		return [''];
	}

	const longestSequence = [];
	let columnIndex = set1.length;
	let rowIndex = set2.length;

	while (columnIndex > 0 || rowIndex > 0) {
		if (set1[columnIndex - 1] === set2[rowIndex - 1]) {
			// Move by diagonal left-top.
			longestSequence.unshift(set1[columnIndex - 1]);
			columnIndex -= 1;
			rowIndex -= 1;
		} else if (lcsMatrix[rowIndex][columnIndex] === lcsMatrix[rowIndex][columnIndex - 1]) {
			// Move left.
			columnIndex -= 1;
		} else {
			// Move up.
			rowIndex -= 1;
		}
	}

	return longestSequence;
}

const replaceDoubleSplitters = (str, replacer = ' ') => {
	let res = '';
	let fstNormal = 0;
	for (let i = 0; i < str.length; i += 1) {
		let splitters = 0;
		while (i < str.length && EXTENDED_SPLITTERS.indexOf(str[i]) >= 0) {
			splitters += 1;
			i += 1;
		}
		if (splitters >= 2) {
			res += str.slice(fstNormal, i - splitters) + replacer;
			fstNormal = i;
		}
	}
	res += str.slice(fstNormal);
	return res;
};

export const findCommonPlacementName = (str1, str2 /* , width, height */) => {
	const existingDims = (/(\d+x\d+)/.exec(str1) || [])[1];
	const newDims = (/(\d+x\d+)/.exec(str2) || [])[1];
	if (existingDims && newDims) {
		str2 = str2.replace(newDims, existingDims);
	}
	let str = longestCommonSubsequence(str1.split(''), str2.split('')).join('');
	str = replaceDoubleSplitters(str);
	return _.trim(str, EXTENDED_SPLITTERS);
};

const CHILD_ARR_BY_NAME = {
	Root: 'publishers',
	Publisher: 'websites',
	Site: 'placements',
	Placement: 'ssps',
};

export const objFns = {
	getChildren: (n) => n.publishers || n.websites || n.placements || n.ssps || [],
	getLabel: (n) => n.name || n.domain || n.__name,
	getNodeId: (n) => n.__rand || n.id,
	childArrName: (n) => CHILD_ARR_BY_NAME[n.__type],
	getParentMatching: (n, fn, includeSelf) => {
		for (let elm = includeSelf ? n : n.__parent; elm; elm = elm.__parent) {
			if (fn(elm)) {
				return elm;
			}
		}
	},
	getSite: (n) => objFns.getParentMatching(n, (p) => p.__type === 'Site', true),
	getPublisher: (n) => objFns.getParentMatching(n, (p) => p.__type === 'Publisher', true),
};

export const entryData = (entry) => ({
	name: ((/(.*)\(.*\)/.exec(entry.name) || [])[1] || '').trim(),
	id: (/.*?-(.*)/.exec(entry.sourceId) || [])[1],
	ssp: entry.parent.sspObj,
});
