import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import FieldArray from '../FieldArray';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import DynamicSearchFilter from '../DynamicSearchFilter';
import MatchReport from './matchReport';
import * as relevantApi from '../../api/relevant';

const { ADVERTISER_MAPPED_AWAY } = MatchReport;
const WORD_MIN_LENGTH = 3;
const relevant = relevantApi.DynamicExports;

class SingleMapping extends React.Component {
	render() {
		const {
			mapping, type, field, metric, knownAdvertisers, mappingDim, checkForbidden,
		} = this.props;
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Card style={{ overflow: 'visible' }}>
						<CardContent>
							<Typography variant="h2">
								Directly mapped
								{' '}
								{mappingDim.pluralName}
							</Typography>
							<DynamicSearchFilter
								selected={mapping.advertisers.map((nr) => nr.toString())}
								serverCall={(objNrs) => relevant[mappingDim.clsName].call('getObjects', { objNrs })}
								fullWidth
								onChange={(items, advs) => {
									mapping.advertisers = items.map((nr) => parseInt(nr, 10));
									Object.assign(knownAdvertisers, _.keyBy(advs, 'advNr'));
									this.forceUpdate();
								}}
								type={type}
								createUsingDimObject={mappingDim}
								metric={metric}
								dimension="advNr"
								label={mappingDim.dimension}
								groupBy={mappingDim.dimension}
								textFieldLabel={`Add ${mappingDim.uiCName}(s)`}
								showMoreLabel={mappingDim.pluralName}
								checkForbidden={checkForbidden}
								noMapping
							/>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<Card>
						<CardContent>
							<Typography variant="h2">
								Map words / phrases
							</Typography>
							<FieldArray
								model={mapping}
								field={field}
								name="byWords"
								update={() => this.forceUpdate()}
								render={(fld, elm) => (
									<Grid container spacing={3} alignItems="center">
										<Grid item xs={6}>
											<TextField
												required
												label="Word / Phrase"
												minlen={WORD_MIN_LENGTH}
												{...fld('word')}
												fullWidth
											/>
										</Grid>
										<Grid item xs={4}>
											<Checkbox label="Exact match" {...fld('exactMatch')} />
										</Grid>
										<Grid item xs={2}>
											{(elm.word || '').length >= WORD_MIN_LENGTH && (
												<MatchReport
													metric={metric}
													type={type}
													dimType={mappingDim.name}
													mapping={{
														mappings: [{
															targetAdvNr: ADVERTISER_MAPPED_AWAY,
															advertisers: [],
															byWords: [elm],
														}],
														dimType: mappingDim.name,
													}}
												/>
											)}
										</Grid>
									</Grid>
								)}
							/>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		);
	}
}

SingleMapping.propTypes = {
	field: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	mapping: PropTypes.object.isRequired,
	metric: PropTypes.string.isRequired,
	knownAdvertisers: PropTypes.object.isRequired,
	mappingDim: PropTypes.object.isRequired,
	checkForbidden: PropTypes.func.isRequired,
};

SingleMapping.defaultProps = {
};

export default SingleMapping;
