import { AddCircleOutline } from '@mui/icons-material';
import {
	Box,
	Card,
	CardActionArea,
	Typography
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classes from '../../api/classes';
import { getAvailableReportTypes } from '../../providers/ReportsProvider/utils';
import { Carousel } from '../Carousel/Carousel';
import { ReportTemplateCard } from '../ReportTemplates/ReportTemplateCard';
import getReportPreviewImage from '../ReportsOverview/utils';
import UnstyledLink from '../UnstyledLink';
import { filterUserAvailableTemplates, reportUrlFromSettings } from '../ReportTemplates/utils';

const { ReportTemplate } = classes;

export function NewReportSelector({ onClick, showHidden }) {
	const [templates, setTemplates] = useState([]);

	useEffect(() => {
		(async () => {
			let allTemplates = await ReportTemplate.fnCache.listAvailable({ hidden: false, isReportTemplate: true });
			allTemplates = filterUserAvailableTemplates(allTemplates);
			allTemplates = allTemplates.sort((a, b) => a.settings.title.localeCompare(b.settings.title)); // Sort alphabetically for now
			setTemplates(allTemplates);
		})();
	}, []);

	const reportTypes = getAvailableReportTypes().filter((t) => !t.noSaveType);
	const templatesByType = _.groupBy(templates, (t) => t.settings.type);

	return (
		// TODO: Mobile responsive
		<div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
			{ reportTypes.map((type) => (
				<Carousel fadeDuration={0.1} key={type.type}>
					<div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'stretch', width: '100%', height: '100%', gap: 15 }}>
						{/* "Add" card */}
						<Box sx={{ flex: '0 0 260px', minHeight: '120px' }}>
							<Card
								variant="outlined"
								style={{
									boxSizing: 'border-box',
									height: '100%',
								}}
							>
								<CardActionArea sx={{ p: '13px', height: '100%' }}>
									<UnstyledLink
										sx={{ display: 'block', height: '100%' }}
										to={`${type.path}/add`}
										onClick={onClick}
									>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												height: '100%',
												flexDirection: 'column',
												gap: '10px',
											}}
										>
											<AddCircleOutline sx={{ color: 'info.main', fontSize: 55 }} />
											<Typography variant="h1" style={{ display: 'inline', lineHeight: 'normal' }}>{type.label}</Typography>
										</div>
									</UnstyledLink>
								</CardActionArea>
							</Card>
						</Box>

						{/* Vertical dotted separator to separate add from templates */}
						{ templatesByType[type.type]?.filter((t) => (!t.hidden || showHidden)).length > 0 && (
							<Box sx={{ flex: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<div style={{ width: 1, borderLeft: '2px dashed #E0E0E0', height: '90%' }} />
							</Box>
						) }

						{ templatesByType[type.type]?.filter((t) => (!t.hidden || showHidden)).map(({
							id,
							description,
							settings,
							hidden,
							userId,
						}) => (
							<Box sx={{ flex: '0 0 260px' }} key={id}>
								<ReportTemplateCard
									id={id}
									title={settings.title}
									description={description}
									isPublic={!userId}
									isHidden={hidden}
									// isAdminTemplate={isAdminTemplate}
									link={reportUrlFromSettings(settings)}
									image={getReportPreviewImage(
										settings.showPieChart,
										settings.showTable,
										settings.showChart,
										settings.groupBy,
									)}
									onClick={onClick}
								/>
							</Box>
						)) }
					</div>
				</Carousel>
			)) }
		</div>
	);
}

NewReportSelector.propTypes = {
	onClick: PropTypes.func,
	showHidden: PropTypes.bool,
};

NewReportSelector.defaultProps = {
	onClick: () => {},
	showHidden: false,
};
