import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import styles from './styles.css';
import SelectorDropdown from '../../components/SelectorDropdown';
import Dashboard from '../../containers/Dashboard';
import SystemData from '../../lib/systemData';
import { IfHasAudience } from '../../components/Wrappers';

const availableDashboardTypes = [
	{
		value: 'audience',
		name: 'Audience',
	},
	{
		value: 'programmatic',
		name: 'Programmatic',
	},
];

function AdminDashboard() {
	const [product, setProduct] = useState('programmatic');

	const { disableAudience } = SystemData.genericData;

	return (
		<div className={styles.pageContentWrapper}>
			<Container maxWidth="lg">
				<Grid container spacing={3}>
					<IfHasAudience>
						<Grid item xs={12}>
							<SelectorDropdown
								title="Show"
								items={availableDashboardTypes}
								selected={product}
								onChange={(_, __, selectedProduct) => {
									setProduct(selectedProduct);
								}}
								className={styles.dashboardSelectorContainer}
							/>
						</Grid>
					</IfHasAudience>
					<Dashboard type={disableAudience ? 'programmatic' : product} />
				</Grid>
			</Container>
		</div>
	);
}

export default AdminDashboard;
