/* eslint-disable react/prop-types */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
	availReportTypes,
	getAvailableReportTypes,
	byReportType,
	Products,
} from 'relevant-shared/reportData/reportType';
import { pubUserReportOptions } from 'relevant-shared/misc/misc';
import Form from '../../containers/Form';
import TextField from '../TextField';
import SiteSelect from '../SiteSelect';
import { ActionButton } from '../ActionButton/ActionButton';
import Base from '../../layouts/Base';
import JobButton from '../JobButton';
import Checkbox from '../Checkbox';
import {
	MiscTasks,
} from '../../api/relevant';
import { stores } from '../../stores';
import WidgetSelector from '../WidgetSelector';
import MultiSelect from '../MultiSelect';
import classes from '../../api/classes';
import SystemData from '../../lib/systemData';
import OperationWrapper from '../OperationWrapper';
import { filterUserAvailableTemplates } from '../ReportTemplates/utils';
import { ConfirmDialog } from '../ConfirmDialog';
import { Dialog } from '../Dialog';

const { ReportTemplate } = classes;

@Form
class UserForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			templates: [],
			availableTemplates: [],
		};
	}

	getUserGenericData() {
		const { publisher, isAdmin = false } = this.props;
		const { genericData } = SystemData;
		return {
			...genericData,
			isAdministrator: isAdmin,
			userReportOptions: pubUserReportOptions(genericData, publisher),
		};
	}

	filterTpls(tpls) {
		const { model, isAdmin = false } = this.props;
		return filterUserAvailableTemplates(tpls, {
			isAdmin,
			genericData: this.getUserGenericData(),
			user: model,
		});
	}

	async loadTemplates() {
		const { publisher, isAdmin } = this.props;
		const allTemplates = _.filter(await ReportTemplate.fnCache.listAvailable({
			isDashboardTemplate: true,
			isUserTemplate: !isAdmin,
		}), { [isAdmin ? 'isAdminTemplate' : 'isUserTemplate']: true });
		let availTypes = availReportTypes;
		if (!isAdmin && publisher) {
			availTypes = getAvailableReportTypes(this.getUserGenericData(), {
				programmatic: publisher.programmaticAccess,
				audience: publisher.audienceAccess,
			});
		}
		const availableTemplates = allTemplates.filter((t) => availTypes.some((x) => x.type === t.settings.type));

		this.setState({
			templates: this.filterTpls(availableTemplates.filter((t) => t.isDefaultDashboard)),
			availableTemplates,
		});
	}

	async addDashboardsForNewUser() {
		const { form } = this.props;
		const { templates } = this.state;
		const arr = this.filterTpls(templates).map((t, idx) => ({
			component: 'Report',
			componentProperties: t.settings,
			id: idx + 1,
		}));
		const dashboards = _.omit({
			..._.mapValues(Products, () => []),
			..._.groupBy(arr, (t) => byReportType[t.componentProperties.type]?.product),
		}, 'undefined');
		// We need to use the waitUpdate=true parameter here because the form is a 'draft' so the model-object
		// is updated using setState() - and we want to wait until that update is done.
		await form.setVals({ data: { dashboards } }, true);
	}

	renderDashboardSelect() {
		let { templates, availableTemplates } = this.state;
		templates = this.filterTpls(templates);
		availableTemplates = this.filterTpls(availableTemplates);
		const dashboards = templates.map((t) => ({ ...t, label: t.settings.title, value: t.id }));
		const availableDashboards = availableTemplates.map((t) => ({ ...t, label: t.settings.title, value: t.id }));
		return (
			<MultiSelect
				label="Default dashboards"
				FormControlProps={{
					name: 'defaultDashboard',
					fullWidth: true,
				}}
				options={availableDashboards}
				value={dashboards}
			>
				<WidgetSelector
					multiple
					availableWidgets={availableDashboards}
					selectedWidgets={dashboards}
					onSelect={(selected) => {
						this.setState({ templates: selected ?? [] });
					}}
				/>
			</MultiSelect>
		);
	}

	render() {
		const {
			hasValue, field, value, submit, model, form, isAdmin, publisher,
		} = this.props;

		const isNew = !hasValue('_id');
		const { identity } = stores;
		const { programmaticAccess } = publisher || {};
		const selfCanAccessAllPubs = identity.isAdministrator() || identity.hasAllPubAccess();
		const hasDashboardSelect = isNew && (isAdmin || programmaticAccess);
		return (
			<OperationWrapper fn={() => hasDashboardSelect && this.loadTemplates()} disableMode>
				<Paper>
					<Box padding={2}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="h2">
									{isNew ? 'Adding a new user' : `Editing: ${value('fullname')}`}
								</Typography>
							</Grid>
							<Grid item xs={isNew ? 6 : 12}>
								<TextField
									{...field('email')}
									label="E-mail"
									required
									email
									fullWidth
								/>
							</Grid>
							{hasDashboardSelect && (
								<Grid item xs={6}>
									{this.renderDashboardSelect()}
								</Grid>
							)}
							<Grid item xs={6}>
								<TextField
									{...field('firstname')}
									label="First name"
									required
									textonly
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									{...field('lastname')}
									label="Last name"
									required
									textonly
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								{isNew
									? (
										<Checkbox
											{...field('mailCredentials')}
											label="Mail login credentials"
										/>
									)
									:	(
										<JobButton
											label="Reset password and mail"
											fn={() => new Promise((resolve, reject) => {
												Base.renderGlobal((closeFn) => (
													<ConfirmDialog
														open
														text={(
															<span>
																Are you sure you want to reset and mail credentials to&nbsp;
																<b>{model.email}</b>
															</span>
														)}
														onAny={async (ok) => {
															closeFn();
															if (ok) {
																try {
																	await MiscTasks.call('sendPasswordSelectToken', { id: model.id, reset: true });
																	Base.renderGlobal((done) => (
																		<Dialog
																			open
																			status="success"
																			text="Pasword reset and mail sent"
																			onClose={done}
																		/>
																	));
																} catch (e) {
																	reject(e);
																}
															}
															resolve();
														}}
													/>
												));
											})}
											color="primary"
										/>
									)}
							</Grid>
							{!isAdmin && (
								<>
									<Grid item xs={12}>
										<Typography variant="h3">
											Permissions
										</Typography>
										<Checkbox
											{...field('permissions.canEditUsers')}
											label="Can edit users"
										/>
										<Checkbox
											{...field('permissions.hasAllPubAccess')}
											label="Has access to all publishers"
											disabled={!selfCanAccessAllPubs}
										/>
									</Grid>
									{!value('permissions.hasAllPubAccess') && (
										<Grid item xs={12}>
											<SiteSelect
												title="Additional reporting access to other publishers"
												selectorType="PopupSelector"
												selected={model.additionalPubAccess || []}
												onChange={(items) => form.setVals({ additionalPubAccess: items })}
											/>
										</Grid>
									)}
								</>
							)}
							<Grid item>
								<ActionButton
									label="Submit"
									color="primary"
									onClick={async () => {
										if (hasDashboardSelect) {
											await this.addDashboardsForNewUser();
										}
										submit();
									}}
								/>
							</Grid>
						</Grid>
					</Box>
				</Paper>
			</OperationWrapper>
		);
	}
}

UserForm.propTypes = {
	isAdmin: PropTypes.bool,
};

UserForm.defaultProps = {
	isAdmin: false,
};

export default UserForm;
