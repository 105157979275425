import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import PopupItemList from '../PopupItemList';

/**
* A reusable modal that displays a list of items that you can select.
*/
function PopupItemSelect({
	items, header, onChange, buttonText, getOptionSubText,
}) {
	const [popupIsOpen, setPopupIsOpen] = useState(false);
	const onOpenStateChange = useCallback((open) => {
		setPopupIsOpen(open);
	}, []);

	return (
		<div>
			<Button
				variant="outlined"
				color="primary"
				onClick={() => setPopupIsOpen(true)}
			>
				{ buttonText }
			</Button>
			{ popupIsOpen && (
				<PopupItemList
					items={items}
					header={header}
					onChange={onChange}
					onOpenStateChange={onOpenStateChange}
					getOptionSubText={getOptionSubText}
				/>
			)}
		</div>
	);
}

export default PopupItemSelect;

PopupItemSelect.propTypes = {
	items: PropTypes.objectOf(PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
		selected: PropTypes.bool,
	})).isRequired,
	buttonText: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	header: PropTypes.string.isRequired,
	getOptionSubText: PropTypes.func,
};

PopupItemSelect.defaultProps = {
	getOptionSubText: () => undefined,
};
