const { PREBID_CONFIGURATION_DIM } = require('../misc/sharedConstants');

const VALUE_REPORT_COMPATIBLE_DIMENSIONS = [
	'date',
	'publisherId',
	PREBID_CONFIGURATION_DIM,
	'siteId',
	'placementId',
];

const VALUE_METRIC_PREFIX = '__';
const VALUE_METRIC_CALC_PREFIX = '_opt_';
const VALUE_METRIC_COUNTER_PREFIX = '_optc_';

const VALUE_METRIC_ALL_PREFIXES = [
	VALUE_METRIC_PREFIX,
	VALUE_METRIC_CALC_PREFIX,
	VALUE_METRIC_COUNTER_PREFIX,
];

const getValueMetricPrefix = (name) => VALUE_METRIC_ALL_PREFIXES.find((s) => name.startsWith(s));

const unprefix = (name) => {
	const pfx = getValueMetricPrefix(name);
	return pfx ? name.slice(pfx.length) : name;
};

const isOptiMetric = (name) => !!getValueMetricPrefix(name);

const toOptimizeMetricName = (name) => {
	const raw = unprefix(name);
	return {
		name: `${VALUE_METRIC_PREFIX}${raw}`,
		calc: `${VALUE_METRIC_CALC_PREFIX}${raw}`,
		count: `${VALUE_METRIC_COUNTER_PREFIX}${raw}`,
	};
};

module.exports = {
	VALUE_REPORT_COMPATIBLE_DIMENSIONS,
	VALUE_METRIC_PREFIX,
	isOptiMetric,
	toOptimizeMetricName,
};
