const _ = require('lodash');
const EnvReportInterface = require('./envReportInterface');

const reportTypes = [];
const byReportType = {};
const availReportTypes = [];
const byAvailReportType = {};

const setArr = (dst, arr) => {
	dst.splice(0);
	dst.push(...arr);
};

const setObj = (dst, obj) => {
	Object.keys(dst).forEach((k) => {
		delete dst[k];
	});
	Object.assign(dst, obj);
};

const defaultSettings = () => {
	const genericData = EnvReportInterface.getGenericData?.();
	return genericData && {
		...genericData,
		isAdministrator: EnvReportInterface.isAdministrator(),
	};
};

const Products = {
	audience: {
		hasProduct: ({ disableAudience, isAdministrator } = defaultSettings()) => !disableAudience
			&& (isAdministrator || EnvReportInterface.isAudiencePublisher()),
	},
	programmatic: {
		hasProduct: ({ isAdministrator } = defaultSettings()) => isAdministrator
			|| EnvReportInterface.isProgrammaticPublisher(),
	},
};

class ReportType {
	constructor(obj, type) {
		Object.assign(this, {
			type,
			product: 'programmatic',
			reportLocation: `/reports/${type}`,
			genericReportLocation: `/generic-reports/${type}`,
			SHORT_DESCRIPTION: obj.DESCRIPTION,
		}, obj);
	}

	get customizer() {
		return this.allCustomizers?.createByType(this.type);
	}

	get label() {
		return this.DESCRIPTION;
	}

	get path() {
		return this.reportLocation;
	}

	static reportTypeClass = ReportType;

	static init(constants) {
		this.constants = constants;
		this.update();
		EnvReportInterface.onGenericDataUpdated(() => this.update());
	}

	static getAvailableReportTypes(settings, allowedProductMap) {
		const obj = settings || defaultSettings();
		if (!obj) {
			return [];
		}
		const allowed = allowedProductMap || _.pickBy(Products, (p) => p.hasProduct(obj));
		return reportTypes.filter(({ product, availableCheck }) => allowed[product] && availableCheck(obj));
	}

	static update() {
		const { constants } = this;
		const Cls = this.reportTypeClass;
		let arr = Object.entries(constants)
			.filter(([, v]) => v?.SUM_DESCRIPTIONS && v?.GROUP_BY_OPTIONS)
			.map(([type, obj]) => (
				obj instanceof Cls ? obj : new Cls(obj, type)
			));
		arr = _.sortBy(arr, (elm) => { // sort primarily by "UI_ORDER" and then keep order of the rest
			const idx = constants.UI_ORDER.indexOf(elm.type);
			return idx >= 0 ? idx : (arr.indexOf(elm) + 1) * 1000;
		});
		setArr(reportTypes, arr);
		setObj(byReportType, _.keyBy(arr, 'type'));
		Object.assign(constants, byReportType);
		setArr(availReportTypes, this.getAvailableReportTypes());
		setObj(byAvailReportType, _.keyBy(availReportTypes, 'type'));
	}

	static setReportTypeClass(cls) {
		this.reportTypeClass = cls;
		if (this.constants) {
			this.update(this.constants);
		}
	}
}

Object.assign(ReportType, {
	reportTypes,
	byReportType,
	availReportTypes,
	byAvailReportType,
	Products,
});

module.exports = ReportType;
