import React from 'react';
import _ from 'lodash';
import LogCustomizer from 'relevant-shared/reportData/customizers/logCustomizer';
import CheckboxGroup from '../../CheckboxGroup';
import DynamicSearchFilter from '../../DynamicSearchFilter';
import { LogDataIdMapping } from '../../../api/relevant';

class FrontendLogCustomizer extends LogCustomizer {
	getCustomFilters({ selectOptions }) {
		const { logTypeSettings, allLogFlds } = this.reportData.getConstants();
		return allLogFlds.map(({
			desc, field, type, nameMap,
		}) => {
			if (nameMap) {
				return {
					label: desc,
					name: field,
					rendered: (
						<CheckboxGroup
							items={_.map(nameMap, (v, k) => ({ id: k.toString(), text: v }))}
							{...selectOptions(field)}
						/>
					),
				};
			} if (type === 'id') {
				return {
					label: desc,
					name: field,
					rendered: (
						<DynamicSearchFilter
							{...selectOptions(field)}
							groupBy={field}
							metric="auctions"
							dimension={field}
							type={this.reportData.settings.type}
							label={field}
							objectIdFld="localId"
							textFieldLabel="Add"
							showMoreLabel="objects"
							overrideHistoryDaysBack={30}
							customizer={this}
							excludeIdCb={() => false}
							serverCall={async (selected) => {
								const resp = await LogDataIdMapping.call('getValues', {
									logImporterId: logTypeSettings.logImporterId,
									fieldName: field,
									idArr: selected,
								});
								return _.map(resp, (name, localId) => ({
									name: name === null ? `[No ${desc}]` : name,
									localId,
								}));
							}}
							fullWidth
						/>
					),
				};
			}
			return null;
		}).filter((o) => o);
	}
}

export default FrontendLogCustomizer;
