import React, { Fragment } from 'react';
import { CircularProgress } from '@mui/material';

export const loadingIcon = (
	<CircularProgress
		size="1.5em"
		color="inherit"
	/>
);

export const AlertProps = {
	style: { display: 'flex', alignItems: 'center' },
	variant: 'filled',
};

export function getNotificationData(builds) {
	const buildDatas = Object.values(builds);
	const buildStatuses = buildDatas.map(({ status }) => status);
	let message = '';
	let status = buildStatuses.some((s) => s === 'RUNNING') ? 'info' : undefined;
	if (status) {
		message = 'Building Prebid.js...';
	} else {
		status = buildStatuses.some((s) => s === 'FAILED') ? 'error' : undefined;
		if (status) {
			const failedBuilds = buildDatas
				.filter(({ status: s }) => s === 'FAILED')
				.map(({ configName }) => `"${configName}"`);
			const multipleFailed = failedBuilds.length > 1;
			if (multipleFailed) {
				message = (
					<>
						Failed building Prebid.js using configs
						<strong>{failedBuilds.join(', ')}</strong>
						. Please try different builds.
					</>
				);
			} else {
				message = (
					<>
						Failed building Prebid.js using config
						<strong>{failedBuilds[0]}</strong>
						. Please try a different build.
					</>
				);
			}
		} else {
			status = (buildStatuses.length > 0
				&& buildStatuses.every((s) => s === 'SUCCESS')) ? 'success' : undefined;
			if (status) {
				message = 'Successfully built Prebid.js.';
			}
		}
	}

	const icon = status === 'info' ? loadingIcon : null;
	const toastData = {
		status,
		open: Boolean(status),
		message,
		icon,
		AlertProps,
	};
	return { toastData, stop: status === 'success' || status === 'error' };
}

/**
 * Get builds that hasn't been dismissed by user
 */
export function getNonDismissedBuilds(builds, userEmail) {
	const buildEntries = Object.entries(builds);
	const res = {};
	buildEntries.forEach(([key, value]) => {
		if (!value.dismissedBy || !value.dismissedBy.includes(userEmail)) {
			res[key] = value;
		}
	});
	return res;
}

export function hasOngoingBuilds(builds) {
	return Object.values(builds).some((build) => build.status === 'RUNNING');
}
