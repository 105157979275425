const _ = require('lodash');
const PrebidConfigOwnerNode = require('./prebidConfigOwnerNode');
const SspPlacementNode = require('./sspPlacementNode');

/**
 * @class PrebidConfigurationNode presents a PrebidConfiguration and all its possible children.
 */
class PrebidConfigNode extends PrebidConfigOwnerNode {
	constructor(cfg, parentNode) {
		super(cfg, parentNode, 'PrebidConfigNode');
	}

	get siteNode() {
		return this.getParentOfType('SiteNode');
	}

	get isChildConfig() {
		return this.parentNode.type === 'PrebidConfigNode';
	}

	get isEnabled() {
		return !this.isChildConfig || !!this.obj.enabled;
	}

	get mainConfigNode() {
		return this.isChildConfig ? this.parentNode : this;
	}

	removeNode() {
		super.removeNode();
		const { id } = this;
		_.remove(this.publisherNode.obj.bidParams, (p) => p.pbCfgId === id || p.unitId === id);
	}
}

PrebidConfigNode.parentPath = 'prebidConfigs';

module.exports = PrebidConfigNode;
