import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';

function TriggerFilter({ field }) {
	return (
		<>
			<Checkbox
				label="Unacknowledged"
				{...field('showUnacknowledged')}
			/>
			<Checkbox
				label="Acknowledged"
				{...field('showAcknowledged')}
			/>
			<Checkbox
				label="Recently recovered"
				{...field('showRecovered')}
			/>
		</>
	);
}

TriggerFilter.propTypes = {
	field: PropTypes.func.isRequired,
};

export default TriggerFilter;
