class TriggerReportFilter {
	constructor({ calculator }) {
		Object.assign(this, {
			calculator,
			reportData: calculator.reportData,
		});
		const { triggerFilters, sums, useCompareTo } = calculator.settings;
		this.validFilters = (triggerFilters || []).filter(({ sum, isComp }) => (
			sums.includes(sum) && !(isComp && !useCompareTo)
		));
	}

	static disqualifiedByCompCond(val, filter) {
		const compValue = parseFloat(filter.compValue);
		if (Number.isNaN(compValue)) {
			return false; // not set
		}
		const matches = filter.compCond === 'lt' ? (val < compValue) : (val >= compValue);
		return !matches;
	}

	isFailure(dataObj, returnDesc) {
		if (!this.validFilters.length) {
			return returnDesc ? null : false;
		}
		let errorDescArr;
		for (const filter of this.validFilters) {
			const { sum, isComp, cond } = filter;
			const value = parseFloat(filter.value) || 0;
			let val = this.reportData.calculate(dataObj, sum, isComp);
			if (isComp) { // check how many % current value is compared to compared value
				if (TriggerReportFilter.disqualifiedByCompCond(val, filter)) {
					return false;
				}
				const current = this.reportData.calculate(dataObj, sum, false);
				if (!val) {
					if (!current) {
						val = 100; // 0 both then and now
					} else {
						val = 100000 * (current > 0 ? 1 : -1);
					}
				} else {
					val = (current / val) * 100;
				}
			}
			const isError = cond === 'lt' ? (val < value) : (val >= value);
			if (!isError) {
				return false;
			}
			if (returnDesc) {
				errorDescArr = errorDescArr || [];
				let sumName = this.reportData.getConstants().SUM_DESCRIPTIONS[sum] || 'Unknown';
				let valStr;
				if (isComp) {
					const diffPerc = val - 100;
					valStr = `${diffPerc < 0 ? '-' : ''}${diffPerc.toFixed(1)}%`;
				} else {
					valStr = val.toFixed(2);
				}
				if (isComp) {
					sumName = `${sumName}*`;
				}
				errorDescArr.push(`${sumName}: ${valStr}`);
			}
		}
		return errorDescArr ? errorDescArr.join(', ') : true;
	}
}

module.exports = TriggerReportFilter;
