import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import styles from './styles.css';
import DspSettings from '../../containers/DspSettings';

class GlobalSettingsAudience extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className={styles.pageContentWrapper}>
				<Container maxWidth="xl">
					<DspSettings history={this.props.history} match={this.props.match} />
				</Container>
			</div>
		);
	}
}

GlobalSettingsAudience.propTypes = {
	history: PropTypes.object.isRequired,
};

GlobalSettingsAudience.defaultProps = {

};

export default GlobalSettingsAudience;
