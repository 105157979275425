import _ from 'lodash';

class FormCollection {
	constructor() {
		this.forms = [];
	}

	add(form) {
		if (!_.includes(this.forms, form)) {
			this.forms.push(form);
		}
	}

	remove(form) {
		_.pull(this.forms, form);
	}

	checkErrors({ filter } = {}) {
		let hasErr = false;
		this.forms.forEach((form) => {
			if ((!filter || filter(form)) && form.hasErrors()) {
				hasErr = true;
			}
		});
		return hasErr;
	}
}

export default FormCollection;
