import React from 'react';
import _ from 'lodash';

class FormContext extends React.Component {
	constructor(props) {
		super(props);
		this.id = Math.random().toString();
	}

	componentDidMount() {
		FormContext.contexts.push(this);
	}

	componentWillUnmount() {
		_.pull(FormContext.contexts, this);
		if (this.props.form && this.hasPendingUpdates) {
			this.props.form.update();
		}
	}

	update() {
		this.hasPendingUpdates = true;
		this.forceUpdate();
	}

	render() {
		return (
			<div
				data-form-context-id={this.id}
			>
				{this.props.content()}
			</div>
		);
	}
}

FormContext.contexts = [];

export default FormContext;
