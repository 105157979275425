const _ = require('lodash');

// As we're not displaying any "hint" for passwords in frontend anymore, increase security a bit by faking length
const FAKE_PASSWD_LEN = 10;

const ALL_FIELDS = {
	username: { type: String, required: true },
	verificationEmail: { type: String, required: false },
	userId: { type: String, required: true },
	publisherUserId: { type: String, required: true },
	password: { type: String, required: true, secret: true },
	apiKey: { type: String, required: true, secret: true },
	consumerKey: { type: String, required: true, secret: true },
	consumerSecret: { type: String, required: true, secret: true },
	// nextConsumerKey and nextConsumerKey are used for AmazonSsp, to avoid API rejections every 3 months.
	nextConsumerKey: { type: String, secret: true },
	nextConsumerSecret: { type: String, secret: true },
	serviceAccountJson: { type: String, required: true, secret: true },
	realm: { type: String, required: true },
	accessToken: { type: String, required: true, secret: true },
	refreshToken: { type: String, required: true, secret: true },
	accountId: { type: String, required: true },
};

// 'dst' should be a plain object (result of Credentials.toObject()) *not* the BaseModel derived object
const setDummyCredentialsForHbm = (dst) => {
	if (!dst) {
		return;
	}
	_.forOwn(ALL_FIELDS, ({ secret }, key) => {
		if (secret) {
			const fld = `${key}Len`;
			if (fld in dst) {
				dst[fld] = FAKE_PASSWD_LEN;
			}
		}
	});
	/** Set non-secret required fields that we *think* won't be used for HBM (but only for API imports) */
	_.defaults(dst, {
		username: 'dummy@mail.com',
		userId: 'dummy',
		publisherUserId: 'dummy',
	});
};

module.exports = {
	FAKE_PASSWD_LEN,
	ALL_FIELDS,
	setDummyCredentialsForHbm,
};
