import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import AppsIcon from '@mui/icons-material/Apps';
import { useHistory } from 'react-router-dom';
import Divider from '../MenuDivider';
import TopBarMenu from '../TopBarMenu';

function AppSelector({ onProductChange }) {
	const history = useHistory();
	const [open, setOpen] = useState(false);

	const onChange = (productType) => {
		if (productType === 'audience') {
			history.push('/audience/dashboard');
		} else if (productType === 'yield') {
			history.push('/dashboard');
		}
		setOpen(false);
		onProductChange(productType);
	};
	return (
		<TopBarMenu
			open={open}
			onClick={() => setOpen(!open)}
			icon={<AppsIcon />}
			title="Select product"
		>
			<MenuItem
				onClick={() => onChange('yield')}
			>
				Relevant Yield
			</MenuItem>
			<Divider />
			<MenuItem
				onClick={() => onChange('audience')}
			>
				Relevant Audience
			</MenuItem>
		</TopBarMenu>
	);
}

AppSelector.propTypes = {
	onProductChange: PropTypes.func.isRequired,
};

export default AppSelector;
