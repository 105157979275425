import {
	observable, observe, computed, toJS, when,
} from 'mobx';

class BaseStore {
	@observable _data = null;

	@observable _status;

	constructor(data = null) {
		this.setInternal(data);
		this._status = data ? 'ok' : 'uninitialized';

		if (!data) {
			this.initialize();
		}
	}

	@computed get value() {
		return this._data;
	}

	@computed get status() {
		return toJS(this._status);
	}

	set(payload) {
		this.setInternal(payload);
	}

	setInternal(payload) {
		this._data = payload;
		delete this._jsCache;
		if (payload) {
			observe(this._data, () => {
				delete this._jsCache;
			});
		}
	}

	js() {
		if (!this._jsCache) {
			this._jsCache = toJS(this._data);
		}
		return this._jsCache;
	}

	uninitialized() {
		this._status = 'uninitialized';
	}

	loading() {
		this._status = 'loading';
	}

	ok() {
		this._status = 'ok';
	}

	isReady() {
		return this._status === 'ok';
	}

	whenReady(fx) {
		when(
			() => this.isReady(),
			fx,
		);
	}

	initialize() {
	}
}

export default BaseStore;
