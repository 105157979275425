import _ from 'lodash';
import moment from 'moment';

const rumorBase = (name) => _.mapValues(
	_.keyBy(['info', 'warn', 'error', 'debug']),
	(fn) => (str) => console[fn](`${name} ${moment().format('YYYY-MM-DD HH:mm:ss')} ${str}`),
);

const DUMMY_PROFILER = { completed: () => {} };
const MAX_CSV_HEADER_SEARCH_ROWS = 1000;

let objectIdLowPart = Math.floor(Math.random() * (256 ** 3));

const lastCommaToPeriod = (val) => {
	const commaIdx = val.lastIndexOf(',');
	const periodIdx = val.lastIndexOf('.');
	if (commaIdx > periodIdx) {
		return `${val.slice(0, commaIdx)}.${val.slice(commaIdx + 1)}`;
	}
	return val;
};

class MiscUtils {
	static profiler(enabled, name) {
		if (!enabled) {
			return DUMMY_PROFILER;
		}
		let time = new Date();
		const logger = rumorBase(`profiler${name ? `[${name}]` : ''}`);
		return {
			completed: (str) => {
				const now = new Date();
				logger.info(`(${now - time} ms) - ${str}`);
				time = now;
			},
		};
	}

	static async waitTimeout(promise, ms)	{
		let timedOut;
		let timeoutId;
		let except;
		let res;
		const timeout = new Promise((resolve) => {
			timeoutId = setTimeout(() => {
				timedOut = true;
				resolve();
			}, ms);
		});
		const waitPromise = new Promise((resolve) => {
			promise.then(() => resolve()).catch((e) => {
				except = e;
				resolve();
			});
		});
		await Promise.race([waitPromise, timeout]);
		clearTimeout(timeoutId);
		if (except) {
			throw except;
		} else if (timedOut) {
			throw Error(`Timeout after ${ms} ms`);
		}
		return res;
	}

	/** Converts all environment variables starting with 'pfx' and construct an object of that */
	static configFromEnv(pfx, env = process.env)	{
		const res = {};
		for (const [k, v] of Object.entries(env)) {
			if (k.startsWith(pfx)) {
				res[k.slice(pfx.length)] = v;
			}
		}
		return res;
	}

	static alphaSorted(arr, sortKey) {
		let fn;
		if (sortKey) {
			fn = _.isFunction(sortKey) ? sortKey : (v) => v[sortKey];
		} else {
			fn = (v) => v;
		}
		const cmp = (a, b) => fn(a).toLowerCase().localeCompare(fn(b).toLowerCase());
		return arr.slice().sort(cmp);
	}

	static hasUTF16Marker(str) {
		return str[0] === '\ufffd' && str[1] === '\ufffd';
	}

	static fromUTF16(str) {
		if (MiscUtils.hasUTF16Marker(str)) {
			str = str.substring(2);
		}
		const chars = [];
		for (let i = 0; i < str.length; i += 2) {
			chars.push(String.fromCharCode(str.charCodeAt(i) + (str.charCodeAt(i + 1) * 256)));
		}
		return chars.join('');
	}

	static loadCsv(txt, CSV_COLS, POSSIBLE_CSV_DELIMITERS = [',', '\t', ';']) {
		if (txt[0] === '\ufeff') { // BOM character
			txt = txt.substring(1);
		} else if (MiscUtils.hasUTF16Marker(txt)) {
			txt = MiscUtils.fromUTF16(txt);
		}
		const splitText = (rowStr, delim) => {
			const res = [];
			let end;
			for (let str = `${rowStr}${delim}`; str.length > 0; str = str.slice(end + 1)) {
				if (str[0] === '"') {
					for (let i = 1; ; i += 1) {
						if (i === str.length - 1) {
							throw new Error('Unterminated double-quote');
						}
						if (str[i] === '"') {
							if (str[i + 1] === '"') {
								i += 1;
							} else if (str[i + 1] !== delim) {
								throw new Error('Expected end of cell after double-quote mark');
							} else {
								end = i + 1;
								break;
							}
						}
					}
					res.push(str.substr(1, end - 2).replace(/""/g, '"'));
				} else {
					end = str.indexOf(delim);
					res.push(str.substr(0, end));
				}
			}
			return res;
		};

		const splitLines = (fullStr) => {
			let str = fullStr.replace(/\r\n/g, '\n');
			if (!str.length) {
				return [];
			}
			if (str[str.length - 1] !== '\n') {
				str += '\n';
			}
			const ofs = [];
			let inQuote = false;
			for (let i = 0; i < str.length; i++) {
				if (str[i] === '"') {
					if (inQuote) {
						if (str[i + 1] === '"') {
							i++; // jump one extra charactar as it was an encoded double-quote
						} else {
							inQuote = false;
						}
					} else {
						inQuote = true;
					}
				}				else if (str[i] === '\n' && !inQuote) {
					ofs.push(i);
				}
			}
			const res = [];
			for (let i = 0; i < ofs.length; i++) {
				const start = i ? ofs[i - 1] + 1 : 0;
				res.push(str.substring(start, ofs[i]));
			}
			return res;
		};

		const lines = splitLines(txt).filter((l) => l.length);
		if (lines.length < 2) {
			return [];
		}

		let separator;
		let colMap;
		let missingCols = [];
		let neededCols;
		let headerIdx;
		const csvColFn = _.isFunction(CSV_COLS) ? CSV_COLS : null;
		/** Find header, as it might in some cases not be the first line */
		let success = false;
		let hasFoundFirstHeaderParts = false;
		for (headerIdx = 0; headerIdx < Math.min(lines.length, MAX_CSV_HEADER_SEARCH_ROWS); headerIdx++) {
			const header = lines[headerIdx];
			separator = POSSIBLE_CSV_DELIMITERS[0];
			let headerParts = [];
			POSSIBLE_CSV_DELIMITERS.forEach((sep) => {
				try {
					const candidateHeaderParts = splitText(header, sep);
					if (candidateHeaderParts.length > headerParts.length) {
						separator = sep;
						headerParts = candidateHeaderParts;
					}
				} catch (e) {}
			});
			if (!headerParts.length) {
				return;
			}
			colMap = {};
			neededCols = 0;
			if (csvColFn) {
				CSV_COLS = csvColFn(headerParts);
			}
			let failed = false;
			_.forOwn(CSV_COLS, (v, k) => {
				const name = v.name || v;
				const idx = headerParts.indexOf(name);
				if (idx < 0) {
					failed = true;
					if (!hasFoundFirstHeaderParts) {
						missingCols.push(name);
					}
				} else {
					colMap[k] = idx;
					neededCols = Math.max(neededCols, idx + 1);
				}
			});
			hasFoundFirstHeaderParts = true;
			if (!failed) {
				missingCols = [];
				success = true;
				break;
			}
		}

		if (!success) {
			throw new Error(`Failed finding .csv header, missing column(s): "${missingCols.join(', ')}"`);
		}
		const resArray = [];
		for (let i = headerIdx + 1; i < lines.length; i += 1) {
			const obj = {};
			try {
				const cells = splitText(lines[i], separator);
				if (cells.length < neededCols) {
					throw new Error('Too few columns');
				}
				_.forOwn(CSV_COLS, (v, k) => {
					let val = cells[colMap[k]];
					if (v.valueRequired && !val.trim()) {
						throw Error(`Missing value for "${v.name || v}"`);
					}
					if (v.type === Number) {
						if (v.lastCommaIsPeriod) {
							val = lastCommaToPeriod(val);
						}
						val = !val ? 0 : parseFloat(val.replace(/,/g, ''));
						if (isNaN(val)) {
							throw new Error(`Invalid number value for: "${v.name || v}"`);
						}
					} else if (v.type === Date) {
						val = v.dateFormat ? moment.utc(val, v.dateFormat).toDate() : new Date(val);
						if (isNaN(val.getTime())) {
							throw new Error(`Invalid date value for: "${v.name || v}"`);
						}
					}
					obj[k] = val;
				});
				resArray.push(obj);
			} catch (e) {
				if (i < lines.length - 1) { // Allow error on last line as that might be summary
					throw new Error(`Error on row ${i + 1}: ${e}`);
				}
			}
		}
		return resArray;
	}

	static rearrangeObj(obj, order) {
		const res = {};
		order.forEach((key) => {
			if (key in obj) {
				res[key] = obj[key];
			}
		});
		_.forOwn(obj, (val, key) => {
			if (!(key in res)) {
				res[key] = val;
			}
		});
		return res;
	}

	static errorMsg(e) {
		const errOf = (obj) => (obj ? (obj.message || obj.error || obj.errmsg) : null);
		const toErr = (obj) => (obj ? (_.isString(obj) ? obj : JSON.stringify(obj)) : null);
		return (errOf(e.error) || errOf(e) || toErr(e.error) || toErr(e) || 'Error').toString();
	}

	static objectIdStr() {
		const toHex = (val, bytes) => {
			const chars = bytes * 2;
			const res = Array(chars);
			for (let i = 0; i < chars; i += 1) {
				const hex = val & 15;
				res[(chars - i) - 1] = hex + (hex < 10 ? 48 : 87);
				val = Math.floor(val / 16);
			}
			return String.fromCharCode(...res);
		};
		objectIdLowPart = (objectIdLowPart + 1) % (256 ** 3);
		return toHex(Math.floor(new Date().getTime() / 1000), 4)
			+ toHex(Math.floor(Math.random() * (256 ** 5)), 5)
			+ toHex(objectIdLowPart, 3);
	}

	/** Simple list of callback-functions */
	static listeners() {
		const fns = [];
		return {
			add: (fn, delayed) => fns.push({ fn, delayed }),
			remove: (fn) => _.remove(fns, (e) => e.fn === fn),
			notify: (...args) => fns.forEach(({ fn, delayed }) => (delayed ? setTimeout(() => fn(...args)) : fn(...args))),
		};
	}
}

export default MiscUtils;
