import _ from 'lodash';
import React from 'react';
import Base from '../../layouts/Base';
import CountrySelect from '../CountrySelect';
import TextField from '../TextField';
import { PopupForm } from '../Wrappers';
import { ConfirmDialog } from '../ConfirmDialog';

export const filterEnabledForCfg = (cfg, onlyEnabled) => (
	(cfg.country?.length && (!onlyEnabled || (cfg.enabled && parseFloat(cfg.percentage))))
	|| cfg.prebidConfigs?.some((c) => filterEnabledForCfg(c, onlyEnabled))
);

export const percentUsedByChildren = (parent, exceptPbCfg) => _.sumBy(parent.prebidConfigs || [], (item) => {
	if (!item.enabled || filterEnabledForCfg(item)) {
		return 0;
	}
	if (exceptPbCfg && exceptPbCfg.id === item.id) {
		return 0;
	}
	return parseFloat(item.percentage) || 0;
});

export const percentageTextField = ({ parent, child, fld }) => (
	<TextField
		label="Percentage"
		disabled={!child.enabled}
		{...fld('percentage').validation({
			required: true,
			float: true,
			...(child.enabled && {
				between: {
					low: 0,
					high: Math.max(100 - (filterEnabledForCfg(child) ? 0 : percentUsedByChildren(parent, child)), 0),
				},
			}),
		})}
	/>
);

export const countryFilter = ({ child, form: mainForm }) => (
	<PopupForm
		title="Countries"
		size="sm"
		selected={child.country}
		getModel={() => _.pick(child, 'country')}
		content={({ model, form }) => (
			<>
				<CountrySelect
					selected={model.country}
					onChange={(items) => form.update(() => {
						model.country = items;
					})}
					includeTree
				/>
			</>
		)}
		onApplyChanges={async ({ model }) => {
			const newState = { ...model };
			if (!child.country.length && model.country.length && child.percentage === 0) {
				const setPerc = await Base.renderGlobal((closeFn) => (
					<ConfirmDialog
						open
						title="Set traffic to 100%?"
						text={(
							<span>
								You have now enabled country targeting but the percentage of matching
								traffic is still set to <b>0%</b>.
								Do you want to <i>change</i> the <i>percentage</i> of traffic to <b>100%</b>?
							</span>
						)}
						onAny={closeFn}
					/>
				));
				if (setPerc) {
					newState.percentage = 100;
				}
			}
			mainForm.update(() => Object.assign(child, newState));
		}}
	/>
);
