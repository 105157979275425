import { getChartGroupBy, isLineChart } from '../Report/utils';
import BarchartImage from '../../assets/barchart.png';
import LineChartImage from '../../assets/linechart.png';
import PieChartImage from '../../assets/piechart.png';
import TableImage from '../../assets/table.png';

export default function getReportPreviewImage(
	showPieChart,
	showTable,
	showChart,
	groupBy,
) {
	const chartType = groupBy && isLineChart(getChartGroupBy(groupBy)) ? 'line' : 'bar';
	// Pie chart gets priority
	if (showPieChart) {
		return PieChartImage;
	} if (showChart) { // Chart gets 2nd priority
		if (chartType === 'line') {
			return LineChartImage;
		}
		return BarchartImage;
	} if (showTable) { // Table gets 3rd priority
		return TableImage;
	}
	// Barchart as default
	return BarchartImage;
}
