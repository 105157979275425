import FrontendStorage from './frontendStorage';

export default {
	get() {
		return FrontendStorage.getItem('AuthToken');
	},

	set(token) {
		FrontendStorage.setItem('AuthToken', token);
	},

	clear() {
		FrontendStorage.removeItem('AuthToken');
	},

	syncToCookie() {
		const { hostname } = location;
		const parts = hostname.split('.');
		let domain;
		if (parts.length < 2) {
			domain = hostname;
		} else {
			domain = `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
		}
		const token = this.get() || '';

		// avoid too long cookies for uses still having user data in their AuthToken since before this update
		// simply let them login again
		if (token.length > 4050) {
			this.clear();
			location.reload();
		}
		document.cookie = `Authorization=${token};path=/;domain=${domain}`;
	},
};
