import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MuiDialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import ReportData from 'relevant-shared/reportData/reportData';
import Constants from 'relevant-shared/reportData/constants';
import { capitalize, percentChange } from 'relevant-shared/misc/misc';
import * as DynamicDateRanges from 'relevant-shared/reportData/dynamicDateRanges';
import Granularity from 'relevant-shared/reportData/granularity';
import ReportCalculator from 'relevant-shared/reportData/reportCalculator';
import AllCustomizers from 'relevant-shared/reportData/customizers/allCustomizers';
import { getValidStartEnd } from 'relevant-shared/reportData/utils';
import AllMappingDimensions from 'relevant-shared/mappingDimensions/allMappingDimension';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import metricSettings from 'relevant-shared/reportData/metricSettings';
import OperationWrapper from '../OperationWrapper';
import MiscUtils from '../../lib/miscUtils';
import DateUtils from '../../lib/dateUtils';
import BrowserUtils from '../../lib/browserUtils';
import { fmtNum } from '../../lib/numberUtils';
import Select from '../Select';
import TextField from '../TextField';
import { stores } from '../../stores';
import styles from './styles.css';
import reportBlockingReason from './reportBlockingReason';
import ReportAsUrl from './reportAsUrl';
import ReportTable from './reportTable';
import { dbEncodedTableState, dbDecodedTableState } from './reportTableUtils';
import ChartsWrapper from './chartsWrapper';
import ReportSettings from './reportSettings';
import Disabler from '../Disabler';
import Base from '../../layouts/Base';
import SystemData from '../../lib/systemData';
import { DynamicDateRangeSelector } from './dynamicDateRangeSelector';
import PieChartNotSupportedAlert from './PieChartNotSupportedAlert';
import {
	CompareToSwitch,
	ForecastToggle,
	ReportTypeSelector,
	StartEndPicker,
	getUserGroupByOptions,
	isForecastAvailable,
} from './utils';
import TimezoneSelect from '../TimezoneSelect';
import ChartSettings from './ChartSettings';
import classes from '../../api/classes';
import { Dialog } from '../Dialog';
import { ConfirmDialog } from '../ConfirmDialog';

const { Event } = classes;

class Report extends React.Component {
	constructor(props) {
		super(props);
		Object.assign(this, Constants[props.type]);
		this.state = { groupByOptions: this.GROUP_BY_OPTIONS, columns: props.chartSettings?.maxChartsPerRow || 1 };
		Object.assign(this.state, {
			...this.getInitState(props),
			...getValidStartEnd(props, new Date()),
			events: null,
		});
		if (!this.state.sums) {
			this.state.sums = this.getInitSums();
		}
		if (this.state.chartSettings?.chartType === 'default') {
			this.state.chartSettings.chartType = 'auto'; // Fix for some old saved templates/dashboards
		}
		this.chartWrapperCalculator = _.memoize((calculator) => calculator.rearrangedForChartWrapper());
	}

	componentDidMount() {
		this.updateDimensions();
		window.addEventListener('resize', this.updateDimensions);
	}

	shouldComponentUpdate(nextProps, nextState) {
		const stateHasChanged = !_.isEqual(
			_.omit(this.state, 'tableState'),
			_.omit(nextState, 'tableState'),
		);
		const propsHaveChanged = !_.isEqual(this.props, nextProps);
		return stateHasChanged || propsHaveChanged;
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	getInitState(props) {
		const state = _.pick(props, Object.keys(Report.propTypes));
		// May be undefined for Xandr log reports
		if (!SystemData.genericData.userReportOptions[props.type]) {
			return state;
		}
		const { USER_SUM_OPTIONS } = SystemData.genericData.userReportOptions[props.type];
		/* The database may sometimes (sadly) be in a bad state, so check if sums/groupBy contains dimensions that
		/ are not actually allowed, i.e is not included in USER_SUM_OPTIONS/USER_GROUP_BY_OPTIONS, and filter those out.
		*/
		if (!stores.identity.isAdministrator()) {
			if (state.sums) {
				state.sums = state.sums.filter((dimension) => USER_SUM_OPTIONS.includes(dimension));
				if (state.sums.length === 0) {
					state.sums = [USER_SUM_OPTIONS[0]];
				}
			}
			const extraAllow = (dim) => this.getCustomizer().allowAdditionalUserGroupByOption(dim);
			// If we've filtered out everything/is undefined, set to first available option.
			const userGroupByOptions = this.getUserGroupByOptions();
			if (state.groupBy) {
				state.groupBy = state.groupBy.filter((dimension) => (
					userGroupByOptions.includes(dimension) || extraAllow(dimension)
				));
				// If we've filtered out everything/is undefined, set to first available option.
				if (state.groupBy.length === 0) {
					state.groupBy = [userGroupByOptions[0]];
				}
			}

			if (state.whereIn) {
				const keys = Object.keys(state.whereIn);
				keys.forEach((key) => {
					// Special case: normal users's shouldn't be allowed to group by user (sales rep.)
					// But it should still be possible to filter by own advertisers (userNr: [my-id])
					if (!userGroupByOptions.includes(key) && !extraAllow(key) && key !== 'userNr') {
						delete state.whereIn[key];
					}
				});
			}
		}
		return state;
	}

	updateDimensions = (props) => {
		const { maxChartsPerRow } = props || {};
		const width = this.node?.clientWidth || 1;
		const { chartSettings } = this.state;
		const columns = Constants.MAX_COLUMNS(maxChartsPerRow || chartSettings?.maxChartsPerRow || 1, width);
		if (this.state.columns !== columns) {
			this.updateState({ columns });
		}
	};

	getCustomizer() {
		const { reportData } = this.state;
		return reportData ? reportData.customizer : AllCustomizers.createByType(this.props.type, null);
	}

	getInitSums() {
		return this.getCustomizer().getInitSums({ isAdministrator: stores.identity.isAdministrator() });
	}

	getCurrentProperties(userAdjusted) {
		const res = _.omit(_.clone(_.pickBy(this.state, (v, k) => Report.propTypes[k])), [
			'settingsOpen', 'trigger', 'header', 'chartSettingsOpen', 'isActualReport', 'warnOldData',
		]);
		res.tableState = dbEncodedTableState(res.tableState);
		if (userAdjusted
			&& !stores.identity.isAdministrator()
			&& !stores.identity.hasAllPubAccess()
			&& !((res.whereIn || {}).publisherId || []).length
		) {
			res.whereIn = _.cloneDeep(res.whereIn || {});
			res.whereIn.publisherId = stores.identity.allPubAccess();
		}
		res.filterSelectionType = _.pickBy(res.filterSelectionType, (v) => v && v !== 'INCLUSIVE');
		return res;
	}

	getGroupByOption() {
		return this.state.groupByOptions || this.GROUP_BY_OPTIONS;
	}

	getGranularityOptions() {
		return _.pick(
			Granularity.getOptions(),
			this.state.reportData.customizer.supportedGranularities(),
		);
	}

	getServerSettings() {
		return this.state.reportData.settings;
	}

	getCalculator() {
		return this.state.calculator;
	}

	updateState(newState) {
		const newFullState = { ...this.state, ...newState };
		let extra;
		if (newFullState.reportData) {
			extra = { calculator: new ReportCalculator(newFullState) };
		}
		this.setState({
			...newState,
			...extra,
		});
	}

	async update(state, reloadReport, directState) {
		BrowserUtils.keepScrollPos(() => this.updateInternal(state, reloadReport, directState));
	}

	async updateInternal(state, reloadReport, directState) {
		const fullState = { ...state, ...directState };
		if (!reloadReport) {
			this.updateState(fullState);
			return;
		}
		this.updateState({ reloading: true, ...directState });
		try {
			await this.requestReport({ ...fullState, reloading: false });
		} catch (e) {
			Base.renderGlobal((done) => (
				<Dialog
					open
					status="error"
					text={MiscUtils.errorMsg(e)}
					onClose={() => {
						this.updateState({ reloading: false });
						done();
					}}
				/>
			));
		}
	}

	async requestReport(withState) {
		const reportState = { ...this.state, ...withState };
		const startEnd = getValidStartEnd(reportState);
		const reportData = await ReportData.from({ ...reportState, ...startEnd });
		setTimeout(() => this.updateState({
			...withState,
			...startEnd,
			calcSums: reportData.calcSumsMap,
			serverSums: reportData.allDataSums(),
			reportData,
			groupByOptions: reportData.customizer.getGroupByOptions(this, reportData.report),
		}));
	}

	calculateSumVal(dataObj, sumVal, fallbackIfInvalid, useComp) {
		const { reportData } = this.state;
		if (fallbackIfInvalid !== undefined) {
			const invalidFn = Constants[this.props.type].IS_INVALID_AS_TOTAL;
			if (invalidFn && invalidFn(this.state, sumVal)) {
				return fallbackIfInvalid;
			}
		}
		return reportData.calculate(dataObj, sumVal, useComp);
	}

	renderGranularityPicker() {
		const { granularity } = this.state;
		const options = this.getGranularityOptions();
		const needReload = (newGranularity) => {
			const serverGranularity = this.getServerSettings().granularity;
			const newTimeRange = Constants.SHORT_TIME_RANGES[newGranularity];
			const serverTimeRange = Constants.SHORT_TIME_RANGES[serverGranularity];
			return newTimeRange && (!serverTimeRange || serverTimeRange.ms > newTimeRange.ms);
		};
		return (
			<Box sx={{ ml: 1 }}>
				<Select
					name="Show"
					label="Show"
					items={_.map(options, (v, k) => ({ label: v, value: k }))}
					value={granularity}
					onChange={(ev) => this.update({ granularity: ev.target.value }, needReload(ev.target.value))}
					underlineShow={false}
				/>
			</Box>
		);
	}

	limitSumsByGrouping(initSums, groupBy) {
		let sums = initSums;
		const limiters = this.GROUP_BY_SUM_LIMITERS || {};
		groupBy.forEach((by) => {
			const limiter = limiters[by];
			if (limiter) {
				sums = sums.filter((sum) => limiter.indexOf(sum) >= 0);
			}
		});
		return sums;
	}

	updateGroupBy(groupBy, stateSums) {
		let sums = this.limitSumsByGrouping(stateSums, groupBy);
		if (!sums.length) {
			sums = this.getInitSums();
		}
		return sums;
	}

	getUserGroupByOptions() {
		const { type } = this.props;
		const res = getUserGroupByOptions(type);
		res.push(...this.getCustomizer().getAdditionalUserGroupByOptions(this.state));
		return _.uniq(res);
	}

	getDimensionExclude(state) {
		const { groupByOptions } = this.state;
		return _.filter([
			state.useForecast && this.NON_FORECAST_OPTIONS,
			this.getCustomizer().getDimensionExclude(state, groupByOptions),
		]).flat();
	}

	availableOptionsFor(idx, stateObj) {
		const state = stateObj || this.state;
		const { groupByOptions } = this.state;
		const { groupBy } = state;
		const isAdmin = stores.identity.isAdministrator();
		const userGroupByOptions = this.getUserGroupByOptions();
		const excludeDims = this.getDimensionExclude(state);
		return _.pickBy(groupByOptions, (val, key) => {
			if (excludeDims.indexOf(key) >= 0) {
				return false;
			}
			if (idx !== undefined && groupBy[idx] === key) { // selected option should always be available
				return true;
			} if (groupBy.indexOf(key) >= 0) { // options selected elsewhere should *not* be available
				return false;
			}
			if (!isAdmin) {
				if (userGroupByOptions.indexOf(key) < 0) {
					return false;
				}
			}
			return true;
		});
	}

	downloadAsCsv = () => {
		const { calculator, title } = this.state;
		return BrowserUtils.downloadTextFile(calculator.reportToCsv(), `${title} ${new Date()}.csv`);
	};

	renderIconButtons(reportLoc) {
		const {
			onSave,
			onRemove,
			settingsOpen,
			onSettingsOpenToggle,
			isActualReport,
			onTitleChange,
		} = this.props;
		const { reportData } = this.state;
		return (
			<>
				{!isActualReport && (
					<>
						<ReportAsUrl
							getUserAdjustedProperties={() => _.omit(this.getCurrentProperties(true), 'isActualReport')}
							reportLoc={reportLoc}
						/>
						<Tooltip title="Export as .CSV">
							<IconButton
								onClick={() => this.downloadAsCsv()}
								disableTouchRipple
								size="large"
							>
								<FileDownloadIcon />
							</IconButton>
						</Tooltip>
						{!!onSettingsOpenToggle && (
							<Tooltip title="Open settings">
								<IconButton
									onClick={() => onSettingsOpenToggle(true)}
									disableTouchRipple
									size="large"
								>
									<SettingsIcon />
								</IconButton>
							</Tooltip>
						)}
					</>
				)}
				{onSave
				&& (
					<Tooltip title="Save widget">
						<IconButton onClick={() => onSave()} disableTouchRipple size="large">
							<SaveIcon />
						</IconButton>
					</Tooltip>
				)}
				{onRemove
				&& (
					<Tooltip title="Remove widget">
						<div>
							<ConfirmDialog
								text="Are you sure you want to delete this report widget?"
								onConfirm={() => onRemove()}
							>
								<IconButton><DeleteIcon /></IconButton>
							</ConfirmDialog>
						</div>
					</Tooltip>
				)}
				<MuiDialog
					maxWidth="sm"
					fullWidth
					open={!!settingsOpen}
					onClose={() => onSettingsOpenToggle(false)}
				>
					{settingsOpen
					&& (
						<ReportSettings
							report={this}
							reportData={reportData}
							settings={_.clone(this.state)}
							settingsFilter={Object.keys(Report.propTypes)}
							initSums={this.getInitSums()}
							advMappings={(reportData.report || {}).advMappingData || []}
							groupByOptions={this.getGroupByOption()}
							onSave={(settings) => {
								this.update(settings, true);
								onSettingsOpenToggle(false);
								onTitleChange(settings.title);
							}}
							onCancel={() => onSettingsOpenToggle(false)}
							funcs={{
								limitSumsByGrouping: this.limitSumsByGrouping.bind(this),
								updateGroupBy: this.updateGroupBy.bind(this),
								getUserGroupByOptions: this.getUserGroupByOptions.bind(this),
								availableOptionsFor: this.availableOptionsFor.bind(this),
								getDimensionExclude: this.getDimensionExclude.bind(this),
							}}
							useForecast={this.state.useForecast}
						/>
					)}
				</MuiDialog>
			</>
		);
	}

	renderPieErrorMessage() {
		const { onSettingsOpenToggle } = this.props;
		const { groupBy, useCompareTo } = this.state;

		const multiDimensional = groupBy.length > 1;
		const byDate = groupBy[0] === 'date';

		if (multiDimensional) {
			return (
				<PieChartNotSupportedAlert
					onFix={() => onSettingsOpenToggle(true)}
					suggestion="Edit configuration"
				>
					The pie chart visualisation is not supported
					when multiple dimensions are selected
				</PieChartNotSupportedAlert>
			);
		}
		if (byDate) {
			return (
				<PieChartNotSupportedAlert
					onFix={() => this.update({ showPieChart: false })}
					suggestion="Turn off pie chart"
				>
					The pie chart visualisation is not supported
					for the &quot;date&quot; dimension
				</PieChartNotSupportedAlert>
			);
		}
		if (useCompareTo) {
			return (
				<PieChartNotSupportedAlert
					onFix={() => this.update({ useCompareTo: false })}
					suggestion="Remove comparison"
				>
					The pie chart visualisation is not supported
					when a comparison date range is selected
				</PieChartNotSupportedAlert>
			);
		}
		return null;
	}

	generateTrendLabel({
		label, current, historical, decimals = 2,
	}) {
		if (!historical || current === historical) {
			return label;
		}

		const { sums } = this.state;
		const { type } = this.props;

		const settings = metricSettings[type] || {};
		const { isNegativeIfIncrease, isPercentage } = settings[sums[0]] || {};

		const caluclateDifference = () => {
			const rounded = (val) => +parseFloat(val).toFixed(decimals);
			if (isPercentage) {
				return rounded(current - historical);
			}
			return rounded(percentChange(historical /* from */, current /* to */));
		};

		const diff = caluclateDifference();
		if (diff === 0) {
			return label;
		}

		const isPositive = (neg) => (neg ? diff < 0 : diff > 0);
		const color = isPositive(isNegativeIfIncrease) ? 'green' : 'red';
		const Icon = isPositive() ? TrendingUpIcon : TrendingDownIcon;

		return (
			<div style={{ display: 'flex' }}>
				<div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
					{`${label}`}
				</div>
				<div style={{ paddingLeft: 10, fontSize: 'small', color }}>
					<Icon sx={{ color, verticalAlign: 'middle' }} />
					{`${diff}${isPercentage ? '%pt.' : '%'}`}
				</div>
			</div>
		);
	}

	renderTopBar() {
		const { timezone } = this.state;
		const customizer = this.getCustomizer();
		return (
			<Grid container spacing={3} sx={{ mb: -2, mt: -6 }}>
				{customizer.hasTimezoneSupport() && (
					<Grid item style={{ minWidth: 200 }}>
						<TimezoneSelect
							value={timezone}
							onChange={(ev) => this.update({ timezone: ev.target.value }, true)}
						/>
					</Grid>
				)}
				<Grid item sx={{ flexGrow: 1 }}>
					{customizer.renderTopBar?.(this.state, (state) => this.update(state, true))}
				</Grid>
			</Grid>
		);
	}

	renderInternal() {
		const { cardProps } = this.props;

		const {
			reportData,
			groupBy,
			title,
			sums,
			showChart,
			showTable,
			showPieChart,
			hideSettings,
			serverSums,
			sortSum,
			sortSumIsComp,
			sortReversed,
			rowsPerTab,
			calculator,
			importanceTabPerc,
			trigger,
			chartSettings,
			chartSettingsOpen,
			useCompareTo,
			events,
		} = this.state;

		const { isActualReport } = this.props;
		const { reportLocation, genericReportLocation } = this;
		const reportLoc = reportLocation ? genericReportLocation || `${reportLocation}/add` : null;
		const isAdmin = stores.identity.isAdministrator();
		const hasDate = groupBy.includes('date');
		const displayChartSettings = (showChart || showPieChart) && chartSettingsOpen;
		const displayMergeDimensionSettings = calculator?.settings?.groupBy?.filter((dim) => dim !== 'date').length > 1;
		const groupByOptions = this.getGroupByOption();
		const { report, customizer } = reportData;
		const warningMsg = customizer.getReportWarning?.({ reportData, calculator });
		const blocking = reportBlockingReason({
			reportData,
			onUpdateState: (...args) => this.update(...args),
		});

		const currencyOf = (sum) => {
			const description = this.SUM_DESCRIPTIONS[sum];
			const currency = this.IS_REVENUE_SUM[sum]
				? ` ${report.currency}`
				: '';
			return `${description}${currency}`;
		};
		function descriptionOf(sum) {
			return this.SUM_DESCRIPTIONS[sum];
		}

		const formattedData = calculator.formatReport(groupBy.length, true);

		const summary = calculator.summarizeNumbers(report.data);
		const [data, dataComp] = [false, ...(useCompareTo ? [true] : [])].map((useComp) => {
			const val = this.calculateSumVal(summary, sums[0], null, useComp);
			return val === null ? null : {
				val,
				formatted: `${fmtNum(val)} ${this.IS_REVENUE_SUM[sums[0]] ? report.currency : ''}`,
			};
		});

		const [dataSum, dataSumComp] = [data?.formatted, dataComp?.formatted];

		const formattedLabel = (this.SUM_DESCRIPTIONS[sums[0]] || sums[0])
			.toLowerCase()
			.replace('total ', '');

		const canShowEvents = isAdmin && showChart && hasDate;
		const loadEventsIfNeeded = async () => {
			const { events: existingEvents } = this.state;
			if (chartSettings?.showEventLines && canShowEvents && !existingEvents) {
				this.setState({ events: await Event.fnCache.getEvents() });
			}
		};
		loadEventsIfNeeded();

		const renderChartSettings = (extraProps) => {
			if (!showChart && !showPieChart) {
				return null;
			}
			const onChartSettingsClick = () => {
				this.updateState({
					chartSettingsOpen: !chartSettingsOpen,
				});
			};

			const pie = !showChart && showPieChart;
			const chartType = calculator.calculateChartType({ pie });

			return (
				<ChartSettings
					autoChartType={chartType}
					showChart={showChart}
					showPieChart={showPieChart}
					chartSettingsOpen={chartSettingsOpen ?? false}
					onChartSettingsClick={onChartSettingsClick}
					onShowEventLinesChange={() => {
						const updatedChartSettings = {
							...chartSettings,
							showEventLines: !chartSettings.showEventLines,
						};
						this.updateState({
							...this.state,
							chartSettings: updatedChartSettings,
						});
					}}
					onChartSettingsChange={(updatedFields) => {
						this.updateState({
							chartSettings: {
								...chartSettings,
								...updatedFields,
							},
						});
						this.updateDimensions(updatedFields);
					}}
					onEventsChange={(updatedEvents) => {
						this.setState({ events: updatedEvents });
					}}
					chartSettings={chartSettings}
					canShowEvents={canShowEvents}
					displayMergeDimensionSettings={displayMergeDimensionSettings}
					groupBy={groupBy}
					groupByOptions={groupByOptions}
					eventTags={_.uniq((events || []).map(({ tags }) => tags).flat())}
					onClose={onChartSettingsClick}
					{...extraProps}
				/>
			);
		};

		const { columns } = this.state;

		const renderChart = (extraProps = {}) => (
			<ChartsWrapper
				columns={columns}
				events={canShowEvents ? events : null}
				calculator={this.chartWrapperCalculator(calculator)}
				referenceData={formattedData.arr}
				sums={sums}
				currencyOf={currencyOf}
				descriptionOf={descriptionOf.bind(this)}
				chartSettings={chartSettings}
				hasDateDimension={groupBy.includes('date')}
				displayChartSettings={displayChartSettings}
				displayMergeDimensionSettings={displayMergeDimensionSettings}
				groupByOptions={groupByOptions}
				dimensions={groupBy
					.filter((dim) => dim !== 'date')
					.map((dim) => ({ label: groupByOptions[dim], value: dim }))}
				{...extraProps}
			/>
		);

		const renderSum = (sumStr, label) => (
			<TextField
				name={Math.random().toString()}
				label={label}
				value={sumStr}
				underlineShow={false}
				nonEditable
				className={styles.totalInput}
				inputProps={{ size: 1 }}
			/>
		);

		const renderSumBoxes = () => {
			const trendLabel = (label) => this.generateTrendLabel({
				label, current: data.val, historical: dataComp?.val,
			});
			const box = (r, children) => (
				<Box sx={{ mt: 2 }}>
					{r}
					{children}
				</Box>
			);
			const mainLabel = capitalize(formattedLabel);
			const comparisonLabel = `Historical ${formattedLabel.toLowerCase()}`;
			return box(
				renderSum(dataSum, trendLabel(mainLabel)),
				dataSumComp ? box(renderSum(dataSumComp, comparisonLabel)) : null,
			);
		};

		return (
			<Card
				variant="outlined"
				sx={{ overflow: 'visible', userSelect: 'text' }}
				{...cardProps}
			>
				<CardContent sx={{ mt: 0 }}>
					<Box display="flex" alignItems="center" marginBottom={hideSettings ? 0 : 4}>
						{!isActualReport && (
							<Typography variant="h2" className={styles.title}>
								{title}
							</Typography>
						)}
						{this.renderIconButtons(reportLoc)}
					</Box>
					{!hideSettings
					&& (
						<>
							{this.renderTopBar()}
							<Box
								sx={{
									display: 'flex',
									flexWrap: 'nowrap',
									justifyContent: 'space-between',
								}}
							>
								<Box
									sx={{ display: 'flex', flexWrap: 'wrap' }}
								>
									<Box
										display="flex"
										sx={{
											maxHeight: (theme) => theme.spacing(7),
											mt: 3,
										}}
									>
										<ReportTypeSelector
											state={this.state}
											update={(s) => this.update(s)}
										/>
									</Box>
									<Box
										sx={{
											display: 'flex',
											mt: 3,
											ml: 1,
										}}
									>
										<StartEndPicker
											report={this}
											state={this.state}
											isActive={!this.state.dynamicDateRange}
											update={(settings) => this.update(settings, true)}
											renderAsForecasted={(d) => reportData.report.firstForecastDate && DateUtils.toDate(d) >= DateUtils.fullDay(reportData.report.firstForecastDate)}
											useSpaceBetween
										/>
									</Box>
									<Box sx={{ pt: 2, ml: 1, mt: 1 }}>
										<DynamicDateRangeSelector
											update={(settings) => this.update(settings, true)}
											currentDynamicDateRange={this.state.dynamicDateRange}
											dateRanges={customizer.getDynamicDateRangesValues()}
											supportedGranularities={customizer.supportedGranularities()}
											allowToday={customizer.hasDataForToday()}
										/>
									</Box>
									<Box sx={{ mt: 3, mr: 1 }}>
										{hasDate && this.renderGranularityPicker()}
									</Box>
									<Box sx={{ mt: 3 }}>
										<FormGroup>
											{isForecastAvailable(isAdmin) && (
												<ForecastToggle
													state={this.state}
													update={(s) => this.update(s, true)}
												/>
											)}
											<CompareToSwitch
												state={this.state}
												update={(settings) => this.update(settings, true)}
											/>
										</FormGroup>
									</Box>
								</Box>
								{dataSum !== undefined && renderSumBoxes()}
							</Box>
						</>
					)}
					{renderChartSettings()}
					{!blocking && showPieChart && (
						<Box marginTop={2}>
							{this.renderPieErrorMessage() || renderChart({
								onDisableChartType: () => this.update({ showPieChart: false }),
								pie: true,
								chartSettings: {
									...chartSettings,
								},
							})}
						</Box>
					)}
					{warningMsg && (
						<Box marginTop={2}>
							{warningMsg}
						</Box>
					)}

					{!blocking && showChart && renderChart()}
					{!blocking && showTable && (
						<ReportTable
							{...this.getCurrentProperties()}
							calculator={calculator}
							calculateSumVal={(...args) => this.calculateSumVal(...args)}
							data={formattedData}
							serverSums={serverSums}
							sortReversed={sortReversed}
							sortSum={sortSum}
							sortSumIsComp={sortSumIsComp}
							onSettingsChanged={async (settings, reload = false) => this.update(settings, reload)}
							rowsPerTab={rowsPerTab || Report.defaultProps.rowsPerTab}
							importanceTabPerc={importanceTabPerc || 0}
							groupByOptions={this.getGroupByOption()}
							tableState={dbDecodedTableState(this.props.tableState)}
							reportData={reportData}
							trigger={trigger}
							funcs={{
								limitSumsByGrouping: this.limitSumsByGrouping.bind(this),
								availableOptionsFor: this.availableOptionsFor.bind(this),
								getInitSums: this.getInitSums.bind(this),
							}}
						/>
					)}
					{blocking}
				</CardContent>
			</Card>
		);
	}

	render() {
		const { header } = this.props;
		const { reloading, reportData } = this.state;
		return (
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<div ref={(node) => { this.node = node; }}>
						{ header }
						<Disabler disabled={!!reloading}>
							<OperationWrapper
								fn={() => this.requestReport()}
							>
								{reportData && this.renderInternal()}
							</OperationWrapper>
						</Disabler>
					</div>
				</Grid>
			</Grid>
		);
	}
}

Report.propTypes = {
	title: PropTypes.string,
	start: PropTypes.any,
	end: PropTypes.any,
	timezone: PropTypes.any,
	tableState: PropTypes.objectOf(PropTypes.any),
	dynamicDateRange: PropTypes.oneOf(DynamicDateRanges.getAllDbKeys()),
	compareTo: PropTypes.any,
	useCompareTo: PropTypes.bool,
	granularity: PropTypes.oneOf(['perday', 'perweek', 'permonth', 'peryear', 'perhour', 'per10min']),
	groupBy: PropTypes.array,
	whereEqual: PropTypes.object,
	whereIn: PropTypes.object,
	sums: PropTypes.array,
	showChart: PropTypes.bool,
	showTable: PropTypes.bool,
	showPieChart: PropTypes.bool,
	settingsOpen: PropTypes.bool,
	hideSettings: PropTypes.bool,
	mergePublicHidden: PropTypes.bool,
	splitByAuctionRunner: PropTypes.bool,
	maxAdvertisers: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	includeNoUserAdvertisers: PropTypes.bool,
	..._.transform(AllMappingDimensions, (res, { reportProp }) => {
		res[reportProp] = PropTypes.string;
	}, {}),
	currency: PropTypes.string,
	type: PropTypes.string,
	sortSum: PropTypes.string,
	sortSumIsComp: PropTypes.bool,
	sortReversed: PropTypes.bool,
	rowsPerTab: PropTypes.number,
	importanceTabPerc: PropTypes.number,
	trendMethod: PropTypes.string,
	trendPeriods: PropTypes.number,
	useForecast: PropTypes.bool,
	correctionType: PropTypes.string,
	filterFlags: PropTypes.object,
	attributes: PropTypes.object,
	trigger: PropTypes.object, // Only when editing triggers
	triggerFilters: PropTypes.arrayOf(
		PropTypes.shape({
			sum: String,
			isComp: Boolean,
			cond: PropTypes.oneOf(['lt', 'gtEq']),
			value: Number,
		}),
	),
	chartSettings: PropTypes.shape({
		fill: PropTypes.bool,
		stacked: PropTypes.bool,
		barChartType: PropTypes.oneOf(['horizontal', 'vertical']),
		chartType: PropTypes.oneOf(['auto', 'line', 'bar', 'pie']),
		showEventLines: PropTypes.bool,
		selectedEventTags: PropTypes.arrayOf(PropTypes.string),
		maxChartsPerRow: PropTypes.number,
	}),
	header: PropTypes.element,
	isActualReport: PropTypes.bool,
	onTitleChange: PropTypes.func,
	filterSelectionType: PropTypes.objectOf(PropTypes.oneOf(['INCLUSIVE', 'EXCLUSIVE'])),
	warnOldData: PropTypes.bool,
};

Report.defaultProps = {
	title: 'My report',
	start: -31,
	end: -1,
	tableState: undefined,
	compareTo: undefined,
	dynamicDateRange: undefined,
	useCompareTo: false,
	granularity: 'perday',
	groupBy: ['date'],
	whereEqual: undefined,
	whereIn: undefined,
	sums: undefined,
	showChart: true,
	showTable: false,
	showPieChart: false,
	settingsOpen: false,
	hideSettings: false,
	mergePublicHidden: false,
	splitByAuctionRunner: false,
	maxAdvertisers: 200,
	includeNoUserAdvertisers: false,
	..._.transform(AllMappingDimensions, (res, { reportProp }) => {
		res[reportProp] = undefined;
	}, {}),
	currency: undefined,
	timezone: undefined,
	type: 'audience',
	sortSum: undefined,
	sortSumIsComp: false,
	sortReversed: false,
	rowsPerTab: 100,
	importanceTabPerc: 0, // 100%
	trendMethod: 'median',
	trendPeriods: 3,
	useForecast: false,
	correctionType: undefined,
	filterFlags: {},
	attributes: null,
	trigger: undefined,
	triggerFilters: undefined,
	chartSettings: {
		fill: false,
		stacked: false,
		groupedDimensions: [],
		barChartType: 'vertical',
		showEventLines: false,
		selectedEventTags: [],
		maxChartsPerRow: 1,
		chartType: 'auto',
	},
	header: null,
	// If its rendered from reports, not dashboards or alarms.
	isActualReport: false,
	onTitleChange: () => undefined,
	filterSelectionType: {
		publisherId: 'INCLUSIVE',
		placementId: 'INCLUSIVE',
		siteId: 'INCLUSIVE',
		sourceId: 'INCLUSIVE',
		sspId: 'INCLUSIVE',
		mediaTypeId: 'INCLUSIVE',
		paymentTypeId: 'INCLUSIVE',
		dealTypeId: 'INCLUSIVE',
		revenueType: 'INCLUSIVE',
		demandChannel: 'INCLUSIVE',
		..._.transform(AllMappingDimensions, (res, { reportProp }) => {
			res[reportProp] = 'INCLUSIVE';
		}, {}),
	},
	warnOldData: false,
};

export default Report;
