const _ = require('lodash');
const { varIsNonEmpty } = require('../misc');
const DiffFns = require('./diffFns');
const { MODE } = require('./diffConstants');

/**
 * This class represents a portion of an array where the first element ('obj') is identifiable while the
 * rest ('nonObjs') are not. As a special-case there will always be one DiffObjRange instance to represent
 * the start of the array with obj == undefined
 * The purpose of this is to get/apply differences in arrays via their "identifiable" elements.
 * In most cases the situation will be so that either *all* elements are identifiable (e.g. Site.placements) so that
 * there is one DiffObjRange for each element + an empty start-range OR *no* elements are identifiable
 * (e.g. PlacementType.dimensions) so that there is only a single start-range with all elements in 'nonObjs'
 * */
class DiffObjRange {
	constructor({ diffArr, obj, ...rest }) {
		Object.assign(this, {
			diffArr, // The DiffArr instance we're a member of
			obj, // The _id-object OR a string that looks like a MongoDb ID
			diff: undefined, // diff of the _id-object
			nonObjs: [],
			mode: MODE.NEW,
			appendNonObjs: undefined, // set when mode == APPEND

			// If 'prevChanged' is true - the order of elements have changed in such a way so that the previously
			// identifiable element have another id in the new array
			prevChanged: false,
			...rest,
		});
	}

	// There is *any* kind of change so that it makes sense to produce a diff
	hasChange() {
		return this.mode !== MODE.UNCHANGED || this.diff || this.prevChanged;
	}

	// "Identifier" of this range (identifier of .obj)
	getId() {
		return this.diffArr.getIdFromElement(this.obj);
	}

	// Index in the DiffArr instance we belong to
	index() {
		const { objRanges } = this.diffArr;
		if (objRanges[this.cachedIdx] !== this) {
			this.cachedIdx = objRanges.indexOf(this);
		}
		return this.cachedIdx;
	}

	// Find some previous DiffObjRange that satisfies 'cb' (search backwards from the previous element)
	findPrev(cb) {
		for (let i = this.index() - 1; i >= 0; i -= 1) {
			const objRange = this.diffArr.objRanges[i];
			if (cb(objRange)) {
				return objRange;
			}
		}
		return undefined;
	}

	// Initialize settings by checking the difference with the corresponding DiffObRange instance for the
	// original array. (notice: used when *getting* a diff, not when applying it)
	applyOrgObjRange(orgObjRange) {
		if (!orgObjRange) { // there was no corresponding range, we're new
			this.mode = MODE.NEW;
		} else if (this.nonObjs.length < orgObjRange.nonObjs.length) { // We've deleted something => replace all
			this.mode = MODE.REPLACE;
		} else {
			let foundDiff;
			for (let i = 0; i < orgObjRange.nonObjs.length; i += 1) {
				const newElm = this.nonObjs[i];
				const orgElm = orgObjRange.nonObjs[i];
				const elmDiff = DiffFns.getValueDiff(orgElm, newElm);
				if (elmDiff !== undefined) {
					foundDiff = true;
					this.mode = MODE.REPLACE;
					break; // We've found a difference in 'nonObjs' (that is not just an append) => replace all
				}
			}
			if (!foundDiff) {
				if (this.nonObjs.length > orgObjRange.nonObjs.length) {
					this.appendNonObjs = this.nonObjs.slice(orgObjRange.nonObjs.length);
					this.mode = MODE.APPEND; // Element should be appended
				} else {
					this.mode = MODE.UNCHANGED; // Nothing has changed
				}
			}
		}
		if (orgObjRange?.obj) {
			this.diff = DiffFns.getObjectDiffInternal(orgObjRange.obj, this.obj); // set diff of the identifiable elm
			const prevNewShared = this.findPrev((prev) => this.diffArr.objRangesById[prev.getId()]);
			const prevOldShared = orgObjRange.findPrev((prev) => orgObjRange.diffArr.objRangesById[prev.getId()]);
			if (prevNewShared.getId() !== prevOldShared.getId()) {
				this.prevChanged = true; // We've moved in relation to previously existing objects
			}
		}
	}

	// Return diff-object of this range, or undefined if there is no difference
	getDiff() {
		if (!this.hasChange()) {
			return undefined;
		}
		const id = this.getId();
		const res = {
			id,
			type: DiffFns.isDiffIdentifier(id) ? this.obj?._diffType : null,
			mode: this.mode,
			nonObjs: this.appendNonObjs || this.nonObjs,
			prevChanged: this.prevChanged,
			obj: this.mode === MODE.NEW ? this.obj : null,
			diff: this.diff,
		};
		return _.pickBy(res, varIsNonEmpty);
	}

	// Add non-identifiable element to range
	pushNonObj(elm) {
		this.nonObjs.push(elm);
	}
}

module.exports = DiffObjRange;
