import { useEffect, useState } from 'react';
/**
* @param {*} callback Function to run on interval
* @param {*} frequency Interval frequency in milliseconds
* @param {*} runInitial Do you want to run callback initially?
*/
export default function useInterval(callback, frequency, runInitial = false) {
	const [latestResult, setLatestResult] = useState(null);
	const [renderCount, setRenderCount] = useState(0);

	// Ignore the lint warning, we want this to reinitialize on every render
	// so that we can "reset" the interval
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const runCallback = async () => {
		if (callback) {
			const res = await callback();
			setLatestResult(res);
		}
	};

	useEffect(() => {
		let interval;
		if (frequency) {
			interval = setInterval(() => {
				runCallback();
			}, frequency);
		}
		return () => clearInterval(interval);
	}, [runCallback, frequency]);

	// Run initial if initial render
	useEffect(() => {
		if (renderCount === 0) {
			if (runInitial) {
				runCallback();
			}
			setRenderCount((c) => c + 1);
		}
	}, [renderCount, runCallback, runInitial]);

	return latestResult;
}
