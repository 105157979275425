/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

let currentDrag; // Let's assume we're never dragging two nodes simultaneously
let currentOverDiv;

const { debugLogDragDrop } = window.localStorage;

/** Component for drag-dropping "nodes" (websites, placements, etc) */
function NodeDragDrop({
	form, publisherNode, id, children, canDrop, disabled,
}) {
	const nodeOf = (nodeId) => publisherNode.byId[nodeId];
	const myNode = () => nodeOf(id);
	const stop = () => {
		if (currentDrag?.id === id) {
			currentDrag = null;
		}
	};

	const ref = useRef(null);

	const showAsOver = (div, over) => {
		div.style.opacity = over ? 0.3 : 1;
	};
	const unsetOver = () => {
		if (currentOverDiv) {
			showAsOver(currentOverDiv, false);
			currentOverDiv = null;
		}
	};
	const setOver = (isOver) => {
		if (ref.current) {
			if (isOver) {
				if (currentOverDiv && currentOverDiv !== ref.current) {
					showAsOver(currentOverDiv, false);
				}
				currentOverDiv = ref.current;
			}
			showAsOver(ref.current, isOver);
		}
	};

	// eslint-disable-next-line no-unused-vars, arrow-body-style
	const events = (obj) => _.mapValues(obj, (fn, attr) => (ev, ...rest) => {
		if (debugLogDragDrop) {
			console.info(attr, myNode()?.name);
		}
		return fn(ev, ...rest);
	});

	const canDropHere = () => currentDrag
		&& currentDrag.id !== id
		&& myNode()
		&& canDrop(currentDrag.node, myNode());

	useEffect(() => stop);
	const onDrop = (ev) => {
		if (!canDropHere()) {
			stop();
			return;
		}
		unsetOver();
		ev.preventDefault();
		const me = myNode();
		const other = currentDrag.node;
		const meArr = me.parentDbArray;
		const otherArr = other.parentDbArray;
		if (!meArr || !otherArr) {
			return;
		}
		const { clientY, target } = ev;
		const { top, height } = target.getBoundingClientRect();
		const upper = clientY - top < height / 2;
		form.update(() => {
			_.pull(otherArr, other.obj);
			meArr.splice(_.findIndex(meArr, me.obj) + (upper ? 0 : 1), 0, other.obj);
		});
	};
	if (disabled) {
		return <div>{children}</div>;
	}

	return (
		<div
			ref={ref}
			draggable
			{...events({
				onDrop,
				onDragOver: (e) => e.preventDefault(),
				onDragEnd: () => {
					unsetOver();
					stop();
				},
				onDragStart: (e) => {
					if (_.isNumber(e.clientX) && _.isNumber(e.clientY)) {
						// Semi-hack: don't start dragging on inputs as user is probably not trying to drag
						if (document.elementFromPoint(e.clientX, e.clientY)?.tagName === 'INPUT') {
							e.preventDefault();
							return;
						}
					}
					currentDrag = { id, node: myNode() };
					e.dataTransfer.setData('text/plain', id);
				},
				onDragEnter: () => {
					if (currentDrag && canDropHere()) {
						setOver(true);
					}
				},
				onDragLeave: (e) => {
					if (!ref.current || !e.relatedTarget || !ref.current.contains(e.relatedTarget)) {
						setOver(false);
					}
				},
			})}
		>
			{children}
		</div>
	);
}

NodeDragDrop.propTypes = {
	form: PropTypes.object.isRequired,
	publisherNode: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired,
	children: PropTypes.any,
	canDrop: PropTypes.func,
	disabled: PropTypes.bool,
};

NodeDragDrop.defaultProps = {
	children: undefined,
	canDrop: (other, me) => me.type === other.type,
	disabled: false,
};

export default NodeDragDrop;
