import { invalidateStore } from 'relevant-shared/objects/objectStore';
import { GenericTagField } from 'relevant-shared/objects/tagFieldBase';
import { arrayCallbacks } from 'relevant-shared/misc/misc';
import classes from './classes';
import MiscUtils from '../lib/miscUtils';

const {
	Event,
	SspPlacement,
	Publisher,
	Site,
	GlobalSettingsObject,
	Tag,
} = classes;

Event.prototype.postInit = function () {
	this.date = new Date(this.date);
};

SspPlacement.prototype.postInit = function () {
	if (this.isNew) {
		// Necessary to do manually because of unfortunate naming of 'id' for ssp placement id
		this._id = MiscUtils.objectIdStr();
	}
};

Publisher.alwaysDeepCreate = true; // To make sure the postInit() function for Site/Placement always runs
Publisher.prototype.postInit = function () {
	arrayCallbacks(this.websites, () => invalidateStore());
};

Site.prototype.postInit = function () {
	arrayCallbacks(this.placements, () => invalidateStore());
};

GlobalSettingsObject.alwaysDeepCreate = true; // To make sure postInit() function for Tag always runs

Tag.prototype.postInit = function () {
	this.fields = (this.fields || []).map((fld) => new GenericTagField(fld));
};
