const _ = require('lodash');
const moment = require('moment');
const { createCsv } = require('../misc/misc');
const csvExport = require('./csvExport');
const DateUtils = require('../misc/dateUtils');
const EnvReportInterface = require('./envReportInterface');
const Constants = require('./constants');
const ReportData = require('./reportData');
const TriggerReportFilter = require('./triggerReportFilter');
const ReportCalculatorTableFormatter = require('./reportCalculatorTableFormatter');
const { rearranged, getChartGroupBy } = require('./utils');

const fm2 = (n) => (n < 10 ? `0${n}` : n);

const { COMP_PFX } = Constants;

const bidRangeToVal = (v) => {
	const num = parseFloat(v);
	return Number.isNaN(num) ? -1 : num; // To support e.g. "[No floor]" value
};

const flattenDimensions = (dataObj, fromIdx, numDims) => {
	const { data, groupBy, labels } = dataObj;
	const dims = groupBy.slice(fromIdx, fromIdx + numDims);
	const flatDim = dims.join('_');
	const newLabels = {};
	const newData = {};
	const newObj = {
		data: newData,
		groupBy: [...groupBy.slice(0, fromIdx), flatDim, ...groupBy.slice(fromIdx + numDims)],
		labels: {
			..._.omit(labels, dims),
			[flatDim]: newLabels,
		},
	};
	const path = [];
	const makeFlatDimension = (dst, src, level) => {
		_.forOwn(src, (v, k) => {
			path.push(k);
			if (level === numDims - 1) {
				const id = path.join('_');
				newLabels[id] = path.map((p, i) => (labels[groupBy[fromIdx + i]] || {})[p] || '').join(' - ');
				dst[id] = v;
			} else {
				makeFlatDimension(dst, v, level + 1);
			}
			path.pop();
		});
	};
	const flatten = (src, dst, level) => {
		if (level === fromIdx) {
			makeFlatDimension(dst, src, 0);
		} else {
			_.forOwn(src, (v, k) => {
				dst[k] = {};
				flatten(v, dst[k], level + 1);
			});
		}
	};
	flatten(data, newData, 0);
	return {
		...dataObj,
		...newObj,
	};
};

class ReportCalculator {
	constructor(settings) {
		const {
			reportData,
			granularity,
			useForecast,
			type,
			importanceTabPerc,
			trigger,
		} = settings;
		Object.assign(this, {
			settings,
			reportData,
			granularity,
			useForecast,
			type,
			importanceTabPerc,
			dateLookup: {},
			fmtDateLookup: {},
			allDataSums: reportData.allDataSums(),
			constants: Constants[type],
		});
		if (trigger) { // user 'failures' as a metric
			this.triggerFilter = new TriggerReportFilter({ calculator: this });
			this.allDataSums.push('failures');
		}
		this.IS_BID_RANGE = this.constants.IS_BID_RANGE || { bidRange: true, floorRange: true };
		this.calculateSumVal = this.calculateSumVal.bind(this);
	}

	withNewSettings({ groupBy, labels, data }) {
		return new ReportCalculator({
			...this.settings,
			groupBy,
			reportData: new ReportData({
				copyAllFromSettings: true,
				...this.settings.reportData,
				settings: {
					...this.settings.reportData.settings,
					groupBy,
				},
				report: {
					...this.settings.reportData.report,
					data,
					labels,
				},
			}),
		});
	}

	/**
	 * Merge dimensions, i.e sspID + publisherId becomes sspId_publisherId
	 */
	withGroupedDimensions(groupedDimensionSet) {
		const originalDimensions = this.settings.groupBy;
		const groupedDimensions = originalDimensions.filter((d) => groupedDimensionSet.includes(d));
		if (groupedDimensions.length < 2) {
			return this; // nothing to do
		}
		let fromIndex = originalDimensions.indexOf(groupedDimensions[0]);
		const sortedDimensions = [
			...originalDimensions.slice(0, fromIndex),
			...groupedDimensions,
			..._.without(originalDimensions.slice(fromIndex), ...groupedDimensions),
		];
		const calculator = this.rearrangedForChartData(sortedDimensions);
		fromIndex = calculator.settings.groupBy.indexOf(groupedDimensions[0]);
		const numDimensions = groupedDimensions.length;
		const { report } = calculator.reportData;
		const { groupBy, labels, data } = flattenDimensions({
			groupBy: sortedDimensions,
			labels: report.labels,
			data: report.data,
		}, fromIndex, numDimensions);
		return this.withNewSettings({ groupBy, labels, data });
	}

	rearrangedForChartData(chartGroupBy) {
		const { groupBy } = this.reportData.settings;

		let { data } = this.reportData.report;
		if (!_.isEqual(chartGroupBy, groupBy)) {
			data = rearranged(
				data,
				groupBy,
				chartGroupBy,
				groupBy.length,
			);
		}

		return this.withNewSettings({
			groupBy: chartGroupBy,
			data,
			labels: this.reportData.report.labels,
		});
	}

	calculateSumVal(dataObj, sumVal, fallbackIfInvalid, useComp) {
		const { reportData, settings } = this;
		if (fallbackIfInvalid !== undefined) {
			const invalidFn = Constants[settings.type].IS_INVALID_AS_TOTAL;
			if (invalidFn && invalidFn(reportData.settings, sumVal)) {
				return fallbackIfInvalid;
			}
		}
		return reportData.calculate(dataObj, sumVal, useComp);
	}

	rearrangedForChartWrapper() {
		const { chartSettings } = this.settings;
		const { groupedDimensions } = chartSettings || {};
		let calculator = this;

		if (groupedDimensions?.length > 1) {
			calculator = calculator.withGroupedDimensions(groupedDimensions.map(({ value }) => value));
		}
		const chartGroupBy = getChartGroupBy(calculator.settings.groupBy);
		calculator = calculator.rearrangedForChartData(chartGroupBy);
		return calculator;
	}

	properties({ pie } = {}) {
		const { groupBy, useCompareTo, chartSettings } = this.settings;
		const chartGroupBy = getChartGroupBy(groupBy);
		const dateIdx = groupBy.indexOf('date');
		const dateInChart = dateIdx === 1 || (groupBy.length === 1 && dateIdx === 0);
		const includesDate = dateIdx >= 0;
		const isOnlyDate = groupBy.length === 1 && groupBy[0] === 'date';
		const isLineChart = includesDate && (useCompareTo || !isOnlyDate);
		let { chartType } = chartSettings || {};
		// eslint-disable-next-line no-nested-ternary
		const defaultChartType = pie ? 'pie' : (isLineChart ? 'line' : 'bar');
		if (!chartType || chartType === 'auto' || pie) {
			chartType = defaultChartType;
		}
		return {
			includesDate,
			isOnlyDate,
			isLineChart,
			chartGroupBy,
			chartType,
			dateInChart,
		};
	}

	calculateChartType(props) {
		const { chartType } = this.properties(props);
		return chartType;
	}

	getAllLabels(params) {
		const { reportData } = this;
		const { onDateLabel } = params || {};
		const res = {};
		_.forOwn(reportData.report.labels, (val, key) => {
			if (this.IS_BID_RANGE[key]) {
				res[key] = _.sortBy(Object.values(val), bidRangeToVal);
			} else {
				res[key] = EnvReportInterface.MiscUtils.alphaSorted(Object.values(val));
			}
		});
		const dateLabels = [];
		this.getDates().forEach((date) => {
			const formatted = this.fmtDate(date);
			if (_.last(dateLabels) !== formatted) {
				onDateLabel?.(date, formatted);
				dateLabels.push(formatted);
			}
		});
		res.date = dateLabels;
		return res;
	}

	getStartEnd() {
		const { now } = this.reportData;
		const { start, end } = this.reportData.settings;
		return {
			start: DateUtils.toDate(start, now),
			end: DateUtils.toDate(end, now),
		};
	}

	getLabelOptionsForColumns = (cols) => {
		if (cols >= Constants.MAX_LABELS.length) {
			return Constants.MAX_LABELS[Constants.MAX_LABELS.length - 1];
		}
		return Constants.MAX_LABELS[cols];
	};

	getDates() {
		const { granularity, useForecast } = this;
		const { start, end } = this.reportData.settings;
		const { now } = this.reportData;
		const dates = DateUtils.dates(DateUtils.toDate(start, now), DateUtils.toDate(end, now));
		const timeRange = Constants.SHORT_TIME_RANGES[granularity];
		if (!timeRange) {
			return dates;
		}
		const { ms } = timeRange;
		const res = [];
		const today = DateUtils.fullDay(now);
		dates.forEach((date) => {
			let until = DateUtils.fullDay(date, 1);
			if (date.getTime() === today.getTime() && !useForecast) {
				until = (now - (now % ms)) + ms;
			}
			for (let d = date; d < until; d = new Date(d.getTime() + ms)) {
				res.push(d);
			}
		});
		return res;
	}

	fmtDate(dateVal) {
		const { reportData, granularity } = this;
		const key = `${granularity}_${dateVal}`;
		let res = this.fmtDateLookup[key];
		if (res) {
			return res;
		}
		const date = new Date(dateVal);
		const { report } = reportData;
		const perday = (d) => moment.utc(d).format(report.dateFormat ? report.dateFormat : 'YYYY-MM-DD');
		const dateConverters = {
			perday,
			perweek: (d) => {
				const weekNr = moment.utc(d).locale('sv-SE').format('w');
				let year = d.getFullYear();
				if (weekNr === '1' && d.getMonth() >= 11) {
					year += 1;
				}
				if (weekNr === '53' && d.getDate() < 7) {
					year -= 1;
				}
				return `${year} Week ${weekNr}`;
			},
			permonth: (d) => moment.utc(d).format('MMMM YYYY'),
			peryear: (d) => d.getUTCFullYear().toString(),
			perhour: (d) => `${perday(d)} ${fm2(d.getUTCHours())}`,
			per10min: (d) => `${perday(d)} ${fm2(d.getUTCHours())}:${fm2(d.getUTCMinutes())}`,
		};
		res = dateConverters[granularity](date);
		this.fmtDateLookup[key] = res;
		if (!this.dateLookup[res]) {
			this.dateLookup[res] = date.getTime();
		}
		return res;
	}

	// "Fully" means that, for example - if the report is by *month* between 2023-09 => 2023-12 and date is
	// 2023-12-30, then the result will be 0.75, as 3/4 of the months in the report is "fully" before that date
	// (as December is not)
	getFullyBeforePerc(date) {
		const { granularity } = this;
		const { end } = this.getStartEnd();
		const shortMs = Constants.SHORT_TIME_RANGES[granularity]?.ms;
		const all = this.getStartDatesOfDateLabels();
		const last = _.last(all);
		const afterEnd = shortMs ? new Date(last.getTime() + shortMs) : DateUtils.fullDay(end, 1);
		let idx = _.findIndex(all, (d) => d >= date);
		if (idx < 0) {
			if (date < afterEnd) {
				idx = all.length - 1; // last range contains date
			} else {
				return 1; // date is after all
			}
		} else if (idx === 0) {
			return 0; // first range contained date (so nothing is *fully* before)
		} else if (all[idx] > date) {
			idx -= 1; // range before contained date
		}
		return idx / (all.length - (this.properties().isLineChart ? 1 : 0));
	}

	getClearBeforePerc(sum) {
		const { reportData } = this;
		const {
			calcSumsMap, report,
		} = reportData;
		const { clearBefore = {} } = report;
		const checkSums = calcSumsMap[sum]?.requires || [sum];
		const ts = _.max(checkSums.map((s) => clearBefore[s] || 0));
		if (!ts) {
			return 0;
		}
		const { start } = this.getStartEnd();
		if (start.getTime() >= ts) {
			return 0;
		}
		const res = this.getFullyBeforePerc(new Date(ts));
		if (res !== 0 && res !== 1 && !this.properties().dateInChart) {
			return 0; // show nothing in non-date-charts when they are just partially empty
		}
		return res;
	}

	getStartDatesOfDateLabels() {
		let prev;
		const res = [];
		this.getDates().forEach((date) => {
			const formatted = this.fmtDate(date);
			if (prev !== formatted) {
				res.push(date);
				prev = formatted;
			}
		});
		return res;
	}

	getAsForecastedPerc() {
		const {
			groupBy, start, end,
		} = this.reportData.settings;
		const { granularity } = this;
		const { firstForecastDate } = this.reportData.report;
		if (!firstForecastDate) {
			return 0;
		}
		if (!this.properties().dateInChart) {
			return 1;
		}
		const fstForecast = DateUtils.fullDay(firstForecastDate);
		const fstAll = DateUtils.toDate(start);
		const lastAll = DateUtils.toDate(end);
		if (fstForecast > lastAll) {
			return 0;
		}
		if (fstForecast <= fstAll) {
			return 1;
		}
		let normalDays;
		let totalDays;
		if (granularity !== 'perday') {
			const all = _.uniq(DateUtils.dates(fstAll, lastAll).map((d) => this.fmtDate(d)));
			totalDays = all.length;
			normalDays = all.indexOf(this.fmtDate(fstForecast));
		} else {
			normalDays = moment.utc(fstForecast).diff(fstAll, 'days');
			totalDays = moment.utc(lastAll).diff(fstAll, 'days') + 1;
		}
		const extra = groupBy.length >= 2 ? 0.5 : 0;
		return 1 - ((normalDays - extra) / (totalDays - (extra * 2)));
	}

	getLabelTotals(fullArr, levels) {
		const res = {};
		const { groupBy } = this.reportData.settings;
		const defSums = this.defaultSums();
		const addTotals = (arr, idx) => {
			const type = groupBy[idx];
			res[type] = res[type] || {};
			const typeDst = res[type];
			arr.forEach((elm) => {
				const { label } = elm;
				typeDst[label] = typeDst[label] || _.clone(defSums);
				const valDst = typeDst[label];
				const data = elm.totals || elm.data;
				Object.keys(defSums).forEach((key) => {
					valDst[key] += data[key];
				});
				if (idx < levels - 1) {
					addTotals(elm.data, idx + 1);
				}
			});
		};
		addTotals(fullArr, 0);
		return res;
	}

	toArr(obj, idx, levels) {
		const { allDataSums } = this;
		const { groupBy } = this.reportData.settings;
		const isMaxLevel = idx === levels - 1;
		let arr = Object.entries(obj).map(([label, value]) => {
			const data = isMaxLevel ? value : this.toArr(value, idx + 1, levels);
			const res = { label, data };
			if (!isMaxLevel) {
				res.totals = this.defaultSums();
				data.forEach((d) => {
					const src = d.totals || d.data;
					allDataSums.forEach((type) => {
						res.totals[type] += src[type];
					});
				});
			}
			return res;
		});
		if (groupBy[idx] === 'date') {
			arr.sort((e1, e2) => this.dateLookup[e1.label] - this.dateLookup[e2.label]);
		} else if (this.IS_BID_RANGE[groupBy[idx]]) {
			arr = _.sortBy(arr, (e) => bidRangeToVal(e.label));
		} else {
			arr = EnvReportInterface.MiscUtils.alphaSorted(arr, 'label');
		}
		return arr;
	}

	formatReport(maxLevels, asTableFormatted) {
		const { reportData, triggerFilter } = this;
		const { report } = reportData;
		const { groupBy } = reportData.settings;

		const formatLevel = (obj, idx) => {
			const res = {};
			const type = groupBy[idx];
			const isDate = type === 'date';
			const isLeafLevel = idx === groupBy.length - 1;
			const isMaxLevel = idx === maxLevels - 1;
			Object.keys(obj).forEach((key) => {
				let value;
				if (isLeafLevel) {
					value = _.clone(obj[key]); // { revenue: 123, impressions: 12345, ...}
					if (triggerFilter) {
						value.failures = triggerFilter.isFailure(value) ? 1 : 0;
					}
				} else {
					value = formatLevel(obj[key], idx + 1);
					if (isMaxLevel) {
						value = this.summarizeNumbers(value);
					}
				}
				let label;
				if (isDate) {
					label = this.fmtDate(key);
					if (res[label]) { // merge per week, month or year
						value = _.mergeWith(res[label], value, (dst, src) => (_.isNumber(dst) ? dst + src : undefined));
					}
				} else {
					label = report.labels[type][key];
				}
				res[label] = value;
			});
			if (isDate) { // add *all* dates
				this.getDates().forEach((date) => {
					const label = this.fmtDate(date);
					if (!res[label]) {
						res[label] = isMaxLevel ? this.defaultSums() : {};
					}
				});
			}
			return res;
		};

		const objData = formatLevel(report.data, 0);
		let arrData = this.toArr(objData, 0, maxLevels);
		if (asTableFormatted) {
			const formatter = new ReportCalculatorTableFormatter(this);
			arrData = formatter.getTableFormatted(arrData);
		}
		return { obj: objData, arr: arrData, levels: maxLevels };
	}

	trimExcessLabels(fullObj, fullArr, levels, sumVal, maxCount, labels) {
		const { calcSumsMap } = this.reportData;
		const { groupBy } = this.reportData.settings;
		if (calcSumsMap[sumVal]) {
			// If available, we should use "another" metric to trim away the smallest values
			sumVal = calcSumsMap[sumVal].trimBy || sumVal;
		}
		const OTHER_LABEL = '**Other**';
		let labelTotals;
		const survivors = {};
		const newLabels = {};
		let initArr = fullArr;
		groupBy.slice(0, levels).forEach((type) => {
			if (!labelTotals) { // ok we really need to do something here
				initArr = _.cloneDeep(initArr);
				labelTotals = this.getLabelTotals(initArr, levels);
			}
			const myTotals = labelTotals[type] || {};
			const myLabels = labels[type].filter((l) => myTotals[l]);
			newLabels[type] = myLabels;
			if (type === 'date' || myLabels.length <= maxCount) {
				return;
			}
			const surviveArr = myLabels
				.slice()
				.sort((a, b) => myTotals[b][sumVal] - myTotals[a][sumVal])
				.slice(0, maxCount);
			survivors[type] = _.zipObject(surviveArr, Array(surviveArr.length).fill(true));
			newLabels[type] = myLabels
				.filter((label) => survivors[type][label])
				.concat([OTHER_LABEL]);
		});
		if (_.isEmpty(survivors)) {
			return { arr: initArr, labels }; // nothing needed to be done;
		}
		const trimLevel = (obj, arr, idx) => {
			const type = groupBy[idx];
			const mySurvivors = survivors[type];
			const survived = [];
			const died = [];
			const isMaxLevel = idx === levels - 1;
			arr.forEach((elm) => {
				if (!mySurvivors || mySurvivors[elm.label]) {
					survived.push(elm);
					if (!isMaxLevel) {
						elm.data = trimLevel(obj[elm.label], elm.data, idx + 1);
					}
				} else {
					died.push(elm);
				}
			});
			if (!died.length) {
				return arr;
			}
			const otherObj = {};
			died.forEach((dead) => {
				_.mergeWith(otherObj, obj[dead.label], (dst, src) => (_.isNumber(dst) ? dst + src : undefined));
			});
			const otherData = isMaxLevel ? otherObj : this.toArr(otherObj, idx + 1, levels);
			survived.push({ label: OTHER_LABEL, data: otherData });
			return survived;
		};
		const arr = trimLevel(fullObj, initArr, 0);
		return { arr, labels: newLabels };
	}

	defaultSums() {
		const { allDataSums } = this;
		return _.zipObject(allDataSums, Array(allDataSums.length).fill(0));
	}

	summarizeNumbers(obj) {
		const res = this.defaultSums();
		const sum = (o) => {
			_.forOwn(o, (val, key) => {
				if (_.isNumber(val)) {
					res[key] += val;
				} else if (_.isPlainObject(val)) {
					sum(val);
				}
			});
		};
		sum(obj || {});
		return res;
	}

	getTotals(array) {
		const { allDataSums } = this;
		const totals = _.zipObject(allDataSums, Array(allDataSums.length).fill(0));
		array.forEach((element) => {
			allDataSums.forEach((sumVal) => {
				totals[sumVal] += (element.totals || element.data)[sumVal];
			});
		});
		return totals;
	}

	isFilteringByImportance() {
		const { importanceTabPerc } = this;
		return (Boolean(importanceTabPerc))
			&& (importanceTabPerc > 0)
			&& (importanceTabPerc < 100);
	}

	reportToCsv() {
		const tabFormattedData = this.formatReport(this.settings.groupBy.length, true);
		const { GROUP_BY_OPTIONS } = this.constants;
		const groupByOptions = this.reportData.customizer
			.getGroupByOptions({ GROUP_BY_OPTIONS }, this.reportData.report);

		const { groupBy, attributes } = this.settings;
		const {
			report,
			attribsByLabel,
		} = this.reportData;

		const { attributeMappings } = report;

		const sumInfosByKey = _.keyBy(this.getAllSumInfos(), 'key');
		const dimToAttribs = {};
		const csvCols = {};

		groupBy.forEach((dim) => {
			csvCols[dim] = groupByOptions[dim] || 'Unknown';
			const attribs = [];
			const mappings = attributeMappings[dim];
			_.keys(_.pickBy((attributes || {})[dim])).forEach((attrib) => {
				const desc = mappings[attrib];
				if (desc) {
					const csvKey = `${dim}_${attrib}`;
					attribs.push({ name: attrib, csvKey });
					csvCols[csvKey] = desc;
				}
			});
			if (attribs.length) {
				dimToAttribs[dim] = { attribs, byLabel: attribsByLabel[dim] || {} };
			}
		});

		Object.assign(csvCols, _.mapValues(sumInfosByKey, ({ name }) => name));
		const csvData = _.cloneDeepWith(
			csvExport.getDenseArray(
				tabFormattedData.arr,
				tabFormattedData,
				this.calculateSumVal,
				groupBy,
				sumInfosByKey,
				dimToAttribs,
			),
			(v) => (_.isNumber(v) ? v.toFixed(Math.round(v) === v ? 0 : 2) : undefined),
		);
		return createCsv(csvData, csvCols);
	}

	getAllSumInfos() {
		const { sums, useCompareTo } = this.reportData.settings;
		const sumInfo = [];
		const { SUM_DESCRIPTIONS, SUM_DESCRIPTION_ABBREVIATIONS } = this.constants;
		sums.forEach((sum) => {
			const name = SUM_DESCRIPTIONS[sum];
			const abbr = SUM_DESCRIPTION_ABBREVIATIONS?.[sum] || null;
			const singleInfo = {
				sum,
				key: sum,
				name,
				abbr,
			};
			sumInfo.push(singleInfo);
			if (useCompareTo) {
				sumInfo.push({
					sum,
					isComp: true,
					key: `${COMP_PFX}${sum}`,
					name: `${name}*`,
					abbr: abbr && `${abbr}*`,
					orgSumInfo: singleInfo,
				});
			}
		});
		return sumInfo;
	}
}

module.exports = ReportCalculator;
