const isNumCh = (c) => c >= 48 && c <= 57;

const split = (str) => {
	const s = str.toLowerCase();
	let startIdx = -1;
	const res = [];
	let i;
	const add = () => startIdx >= 0 && res.push(s.slice(startIdx, i));
	let prevC = 0;
	for (i = 0; i < s.length; i += 1) {
		const c = s.charCodeAt(i);
		const isNum = isNumCh(c);
		if (c > 127 || (c >= 97 && c <= 122) || isNum) {
			if (isNum !== isNumCh(prevC)) {
				add();
				startIdx = i;
			} else {
				startIdx = startIdx < 0 ? i : startIdx;
			}
		} else {
			add();
			startIdx = -1;
		}
		prevC = c;
	}
	add();
	return res;
};

const MIN_SEARCH_START = 0;

let checkGeneration = 0;

function createFilterFnOptions(options) {
	const byFull = {};
	const byStart = {};
	options.forEach((option) => {
		const words = split(option.path);
		const obj = { option, counter: 0 };
		const seenStarts = {};
		const seenFull = {};
		words.forEach((word) => {
			if (seenFull[word]) {
				return;
			}
			seenFull[word] = true;
			byFull[word] = byFull[word] || [];
			byFull[word].push(obj);
			for (let i = 1; i <= word.length; i += 1) {
				const startOfWord = word.slice(0, i);
				if (!seenStarts[startOfWord]) {
					seenStarts[startOfWord] = true;
					byStart[startOfWord] = byStart[startOfWord] || [];
					byStart[startOfWord].push(obj);
				}
			}
		});
	});
	return { byFull, byStart };
}

function filterOptions(str, { byFull, byStart }) {
	const words = split(str);
	if (!words.length || (words.length === 1 && words[0].length < MIN_SEARCH_START)) {
		return [];
	}
	const lastWordMapping = str[str.length - 1] === ' ' ? byFull : byStart;
	if (words.length === 1) {
		return (lastWordMapping[words[0]] || []).map(({ option }) => option);
	}
	checkGeneration += 1;
	const start = checkGeneration * 1024;
	const expected = start + words.length - 2;
	(byFull[words[0]] || []).forEach((obj) => {
		obj.counter = start;
	});
	for (let i = 1; i < words.length - 1; i += 1) {
		(byFull[words[i]] || []).forEach((obj) => {
			obj.counter += 1;
		});
	}
	const res = [];
	(lastWordMapping[words[words.length - 1]] || []).forEach(({ counter, option }) => {
		if (counter === expected) {
			res.push(option);
		}
	});
	return res;
}

export {
	createFilterFnOptions,
	filterOptions,
};
