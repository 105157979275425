const _ = require('lodash');
const TypeCustomizer = require('./typeCustomizer');
const Constants = require('../constants');
const DateUtils = require('../../misc/dateUtils');

class LogCustomizer extends TypeCustomizer {
	getInitSums() {
		return ['revenue'];
	}

	transformedReportParams(params) {
		const startTs = new Date(params.start).getTime();
		let endTs;
		if (DateUtils.fullDay(params.end) >= DateUtils.today()) {
			endTs = 0;
		} else {
			endTs = DateUtils.fullDay(params.end, 1).getTime();
		}
		const timeRange = Constants.SHORT_TIME_RANGES[params.granularity];
		return {
			...params,
			startTs,
			endTs,
			timeRangeMs: timeRange ? timeRange.ms : 0,
			sspId: this.reportData.getConstants().logTypeSettings.id,
		};
	}

	transformedReportResult(res) {
		const { reportMultiplier } = this.reportData.getConstants().logTypeSettings;
		if (!reportMultiplier || reportMultiplier === 1) {
			return res;
		}
		res.data = _.cloneDeepWith(res.data, (val) => (_.isNumber(val) ? val * reportMultiplier : undefined));
		return res;
	}

	supportedGranularities() {
		return _.keys(Constants.SHORT_TIME_RANGES).concat(super.supportedGranularities());
	}

	siteSelectType() {
		return 'programmatic';
	}

	normalizeDate(dateStr) {
		return new Date(parseInt(dateStr, 10));
	}

	hasDataForToday() {
		return false; // We should probably support that
	}
}

module.exports = LogCustomizer;
