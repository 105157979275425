import React from 'react';
import PropTypes from 'prop-types';
import ActionOpenInNew from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import BrowserUtils from '../../lib/browserUtils';
import { stores } from '../../stores';

class ReportAsUrl extends React.Component {
	constructor(props) {
		super(props);
		this.state = { to: 'route' };
	}

	updateUrl = (callback) => {
		const parentState = this.props.getUserAdjustedProperties();
		const url = BrowserUtils.makeQs(this.props.reportLoc, {
			settings: btoa(JSON.stringify(parentState)),
			admin: stores.identity.isAdministrator(),
		});
		if (callback) {
			callback(url);
		}
		this.setState({ to: url });
	};

	render() {
		return (
			<Tooltip title="Open in new tab">
				<IconButton
					href={this.state.to}
					target="_blank"
					onMouseEnter={() => this.updateUrl()}
					onFocus={() => this.updateUrl()}
					onClick={(event) => {
						event.preventDefault();
						this.updateUrl((url) => window.open(url));
					}}
					disableTouchRipple
					size="large"
				>
					<ActionOpenInNew />
				</IconButton>
			</Tooltip>
		);
	}
}

ReportAsUrl.propTypes = {
	getUserAdjustedProperties: PropTypes.func.isRequired,
	reportLoc: PropTypes.string.isRequired,
};

ReportAsUrl.defaultProps = {
	getCurrentProperties: () => {},
	reportLoc: 'foo',
};

export default ReportAsUrl;
