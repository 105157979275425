import _ from 'lodash';
import moment from 'moment';
import { availReportTypes } from 'relevant-shared/reportData/reportType';
import { Reports } from '../../api/relevant';

/**
 * Get all reports (API, HB, Historical, Audience, Log) that are available to the user.
 */
export async function getAvailableReports() {
	const data = {};
	const reports = _.groupBy(await Reports.call('listReports'), (r) => r.settings.type);
	_.map(availReportTypes, 'type').forEach((type) => {
		const reportsForType = reports[type] || [];
		data[type] = reportsForType.sort((a, b) => {
			if (a.createdDate === b.createdDate) {
				return 0;
			}
			if (!a.createdDate) {
				return 1;
			}
			if (!b.createdDate) {
				return -1;
			}
			return moment(b.createdDate).unix() - moment(a.createdDate).unix();
		});
	});

	return data;
}

export function getAvailableReportTypes() {
	return [...availReportTypes];
}
