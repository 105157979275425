import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import styles from './styles.css';

function CollapseHeader({
	children, expanded, onClick, title, variant, styles: st,
}) {
	// TODO: Delete first branch of this conditional once we
	//  stop putting buttons and links inside this element
	return children ? (
		<div className={styles.container} style={st?.container}>
			<div
				role="button"
				tabIndex={0}
				className={`${styles.button} ${styles.mainClickable}`}
				onClick={onClick}
				aria-label={`${title} - click to ${expanded ? 'collapse' : 'expand'} content`}
				style={st?.buttonDiv}
			>
				<Typography variant={variant}>
					{title}
				</Typography>
			</div>
			{children}
			<button
				type="button"
				className={styles.button}
				onClick={onClick}
				aria-hidden
			>
				{expanded ? <ExpandLess /> : <ExpandMore />}
			</button>
		</div>
	) : (
		<div
			role="button"
			tabIndex={0}
			className={`${styles.button} ${styles.container}`}
			onClick={onClick}
			aria-label={`${title} - click to ${expanded ? 'collapse' : 'expand'} content`}
			style={st?.buttonDiv}
		>
			<Typography variant={variant} className={styles.mainClickable}>
				{title}
			</Typography>
			{expanded ? <ExpandLess /> : <ExpandMore />}
		</div>
	);
}

CollapseHeader.propTypes = {
	children: PropTypes.node,
	expanded: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
	title: PropTypes.node.isRequired,
	variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
	styles: PropTypes.object,
};

CollapseHeader.defaultProps = {
	children: null,
	styles: undefined,
};

export default CollapseHeader;
