import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';

const ResponsiveHeader = styled(Typography)(({ theme, color }) => ({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	[theme.breakpoints.up('lg')]: {
		maxWidth: theme.spacing(56),
	},
	[theme.breakpoints.down('lg')]: {
		maxWidth: theme.spacing(38),
	},
	[theme.breakpoints.down(1018)]: {
		maxWidth: theme.spacing(33),
	},
	[theme.breakpoints.down(978)]: {
		maxWidth: theme.spacing(23),
	},
	[theme.breakpoints.down('sm')]: {
		maxWidth: theme.spacing(19),
	},
}));
export default function TopBarHeader({ title, icon, color }) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{
				display: 'flex',
				alignItems: 'center',
				mr: 1,
				color: 'primary.tonedDownText',
			}}
			>
				{icon}
			</Box>
			<ResponsiveHeader
				variant="h6"
				noWrap
				component="div"
				color={color}
			>
				{title}
			</ResponsiveHeader>
		</Box>
	);
}

TopBarHeader.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.node,
	color: PropTypes.string,
};

TopBarHeader.defaultProps = {
	title: '',
	icon: null,
	color: 'primary.contrastText',
};
