import { useEffect, useState } from 'react';

/**
 * Delay a boolean from changing value
 */
export default function useDelay(bool, seconds) {
	const [state, setState] = useState(bool);
	useEffect(() => {
		const timeout = setTimeout(() => {
			setState(bool);
		}, seconds * 1000);

		return () => clearTimeout(timeout);
	}, [bool]);

	return state;
}
