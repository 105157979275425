const _ = require('lodash');
const moment = require('moment');
const AllCustomizers = require('relevant-shared/reportData/customizers/allCustomizers');
const DateUtils = require('../misc/dateUtils');
const { getDateRangeBoundaries } = require('./dynamicDateRanges');

const comparisonDiff = (startDate, now, key) => {
	const s = moment(startDate).subtract(1, key);
	return s.diff(moment(now), 'days');
};

const COMPARISON_TYPES = {
	YEAR_ON_YEAR: {
		key: 'y1',
		label: 'Compare Year on Year',
		fn: (startDate, now) => comparisonDiff(startDate, now, 'y'),
	},
	MONTH_ON_MONTH: {
		key: 'm1',
		label: 'Compare Month on Month',
		fn: (startDate, now) => comparisonDiff(startDate, now, 'months'),
	},
};

/** change order of report data (use-case is to move date first) */
const rearranged = (obj, fromLevel, toLevel, levels) => {
	const res = {};
	const path = [];
	let positions;
	const useSwapArrays = _.isArray(fromLevel);
	const throwIf = (cond) => {
		if (cond) {
			throw Error('Invalid arguments to rearranged()');
		}
	};
	throwIf(useSwapArrays !== _.isArray(toLevel));
	if (useSwapArrays) {
		throwIf(fromLevel.length !== toLevel.length || fromLevel.length !== levels);
		positions = toLevel.map((dim) => fromLevel.indexOf(dim));
		throwIf(positions.includes(-1));
	} else {
		positions = _.range(levels);
		positions[fromLevel] = toLevel;
		positions[toLevel] = fromLevel;
	}
	const run = (idx, currObj) => {
		const isFinal = levels - 1 === idx;
		_.forOwn(currObj, (val, key) => {
			path[idx] = key;
			if (isFinal) {
				let dst = res;
				for (let i = 0; i < levels; i += 1) {
					const k = path[positions[i]];
					if (!dst[k]) {
						dst[k] = i === levels - 1 ? val : {};
					}
					dst = dst[k];
				}
			} else {
				run(idx + 1, val);
			}
		});
	};
	run(0, obj);
	return res;
};

const dateNormalized = (objByDate, start, end, empty) => {
	const res = {};
	_.forOwn(objByDate, (val, key) => {
		res[DateUtils.fullDay(key)] = val;
	});
	DateUtils.dates(start, end).forEach((date) => {
		if (!res[date]) {
			res[date] = { ...empty };
		}
	});
	return res;
};

const getCompareToRange = (state, nowDate) => {
	const {
		start, end, compareTo, reportData, type, timezone,
	} = state;
	const now = DateUtils.timezoneOffsetDate(nowDate, timezone);
	const customizer = reportData?.customizer || AllCustomizers.createByType(type);
	const canSelectBehind = customizer ? customizer.canSelectBehind() : true;
	const canSelectAhead = state.useForecast || customizer?.canSelectAhead();
	const today = DateUtils.fullDay(now || new Date());
	const startDate = DateUtils.toDate(start, now);
	const endDate = DateUtils.toDate(end, now);
	const rangeLen = moment.utc(endDate).diff(startDate, 'days');
	let compStart = compareTo;
	const compType = _.values(COMPARISON_TYPES).find((t) => t.key === compStart);
	if (compType) {
		compStart = compType.fn(startDate, now);
	}
	const hasCompStart = compStart || compStart === 0;
	if (!canSelectBehind && canSelectAhead) {
		if (hasCompStart) {
			if (DateUtils.isRelativeDate(compStart)) {
				compStart = Math.max(compStart, 0);
			} else {
				compStart = new Date(Math.max(compStart, today));
			}
		} else {
			compStart = 0;
		}
		const compStartDate = DateUtils.toDate(compStart, now);
		return {
			compareStart: compStart,
			start: compStartDate,
			end: DateUtils.fullDay(compStartDate, rangeLen),
		};
	}
	if (!hasCompStart) {
		const dowDiff = (startDate.getDay() - endDate.getDay()) + (endDate.getDay() >= startDate.getDay() ? 7 : 0);
		compStart = DateUtils.fullDay(startDate, -(rangeLen + dowDiff));
	}
	let compStartDate = DateUtils.toDate(compStart, now);
	const maxStartDaysBack = -(rangeLen + 1);
	const maxStart = DateUtils.fullDay(today, maxStartDaysBack);
	if (compStartDate > maxStart) {
		compStart = _.isNumber(compStart) ? maxStartDaysBack : maxStart;
		compStartDate = DateUtils.toDate(compStart, now);
	}
	return {
		compareStart: compStart,
		start: compStartDate,
		end: DateUtils.fullDay(compStartDate, rangeLen),
		maxStart,
	};
};

const getValidStartEnd = ({
	dynamicDateRange,
	start,
	end,
	type,
	timezone,
}, nowDate = new Date()) => {
	let res = { start, end };
	const allowToday = AllCustomizers.createByType(type).hasDataForToday();
	if (dynamicDateRange) {
		const offsetMs = DateUtils.getTimezoneOffset({ timezone, timestamp: nowDate });
		const now = new Date(nowDate.getTime() + offsetMs);
		const converted = getDateRangeBoundaries(dynamicDateRange, now, allowToday);
		if (!_.isEmpty(converted)) {
			res = converted;
		}
	} else {
		['start', 'end'].forEach((dateVar) => {
			if (!DateUtils.isRelativeDate(res[dateVar])) {
				res[dateVar] = DateUtils.fullDay(res[dateVar]);
			}
		});
	}
	return res;
};

const sizeToLabel = (size) => {
	const sz = parseInt(size, 10);
	if (!sz || sz < 0 || sz >= 2 ** 32) {
		return '[Unknown]';
	}
	return `${Math.floor(sz / 65536)}x${Math.floor(sz % 65536)}`;
};

const getChartGroupBy = (groupBy) => {
	if (groupBy.length <= 1) {
		return groupBy;
	}
	if (!groupBy.includes('date')) {
		return [groupBy[1], groupBy[0], ...groupBy.slice(2)];
	}
	const dateIndex = groupBy.indexOf('date');
	if (dateIndex === 1) {
		return groupBy;
	}
	const groupByNoDate = groupBy.filter((element) => element !== 'date');
	return [groupByNoDate[0], 'date', ...groupByNoDate.slice(1)];
};

function makeRelativeDates(settings) {
	const newSettings = _.cloneDeep(settings);
	['start', 'end', 'compareTo'].forEach((dateVar) => {
		const val = (newSettings ?? {})[dateVar];
		if (val && !DateUtils.isRelativeDate(val) && !Number.isNaN(new Date(val).getTime())) {
			const then = DateUtils.fullDay(val);
			const ago = moment.utc(DateUtils.today()).diff(then, 'days');
			newSettings[dateVar] = -ago;
		}
	});
	return newSettings;
}

const iterateReportData = (data, groupBy, fn) => {
	const param = {};
	const run = (obj, level) => {
		_.forOwn(obj, (v, k) => {
			param[groupBy[level]] = k;
			if (level === groupBy.length - 1) {
				fn(v, param, obj, k);
			} else {
				run(v, level + 1);
			}
		});
	};
	run(data, 0);
};

module.exports = {
	getCompareToRange,
	rearranged,
	dateNormalized,
	getValidStartEnd,
	sizeToLabel,
	getChartGroupBy,
	COMPARISON_TYPES,
	makeRelativeDates,
	iterateReportData,
};
