import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, IconButton, Tooltip } from '@mui/material';
import SortButton from './SortButton';
import useFilters from '../context/hooks/useFilters';

function Sort() {
	const {
		sortProperty,
		sortDirectionAscending,
		onSortDirectionAscendingChange,
		onSortPropertyChange,
	} = useFilters();

	return (
		<Box sx={{ alignItems: 'center' }}>
			<SortButton
				value={sortProperty}
				onChange={(value) => onSortPropertyChange(value)}
			/>
			<Tooltip title="Reverse sort direction">
				<IconButton
					size="small"
					onClick={onSortDirectionAscendingChange}
				>
					{ sortDirectionAscending ? (
						<ArrowUpwardIcon sx={{ color: 'text.secondary' }} />
					) : (
						<ArrowDownwardIcon sx={{ color: 'text.secondary' }} />
					)}

				</IconButton>
			</Tooltip>
		</Box>
	);
}

export default Sort;
