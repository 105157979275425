/* eslint-disable object-shorthand */
const _ = require('lodash');
const { AllDimensions } = require('../mappingDimensions/allDimensionArrays');
const { FlagDimsByName } = require('./flagDimensions');
const { PREBID_CONFIGURATION_DIM, AS_FLAGS } = require('../misc/sharedConstants');
const { FUTURE_RESERVED, FUTURE_FORECASTED } = require('./futureConstants');
const { toOptimizeMetricName } = require('../optimize/constants');
const DateUtils = require('../misc/dateUtils');
const EnvReportInterface = require('./envReportInterface');
const ReportType = require('./reportType');
const { percentChange } = require('../misc/misc');
const AllSeqObjectDimensions = require('../mappingDimensions/allSeqObjectDimensions');

const expandSums = (p, serverSums) => ([
	...serverSums,
	..._.keys(_.pickBy(p.CALCULATED_SUMS, ({ requires }) => (
		_.intersection(requires, serverSums).length
	))),
]);

const invalidAsSumFn = (type, invalidCombos) => {
	const invalidArr = Object.entries(invalidCombos);
	return ({ groupBy }, sumVal) => {
		// eslint-disable-next-line no-use-before-define
		const calcSum = Constants[type].CALCULATED_SUMS?.[sumVal];
		return !!invalidArr.find(([invalidSum, whenHasDims]) => (
			(sumVal === invalidSum || calcSum?.requires.includes(invalidSum))
				&& groupBy.some((s) => whenHasDims.includes(s))
		));
	};
};

const limitSums = (p, forbidden) => _.keys(_.omit(p.SUM_DESCRIPTIONS, expandSums(p, forbidden)));

const HB_PER_PLACEMENT_DIMS = ['placementId', 'floorRange'];
const HB_PER_BID_DIMS = ['bidder', 'sourceId', 'size', ...Object.keys(FlagDimsByName)];

const Constants = {
	UI_ORDER: ['programmatic', 'hb', 'summary_hb', 'future'], // others will occur after

	audience: {
		DESCRIPTION: 'Audience',
		API_FN: 'reportAudience',
		product: 'audience',
		availableCheck: ({ disableAudience }) => !disableAudience,

		SUM_DESCRIPTIONS: {
			revenue: 'Total revenue',
			relevantRevenue: 'Network revenue',
			dataProviderRevenue: 'Data provider revenue',
			nonApproved: 'Non-approved revenue',
			impressions: 'Impressions',
		},

		SUM_DESCRIPTION_ABBREVIATIONS: {
			revenue: 'Tot. rev.',
			relevantRevenue: 'Netw. rev.',
			dataProviderRevenue: 'Data provider rev.',
			nonApproved: 'Non-approved rev.',
			impressions: 'Imp.',
		},

		GROUP_BY_OPTIONS: {
			date: 'Date',
			segmentDmpId: 'Segment',
			cxSiteId: 'Site',
			publisherId: 'Publisher',
			dsp: 'DSP',
		},
	},

	hb: {
		DESCRIPTION: 'HB Analytics',
		API_FN: 'reportHb',
		availableCheck: ({ isAdministrator, hbaEnabled, userReportOptions }) => hbaEnabled && (
			isAdministrator || userReportOptions.hb.USER_OTHER_OPTIONS.includes('hbaEnabledForNormalUsers')),

		OTHER_OPTIONS: {
			hbaEnabledForNormalUsers: 'HB reports available for normal users',
		},

		SUM_DESCRIPTIONS: {
			revenue: 'Revenue',
			pageViews: 'Auctions',
			adserverIn: 'Ad unit requests',
			renderSuccess: 'Impressions',
			bidWon: 'Winning bids',
			ecpm: 'eCPM',
			adUnitEcpm: 'Ad unit eCPM',
			pvEcpm: 'Auction eCPM',
			fillRate: 'Fill rate %',
			bidEcpm: 'Bid eCPM',
			bids: 'Bid requests',
			bidRatePerc: 'Bid rate %',
			bidVolume: 'Total bid revenue',
			unloadBeforeResponse: 'Cancelled bids',
			avgResponseMs: 'Avg. response ms',
			avgRenderMs: 'Avg. render ms',
			timedOut: 'Timed out bids',
			timedOutPerc: 'Timed out bids %',
			noBid: 'No bid responses',
			isApproximation: 'Is approximation',
			bidResponse: 'Bid responses',
			renderFailed: 'Failed renderings',
			uniqueBidRevenue: 'Unique HB bid revenue',
			incrementalBidRevenue: 'Incremental HB bid revenue',
			lostToAdserverRevenue: 'Lost revenue to adserver',
			bidDensityPerc: 'Bid density %',
			winRatePerc: 'Win rate %',
			isAdserverBid: 'Adserver bids',
		},

		SUM_DESCRIPTION_ABBREVIATIONS: {
			revenue: 'Rev.',
			pageViews: 'Auctions',
			adserverIn: 'Ad unit req.',
			renderSuccess: 'Imp.',
			bidWon: 'Win. bids',
			ecpm: 'eCPM',
			adUnitEcpm: 'Ad unit eCPM',
			pvEcpm: 'Auction eCPM',
			fillRate: 'Fill rate %',
			bidEcpm: 'Bid eCPM',
			bids: 'Bid req.',
			bidRatePerc: 'Bid rate %',
			bidVolume: 'Bid rev.',
			unloadBeforeResponse: 'Cancelled bids',
			avgResponseMs: 'Avg. res. ms',
			avgRenderMs: 'Avg. render ms',
			timedOut: 'Timed out bids',
			timedOutPerc: 'Timed out bids %',
			noBid: 'No bid res.',
			bidResponse: 'Bid res.',
			renderFailed: 'Failed renderings',
			uniqueBidRevenue: 'Uniq. rev',
			incrementalBidRevenue: 'Inc. rev.',
			lostToAdserverRevenue: 'Ads rev.',
			bidDensityPerc: 'Bid dens. %',
			winRatePerc: 'Win rate %',
			isDirect: 'Dir. Ads. rev.',
			isApproximation: 'Approx. Ads. rev.',
			isAdserverBid: 'Ads. bids',
		},

		GROUP_BY_OPTIONS: {
			date: 'Time period',
			publisherId: 'Publisher',
			siteId: 'Site',
			placementId: 'Placement',
			bidder: 'Bidder',
			sourceId: 'Source',
			bidRange: 'Bid range',
			floorRange: 'Floor range',
			size: 'Creative Size',
			..._.mapValues(FlagDimsByName, 'desc'),
			country: 'Country',
			[PREBID_CONFIGURATION_DIM]: 'Prebid configuration',
			/** All custom parameters will be added */
		},

		IS_INVALID_AS_TOTAL: invalidAsSumFn('hb', {
			pageViews: [...HB_PER_PLACEMENT_DIMS, ...HB_PER_BID_DIMS],
			adserverIn: HB_PER_BID_DIMS,
		}),

		CALCULATED_SUMS: {
			avgResponseMs: {
				requires: ['responseMs', 'bidResponse', 'noBid'],
				fn: (d) => (d.responseMs / (d.bidResponse + d.noBid)) || 0,
				trimBy: 'revenue',
			},
			avgRenderMs: {
				requires: ['renderMs', 'bidWon'],
				fn: (d) => (d.renderMs / d.bidWon) || 0,
				trimBy: 'revenue',
			},
			ecpm: {
				requires: ['revenue', 'renderSuccess'],
				fn: (d) => (d.renderSuccess ? (d.revenue * 1000) / d.renderSuccess : 0),
				trimBy: 'revenue',
			},
			fillRate: {
				requires: ['adserverIn', 'renderSuccess'],
				fn: (d) => Math.min(d.adserverIn ? (d.renderSuccess * 100) / d.adserverIn : 0, 100),
				trimBy: 'adserverIn',
			},
			bidEcpm: {
				requires: ['bidVolume', 'bidResponse'],
				fn: (d) => (d.bidResponse ? (d.bidVolume * 1000) / d.bidResponse : 0),
				trimBy: 'bidVolume',
			},
			timedOutPerc: {
				requires: ['bids', 'timedOut'],
				fn: (d) => (d.bids ? (d.timedOut / d.bids) : 0) * 100,
				trimBy: 'bids',
			},
			adUnitEcpm: {
				requires: ['adserverIn', 'revenue'],
				fn: (d) => (d.adserverIn ? (d.revenue * 1000) / d.adserverIn : 0),
				trimBy: 'revenue',
			},
			pvEcpm: {
				requires: ['pageViews', 'revenue'],
				fn: (d) => (d.pageViews ? (d.revenue * 1000) / d.pageViews : 0),
				trimBy: 'revenue',
			},
			bidRatePerc: {
				requires: ['bids', 'bidResponse'],
				fn: (d) => (d.bids ? (d.bidResponse / d.bids) : 0) * 100,
				trimBy: 'bids',
			},
			bidDensityPerc: {
				requires: ['adserverIn', 'bidResponse'],
				fn: (d) => (d.adserverIn ? (d.bidResponse / d.adserverIn) : 0) * 100,
				trimBy: 'adserverIn',
			},
			winRatePerc: {
				requires: ['renderSuccess', 'bidResponse'],
				fn: (d) => (d.bidResponse ? (d.renderSuccess / d.bidResponse) : 0) * 100,
				trimBy: 'bidResponse',
			},
		},

		getDefaultProperties: (isSavedReport) => ({
			granularity: 'per10min',
			start: isSavedReport ? DateUtils.fullDay(new Date(), -1) : -1,
			end: isSavedReport ? DateUtils.fullDay(new Date(), 0) : 0,
		}),
	},

	future: {
		DESCRIPTION: 'API Adserver Forecast',
		SHORT_DESCRIPTION: 'Adserver Forecast',
		API_FN: 'reportProgrammatic',
		availableCheck: ({ isAdministrator, futureEnabled, userReportOptions }) => futureEnabled && (
			isAdministrator
				|| userReportOptions.future.USER_OTHER_OPTIONS.includes('enabledForNormalUsers')
		),

		SUM_DESCRIPTIONS: {
			revenue: 'Revenue',
			[FUTURE_FORECASTED]: 'Forecasted inventory',
			[FUTURE_RESERVED]: 'Reserved impressions',
			/**
			 * Sell-through rate
			 * See CALCULATED_SUMS
			*/
			sellThroughPerc: 'Sell-through %',
		},

		SUM_DESCRIPTION_ABBREVIATIONS: {
			revenue: 'Rev.',
			[FUTURE_FORECASTED]: 'Fore. inv.',
			[FUTURE_RESERVED]: 'Res. imps.',
			sellThroughPerc: 'Sell. %',
		},

		GROUP_BY_OPTIONS: {
			date: 'Date',
			sspId: 'Adserver',
			publisherId: 'Publisher',
			siteId: 'Site',
			placementId: 'Placement',
			placementLabel: 'Placement label',
			sourceId: 'Source',
			userNr: 'Sales Rep.',
			..._.mapValues(_.keyBy(AllDimensions.filter((d) => d.futureEnabled), 'dimension'), 'uiCName'),
		},

		OTHER_OPTIONS: {
			enabledForNormalUsers: 'Future reports available for normal sales users',
		},

		getDefaultProperties: () => ({
			granularity: 'perday',
			start: 0,
			end: 89,
		}),

		OTHER_SETTINGS: {
			filters: {
				sspFilter: (ssp) => ssp.futureImporter,
			},
		},

		CALCULATED_SUMS: {
			sellThroughPerc: {
				requires: [FUTURE_RESERVED, FUTURE_FORECASTED],
				fn: (d) => Math.min(d[FUTURE_FORECASTED] ? (d[FUTURE_RESERVED] * 100) / d[FUTURE_FORECASTED] : 0, 100),
				trimBy: FUTURE_FORECASTED,
			},
		},
	},

	programmatic: {
		DESCRIPTION: 'API Insights',
		API_FN: 'reportProgrammatic',
		availableCheck: () => true,
		reportLocation: '/reports/api',
		CAN_FORECAST: true,

		SUM_DESCRIPTIONS: {
			revenueAfterSsp: 'Total revenue',
			revenue: 'API gross revenue',
			// sspCommission: 'SSP Commission',
			relevantRevenue: 'Network revenue',
			publisherRevenue: 'Publisher revenue',
			// systemWideCost: 'Adserver cost',
			adserverIn: 'Adserver in',
			// adserverOut: 'Adserver out',
			impressionsIn: 'Est. SSP in',
			// impressionsOut: 'SSP out',
			soldImpressions: 'Sold impressions',
			pageViews: 'Page views',
			ecpm: 'Total eCPM',
			publisherEcpm: 'Publisher eCPM',
			adserverEcpm: 'Adserver eCPM',
			pageViewsEcpm: 'Page views eCPM',
			pubPageViewsEcpm: 'Publisher Page views eCPM',
			fillRate: 'Fill rate %',
			sspFillRate: 'Est. SSP Fill %',
			relevantShare: 'Network rev-%',
			/** trend metrics */
			revenueChangeRev: 'Revenue change (rev)',
			revenueChangePerc: 'Revenue change (%)',
			efficiencyChangeRev: 'Efficiency change (rev)',
			efficiencyChangePerc: 'Efficiency change (%)',
			clicks: 'Clicks',
			ctr: 'Click-through rate %',
			ismPerc: 'Viewability %',
			ism: 'Viewable Imps',
			ismMeasured: 'Viewable measured',
		},

		SUM_DESCRIPTION_ABBREVIATIONS: {
			revenueAfterSsp: 'Tot. rev.',
			revenue: 'API GR',
			relevantRevenue: 'Netw. rev.',
			publisherRevenue: 'Pub. rev.',
			soldImpressions: 'Sold imp.',
			pageViews: 'PV',
			ecpm: 'Tot. eCPM',
			publisherEcpm: 'Pub. eCPM',
			adserverEcpm: 'Adserver eCPM',
			pageViewsEcpm: 'PV eCPM',
			pubPageViewsEcpm: 'PPV eCPM',
			fillRate: 'Fill rate %',
			sspFillRate: 'Est. SSP Fill %',
			relevantShare: 'Netw. rev-%',
			/** trend metrics */
			revenueChangeRev: 'Rev. change',
			revenueChangePerc: 'Rev. change-%',
			efficiencyChangeRev: 'Eff. change',
			efficiencyChangePerc: 'Eff. change-%',
			ctr: 'CTR %',
		},

		GROUP_BY_OPTIONS: {
			date: 'Date',
			sspId: 'SSP',
			publisherId: 'Publisher',
			siteId: 'Site',
			placementId: 'Placement',
			placementLabel: 'Placement label',
			sourceId: 'Source',
			revenueType: 'Revenue type',
			userNr: 'Sales Rep.',
			..._.mapValues(_.keyBy(AllDimensions.filter((d) => !d.hideAsDefault), 'dimension'), 'uiCName'),
		},

		NON_FORECAST_OPTIONS: [
			'userNr',
			..._.map(AllDimensions, 'dimension'),
		],

		NON_USER_OPTIONS: [
			'userNr', // TODO: this should be possible some day
		],

		REVENUE_TYPES: _.mapValues(FlagDimsByName.revenueType.valuesByName, 'desc'),

		CALCULATED_SUMS: {
			ecpm: {
				requires: ['revenueAfterSsp', 'soldImpressions'],
				fn: (d) => (d.soldImpressions ? (d.revenueAfterSsp * 1000) / d.soldImpressions : 0),
				trimBy: 'revenueAfterSsp',
			},
			publisherEcpm: {
				requires: ['publisherRevenue', 'soldImpressions'],
				fn: (d) => (d.soldImpressions ? (d.publisherRevenue * 1000) / d.soldImpressions : 0),
				trimBy: 'publisherRevenue',
			},
			ismPerc: {
				requires: ['ism', 'ismMeasured', 'soldImpressions'],
				fn: (d) => (d.ismMeasured ? Math.min((d.ism / d.ismMeasured) * 100, 100) : 0),
				trimBy: 'soldImpressions',
			},
			adserverEcpm: {
				requires: ['revenueAfterSsp', 'adserverIn'],
				fn: (d) => (d.adserverIn ? (d.revenueAfterSsp * 1000) / d.adserverIn : 0),
				trimBy: 'adserverIn',
			},
			pageViewsEcpm: {
				requires: ['revenueAfterSsp', 'pageViews'],
				fn: ({ revenueAfterSsp, pageViews }) => (pageViews ? (revenueAfterSsp * 1000) / pageViews : 0),
				trimBy: 'pageViews',
			},
			pubPageViewsEcpm: {
				requires: ['publisherRevenue', 'pageViews'],
				fn: ({ publisherRevenue, pageViews }) => (pageViews ? (publisherRevenue * 1000) / pageViews : 0),
				trimBy: 'pageViews',
			},
			fillRate: {
				requires: ['adserverIn', 'soldImpressions'],
				fn: (d) => Math.min(d.adserverIn ? (d.soldImpressions * 100) / d.adserverIn : 0, 100),
				trimBy: 'adserverIn',
			},
			ctr: {
				requires: ['soldImpressions', 'clicks'],
				fn: (d) => Math.min(d.soldImpressions ? (d.clicks * 100) / d.soldImpressions : 0, 100),
				trimBy: 'soldImpressions',
			},
			sspFillRate: {
				requires: ['impressionsIn', 'soldImpressions'],
				fn: (d) => Math.min(d.impressionsIn ? (d.soldImpressions * 100) / d.impressionsIn : 0, 100),
				trimBy: 'impressionsIn',
			},
			relevantShare: {
				requires: ['revenueAfterSsp', 'relevantRevenue'],
				fn: (d) => (d.revenueAfterSsp ? d.relevantRevenue / d.revenueAfterSsp : 0) * 100,
				trimBy: 'revenueAfterSsp',
			},
			efficiencyChangeRev: {
				requires: ['revenueAfterSsp', 'adserverIn'],
				trendMetric: {
					getVal: (d) => (d.adserverIn ? d.revenueAfterSsp / d.adserverIn : 0),
					diffVal: (oldD, newD) => (newD.val - oldD.val) * newD.adserverIn,
				},
				trimBy: 'trend_revenueAfterSsp',
			},
			efficiencyChangePerc: {
				requires: ['revenueAfterSsp', 'adserverIn'],
				trendMetric: {
					getVal: (d) => Constants.programmatic.CALCULATED_SUMS.adserverEcpm.fn(d),
					diffVal: (oldD, newD) => percentChange(oldD.val, newD.val),
				},
				trimBy: 'trend_revenueAfterSsp',
			},
			revenueChangeRev: {
				requires: ['revenueAfterSsp'],
				trendMetric: {
					getVal: (d) => d.revenueAfterSsp,
				},
				trimBy: 'trend_revenueAfterSsp',
			},
			revenueChangePerc: {
				requires: ['revenueAfterSsp'],
				trendMetric: {
					getVal: (d) => d.revenueAfterSsp,
					diffVal: (oldD, newD) => percentChange(oldD.val, newD.val),
				},
				trimBy: 'trend_revenueAfterSsp',
			},
		},
		OTHER_OPTIONS: {
			revenueCorrection: 'Revenue correction',
			forecast: 'Forecast',
		},

		IS_INVALID_AS_TOTAL: (state, sumVal) => _.includes(Constants.programmatic.ADSERVER_SUMS, sumVal)
			&& state.groupBy.some((s) => ['sspId', 'sourceId', 'revenueType'].includes(s)),
	},

	invoiceMacroFields: [
		'title',
		'granularity',
		'groupBy',
		'whereIn',
		'sums',
		'showChart',
		'showTable',
		'showPieChart',
		'maxAdvertisers',
		'currency',
		'correctionType',
		'chartSettings',
		'filterSelectionType',
	],

	SHORT_TIME_RANGES: {
		perhour: { ms: 3600 * 1000 },
		per10min: { ms: 600 * 1000 },
	},

	MAX_NO_AUTO_SKIP: 20,
	COMP_PFX: 'comp_',
};

// Maxium labels for charts in columns
// idx: max
Object.assign(Constants, {
	MAX_LABELS: [
		{ maxLabels: Constants.MAX_NO_AUTO_SKIP - 1, options: { aspectRatio: 2, pie: { aspectRatio: 2 } } }, // 0
		{ maxLabels: Constants.MAX_NO_AUTO_SKIP - 1, options: { aspectRatio: 2, pie: { aspectRatio: 2 } } }, // 1
		{ maxLabels: 15, options: { aspectRatio: 1.8, pie: { aspectRatio: 1.4 } } }, // 2
		{ maxLabels: 10, options: { aspectRatio: 1.5, pie: { aspectRatio: 1.7 } } }, // 3
		{ maxLabels: 8, options: { aspectRatio: 1.4, pie: { aspectRatio: 2.2 } } }, // 4
		{ maxLabels: 4, options: { aspectRatio: 1.3, pie: { aspectRatio: 3 } } }, // 5
		{ maxLabels: 4, options: { aspectRatio: 1.3, pie: { aspectRatio: 3.2 } } }, // 6...
	],
	MAX_COLUMNS: (maxCols, width) => {
		const MAX_COLS_PER_WIDTH = [{ minWidth: 1150, colsMax: 6 }, { minWidth: 900, colsMax: 4 }, { minWidth: 700, colsMax: 2 }, { minWidth: 0, colsMax: 1 }];
		return Math.min(MAX_COLS_PER_WIDTH.find(({ minWidth }) => width >= minWidth).colsMax, maxCols);
	},
});

Constants.missingSspEntries = {
	DESCRIPTION: 'Missing API traffic',
	API_FN: 'reportMissingSspEntries',
	availableCheck: ({ isAdministrator }) => isAdministrator,
	reportLocation: '/reports/missing-ssp-traffic',
	noSaveType: true,

	getDefaultProperties: () => ({
		title: 'Lost SSP traffic last 30 days',
		start: -30,
		end: -1,
		groupBy: ['sspId', 'sourceId'],
		showChart: false,
		showTable: true,
		settingsOpen: false,
	}),

	SUM_DESCRIPTIONS: _.pick(Constants.programmatic.SUM_DESCRIPTIONS, [
		'revenue',
		'revenueAfterSsp',
		'impressionsIn',
		'soldImpressions',
	]),

	GROUP_BY_OPTIONS: {
		date: 'Date',
		sourceId: 'Source',
		sspId: 'SSP',
		revenueType: 'Revenue type',
	},
	REVENUE_TYPES: Constants.programmatic.REVENUE_TYPES,
};

Constants.summary_hb = (() => {
	const { hb } = Constants;
	const ADS_METRICS = ['adsImps', 'adsRev', 'adsReq'];
	const PLACEMENT_LEVEL_METRICS = ['adserverIn', ...ADS_METRICS];
	const PLACEMENT_LEVEL_METRICS_KEYS = PLACEMENT_LEVEL_METRICS.reduce((m, v) => {
		if (v === 'adserverIn') {
			m[v] = '__adIn'; // Special case for adserver in mixing __adIn and adserverIn
		} else {
			m[v] = `__${v}`;
		}
		return m;
	}, {});

	const STORED_DIMS = ['publisherId', 'siteId', PREBID_CONFIGURATION_DIM, 'adUnitCode', 'bidder'];
	const STORED_METRICS = [
		'revenue',
		'renderSuccess',
		'bidResponse',
		'noBid',
		'timedOut',
		'bids',
		...PLACEMENT_LEVEL_METRICS,
	];
	const CALCULATED_SUMS = {
		..._.pickBy(hb.CALCULATED_SUMS, (v) => !_.difference(v.requires, STORED_METRICS).length),
		adsRevEcpm: {
			requires: ['adsReq', 'adsRev'],
			fn: (d) => (d.adsReq ? (d.adsRev * 1000) / d.adsReq : 0),
			trimBy: 'adsReq',
		},
	};
	return {
		DESCRIPTION: 'HB Analytics Historical',
		SHORT_DESCRIPTION: 'HB Historical',
		API_FN: 'reportSummaryHb',
		availableCheck: hb.availableCheck,
		reportLocation: '/reports/hb-historical',
		NO_HBA_DATA: '[No HB data]',
		STORED_DIMS,
		STORED_METRICS,
		SUM_DESCRIPTIONS: {
			..._.pick(hb.SUM_DESCRIPTIONS, [...STORED_METRICS, ...Object.keys(CALCULATED_SUMS)]),
			adsRev: 'AdServer revenue',
			adsImps: 'AdServer impressions',
			adsReq: 'AdServer requests',
			adsRevEcpm: 'AdServer eCPM',
		},
		GROUP_BY_OPTIONS: _.pick(hb.GROUP_BY_OPTIONS, ['date', 'placementId', ...STORED_DIMS]),
		HB_METRICS: _.without(STORED_METRICS, ...ADS_METRICS),
		ADS_METRICS,
		ADS_METRICS_EXPANDED: expandSums({ CALCULATED_SUMS }, ADS_METRICS),
		SUM_DESCRIPTION_ABBREVIATIONS: {
			...hb.SUM_DESCRIPTION_ABBREVIATIONS,
			adsRev: 'AdS. rev.',
			adsImps: 'AdS. imps.',
			adsReq: 'AdS. req.',
			adsRevEcpm: 'AdS. eCPM',
		},
		CALCULATED_SUMS,
		PLACEMENT_LEVEL_METRICS,
		PLACEMENT_LEVEL_METRICS_KEYS,
		IS_INVALID_AS_TOTAL: invalidAsSumFn(
			'summary_hb',
			_.mapValues(_.keyBy(PLACEMENT_LEVEL_METRICS), () => ['bidder']),
		),
	};
})();

((p) => {
	const ADV_LIMITERS = limitSums(p, [
		'adserverIn',
		'impressionsIn',
		'impressionsOut',
		'pageViews',
	]);
	const PLACEMENT_LIMITERS = limitSums(p, [
		'pageViews',
	]);

	p.GROUP_BY_SUM_LIMITERS = {
		..._.mapValues(_.keyBy(AllDimensions, 'dimension'), () => ADV_LIMITERS),
		userNr: ADV_LIMITERS,
		placementId: PLACEMENT_LIMITERS,
		revenueType: PLACEMENT_LIMITERS,
		demandChannel: PLACEMENT_LIMITERS,
		sourceId: PLACEMENT_LIMITERS,
		sspId: PLACEMENT_LIMITERS,
	};
	p.ADSERVER_SUMS = expandSums(p, ['adserverIn']);
})(Constants.programmatic);

((p) => {
	const ADV_LIMITERS = limitSums(p, [FUTURE_FORECASTED]);

	p.GROUP_BY_SUM_LIMITERS = {
		..._.mapValues(_.keyBy(AllDimensions, 'dimension'), () => ADV_LIMITERS),
		userNr: ADV_LIMITERS,
	};
})(Constants.future);

const REVENUE_SUMS = [
	'revenue',
	'nonApproved',
	'relevantRevenue',
	'dataProviderRevenue',
	'publisherRevenue',
	'revenueAfterSsp',
	'sspCommission',
	'relevantRevenue',
	'publisherRevenue',
	'ecpm',
	'publisherEcpm',
	'adserverEcpm',
	'revenueChangeRev',
	'efficiencyChangeRev',
];

Object.keys(_.pickBy(Constants, (v) => v.API_FN)).forEach((type) => {
	const obj = Constants[type];
	obj.IS_REVENUE_SUM = _.mapValues(_.pick(obj.SUM_DESCRIPTIONS, REVENUE_SUMS), () => true);
});

const updateLogConstants = () => {
	const logTypes = EnvReportInterface.getGenericData?.().logReportTypes || [];
	Object.keys(Constants).filter((k) => k.startsWith('log_')).forEach((k) => { delete Constants[k]; });
	logTypes.forEach((logTypeSettings) => {
		const {
			id, name, fields, reportFn, calculatedSums,
		} = logTypeSettings;
		const allLogFlds = _.flatten(_.values(_.pick(fields, ['standard', 'bid'])));
		const optsMatching = (fn) => _.mapValues(_.keyBy(allLogFlds.filter(fn), 'field'), 'desc');
		const isDim = ({ type, nameMap }) => nameMap || _.includes(['id', 'dimensions', 'cpm'], type);
		const isSum = (fld) => !isDim(fld) || fld.type === 'cpm';
		const relativeToFlds = allLogFlds.filter((fld) => isSum(fld) && fld.relativeTo);
		const DEFAULT_GROUP_BY = {
			date: 'Time period',
			publisherId: 'Publisher',
			siteId: 'Site',
			placementId: 'Placement',
		};

		const DEFAULT_SUMS = {
			bidsWon: 'Impressions',
			auctions: 'Auctions',
			bids: 'Bids',
			bidsLost: 'Bids lost',
		};

		const bidPriceFld = (fields.bid.find((fld) => fld.isBidPrice) || {}).field;

		const DEFAULT_CALCULATED_SUMS = {
			...(bidPriceFld && {
				bidEcpm: {
					desc: 'Bid eCPM',
					requires: ['bids', bidPriceFld],
					fn: (d) => (d.bids ? (d[bidPriceFld] * 1000) / d.bids : 0),
					trimBy: 'bidsWon',
				},
			}),
			ecpm: {
				desc: 'eCPM',
				requires: ['revenue', 'bidsWon'],
				fn: (d) => (d.bidsWon ? (d.revenue * 1000) / d.bidsWon : 0),
				trimBy: 'bidsWon',
			},
			winnerEcpm: {
				desc: 'Winner eCPM',
				requires: ['winner_revenue', 'auctions'],
				fn: (d) => (d.auctions ? (d.winner_revenue * 1000) / d.auctions : 0),
				trimBy: 'auctions',
			},
			winRate: {
				desc: 'Win rate %',
				requires: ['bidsWon', 'bids'],
				fn: (d) => Math.min(d.bids ? (d.bidsWon * 100) / d.bids : 0, 100),
				trimBy: 'bids',
			},
		};

		const allCalcSums = {
			...DEFAULT_CALCULATED_SUMS,
			// functions from server comes as strings, hence eval
			..._.mapValues(calculatedSums, (obj) => ({ ...obj, fn: eval(obj.fn) })),
			..._.keyBy(relativeToFlds.map(({ desc, field, relativeTo }) => ({
				desc: desc,
				requires: [field, relativeTo],
				fn: (d) => (d[relativeTo] ? (d[field] * 1000) / d[relativeTo] : 0),
				trimBy: relativeTo,
				key: `__${field}`,
			})), 'key'),
		};

		const auctionSumArr = [
			'auctions',
			// Auction-sums that are only for winning bids are ok
			...fields.standard.filter((fld) => !fld.winnerOnly).map(({ field }) => field),
		];
		const auctionSumMap = _.zipObject(auctionSumArr, Array(auctionSumArr.length).fill(true));
		_.forOwn(allCalcSums, (({ requires }, key) => {
			if (requires.find((sum) => auctionSumMap[sum])) {
				auctionSumMap[key] = true;
			}
		}));
		const bidFldMap = _.keyBy(fields.bid, 'field');
		const bidRanges = allLogFlds.filter((fld) => fld.type === 'cpm').map((fld) => fld.field);

		const settings = {
			DESCRIPTION: name,
			API_FN: reportFn,
			availableCheck: ({ isAdministrator }) => isAdministrator,
			reportLocation: `/reports/log/${id}`,
			SUM_DESCRIPTIONS: {
				...DEFAULT_SUMS,
				...optsMatching(isSum),
				..._.mapValues(allCalcSums, 'desc'),
			},
			GROUP_BY_OPTIONS: {
				...DEFAULT_GROUP_BY,
				...optsMatching(isDim),
			},
			CALCULATED_SUMS: allCalcSums,
			IS_REVENUE_SUM: { /** TODO: maybe..? */ },
			IS_BID_RANGE: _.zipObject(bidRanges, Array(bidRanges.length).fill(true)),
			HIDE_SUM: _.keyBy(relativeToFlds, 'field'),
			logTypeSettings,
			allLogFlds,
			IS_INVALID_AS_TOTAL: ({ groupBy }, sumVal) => auctionSumMap[sumVal] && groupBy.find((dim) => bidFldMap[dim]),
		};
		Constants[`log_${id}`] = settings;
	});
};

const updateOptimizeMetrics = () => {
	const metrics = EnvReportInterface.getGenericData?.()?.optimizeMetrics || [];
	const { SUM_DESCRIPTIONS, CALCULATED_SUMS } = Constants.hb;
	metrics.forEach(({ name: orgName, label }) => {
		const { count, calc, name } = toOptimizeMetricName(orgName);
		SUM_DESCRIPTIONS[calc] = label;
		CALCULATED_SUMS[calc] = {
			requires: ['adserverIn', name, count],
			fn: (d) => (d[name] || 0) / (d[count] || 1),
			trimBy: 'adserverIn',
		};
	});
};

const initCustomImportSettings = ({ name } = {}) => {
	if (name) {
		const uiCName = name.charAt(0).toUpperCase() + name.slice(1);
		Constants.programmatic.GROUP_BY_OPTIONS.customImportDimNr = uiCName;
		const dim = AllSeqObjectDimensions.byName('customImportDim');
		Object.assign(dim, {
			uiName: name,
			uiCName,
			pluralName: `${name}s`,
			pluralCname: `${uiCName}s`,
		});
	}
};

const updateConstants = () => {
	updateLogConstants();
	initCustomImportSettings(EnvReportInterface.getGenericData?.().CUSTOMIMPORTDIM);
	updateOptimizeMetrics();
};

updateConstants();
EnvReportInterface.onGenericDataUpdated(updateConstants);
ReportType.init(Constants);

module.exports = Constants;

