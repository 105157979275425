import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

function Icon(p) {
	const Pic = p.pic;
	if ('show' in p && !p.show) {
		return null;
	}
	if (!p.href && !p.onClick) {
		return (
			<Tooltip title={p.tooltip}>
				<Pic color={p.color} style={p.style} />
			</Tooltip>
		);
	}
	return (
		<Tooltip title={p.tooltip}>
			<IconButton
				{...(p.href ? { href: p.href } : {})}
				onClick={(ev) => {
					ev.stopPropagation();
					return p.onClick ? p.onClick(ev) : undefined;
				}}
				size="large"
			>
				<Pic color={p.color} style={p.style} />
			</IconButton>
		</Tooltip>
	);
}

Icon.propTypes = {
	pic: PropTypes.elementType.isRequired,
	tooltip: PropTypes.node.isRequired,
	href: PropTypes.string,
	onClick: PropTypes.func,
	color: PropTypes.string,
};

Icon.defaultProps = {
	href: undefined,
	onClick: undefined,
	color: undefined,
};

export default Icon;
