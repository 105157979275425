import React, { useReducer, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
	TextField,
	Box,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Check from '@mui/icons-material/Check';
import { createFilterFnOptions, filterOptions } from '../filterOptions';

const initialState = {
	inputValue: '',
	success: false,
};

const selectAllItem = {
	label: 'Select all',
	id: 'all',
	path: 'all',
};

function reducer(state, action) {
	switch (action.type) {
		case 'inputChange':
			return {
				...state,
				inputValue: action.inputValue,
			};
		case 'add':
			return {
				...state,
				success: true,
				latestAddedItem: action.latestAddedItem,
			};
		case 'reset':
			return {
				...state,
				success: false,
				inputValue: '',
			};
		default:
			throw Error(`Unhandled action type ${action.type}`);
	}
}

function SearchAdder({
	options,
	onAdd,
	singleSelect,
}) {
	const [{ success, inputValue, latestAddedItem }, dispatch] = useReducer(reducer, initialState);
	const filterObj = useMemo(() => createFilterFnOptions(options), [options]);
	const filteredOptions = useRef(options);

	// Add 'all' "fake" option to the beginning of the list
	const updatedOptions = singleSelect ? options : [
		selectAllItem,
		...options,
	];

	return (
		<Autocomplete
			inputValue={inputValue}
			onInputChange={(_, value) => {
				dispatch({ type: 'inputChange', inputValue: value });
			}}
			options={updatedOptions}
			renderInput={(params) => (
				<TextField
					{...params}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							success && (
								<Box sx={{
									display: 'flex',
									alignItems: 'center',
									color: 'success.main',
								}}
								>
									<Check />
									<Box sx={{ ml: 0.5, mr: 0.5 }}>
										Added
										{' '}
										{latestAddedItem}
									</Box>
								</Box>
							)
						),
					}}
					label="Quick add"
				/>
			)}
			filterOptions={(selectableOptions) => {
				// Always return first 5000 items only
				if (!inputValue) {
					return selectableOptions.slice(0, 3000);
				}

				let filtered = filterOptions(inputValue, filterObj);
				if (filtered.length > 0) {
					filtered = [
						selectAllItem,
						...filtered,
					];
				}
				// Set filteredOptions before slicing out 3000 first items,
				// because in case of "select all" we want to add really _all_ items
				filteredOptions.current = filtered;
				const res = filtered.slice(0, 3000);
				return res;
			}}
			// Override option rendering to use id instead of label as key
			renderOption={(props, option) => (
				<li
					{...props}
					key={option.id}
					title={option.path}
					onClick={() => {
						if (option.id === 'all') {
							// Remove fake "all" item, then add all options
							const toAdd = filteredOptions.current
								.filter(({ id }) => id !== 'all')
								.map(({ id }) => id);
							onAdd(toAdd);
							dispatch({ type: 'add', latestAddedItem: `${toAdd.length} items` });
						} else {
							onAdd([option.id]);
							dispatch({ type: 'add', latestAddedItem: option.label });
						}
					}}
				>
					{option.label}
				</li>
			)}
			onFocus={() => dispatch({ type: 'reset' })}
			freeSolo
			selectOnFocus
			handleHomeEndKeys
			disableCloseOnSelect
			clearOnEscape
		/>
	);
}

SearchAdder.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		path: PropTypes.string.isRequired,
	})),
	onAdd: PropTypes.func,
	singleSelect: PropTypes.bool,
};

SearchAdder.defaultProps = {
	options: [],
	onAdd: () => {},
	singleSelect: false,
};

export default SearchAdder;
