import moment from 'moment';
import _ from 'lodash';
import DateUtils from './dateUtils';

const CUT_BITS = 8;
const DATE_BITS = 13;
const RANGE_BITS = CUT_BITS + DATE_BITS;
export const MAX_RANGES = 3;
const DATE_START = new Date('2017-12-31'); // first valid date is '2018-01-01'
export const FIRST_VALID_DATE = DateUtils.fullDay(DATE_START, 1);
export const LAST_VALID_DATE = DateUtils.fullDay(DATE_START, (2 ** DATE_BITS) - 1);

export const nrToCut = (nr) => {
	if (nr < 0 || nr > 200) {
		throw Error('Invalid cut');
	}
	if (nr > 100) {
		return nr - 100.5;
	}
	return nr;
};

const cutToNr = (cut) => {
	if (cut < 0 || cut > 100) {
		throw Error('Invalid cut');
	}
	const rest = cut % 1;
	if (!rest) {
		return cut;
	}
	if (rest !== 0.5) {
		throw Error('Cuts can only have a granularity of 0.5%');
	}
	return 101 + Math.floor(cut);
};

const getByBits = (num, startBit, numBits) => Math.floor(num / (2 ** startBit)) % (2 ** numBits);

const setByBits = (orgNum, value, startBit, numBits) => (orgNum - (getByBits(orgNum, startBit, numBits) * (2 ** startBit))) + (value * (2 ** startBit));

export const getEncCutDate = (date) => moment(DateUtils.fullDay(date)).diff(DATE_START, 'days');

const getDateFromEnc = (encDate) => DateUtils.fullDay(DATE_START, encDate);

export const getCutOf = (cutNr, encCutDate) => {
	if (cutNr < (2 ** CUT_BITS)) {
		return cutNr;
	}
	for (let i = 0; i < MAX_RANGES; i += 1) {
		const rangeStart = i * RANGE_BITS;
		const cut = nrToCut(getByBits(cutNr, rangeStart, CUT_BITS));
		if (i === MAX_RANGES - 1) {
			return cut;
		}
		const date = getByBits(cutNr, rangeStart + CUT_BITS, DATE_BITS);
		if (!date || encCutDate < date) {
			return cut;
		}
	}
	throw Error('Can\'t happen');
};

export const getCutRanges = (cutNr) => {
	const res = [];
	for (let i = 0; i < MAX_RANGES; i += 1) {
		const rangeStart = i * RANGE_BITS;
		const cut = nrToCut(getByBits(cutNr, rangeStart, CUT_BITS));
		if (i === MAX_RANGES - 1) {
			res.push({ cut });
		} else {
			const date = getByBits(cutNr, rangeStart + CUT_BITS, DATE_BITS);
			if (!date) {
				res.push({ cut });
				break;
			}
			res.push({ cut, before: getDateFromEnc(date) });
		}
	}
	return res;
};

export const getCutNrFromRanges = (ranges) => {
	if (_.isNumber(ranges)) {
		return ranges;
	}
	let res = 0;
	if (ranges.length > MAX_RANGES) {
		throw Error('Too many cut ranges');
	}
	for (let i = 0; i < MAX_RANGES; i += 1) {
		const rangeStart = i * RANGE_BITS;
		const cut = parseFloat(ranges[i].cut);
		if (isNaN(cut)) {
			throw Error('Invalid cut number');
		}
		const cutNr = cutToNr(cut);
		res = setByBits(res, cutNr, rangeStart, CUT_BITS);
		if (i === MAX_RANGES - 1 || !ranges[i].before) {
			return res;
		}
		const before = getEncCutDate(ranges[i].before);
		if (before <= 0 || before >= 2 ** DATE_BITS) {
			throw Error('Invalid cut date');
		}
		res = setByBits(res, before, rangeStart + CUT_BITS, DATE_BITS);
	}
	return res;
};

export const CutValidator = {
	validator: (cutNr) => {
		try {
			getCutRanges(cutNr).forEach((range, idx, arr) => {
				if (idx && range.before && arr[idx - 1].before >= range.before) {
					throw Error('Ranges in wrong order');
				}
			});
		} catch (e) {
			return false;
		}
		return true;
	},
	message: 'Invalid cut value',
};

export const CutFieldSettings = {
	type: Number, required: true, default: 0, min: 0, validate: CutValidator,
};
