import {
	IconButton,
	Tooltip,
	Box,
	ClickAwayListener,
	Paper,
	MenuList,
	MenuItem,
	styled,
} from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import WidgetSelector from '../WidgetSelector';

const transitionDuration = '300ms';
const PREFIX = 'AddDashboardButton';
const classes = {
	button: `${PREFIX}-button`,
	open: `${PREFIX}-open`,
	menu: `${PREFIX}-menu`,
	wrapper: `${PREFIX}-wrapper`,
	wrapperOpen: `${PREFIX}-wrapperOpen`,
};

const Root = styled('div')(({
	theme,
}) => ({
	[`& .${classes.wrapper}`]: {
		transition: `opacity ${transitionDuration} ease-in-out, margin-bottom ${transitionDuration} ease-in-out`,
		opacity: 0,
		position: 'absolute',
		bottom: '100%',
		right: 0,
		mb: 0,
		top: '64px',
	},

	[`& .${classes.wrapperOpen}`]: {
		opacity: 1,
		marginBottom: theme.spacing(1),
	},
}));

function TopBarDashboardOptions({
	availableWidgets,
	onSelect,
}) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Box sx={{
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
		}}
		>
			<Root>
				<IconButton color="offWhite" onClick={() => setIsOpen(true)}>
					<AddIcon />
				</IconButton>

				<Box
					className={`${classes.wrapper}${isOpen ? ` ${classes.wrapperOpen}` : ''}`}
				>
					{ isOpen && (
						<ClickAwayListener
							onClickAway={() => {
								setIsOpen(false);
							}}
						>
							<Paper>
								<MenuList
									autoFocusItem
									variant="menu"
								>
									<MenuItem disabled>
										Select a template
									</MenuItem>
									<div style={{ minWidth: 300 }}>
										<WidgetSelector
											availableWidgets={availableWidgets}
											onSelect={(widget) => {
												onSelect(widget);
												setIsOpen(false);
											}}
										/>
									</div>
								</MenuList>
							</Paper>
						</ClickAwayListener>
					)}
				</Box>
			</Root>

		</Box>
	);
}

TopBarDashboardOptions.propTypes = {
	availableWidgets: PropTypes.arrayOf(PropTypes.shape({})),
	onSelect: PropTypes.func,
};

TopBarDashboardOptions.defaultProps = {
	availableWidgets: [],
	onSelect: () => undefined,
};

export default TopBarDashboardOptions;
