import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import storeContainer from '../storeContainer';
import TableOfUsers from '../../components/TableOfUsers';
import Spinner from '../../components/Spinner';
import withIdentity from '../withIdentity';
import { withUpdate } from '../../components/Wrappers';

@withIdentity()
@storeContainer()
@withUpdate
class UserCollection extends React.Component {
	componentDidMount() {
		const { users } = this.props;
		if (!users.isReady()) {
			try {
				users.loadAll();
			} catch (e) {
				console.log(e);
			}
		}
	}

	async deleteUser(id) {
		const { users } = this.props;
		try {
			await users.delete(id);
		} catch (e) {
			console.log(e);
		}
	}

	async startGhosting(id) {
		const {
			startGhosting, isAudiencePublisher, isProgrammaticPublisher, history,
		} = this.props;
		try {
			await startGhosting(id);
			if (isProgrammaticPublisher()) {
				history.push('/dashboard');
			} else if (isAudiencePublisher()) {
				history.push('/audience/dashboard');
			} else {
				history.push('/');
			}
		} catch (e) {
			console.log(e);
		}
	}

	render() {
		const {
			publisherId, user, users, isAdministrator, isGhosting,
		} = this.props;
		return users.isReady()
			? (
				<TableOfUsers
					users={isAdministrator() ? users.byPublisherId(publisherId) : users.byPublisherId(user()?.profile?.publisherId)}
					deleteRow={(id) => this.updateAsync(() => this.deleteUser(id))}
					ghostUser={isAdministrator() && !isGhosting() ? (id) => this.startGhosting(id) : null}
				/>
			)
			: <Spinner />;
	}
}

UserCollection.propTypes = {
	publisherId: PropTypes.string,
	history: PropTypes.object.isRequired,
	user: PropTypes.func,
	users: PropTypes.object,
	startGhosting: PropTypes.func,
	isGhosting: PropTypes.func,
	isAdministrator: PropTypes.func,
	isAudiencePublisher: PropTypes.func,
	isProgrammaticPublisher: PropTypes.func,
};

UserCollection.defaultProps = {
	publisherId: null,
};

export default withRouter(UserCollection);
