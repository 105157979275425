import IdentityStore from './IdentityStore';
import PublisherStore from './PublisherStore';
import UserStore from './UserStore';

export let stores = null;

function createStores() {
	if (stores) return stores;

	stores = {};
	stores.identity = new IdentityStore();
	stores.publishers = new PublisherStore();
	stores.users = new UserStore();

	return stores;
}

createStores();
