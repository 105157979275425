import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ReportPage from './ReportPage';
import { hasAccessToPublishers } from '../../components/Report/utils';
import useUrlParams from '../../hooks/useUrlParams';
import { Dialog } from '../../components/Dialog';

/**
 * Checks for URL params for report settings and if they exist, passes them along with
 * type and reportId to the ReportPage component.
 *
 */
function ReportPageWrapper({
	reportId,
	type,
	...rest
}) {
	const history = useHistory();
	const urlParams = useUrlParams();
	const defaultSettings = urlParams.settings ? JSON.parse(atob(urlParams.settings)) : null;
	const ignoreAccessCheck = urlParams.ignoreCheck !== undefined;
	const hasAccess = ignoreAccessCheck || (defaultSettings ? hasAccessToPublishers(defaultSettings) : true);

	if (!hasAccess) {
		return (
			<Dialog
				open
				text="You don't have access to this report URL.
The person sending the link must if possible
filter out only the publisher(s) you have access to in the report settings"
				onClose={() => history.push('/dashboard')}
			/>
		);
	}
	return (
		<ReportPage
			reportId={reportId}
			type={type}
			defaultSettings={defaultSettings}
			{...rest}
		/>
	);
}

ReportPageWrapper.propTypes = {
	reportId: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
};

export default ReportPageWrapper;
