const _ = require('lodash');

const setupDimension = (dim, combNrIdx) => {
	const missing = ['name', 'dimension'].find((fld) => !(fld in dim));
	if (missing) {
		throw Error(`Mandatory field '${missing}' is missing`);
	}
	dim.uiName = dim.uiName || dim.name;
	const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
	const uiCName = capitalize(dim.uiName);
	const cName = capitalize(dim.name);
	_.defaults(dim, {
		combNrIdx,
		cName,
		uiCName,
		clsName: cName,
		pluralName: `${dim.uiName}s`,
		pluralCname: `${uiCName}s`,
		reportProp: `${dim.name}MappingId`,
		customMappingProp: `custom${cName}Mapping`,
		defaultMappingKv: `default${cName}MappingId`,
		mappingEnabled: false, // Are there mapping for this dimension?
		sysSpecific: false, // Name can be the same for different objects from different SSPs
		unitNameFld: `${dim.name}${dim.sysSpecific ? 'Name' : ''}`,
		unitIdFld: `${dim.name}Id`,
		sysIdFld: 'sysLocalId', // For example Deal ID / Line item ID in SSP
		uniqFld: dim.sysSpecific ? 'uniqueId' : 'name',
	});
	_.defaults(dim, {
		unitAttribFld: dim.sysSpecific ? dim.unitIdFld : dim.unitNameFld,
		...(dim.importExcludeFilter && {
			sspFilterFld: `filterOut${capitalize(cName)}`,
			sspFilterPlacFld: `filterOutPlac${capitalize(cName)}`,
			importExcludeDescription: `Exclude data from ${dim.pluralCname}(s)`,
		}),
	});
};

module.exports = {
	setupDimension,
};
