const { CURRENCIES } = require('../constants');

const BUILT_IN_UI_GROUP = 'Built-in settings';

module.exports = [
	{
		type: 'Number',
		uiGroup: BUILT_IN_UI_GROUP,
		name: 'rlvFloor',
		description: 'Price floor',
		omitWhenDefault: true,
	},
	{
		type: 'Boolean',
		uiGroup: BUILT_IN_UI_GROUP,
		name: 'rlvFloorOpt',
		description: 'Optimize floor price',
		omitWhenDefault: true,
		optiField: true,
	},
	{
		type: 'Boolean',
		uiGroup: BUILT_IN_UI_GROUP,
		name: 'rlvFloorOptOnly',
		description: 'Only set price floor for optimized users',
		omitWhenDefault: true,
		optiField: true,
	},
	{
		type: 'Boolean',
		uiGroup: BUILT_IN_UI_GROUP,
		name: 'rlvFloorOptAllowNo',
		description: 'Allow optimizer to disable floor even when non-zero',
		omitWhenDefault: true,
		optiField: true,
	},
	{
		type: 'String',
		uiGroup: BUILT_IN_UI_GROUP,
		name: 'rlvFloorCur',
		description: 'Price floor currency',
		defaultValue: null,
		hasOptions: true,
		options: Object.entries(CURRENCIES).map(([code, { name }]) => ({
			name: code,
			label: `${name} (${code})`,
		})),
		omitWhenDefault: true,
	},
	{
		// Essentially maps to disableCollapse option for appnexus renderer settings
		type: 'Object',
		uiGroup: BUILT_IN_UI_GROUP,
		name: 'rlvReloadSettings',
		description: 'Reload settings',
		defaultValue: {},
		fields: [
			{
				type: 'Boolean',
				name: 'enabled',
				description: 'Enable placement reloading',
				defaultValue: false,
			},
			{
				type: 'Number',
				name: 'timeBetween',
				description: 'Reload interval in seconds',
				defaultValue: 60,
				isRequired: true,
			},
			{
				type: 'String',
				name: 'strategy',
				description: 'Count reload interval by',
				defaultValue: 'load',
				isRequired: true,
				hasOptions: true,
				options: [
					{
						name: 'load',
						label: 'Time since loaded',
					},
					{
						name: 'viewStart',
						label: 'Time since first time viewable',
					},
					{
						name: 'sticky',
						label: 'Time being viewable',
					},
				],
			},
			{
				type: 'Number',
				name: 'reloadTimes',
				description: 'Maximum number of reloads',
				defaultValue: 2,
				isRequired: true,
			},
			{
				type: 'Number',
				name: 'minVisibility',
				description: 'Minium viewablity % for reload',
				defaultValue: 70,
				isRequired: true,
			},
			{
				type: 'Boolean',
				name: 'keepDims',
				description: 'Set CSS min width/height when reloading (prevents jump-effect)',
				defaultValue: false,
			},
			{
				type: 'String',
				name: 'fType',
				description: 'Filter type',
				defaultValue: 'incl',
				isRequired: true,
				hasOptions: true,
				options: [
					{
						name: 'incl',
						label: 'Include advertisers/orders',
					},
					{
						name: 'excl',
						label: 'Exclude advertisers/orders',
					},
				],
			},
			{
				type: 'Array',
				description: 'Filter advertisers/orders (only supported in GAM)',
				defaultValue: [],
				name: 'filter',
				fields: [
					{
						type: 'String',
						name: 'type',
						description: 'Object type',
						defaultValue: 'adv',
						isRequired: true,
						hasOptions: true,
						options: [
							{
								name: 'adv',
								label: 'Advertiser ID',
							},
							{
								name: 'order',
								label: 'Order ID',
							},
						],
					},
					{
						type: 'String',
						name: 'value',
						description: 'ID',
						isRequired: true,
					},
				],
			},
		],
	},
	{
		// Essentially maps to disableCollapse option for appnexus renderer settings
		type: 'Object',
		uiGroup: BUILT_IN_UI_GROUP,
		name: 'rlvLazy',
		description: 'Lazy load settings',
		defaultValue: {},
		fields: [
			{
				type: 'Boolean',
				name: 'enabled',
				description: 'Lazy loading',
				defaultValue: false,
			},
			{
				type: 'Number',
				name: 'pixels',
				description: 'Pixel distance from screen (vertically)',
				defaultValue: 0,
				isRequired: true,
			},
		],
	},
];
