import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AllMappingDimension from 'relevant-shared/mappingDimensions/allMappingDimension';
import ExpandSelector from '../ExpandSelector';
import DynamicSearchFilter from '../DynamicSearchFilter';
import CheckboxGroup from '../CheckboxGroup';
import FilterTypeSelector from './FilterTypeSelector';

export function DynFilter(props) {
	const {
		dimension, name, title, selectOptions, initSums, otherSettings, filterSelectionType, onChangeFilterSelectionType,
	} = props;
	const capName = name[0].toUpperCase() + name.slice(1);
	const mappingDim = _.find(AllMappingDimension, { dimension });
	let advMappingId;
	if (otherSettings && mappingDim) {
		advMappingId = otherSettings[mappingDim.reportProp];
	}
	return (
		<ExpandSelector
			title={title || `${capName}s`}
			selected={selectOptions(dimension).selected}
		>

			<FilterTypeSelector
				onChange={(e) => onChangeFilterSelectionType(dimension, e.target.value)}
				value={filterSelectionType[dimension]}
			/>

			<DynamicSearchFilter
				{...selectOptions(dimension)}
				metric={initSums[0]}
				dimension={dimension}
				fullWidth
				groupBy={dimension}
				label={dimension}
				textFieldLabel={`Add ${capName}(s)`}
				showMoreLabel={`${name}s`}
				{...(advMappingId && { advMappingId })}
				{..._.omit(props, Object.keys(DynFilter.propTypes))}
			/>
		</ExpandSelector>
	);
}

DynFilter.propTypes = {
	dimension: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	title: PropTypes.string,
	selectOptions: PropTypes.func.isRequired,
	onChangeFilterSelectionType: PropTypes.func.isRequired,
	filterSelectionType: PropTypes.object.isRequired,
	initSums: PropTypes.array.isRequired,
	otherSettings: PropTypes.object,
};

DynFilter.defaultProps = {
	title: undefined,
	otherSettings: undefined,
};

export function SimpleTypeSelect({
	title,
	dimension,
	types,
	whereIn,
	selectOptions,
	filterSelectionType,
	onChangeFilterSelectionType,
}) {
	return (
		<ExpandSelector
			title={title}
			selected={(whereIn || {})[dimension] || []}
		>
			<>
				{ onChangeFilterSelectionType && (
					<FilterTypeSelector
						onChange={onChangeFilterSelectionType}
						value={filterSelectionType}
					/>
				)}
				<CheckboxGroup
					items={Object.values(types).map(({ desc, id }) => ({ text: desc, id }))}
					{...selectOptions(dimension)}
				/>
			</>
		</ExpandSelector>
	);
}

SimpleTypeSelect.propTypes = {
	title: PropTypes.string.isRequired,
	dimension: PropTypes.string.isRequired,
	types: PropTypes.object.isRequired,
	whereIn: PropTypes.object,
	selectOptions: PropTypes.func.isRequired,
	filterSelectionType: PropTypes.string,
	onChangeFilterSelectionType: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

SimpleTypeSelect.defaultProps = {
	whereIn: undefined,
	filterSelectionType: undefined,
	onChangeFilterSelectionType: undefined,
	children: undefined,
};
