import React, { createContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import useTopBar from '../../hooks/useTopbar';

export const TitleContext = createContext([]);

export default function TitleProvider({ children }) {
	const { setTitle: setTopBarTitle } = useTopBar();

	const updateTitle = useCallback((title) => {
		if (title) {
			document.title = title;
			setTopBarTitle(title);
		}
	}, [setTopBarTitle]);

	return (
		<TitleContext.Provider value={updateTitle}>
			{ children }
		</TitleContext.Provider>
	);
}

TitleProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
