import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import linkable from '../linkable';

function DeleteButton(props) {
	return (
		<IconButton {...props} size="large">
			<DeleteIcon />
		</IconButton>
	);
}

DeleteButton.propTypes = {
};

export default linkable(DeleteButton);
