const Node = require('./nodes/node');
const SiteNode = require('./nodes/siteNode');
const PlacementNode = require('./nodes/placementNode');
const BidderNode = require('./nodes/bidderNode');
const SspShadowNode = require('./nodes/sspShadowNode');
const SspPlacementNode = require('./nodes/sspPlacementNode');
const PublisherNode = require('./nodes/publisherNode');
const PrebidConfigNode = require('./nodes/prebidConfigNode');

/**
 * This file contains "wrappers" around the objects in the Publisher object to be used in backend/frontend:
 * - Publisher, Site, Placement, SspPlacement
 * Optimally the relevant-store/lib/models code would be shared so that the Publisher, Site, etc classes could
 * also in the frontend. But as long as that is not the case this is the workaround..
 * */

Object.assign(Node.Types, {
	Node,
	SiteNode,
	PlacementNode,
	BidderNode,
	SspShadowNode,
	SspPlacementNode,
	PublisherNode,
	PrebidConfigNode,
});

module.exports = Node.Types;
