import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Grid,
	Card,
	CardContent,
	CardHeader,
	Tooltip,
	Box,
} from '@mui/material';
import _ from 'lodash';
import SystemData from '../../lib/systemData';
import Select from '../Select';
import AnalyticsSystemMapper from '../AnalyticsSystemMapper';
import DeleteButton from '../DeleteButton';
import { ConfirmDialog } from '../ConfirmDialog';

function AnalyticsSystemForm({
	analyticsSystemOptions,
	analyticsSystem: preAnalyticsSystem,
	onChange,
}) {
	const selectedSystem = analyticsSystemOptions.find((o) => o.id === preAnalyticsSystem?.analyticsSystemId);
	const initialAnalyticsSystem = selectedSystem ? preAnalyticsSystem : null;

	const [analyticsSystem, setAnalyticsSystem] = useState(
		initialAnalyticsSystem ? { ...initialAnalyticsSystem } : null,
	);
	const [profiles, setProfiles] = useState(
		initialAnalyticsSystem && initialAnalyticsSystem.profileIds
			? initialAnalyticsSystem.profileIds.map((profileId) => ({ profileId })) : [],
	);
	const [profileOptions, setProfileOptions] = useState([]);
	const message = selectedSystem?.getCustomAnalyticsSystemMessage?.(profiles, profileOptions);

	const onAddNewAnalyticsSystem = ({ target }) => {
		const { value } = target;
		const newAnalyticsSystem = new SystemData.models.AnalyticsSystem({
			analyticsSystemId: value,
			profileIds: [],
		});
		setAnalyticsSystem(newAnalyticsSystem);
	};

	const onProfilesChange = (editedProfiles = []) => {
		const profileIds = editedProfiles.map(({ profileId }) => profileId);
		setProfiles(editedProfiles);
		onChange({
			...analyticsSystem,
			profileIds,
		});
	};

	const onRemoveAnalyticsSystem = () => {
		setAnalyticsSystem(null);
		setProfiles([]);
		onChange(null);
	};

	useEffect(() => {
		if (analyticsSystem) {
			const sys = analyticsSystemOptions.find(({ id }) => id === analyticsSystem.analyticsSystemId);
			if (sys && sys.knownProfiles) {
				const newOptions = sys.knownProfiles.map((profile) => ({
					...profile,
					label: selectedSystem?.friendlyProfileName?.(profile) || profile.name,
				}));
				// Include profileId in label in an effort to make it unique as it's needed for AutocompleteInput
				Object.values(_.groupBy(newOptions, 'label'))
					.filter((arr) => arr.length > 1)
					.flat()
					.forEach((profile) => {
						profile.label = `${profile.label} [${profile.profileId}]`;
					});
				newOptions.byProfileId = _.keyBy(newOptions, 'profileId');
				setProfileOptions(newOptions);
			}
		}
	}, [analyticsSystem, analyticsSystemOptions]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				{ analyticsSystem && Object.keys(analyticsSystem).length > 0 ? (
					<Card>
						<CardHeader
							title={analyticsSystemOptions.find((o) => o.id === analyticsSystem.analyticsSystemId).name}
							action={(
								<Tooltip title="Remove">
									<div>
										<ConfirmDialog
											text="Are you sure you want to remove this Analytics system mapping?"
											onConfirm={() => onRemoveAnalyticsSystem()}
										>
											<DeleteButton />
										</ConfirmDialog>
									</div>
								</Tooltip>
							)}
						/>
						<CardContent>
							<AnalyticsSystemMapper
								profiles={profiles}
								profileOptions={profileOptions}
								selectedSystem={selectedSystem}
								{...analyticsSystem}
								onChange={(value) => onProfilesChange(value)}
							/>
						</CardContent>
						{!!message && (
							<Box sx={{ m: 1.5 }}>
								{message}
							</Box>
						)}
					</Card>
				) : (
					<Select
						name="analyticsSystem"
						label="Add a new analytics system"
						items={analyticsSystemOptions.map(({ name, id }) => ({ label: name, value: id }))}
						onChange={onAddNewAnalyticsSystem}
						value={analyticsSystem}
						fullWidth
					/>
				)}
			</Grid>
		</Grid>
	);
}

export default AnalyticsSystemForm;

AnalyticsSystemForm.propTypes = {
	analyticsSystemOptions: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		label: PropTypes.string,
		value: PropTypes.string,
		type: PropTypes.string,
	})).isRequired,
	analyticsSystem: PropTypes.shape({
		analyticsSystemId: PropTypes.string,
		isNew: PropTypes.bool,
		profileIds: PropTypes.arrayOf(PropTypes.string),
		id: PropTypes.string,
		_id: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
};

AnalyticsSystemForm.defaultProps = {
	analyticsSystem: undefined,
};
