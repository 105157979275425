const _ = require('lodash');
const TagUtils = require('relevant-shared/misc/tagUtils');
const { toParamMap } = require('../../prebid/bidParamUtils');
const EnvReportInterface = require('../../reportData/envReportInterface');
const SspShadowNode = require('./sspShadowNode');
const Node = require('./node');
const SiteNode = require('./siteNode');

class PublisherNode extends Node {
	constructor(pub, {
		ssps,
		adservers,
		userIdModules,
		globalSettings,
	}) {
		super(pub, null, 'PublisherNode');
		Object.assign(this, {
			sspsById: _.keyBy(ssps, 'id'),
			adserversById: _.keyBy(adservers, 'id'),
			userIdModulesById: _.keyBy(userIdModules, 'id'),
			globalParams: toParamMap(globalSettings.bidParams),
			pubParams: toParamMap(pub.bidParams),
			placementTypes: _.flatten([pub, globalSettings].map((o) => o.placementTypes)),
			ssps,
			adservers,
			userIdModules,
			globalSettings,
		});
		pub.websites.forEach((site) => {
			this.children.push(new SiteNode(site, this));
		});
		this.byType.SspPlacementNode.forEach((sspPlac) => {
			sspPlac.obj.shadows.forEach((shadow) => {
				const plac = this.byId[shadow.placementId];
				if (plac) {
					plac.children.push(new SspShadowNode(shadow, plac, sspPlac));
				}
			});
		});
	}

	get publisherNode() {
		return this;
	}

	refreshBidParams() {
		this.pubParams = toParamMap(this.obj.bidParams);
	}

	bidderNodesByConfig({ id: pbConfigId }) {
		// For child prebid configs => use SSP-placements selected parent config
		const pbId = this.byId[pbConfigId]?.mainConfigNode?.id;
		return this.bidderNodes.filter((o) => o.obj.pbCfgs.find((id) => id.toString() === pbId));
	}

	/** Return all prebid parameters for *existing* nodes associated with a *prebid configuration*.
	 * That is - all UnitParams with .pbCfgId === pbConfigId. This does NOT include the parameters
	 * for the prebid configuration itself - when .unitId === pbConfigId */
	getParamsById({ id: pbConfigId }) {
		const getNode = ({ unitId }) => this.byId[unitId];
		const params = this.obj.bidParams.filter((b) => b.pbCfgId === pbConfigId && getNode(b));
		return _.sortBy(params, (p) => getNode(p).parents.length);
	}

	/**
	 * Update UnitParams.params ('parentParams') for UnitParams belonging to a parent prebid configuration
	 * with UnitParams.params ('childParams') for the matching node in the child prebid configuration.
	 * This is done when copying a child configuration to its' parent using PublisherNode.transferParams()
	 * In addition, all parameters for "descendants" (e.g. sites+placements for a publisher) will be "cleaned"
	 * from the corresponding values. For example: if we've set "genericPlacementData.someObject.someValue" = 5"
	 * for a placement in the parent-configuration but we're now copying from a child-configuration where we've
	 * set "genericPlacementData.someObject.someValue" = 10" for the publisher node - in that case we should
	 * remove "genericPlacementData.someObject.someValue" (5) for the placement.
	 * That is because for the child-configuration the value for the placement is indeed 10
	 * (inherited from the publisher) because of the order prebid-parameters are evalutated.
	 * 'toRemove' are the descendant UnitParams objects in the parent configuration. */
	replaceParams(parentParam, childParam, toRemove, path = []) {
		Object.keys(childParam).forEach((key) => {
			const fullPath = [...path, key];
			if (_.isPlainObject(childParam[key])) {
				parentParam[key] = _.isPlainObject(parentParam[key]) ? parentParam[key] : {};
				this.replaceParams(parentParam[key], childParam[key], toRemove, fullPath);
			} else {
				parentParam[key] = childParam[key];
				toRemove.forEach(({ params }) => {
					let obj = params;
					const len = fullPath.length - 1;
					for (let i = 0; i < len;) {
						const part = fullPath[i];
						if (!(part in obj)) {
							return;
						}
						obj = obj[part];
						i += 1;
					}
					delete obj[fullPath[len]];
				});
			}
		});
		return parentParam;
	}

	/** Copy bid parameters for a child prebid configuration 'child' into the parent prebid configuration 'parent' */
	transferParams(parent, child) {
		const { UnitParams } = EnvReportInterface.typeMap();

		// First copy the bid parameters for publisher/site/placement nodes. This does *not* include
		// parameters where UnitParams.unitId === pbjsConfigId
		const parentParams = this.getParamsById(parent);
		const childParams = this.getParamsById(child);
		childParams.forEach((param) => {
			const node = this.byId[param.unitId];
			if (!node) {
				return;
			}
			let parentParam = parentParams.find((p) => (
				p.unitId === param.unitId && p.sspId === param.sspId));

			if (!parentParam) {
				parentParam = new UnitParams({
					unitId: param.unitId,
					sspId: param.sspId,
					pbCfgId: parent.id,
					params: {},
				});
				this.obj.bidParams.push(parentParam);
				parentParams.push(parentParam);
			}
			// See documentation for PublisherNode.replaceParams() for an explanation.
			const decendants = _.keyBy(_.map(node.descendants(), 'id'));

			const toRemove = parentParams.filter((p) => (
				p.unitId in decendants && p.sspId === parentParam.sspId));

			this.replaceParams(parentParam.params, param.params, toRemove);
		});

		// Then copy bid parameters for the prebid config itself, not associated with any publisher/site/placement.
		// This currently involves "Prebid Config Tag Fields" (UnitData.sspId === 'genericPbConfigData')
		const getSelfParams = (pbCfgId) => _.keyBy(this.obj.bidParams.filter((p) => (
			p.unitId === pbCfgId
		)), 'sspId');
		const parentSelf = getSelfParams(parent.id);
		const childSelf = getSelfParams(child.id);
		_.forOwn(childSelf, ({ params }, sspId) => {
			const existing = parentSelf[sspId];
			if (existing) {
				TagUtils.mergeNoArr(existing.params, params);
			} else {
				this.obj.bidParams.push(new UnitParams({
					unitId: parent.id,
					sspId,
					pbCfgId: null,
					params: _.cloneDeep(params),
				}));
			}
		});
		this.refreshBidParams();
	}

	updateBidderNodesForConfig(nodes, pbjsConfig) {
		const nodesById = _.keyBy(nodes, 'id');
		this.bidderNodes.forEach((node) => {
			const shouldInclude = !!nodesById[node.id];
			const { pbCfgs } = node.obj;
			const hasNode = _.includes(pbCfgs, pbjsConfig.id);
			if (shouldInclude && !hasNode) {
				pbCfgs.push(pbjsConfig.id);
			} else if (!shouldInclude && hasNode) {
				_.pull(pbCfgs, pbjsConfig.id);
			}
		});
	}
}

module.exports = PublisherNode;
