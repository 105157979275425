exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".appInstanceToolbarContainerHidden__src-components-GlobalAdmin-styles__1w17Z {\n    display: none;\n}\n\n.appInstanceToolbarContainer__src-components-GlobalAdmin-styles__75OWm {\n    display: inline-block;\n}\n\n.popperMenu__src-components-GlobalAdmin-styles__31p9N {\n    max-height: 200px;\n    overflow: auto;\n}\n\n.popper__src-components-GlobalAdmin-styles__15ksP {\n    z-index: 10000;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/digitalenterprises/temp/relevant-yield/relevant-frontend/src/components/GlobalAdmin/styles.css"],"names":[],"mappings":"AAAA;IACI,cAAa;CACjB;;AAEA;IACI,sBAAqB;CACzB;;AAEA;IACI,kBAAiB;IACjB,eAAc;CAClB;;AAEA;IACI,eAAc;CAClB","file":"styles.css","sourcesContent":[".appInstanceToolbarContainerHidden {\n    display: none;\n}\n\n.appInstanceToolbarContainer {\n    display: inline-block;\n}\n\n.popperMenu {\n    max-height: 200px;\n    overflow: auto;\n}\n\n.popper {\n    z-index: 10000;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"appInstanceToolbarContainerHidden": "appInstanceToolbarContainerHidden__src-components-GlobalAdmin-styles__1w17Z",
	"appInstanceToolbarContainer": "appInstanceToolbarContainer__src-components-GlobalAdmin-styles__75OWm",
	"popperMenu": "popperMenu__src-components-GlobalAdmin-styles__31p9N",
	"popper": "popper__src-components-GlobalAdmin-styles__15ksP"
};