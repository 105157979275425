import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import HtmlEditor from '../HtmlEditor';
import TextField from '../TextField';
import PopupSelector from '../PopupSelector';
import FieldArray from '../FieldArray';
import { ActionButton } from '../ActionButton/ActionButton';
import Select from '../Select';
import SystemData from '../../lib/systemData';
import { ConfirmDialog } from '../ConfirmDialog';

class InvoiceEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = { allowSave: true };
	}

	render() {
		const { confirmation } = this.state;
		const {
			model, onCancel, submit, field, saveAndSend, reGenerate, form,
		} = this.props;
		const { CURRENCIES } = SystemData.genericData;
		return (
			<>
				<Grid item xs={12}>
					<Paper>
						<Box padding={2}>
							<PopupSelector
								title="Extra corrections"
								selected={model.extraCorrections}
								form={form}
								size="sm"
							>
								<FieldArray
									model={model}
									field={field}
									name="extraCorrections"
									update={() => this.forceUpdate()}
									render={(fld) => (
										<Grid container spacing={3}>
											<Grid item xs={6}>
												<TextField required label="Reason" {...fld('reason')} fullWidth margin="normal" />
											</Grid>
											<Grid item xs={3}>
												<TextField required float label="Amount" {...fld('correction')} fullWidth margin="normal" />
											</Grid>
											<Grid item xs={3}>
												<Select
													label="Currency"
													{...fld('currency')}
													nonSelected="(default)"
													required
													items={_.map(CURRENCIES, (v, k) => ({ label: `${v.name} (${k})`, value: k }))}
													fullWidth
													margin="normal"
												/>
											</Grid>
										</Grid>
									)}
								/>
							</PopupSelector>
							<TextField label="Invoice EUR" required float {...field('invoiceEUR')} fullWidth margin="normal" />
							<TextField label="Email address" required emails {...field('emailAddress')} fullWidth margin="normal" />
							<TextField label="Subject" required {...field('document.subject')} fullWidth margin="normal" />
							<HtmlEditor
								content={model.document.html}
								config={{ height: 600 }}
								events={{
									change: ({ editor }) => {
										model.document.html = editor.getData();
									},
									mode: ({ editor }) => {
										// Don't allow save in source mode, as change event is not fired when editing source.
										this.setState({ allowSave: editor.mode === 'wysiwyg' });
									},
								}}
							/>
						</Box>
					</Paper>
				</Grid>
				<Grid item>
					<ActionButton
						disabled={!this.state.allowSave}
						label="Ok"
						color="primary"
						onClick={submit}
					/>
				</Grid>
				<Grid item>
					<ActionButton
						label="Cancel"
						onClick={() => onCancel()}
						variant="text"
						color="primary"
					/>
				</Grid>
				<Grid item>
					<ActionButton
						disabled={!this.state.allowSave}
						label="Save And Send"
						color="secondary"
						onClick={async () => {
							if (form.hasErrors()) {
								return;
							}
							const close = () => this.setState({ confirmation: null });
							const confirm = {
								open: true,
								text: <span>
									Are you sure that you want to save and
									<b><Box component="span" color="error.main">send</Box></b>
									{' '}
									this invoice?
              </span>,
								onConfirm: () => {
									close();
									saveAndSend();
								},
								onCancel: close,
							};
							this.setState({ confirmation: confirm });
						}}
					/>
				</Grid>
				<Grid item>
					<ActionButton
						disabled={!this.state.allowSave}
						label="Re-generate invoice"
						color="secondary"
						onClick={async () => {
							if (form.hasErrors()) {
								return;
							}
							const close = () => this.setState({ confirmation: null });
							const confirm = {
								open: true,
								text: <span>
									Are you sure that you want to
									<b><Box component="span" color="error.main">re-generate</Box></b>
									{' '}
									this invoice? All potential changes of content, subject and total revenue will be overwritten
              </span>,
								onConfirm: () => {
									close();
									reGenerate();
								},
								onCancel: close,
							};
							this.setState({ confirmation: confirm });
						}}
					/>
				</Grid>
				{confirmation && <ConfirmDialog {...confirmation} />}
			</>
		);
	}
}

InvoiceEditor.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	saveAndSend: PropTypes.func.isRequired,
	reGenerate: PropTypes.func.isRequired,
};

InvoiceEditor.defaultProps = {
};

export default InvoiceEditor;
