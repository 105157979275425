import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Login from '../../components/Login';
import Spinner from '../../components/Spinner';
import withIdentity from '../withIdentity';
import SystemData from '../../lib/systemData';

@withIdentity()
class LoginContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
			authenticationFailed: false,
		};
	}

	async perfromOAuthLogin(credentials) {
		const { authenticationFailed } = this.state;
		if (!authenticationFailed) {
			await this.performLogin(null, null, credentials);
		}
	}

	async performLogin(email, password, credentials) {
		const {
			tryLogin, isAdministrator, isProgrammaticPublisher, isAudiencePublisher, history, redirect,
		} = this.props;
		const { inDemoMode, demoRequireLogin } = SystemData.genericData;
		if (inDemoMode && demoRequireLogin) {
			for (const part of demoRequireLogin.split('||')) {
				const [demoEmail, demoPassword] = part.split('|');
				if (email === demoEmail && password === demoPassword) {
					email = 'admin@mugs.info';
					password = 'MAGIC_DEMO_PASSWORD';
				}
			}
		}
		try {
			this.setState({ submitting: true });
			await tryLogin(email, password, credentials);

			if (redirect) {
				history.push(redirect);
			} else if (isAdministrator()) {
				history.push('/dashboard');
			} else if (isAudiencePublisher()) {
				history.push('/audience/dashboard');
			} else if (isProgrammaticPublisher()) {
				history.push('/dashboard');
			} else {
				this.setState({ submitting: false });
			}
		} catch (e) {
			this.setState({ submitting: false, authenticationFailed: e.code === 401 });
		}
	}

	performLogout() {
		const { logout } = this.props;
		logout();
	}

	render() {
		if (this.state.submitting) return <Spinner text="Attempting to login..." />;

		const { history } = this.props;
		const user = this.props.user();
		const username = (user && user.profile && (`${user.profile.email} (${user.profile.fullname})`)) || null;

		return (
			<Login
				login={(email, password) => this.performLogin(email, password)}
				oauth2Login={(credentials) => this.perfromOAuthLogin(credentials)}
				logout={() => this.performLogout()}
				isLoggedIn={user.authenticated}
				username={username}
				loginFailed={this.state.authenticationFailed}
				forgotPassword={() => history.push('/public/recoverPassword')}
			/>
		);
	}
}

LoginContainer.propTypes = {
	user: PropTypes.func,
	logout: PropTypes.func,
	tryLogin: PropTypes.func,
	redirect: PropTypes.string,
};

LoginContainer.defaultProps = {
};

export default withRouter(LoginContainer);
