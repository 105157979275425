import React, {
	Fragment,
	useEffect,
	useState,
	useCallback,
	useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Link } from '../../components/Link/Link';
import {
	Card, CardContent, Tooltip, IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { PageContentWrapper } from '../../components/Wrappers';
import { PrebidBuildConfiguration, PrebidBuildHandler } from '../../api/relevant';
import useRequest from '../../hooks/useRequest/useRequest';
import SimpleOperationWrapper from '../../components/SimpleOperationWrapper';
import PrebidBuildTable from '../../components/PrebidBuild/PrebidBuildTable';
import PrebidBuildFormModal from '../../components/PrebidBuild/PrebidBuildFormModal';
import { BuildContext } from '../../providers/BuildProvider/context';

function PrebidBuilds({ match, location }) {
	const [buildConfigs, setBuildConfigs] = useState([]);
	const [autoIncludedModules, setAutoIncludedModules] = useState({});
	const [setRequest, loading, error, reset] = useRequest({});
	const { builds: buildStatuses } = useContext(BuildContext);

	const getData = useCallback(() => {
		setRequest({
			requestFunction: () => PrebidBuildConfiguration.list(),
			requestCallback: (configs) => {
				setBuildConfigs(configs);
			},
		});
	}, [setRequest]);

	useEffect(() => {
		getData();
	}, [getData]);

	useEffect(() => {
		if (buildConfigs.length > 0) {
			const versions = Array.from(new Set(buildConfigs.map(({ version }) => version)));
			if (versions) {
				setRequest({
					requestFunction: () => PrebidBuildHandler.call('getAutoIncludedModules', { prebidVersions: versions }),
					requestCallback: (res) => {
						setAutoIncludedModules(res);
					},
				});
			}
		}
	}, [setRequest, buildConfigs]);

	const onDeleteBuild = (id) => {
		setRequest({
			requestFunction: () => PrebidBuildHandler.call('deletePrebidBuild', { id }),
			requestCallback: (res) => {
				setBuildConfigs(res);
			},
		});
	};

	return (
		<>
			<PageContentWrapper title="Prebid builds">
				<SimpleOperationWrapper
					loading={loading}
					error={error}
					onErrorModalClick={reset}
				>
					<Card>
						<CardContent>
							<Tooltip title="Add new build">
								<Link to={`${match.path}/add-prebid-build`}>
									<IconButton size="large">
										<AddIcon />
									</IconButton>
								</Link>
							</Tooltip>
							<PrebidBuildTable
								buildConfigs={buildConfigs}
								buildStatuses={buildStatuses}
								autoIncludedModules={autoIncludedModules}
								onDeleteBuild={onDeleteBuild}
							/>
						</CardContent>
					</Card>
				</SimpleOperationWrapper>
			</PageContentWrapper>
			<Route
				exact
				path={`${match.path}/add-prebid-build`}
				render={() => (
					<PrebidBuildFormModal
						match={match}
						location={location}
						onSave={() => getData()}
					/>
				)}
			/>
		</>
	);
}

export default PrebidBuilds;

PrebidBuilds.propTypes = {
	match: PropTypes.shape({
		path: PropTypes.string.isRequired,
	}).isRequired,
	location: PropTypes.shape({
		search: PropTypes.string.isRequired,
	}).isRequired,
};
