/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DataGridPro as MuiDataGrid } from '@mui/x-data-grid-pro';

function DataGrid({
	identifier,
	definitions,
	data,
	pagination,
	defaultFlex,
	initPageSize,
	...rest
}) {
	const [pageSize, setPageSize] = useState(initPageSize);
	const seenKeys = {};
	const fieldProps = (key, transform) => {
		const nr = seenKeys[key] || 0;
		seenKeys[key] = nr + 1;
		const res = { field: nr ? `${key}__${nr}` : key };
		if (transform) {
			res.valueGetter = (params) => transform(params.row[key], params.row, params);
		} else if (nr) {
			res.valueGetter = ({ row }) => row[key];
		}
		return res;
	};
	const columns = definitions.map(({
		key, title, style, format, transform, width: w, ...more
	}) => {
		const width = w || parseInt(style?.width, 10);
		return {
			...fieldProps(key, transform),
			headerName: title,
			sortable: !format,
			...(width && { width }),
			...(defaultFlex && !width && { flex: defaultFlex }),
			...(format && {
				renderCell: (params) => format(params.value, params.row, params),
			}),
			...more,
		};
	});
	return (
		<MuiDataGrid
			autoHeight
			getRowHeight={() => 'auto'}
			{...(!pagination ? {} : {
				pagination: true,
				pageSize,
				onPageSizeChange: (val) => setPageSize(val),
			})}
			getRowId={identifier}
			rows={data}
			columns={columns}
			{...rest}
		/>
	);
}

export default DataGrid;

DataGrid.propTypes = {
	identifier: PropTypes.func.isRequired,
	pagination: PropTypes.bool,
	definitions: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	defaultFlex: PropTypes.number,
	cellDefaultStyle: PropTypes.object,
	initPageSize: PropTypes.number,
	rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

DataGrid.defaultProps = {
	initPageSize: 10,
	pagination: false,
	rowsPerPageOptions: [10, 50, 100, 500, 1000, 1000000],
	cellDefaultStyle: undefined,
	defaultFlex: 1,
};
