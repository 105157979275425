import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { ActionButton } from '../ActionButton/ActionButton';
import DataTable from '../DataTable';
import MiscUtils from '../../lib/miscUtils';
import RecordTableTools from '../RecordTableTools';

const sortReports = (reports) => MiscUtils.alphaSorted(
	reports.map((report) => ({ ...report.settings, id: report.id })),
	'title',
);

const reportsTable = (data, hrefBase, deleteReport, byId) => (
	<Grid item xs={12}>
		<DataTable
			showCheckboxes={false}
			identifier={(row) => row.id}
			definitions={[
				{
					key: 'title',
					title: 'Select report',
					format: (title, row) => (
						<ActionButton
							fullWidth
							label={title}
							href={`${hrefBase}${row.id}`}
							color="primary"
						/>
					),
				},
				{
					key: 'id',
					title: 'Delete',
					align: 'right',
					style: { width: '25px' },
					format: (id) => (<RecordTableTools deleteAction={() => deleteReport(byId[id])} />),
				},
			]}
			data={data}
		/>
	</Grid>
);

const getReportSubTable = (userReports, hrefBase, deleteReport, byId, title) => (
	<>
		<Grid item xs={12}>
			<Typography variant="h3">
				{title}
			</Typography>
		</Grid>
		{ reportsTable(userReports, hrefBase, deleteReport, byId) }
		<Divider />
	</>
);

class ReportList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { reports, hrefBase, deleteReport } = this.props;
		const byId = _.keyBy(reports, 'id');
		const userReports = sortReports(reports.filter(({ userId }) => userId));
		const publicReports = sortReports(reports.filter(({ userId }) => !userId));
		return (
			<Grid item xs={12}>
				<Paper>
					<Box padding={2}>
						<Grid container spacing={3} justifyContent="space-between">
							<Grid item>
								<Typography variant="h2">
									Reports
								</Typography>
							</Grid>
							<Grid item>
								<ActionButton
									label="New report"
									href={`${hrefBase}add`}
									color="primary"
									icon={<AddIcon />}
								/>
							</Grid>
							{(!_.isEmpty(userReports)) && (
								getReportSubTable(userReports, hrefBase, deleteReport, byId, 'My reports')
							)}
							{(!_.isEmpty(publicReports)) && (
								getReportSubTable(publicReports, hrefBase, deleteReport, byId, 'Public reports')
							)}
						</Grid>
					</Box>
				</Paper>
			</Grid>
		);
	}
}

ReportList.propTypes = {
	reports: PropTypes.array.isRequired,
	hrefBase: PropTypes.string.isRequired,
	deleteReport: PropTypes.func.isRequired,
};

ReportList.defaultProps = {
};

export default ReportList;
