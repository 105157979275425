import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Popover, Box } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

function HelpIconButton({ children }) {
	const [isOpen, setIsOpen] = useState(false);
	const anchorEl = useRef();

	return (
		<IconButton ref={anchorEl} onClick={() => setIsOpen(!isOpen)}>
			<HelpIcon fontSize="small" />
			<Popover
				open={isOpen}
				anchorEl={anchorEl.current}
				onClose={() => setIsOpen(false)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Box sx={{ p: 2, maxWidth: '248px' }}>
					{children}
				</Box>
			</Popover>

		</IconButton>
	);
}

export default HelpIconButton;

HelpIconButton.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
