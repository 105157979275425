import React, {
	createContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import useSearchFilter from './hooks/useSearchFilter';
import usePropertyFilter from './hooks/usePropertyFilter';
import useSort from './hooks/useSort';

export const FilterContext = createContext([]);

// Get subsection of filtered reports filtered by different methods
function getResultingFilteredReports(filteredBySearch, filteredByProperty) {
	return filteredBySearch.filter((report) => filteredByProperty.includes(report));
}

export default function FilterProvider({ children }) {
	const [reports, setReports] = useState([]);
	const { filteredBySearchReports, onSearchChange } = useSearchFilter(reports);
	const {
		filteredByPropertyReports,
		activeFilters,
		currentSharingStateFilter,
		onSharingStateChange,
	} = usePropertyFilter(reports);
	const [filteredReports, setFilteredReports] = useState(
		getResultingFilteredReports(filteredBySearchReports, filteredByPropertyReports),
	);
	const {
		sortedReports,
		sortProperty,
		sortDirectionAscending,
		onSortPropertyChange,
		onSortDirectionAscendingChange,
	} = useSort(filteredReports);

	useEffect(() => {
		const res = getResultingFilteredReports(filteredBySearchReports, filteredByPropertyReports);
		setFilteredReports(res);
	}, [filteredBySearchReports, filteredByPropertyReports]);

	const providerValue = useMemo(() => ({
		reports,
		filteredReports: sortedReports,
		setReports,
		filteredBySearchReports,
		filteredByPropertyReports,
		sortProperty,
		sortDirectionAscending,
		activeFilters,
		currentSharingStateFilter,
		onSortPropertyChange,
		onSortDirectionAscendingChange,
		onSharingStateChange,
		onSearchChange,
	}), [
		filteredByPropertyReports,
		filteredBySearchReports,
		sortedReports,
		reports,
		sortProperty,
		sortDirectionAscending,
		activeFilters,
		currentSharingStateFilter,
		onSortPropertyChange,
		onSortDirectionAscendingChange,
		onSearchChange,
		onSharingStateChange,
	]);

	return (
		<FilterContext.Provider value={providerValue}>
			{ children }
		</FilterContext.Provider>
	);
}

FilterProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
