import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CheckedIcon from '@mui/icons-material/Check';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AllMappingDimensions from 'relevant-shared/mappingDimensions/allMappingDimension';
import MatchReport from './matchReport';
import { AdvertiserMapping } from '../../api/relevant';
import { ActionButton } from '../ActionButton/ActionButton';
import DataTable from '../DataTable';
import RecordTableTools from '../RecordTableTools';
import OperationWrapper from '../OperationWrapper';
import { stores } from '../../stores';
import AdvertiserEdit from './advertiserEdit';

class AdvertiserMappingList extends React.Component {
	constructor(props) {
		super(props);
		this.state = { tabSelected: props.initTabSelected };
	}

	async load(dimType) {
		const [list, defaultId] = await Promise.all([
			AdvertiserMapping.call('getAll'),
			AdvertiserMapping.call('getDefault', { dimType }),
		]);
		this.setState({ list: _.filter(list, { dimType }), defaultId });
	}

	renderMappingDimension(mappingDim) {
		const {
			path, metric, type, editIndividualAdvertisers,
		} = this.props;
		const { list = [], defaultId } = this.state;
		const isAdmin = stores.identity.isAdministrator();
		const isReadOnly = (mapping) => !isAdmin && !mapping.publisherId;
		return (
			<Fragment key={mappingDim.name}>
				<Grid item xs={12}>
					<OperationWrapper
						fn={async (op) => {
							this.op = op;
							await this.load(mappingDim.name);
						}}
					>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Paper>
									<Box padding={2}>
										<Typography variant="h2">
											{mappingDim.uiCName}
											{' '}
											Mappings
										</Typography>
										<DataTable
											showCheckboxes={false}
											selectableRows={false}
											identifier={(row) => row.id}
											definitions={[
												{ key: 'name', title: 'Name' },
												{
													key: 'id',
													title: `Matching ${mappingDim.pluralName}`,
													style: { width: '80px' },
													format: (__, mapping) => (
														<MatchReport
															type={type}
															metric={metric}
															mapping={mapping}
															dimType={mappingDim.name}
														/>
													),
												},
												{
													key: 'parent',
													title: 'Parent mapping',
													format: (parent) => (list.find((m) => m.id === parent) || {}).name || '',
												},
												{
													hide: isAdmin,
													key: 'id',
													title: 'Is read-only',
													format: (id, mapping) => isReadOnly(mapping) && <span><CheckedIcon /></span>,
												},
												{
													hide: !isAdmin,
													key: 'id',
													title: 'User visible',
													format: (id, mapping) => mapping.userVisible && <span><CheckedIcon /></span>,
												},
												{
													key: 'id',
													title: 'Used by other mappings',
													format: (__, mapping) => !!mapping.refsRecorded && <span><CheckedIcon /></span>,
												},
												{
													key: 'id',
													title: 'Default mapping',
													style: { width: '170px' },
													format: (id, mapping) => (
														<ActionButton
															label={id === defaultId ? 'Remove as default' : 'Set as default'}
															color={id === defaultId ? 'primary' : undefined}
															onClick={() => this.op.reload(async () => {
																await mapping.setAsDefault({ value: id !== defaultId });
																await this.load(mappingDim.name);
															})}
														/>
													),
												},

												{
													key: 'id',
													title: '',
													align: 'right',
													format: (id, mapping) => !isReadOnly(mapping)
										&& (
											<RecordTableTools
												editHref={`${path}/${id}`}
												deleteAction={(mapping.refsRecorded || isReadOnly(mapping)) ? null : (() => this.op.reload(async () => {
													await mapping.delete();
													await this.load(mappingDim.name);
												}))}
											/>
										),
												},
											].filter((d) => !d.hide)}
											data={_.sortBy(list, 'name')}
										/>
									</Box>
								</Paper>
							</Grid>
							<Grid item>
								<ActionButton
									label="Add"
									href={`${path}/add?dimType=${mappingDim.name}`}
									icon={<AddIcon />}
									color="primary"
								/>
							</Grid>
						</Grid>
					</OperationWrapper>
				</Grid>
				{editIndividualAdvertisers && (
					<Grid item xs={12}>
						<Paper>
							<Box padding={2}>
								<Typography variant="h2">
									Individual
									{' '}
									{mappingDim.pluralCname}
								</Typography>
								<AdvertiserEdit mappingDim={mappingDim} />
							</Box>
						</Paper>
					</Grid>
				)}
			</Fragment>
		);
	}

	render() {
		const { tabSelected } = this.state;
		const mappingDim = AllMappingDimensions[tabSelected];
		return (
			<>
				<AppBar position="static">
					<Tabs
						color="primary"
						textColor="inherit"
						value={tabSelected}
						onChange={(__, newTab) => this.setState({ tabSelected: newTab })}
					>
						{AllMappingDimensions.map(({ pluralCname }, idx) => (
							<Tab
								key={pluralCname}
								label={pluralCname}
								id={`mapping-tab-${idx}`}
								aria-controls={`mapping-tabpanel-${idx}`}
							/>
						))}
					</Tabs>
				</AppBar>
				{this.renderMappingDimension(mappingDim)}
			</>
		);
	}
}

AdvertiserMappingList.propTypes = {
	path: PropTypes.string.isRequired,
	type: PropTypes.string,
	metric: PropTypes.string,
	editIndividualAdvertisers: PropTypes.bool,
	initTabSelected: PropTypes.number,
};

AdvertiserMappingList.defaultProps = {
	type: 'programmatic',
	metric: 'revenue',
	editIndividualAdvertisers: true,
	initTabSelected: 0,
};

export default AdvertiserMappingList;
