import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PeopleIcon from '@mui/icons-material/People';
import DeleteButton from '../DeleteButton';
import RestartButton from '../RestartButton';
import { ConfirmDialog } from '../ConfirmDialog';

function AppInstanceToolbar({
	selectedInstanceIds,
	instanceInfo,
	deployState,
	onDeleteClick,
	onDeployClick,
	onRestartClick,
	onViewUsersClick,
}) {
	const numSelectedInstances = selectedInstanceIds.length;
	const deleteMsg = numSelectedInstances > 1
		? `Are you sure you want to delete these ${numSelectedInstances} instances?`
		: 'Are you sure you want to delete this instance?';

	const allUnresponding = selectedInstanceIds.every((id) => !instanceInfo[id] || Object.keys(instanceInfo[id]).length === 0);
	// Ineligible for deploy if unresponding or is the currently used instance
	const numIneligibleInstances = selectedInstanceIds.filter((id) => (
		!instanceInfo[id] || Object.keys(instanceInfo[id]).length === 0
		|| instanceInfo[id].isCurrentInstance
	)).length;

	// Hide deploy button if all selected instances are ineligible
	// or if deploy(s) are ongoing
	const hideDeployAndRestart = numIneligibleInstances === selectedInstanceIds.length
		|| deployState.deploysAreDone === false;
	return (
		<>
			<Tooltip title="Delete">
				<span>
					<ConfirmDialog
						text={deleteMsg}
						onConfirm={() => onDeleteClick()}
					>
						<DeleteButton />
					</ConfirmDialog>
				</span>
			</Tooltip>
			{ !allUnresponding
				&& (
					<Tooltip title="View users">
						<IconButton onClick={({ target }) => onViewUsersClick(target)} size="large">
							<PeopleIcon />
						</IconButton>
					</Tooltip>
				)}
			{ !hideDeployAndRestart
				&& (
					<>
						<Tooltip title="Deploy">
							<IconButton onClick={({ target }) => onDeployClick(target)} size="large">
								<ArrowUpwardIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Restart">
							<span>
								<ConfirmDialog
									text="Are you sure you want to restart these instances?"
									onConfirm={() => onRestartClick()}
								>
									<RestartButton />
								</ConfirmDialog>
							</span>
						</Tooltip>
					</>
				)}
		</>
	);
}
export default AppInstanceToolbar;

AppInstanceToolbar.propTypes = {
	selectedInstanceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
	onDeleteClick: PropTypes.func.isRequired,
	onDeployClick: PropTypes.func.isRequired,
	onRestartClick: PropTypes.func.isRequired,
	onViewUsersClick: PropTypes.func.isRequired,
	instanceInfo: PropTypes.objectOf(PropTypes.object),
	deployState: PropTypes.shape({
		deploysAreDone: PropTypes.bool,
		deploys: PropTypes.object,
	}),
};

AppInstanceToolbar.defaultProps = {
	instanceInfo: {},
	deployState: {},
};
