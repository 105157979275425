const _ = require('lodash');
const { COMP_PFX } = require('./constants');

class ReportCalculatorTableFormatter {
	constructor(calculator) {
		Object.assign(this, calculator, { calculator });
	}

	filterByImportance(rootArray) {
		const importancePercentage = this.settings.importanceTabPerc || 0;
		const { reportData, allDataSums } = this;
		const { sums, groupBy } = reportData.settings;
		const { CALCULATED_SUMS = {} } = this.constants;

		const calcMetric = sums
			.map((sum) => CALCULATED_SUMS[sum])
			.find((calculatedSum) => calculatedSum);
		const metric = calcMetric ? calcMetric.trimBy : allDataSums[0];
		let getter = (o) => o[metric];
		if (reportData.compareToRange) {
			const compMetric = `${COMP_PFX}${metric}`;
			getter = (o) => o[metric] + o[compMetric];
		}

		let all = [];
		// TODO: what does this do?
		// eslint-disable-next-line max-len
		const addObjects = (arr, level = 0) => (level === groupBy.length - 1 ? all.push(...arr) : arr.forEach((e) => addObjects(e.data, level + 1)));
		addObjects(rootArray);
		all = all.map((e) => e.data);
		const total = _.sumBy(all, getter);
		const sorted = _.sortBy(all, getter).reverse();

		let sum = 0;
		let minAccept = 0;
		// TODO: re-write without restricted syntax
		// eslint-disable-next-line no-restricted-syntax
		for (const element of sorted) {
			sum += getter(element);
			if (sum >= (importancePercentage / 100) * total) {
				minAccept = getter(element);
				break;
			}
		}

		const copy = (originalArray, level = 0) => {
			const isFinal = (level === groupBy.length - 1);
			const array = originalArray;

			const remaining = array.map((element) => {
				if (isFinal) {
					return (getter(element.data) >= minAccept) ? element : null;
				}
				const children = copy(element.data, level + 1);
				if (!children.length) {
					return null;
				}
				return {
					...element,
					data: children,
					totals: this.calculator.getTotals(children),
				};
			});
			return remaining.filter((e) => e);
		};
		return copy(rootArray);
	}

	getTableFormatted(arrData) {
		const sortDataArray = (dataArray, sortFunction) => {
			const sortedArray = _.sortBy(dataArray, sortFunction);
			return sortedArray.map((dataSet) => {
				const isFinalLevel = !Array.isArray(dataSet.data) || _.isEmpty(dataSet.data);
				return (isFinalLevel)
					? dataSet
					: { ...dataSet, data: sortDataArray(dataSet.data, sortFunction) };
			});
		};

		const calculateElementSumValue = (element, calculateSumVal, sortReversed) => {
			const dataObj = Object.prototype.hasOwnProperty.call(element, 'totals')
				? element.totals
				: element.data;

			const sumValues = calculateSumVal(dataObj);

			return (sortReversed)
				? sumValues * -1
				: sumValues;
		};
		let res = arrData;
		// Filter by importance if needed
		if (this.calculator.isFilteringByImportance()) {
			res = this.filterByImportance(arrData);
		}
		// Apply sorting (if set and valid)
		const {
			sortSum, sortReversed, sortSumIsComp, useCompareTo,
		} = this.settings;
		const { sums } = this.reportData.settings;
		if (!sortSum || !sums.includes(sortSum)) {
			return res;
		}
		if (sortSumIsComp && !useCompareTo) {
			return res;
		}

		const calculateSumVal = _.partial(
			(...args) => this.calculator.calculateSumVal(...args),
			_,
			sortSum,
			undefined,
			sortSumIsComp,
		);

		const sortFunction = _.partial(
			calculateElementSumValue,
			_,
			calculateSumVal,
			sortReversed,
		);
		res = sortDataArray(res, sortFunction);
		return res;
	}
}

module.exports = ReportCalculatorTableFormatter;
