import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import SettingsIcon from '@mui/icons-material/Settings';
import Form from '../../containers/Form';
import { ActionButton } from '../ActionButton/ActionButton';
import DataTable from '../DataTable';
import RecordTableTools from '../RecordTableTools';

@Form
class DspSettingsForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { model } = this.props;
		return (
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<Paper>
						<Box
							display="flex"
							alignItems="center"
							padding={2}
						>
							<SettingsIcon />
							{' '}
							<Typography variant="h4">
								Price lists
							</Typography>
						</Box>
						<Divider />
						<TableContainer>
							<DataTable
								showCheckboxes={false}
								identifier={(row) => row.id}
								definitions={[
									{ key: 'name', title: 'Name' },
									{
										key: 'id',
										title: '',
										align: 'right',
										padding: 'none',
										whenNull: () => null,
										format: (_x, row) => (
											<RecordTableTools
												editHref={`/settings/pricelists/${row.id}`}
												deleteAction={row.isInUse ? null : () => this.props.deletePricelist(row)}
											/>
										),
									},
								]}
								data={model.pricelists}
							/>
						</TableContainer>
					</Paper>
				</Grid>
				<Grid item>
					<ActionButton
						label="New price list"
						href="/settings/pricelists/add"
						icon={<AddIcon />}
						color="primary"
					/>
				</Grid>
			</Grid>
		);
	}
}

DspSettingsForm.propTypes = {
	model: PropTypes.object.isRequired,
	deleteDsp: PropTypes.func.isRequired,
	deletePricelist: PropTypes.func.isRequired,
};

DspSettingsForm.defaultProps = {
	onSubmit: () => {},
};

export default DspSettingsForm;
