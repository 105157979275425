import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TableContainer from '@mui/material/TableContainer';

import DateUtils from '../../lib/dateUtils';
import storeContainer from '../../containers/storeContainer';
import OperationWrapper from '../OperationWrapper';
import PopupSelector from '../PopupSelector';
import { AccessHistory as AccessHistoryApi } from '../../api/relevant';
import DatePicker from '../DatePicker';
import DataTable from '../DataTable';
import Checkbox from '../Checkbox';

@storeContainer()
class AccessHistory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			start: DateUtils.fullDay(new Date(), -30),
			end: DateUtils.today(),
			accesses: [],
		};
	}

	getEntries() {
		const {
			accesses, includeGhosting, publishers, users,
		} = this.state;
		const pubsById = _.keyBy(publishers || [], 'id');
		const usersById = _.keyBy(users || [], 'id');
		const entries = {};
		const getEntry = (userId) => {
			if (entries[userId]) {
				return entries[userId];
			}
			const user = usersById[userId];
			if (!user) {
				return null;
			}
			const pub = pubsById[user.publisherId];
			if (!pub) {
				return null;
			}
			entries[userId] = {
				user, pub, normal: {}, ghosting: {}, all: {},
			};
			return entries[userId];
		};
		accesses.forEach((access) => {
			_.forOwn(access.users, (userData, userId) => {
				_.forOwn(userData.byIp, (ipData, ip) => {
					const entry = getEntry(userId);
					if (entry) {
						const add = (dst) => {
							const { date } = access;
							const newIpData = {
								...ipData, date, ip, isGhosting: !!ipData.isGhosting,
							};
							entry[dst][date] = (entry[dst][date] || []).concat(newIpData);
						};
						add('all');
						add(ipData.isGhosting ? 'ghosting' : 'normal');
					}
				});
			});
		});
		const res = _.sortBy(Object.values(entries), (e) => e.pub.name, (e) => e.user.firstname);
		return res.filter((a) => includeGhosting || !_.isEmpty(a.normal));
	}

	renderAccesses(obj) {
		const arr = _.sortBy(_.flatten(Object.values(obj)), 'date').reverse();
		if (!arr.length) {
			return 0;
		}
		return (
			<PopupSelector
				size="sm"
				title={Object.keys(obj).length.toString()}
			>
				<DataTable
					showCheckboxes={false}
					selectableRows={false}
					identifier={(row) => `${row.date}_${row.ip}`}
					definitions={[
						{
							key: 'date',
							title: 'Date',
							style: {
								whiteSpace: 'nowrap',
							},
							format: (date) => moment.utc(date).format('YYYY-MM-DD'),
						},
						{
							key: 'ip',
							title: 'IP Address',
						},
						{
							key: 'isGhosting',
							title: 'Is ghosting',
							format: (ghosting) => (<Box component="span" color={ghosting ? 'error.main' : 'success.main'}>{ghosting ? 'YES' : 'NO' }</Box>),
						},
					]}
					data={arr}
				/>
			</PopupSelector>
		);
	}

	render() {
		const { start, end } = this.state;
		const fld = (name) => ({ name, value: this.state[name], onChange: (ev) => this.setState({ [name]: ev.target.value }) });
		const loadAccesses = async () => this.setState({
			accesses: await AccessHistoryApi.call('getAccesses', { start, end }),
		});
		const loadList = async (listName) => {
			const list = this.props[listName];
			if (this.state[listName]) {
				return;
			}
			if (!list.isReady()) {
				await list.loadAll();
			}
			this.setState({ [listName]: list.all });
		};

		return (
			<>
				<DialogTitle>
					Access History
				</DialogTitle>
				<DialogContent dividers>
					<OperationWrapper
						fn={async () => {
							const ops = ['publishers', 'users'].map((name) => loadList(name)).concat(loadAccesses());
							await Promise.all(ops);
						}}
						reloadable
						load={[start, end]}
					>
						<Grid container spacing={3} alignItems="center">
							<Grid item>
								<DatePicker
									floatingLabelText="Start date"
									maxDate={end}
									autoOk
									{...fld('start')}
								/>
							</Grid>
							<Grid item>
								<DatePicker
									floatingLabelText="End date"
									minDate={start}
									maxDate={DateUtils.today()}
									autoOk
									{...fld('end')}
								/>
							</Grid>
							<Grid item>
								<Checkbox
									label="Include only ghosting access"
									{...fld('includeGhosting')}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h3">
									Matches
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TableContainer component={Paper}>
									<DataTable
										showCheckboxes={false}
										selectableRows={false}
										identifier={(row) => row.user.id}
										definitions={[
											{
												key: 'pub',
												title: 'Publisher',
												format: (pub) => pub.name,
											},
											{
												key: 'user',
												title: 'User\'s name',
												format: (user) => (user.firstname ? `${user.firstname} ${user.lastname}` : '[NO NAME]'),
											},
											{
												key: 'user',
												title: 'Email',
												format: (user) => user.email,
											},
											{
												key: 'normal',
												title: 'Days of normal access',
												format: (obj) => this.renderAccesses(obj),
											},
											{
												key: 'ghosting',
												title: 'Days of ghosting access',
												format: (obj) => this.renderAccesses(obj),
											},
											{
												key: 'all',
												title: 'Days of any access',
												format: (obj) => this.renderAccesses(obj),
											},
										]}
										data={this.getEntries()}
									/>
								</TableContainer>
							</Grid>
						</Grid>
					</OperationWrapper>
				</DialogContent>
			</>
		);
	}
}

AccessHistory.wrappedComponent.propTypes = {
	publishers: PropTypes.object.isRequired,
	users: PropTypes.object.isRequired,
};

AccessHistory.wrappedComponent.defaultProps = {

};

export default AccessHistory;
