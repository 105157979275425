import React from 'react';
import TopBarReportOptions from './TopBarReportOptions';
import TopBarReportsOverviewOptions from './TopBarReportsOverviewOptions';
import useTopBar from '../../hooks/useTopbar';
import TopBarHeader from './TopBarHeader';
import TopBarDashboardOptions from './TopBarDashboardOptions';
import TopBarEventsOptions from './TopBarEventsOptions';
import TopBarPublisherAccountsOptions from './TopBarPublisherAccountsOptions';

export default function TopBarOptions() {
	const {
		title,
		titleNavigation,
		status,
		settings: { type, ...rest },
	} = useTopBar();

	const components = {
		report: () => (
			<TopBarReportOptions
				title={title}
				status={status}
				{...rest}
			/>
		),
		reportsOverview: () => (
			<TopBarReportsOverviewOptions
				titleNavigation={
					titleNavigation
				}
				{...rest}
			/>
		),
		publisherAccounts: () => (
			<TopBarPublisherAccountsOptions
				title={title}
			/>
		),
		dashboard: () => (
			<TopBarDashboardOptions
				availableWidgets={rest.availableWidgets}
				onSelect={rest.onSelect}
			/>
		),
		events: () => (
			<TopBarEventsOptions
				title={title}
				onAddClick={rest.onAddClick}
			/>
		),
		default: () => (
			<TopBarHeader
				title={title}
				color="offWhite"
			/>
		),
	};

	return (components[type] || components.default)();
}
