/* eslint-disable
	no-multi-assign,
	no-plusplus,
	no-useless-escape,
	no-await-in-loop,
	no-restricted-syntax,
	no-continue
*/
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import MinusIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DeleteButton from '../DeleteButton';
import TextField from '../TextField';
import { ActionButton } from '../ActionButton/ActionButton';
import DataTable from '../DataTable';
import OperationWrapper from '../OperationWrapper';

const DUMMY_KEY = '__dummy';
const CANDIDATE_CHUNK = 5;
const MAX_CHECK_FIRST_WORD_CHARS = 4;
const MAX_ONLY_CHECK_FIRST_WORD_CHARS = 0;
const MAX_JS_LOAD_CHUNK_MS = 20;
const FORBIDDEN_WORDS = Object.getOwnPropertyNames(Object.getPrototypeOf({}));
const FORBIDDEN_WORD_MAP = _.zipObject(FORBIDDEN_WORDS, Array(FORBIDDEN_WORDS.length).fill(true));

class SearchBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			candidates: [],
			chunkSz: props.candidateChunk,
			searchStr: '',
			searchData: {
				byId: {},
				byFirstWord: {},
				byAnyWord: {},
				byAnyWordFull: {},
			},
		};
		this.initSearchDataPromise = new Promise((initResolve, initReject) => {
			Object.assign(this, { initResolve, initReject });
		});
	}

	componentDidMount() {
		this.globalListener = (ev) => {
			const { showSearchResult } = this.state;
			if (!showSearchResult) {
				return;
			}
			const container = this.searchContainerElm;
			if (!container) {
				return;
			}
			if (ev.type === 'keyup') {
				if (ev.keyCode !== 27) {
					return;
				}
			} else {
				for (let elm = ev.target; elm; elm = elm.parentNode) {
					if (elm === container) {
						return;
					}
				}
			}
			ev.stopPropagation();
			ev.preventDefault();
			this.setState({ showSearchResult: false });
		};
		addEventListener('mousedown', this.globalListener, true);
		addEventListener('keyup', this.globalListener, true);
	}

	componentWillUnmount() {
		if (this.globalListener) {
			window.removeEventListener('click', this.globalListener, true);
			window.removeEventListener('keydown', this.globalListener, true);
		}
	}

	sortRows(rowArr) {
		const { sortBy, getName } = this.props;
		return _.sortBy(rowArr, sortBy || ((r) => getName(r).toLowerCase()));
	}

	splitStr(str) {
		const { splitRegexp } = this.props;
		return str.toLowerCase().split(splitRegexp).filter((w) => w.length && !FORBIDDEN_WORD_MAP[w]);
	}

	async initSearchData(loadPromise, preSelectedRows) {
		let hasResolved;
		let allArr;
		const resolveIfNeeded = () => {
			if (!hasResolved) {
				this.initResolve();
				hasResolved = true;
			}
		};
		const { getIdentifier, getName } = this.props;
		const searchData = { ...this.state.searchData, byId: _.keyBy(preSelectedRows, (row) => getIdentifier(row)) };
		this.setState({ searchData });
		try {
			allArr = this.sortRows(await loadPromise);
		} catch (e) {
			this.initReject(e);
		}
		let start = new Date();
		const addTo = (dst, key, row) => {
			const arrDst = dst[key];
			if (arrDst) {
				arrDst.push(row);
			} else {
				dst[key] = [row];
			}
		};
		for (const row of allArr) {
			searchData.byId[getIdentifier(row)] = row;
			row.wordParts = {};
			this.splitStr(getName(row)).forEach((word, wordIdx) => {
				for (let i = 1; i <= word.length; i++) {
					const wordPart = word.substr(0, i);
					if (!row.wordParts[wordPart]) {
						row.wordParts[wordPart] = true;
						addTo(searchData.byAnyWord, wordPart, row);
						if (!wordIdx) {
							addTo(searchData.byFirstWord, wordPart, row);
						}
					}
					row.wordParts[wordPart] = true;
				}
				addTo(searchData.byAnyWordFull, word, row);
			});
			if (new Date() - start > MAX_JS_LOAD_CHUNK_MS) {
				resolveIfNeeded();
				await new Promise((r) => setTimeout(r));
				start = new Date();
			}
		}
		resolveIfNeeded();
		this.setSearchString(this.state.searchStr || '');
	}

	async load() {
		const { selected, loadPreSelectedRows, loadAllRows } = this.props;
		const loadPromise = _.isFunction(loadAllRows) ? loadAllRows() : loadAllRows;
		let preSelectedRows = [];
		if (selected.length) {
			preSelectedRows = await loadPreSelectedRows(selected);
		}
		this.initSearchData(loadPromise, preSelectedRows);
	}

	findByString(str) {
		const { searchData } = this.state;
		const { splitRegexp, getIdentifier } = this.props;
		const words = this.splitStr(str);
		if (!words.length) {
			return [];
		}
		const endsWithSpace = !!str[str.length - 1].match(splitRegexp);

		/**
		 * When we're starting writing a word - during the first few characters (MAX_CHECK_FIRST_WORD_CHARS)
		 * only check 'byFirstWord' primarily. If we don't find anything and search string is VERY short
		 * (MAX_ONLY_CHECK_FIRST_WORD_CHARS) => give up and return nothing.
		 * */
		if (!endsWithSpace && words.length === 1 && words[0].length <= MAX_CHECK_FIRST_WORD_CHARS) {
			const res = searchData.byFirstWord[words[0]] || [];
			if (res.length || words[0].length <= MAX_ONLY_CHECK_FIRST_WORD_CHARS) {
				return this.sortRows(res);
			}
		}
		let candidateSets = words.map((word, idx) => {
			if (idx === words.length - 1 && !endsWithSpace) {
				return searchData.byAnyWord[word] || []; // only last word without space after we'll match partially
			}
			return searchData.byAnyWordFull[word] || []; // other words must be 'full'
		});
		if (candidateSets.find((c) => !c.length)) {
			return [];
		}
		candidateSets = _.sortBy(candidateSets, 'length'); // start with smallest matching set for better performance
		let survivors;
		candidateSets.forEach((arr, idx) => { // For an advertiser to "survive" it must match all words
			const stillSurvives = {};
			arr.forEach((row) => {
				const id = getIdentifier(row);
				if (!idx || survivors[id]) {
					stillSurvives[id] = row;
				}
			});
			if (_.isEmpty(stillSurvives)) {
				survivors = {};
				return; // no match
			}
			survivors = stillSurvives;
		});
		return this.sortRows(_.values(survivors));
	}

	setSearchString(str) {
		const newCandidates = this.findByString(str);
		this.setState({
			searchStr: str,
			candidates: newCandidates,
			chunkSz: this.props.candidateChunk,
			showSearchResult: true,
		});
	}

	render() {
		const {
			style,
			selected,
			onChange,
			onlySingleSelect,
			onSingleSelect,
			hideEmptySelected,
			getIdentifier,
			shouldExclude,
			getName,
			label,
			searchDataCols,
			resultDataCols,
			renderAfterSearchField,
			candidateChunk,
			fullWidth,
		} = this.props;
		const {
			searchData, candidates, showSearchResult, searchStr, chunkSz,
		} = this.state;

		const COMMON_TABLE_PROPS = {
			showCheckboxes: false,
			selectableRows: false,
			cellDefaultStyle: { paddingLeft: '3px', paddingRight: '3px' },
			identifier: (row) => getIdentifier(row),
		};

		const DEFAULT_DATA_COLS = [{
			key: DUMMY_KEY,
			title: 'Name',
			whenNull: (row) => (!shouldExclude(row) ? getName(row) : (
				<Box color="grey.400">
					{getName(row)}
				</Box>
			)),
		}];

		let selectedArr = [];
		const selectedMap = _.zipObject(selected, Array(selected.length).fill(true));
		const idsToObjects = (arr) => arr.map((id) => searchData.byId[id]).filter((a) => a);
		const notify = (arr, changeRowId, isAdd) => {
			const newArr = idsToObjects(arr);
			onChange(arr, newArr, changeRowId ? idsToObjects([changeRowId])[0] : null, isAdd);
			onSingleSelect(arr[0], newArr[0]);
			if (onlySingleSelect) {
				this.setState({ showSearchResult: false });
				this.setSearchString('');
			}
		};
		if (searchData) {
			selectedArr = this.sortRows(idsToObjects(selected));
		}
		return (
			<OperationWrapper fn={() => this.load()}>
				<div
					onBlur={(ev) => {
						ev.preventDefault();
						this.searchResClicked = false;
						setTimeout(() => {
							if (!this.searchResClicked) {
								this.setState({ showSearchResult: false });
							}
						});
					}}
					onFocus={() => this.setState({ showSearchResult: true })}
					ref={(elm) => {
						if (elm) {
							const input = elm.getElementsByTagName('INPUT')[0];
							if (input) {
								input.setAttribute('autocomplete', 'off');
							}
						}
					}}
				>
					<TextField
						label={label}
						name="searchRows"
						value={searchStr}
						onChange={(ev) => this.setSearchString(ev.target.value)}
						fullWidth={fullWidth}
					/>
					{showSearchResult && !!(searchStr || '').trim().length
						&& (
							<OperationWrapper
								fn={async () => {
									await this.initSearchDataPromise;
									this.setSearchString(searchStr);
								}}
							>
								<Box position="relative">
									<div
										role="none"
										style={({
											position: 'absolute', zIndex: 2, width: '100%', ...style,
										})}
										ref={(elm) => { this.searchContainerElm = elm; }}
										onMouseDown={() => {
											setTimeout(() => {
												this.searchResClicked = true;
											});
										}}
									>
										<Card>
											<CardContent>
												<DataTable
													{...COMMON_TABLE_PROPS}
													noHeader
													definitions={[
														{
															key: DUMMY_KEY,
															title: '',
															style: { width: '40px' },
															padding: 'none',
															whenNull: (row) => {
																const rowId = getIdentifier(row);
																const exclude = shouldExclude(row);
																if (selectedMap[rowId]) {
																	return (
																		<IconButton
																			onClick={() => notify(_.without(selected, rowId), rowId, false)}
																			size="large"
																		>
																			<MinusIcon color="error" />
																		</IconButton>
																	);
																}
																return (
																	<IconButton
																		onClick={() => !exclude && notify(selected.concat(rowId), rowId, true)}
																		disabled={exclude}
																		size="large"
																	>
																		<Box component="span" color={exclude ? undefined : 'success.light'}>
																			<AddIcon />
																		</Box>
																	</IconButton>
																);
															},
														},
														...(searchDataCols || DEFAULT_DATA_COLS),
													]}
													data={_.take(candidates, chunkSz)}
												/>
												{candidates.length > chunkSz
											&& (
												<Button
													onClick={() => {
														this.setState({ chunkSz: chunkSz + candidateChunk });
													}}
													fullWidth
												>
													Show
													<strong>
														{candidates.length - chunkSz}
&nbsp;
													</strong>
													more
												</Button>
											)}
											</CardContent>
										</Card>
									</div>
								</Box>
							</OperationWrapper>
						)}
				</div>
				{renderAfterSearchField && (_.isFunction(renderAfterSearchField) ? renderAfterSearchField(this) : renderAfterSearchField)}
				{!onlySingleSelect && !(!selectedArr.length && hideEmptySelected) && (
					<DataTable
						{...COMMON_TABLE_PROPS}
						noHeader
						definitions={[
							{
								key: DUMMY_KEY,
								title: '',
								style: { width: '70px' },
								padding: 'none',
								headerElm: selectedArr.length && (
									<ActionButton
										label="Clear all"
										color="primary"
										onClick={() => notify([])}
										icon={<DeleteIcon />}
									/>
								),
								whenNull: (row) => (
									<DeleteButton
										onClick={() => notify(_.without(selected, getIdentifier(row)))}
									/>
								),
							},
							...(resultDataCols || DEFAULT_DATA_COLS),
						]}
						data={selectedArr}
					/>
				)}
			</OperationWrapper>
		);
	}
}

SearchBar.propTypes = {
	style: PropTypes.object,
	getIdentifier: PropTypes.func, // Returns identifier of object
	getName: PropTypes.func, // Returns identifier of object
	shouldExclude: PropTypes.func, // Returns true if a search result should be "excluded" and not possible to select (grayed out currently)
	sortBy: PropTypes.any,
	loadPreSelectedRows: PropTypes.func,
	loadAllRows: PropTypes.any.isRequired,
	splitRegexp: PropTypes.object,
	label: PropTypes.string.isRequired,
	searchDataCols: PropTypes.array,
	resultDataCols: PropTypes.array,
	selected: PropTypes.array,
	onChange: PropTypes.func,
	onSingleSelect: PropTypes.func,
	onlySingleSelect: PropTypes.bool,
	hideEmptySelected: PropTypes.bool,
	renderAfterSearchField: PropTypes.any,
	candidateChunk: PropTypes.number,
	fullWidth: PropTypes.bool,
};

SearchBar.defaultProps = {
	style: undefined,
	getIdentifier: (row) => row.id,
	getName: (row) => row.name,
	shouldExclude: () => false,
	sortBy: undefined, // default is to sort by the getName function
	loadPreSelectedRows: (selected) => selected,
	splitRegexp: /[\s\_\,\.\-\[\]\(\)]/,
	searchDataCols: undefined,
	resultDataCols: undefined,
	selected: [],
	onlySingleSelect: false,
	onChange: () => {},
	onSingleSelect: () => {},
	hideEmptySelected: false,
	renderAfterSearchField: undefined,
	candidateChunk: CANDIDATE_CHUNK,
	fullWidth: false,
};

export default SearchBar;
