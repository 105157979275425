import React from 'react';
import PropTypes from 'prop-types';
import storeContainer from '../storeContainer';
import Spinner from '../../components/Spinner';
import PricelistEditForm from '../../components/PricelistEditForm';
import {
	Pricelist,
	Segment,
} from '../../api/relevant';

@storeContainer()
class PricelistEditorWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.state = null;
	}

	componentDidMount() {
		this.fetchApplicationSettings();
	}

	onCancel() {
		this.props.history.push('/settings/audience-configurations');
	}

	async fetchApplicationSettings() {
		const newState = {};
		const { id } = this.props;
		await Promise.all([
			(async () => { newState.pricelist = id ? (await Pricelist.get(id)) : {}; })(),
			(async () => { newState.segments = await Segment.list(); })(),
			(async () => { newState.pricelists = await Pricelist.list(); })(),
		]).then(() => {
			this.setState(newState);
		}).catch((why) => console.info(why));
	}

	async submitApplicationSettings(model)	{
		console.log('Submitting ', model);
		Object.assign(model, this.priceListContext.editor.toPriceList());
		if (this.props.id) {
			await model.update();
		} else {
			await Pricelist.add(model);
		}
		this.onCancel();
	}

	render() {
		if (!this.state) return <Spinner delay />;
		this.priceListContext = {
			segments: this.state.segments,
			pricelists: this.state.pricelists,
			pricelist: this.state.pricelist,
		};
		return (
			<PricelistEditForm
				model={this.state.pricelist}
				onSubmit={(model) => this.submitApplicationSettings(model)}
				onCancel={() => this.onCancel()}
				priceListContext={this.priceListContext}
			/>
		);
	}
}

PricelistEditorWrapper.propTypes = {
	id: PropTypes.string,
	history: PropTypes.object.isRequired,
};

PricelistEditorWrapper.defaultProps = {
	id: null,
};

export default PricelistEditorWrapper;
