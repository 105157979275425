import React from 'react';
import PropTypes from 'prop-types';
import { BuySystemList } from '../SystemLists';

class MarketSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<BuySystemList />
		);
	}
}

MarketSettings.propTypes = {

};

MarketSettings.defaultProps = {

};

export default MarketSettings;
