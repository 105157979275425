async function readFile(file) {
	const reader = new FileReader();
	return new Promise((resolve, reject) => {
		reader.onload = (e) => {
			const csv = e.target.result;
			resolve(csv);
		};
		reader.readAsText(file);
		reader.onerror = reject;
	});
}

export {
	readFile,
};
