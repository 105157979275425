import MiscUtils from './miscUtils';

class FrontendStorage {
	constructor() {
		this.listeners = MiscUtils.listeners();
	}

	setItem(key, value) {
		this.listeners.notify('setItem', key, value);
		localStorage.setItem(key, value);
	}

	removeItem(key) {
		this.listeners.notify('removeItem', key);
		localStorage.removeItem(key);
	}

	getItem(key) {
		return localStorage.getItem(key);
	}
}

export default new FrontendStorage();
