import React, { useEffect, useRef, useState } from 'react';
import { SearchBar } from './SearchBar';
import { Publisher } from '../../api/relevant';
import Fuse, { FuseResult } from 'fuse.js';
import { useThrottle } from '@uidotdev/usehooks';
import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';
import { Link } from '../../components/Link/Link';
import { usePublishersQuery } from './query';

type SearchIndexType = {
	kind: 'publisher' | 'site' | 'placement';
	// The full text to fuzzy search on, put in the index
	searchText: string;
	// A shorter text to display in the search results (must be a substring of searchText for highlighting to work)
	text: string;
	// Optional subtext to display in the search results
	subtext?: string;
	link: string;
	key: string;
	searchBarClass?: string;
}

const kindTitles = {
	publisher: 'Publishers',
	site: 'Sites',
	placement: 'Placements',
};

const kinds = {
	'publisher': {
		title: 'Publishers',
		more: '/accounts?f=',
	},
	'site': {
		title: 'Sites',
	},
	'placement': {
		title: 'Placements',
	},
};

// Merge overlapping start-end pairs in an array of indices
// Ex. [{ start: 0, end: 3 }, { start: 2, end: 5 }] => [{ start: 0, end: 5 }]
function mergeIndices(indices: { start: number; end: number }[]) {
	const sorted = indices.sort((a, b) => a.start - b.start);
	const merged: { start: number; end: number }[] = [];
	let current = sorted[0];
	for (let i = 1; i < sorted.length; i++) {
		if (sorted[i].start <= current.end) {
			current.end = Math.max(current.end, sorted[i].end);
		} else {
			merged.push(current);
			current = sorted[i];
		}
	}
	merged.push(current);
	return merged;
};

type PublisherSearchBarProps = {
	searchBarClass?: string;
	placeholder?: string;
}

export function PublisherSearchBar({ searchBarClass, placeholder } : PublisherSearchBarProps) {
	const [searchValue, setSearchValue] = useState('');
	const searchValueThrottled = useThrottle(searchValue, 150);
	const [searchResults, setSearchResults] = useState<Record<SearchIndexType['kind'], FuseResult<SearchIndexType>[]>>();
	const [inFocus, setInFocus] = useState(false);
	const root = useRef<HTMLDivElement>(null);

	const { data: publishers } = usePublishersQuery();
	const { data: indexData } = useQuery<SearchIndexType[]>({
		queryKey: ['publishers', 'searchIndex'],
		enabled: !!publishers,
		queryFn: async () => {
			// Construct index for publishers -> sites -> placements (+ make SSPs and Adserver IDs searchable)
			let indexData: SearchIndexType[] = [];
			for (const p of publishers!) {
				if (p.hidden) {
					continue;
				}
				indexData.push({
					kind: 'publisher',
					searchText: p.name,
					text: p.name,
					link: `/accounts/${p.id}`,
					key: p.id,
				});
				for (const s of p.websites as any[]) {
					indexData.push({
						kind: 'site',
						searchText: `${p.name} ${s.domain}`,
						text: s.domain,
						subtext: p.name,
						link: `/accounts/${p.id}/sites/${s.id}`,
						key: s.id,
					});
					for (const pl of s.placements as any[]) {
						indexData.push({
							kind: 'placement',
							searchText: `${p.name} ${s.domain} ${pl.name}`,
							text: pl.name,
							subtext: `${p.name} » ${s.domain}`,
							link: `/accounts/${p.id}/sites/${s.id}/placements/${pl.id}`,
							key: pl.id,
						});
						for (const ssp of pl.ssps as any[]) {
							indexData.push({
								kind: 'placement',
								searchText: `${p.name} ${s.domain} ${pl.name} (SSP: ${ssp.id})`,
								text: `${pl.name} (SSP: ${ssp.id})`,
								subtext: `${p.name} » ${s.domain}`,
								link: `/accounts/${p.id}/sites/${s.id}/placements/${pl.id}`,
								key: pl.id,
							});
						}
						for (const ad of pl.adservers as any[]) {
							// Some adservers are set up to use the SSP ID, so skip if adserver ID is missing or empty string
							const adserverId = ad.settings.placementId ?? ad.settings.location ?? ad.settings.lineItemId;
							if (adserverId) {
								indexData.push({
									kind: 'placement',
									searchText: `${p.name} ${s.domain} ${pl.name} (Adserver ID: ${adserverId})`,
									text: `${pl.name} (Adserver ID: ${adserverId})`,
									subtext: `${p.name} » ${s.domain}`,
									link: `/accounts/${p.id}/sites/${s.id}/placements/${pl.id}`,
									key: pl.id,
								});
							}
						}
					}
				}
			}

			return indexData;
		}
	});

	const fuse = useRef<Fuse<SearchIndexType> | null>(null);
	useEffect(() => {
		;(async () => {
			fuse.current = new Fuse(indexData, {
				keys: ['searchText'],
				includeScore: true,
				includeMatches: true,
				ignoreLocation: true,
				useExtendedSearch: true,
				minMatchCharLength: 1,
			});
		})();
	}, [indexData]);

	useEffect(() => {
		if (searchValueThrottled === '') {
			setSearchResults(undefined);
		} else {
			let value = searchValueThrottled;
			// Always match number sequences exactly, even if they're 1 character long.
			// The single quote character is special fuse.js search syntax that matches the following word exactly.
			// The space at the end is required so that matching for "160x600" and similar still works.
			value = value.replace(/\d+/g, (match) => `'${match} `);

			// Do the search
			let results = fuse.current?.search(value, { limit: 15 });
			// Remove duplicate results (since SSP and Adserver ID's are duplicated lines in the index,
			// we only want to show one instance of the placement in the results).
			results = results?.filter((r, i) => results.findIndex((r2) => r2.item.key === r.item.key) === i);

			// Fix match indices so we can display the highlight properly
			const resultsFixed = (results?.map((r) => {
				let indices = r.matches?.[0].indices.map((m) => ({ start: m[0], end: m[1] }))
				// Merge overlapping start-end pairs in matches
				indices = mergeIndices(indices);
				// Clamp match indices to only the substring of text inside searchText,
				// cause we only care about rendering matches for the display text, not
				// the whole searchText
				const start = r.item.searchText.indexOf(r.item.text);
				if (start === -1) {
					return {
						...r,
						matches: null
					}
				}
				const end = r.item.searchText.indexOf(r.item.text) + r.item.text.length;
				indices = indices.filter((m) => m.start >= start && m.end <= end); // Remove any matches that are outside the bounds of the text
				indices = indices.map((m) => ({ start: Math.max(m.start - start, 0), end: Math.min(m.end - start, r.item.text.length - 1) })); // Adjust start and end to be relative to the text
				return {
					...r,
					matches: indices.length > 0 ? indices : null
				}
			}));

			// Group on kind
			const grouped = resultsFixed.reduce((acc, cur) => {
				(acc[cur.item.kind] ??= []).push(cur);
				return acc;
			}, {} as Record<SearchIndexType['kind'], FuseResult<SearchIndexType>[]>);
			// Sort each group by score
			for (const kind in grouped) {
				grouped[kind] = grouped[kind].sort((a, b) => a.score - b.score);
			}
			setSearchResults(grouped);
		}
	}, [searchValueThrottled]);

	function handleFocus(e: React.FocusEvent) {
		if (root.current?.contains(e.target as Node)) {
			setInFocus(true);
		}
	}
	function handleBlur(e: React.FocusEvent) {
		if (!root.current?.contains(e.relatedTarget as Node)) {
			setInFocus(false);
		}
	}

	return (
		<div ref={root} className="relative" onBlur={handleBlur} onFocus={handleFocus}>
			<SearchBar onChange={setSearchValue} hasResults={!!searchResults && inFocus} className={searchBarClass} placeholder={placeholder} />
			{(searchResults && inFocus) && (
				<div 
					className={clsx(
						"rounded-x absolute z-10 flex w-full min-w-[300px] flex-col rounded-b border-x border-b border-grey-200 bg-white pb-1 transition",
						inFocus && "!border-grey-600 !shadow",
					)}
				>
					{Object.entries(searchResults ?? {}).map(([kind, results]) => (
						<div key={kind} className="border-b border-grey-200 last:border-b-0">
							<h3 className="text-sm font-medium text-grey-600 ml-3 mt-3">{kinds[kind].title}</h3>
							{results.map((r) => (
								<a
									key={r.item.key}
									href={r.item.link}
									className={clsx(
										"block text-grey-600 text-sm hover:bg-cherry-50 focus:bg-cherry-50 cursor-pointer px-3 transition",
										r.item.subtext ? "py-1" : "py-3"
									)}
									tabIndex={0} // NOTE: The tab order between the input and the results IS correct, but Firefox on MacOS ignores it unless the user sets the "Keyboard navigation" option to On in their system settings...
								>
									<span>
										{r.matches ? (
											<>
												{r.matches.map((m, j) => (
													<React.Fragment key={j}>
														{r.item.text.slice(j === 0 ? 0 : r.matches[j-1].end+1, m.start)}
														<span className="text-cherry-600">
															{r.item.text.slice(m.start, m.end+1)}
														</span>
													</React.Fragment>
												))}
												{r.item.text.slice(r.matches[r.matches.length-1].end+1)}
											</>
										) : (
											r.item.text
										)}
									</span>
									<span className="text-xs text-grey-400 block">{r.item.subtext}</span>
								</a>
							))}
							{kinds[kind].more && (
								<Link to={`${kinds[kind].more}${searchValue}`} className="block px-3 py-2">Show all results</Link>
							)}
						</div>
					))}
					{Object.keys(searchResults ?? {}).length === 0 && (
						<div className="text-grey-600 text-sm text-center py-6">No results found</div>
					)}
				</div>
			)}
		</div>
	);
}