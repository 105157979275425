import { AllSeqObjectDimensions } from 'relevant-shared/mappingDimensions/allDimensionArrays';
import classes from './classes';
import { relevant } from './index';

const { apiClassMap, ApiObject } = classes;

const addCalls = (objName) => {
	let clsObj;
	const cls = () => {
		if (clsObj) {
			return clsObj; // we've already done this once
		}
		if (objName in apiClassMap) { // Check needed as the next line will otherwise create object in Proxy.get
			const tmp = apiClassMap[objName];
			if (tmp.clsInfo) { // Yes, this is a real class
				clsObj = tmp;
				return clsObj;
			}
		}
		// Path might not use default naming convention  - for example BaseSsp is using 'ssps' instead of 'basessps'
		const postfix = objName[objName.length - 1].toLowerCase() === 's' ? '' : 's';
		const expectedPath = objName.toLowerCase() + postfix;
		clsObj = ApiObject.clsByPath[expectedPath];
		if (!clsObj) {
			throw Error(`Can't find api path for '${objName}'`);
		}
		return clsObj;
	};
	const obj = {};
	['get', 'list', 'listOne', 'add', 'asUrl', 'fn'].forEach((fnName) => {
		obj[fnName] = (...args) => cls()[fnName](...args);
	});
	// 'callStatic' was previously named 'call', but not possible when cls() is a class because of the built-in function
	obj.call = (...args) => cls().callStatic(...args);
	return obj;
};
export const login = (email, password, oauth) => relevant.post('/users/login', { email, password, oauth });
export const addUser = (body) => relevant.post('/users', body);
export const getUser = (id) => relevant.get(`/users/${id}`);
export const editUser = (id, body) => relevant.put(`/users/${id}`, body);
export const deleteUser = (id) => relevant.delete(`/users/${id}`);
export const listOfUsers = () => relevant.get('/users');

export const addPublisher = (body) => Publisher.add(body);
export const editPublisher = (id, body) => body.update();
export const listOfPublishers = async () => ({ result: await Publisher.list() });

export const addUserData = (id, body) => relevant.post(`/users/${id}/data`, body);
export const getUserData = (id) => relevant.get(`/users/${id}/data`);

export const addUserRole = (id, body) => relevant.post(`/users/${id}/roles`, body);
export const deleteUserRole = (id, role, scope) => relevant.delete(`/users/${id}/roles/${role}@${scope}`);

export const changePassword = (body) => relevant.put('/users/me/password', body);

export const listOfAdministrators = () => relevant.get('/users?role=admin@relevant');

export const addPublisherByUserId = (id, body) => relevant.post(`/users/${id}/publisher`, body);
export const getPublisherByUserId = (id) => relevant.get(`/users/${id}/publisher`);
export const editPublisherByUserId = (id, body) => relevant.put(`/users/${id}/publisher`, body);
export const deletePublisherByUserId = (id) => relevant.delete(`/users/${id}/publisher`);

export const authenticateGmail = (body) => relevant.post('/authenticateGmail', body);

export const listOfSspTypes = () => relevant.get('/ssptypes');

export const getAdminRaRevenue = (body) => relevant.post('/adminstats/ra/revenue', body);

export const getAdminRpRevenue = (body) => relevant.post('/adminstats/rp/revenue', body);
export const getAdminRpTraffic = (body) => relevant.post('/adminstats/rp/trafficSentToSuperSSP', body);
export const getAdminRpPaidImpressions = (body) => relevant.post('/adminstats/rp/paidImpressionsInSuperSSP', body);

export const getPublisherRpRevenue = (id, body) => relevant.post(`/publishers/${id}/rp/revenue`, body);

export const collectAudienceData = () => relevant.get('/jobs/collectAudienceData');
export const collectProgrammaticData = () => relevant.get('/jobs/collectProgrammaticData');
export const listOfJobLogs = () => relevant.get('/jobs/getJobLogs');

export const AccessHistory = addCalls('AccessHistory');
export const Adserver = addCalls('Adserver');
export const Alarms = addCalls('Alarms');
export const AdvertiserMapping = addCalls('AdvertiserMapping');
export const AdvertiserUserMapping = addCalls('AdvertiserUserMapping');

export const AnalyticsSystems = addCalls('AnalyticsSystems');
export const AnalyticsEntry = addCalls('AnalyticsEntry');
export const AppInstance = addCalls('AppInstance');
export const BuySystem = addCalls('BuySystem');
export const Cdn = addCalls('Cdn');
export const CintData = addCalls('CintData');
export const CintSegment = addCalls('CintSegment');
export const Cluster = addCalls('Cluster');
export const CxReports = addCalls('CxReports');
export const DocumentTemplate = addCalls('DocumentTemplate');
export const Dsp = addCalls('Dsp');
export const GlobalSettingsObject = addCalls('GlobalSettingsObject');
export const GoogleAnalytics = addCalls('GoogleAnalytics');
export const GmailAccount = addCalls('GmailAccount');
export const Invoice = addCalls('Invoice');
export const KeyValue = addCalls('KeyValue');
export const LogDataIdMapping = addCalls('LogDataIdMapping');
export const MiscTasks = addCalls('MiscTasks');
export const Pricelist = addCalls('Pricelist');
export const ProgrammaticSettings = addCalls('ProgrammaticSettings');
export const Publisher = addCalls('Publisher');
export const Reports = addCalls('Reports');
export const RevenueRules = addCalls('RevenueRules');
export const Segment = addCalls('Segment');
export const SiteProperties = addCalls('SiteProperties');
export const Ssp = addCalls('Ssp');
export const TagData = addCalls('TagData');
export const Tasks = addCalls('Tasks');
export const Trigger = addCalls('Trigger');
export const TriggerReportRun = addCalls('TriggerReportRun');
export const GlobalUserData = addCalls('GlobalUserData');
export const SummaryHbData = addCalls('SummaryHbData');
export const Comment = addCalls('Comment');
export const UserIdModule = addCalls('UserIdModule');
export const PrebidBuildConfiguration = addCalls('PrebidBuildConfiguration');
export const PrebidBuildHandler = addCalls('PrebidBuildHandler');

const obj = AllSeqObjectDimensions
	.reduce((acc, { cName }) => {
		if (!(cName in acc)) {
			acc[cName] = addCalls(cName);
		}
		return acc;
	}, {});
export const DynamicExports = obj;
