import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';

function PopoutButton({
	renderButton,
	children,
	open,
	onOpenStateChange,
	anchorEl,
	popoverActions,
	anchorOrigin,
	transformOrigin,
}) {
	const [popoverAnchorEl, setPopoverAnchorEl] = useState(anchorEl);
	const [isOpen, setIsOpen] = useState(open);

	const toggleOpen = useCallback((state) => {
		setIsOpen(state);
		if (onOpenStateChange) {
			onOpenStateChange(state);
		}
	}, [onOpenStateChange]);

	const onClick = ({ target }) => {
		// Set to predefined anchor element from prop
		// or to click target
		if (anchorEl) {
			setPopoverAnchorEl(anchorEl);
		} else {
			setPopoverAnchorEl(target);
		}
		toggleOpen(true);
	};

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	return (
		<>
			{ renderButton({ onClick }) }
			<Popover
				action={popoverActions}
				open={isOpen}
				anchorEl={popoverAnchorEl}
				onClose={() => {
					toggleOpen(false);
					setPopoverAnchorEl(null);
				}}
				anchorOrigin={anchorOrigin || {
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={transformOrigin || {
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				{ children }
			</Popover>
		</>
	);
}

export default PopoutButton;

PopoutButton.propTypes = {
	renderButton: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	// Optionally open/close from parent
	open: PropTypes.bool,
	anchorEl: PropTypes.instanceOf(Element),
	// Ping parent when open state changes
	onOpenStateChange: PropTypes.func,
	popoverActions: PropTypes.shape({ current: PropTypes.any }),
	anchorOrigin: PropTypes.shape({
		vertical: PropTypes.string,
		horizontal: PropTypes.string,
	}),
	transformOrigin: PropTypes.shape({
		vertical: PropTypes.string,
		horizontal: PropTypes.string,
	}),
};

PopoutButton.defaultProps = {
	open: false,
	anchorEl: null,
	onOpenStateChange: undefined,
	anchorOrigin: null,
	transformOrigin: null,
	popoverActions: null,
};
