import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
	Box,
	IconButton,
	Tooltip,
	TablePagination,
	Chip,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import SuccessIcon from '@mui/icons-material/Done';
import PeopleIcon from '@mui/icons-material/People';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CircularProgress from '@mui/material/CircularProgress';
import deployStatuses from 'relevant-shared/misc/deployStatuses';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RestartButton from '../RestartButton';
import DataTable from '../DataTable';
import DeleteButton from '../DeleteButton';
import AppInstanceStatus from '../AppInstanceStatus';
import { isDeployDone } from '../../lib/globalAdminUtils';
import styles from './styles.css';
import { ConfirmDialog } from '../ConfirmDialog';

const mapToTableData = (instance, instanceInfo, deploy) => {
	const isUp = instanceInfo && Object.keys(instanceInfo).length > 0;
	return {
		...instance,
		...(isUp && { version: `${moment(instanceInfo.commitDate).format('YYYY-MM-DD')} - ${instanceInfo.branch}` }),
		...(deploy && Object.keys(deploy).length > 0 && { deploy }),
		...(isUp && { isCurrentInstance: instanceInfo.isCurrentInstance }),
		...(isUp && { appUrl: instanceInfo.appUrl }),
		isUp,
	};
};

function AppInstanceList({
	instances,
	instanceInfo,
	deployState,
	selectedInstanceIds,
	loading,
	onSelect,
	onEditClick,
	onDeleteClick,
	onDeployClick,
	onRestartClick,
	onViewUsersClick,
}) {
	const [hoveringIndex, setHoveringIndex] = useState();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const tableData = instances
		.map((instance) => mapToTableData(
			instance,
			instanceInfo[instance.id],
			deployState.deploys ? deployState.deploys[instance.id] : {},
		));

	const currentPageRows = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	const disabledRowIndices = currentPageRows
		.map((data, index) => ({ ...data, index }))
		.filter(({ deploy }) => deploy && !isDeployDone(deploy))
		.map(({ index }) => index);

	const renderSuccessIcon = (title) => (
		<Tooltip title={title}>
			<Box
				className={styles.deployStatus}
				component="div"
				color="success.main"
			>
				<SuccessIcon />
			</Box>
		</Tooltip>
	);

	const renderErrorIcon = (title) => (
		<Tooltip title={title}>
			<Box className={styles.deployStatus} component="div">
				<ErrorIcon color="error" />
			</Box>
		</Tooltip>
	);

	const renderLoadingIcon = (title) => (
		<Tooltip title={title}>
			<div className={styles.deployStatus}>
				<CircularProgress size={24} />
			</div>
		</Tooltip>
	);

	const renderWarningIcon = (title) => (
		<Tooltip title={title}>
			<Box className={styles.deployStatus} color="warning.main">
				<WarningIcon />
			</Box>
		</Tooltip>
	);

	const onRowHoverChange = (hovering, rowIndex) => {
		if (hovering) {
			setHoveringIndex(rowIndex);
		} else {
			setHoveringIndex(undefined);
		}
	};

	return (
		<>
			{currentPageRows.length > 0
				&& (
					<span className={loading ? styles.appInstanceStatusHidden : ''}>
						<AppInstanceStatus
							data={currentPageRows}
							deploysAreDone={deployState.deploysAreDone}
							deployTime={deployState.deployTime}
						/>
					</span>
				)}
			<DataTable
				data={currentPageRows}
				identifier={({ id }) => id}
				selectableRows={false}
				isSelected={({ id }) => selectedInstanceIds.includes(id)}
				onRowSelection={onSelect}
				onRowHoverChange={onRowHoverChange}
				showCheckboxes
				hoverable
				disabledCheckboxesRowIndices={disabledRowIndices}
				definitions={[
					{
						key: 'isUp',
						title: 'Status',
						format: (isUp) => (
							isUp ? (
								<Tooltip title="The instance seems to be up">
									<Box
										className={styles.runningStatus}
										component="div"
										color="common.white"
										bgcolor="success.main"
									>
										UP
									</Box>
								</Tooltip>
							)
								: (!loading
											&& (
												<Tooltip title="The instance seems to be down">
													<Box
														className={styles.runningStatus}
														component="div"
														color="common.white"
														bgcolor="error.main"
													>
														DOWN
													</Box>
												</Tooltip>
											)
								)
						),
					},
					{
						key: 'isActive',
						title: 'Active',
						format: (isActive) => (
							isActive ? (
								<Tooltip title="The instance is active">
									<CheckCircleIcon color="success" />
								</Tooltip>
							) : null
						),
					},
					{
						key: 'deploy',
						title: 'Deploy/Restart',
						whenNull: () => '',
						format: (deploy) => {
							if (deploy) {
								const {
									PENDING, ONGOING, FAILED, STOPPED,
								} = deployStatuses;
								if (deploy.status === PENDING || deploy.status === ONGOING) {
									return renderLoadingIcon(deploy.message);
								} if (deploy.status === FAILED) {
									return renderErrorIcon(deploy.message);
								} if (deploy.status === STOPPED) {
									return renderWarningIcon(deploy.message);
								}
								return renderSuccessIcon(deploy.message);
							}
							return null;
						},
					},
					{
						key: 'name',
						title: 'Instance name',
						whenNull: () => <i>Unknown</i>,
					},
					{
						key: 'appUrl',
						title: 'Instance URL',
						format: (appUrl) => <a href={appUrl} target="_blank" rel="noopener noreferrer">{appUrl}</a>,
					},
					{
						key: 'version',
						title: 'Version',
						whenNull: () => <i>Unknown</i>,
					},
					{
						key: 'modules',
						title: 'Modules',
						whenNull: () => <i>Unknown</i>,
						format: (modules) => {
							if (modules?.length > 0) {
								return modules.map((module) => (
									<Chip
										sx={{ mt: 1 }}
										label={module}
									/>
								));
							}
							return <i>Unknown</i>;
						},
					},
					{
						key: 'id',
						title: '',
						format: (id, instance, index) => {
							const normal = styles.appInstanceTools;
							const hidden = styles.appInstanceToolsHidden;
							const containerClass = `${normal} ${index !== hoveringIndex ? hidden : ''}`;
							const disableDeployButton = disabledRowIndices.length > 0
								|| !instance.isUp
								|| instance.isCurrentInstance;
							return (
								<div className={containerClass}>
									<Tooltip title="Edit">
										<span>
											<IconButton
												disabled={disabledRowIndices.includes(index)}
												aria-label="Edit"
												onClick={() => onEditClick(id)}
												size="large"
											>
												<EditIcon />
											</IconButton>
										</span>
									</Tooltip>
									<Tooltip title="Delete">
										<span>
											<ConfirmDialog
												text="Are you sure you want to delete this instance?"
												onConfirm={() => onDeleteClick([id])}
											>
												<DeleteButton disabled={disabledRowIndices.includes(index)} />
											</ConfirmDialog>
										</span>
									</Tooltip>
									<Tooltip title="View users">
										<span>
											<IconButton
												disabled={!instance.isUp}
												onClick={({ target }) => onViewUsersClick([id], target)}
												size="large"
											>
												<PeopleIcon />
											</IconButton>
										</span>
									</Tooltip>
									<Tooltip title="Restart">
										<span>
											<ConfirmDialog
												text="Are you sure you want to restart this instance?"
												onConfirm={() => onRestartClick([id])}
											>
												<RestartButton disabled={disableDeployButton} />
											</ConfirmDialog>
										</span>
									</Tooltip>
									<Tooltip title="Deploy">
										<span>
											<IconButton
												disabled={disableDeployButton}
												aria-label="Deploy"
												onClick={(e) => onDeployClick([id], e.target)}
												size="large"
											>
												<ArrowUpwardIcon />
											</IconButton>
										</span>
									</Tooltip>
								</div>
							);
						},
					},
				]}
			/>
			<TablePagination
				component="div"
				count={instances.length}
				page={page}
				rowsPerPage={rowsPerPage}
				onPageChange={(__, newPage) => {
					setPage(newPage);
				}}
				onRowsPerPageChange={(e) => {
					setRowsPerPage(e.target.value);
					setPage(Math.floor((page * rowsPerPage) / e.target.value));
				}}
			/>
		</>
	);
}

AppInstanceList.propTypes = {
	instances: PropTypes.arrayOf(PropTypes.object).isRequired,
	instanceInfo: PropTypes.objectOf(PropTypes.object),
	deployState: PropTypes.shape({
		deploys: PropTypes.objectOf(PropTypes.shape({
			status: PropTypes.string,
			message: PropTypes.string,
			branch: PropTypes.string,
		})),
		deploysAreDone: PropTypes.bool,
		deployTime: PropTypes.number,
	}),
	selectedInstanceIds: PropTypes.arrayOf(PropTypes.string),
	loading: PropTypes.bool,
	onEditClick: PropTypes.func.isRequired,
	onDeleteClick: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	onDeployClick: PropTypes.func.isRequired,
	onRestartClick: PropTypes.func.isRequired,
	onViewUsersClick: PropTypes.func.isRequired,
};

AppInstanceList.defaultProps = {
	instanceInfo: {},
	selectedInstanceIds: [],
	deployState: {},
	loading: false,
};

export default AppInstanceList;
