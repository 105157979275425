import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { getSectionToExpand } from '../utils';

function useExpandedSections() {
	const [expandedSections, setExpandedSections] = useState([]);
	const history = useHistory();
	// On mount, set which sections are expanded. In reality just one.
	useEffect(() => {
		const path = history.location.pathname;

		const sectionToExpand = getSectionToExpand(path);
		if (sectionToExpand) {
			setExpandedSections([sectionToExpand]);
		}
		// disable rule, we want effect to run on mount only
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateExpandedSections = (section) => {
		const updated = [...expandedSections];
		const index = updated.indexOf(section);
		if (index !== -1) {
			updated.splice(index, 1);
			setExpandedSections(updated);
		} else {
			updated.push(section);
			setExpandedSections(updated);
		}
	};

	return [expandedSections, updateExpandedSections];
}

export default useExpandedSections;
