import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _ from 'lodash';
import IconButton from '@mui/material/IconButton';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/AddCircle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DeleteButton from '../DeleteButton/index';
import ExpandSelector from '../ExpandSelector/index';
import ReactUtils from '../../lib/reactUtils';
import FieldEditor from './fieldEditor';
import FieldDataObject from './fieldDataObject';
import Form from '../../containers/Form';

const swap = (arr, i1, i2) => {
	const tmp = arr[i1];
	arr[i1] = arr[i2];
	arr[i2] = tmp;
};

class FieldDataArray extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			model, field, inDisabled, summary,
		} = this.props;
		const value = model;
		const update = (fn) => (() => {
			fn();
			const { name, value: arr } = field('value');
			Form.fromField(field).updateField(name, arr);
		});
		return (
			<div>
				{ReactUtils.fldMap(model, field, 'value', (elm, fld, idx, arr) => (
					<Card key={`fld_${idx}`}>
						<CardContent>
							{!summary && (
								<>
									<DeleteButton
										disabled={inDisabled}
										title="Delete"
										onClick={update(() => arr.splice(idx, 1))}
									/>
									{idx !== 0 && (
										<IconButton
											size="large"
											disabled={inDisabled}
											onClick={update(() => swap(arr, idx, idx - 1))}
										>
											<UpIcon />
										</IconButton>
									)}
									{ idx < arr.length - 1 && (
										<IconButton
											size="large"
											disabled={inDisabled}
											onClick={update(() => swap(arr, idx, idx + 1))}
										>
											<DownIcon />
										</IconButton>
									)}
								</>
							)}
							<FieldDataObject
								summary={summary}
								field={fld}
								model={elm}
								alwaysOwn
								inDisabled={inDisabled}
							/>
						</CardContent>
					</Card>
				))}
				{!summary && (
					<IconButton
						size="large"
						disabled={inDisabled}
						onClick={update(() => model.value.push(_.cloneDeep(value.defAppend)))}
					>
						<AddIcon />
					</IconButton>
				)}
			</div>
		);
	}
}

FieldDataArray.propTypes = {
	model: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	inDisabled: PropTypes.bool,
	summary: PropTypes.bool,
};

FieldDataArray.defaultProps = {
	inDisabled: false,
	summary: false,
};

export default FieldDataArray;
