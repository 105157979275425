exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".pageContentWrapper__src-pages-PublisherUserList-styles__2JHjB {\n\tpadding-bottom: 100px;\n}\n\n.buttonRow__src-pages-PublisherUserList-styles__3yoYj {\n\tmargin-top: 10px;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/digitalenterprises/temp/relevant-yield/relevant-frontend/src/pages/PublisherUserList/styles.css"],"names":[],"mappings":"AAAA;CACC,sBAAqB;CACtB;;AAEA;CACC,iBAAgB;CACjB","file":"styles.css","sourcesContent":[".pageContentWrapper {\n\tpadding-bottom: 100px;\n}\n\n.buttonRow {\n\tmargin-top: 10px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"pageContentWrapper": "pageContentWrapper__src-pages-PublisherUserList-styles__2JHjB",
	"buttonRow": "buttonRow__src-pages-PublisherUserList-styles__3yoYj"
};