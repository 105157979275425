import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import DataTableWithMemory from '../DataTable/dataTableWithMemory';
import Checkbox from '../Checkbox';
import Select from '../Select';
import TextField from '../TextField';
import { getSyncUpdates } from './syncFns';
import MiscUtils from '../../lib/miscUtils';

function AdserverPlacementSyncSettings({
	field, model, form, publisherNode,
}) {
	const rows = getSyncUpdates({ publisherNode, ...model });
	const { fields } = publisherNode.globalSettings.placementTag;
	const availPlacFields = _.filter(fields, { type: 'String' });
	const { selected, placFieldAppend, otherPlacementsName } = model;
	return (
		<Grid container spacing={3}>
			<Grid item xs={5}>
				<Select
					nonSelected="Default (use SSP corresponding to ad server)"
					fullWidth
					label="SSP"
					{...field('targetSsp')}
					items={MiscUtils.alphaSorted(publisherNode.ssps, 'name').map(({ name, id }) => ({
						label: name,
						value: id,
					}))}
				/>
			</Grid>
			<Grid item xs={12}>
				<Checkbox
					label="Include SSP placements even if they exists elsewhere in publisher"
					{...field('onlyCheckLocal')}
				/>
			</Grid>
			{!!availPlacFields.length && (
				<Grid item xs={5}>
					<Select
						nonSelected="(none)"
						fullWidth
						label="Append /[value] from root placement tag String field"
						{...field('placFieldAppend')}
						items={availPlacFields.map((f) => ({ label: f.description, value: f.name }))}
					/>
				</Grid>
			)}
			{placFieldAppend && (
				<>
					<Grid item xs={12}>
						<Checkbox
							label="Use wildcards when possible"
							{...field('useWildcards')}
						/>
					</Grid>
					<Grid item xs={5}>
						<TextField
							label="Name of 'other traffic' placement (leave empty to skip)"
							{...field('otherPlacementsName')}
							fullWidth
						/>
					</Grid>
					{otherPlacementsName && (
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<Typography variant="h2">
										Ohter traffic placements
									</Typography>
									<Grid item xs={12}>
										<Checkbox
											label="Include Publisher name"
											{...field('includePublisher')}
										/>
										<Checkbox
											label="Include Site name"
											{...field('includeSite')}
										/>
										<Checkbox
											label="ONLY add to 'other traffic' when placement tag is set"
											{...field('onlyOther')}
										/>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					)}
				</>
			)}
			<Grid item xs={12}>
				{rows.length ? (
					<>
						<b>{`${selected.length} / ${rows.length} `}</b>
						selected
						<DataTableWithMemory
							showCheckboxes
							identifier={(row) => row.key}
							onChange={(s) => form.setVals({ selected: s })}
							selected={selected}
							definitions={[
								{
									key: 'key',
									title: 'SSP placement',
									format: (__, { plac, newSspPlac, placName }) => (
										<span>
											{`${plac.parentNode.name} >> ${placName} >> `}
											<b>{newSspPlac.id}</b>
										</span>
									),
								},
							]}
							data={rows}
						/>
					</>
				) : (
					<Box sx={{ color: 'success.main' }}>Nothing needs to be done</Box>
				)}
			</Grid>
		</Grid>
	);
}

AdserverPlacementSyncSettings.propTypes = {
	field: PropTypes.func.isRequired,
	model: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	publisherNode: PropTypes.object.isRequired,
};

export default AdserverPlacementSyncSettings;
