import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '../TextField';
import Form from '../../containers/Form';
import PopupSelector from '../PopupSelector';
import DeleteButton from '../DeleteButton';
import DatePicker from '../DatePicker';
import {
	MAX_RANGES,
	FIRST_VALID_DATE,
	LAST_VALID_DATE,
} from '../../lib/cutUtils';
import DateUtils from '../../lib/dateUtils';

@Form
class RangeSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			field, model, submit, form, onCancel,
		} = this.props;
		const { ranges } = model;
		return (
			<PopupSelector
				form={form}
				title={`Date ranges (${ranges.length > 1 ? `${ranges.length} selected` : 'none'})`}
				onApplyChanges={submit}
				allowErrorsIfCancel
				onCancel={onCancel}
			>
				<Grid container spacing={3} alignItems="center">
					{ranges.map((obj, idx) => (
						<Fragment key={`idx_${idx}`}>
							<Grid item xs={2}>
								{ranges.length > 1 && (
									<DeleteButton
										title=""
										onClick={() => {
											if (idx && idx === ranges.length - 1) {
												ranges[idx - 1].before = null;
											} else if (!idx && ranges.length < 3) {
												ranges[idx + 1].before = null; // only one left
											}
											ranges.splice(idx, 1);
											this.forceUpdate();
										}}
									/>
								)}
							</Grid>
							<Grid item xs={5}>
								<TextField
									{...field(`ranges.[${idx}].cut`)}
									label="Cut %"
									between={{ low: 0, high: 100 }}
									float
									required
									satisfy={{
										valid: (value) => ((v) => !isNaN(value) && Math.round(v * 2) === v * 2)(parseFloat(value)),
										message: 'Cuts can only have a granularity of 0.5%',
									}}
								/>
							</Grid>
							<Grid item xs={5}>
								{idx < (MAX_RANGES - 1) && (
									<DatePicker
										floatingLabelText="Before date"
										maxDate={idx < ranges.length - 2 ? DateUtils.fullDay(ranges[idx + 1].before, -1) : LAST_VALID_DATE}
										minDate={idx ? DateUtils.fullDay(ranges[idx - 1].before, 1) : FIRST_VALID_DATE}
										autoOk
										{...(((o) => ({
											...o,
											onChange: (ev, ...args) => {
												if (idx === ranges.length - 1) {
													ranges.push({ cut: 0 });
												}
												return o.onChange(ev, ...args);
											},
										}))(field(`ranges.[${idx}].before`)))}
									/>

								)}
							</Grid>
						</Fragment>
					))}
				</Grid>
			</PopupSelector>
		);
	}
}

RangeSelect.wrappedComponent.propTypes = {
	field: PropTypes.func.isRequired,
	model: PropTypes.object.isRequired,
	submit: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	onCancel: PropTypes.func,
};

RangeSelect.wrappedComponent.defaultProps = {
	onCancel: undefined,
};

export default RangeSelect;
