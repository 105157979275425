import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	IconButton,
} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export default function ExpandableSidebarButton({
	text,
	icon,
	children,
	expanded,
	onExpandChange,
}) {
	return (
		<>
			<ListItem
				button
				secondaryAction={(
					<IconButton disableRipple sx={{ color: 'primary.tonedDownText' }}>
						{ expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight /> }
					</IconButton>
				)}
				onClick={() => onExpandChange(!expanded)}
				sx={{
					color: 'primary.tonedDownText',
					// 4px invisible border to account for SidebarButton's border
					borderLeft: '4px solid transparent',
				}}
			>
				<ListItemIcon sx={{ color: 'primary.tonedDownText' }}>
					{icon}
				</ListItemIcon>
				<ListItemText primary={text} />
			</ListItem>
			<Collapse
				appear={false}
				in={expanded}
			>
				{ children }
			</Collapse>
		</>
	);
}

ExpandableSidebarButton.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.node.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	expanded: PropTypes.bool.isRequired,
	onExpandChange: PropTypes.func.isRequired,
};
