import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import DeleteButton from '../DeleteButton';
import { ConfirmDialog } from '../ConfirmDialog';

export default function PrebidBuildDeleteButton({ onClick, useTooltip }) {
	if (useTooltip) {
		return (
			<Tooltip title="Delete build">
				<span>
					<ConfirmDialog
						onConfirm={onClick}
						text="The build will be removed from all websites where it is used. Are you sure you want to delete this Prebid build?"
					>
						<DeleteButton />
					</ConfirmDialog>
				</span>
			</Tooltip>
		);
	}
	return (
		<ConfirmDialog
			onConfirm={onClick}
			text="The build will be removed from all websites where it is used. Are you sure you want to delete this Prebid build?"
		>
			<DeleteButton />
		</ConfirmDialog>
	);
}

PrebidBuildDeleteButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	useTooltip: PropTypes.bool,
};

PrebidBuildDeleteButton.defaultProps = {
	useTooltip: false,
};
