const _ = require('lodash');
const TagUtils = require('../misc/tagUtils');
const { PrebidDataObjects } = require('./prebidDataObjects');
const { GenericTagField } = require('../objects/tagFieldBase');
const {
	RLV_PREFIX,
	BASE_INTERNAL_BID_TAG_FIELDS,
	BASE_BIDDING_TYPE_OPTION,
	BASE_USER_ID_MODULE_PARAMS,
} = require('./constants');

const paramKey = (unitId, sspId, pbCfgId) => `${unitId || ''}_${sspId || ''}_${pbCfgId || ''}`;
const unitParamKey = (unit) => paramKey(unit.unitId, unit.sspId, unit.pbCfgId);
const toParamMap = (bidParams) => _.mapValues(_.keyBy(bidParams, unitParamKey), (v) => v.params);

const splitParams = (params) => {
	const normal = {};
	const internal = {};
	_.forOwn(params, (v, k) => {
		if (k.startsWith(RLV_PREFIX)) {
			internal[k.slice(RLV_PREFIX.length)] = v;
		} else {
			normal[k] = v;
		}
	});
	return { normal, internal };
};

const getTagFields = (systemId, { globalSettings, sspsById, userIdModulesById }) => {
	const system = (sspsById && sspsById[systemId]) || (userIdModulesById && userIdModulesById[systemId]);

	const pbDataObject = PrebidDataObjects[systemId];
	let tagFields = [];
	if (pbDataObject) {
		tagFields = pbDataObject.getTagFields({ globalSettings });
	} else if (system) {
		tagFields = [
			...(system.internalBidTagFields || []),
			...(system.bidTagFields || []),
		];
	}
	return tagFields.map((tagField) => (!tagField.generateDefault ? new GenericTagField(tagField) : tagField));
};

/**
 * Take one or two values with meta, like ({ name: 'bla', isOwn: false, value: { ...}, ... })
 * Then return an array of this value(s) along with all descending values.
 * Invoke callback (if supplied) on each value and stop recursing if callback returns false (exactly)
 * If two values are used, they must have identical structure (use the same Tag object)
 * This function won't go into arrays
 */
const getAllValuesWithMeta = (valueWithMeta, optOtherValueWithMeta, cb) => {
	const otherIsFn = _.isFunction(optOtherValueWithMeta);
	const callback = otherIsFn ? optOtherValueWithMeta : cb;
	const firstOther = !otherIsFn && optOtherValueWithMeta;
	const res = [];
	const addVals = (value, other) => {
		if (other) {
			res.push([value, other]);
			if (callback?.(value, other) === false) {
				return;
			}
		} else {
			if (firstOther) {
				throw Error('Different structure of values');
			}
			res.push(value);
			if (callback?.(value) === false) {
				return;
			}
		}
		if (value.type === 'Object' || value.type === 'ByObject') {
			_.forOwn(value.value, (v, k) => addVals(v, other?.value[k]));
		}
	};
	if (valueWithMeta) {
		addVals(valueWithMeta, firstOther);
	}
	return res;
};

const getAllValuesWithMetaFromObj = (objWithMeta, optOtherObjWithMeta, cb) => {
	const other = !_.isFunction(optOtherObjWithMeta) && optOtherObjWithMeta;
	const res = [];
	_.forOwn(objWithMeta, (v, k) => {
		res.push(getAllValuesWithMeta(v, other?.[k], cb));
	});
	return res.flat();
};

const bidderParams = (arr, tagFields, {
	includesFinal = true,
	withMeta,
	onlyNonFields,
	opts,
}) => {
	const fieldNames = _.map(tagFields, 'name');
	const res = {};
	if (!onlyNonFields) {
		GenericTagField.generateDefaultValues(res, tagFields, withMeta, opts || {});
	}
	arr.forEach((obj, idx) => {
		if (!onlyNonFields) {
			const isFinal = includesFinal && idx === arr.length - 1;
			let beforeFinal;
			if (isFinal && withMeta) {
				beforeFinal = _.cloneDeep(res);
			}
			GenericTagField.applyDataObject(
				res,
				tagFields,
				obj,
				withMeta,
				isFinal,
				opts,
			);
			if (beforeFinal) { // save copy ov value we're inheriting from to show them when disabling override-swithc
				getAllValuesWithMetaFromObj(res, beforeFinal, (val, orgValue) => {
					val.orgValue = orgValue;
				});
			}
		}
		if (!withMeta && !(opts && opts.onlyForOwnAsDefault)) { // merge possible "non-field" parameters
			TagUtils.mergeNoArr(res, _.omit(obj, fieldNames));
		}
	});
	if (!withMeta && !onlyNonFields) {
		GenericTagField.clearOmittedDefaults(res, tagFields);
	}
	return res;
};

// Make copy of the original (preview) value that can be used, which means it should also have an own .orgValue
// that doesn't point to itself.
const cloneOrgValue = (orgValue) => {
	if (!orgValue) {
		return null;
	}
	const cloned = _.cloneDeep(orgValue);
	const orgArr = getAllValuesWithMeta(orgValue);
	const clonedArr = getAllValuesWithMeta(cloned);
	clonedArr.forEach((val, idx) => {
		val.orgValue = orgArr[idx];
	});
	return cloned;
};

const getInternalSspBidTagFields = (hasPrebidServerSupport) => {
	const biddingTypeField = { ...BASE_BIDDING_TYPE_OPTION };

	if (hasPrebidServerSupport) {
		biddingTypeField.options = [
			...biddingTypeField.options,
			{ name: 'server', label: 'Server-side (Please contact the Relevant team before enabling this)' },
			{ name: 'parallel', label: 'Parallel (Please contact the Relevant team before enabling this)' },
		];
	}
	return [...BASE_INTERNAL_BID_TAG_FIELDS, biddingTypeField];
};

const getInternalUserIdModuleBidTagFields = () => (
	[
		{
			type: 'Boolean',
			fields: [],
			description: 'Enable User ID module',
			defaultValue: false,
			name: 'enableUserIdModule',
		},
	]
);

const getUserIdLocalStorageNameTagField = (name, displayInUi = true) => ({
	type: 'String',
	name: 'name',
	description: 'Cookie/local storage name',
	defaultValue: `rlv_userid_${name}`,
	isRequired: true,
	// Special case param when we don't want to display in UI.
	displayInUi,
});

const getParamsWithModifiedStorageNameField = (prebidName, displayInUi = true) => {
	const params = [...BASE_USER_ID_MODULE_PARAMS];
	const storageParamsIndex = params.findIndex(({ name }) => name === 'storage');
	const storageParams = { ...params[storageParamsIndex] };
	const baseFields = [...storageParams.fields];
	storageParams.fields = [...baseFields, getUserIdLocalStorageNameTagField(prebidName, displayInUi)];
	params[storageParamsIndex] = storageParams;
	return params;
};

const generateUserIdModuleConfig = (prebidParams, userIdModule) => {
	let config;
	const { storage, bidders, value } = prebidParams;
	let mappedValue;
	if (value) {
		mappedValue = value.reduce((acc, curr) => {
			if (!acc[curr.key]) {
				acc[curr.key] = curr.value;
			}
			return acc;
		}, {});
	}
	// Delete params that should not end up under "params" in userSync object
	delete prebidParams.storage;
	delete prebidParams.bidders;
	delete prebidParams.value;
	if (prebidParams._rlv_enableUserIdModule) {
		for (const key in prebidParams) {
			if (key.startsWith(RLV_PREFIX)) {
				delete prebidParams[key];
			}
		}
		config = {
			name: userIdModule.prebidName,
			params: prebidParams,
			...(!userIdModule.hasNoStorage && { storage }),
			...(bidders && bidders.length > 0 && { bidders: bidders.map(({ bidder }) => bidder) || [] }),
			...(mappedValue && Object.keys(mappedValue).length > 0 && { value: mappedValue }),
		};
	}
	return config;
};

module.exports = {
	paramKey,
	unitParamKey,
	toParamMap,
	splitParams,
	getTagFields,
	bidderParams,
	cloneOrgValue,
	getInternalSspBidTagFields,
	getInternalUserIdModuleBidTagFields,
	getUserIdLocalStorageNameTagField,
	getParamsWithModifiedStorageNameField,
	getAllValuesWithMeta,
	getAllValuesWithMetaFromObj,
	generateUserIdModuleConfig,
};
