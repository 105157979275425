import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

class DailyTaskStatuses extends React.Component {
	renderStatuses(tasks, types, from, to) {
		const headerCells = [];
		const headerRows = [];
		headerCells.push(<TableCell key={headerCells.length + 1} />);
		_.map(types, (type) => {
			headerCells.push(
				<TableCell key={type.id}>{type.name + (type.active ? ' (Active)' : ' (Disabled)')}</TableCell>,
			);
		});
		headerRows.push(<TableRow key={headerRows.length + 1}>{headerCells}</TableRow>);

		const rows = [];
		const date = moment(from);
		const toDate = moment(to);
		const numDays = toDate.diff(date, 'days');
		for (let i = 0; i <= numDays; i += 1) {
			const cells = [];
			cells.push(
				<TableCell
					key={cells.length + 1}
					style={{ whiteSpace: 'nowrap' }}
				>
					<b>
						{ date.format('DD-MM-YYYY')}
					</b>
				</TableCell>,
			);
			_.map(types, (type) => {
				const matchedTask = _.find(tasks, (task) => ((task.objectId === type.id)
					&& (moment(task.date).format('DD-MM-YYYY') === date.format('DD-MM-YYYY'))));
				if (matchedTask) {
					cells.push(<TableCell key={matchedTask.id}>{matchedTask.state}</TableCell>);
				} else {
					cells.push(<TableCell key={cells.length + 1}>Not Run</TableCell>);
				}
			});
			rows.push(<TableRow key={rows.length + 1}>{cells}</TableRow>);
			date.add(1, 'days');
		}
		return (
			<Table stickyHeader>
				<TableHead>
					{headerRows}
				</TableHead>
				<TableBody>
					{rows}
				</TableBody>
			</Table>
		);
	}

	render() {
		const {
			title, data, types, from, to,
		} = this.props;

		return (
			<Paper>
				<Box padding={2}>
					<Typography variant="h4">{title}</Typography>
					<TableContainer>
						{this.renderStatuses(data, types, from, to)}
					</TableContainer>
				</Box>
			</Paper>
		);
	}
}
DailyTaskStatuses.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired,
	types: PropTypes.array.isRequired,
	from: PropTypes.instanceOf(Date).isRequired,
	to: PropTypes.instanceOf(Date).isRequired,
};

DailyTaskStatuses.defaultProps = {
};

export default DailyTaskStatuses;
