import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import AddIcon from '@mui/icons-material/Add';
import BackIcon from '@mui/icons-material/ArrowBack';
import styles from './styles.css';
import UserCollection from '../../containers/UserCollection';
import { ActionButton } from '../../components/ActionButton/ActionButton';

class PublisherUserList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { id } = this.props.match.params;
		return (
			<div className={styles.pageContentWrapper}>
				<Container>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TableContainer component={Paper}>
								<UserCollection publisherId={id} />
							</TableContainer>
						</Grid>
						<Grid item xs={12}>
							<ActionButton
								color="primary"
								label="Add a new user"
								icon={<AddIcon />}
								href={`/accounts/${id}/users/add`}
							/>
						</Grid>
						<Grid item xs={12}>
							<ActionButton
								label="Account list"
								icon={<BackIcon />}
								href="/accounts"
							/>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}
}

PublisherUserList.propTypes = {
	match: PropTypes.object.isRequired,
};

PublisherUserList.defaultProps = {

};

export default PublisherUserList;
