import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import { PopupForm } from '../Wrappers';
import InfoChannelEdit from './infoChannelEdit';
import SystemData from '../../lib/systemData';
import { LINK_DEFAULT_STYLE } from './constants';

function InfoChannelPopup({
	label, infoChannel, save, reload, workspace,
}) {
	return (
		<Grid item>
			<PopupForm
				getModel={() => {
					if (!infoChannel) {
						return new SystemData.models.TriggerInfoChannel();
					}
					return _.cloneDeep(_.isFunction(infoChannel) ? infoChannel() : infoChannel);
				}}
				title={label}
				size="sm"
				linkStyle={LINK_DEFAULT_STYLE}
				content={({ field }) => <InfoChannelEdit field={field} />}
				onApplyChanges={({ model }) => (!reload ? save(model) : workspace.op.reload(async () => {
					await save(model);
					await workspace.load();
				}))}
			/>
		</Grid>
	);
}

InfoChannelPopup.propTypes = {
	label: PropTypes.string.isRequired,
	infoChannel: PropTypes.any,
	save: PropTypes.func.isRequired,
	reload: PropTypes.bool,
	workspace: PropTypes.object.isRequired,
};

InfoChannelPopup.defaultProps = {
	reload: false,
	infoChannel: undefined,
};

export default InfoChannelPopup;
