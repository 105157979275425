import React, {
	createRef,
	forwardRef, Fragment, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';
import { Button } from '../Button/Button';

let instanceCounter = 0;

const UploadButton = forwardRef(({
	accept,
	children,
	className,
	onChange,
	style,
}, ref) => {
	const [id] = useState(`UploadButton-${++instanceCounter}`);
	const inputRef = createRef();
	const handleClick = useCallback((event) => {
		event.target.value = null;
	}, []);
	return (
		<>
			<input
				accept={accept}
				className={styles.input}
				id={id}
				onChange={onChange}
				onClick={handleClick}
				type="file"
				ref={inputRef}
			/>
			<Button
				className={className}
				ref={ref}
				style={style}
				variant="contained"
				onClick={() => inputRef.current.click()}
			>
				{children}
			</Button>
		</>
	);
});

UploadButton.propTypes = {
	accept: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	color: PropTypes.oneOf(['default', 'primary', 'secondary']),
	fullWidth: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	style: PropTypes.object,
};

UploadButton.defaultProps = {
	className: undefined,
	color: 'default',
	fullWidth: false,
	style: undefined,
};

export default UploadButton;
