const BidderNode = require('./bidderNode');

class SspShadowNode extends BidderNode {
	constructor(sspShadow, parentNode, sspPlac) {
		super(sspShadow, parentNode, 'SspShadowNode', { sspPlac });
	}

	get name() {
		return this.sspPlac.name;
	}

	get sspId() {
		return this.sspPlac.sspId;
	}

	get sspPlacNode() {
		return this.sspPlac;
	}

	get sspPlacementId() {
		return this.sspPlac.sspPlacementId;
	}

	get dbParentNode() {
		return this.sspPlac;
	}
}
SspShadowNode.parentPath = 'shadows';

module.exports = SspShadowNode;
