const DateUtils = require('../misc/dateUtils');
const Granularity = require('./granularity');

// defaultGranularity values will be overwritten with the minimum granularity
// specified in the report customizer if the values listed below are not
// supported by the report type
const getBaseRanges = () => ({
	yesterday: {
		name: 'Yesterday',
		dbKey: 'yesterday',
		defaultGranularity: Granularity.getOptions().per10min,
		neverIncludesToday: true,
	},
	currentWeek: {
		name: 'This Week',
		dbKey: 'currentWeek',
		defaultGranularity: Granularity.getOptions().perday,
	},
	lastWeek: {
		name: 'Last Week',
		dbKey: 'lastWeek',
		defaultGranularity: Granularity.getOptions().perday,
	},
	last30Days: {
		name: 'Last 30 Days',
		dbKey: 'last30Days',
		defaultGranularity: Granularity.getOptions().perday,
	},
	currentMonth: {
		name: 'This Month',
		dbKey: 'currentMonth',
		defaultGranularity: Granularity.getOptions().perday,
	},
	custom: {
		name: 'Custom',
		dbKey: undefined,
		defaultGranularity: Granularity.getOptions().perday,
	},
});

const getLongTermRanges = () => ({
	...getBaseRanges(),
	currentQuarter: {
		name: 'This Quarter',
		dbKey: 'currentQuarter',
		defaultGranularity: Granularity.getOptions().permonth,
	},
	currentYear: {
		name: 'This Year',
		dbKey: 'currentYear',
		defaultGranularity: Granularity.getOptions().permonth,
	},
	lastMonth: {
		name: 'Last Month',
		dbKey: 'lastMonth',
		defaultGranularity: Granularity.getOptions().perday,
		neverIncludesToday: true,
	},
	last90Days: {
		name: 'Last 90 Days',
		dbKey: 'last90Days',
		defaultGranularity: Granularity.getOptions().perday,
		neverIncludesToday: true,
	},
	prevThreeMonths: {
		name: 'Last 3 Months',
		dbKey: 'prevThreeMonths',
		defaultGranularity: Granularity.getOptions().permonth,
	},
	lastThreeMonths: {
		name: 'Previous 3 Months',
		dbKey: 'lastThreeMonths',
		defaultGranularity: Granularity.getOptions().permonth,
		neverIncludesToday: true,
	},
	lastQuarter: {
		name: 'Last Quarter',
		dbKey: 'lastQuarter',
		defaultGranularity: Granularity.getOptions().permonth,
		neverIncludesToday: true,
	},
	lastSixMonths: {
		name: 'Last 6 Months',
		dbKey: 'lastSixMonths',
		defaultGranularity: Granularity.getOptions().permonth,
		neverIncludesToday: true,
	},
	lastYear: {
		name: 'Last Year',
		dbKey: 'lastYear',
		defaultGranularity: Granularity.getOptions().permonth,
		neverIncludesToday: true,
	},
});

const todayRanges = () => ({
	today: {
		name: 'Today',
		dbKey: 'today',
		defaultGranularity: Granularity.getOptions().per10min,
	},
});

const getShortTermRanges = () => ({
	...getBaseRanges(),
	...todayRanges(),
});

const getFutureRanges = () => ({
	...todayRanges(),
	custom: getBaseRanges().custom,
	next30Days: {
		name: 'Next 30 days',
		dbKey: 'next30Days',
		defaultGranularity: Granularity.getOptions().perday,
	},
	nextWeek: {
		name: 'Next Week',
		dbKey: 'nextWeek',
		defaultGranularity: Granularity.getOptions().perday,
	},
	nextMonth: {
		name: 'Next Month',
		dbKey: 'nextMonth',
		defaultGranularity: Granularity.getOptions().perday,
	},
});

const getAllRanges = () => ({
	...getLongTermRanges(),
	...getShortTermRanges(),
	...getFutureRanges(),
});

const getAllRangesValues = () => Object.values(
	getAllRanges(),
);

const getAllDbKeys = () => (
	getAllRangesValues().map(({ dbKey }) => dbKey)
);

const getRangeByDbKey = (dbKey) => getAllRangesValues()
	.find((range) => range.dbKey === dbKey);

const getDateRangeBoundaries = (dbKey, nowDate, includeToday = true) => {
	const range = getAllRanges();
	const now = nowDate || new Date();
	const originalNow = new Date(now);
	if (DateUtils.isToday(now) && !includeToday && !(range[dbKey] || {}).neverIncludesToday) {
		now.setDate(now.getDate() - 1);
	}
	switch (dbKey) {
		case range.currentWeek.dbKey:
			return DateUtils.currentWeek(now);
		case range.currentMonth.dbKey:
			return DateUtils.currentMonth(now);
		case range.currentQuarter.dbKey:
			return DateUtils.currentQuarter(now);
		case range.currentYear.dbKey:
			return DateUtils.currentYear(now);
		case range.lastWeek.dbKey:
			return DateUtils.lastWeek(now);
		case range.lastMonth.dbKey:
			return DateUtils.lastMonth(now);
		case range.lastThreeMonths.dbKey:
			return DateUtils.lastThreeMonths(now);
		case range.prevThreeMonths.dbKey:
			return DateUtils.prevThreeMonths(now);
		case range.last90Days.dbKey:
			return DateUtils.last90Days(now);
		case range.lastSixMonths.dbKey:
			return DateUtils.lastSixMonths(now);
		case range.lastQuarter.dbKey:
			return DateUtils.lastQuarter(now);
		case range.lastYear.dbKey:
			return DateUtils.lastYear(now);
		case range.last30Days.dbKey:
			return DateUtils.last30Days(now);
		case range.yesterday.dbKey:
			return {
				start: DateUtils.fullDay(originalNow, -1),
				end: DateUtils.fullDay(originalNow, -1),
			};
		case range.today.dbKey:
			return {
				start: DateUtils.fullDay(now),
				end: DateUtils.fullDay(now),
			};
		case range.next30Days.dbKey:
			return DateUtils.next30Days(now);
		case range.nextWeek.dbKey:
			return DateUtils.nextWeek(now);
		case range.nextMonth.dbKey:
			return DateUtils.nextMonth(now);
		default:
			// TODO: missing universal logging solution for frontend
			// eslint-disable-next-line no-console
			console.error(`unsupported date range '${dbKey}'`);
			return {};
	}
};

module.exports = {
	getBaseRanges,
	getLongTermRanges,
	getShortTermRanges,
	getAllRanges,
	getAllRangesValues,
	getAllDbKeys,
	getFutureRanges,
	getRangeByDbKey,
	getDateRangeBoundaries,
};
