import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TreeSelect from '../TreeSelect';

function CheckboxGroup({ items, onChange, selected }) {
	const getChildren = useCallback((item) => item.subitems, []);
	const getLabel = useCallback((item) => item.text, []);
	const getNodeId = useCallback((item) => item.id, []);
	const handleSelectionChange = useCallback(
		(selectedItems) => onChange(selectedItems.map(({ id }) => id)),
		[onChange],
	);

	return (
		<TreeSelect
			rootNode={{
				id: 'select_all',
				text: 'All',
				subitems: items,
			}}
			getChildren={getChildren}
			getLabel={getLabel}
			getNodeId={getNodeId}
			selectedObjects={selected.map((id) => ({ id }))}
			expandOnce={[{ id: 'select_all' }]}
			onSelectionChange={handleSelectionChange}
		/>
	);
}

CheckboxGroup.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		text: PropTypes.string.isRequired,
		subitems: PropTypes.array,
	})).isRequired,
	onChange: PropTypes.func.isRequired,
	selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

CheckboxGroup.defaultProps = {
	selected: [],
};

export default CheckboxGroup;
