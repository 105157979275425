import PropTypes from 'prop-types';
import React, {
	useEffect, useRef, useLayoutEffect,
} from 'react';

export default function ReportRow({
	minHeight, minWidth, scaleByHeight, aspectRatio, children,
}) {
	const ref = useRef(null);
	const setSize = () => {
		if (!ref.current) {
			return;
		}
		const width = ref?.current?.clientWidth;
		const columns = Math.round(ref.current.parentNode.parentNode.clientWidth / width);
		const newHeight = columns <= 1 ? 700 : minHeight;
		const scale = (width / minWidth) || 1;

		const newWidth = width < minWidth ? minWidth : width;
		const newScale = scale < 1 ? scale : 1;

		// Set width before scale
		ref.current.firstElementChild.style.width = `${newWidth}px`;

		// Rescale
		ref.current.firstElementChild.style.transform = `scale(${newScale})`;
		ref.current.firstElementChild.style.transformOrigin = '0 0';

		// Estimate height
		if (columns > 1) {
			if (scaleByHeight) {
				ref.current.firstElementChild.style.height = `${newHeight / (aspectRatio || 1)}px`;
			} else {
				const height = width / aspectRatio || 1;
				ref.current.firstElementChild.style.height = `${height}px`;
			}
		} else {
			ref.current.firstElementChild.style.height = 'auto';
		}
	};

	useLayoutEffect(() => {
		setSize();
	});

	useEffect(() => {
		function handleWindowResize() {
			setSize();
		}

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	});

	return (
		<div ref={ref}>
			{children}
		</div>
	);
}

ReportRow.propTypes = {
	aspectRatio: PropTypes.number,
	minHeight: PropTypes.number,
	minWidth: PropTypes.number,
	scaleByHeight: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

ReportRow.defaultProps = {
	aspectRatio: 1,
	minHeight: 700,
	minWidth: 450,
	scaleByHeight: false,
};
