import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import DataTable from '../DataTable';
import { stores } from '../../stores';

class UserSegmentList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { segments } = this.props;
		const segCmp = (s1, s2) => {
			const n1 = (s1.segment || {}).name || '';
			const n2 = (s2.segment || {}).name || '';
			if (n1 === n2) {
				return 0;
			}
			return n1 < n2 ? -1 : 1;
		};
		const noUser = stores.identity.isNoUser();
		const fmtSeg = (seg) => {
			if (!seg) {
				return '[UNKNOWN]';
			}
			if (noUser) {
				return seg.name;
			}
			return <a href={`/settings/segments/${seg.id}`}>{seg.name}</a>;
		};
		return (
			<Grid item xs={12}>
				<TableContainer component={Paper}>
					<DataTable
						showCheckboxes={false}
						selectableRows={false}
						identifier={(row) => row.id}
						definitions={[
							{
								key: 'id',
								title: 'ID',
								style: { width: '200px' },
							},
							{
								key: 'segment',
								title: 'Name',
								format: fmtSeg,
								whenNull() {
									return '[UNKNOWN]';
								},
							},
						]}
						data={segments.sort(segCmp)}
					/>
				</TableContainer>
			</Grid>
		);
	}
}

UserSegmentList.propTypes = {
	segments: PropTypes.array.isRequired,
};

UserSegmentList.defaultProps = {

};

export default UserSegmentList;
