import React from 'react';
import { Box } from '@mui/material';
import Sort from './Sort';
import PropertyFilter from './PropertyFilter';
import SearchFilter from './SearchFilter';

function Filters() {
	return (
		<Box sx={{
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'flex-end',
		}}
		>
			<Box sx={{ display: 'flex' }}>
				<PropertyFilter />
				<SearchFilter />
			</Box>
			<Box sx={{ mb: 1 }}>
				<Sort />
			</Box>
		</Box>
	);
}

export default Filters;
