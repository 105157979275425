import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DynamicSearchFilter from '../DynamicSearchFilter';
import ReactUtils from '../../lib/reactUtils';
import TextField from '../TextField';
import { withUpdate, FormOf } from '../Wrappers';
import JobButton from '../JobButton';
import * as relevantApi from '../../api/relevant';
import SystemData from '../../lib/systemData';
import Spinner from '../Spinner';
import CsvImport from '../CsvImport';
import Base from '../../layouts/Base';
import PopupSelector from '../PopupSelector';
import DataTable from '../DataTable';
import Checkbox from '../Checkbox';
import { ConfirmDialog } from '../ConfirmDialog';
import { Dialog } from '../Dialog';

const AFTER_PRESS_CHECK_MS = 1000;

const showError = (message) => Base.renderGlobal((done) => (
	<Dialog
		open
		status="error"
		text={message}
		onClose={done}
	/>
));

const relevant = relevantApi.DynamicExports;

@withUpdate
class AdvertiserEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = { currentAdv: { name: '', externalId: '' }, saves: 0 };
		this.fld = ReactUtils.fld(this);
		this.knownAdvs = {};
	}

	onNameChanged(newVal) {
		const { mappingDim } = this.props;
		const { currentAdv } = this.state;
		if (this.knownAdvs[newVal]) {
			this.setState({ currentAdv: this.knownAdvs[newVal] });
			return;
		}
		const lastPress = new Date();
		this.lastPress = lastPress;
		const newAdv = { ...currentAdv, name: newVal };
		if (currentAdv.id) {
			newAdv.id = null;
			newAdv.externalId = '';
		}
		this.setState({ isLoading: true, currentAdv: newAdv });
		setTimeout(async () => {
			if (lastPress !== this.lastPress) {
				return;
			}
			const advs = await relevant[mappingDim.clsName].call('getObjects', { objNames: [newVal], asBaseModels: true });
			if (advs.length && lastPress === this.lastPress) {
				const adv = Object.assign(advs[0], { advNr: advs[0].seq });
				this.knownAdvs[newVal] = adv;
				this.setState({ currentAdv: adv, isLoading: false });
			} else {
				this.setState({ isLoading: false });
			}
		}, AFTER_PRESS_CHECK_MS);
	}

	async doImport({ preventDefault, done, allRows }) {
		const { mappingDim } = this.props;
		const newRows = allRows.filter((r) => r.isNew);
		const updatedRows = allRows.filter((r) => r.willUpdate);
		if (!newRows.length && !updatedRows.length) {
			await Base.renderGlobal((close) => (
				<Dialog
					open
					status="success"
					text="Nothing to do"
					onClose={close}
				/>
			));
			return;
		}
		const ok = await Base.renderGlobal((closeFn) => (
			<ConfirmDialog
				open
				text={(
					<span>
						Are you sure you want to add
						<span style={{ fontWeight: 'bold' }}>
							&nbsp;
							{newRows.length}
&nbsp;
						</span>
						{mappingDim.name}
						(s) and update
						<span style={{ fontWeight: 'bold' }}>
							{updatedRows.length}
						</span>
						?
					</span>
				)}
				onAny={closeFn}
			/>
		));
		if (!ok) {
			preventDefault();
			return;
		}
		await relevant[mappingDim.clsName].call('updateObjects', {
			objects: newRows.concat(updatedRows).map((r) => _.pick(r, ['name', 'externalId'])),
		});
		done();
	}

	async handleCsvImport(orgRows, { useRlvId }) {
		const { mappingDim } = this.props;
		const rows = _.values(_.mapValues(_.groupBy(orgRows, 'name'), (arr) => _.last(arr)));
		const names = rows.map((r) => r.name);
		let existing;
		if (useRlvId) {
			const seqIds = [];
			for (const n of names) {
				const str = n.trim();
				const asNum = parseInt(str, 10);
				if (!asNum || asNum.toString() !== str || asNum < 10) {
					return showError(`Value '${n}' is invalid`);
				}
				seqIds.push(asNum);
			}
			existing = await relevant[mappingDim.clsName].call('getObjects', { objNrs: seqIds });
			const bySeq = _.keyBy(existing, 'seq');
			if (existing.length < seqIds.length) {
				const invalid = seqIds.filter((nr) => !bySeq[nr]);
				return showError(`The following internal id(s) doesn't exist: ${invalid.join(', ')}`);
			}
			rows.forEach((row) => {
				row.name = bySeq[row.name].name;
			});
		} else {
			existing = await relevant[mappingDim.clsName].call('getObjects', { objNames: names });
		}
		const existingByName = _.keyBy(existing, 'name');
		const settings = { overwriteExternalIds: false, showUnchanged: false };
		const metaRows = () => rows.map((obj) => {
			const { name, externalId } = obj;
			const res = { ...obj };
			const existingAdv = existingByName[name];
			res.visibleExternalId = externalId;
			if (existingAdv) {
				if (!settings.overwriteExternalIds || !externalId || externalId === existingAdv.externalId) {
					delete res.externalId;
					res.visibleExternalId = existingAdv.externalId;
					res.isOldExternalId = true;
				} else if (externalId) {
					res.willUpdate = true;
				}
			}
			res.isNew = !existingAdv;
			return res;
		});
		return Base.renderGlobal((done) => (
			<FormOf
				model={settings}
				content={({ field }) => (
					<PopupSelector
						forceExpanded
						onApplyChanges={async ({ preventDefault }) => this.doImport({
							done,
							preventDefault,
							allRows: metaRows(),
						})}
						onCancel={done}
					>
						<Checkbox
							label="Overwrite External IDs"
							{...field('overwriteExternalIds')}
						/>
						<Checkbox
							label="Show unchanged"
							{...field('showUnchanged')}
						/>
						<DataTable
							showCheckboxes={false}
							selectableRows={false}
							identifier={(row) => row.name}
							definitions={[
								{
									key: 'name',
									title: 'Name',
								},
								{
									key: 'name',
									title: 'External ID',
									format: (__, { isOldExternalId, visibleExternalId }) => (
										<span style={{ color: isOldExternalId ? 'gray' : 'black' }}>
											{visibleExternalId}
										</span>
									),
								},
								{
									key: 'name',
									title: 'Is new',
									format: (__, { isNew }) => (
										<Box component="span" color={isNew ? 'success.main' : 'grey.600'}>{ isNew ? 'New' : 'Existing'}</Box>
									),
								},
							]}
							data={_.sortBy(metaRows().filter((r) => settings.showUnchanged || r.isNew || r.willUpdate), 'name')}
						/>
					</PopupSelector>
				)}
			/>
		));
	}

	async save() {
		const { saves, currentAdv } = this.state;
		const { mappingDim } = this.props;
		try {
			this.setState({ isSaving: true });
			if (currentAdv.id) {
				await relevant[mappingDim.clsName].call('updateObjects', { objects: [currentAdv] });
			} else {
				const adv = new SystemData.models[mappingDim.clsName](currentAdv);
				await relevant[mappingDim.clsName].add(adv);
				this.setState({ currentAdv: adv });
				this.knownAdvs[adv.name] = adv;
			}
		} finally {
			this.setState({ isSaving: false, saves: saves + 1 });
		}
	}

	render() {
		const { mappingDim } = this.props;
		const {
			isLoading, isSaving, currentAdv, saves,
		} = this.state;
		const disabled = isLoading || isSaving || !currentAdv.name;
		return (
			<Grid container spacing={3} alignItems="center">
				<Grid item xs={6}>
					<DynamicSearchFilter
						textFieldLabel={`Select existing ${mappingDim.uiName}`}
						showMoreLabel={mappingDim.pluralName}
						onlySingleSelect
						key={saves}
						onChange={(__, arr, { closeSearch }) => {
							const adv = new SystemData.models[mappingDim.clsName](_.cloneDeep(_.last(arr)));
							adv.externalId = adv.externalId || '';
							this.knownAdvs[adv.name] = adv;
							this.setState({ currentAdv: adv });
							closeSearch();
						}}
						type="programmatic"
						label={mappingDim.dimension}
						groupBy={mappingDim.dimension}
						includeExternalId
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<CsvImport
						renderBefore={({ field }) => (
							<Checkbox
								label={`${mappingDim.uiCName} is System Internal ID`}
								{...field('useRlvId')}
							/>
						)}
						colSelectors={[
							{
								name: 'name',
								label: `${mappingDim.uiCName} column`,
								required: true,
							},
							{
								name: 'externalId',
								label: 'External ID column',
							},
						]}
						onCsvImported={(rows, settings) => this.handleCsvImport(rows, settings)}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						label="Name"
						value={currentAdv.name}
						name="name"
						disabled={isSaving}
						onChange={(ev) => this.onNameChanged(ev.target.value)}
						fullWidth
					/>
				</Grid>

				<Grid item xs={3}>
					<TextField
						label="ExternalId"
						value={currentAdv.externalId}
						name="externalId"
						onChange={(ev) => this.update(() => {
							currentAdv.externalId = ev.target.value;
						})}
						fullWidth
						disabled={disabled}
					/>

				</Grid>
				<Grid item xs={3}>
					<JobButton
						label="Save"
						color="secondary"
						fn={() => this.save()}
						disabled={disabled}
					/>

				</Grid>
				<Grid item xs={3}>
					{!!(!isLoading && currentAdv.name) && (
						<div>
							{currentAdv.id
								? (<Box component="span" color="success.main">Existing</Box>)
								: (<Box component="span" color="error.main">Non existing</Box>)}
						</div>
					)}
					{isLoading && <div style={{ marginTop: -10, marginLeft: -90 }}><Spinner /></div>}
				</Grid>
			</Grid>
		);
	}
}

AdvertiserEdit.propTypes = {
	mappingDim: PropTypes.object.isRequired,
};

AdvertiserEdit.defaultProps = {
};

export default AdvertiserEdit;
