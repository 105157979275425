import PropTypes from 'prop-types';
import React from 'react';
import {
	getAllDbKeys, getAllRanges, getDateRangeBoundaries, getRangeByDbKey,
} from 'relevant-shared/reportData/dynamicDateRanges';
import Granularity from 'relevant-shared/reportData/granularity';
import Select from '../Select';

const descendingByLabel = (a, b) => (a.label < b.label ? 0 : 1);

const toSelectItems = ({ name, dbKey }) => ({
	label: name,
	value: dbKey,
});

const replaceUndefined = (dateRange, labelForUndefined) => ({
	...dateRange,
	name: dateRange.name || labelForUndefined,
	dbKey: dateRange.dbKey || labelForUndefined,
});

const getGranularity = (dbKey, supportedGranularities) => {
	const { defaultGranularity } = getRangeByDbKey(dbKey);
	const granularityKey = Granularity.getDatabaseKey(defaultGranularity);
	return (supportedGranularities.includes(granularityKey))
		? granularityKey
		// TODO: granularities should have explicit order (rank) instead of
		// using the array index here, but that needs fixing on a global level
		: supportedGranularities[0];
};

function DynamicDateRangeSelector({
	update, currentDynamicDateRange, dateRanges, supportedGranularities, allowToday,
}) {
	const labelForUndefined = 'Custom';

	const onDateRangeChange = ({ target }) => {
		if (target.value === labelForUndefined) {
			const { dbKey } = getAllRanges().custom;
			return update({ dynamicDateRange: dbKey });
		}
		return update({
			dynamicDateRange: target.value,
			granularity: getGranularity(target.value, supportedGranularities),
			...getDateRangeBoundaries(target.value, new Date(), allowToday),
		});
	};

	const dynamicDateRangeItems = dateRanges
		.map((dateRange) => replaceUndefined(dateRange, labelForUndefined))
		.map(toSelectItems)
		.sort(descendingByLabel);

	return (
		<Select
			label="Date range"
			name="Date range"
			items={dynamicDateRangeItems}
			onChange={onDateRangeChange}
			underlineShow={false}
			value={currentDynamicDateRange || labelForUndefined}
		/>
	);
}

DynamicDateRangeSelector.propTypes = {
	// 'undefined' is possible default value, therefore disable eslint
	// eslint-disable-next-line react/require-default-props
	currentDynamicDateRange: PropTypes.oneOf(getAllDbKeys()),
	update: PropTypes.func.isRequired,
	dateRanges: PropTypes.arrayOf(String).isRequired,
	supportedGranularities: PropTypes.arrayOf(String).isRequired,
	allowToday: PropTypes.bool,
};

DynamicDateRangeSelector.defaultProps = {
	allowToday: false,
};

export {
	DynamicDateRangeSelector,
	getGranularity,
};
