import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PrebidBuildHandler } from '../../api/relevant';
import {
	getNotificationData,
	getNonDismissedBuilds,
} from '../../lib/prebidBuild';
import { BuildContext } from './context';
import usePrevious from '../../hooks/usePrevious';
import useInterval from '../../hooks/useInterval';
import { stores } from '../../stores';
import { useToast } from '../../hooks/useToast';

// 30m
const normalIntervalFrequency = 1800;
// 30s
const triggerFrequency = 30;

// TODO: Might want to separate this into two hooks or similar,
// one that handles the actual build pinging etc and one that
// handles the connection to the toast
function BuildProvider({ children }) {
	const [toast, setToast] = useToast({});
	const [builds, setBuilds] = useState({});
	const prevNotificationOpenState = usePrevious(toast.open);
	const [triggerBuildNotification, setTriggerBuildNotification] = useState(false);
	const location = useLocation();
	const [intervalFrequency, setIntervalFrequency] = useState(normalIntervalFrequency);

	const checkBuildStatus = useCallback(async () => {
		const { builds: allBuilds } = await PrebidBuildHandler.call(
			'getBuildStatus',
			{ includeDismissed: true },
		);
		setBuilds(allBuilds);
	}, []);

	useInterval(checkBuildStatus, intervalFrequency * 1000, false);

	/**
	 * Set notification when builds have fetched
	 */
	useEffect(() => {
		const email = stores.identity.email();
		// Toast should only show data for non dismissed builds
		const nonDismissedBuilds = getNonDismissedBuilds({ ...builds }, email);
		const { toastData, stop } = getNotificationData(nonDismissedBuilds);
		if (stop) {
			setIntervalFrequency(normalIntervalFrequency);
		}
		setToast(toastData);
	}, [builds, setToast]);

	// Check build status when triggered by user (i.e for example starting a new build)
	useEffect(() => {
		if (triggerBuildNotification) {
			checkBuildStatus();
			setIntervalFrequency(triggerFrequency);
			setTriggerBuildNotification(false);
		}
	}, [triggerBuildNotification, checkBuildStatus]);

	// Check build status on every location change
	useEffect(() => {
		// Check build status again after 30 minutes/30 sec
		checkBuildStatus();
		if (!toast.open) {
			setIntervalFrequency(normalIntervalFrequency);
		} else {
			setIntervalFrequency(triggerFrequency);
		}
	}, [toast.open, location, checkBuildStatus]);

	// If user closes notification, dismiss current build statuses
	useEffect(() => {
		if (!toast.open && prevNotificationOpenState) {
			PrebidBuildHandler.call('dismissCurrentBuilds');
			setIntervalFrequency(normalIntervalFrequency);
		}
	}, [toast.open, prevNotificationOpenState]);

	return (
		<BuildContext.Provider
			value={{
				builds,
				notificationTrigger: [triggerBuildNotification, setTriggerBuildNotification],
			}}
		>
			{ children }
		</BuildContext.Provider>
	);
}

BuildProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default BuildProvider;
