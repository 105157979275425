import _ from 'lodash';
import * as chartjs from 'chart.js';
import { Chart } from 'chart.js';
import { MAX_NO_AUTO_SKIP } from 'relevant-shared/reportData/constants';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';
import { fmtNum } from '../../lib/numberUtils';
import DateUtils from '../../lib/dateUtils';

Chart.register(annotationPlugin);

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const formatIfNumeric = (value) => {
	const toNumber = Number(value);
	return isNaN(toNumber) ? value : fmtNum(toNumber);
};

const shortenTickLabels = (ticks, maxLabelLength) => {
	ticks.forEach((tick) => {
		if (tick.label.length > maxLabelLength) {
			tick.label = `${tick.label.substring(0, maxLabelLength)}...`;
		}
	});
};

// Get corresponding elements when hovering legend
function getElementsInHover(chart, legendItem) {
	const ci = chart;
	const prevHoveredDatasetIndex = legendItem.datasetIndex;
	const elements = ci.getDatasetMeta(prevHoveredDatasetIndex).data;
	const res = [];
	elements.forEach((el) => {
		res.push({
			element: el,
			datasetIndex: prevHoveredDatasetIndex,
		});
	});
	return res;
}

function toggleLabel(annotationName, ctx, event) {
	const { chart } = ctx;
	const annotationOpts = chart.options.plugins.annotation.annotations[annotationName];
	annotationOpts.label.display = !annotationOpts.label.display;
	annotationOpts.label.position = `${event.x / (ctx.chart.chartArea.width * 100)}%`;
	chart.update();
}

/**
 * Create options for chart.js
 */
function createOptions(
	data,
	chartType,
	currency,
	showLegend,
	extraOptions,
	stacked,
	barChartType,
	events,
) {
	let options;
	const layoutOptions = {
		padding: {
			top: 15,
			bottom: 15,
		},
	};

	const tooltipOptions = {
		titleFont: {
			size: 20,
			family: 'Roboto, sans-serif',
		},
		bodyFont: {
			family: 'Roboto, sans-serif',
			size: 16,
			spacing: 10,
		},
		titleMarginBottom: 20,
		backgroundColor: '#262F3D',
		padding: 15,
		caretPadding: 5,
		caretSize: 10,
		cornerRadius: 2,
	};

	const annotations = {};
	const indexAxis = barChartType === 'horizontal' ? 'y' : 'x';

	const addAnnotation = (annotationDate, content, options = {}) => {
		annotations[annotationDate] = {
			drawTime: 'beforeDraw',
			type: 'line',
			[`${indexAxis}Min`]: annotationDate,
			[`${indexAxis}Max`]: annotationDate,
			borderColor: 'rgba(0, 0, 0, 0.8)',
			borderDash: [5, 5],
			borderWidth: 3,
			label: {
				drawTime: 'afterDraw',
				content,
				display: false,
			},
			enter(ctx, event) {
				toggleLabel(annotationDate, ctx, event);
			},
			leave(ctx, event) {
				toggleLabel(annotationDate, ctx, event);
			},
			...options,
		};
	};

	if (events) {
		events.forEach(({ date, closestDate, title }) => {
			// If there is another event at the same closest date
			if (closestDate in annotations) {
				const toAdd = `${moment.utc(date).format('YYYY-MM-DD HH:mm')}: ${title}`;
				if (_.isArray(annotations[closestDate].label.content)) {
					annotations[closestDate].label.content.push(toAdd);
				} else {
					annotations[closestDate].label.content = [
						annotations[closestDate].label.content,
						toAdd,
					];
				}
			} else {
				addAnnotation(closestDate, `${moment.utc(date).format('YYYY-MM-DD HH:mm')}: ${title}`);
			}
		});
	}

	if (chartType === 'bar') {
		options = {
			indexAxis,
			layout: {
				...layoutOptions,
			},
			plugins: {
				tooltip: {
					...tooltipOptions,
					displayColors: true,
					callbacks: {
						label: (tooltipItem) => tooltipItem.dataset
							.getTooltipText(tooltipItem),
					},
				},
				legend: {
					display: showLegend,
					align: 'start',
				},
				annotation: {
					annotations,
				},
			},
			scales: {
				y: {
					stacked: true,
					beginAtZero: true,
					afterTickToLabelConversion: ({ ticks }) => shortenTickLabels(ticks, 60),
				},
				x: {
					stacked: true,
					ticks: {
						autoSkip: data.labels.length > MAX_NO_AUTO_SKIP,
					},
					afterTickToLabelConversion: ({ ticks }) => shortenTickLabels(ticks, 20),
				},
			},
		};
	} else if (chartType === 'line') {
		options = {
			layout: {
				...layoutOptions,
			},
			plugins: {
				tooltip: {
					...tooltipOptions,
					position: 'nearest',
					mode: 'nearest',
					intersect: true,
					callbacks: {
						label: (tooltipItem) => tooltipItem.dataset
							.getTooltipText(tooltipItem),
					},
				},
				legend: {
					display: showLegend,
					fontFamily: 'Roboto, sans-serif',
					align: 'center',
					position: 'bottom',
					labels: {
						position: 'top',
						fontSize: 14,
						fontColor: '#000',
						boxWidth: 14,
						generateLabels: (chart) => chart.data.datasets
							.filter((d) => !d.hideLegend)
							.map((d, i) => ({
								text: d.label,
								fillStyle: d.backgroundColor,
								hidden: !chart.isDatasetVisible(i),
								strokeStyle: d.borderColor,
								pointStyle: d.pointStyle,
								pointRadius: d.pointRadius || 1,
								pointHoverRadius: d.pointHoverRadius || 2,
								datasetIndex: i,
							})),

					},
					onHover(__, legendItem) {
						const elements = getElementsInHover(this.chart, legendItem);
						this.chart.updateHoverStyle(elements, null, true);
						this.chart.render();
					},
					onLeave(__, legendItem) {
						const elements = getElementsInHover(this.chart, legendItem);
						this.chart.updateHoverStyle(elements, null, false);
						this.chart.render();
					},
				},
				annotation: {
					annotations,
				},
			},
			scales: {
				y: {
					stacked,
					beginAtZero: true,
					ticks: {
						callback: formatIfNumeric,
					},
				},
				x: {
					ticks: {
						autoSkip: data.labels.length > MAX_NO_AUTO_SKIP,
					},
				},
			},
			onClick: (e, elements, chart) => {
				// Get the closest x axis date
				const activePoints = chart.getElementsAtEventForMode(e, 'index', { intersect: false }, false);
				const firstPoint = activePoints[0];
				const closestXLabel = chart.data.labels[firstPoint.index];
			},
		};
	} else if (chartType === 'pie') {
		options = {
			plugins: {
				tooltip: {
					...tooltipOptions,
					position: 'nearest',
					intersect: true,
					callbacks: {
						label(tooltipItem) {
							const { dataset, dataIndex, label } = tooltipItem;
							const { data: d } = dataset;
							const total = d.reduce((acc, value) => acc + value, 0);
							const value = d[dataIndex];
							const pct = (value / total) * 100;
							return `  ${label}: ${fmtNum(value)} ${currency} (${fmtNum(pct)}%)`;
						},
					},
				},
			},
		};
	}

	// In certain conditions additional options that will enable axes are passed
	// down. These options are only meant for plot-style charts.
	return chartType === 'pie' ? options : _.merge(options, extraOptions);
}

const AreaTypes = [
	{
		name: 'asForecastedPerc',
		fillStyle: 'rgba(255, 255, 255, 0.3)',
	},
	{
		name: 'clearBeforePerc',
		fillBefore: true,
		fillStyle: 'rgba(0, 0, 0, 0.3)',
	},
];

// Modifications to apply when running forecast
chartjs.Chart.register({
	id: 'forecast_canvas_modifications',
	afterDraw: (chart) => {
		const xAxis = chart.boxes.find((b) => b.id === 'x');
		const yAxis = chart.boxes.find((b) => b.id === 'y');
		if (!xAxis || !yAxis) {
			return;
		}
		const full = {
			x: xAxis.left,
			y: yAxis.bottom - yAxis.height,
			width: xAxis.width,
			height: yAxis.maxHeight,
		};
		AreaTypes.forEach(({ name, fillBefore, fillStyle }) => {
			const val = chart.config.options[name];
			if (!val) {
				return;
			}
			const EXTRA_MARGIN_RIGHT = 20;
			const rect = {
				y: full.y,
				height: full.height,
			};
			if (fillBefore) {
				Object.assign(rect, {
					x: full.x,
					width: (full.width * val),
				});
			} else {
				Object.assign(rect, {
					x: full.x + ((1 - val) * full.width),
					width: (full.width * val) + EXTRA_MARGIN_RIGHT,
				});
			}
			const { ctx } = chart;
			ctx.save();
			ctx.fillStyle = fillStyle;
			ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
			if (val < 1) {
				ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
				ctx.lineWidth = 2;
				ctx.beginPath();
				ctx.setLineDash([5, 5]);
				const xPos = rect.x + (fillBefore ? rect.width : 0);
				ctx.moveTo(xPos, rect.y);
				ctx.lineTo(xPos, rect.y + rect.height);
				ctx.stroke();
			}
			ctx.restore();
		});
	},
	XafterDraw: (chart) => {
		const xAxis = chart.boxes.find((b) => b.id === 'x');
		const yAxis = chart.boxes.find((b) => b.id === 'y');
		const { asForecastedPerc } = chart.config.options;
		if (asForecastedPerc) {
			const EXTRA_RIGHT_PX = 20;
			const full = {
				x: xAxis.left,
				y: yAxis.bottom - yAxis.height,
				width: xAxis.width,
				height: yAxis.maxHeight,
			};
			const rect = {
				x: full.x + ((1 - asForecastedPerc) * full.width),
				y: full.y,
				width: (full.width * asForecastedPerc) + EXTRA_RIGHT_PX,
				height: full.height,
			};
			const { ctx } = chart;
			ctx.save();
			ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
			ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
			if (asForecastedPerc < 1) {
				ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
				ctx.lineWidth = 2;
				ctx.beginPath();
				ctx.setLineDash([5, 5]);
				ctx.moveTo(rect.x, rect.y);
				ctx.lineTo(rect.x, rect.y + rect.height);
				ctx.stroke();
			}
			ctx.restore();
		}
	},
});

export {
	createOptions,
};
