import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ConfirmDialog } from '../ConfirmDialog';
import { Dialog } from '../Dialog';

export function FailureModal({ done, failures, workspace }) {
	return (
		<Dialog
			open
			status="error"
			onClose={done}
			text={(
				<div>
					The following alarm(s) didn&apos;t run for the following reason(s):
					<ul>
						{_.entries(failures).map(([id, { errorMsg }]) => (
							<li key={id}>
								<b>{(workspace.triggerOf(id) || { name: '[Unknown]' }).name}</b>
								{' '}
								-
								{errorMsg}
							</li>
						))}
					</ul>
				</div>
			)}
		/>
	);
}

FailureModal.propTypes = {
	done: PropTypes.func.isRequired,
	failures: PropTypes.object.isRequired,
	workspace: PropTypes.object.isRequired,
};

export function ConfirmActionModal({
	isTrigger, closeFn, action, matches, otherOwnersWarn,
}) {
	return (
		<ConfirmDialog
			open
			text={(
				<div>
					<span>
						Are you sure you want to&nbsp;
						<span style={{ fontStyle: 'italic' }}>
							{action.name}
&nbsp;
						</span>
						<span style={{ fontWeight: 'bold' }}>
							{matches.length}
&nbsp;
						</span>
						{`${isTrigger ? 'alarm' : 'alarm check'}${matches.length > 1 ? 's' : ''}?`}
					</span>
					{otherOwnersWarn.map(({ otherUserData, trigger }) => (
						<div>
							<span style={{ color: 'red' }}>WARNING: </span>
							<span style={{ fontWeight: 'bold' }}>
								{trigger.name}
								{' '}
							</span>
							belongs to user
							<span style={{ fontWeight: 'bold' }}>
								{' '}
								{otherUserData.fullname}
							</span>
						</div>
					))}
				</div>
			)}
			onAny={closeFn}
		/>
	);
}

ConfirmActionModal.propTypes = {
	isTrigger: PropTypes.bool.isRequired,
	closeFn: PropTypes.func.isRequired,
	action: PropTypes.object.isRequired,
	matches: PropTypes.array.isRequired,
	otherOwnersWarn: PropTypes.array.isRequired,
};

export function ConfirmNoFiltersModal({ closeFn }) {
	return (
		<ConfirmDialog
			open
			text={(
				<span>
					There are no&nbsp;
					<span style={{ fontWeight: 'bold' }}>
						ALARM&nbsp;
					</span>
					conditions, so this alarm will never trigger any failed checks. Are you sure to proceed anyway?
				</span>
			)}
			onAny={closeFn}
		/>
	);
}

ConfirmNoFiltersModal.propTypes = {
	closeFn: PropTypes.func.isRequired,
};

export function ConfirmChangeForOtherModal({ closeFn, otherUserData }) {
	return (
		<ConfirmDialog
			open
			text={(
				<span>
					This alarm belongs to
					{' '}
					<b>{otherUserData.fullname}</b>
					, are you sure you want to change it?
				</span>
			)}
			onAny={closeFn}
		/>
	);
}

ConfirmChangeForOtherModal.propTypes = {
	closeFn: PropTypes.func.isRequired,
	otherUserData: PropTypes.object.isRequired,
};

export function SysTriggerFailedMessage({ failedSysTriggerInfos, workspace }) {
	if (!workspace.isAdmin) {
		return (
			<p>
				Automatic execution of API Insight alarms is temporarily
				{' '}
				<i>paused</i>
&nbsp;
				because of unacknowledged system alarm checks(s) on the administrator level.
			</p>
		);
	}
	return (
		<>
			<p>
				Automatic execution of API Insight alarms is temporarily
				{' '}
				<i>paused</i>
&nbsp;
				because of unacknowledged system alarm checks(s) of types(s)&nbsp;
				{failedSysTriggerInfos.map(({ name }, idx) => (
					<span key={name}>
						<b>{name}</b>
						{idx < failedSysTriggerInfos.length - 1 ? ', ' : ''}
					</span>
				))}
				.
			</p>
			<p>
				Please resolve the issue(s) or
				{' '}
				<b>ACKNOWLEDGE</b>
				{' '}
				the failed check(s) in order to resume automatic
				execution of the API Insight alarms.
				<br />
				If you expect the issue(s) to continue for several days or more, please make
				sure
				{' '}
				<b>ENABLE AUTO-ACK</b>
				{' '}
				is enabled for the failed system check(s).
			</p>
		</>
	);
}
SysTriggerFailedMessage.propTypes = {
	failedSysTriggerInfos: PropTypes.array.isRequired,
	workspace: PropTypes.object.isRequired,
};
