import React, { useState } from 'react';
import { Button, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import PopoutButton from '../../../PopoutButton';

const AVAILABLE_OPTIONS = {
	name: 'Name',
	createdDate: 'Created date',
};

function SortButton({ onChange, value }) {
	const [isOpen, setIsOpen] = useState(false);

	const handleSelectItem = (selected) => {
		setIsOpen(false);
		onChange(selected);
	};

	return (
		<PopoutButton
			sx={{ mb: 0.5, mt: 0.5 }}
			open={isOpen}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			renderButton={({ onClick }) => (
				<Button
					sx={{ color: 'text.secondary', pr: 0, pl: 0 }}
					variant="text"
					size="large"
					onClick={(e) => {
						setIsOpen(true);
						onClick(e);
					}}
				>
					{AVAILABLE_OPTIONS[value]}
				</Button>
			)}
		>
			{ Object.entries(AVAILABLE_OPTIONS).map(([key, label]) => (
				<MenuItem
					key={key}
					value={key}
					onClick={() => handleSelectItem(key)}
				>
					{label}
				</MenuItem>
			))}
		</PopoutButton>
	);
}

SortButton.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default SortButton;
