import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	IconButton,
	Tooltip,
	CircularProgress,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { Reports } from '../../api/relevant';
import useReports from '../../hooks/useReports';
import useDebounce from '../../hooks/useDebounce';

function PinButton({ pinned: initialPinned, reportId, disabled }) {
	const [pinned, setPinned] = useState(initialPinned);
	const [isLoading, setIsLoading] = useState(false);
	// Don't display loading button if action takes < 250ms to avoid stuttering
	const debouncedIsLoading = useDebounce(isLoading, 250);

	const { updateReports } = useReports();
	return (
		<Tooltip title={pinned ? 'Unpin from sidebar' : 'Pin to sidebar'}>
			<span>
				<IconButton
					disabled={isLoading || disabled}
					aria-label="pin"
					onClick={async () => {
						const updatedPinned = !pinned;
						try {
							setIsLoading(true);
							await Reports.call('updatePinnedToSidebar', {
								reportIds: [reportId],
								pinned: updatedPinned,
							});
							setPinned(updatedPinned);
							await updateReports();
						} catch (err) {
						// Revert if it fails
							setPinned(!updatedPinned);
						}
						setIsLoading(false);
					}}
				>
					{ debouncedIsLoading && (
						<CircularProgress
							size={24}
						/>
					)}
					{(pinned && !debouncedIsLoading) && <PushPinIcon />}
					{!pinned && !debouncedIsLoading && <PushPinOutlinedIcon />}
				</IconButton>
			</span>
		</Tooltip>
	);
}

PinButton.propTypes = {
	pinned: PropTypes.bool.isRequired,
	reportId: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};

PinButton.defaultProps = {
	disabled: false,
};

export default PinButton;
