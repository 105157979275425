const _ = require('lodash');
const DiffConstants = require('./diffConstants');

const {
	ARRAY,
	ID_STRING,
	ID_OBJECT,
	OTHER_OBJECT,
	PRIMITIVE,
	OBJECT_DIFF_MARKER,
} = DiffConstants;

let DiffFns;

// Returns whether 'obj' is an object-diff
const isDiffObject = (obj) => obj && obj[OBJECT_DIFF_MARKER];

/** Check if string looks like 12 bytes that are hex-encoded (24 characters). Server-side we might also
 * encounter ObjectId instances. We'll also return true for these.  */
const isMongoIdLike = (val) => {
	if (!val) {
		return false;
	}
	let str = val;
	if (!_.isString(val)) {
		if (!val.toString) {
			return false;
		}
		str = val.toString();
	}
	if (!str || str.length !== 24) {
		return false;
	}
	for (let i = 0; i < 24; i += 1) {
		const c = str.charCodeAt(i);
		if (!((c >= 48 && c <= 57) || (c >= 97 && c <= 102))) {
			return false;
		}
	}
	return true;
};

// Returns the "type" of any value
const getType = (v) => {
	if (Array.isArray(v)) {
		return ARRAY;
	}
	if (isMongoIdLike(v)) {
		return ID_STRING;
	}
	if (_.isObject(v)) {
		return v._id ? ID_OBJECT : OTHER_OBJECT;
	}
	return PRIMITIVE;
};

const isUniquePrimitivesArray = (arr) => {
	const seen = {};
	for (const elm of arr) {
		const type = getType(elm);
		if (type !== PRIMITIVE) {
			return false; // Notice ID_STRING should *not* be counted as a "primitive" and should be handled separately
		}
		if (seen[elm]) {
			return false;
		}
		seen[elm] = true;
	}
	return true;
};

/* Gets the "id" from a value where the id is (probably) a MongoDb id.
* Currenctly supported methods to "identify" values are:
* - a "_diffIdentifier" property
* * - the result from .getIdentifier() if there is such function
* - The value is a string that looks like a MongoDb id OR the value is an ObjectId instance
* - an "_id" property
*/
const getIdFromValue = (v, uniquePrimitives) => {
	if (!v) {
		return undefined;
	}
	if (uniquePrimitives) {
		// Special-case for empty strings as we always want and identifier to be something "truthy"
		return `${v}` || '__emPty_strRing_';
	}
	if (v._diffIdentifier || v.getIdentifier) {
		const identifier = v._diffIdentifier || v.getIdentifier();
		if (identifier) {
			return `_${identifier}`; // Use '_' to distinguish from "real" (MongoDb ids)
		}
	}
	const res = isMongoIdLike(v) ? v : v._id;
	return res ? res.toString() : undefined;
};

const isDiffIdentifier = (id) => (id || '')[0] === '_';

const withFlag = (flag, cb) => {
	DiffFns.ctx[flag] = (DiffFns.ctx[flag] || 0) + 1;
	cb();
	DiffFns.ctx[flag] -= 1;
};

const withCtx = (fn, ctx) => {
	try {
		if (DiffFns.ctx) {
			throw Error('Recursive call to withCtx() not allowed');
		}
		DiffFns.ctx = ctx || {};
		return fn();
	} finally {
		delete DiffFns.ctx;
	}
};

DiffFns = {
	isDiffObject,
	isMongoIdLike,
	getType,
	isUniquePrimitivesArray,
	getIdFromValue,
	isDiffIdentifier,
	withFlag,
	withCtx,
};

// will be further populated by objectDiff.js
module.exports = DiffFns;
