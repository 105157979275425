import _ from 'lodash';
import * as Utils from './utils';
import SystemData from '../../lib/systemData';

const {
	splitByChars,
	getNamedDimensions,
	EXTENDED_SPLITTERS,
	longestCommonSubsequence: longestCommonSubStr,
	findCommonPlacementName,
} = Utils;

const MAX_COMMON_MULTIPLIER = 2.0; // if a site name is this times larger than longest common string => no match
const DOMAIN_REGEXP = /(\.[A-Za-z]{2,3})($| |,|-|\[|\]|\(|\)|_|\/)/g;

class ImportState {
	constructor(publishers, stateVars, entries) {
		Object.assign(this, {
			entries,
			publishers: _.cloneDeep(publishers),
			pubByName: {},
			failedMatches: [],
			externalData: {},
			successCount: 0,
			...stateVars,
		});
		if (this.setupCode) {
			this.evalWithCtx({}, this.setupCode, true);
		}
		this.publishers.forEach((pub) => this.initPublisher(pub));
	}

	evalWithCtx(ctx, code, allowNullReturn = false) {
		const lines = code.split('\n').filter((s) => s.trim());
		const addReturn = lines.length === 1 && !lines[0].startsWith('return');
		const id = `tmpEvalVars_${Math.random()}`;
		const vars = {
			$: ctx,
			utils: Utils,
			importState: this,
			$$: this.externalData,
			CAPITALIZE: (str) => str[0].toUpperCase() + str.slice(1).toLowerCase(),
		};
		window[id] = vars;
		const varSetters = _.keys(vars).map((key) => `var ${key} = data.${key};`).join('\n');
		const res = eval(
			`(function(id) {
					var data = window[id];
					delete window[id];
					${varSetters}
					${addReturn ? 'return ' : ''}${code};
				})('${id}')`,
		);
		if (!res && !allowNullReturn) {
			throw Error(`Nothing returned with $ = ${JSON.stringify(ctx)}`);
		}
		return res;
	}

	makeSearchStr(str, noTopDomainRemoval) {
		if (this.ignoreTopDomains && !noTopDomainRemoval) {
			const withoutTopDomain = str.replace(DOMAIN_REGEXP, (match, __, after) => after);
			const res = this.makeSearchStr(withoutTopDomain, true);
			if (res) {
				return res;
			}
		}
		const CHAR_MAPPING = {
			å: 'a', æ: 'a', ä: 'a', ö: 'o', ø: 'o',
		};
		const tmp = splitByChars(str, 0, EXTENDED_SPLITTERS).join('').toLowerCase();
		let res = '';
		for (let i = 0; i < tmp.length; i += 1) {
			res += CHAR_MAPPING[tmp[i]] || tmp[i];
		}
		return res;
	}

	initPublisher(publisher) {
		const [name] = splitByChars(publisher.name);
		this.pubByName[name.toLowerCase()] = publisher;
		publisher.__searchStr = this.makeSearchStr(publisher.name);
		publisher.__type = 'Publisher';
		(publisher.websites || []).forEach((site) => {
			this.initSite(publisher, site);
		});
	}

	initSite(publisher, site) {
		site.__searchStr = this.makeSearchStr(site.domain);
		site.__type = 'Site';
		site.__parent = publisher;
		(site.placements || []).forEach((plac) => {
			this.initPlacement(site, plac);
		});
	}

	initPlacement(site, placement) {
		placement.__dims = getNamedDimensions(placement.name);
		placement.__searchStr = this.makeSearchStr(placement.name);
		placement.__parent = site;
		placement.__type = 'Placement';
		(placement.ssps || []).forEach((sspPlac) => {
			this.initSspPlacement(placement, sspPlac);
		});
	}

	initSspPlacement(placement, sspPlacement) {
		sspPlacement.__parent = placement;
		sspPlacement.__type = 'SspPlacement';
	}

	createPublisher(name) {
		if (this.noCreateMode) {
			return null;
		}
		const publisher = new SystemData.models.Publisher({
			name,
			dateformat: 'DD-MM-YYYY',
			notes: '',
			audienceAccess: false,
			programmaticAccess: true,
			openRTBContractualCut: 0,
			dealsContractualCut: 0,
			directContractualCut: 0,
			raContractualCut: 50,
			cxDmpSites: [],
			websites: [],
			isNew: true,
			...this.newPublisherInitState,
		});
		this.publishers.push(publisher);
		this.initPublisher(publisher);
		return publisher;
	}

	createSite(publisher, name) {
		if (this.noCreateMode) {
			return null;
		}
		publisher.websites = publisher.websites || [];
		const site = {
			country: 'Finland',
			domain: name,
			placements: [],
			isNew: true,
		};
		publisher.websites.push(site);
		this.initSite(publisher, site);
		return site;
	}

	createPlacement(site, name) {
		if (this.noCreateMode) {
			return null;
		}
		site.placements = site.placements || [];
		const placement = {
			name,
			ssps: [],
			isNew: true,
		};
		site.placements.push(placement);
		this.initPlacement(site, placement);
		return placement;
	}

	getMatchingExisting(candidates, entityName) {
		const entityStr = this.makeSearchStr(entityName);
		const bySimilarity = candidates.map((entity) => ({
			entity,
			commonLen: longestCommonSubStr(entityStr, entity.__searchStr).length,
		})).sort((o1, o2) => {
			if (o1.commonLen === o2.commonLen) {
				return o1.entity.__searchStr.length - o2.entity.__searchStr.length;
			}
			return o2.commonLen - o1.commonLen; // sort by longest common length, then by shortest name
		});
		if (!bySimilarity.length) {
			return null;
		}
		const { entity, commonLen } = bySimilarity[0];
		// When using minCommonPerc = 1 ("Exact"), the strings must match exactly
		if (this.minCommonPerc >= 1 && entity.__searchStr !== entityStr) {
			return null;
		}
		if (commonLen === entityStr.length) { // exact match, return entity even if there is another entity with same name
			return entity;
		}
		/**
		if (bySimilarity.length > 1 && commonLen === bySimilarity[1].commonLen) {
			return null; // instead of choosing between two entitys with same longest common length => return null
		} */
		if (commonLen / entityStr.length < this.minCommonPerc) {
			return null;
		}
		if (entity.__searchStr.length / commonLen > MAX_COMMON_MULTIPLIER) {
			return null;
		}
		return entity;
	}

	addEntry(entry, transformStyle, transformSettings) {
		const { name, id } = Utils.entryData(entry);
		const settings = transformStyle.fn({
			name, id, settings: transformSettings, importState: this, ssp: entry.parent.sspObj,
		});

		const failEntry = () => {
			this.failedMatches.push(entry);
			return false;
		};

		if (!settings || !id) {
			return failEntry();
		}
		const NAME_VARS = [
			['PUBLISHER', 'pubName', 'publisherNaming'],
			['SITE', 'siteName', 'siteNaming'],
			['PLACEMENT', 'placementName', 'placementNaming'],
		];
		const ctx = _.cloneDeep(settings);
		Object.assign(ctx, {
			NAME: name,
			ID: id,
			SSP: entry.parent.sspObj,
		});
		NAME_VARS.forEach(([ctxVar, settingVar, evalVar]) => {
			ctx[ctxVar] = settings[settingVar];
			settings[settingVar] = this.evalWithCtx(ctx, this[evalVar]);
		});
		ctx.settings = _.cloneDeep(settings);
		const {
			pubName, siteName, placementName, width, height,
		} = settings;
		const pub = this.pubByName[pubName.toLowerCase()] || this.getMatchingExisting(this.publishers, pubName) || this.createPublisher(pubName);
		if (!pub) {
			return failEntry();
		}
		let site = this.getMatchingExisting(pub.websites || [], siteName);
		if (site && this.siteMatches) {
			ctx.OTHER = site.domain;
			if (this.evalWithCtx(ctx, this.siteMatches, true) === false) { // false exactly must be returned to cancel match
				site = null;
			}
		}
		if (!site) {
			site = this.createSite(pub, siteName);
			if (!site) {
				return failEntry();
			}
		}
		ctx.PLACEMENTS = site.placements;
		let plac = site.placements.find((p) => {
			if (this.placementMatches) {
				ctx.OTHER = p;
				const matching = this.evalWithCtx(ctx, this.placementMatches, true);
				if (matching) {
					return true;
				} if (matching === false) { // if undefine/null is returned => use normal method
					return false;
				}
			}
			return _.isEqual(p.__dims, { width, height });
		});
		if (!plac) {
			plac = this.createPlacement(site, placementName);
			if (!plac) {
				return failEntry();
			}
		} else if (plac.isNew && this.calcCommonPlacementName) {
			plac.name = findCommonPlacementName(placementName, plac.name, width, height);
		}
		const sspPlac = {
			id,
			source: entry.parent.sspId,
			__name: entry.name,
			isNew: true,
		};
		plac.ssps = plac.ssps || [];
		if (plac.ssps.find((s) => s.source === sspPlac.source && s.id === sspPlac.id)) {
			return false; // This ssp-placement already exists
		}
		plac.ssps.push(sspPlac);
		this.initSspPlacement(plac, sspPlac);
		[pub, site, plac, sspPlac].forEach((obj) => {
			obj.__hasNew = true;
			if (obj.isNew) {
				obj.__rand = Math.random().toString();
			}
		});
		this.successCount += 1;
		return true;
	}
}

export default ImportState;
