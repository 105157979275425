import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/PeopleOutline';
import linkable from '../linkable';

function UsersButton(props) {
	return (
		<IconButton {...props} size="large">
			<PeopleIcon />
		</IconButton>
	);
}

UsersButton.propTypes = {
	href: PropTypes.string,
	onClick: PropTypes.func,
};

export default linkable(UsersButton);
