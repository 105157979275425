import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import PropTypes from 'prop-types';
import AppInstanceForm from '../AppInstanceForm';
import { ActionButton } from '../ActionButton/ActionButton';
import SimpleOperationWrapper from '../SimpleOperationWrapper';
import { AppInstance } from '../../api/relevant';
import useRequest from '../../hooks/useRequest/useRequest';

function AppInstanceEditor({
	instance, existingInstanceUrls, onDone, onCancel,
}) {
	const [shouldSubmit, setShouldSubmit] = useState(false);
	const [instanceBeingEdited, setInstanceBeingEdited] = useState(instance);
	const [setRequest, loading, error, reset] = useRequest({});

	const onEditInstance = (editedInstance) => {
		setInstanceBeingEdited(editedInstance);
	};

	const onSubmit = (formHasErrors) => {
		setShouldSubmit(false);
		if (!formHasErrors) {
			setRequest({
				requestFunction: () => AppInstance.call('addOrUpdate', { instance: instanceBeingEdited }),
				requestCallback: (res) => onDone(res),
			});
		}
	};

	const onKeyDown = (e) => {
		if (e.key === 'Enter' && !loading && !error.state) {
			setShouldSubmit(true);
		}
	};

	const onSubmitClick = () => {
		if (!loading && !error.state) {
			setShouldSubmit(true);
		}
	};

	return (
		<Dialog
			open={instance !== undefined}
			fullWidth
			onKeyDown={onKeyDown}
		>
			<SimpleOperationWrapper
				loading={loading}
				error={error}
				onErrorModalClick={reset}
			>
				<DialogContent>
					{ instanceBeingEdited
					&& (
						<AppInstanceForm
							instance={instanceBeingEdited}
							onFieldUpdate={onEditInstance}
							shouldSubmit={shouldSubmit}
							onSubmit={onSubmit}
							existingInstanceUrls={existingInstanceUrls}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<ActionButton
						label="Cancel"
						onClick={onCancel}
						variant="text"
						color="primary"
					/>
					<ActionButton
						label="Save"
						color="primary"
						onClick={onSubmitClick}
					/>
				</DialogActions>
			</SimpleOperationWrapper>
		</Dialog>
	);
}

AppInstanceEditor.propTypes = {
	instance: PropTypes.shape({
		url: PropTypes.string,
		credentials: PropTypes.shape({
			username: PropTypes.string,
			password: PropTypes.string,
		}),
	}).isRequired,
	existingInstanceUrls: PropTypes.arrayOf(PropTypes.string),
	onDone: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

AppInstanceEditor.defaultProps = {
	existingInstanceUrls: [],
};

export default AppInstanceEditor;
