import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import MuiTextField from '@mui/material/TextField';
import Countries from 'relevant-shared/reportData/countries';
import AutocompleteInput from '../AutocompleteInput';
import CheckboxGroup from '../CheckboxGroup';

const CountryOptions = _.sortBy(_.entries(Countries).map(([code, name]) => ({ code, name })), 'name');
const CountryCheckboxOptions = CountryOptions.map(({ code, name }) => ({ id: code, text: name }));

function CountrySelect(props) {
	const { selected, onChange, includeTree } = props;
	return (
		<>
			<AutocompleteInput
				suggestions={CountryOptions}
				onChange={(opts) => onChange(_.map(opts, 'code'))}
				value={selected.map((code) => ({ code, name: Countries[code] || code }))}
				autocompleteProps={{
					multiple: true,
					renderInput: (params) => (
						<MuiTextField {...params} label="Countries" />
					),
					renderOption: (subProps, option, { selected: isSelected }) => (
						<Box {...subProps}>
							<div>
								<div>{option.name}</div>
							</div>
							<div>
								{ isSelected && <DoneIcon /> }
							</div>
						</Box>
					),
					isOptionEqualToValue: (option, value) => (option.code === value.code),
					getOptionLabel: (option) => option.name,
				}}
			/>
			{includeTree && (
				<CheckboxGroup
					items={CountryCheckboxOptions}
					selected={selected}
					onChange={(items) => onChange(items)}
				/>
			)}
		</>
	);
}
CountrySelect.propTypes = {
	selected: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChange: PropTypes.func.isRequired,
	includeTree: PropTypes.bool,
};

CountrySelect.defaultProps = {
	includeTree: false,
};

export default CountrySelect;
