import React, { useState } from 'react';

import {
	MenuItem,
	ListItemText,
	ListItemIcon,
	Popover,
} from '@mui/material';
import PropTypes from 'prop-types';
import ShareIcon from '@mui/icons-material/Share';
import ReportSharing from './ReportSharing';

function ReportSharingMenuButton({
	onClick,
	isPublic,
	onIsPublicChange,
	shareUrl,
	popoverAnchorEl,
	onClose,
}) {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<MenuItem onClick={() => {
				onClick();
				setIsOpen(true);
			}}
			>
				<ListItemIcon>
					<ShareIcon />
				</ListItemIcon>
				<ListItemText>Share</ListItemText>
			</MenuItem>
			<Popover
				open={isOpen}
				anchorEl={popoverAnchorEl}
				onClose={() => {
					setIsOpen(false);
					onClose();
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<ReportSharing
					isPublic={isPublic}
					shareUrl={shareUrl}
					onIsPublicChange={onIsPublicChange}
				/>
			</Popover>

		</>
	);
}

ReportSharingMenuButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	isPublic: PropTypes.bool.isRequired,
	shareUrl: PropTypes.string.isRequired,
	popoverAnchorEl: PropTypes.instanceOf(Element),
	onIsPublicChange: PropTypes.func,
	onClose: PropTypes.func.isRequired,
};

ReportSharingMenuButton.defaultProps = {
	popoverAnchorEl: null,
	onIsPublicChange: undefined,
};

export default ReportSharingMenuButton;
