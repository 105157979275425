import ReportType from 'relevant-shared/reportData/reportType';
import { stores } from '../../stores';

class FrontendReportType extends ReportType {
	get tag() {
		return `${stores.identity.isAdministrator() ? 'admin' : 'publisher'}_${this.type}`;
	}
}

export default FrontendReportType;
