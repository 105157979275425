import React from 'react';
import PropTypes from 'prop-types';
import Form from '../../containers/Form';
import { ActionButton } from '../ActionButton/ActionButton';
import TextField from '../TextField';

@Form
class ProgrammaticSettingsForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = { };
	}

	render() {
		const { field, form } = this.props;
		const settings = this.props.model;
		return (
			<div>
				<TextField
					{...field('systemWideCost')}
					label="System Wide Cost (&euro;)"
					required
					integer
				/>
				<div>
					<ActionButton
						label="Save"
						color="primary"
						onClick={this.props.submit}
					/>
				</div>
			</div>
		);
	}
}
ProgrammaticSettingsForm.wrappedComponent.propTypes = {
	model: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	field: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
};

ProgrammaticSettingsForm.wrappedComponent.defaultProps = {

};

export default ProgrammaticSettingsForm;
