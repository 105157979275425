exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".sspLogo__src-components-SSPForm-styles__1qPv- {\n\tmax-height: 50px;\n\tmax-width: 100%;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/digitalenterprises/temp/relevant-yield/relevant-frontend/src/components/SSPForm/styles.css"],"names":[],"mappings":"AAAA;CACC,iBAAgB;CAChB,gBAAe;CAChB","file":"styles.css","sourcesContent":[".sspLogo {\n\tmax-height: 50px;\n\tmax-width: 100%;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"sspLogo": "sspLogo__src-components-SSPForm-styles__1qPv-"
};