import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Fade,
	ListItemSecondaryAction,
	Collapse,
	IconButton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PushPinIcon from '@mui/icons-material/PushPin';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLocation, useHistory } from 'react-router-dom';
import SidebarButton from '../SidebarButton';
import { Reports } from '../../../api/relevant';
import { findNextReportPath } from '../utils';
import useReports from '../../../hooks/useReports';
import usePinnedReports from '../../../hooks/usePinnedReports';

function ReportButton({
	id,
	path,
	text,
	selected,
}) {
	const history = useHistory();
	const { pathname } = useLocation();
	const [isHovering, setIsHovering] = useState(false);
	const [showButtons, setShowButtons] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isUnpinning, setIsUnpinning] = useState(false);
	const { reports, updateReports } = useReports();
	const pinnedReports = usePinnedReports();
	const anchorEl = useRef();

	return (
		<div
			onFocus={() => setIsHovering(true)}
			onMouseOver={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<SidebarButton
				path={path}
				text={text}
				selected={selected}
				sx={{
					pl: 9,
				}}
				ListItemTextProps={{
					sx: {
						// Make room for button
						maxWidth: 'calc(100% - 40px)',
					},
				}}
				secondaryIcon={(
					<Fade in={isHovering || showButtons}>
						<ListItemSecondaryAction
							ref={anchorEl}
						>
							<IconButton
								aria-label="more"
								color="inherit"
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									setShowButtons(!showButtons);
								}}
							>
								{ showButtons ? <CloseIcon /> : <MoreVertIcon /> }
							</IconButton>
						</ListItemSecondaryAction>
					</Fade>
				)}
				dense
			/>
			<Collapse in={showButtons}>
				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					mt: 1,
					mb: 2,
				}}
				>
					<LoadingButton
						loading={isDeleting}
						loadingPosition="start"
						sx={{
							mr: 2,
							'&.Mui-disabled': {
								color: 'primary.contrastText',
								opacity: 0.26,
							},
						}}
						color="error"
						variant="contained"
						size="small"
						startIcon={<DeleteIcon />}
						onClick={async () => {
							setIsDeleting(true);
							try {
								await Reports.call('deleteReport', { id });
								await updateReports();
								// Navigate to next report if we're deleting the current one
								if (pathname.includes(id)) {
									const reportType = pathname.split('/')[2];
									let typeReports = reports[reportType];
									if (pinnedReports[reportType].length > 0) {
										typeReports = pinnedReports[reportType];
									}
									const index = typeReports
										.findIndex((report) => report.id === id);
									const nextPath = findNextReportPath(index, reportType, typeReports);
									history.push(nextPath);
								}
							} catch (err) {
								setIsDeleting(false);
							}
						}}
					>
						Delete
					</LoadingButton>
					<LoadingButton
						sx={{
							'&.Mui-disabled': {
								color: 'primary.contrastText',
								opacity: 0.26,
							},
						}}
						onClick={async () => {
							setIsUnpinning(true);
							try {
								await Reports.call('updatePinnedToSidebar', {
									reportIds: [id],
									pinned: false,
								});
								updateReports();
							} catch (err) {
								//
							}
							setIsUnpinning(false);
						}}
						loading={isUnpinning}
						loadingPosition="start"
						color="info"
						variant="outlined"
						size="small"
						startIcon={<PushPinIcon />}
					>
						Unpin
					</LoadingButton>
				</Box>
			</Collapse>
		</div>
	);
}

ReportButton.propTypes = {
	id: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	selected: PropTypes.bool.isRequired,
};

export default ReportButton;
